import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { loadingFor } from '@ngneat/loadoff';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LetModule } from '@ngrx/component';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { from, Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { BlockedCallerRequest, BlockedCallerRequestSchema, BlockedCallerResponse, CallLog, CallLogSchema } from '@pwp-common';

import { setControlEnabled } from '../../../common/form/set-control-enabled';
import { DATETIME_LOCAL_CONTROL_STR_FORMAT, getTimestampFromDateTimeLocalFieldControl } from '../../../common/objects/form-helper';
import { DatetimeValidator } from '../../../common/validators/datetime-validator/datetime-validator';
import { BlockedCallerService } from '../../../services/call/blocked-caller/blocked-caller.service';
import { CommunicationsService } from '../../../services/call/communications/communications.service';
import { TranslocoRootModule } from '../../../transloco/transloco-root.module';
import { ComponentWithForm } from '../../generic/abstract-classes/component-with-form';
import { LoadingModule } from '../../generic/loading.module';

@UntilDestroy()
@Component({
  selector: 'app-blocked-caller',
  standalone: true,
  imports: [
    CheckboxModule,
    CommonModule,
    FormsModule,
    LetModule,
    LoadingModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MessagesModule,
    ReactiveFormsModule,
    ToastModule,
    TranslocoRootModule,
  ],
  templateUrl: './blocked-caller.component.html',
  styleUrls: ['./blocked-caller.component.scss'],
  providers: [MessageService],
})
export class BlockedCallerComponent extends ComponentWithForm implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Dependencies
  /////////////////////////////////////////////////////////////////////////////////////////////

  private readonly blockedCallerService = inject(BlockedCallerService);

  private readonly communicationsService = inject(CommunicationsService);

  private readonly data: { callLog: CallLog } = inject(MAT_DIALOG_DATA);

  private readonly dialogRef = inject(MatDialogRef);

  private readonly formBuilder = inject(FormBuilder);

  private readonly messageService = inject(MessageService);

  private readonly translocoService = inject(TranslocoService);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Properties
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public isBlockExpired = true;

  public initialValue: Observable<BlockedCallerResponse>;

  public apiCallInProgress = false;

  public readonly callIsOlderThan30Days = this.data.callLog.getIncomingCallReceivedTime().add(30, 'days').isBefore(moment());

  public readonly hasBlockedCaller = this.data.callLog.getBlockedCallerId() !== CallLogSchema.Defaults.blockedCallerId;

  public readonly loader = loadingFor('initialValue');

  public readonly form = this.formBuilder.group({
    [BlockedCallerRequestSchema.expireTime]: [
      moment().add(1, 'hour').format(DATETIME_LOCAL_CONTROL_STR_FORMAT),
      [Validators.required, DatetimeValidator.isAfter(this.getMinExpireTime(), undefined)],
    ],
    [BlockedCallerRequestSchema.reason]: ['', [Validators.required]],
    allowedToBlock: [false, [Validators.requiredTrue]],
  });

  public readonly phoneNumberData$ = from(this.communicationsService.getCallLogPII(this.data.callLog.getId())).pipe(
    map((response) => ({
      isAnonymous: this.data.callLog.getHasAnonymousCaller(),
      phoneNumber: response.getCallerPhone(),
    })),
    startWith({
      isAnonymous: this.data.callLog.getHasAnonymousCaller(),
      phoneNumber: '',
    }),
  );

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  private getMinExpireTime() {
    return this.callIsOlderThan30Days ? this.data.callLog.getIncomingCallReceivedTime() : moment();
  }

  private patchValues(response: BlockedCallerResponse): void {
    this.isBlockExpired = response.getExpireTime().isBefore(moment());

    this.form.patchValue({
      expireTime: response.getExpireTime().format(DATETIME_LOCAL_CONTROL_STR_FORMAT),
      reason: response.getReason(),
    });

    setControlEnabled(this.form, !this.hasBlockedCaller || this.isBlockExpired);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Notifications
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  private successMessage(): void {
    const message = this.translocoService.translate('blocked-caller.toastSuccess');

    this.messageService.add({
      severity: 'success',
      summary: message,
      detail: '',
    });
  }

  private failureMessage(error: unknown): void {
    const message = this.translocoService.translate('blocked-caller.toastError');

    console.error(error);
    this.messageService.add({
      severity: 'error',
      summary: message,
      detail: JSON.stringify(error),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // API
  /////////////////////////////////////////////////////////////////////////////////////////////

  private getBlockedCallerRequest(): BlockedCallerRequest | undefined {
    if (this.data.callLog?.getId() === undefined) {
      return undefined;
    }

    const { expireTime, reason } = this.form.controls;

    return new BlockedCallerRequest({
      expireTime: getTimestampFromDateTimeLocalFieldControl(expireTime, undefined, moment.unix(0)),
      reason: reason.value,
      sessionId: this.data.callLog.getId(),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  public override ngOnInit(): void {
    super.ngOnInit();

    if (this.hasBlockedCaller) {
      const request = new BlockedCallerRequest({ blockedCallerId: this.data.callLog.getBlockedCallerId() });
      this.blockedCallerService
        .makeRequestOLD(request)
        .pipe(
          this.loader.initialValue.track(),
          map((r) => {
            this.patchValues(r);
          }),
          untilDestroyed(this),
        )
        .subscribe();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Actions
  /////////////////////////////////////////////////////////////////////////////////////////////

  public async doBlock(): Promise<void> {
    this.apiCallInProgress = true;

    try {
      const request = this.getBlockedCallerRequest();
      await this.blockedCallerService.makeRequestOLD(request).toPromise();
      this.successMessage();
      this.dialogRef.close();
    } catch (error) {
      this.failureMessage(error);
    }

    this.apiCallInProgress = false;
  }

  public async doExpireNow(): Promise<void> {
    this.apiCallInProgress = true;

    try {
      const request = this.getBlockedCallerRequest().setExpireTime(moment());
      await this.blockedCallerService.makeRequestOLD(request).toPromise();
      this.successMessage();
      this.dialogRef.close();
    } catch (error) {
      this.failureMessage(error);
    }

    this.apiCallInProgress = false;
  }
}
