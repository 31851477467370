import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { loadingFor } from '@ngneat/loadoff';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { CallList, EntityStats, EntityStatsDisplayWindow, EntityStatsType, getIntervalForWindow, GLOBAL_TIMEZONE } from '@pwp-common';

import { EntityStatsService } from '../../../../services/analytics/entity-stats/entity-stats.service';
import { CallListService } from '../../../../services/call/call-list/call-list.service';
import { ComponentWithForm } from '../../../generic/abstract-classes/component-with-form';

@Component({
  selector: 'app-admin-overview-dashboard',
  templateUrl: './admin-overview-dashboard.component.html',
  styleUrls: ['./admin-overview-dashboard.component.css'],
})
export class AdminOverviewDashboardComponent extends ComponentWithForm implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////

  possibleWindows = Object.values(EntityStatsDisplayWindow).filter((z) => z !== EntityStatsDisplayWindow.custom);

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  loader = loadingFor('orgStats', 'callLists', 'lastRefreshTime');

  orgStats: Observable<EntityStats>;

  callLists: Observable<Map<string, CallList>>;

  lastRefreshTime: Observable<moment.Moment>;

  windowStart: moment.Moment;

  windowEnd: moment.Moment;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private entityStatsService: EntityStatsService,
    private callListService: CallListService,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////

  private async getData() {
    // Get data
    this.orgStats = this.entityStatsService.getDoc(EntityStatsType.org).pipe(this.loader.orgStats.track(), shareReplay(1));
    this.callLists = this.callListService.getDocs().pipe(this.loader.callLists.track(), shareReplay(1));
    this.lastRefreshTime = this.orgStats.pipe(
      this.loader.lastRefreshTime.track(),
      map((z) => z.getLastUploadTime()),
      shareReplay(1),
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;
    formConfig.window = [EntityStatsDisplayWindow.thisYear, [Validators.required]];
    this.form = this.formBuilder.group(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get window(): AbstractControl | null {
    return this.form.get('window');
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Display Start / End interval
  /////////////////////////////////////////////////////////////////////////////////////////////

  public onFormChanges(value: any): void {
    if (isNil(this.window?.value)) {
    }

    const interval = getIntervalForWindow(this.window.value);
    this.windowStart = moment.tz(interval.getStart(), GLOBAL_TIMEZONE);
    this.windowEnd = moment.tz(interval.getEnd(), GLOBAL_TIMEZONE);
  }
}
