import {SchemaFieldConstants} from '../../../objects/generic/serialization/constants';
import {SchemaField} from '../../../objects/generic/serialization/schema-field';
import {RequestSchema} from '../../generic/request/request-schema';

export class SubmitTicketRequestSchema extends RequestSchema {
  public static readonly subject = 'subject';
  public static readonly message = 'message';
  public static readonly priority = 'priority';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class extends RequestSchema.GenericDefaults {
    public static readonly subject = undefined;
    public static readonly message = undefined;
    public static readonly priority = 'low';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[SubmitTicketRequestSchema.subject] = SchemaField.string(SubmitTicketRequestSchema.Defaults.subject);
    parameters[SubmitTicketRequestSchema.message] = SchemaField.string(SubmitTicketRequestSchema.Defaults.message, undefined, SchemaFieldConstants.maxSafeStringLength);
    parameters[SubmitTicketRequestSchema.priority] = SchemaField.string(SubmitTicketRequestSchema.Defaults.priority);
    /* eslint-enable */

    return parameters;
  }
}
