import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';

import { VoicemailMetadataService } from '../../../../../../services/voicemail/voicemail-metadata/voicemail-metadata.service';
import { VoicemailRow } from '../../../../row/voicemail-row/voicemail-row';
import { InboxService } from '../../../../services/inbox-service/inbox.service';

@UntilDestroy()
@Component({
  selector: 'app-inbox-voicemail-detail-handled-by-button',
  templateUrl: './inbox-voicemail-detail-handled-by-button.component.html',
  styleUrls: ['./inbox-voicemail-detail-handled-by-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class InboxVoicemailDetailHandledByButtonComponent implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////
  @Input() row: VoicemailRow;
  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private voicemailMetadataService: VoicemailMetadataService,
    private inboxService: InboxService,
  ) {}

  ngOnInit(): void {}

  ///////////////////////////////////////////////////////////////////////
  // Toggle Handled
  ///////////////////////////////////////////////////////////////////////

  toggleHandledBySelf = async () => {
    await this.toggleHandledBySelfHandler();
  };

  async toggleHandledBySelfHandler() {
    const voicemailMetadata = await this.voicemailMetadataService.toggleHandledBySelf(this.row.id);
    this.inboxService.markVoicemailAsUpdated(voicemailMetadata.getId());
    this.changeDetectorRef.detectChanges();
  }
}
