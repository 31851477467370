import moment from 'moment-timezone';
import Joi from 'joi';
import {SchemaField} from '../serialization/schema-field';
import {SerializableObjectSchema} from '../serialization/serializable-object-schema';
import {DBDocSchemaFields} from './db-doc-schema-fields';

const UNKNOWN_COLLECTION_NAME = 'COLLECTION_NAME_NOT_DEFINED';

/**
 * All schema classes extend this class.
 */
export abstract class DBDocSchema extends SerializableObjectSchema {
  public static readonly id = DBDocSchemaFields.id;
  public static readonly createTime = DBDocSchemaFields.createTime;
  public static readonly lastUploadTime = DBDocSchemaFields.lastUploadTime;

  public static readonly createdByUserId = DBDocSchemaFields.createdByUserId;
  public static readonly lastModifiedByUserId = DBDocSchemaFields.lastModifiedByUserId;

  static GenericDefaults = class {
    public static readonly id = 'MISSING-document-id';
    public static readonly createTime = moment(0);
    public static readonly createdByUserId = 'UNKNOWN-createdByUserId';

    public static readonly lastUploadTime = moment(0);
    public static readonly lastModifiedByUserId = 'UNKNOWN-lastModifiedByUserId';
  };

  // Constants associated with schema
  static GenericCollections = class {
    public static readonly orgs = 'orgs';
  };

  /**
   * Return the collection to which this object belongs
   */
  public abstract getCollection(orgId: string, ...otherFields: string[]): string;

  public getCollectionName(): string {
    if ('Constants' in this.constructor) {
      const constants = this.constructor['Constants'] as {collection?: string};

      return constants.collection || UNKNOWN_COLLECTION_NAME;
    }

    console.error('error');
    console.error(this);

    throw new Error('Error: object does not define Constants in a class named Constants');
  }

  /**
   * All data specific to the given organization is stored under the specified doc.
   * @param orgId Organization Id
   */
  public orgDoc(orgId: string): string {
    return `${DBDocSchema.GenericCollections.orgs}/${orgId}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;
    parameters[DBDocSchema.id] = SchemaField.string(DBDocSchema.GenericDefaults.id);

    parameters[DBDocSchema.createTime] = SchemaField.timestamp(DBDocSchema.GenericDefaults.createTime);
    parameters[DBDocSchema.createdByUserId] = SchemaField.userId(DBDocSchema.GenericDefaults.createdByUserId);

    parameters[DBDocSchema.lastUploadTime] = SchemaField.timestamp(DBDocSchema.GenericDefaults.lastUploadTime, Joi.ref(DBDocSchema.createTime));
    parameters[DBDocSchema.lastModifiedByUserId] = SchemaField.userId(DBDocSchema.GenericDefaults.lastModifiedByUserId, Joi.ref(DBDocSchema.createdByUserId));

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Org scoped path
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getOrgScopedPath(docId: string) {
    const collection = this.getCollection('example-org');
    const numOfPaths = collection.split('/').length - 1;

    if (numOfPaths > 2) {
      throw new Error('Error: collection with more than 2 paths requires a custom implementation');
    }

    const collectionName = this.getCollectionName();

    if (collectionName === UNKNOWN_COLLECTION_NAME) {
      throw new Error('Error: object does not define a collection name in a class named Constants');
    }

    return `${collectionName}/${docId}`;
  }
}
