import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { ToastModule } from 'primeng/toast';

import { DataTableModule } from '../../generic/data-table/data-table.module';
import { SettingsModule } from '../../generic/settings/settings.module';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';

import { EventRequestConfigEditorComponent } from './editor/event-request-config-editor/event-request-config-editor.component';
import { EventRequestConfigTableComponent } from './editor-table/event-request-config-table/event-request-config-table.component';
import { EventRequestConfigTableEditorExpansionComponent } from './editor-table/event-request-config-table-editor-expansion/event-request-config-table-editor-expansion.component';

@NgModule({
  declarations: [EventRequestConfigTableComponent, EventRequestConfigTableEditorExpansionComponent],
  imports: [
    ButtonModule,
    CheckboxModule,
    CommonModule,
    DataTableModule,
    EventRequestConfigEditorComponent,
    FormsModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    SettingsModule,
    ToastModule,
    TranslocoModule,
  ],
  exports: [EventRequestConfigEditorComponent, EventRequestConfigTableComponent],
})
export class EventRequestConfigModule {}
