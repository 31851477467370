import {SchemaField} from '../../generic/serialization/schema-field';
import {VoiceResponseCommandSchema} from '../generic/voice-response-command-schema';
import {VoiceResponseCommandName} from '../any-voice-response-command/voice-response-command-name';

export class ExecIVRSchema extends VoiceResponseCommandSchema {
  // ID of the call list to dial
  public static readonly ivrId = 'ivrId';

  // Number of retries to offer before the IVR is considered to be failed.
  public static readonly numRetriesAllowed = 'numRetriesAllowed';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = VoiceResponseCommandName.execIVR;
    public static readonly ivrId = undefined;
    public static readonly numRetriesAllowed = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(ExecIVRSchema.Defaults.commandName);
    parameters[ExecIVRSchema.ivrId] = SchemaField.string(ExecIVRSchema.Defaults.ivrId);
    parameters[ExecIVRSchema.numRetriesAllowed] = SchemaField.number(ExecIVRSchema.Defaults.numRetriesAllowed);
    /* eslint-enable */

    return parameters;
  }
}
