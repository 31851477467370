import Joi from 'joi';
import {SerializableObjectSchema} from '../../../../../generic/serialization/serializable-object-schema';
import {ServiceLimitEnforcementStrategyRRuleSchema} from '../../strategies/service-limit-enforcement-strategy-rrule/service-limit-enforcement-strategy-rrule-schema';

export class AnyServiceLimitEnforcementStrategySchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyServiceLimitEnforcementStrategy';

  private static computedSchema = Joi.alternatives()
    .try(...[new ServiceLimitEnforcementStrategyRRuleSchema().getJoiSchema()])
    .id(AnyServiceLimitEnforcementStrategySchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyServiceLimitEnforcementStrategySchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    return AnyServiceLimitEnforcementStrategySchema.computedSchema;
  }
}
