/**
 * List all possible tasks the system is capable of processing.
 */
export enum CommunicationsRequestName {
  createAsyncServiceRequest = 'createAsyncServiceRequest',
  createOutboundCallSession = 'createOutboundCallSession',
  dialAsyncServiceRequest = 'dialAsyncServiceRequest',
  dialConference = 'dialConference',
  dialPhone = 'dialPhone',
  getCallLogPII = 'getCallLogPII',
  getConversationLogPII = 'getConversationLogPII',
  getVoiceDeviceToken = 'getVoiceDeviceToken',
  handleAsyncServiceRequest = 'handleAsyncServiceRequest',
  onTaskRouterConfigurationUpdated = 'onTaskRouterConfigurationUpdated',
}

export type CommunicationsRequestNameType = keyof typeof CommunicationsRequestName;
