import type {ValidationResult} from 'joi';
import {cloneDeep} from 'lodash';

import {FormBuilderVendor} from '../../../../../objects/form/form-builder-vendor';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {PossibleAdminRole} from '../../../../../objects/user/admin-roles/types';
import {PossibleRole} from '../../../../../objects/user/roles/types';
import {FormRequestName} from '../../../form-request-name';
import {FormRequest} from '../../generic/form-request/form-request';
import {FormRequestSchema} from '../../generic/form-request/form-request-schema';

import {FormRequestCreateVersionConstructor} from './form-request-create-version-constructor';
import {FormRequestCreateVersionSchema} from './form-request-create-version-schema';

export class FormRequestCreateVersion extends FormRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected description!: string;

  protected displayName!: string;

  protected formId!: string;

  protected formVersionId!: string;

  protected value!: string;

  protected vendor!: FormBuilderVendor;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: FormRequestCreateVersionConstructor) {
    super({
      ...parameters,
      [FormRequestSchema.type]: FormRequestName.createVersion,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): FormRequestCreateVersion {
    return new FormRequestCreateVersion(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new FormRequestCreateVersionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(FormRequestCreateVersion.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['orgAdmin'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getDescription(): string {
    return cloneDeep(this.description);
  }

  public getDisplayName(): string {
    return cloneDeep(this.displayName);
  }

  public getFormId(): string {
    return cloneDeep(this.formId);
  }

  public getFormVersionId(): string {
    return cloneDeep(this.formVersionId);
  }

  public getValue(): string {
    return cloneDeep(this.value);
  }

  public getVendor(): FormBuilderVendor {
    return cloneDeep(this.vendor);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Log metadata
  /////////////////////////////////////////////////////////////////////////////

  public getLogMetadata(): Record<string, unknown> {
    return {
      ...super.getLogMetadata(),
      description: this.getDescription(),
      displayName: this.getDisplayName(),
      formId: this.getFormId(),
      formVersionId: this.getFormVersionId(),
      value: this.getValue(),
      vendor: this.getVendor(),
    };
  }
}
