<ng-template #dashboardConfigTemplate>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>Window Size</mat-label>
          <mat-select formControlName="slidingWindowDurationDays">
            <mat-option *ngFor="let w of possibleWindows" [value]="w">Past {{ w }} days</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #dashboardTemplate>
  <div class="row">
    <div class="col">
      <app-call-load-overview
        [allUserDataMap]="allUserDataMap | async"
        [entityStatsMap]="entityStatsMap | async"
        [slidingWindowDurationDays]="slidingWindowDurationDays?.value"
      >
      </app-call-load-overview>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <app-event-load-overview
        [allUserDataMap]="allUserDataMap | async"
        [entityStatsMap]="entityStatsMap | async"
        [slidingWindowDurationDays]="slidingWindowDurationDays?.value"
      >
      </app-event-load-overview>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <app-user-load-table
        [allUserDataMap]="allUserDataMap | async"
        [entityStatsMap]="entityStatsMap | async"
        [slidingWindowDurationDays]="slidingWindowDurationDays?.value"
      >
      </app-user-load-table>
    </div>
  </div>
</ng-template>

<app-loading [doneTemplate]="doneTemplate" [loader]="loader">
  <ng-template #doneTemplate>
    <app-analytics-dashboard
      [dashboardConfigTemplate]="dashboardConfigTemplate"
      [dashboardTemplate]="dashboardTemplate"
      [lastRefreshTime]="lastRefreshTime | async"
    >
    </app-analytics-dashboard>
  </ng-template>
</app-loading>
