import {SerializableObject} from '../serializable-object';
import {configure} from 'safe-stable-stringify';
import {isNil} from 'lodash';

const stringify = configure({deterministic: true});
const defaultValue = '{}';
export const serializeToJsonString = (obj: SerializableObject | Object): string => {
  if (isNil(obj)) {
    return defaultValue;
  }

  let valueToStringify = obj;
  if (obj instanceof SerializableObject) {
    valueToStringify = (obj as any).serialize();
  }

  return stringify(valueToStringify)!;
};
