import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsRequestSchema} from '../../generic/communications-request/communications-request-schema';
import {CommunicationSessionId} from '../../../../../objects/core/communication-session-id/communication-session-id';

export class CommunicationsRequestDialAsyncServiceRequestSchema extends CommunicationsRequestSchema {
  public static readonly asyncServiceRequestId = 'asyncServiceRequestId';
  public static readonly callerIdObjId = 'callerIdObjId';
  public static readonly sessionId = 'sessionId';
  public static readonly conferenceFriendlyName = 'conferenceFriendlyName';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly asyncServiceRequestId = undefined;
    public static readonly callerIdObjId = undefined;
    public static readonly sessionId = undefined;
    public static readonly conferenceFriendlyName = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsRequestDialAsyncServiceRequestSchema.callerIdObjId] = SchemaField.autoGeneratedDocId(CommunicationsRequestDialAsyncServiceRequestSchema.Defaults.callerIdObjId).required();
    parameters[CommunicationsRequestDialAsyncServiceRequestSchema.asyncServiceRequestId] =  SchemaField.autoGeneratedDocId(CommunicationsRequestDialAsyncServiceRequestSchema.Defaults.asyncServiceRequestId).required();
    parameters[CommunicationsRequestDialAsyncServiceRequestSchema.sessionId] =  SchemaField.obj(CommunicationSessionId,CommunicationsRequestDialAsyncServiceRequestSchema.Defaults.sessionId).required();
    parameters[CommunicationsRequestDialAsyncServiceRequestSchema.conferenceFriendlyName] =  SchemaField.autoGeneratedDocId(CommunicationsRequestDialAsyncServiceRequestSchema.Defaults.conferenceFriendlyName).required();
    /* eslint-enable */

    return parameters;
  }
}
