import { cloneDeep, isNil } from 'lodash';

import {
  ReservationSkipOffersIf,
  ReservationSkipOffersIfRuleAvailability,
  ReservationSkipOffersIfRuleChannelMax,
  ReservationSkipOffersIfRuleChannelMaxChannelName,
  ReservationSkipOffersIfRuleName,
} from '@pwp-common';

import { defaultReservationSkipOffersIfEditorOutput } from '../../default-reservation-skip-offers-if-editor-output';
import { ReservationSkipOffersIfEditorOutput } from '../../reservation-skip-offers-if-editor-output';

export const dbToReservationSkipOffersIfEditorOutput = (
  reservationOfferSkipIf: ReservationSkipOffersIf,
): ReservationSkipOffersIfEditorOutput => {
  if (isNil(reservationOfferSkipIf)) {
    return cloneDeep(defaultReservationSkipOffersIfEditorOutput);
  }

  const result: ReservationSkipOffersIfEditorOutput = {
    skipIfUnavailable: false,
    skipIfNumCallsInProgressGE: undefined,
  };
  for (const rule of reservationOfferSkipIf.getRules()) {
    switch (rule.getType()) {
      case ReservationSkipOffersIfRuleName.availability: {
        const typedRule = rule as ReservationSkipOffersIfRuleAvailability;
        result.skipIfUnavailable = typedRule.getSkipIfUnavailable();
        continue;
      }
      case ReservationSkipOffersIfRuleName.channelMax: {
        const typedRule = rule as ReservationSkipOffersIfRuleChannelMax;
        if (typedRule.getChannel() === ReservationSkipOffersIfRuleChannelMaxChannelName.phone) {
          result.skipIfNumCallsInProgressGE = typedRule.getSkipIfInProgressGE();
        }
        continue;
      }
    }
  }

  return result;
};
