import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-service-exception-editor-dialog',
  templateUrl: './service-exception-editor-dialog.component.html',
  styleUrls: ['./service-exception-editor-dialog.component.css'],
})
export class ServiceExceptionEditorDialogComponent implements OnInit {
  constructor(public ref: DynamicDialogRef) {}

  ngOnInit(): void {}

  public closeDialog(serviceExceptionId: string) {
    this.ref.close({ serviceExceptionId });
  }
}
