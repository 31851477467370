import Joi from 'joi';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {CommunicationTaskCallbackRequestSchema} from '../../communication-tasks/communication-task-callback-request/communication-task-callback-request-schema';
import {CommunicationTaskConversationRequestSchema} from '../../communication-tasks/communication-task-conversation-request/communication-task-conversation-request-schema';

export class AnyCommunicationTaskSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyCommunicationTask';

  private static computedSchema = Joi.alternatives()
    .try(...[new CommunicationTaskCallbackRequestSchema().getJoiSchema(), new CommunicationTaskConversationRequestSchema().getJoiSchema()])
    .id(AnyCommunicationTaskSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyCommunicationTaskSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    return AnyCommunicationTaskSchema.computedSchema;
  }
}
