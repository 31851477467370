<ng-container *transloco="let t; read: 'date-range-select'">
  <form [formGroup]="form">
    <span class="p-float-label">
      <p-calendar
        appendTo="body"
        ariaLabelledBy="dateRangeControlLabel"
        dateFormat="mm/dd/yy"
        id="dateRangeControl"
        selectionMode="range"
        [formControl]="dateRangeControl"
        [required]="required"
        [showIcon]="true"
        [showOnFocus]="false"
        (onSelect)="closeCalendarIfRangeIsValid()"
      ></p-calendar>
      <label for="dateRangeControl" id="dateRangeControlLabel">{{ label }}</label>
    </span>
  </form>
</ng-container>
