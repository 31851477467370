import moment from 'moment-timezone';
import {isNumber, padStart} from 'lodash';
import {GLOBAL_TIMEZONE} from '../../../helper/constants';

export interface Timestamp {
  seconds: number;
  nanoseconds: number;
}

/**
 * Angular fire returns object with this interface: {seconds: number; nanoseconds: number}
 * Firebase Admin returns objects with this interface: {_seconds: number; _nanoseconds: number}
 */
export interface RawTimestamp {
  seconds?: number;
  nanoseconds?: number;
  _seconds?: number;
  _nanoseconds?: number;
}

export const momentToTimestamp = (input: moment.Moment): Timestamp => {
  const seconds = input.unix();
  const nanoseconds = input.milliseconds() * 1000000;
  return {
    seconds: seconds,
    nanoseconds: nanoseconds,
  };
};

export const rawTimestampToMoment = (
  rawTimestamp: RawTimestamp,
  defaultValue?: moment.Moment
): moment.Moment | undefined => {
  const seconds = rawTimestamp.seconds === undefined ? rawTimestamp._seconds : rawTimestamp.seconds;
  const nanoseconds = rawTimestamp.nanoseconds === undefined ? rawTimestamp._nanoseconds : rawTimestamp.nanoseconds;

  // Check that the object is valid
  if (!isNumber(seconds) || !isNumber(nanoseconds)) {
    return defaultValue;
  }

  const result = moment(`${seconds}.${padStart(nanoseconds.toString(), 9, '0')}`, 'X').tz(GLOBAL_TIMEZONE);
  if (!result.isValid()) {
    return defaultValue;
  }
  return result;
};
