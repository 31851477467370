import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {values} from 'lodash';
import {CommunicationsResponseDialConferenceError} from './communications-response-dial-conference-error';
import {CommunicationSessionId} from '../../../../../objects/core/communication-session-id/communication-session-id';

export class CommunicationsResponseDialConferenceSchema extends CommunicationsResponseSchema {
  public static readonly sessionId = 'sessionId';
  public static readonly conferenceFriendlyName = 'conferenceFriendlyName';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly error = undefined;

    public static readonly sessionId = undefined;
    public static readonly conferenceFriendlyName = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsResponseDialConferenceSchema.error] = SchemaField.string(CommunicationsResponseSchema.Defaults.error).optional().valid(...values(CommunicationsResponseDialConferenceError));
    parameters[CommunicationsResponseDialConferenceSchema.sessionId] =  SchemaField.obj(CommunicationSessionId,CommunicationsResponseDialConferenceSchema.Defaults.sessionId);
    parameters[CommunicationsResponseDialConferenceSchema.conferenceFriendlyName] =  SchemaField.autoGeneratedDocId(CommunicationsResponseDialConferenceSchema.Defaults.conferenceFriendlyName).optional();
    /* eslint-enable */

    return parameters;
  }
}
