<form>
  <mat-form-field>
    <mat-label> {{ label }} </mat-label>
    <input matInput type="text" [formControl]="formControl" [matAutocomplete]="auto" />
    <mat-autocomplete
      #auto="matAutocomplete"
      panelWidth="auto !important"
      [displayWith]="displayFn"
      (optionSelected)="selectionChanged($event.option.value)"
    >
      <mat-option *ngFor="let item of filteredItems | async" [value]="item">
        {{ displayFn(item) }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
