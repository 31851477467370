import { createDefaultEventRequestConfig, DBDocSchema, EventType } from '@pwp-common';

import { makeEventRequestConfigEditorOutput } from './make-event-request-editor-output/make-event-request-config-editor-output';

export const defaultEventRequestConfigEditorOutput = makeEventRequestConfigEditorOutput({
  eventRequestConfig: createDefaultEventRequestConfig(),
  eventType: new EventType({
    displayName: '',
    internalName: DBDocSchema.GenericDefaults.id,
  }),
});
