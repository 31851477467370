import {cloneDeep} from 'lodash';
import {CommunicationWorkflowTargetConstructor} from './communication-workflow-target-constructor';
import {CommunicationWorkflowTargetSchema} from './communication-workflow-target-schema';
import {SerializableObject} from '../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {CommunicationWorkflowTargetTimeout} from '../communication-workflow-target-timeout/generic/communication-workflow-target-timeout/communication-workflow-target-timeout';

export class CommunicationWorkflowTarget extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected queueId!: string | undefined;
  protected timeout!: CommunicationWorkflowTargetTimeout | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWorkflowTargetConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationWorkflowTarget {
    return new CommunicationWorkflowTarget(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationWorkflowTarget.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationWorkflowTargetSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getQueueId(): string | undefined {
    return cloneDeep(this.queueId);
  }

  public getTimeout(): CommunicationWorkflowTargetTimeout | undefined {
    return cloneDeep(this.timeout);
  }
}
