import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { UserPrivateData } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserPrivateDataService extends DbDocumentService<UserPrivateData> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, UserPrivateData);
  }

  ///////////////////////////////////////////////////////////////////////
  // Get One User Data
  ///////////////////////////////////////////////////////////////////////

  public getUserPrivateData(takeOne = true): Observable<UserPrivateData | undefined> {
    const observable = this.getInjectedAuthService()
      .getUserId()
      .pipe(switchMap((userId: string, _: number) => this.getDoc(userId, undefined, takeOne)));

    return observable;
  }
}
