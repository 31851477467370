import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isNil } from 'lodash';

import { EntityStatsDisplayWindow, getUsageDisplayData, Usage } from '@pwp-common';

@Component({
  selector: 'app-all-usage',
  templateUrl: './all-usage.component.html',
  styleUrls: ['./all-usage.component.css'],
})
export class AllUsageComponent implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() usage: Usage[];

  @Input() window: EntityStatsDisplayWindow;

  traces: any = [];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if ('usage' in changes || 'window' in changes) {
      this.refreshData();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Data
  /////////////////////////////////////////////////////////////////////////////////////////////

  private refreshData() {
    this.resetData();
    if (isNil(this.usage) || isNil(this.window)) {
      return;
    }

    for (const [usageCategory, labeledData] of getUsageDisplayData(this.usage, this.window).entries()) {
      this.traces.push({
        x: labeledData.getLabels(),
        y: labeledData.getData(),
        type: 'line',
        name: usageCategory,
      });
    }
    this.changeDetectorRef.detectChanges();
  }

  private resetData() {
    this.traces = [];
  }
}
