import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-analytics-dashboard',
  templateUrl: './analytics-dashboard.component.html',
  styleUrls: ['./analytics-dashboard.component.css'],
})
export class AnalyticsDashboardComponent implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs / Outputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() lastRefreshTime: moment.Moment;

  @Input() dashboardTemplate: TemplateRef<any>;

  @Input() dashboardConfigTemplate: TemplateRef<any>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit() {}
}
