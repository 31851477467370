import { Directive, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { cloneDeep, isEqual, isNil } from 'lodash';

import { KVPair } from '../../../common/objects/kvpair';
import { NgChanges } from '../../../common/objects/ng-changes';

@Directive()
export abstract class ObjListEditor<T> implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Input / Output
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() objList: T[];

  @Output() objListChange = new EventEmitter<T[]>();

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  selectedKVPair: KVPair<T>;

  lastEmittedValue: T[];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  protected isValidList(): boolean {
    if (isNil(this.objList)) {
      return false;
    }
    return true;
  }

  ngOnChanges(changes: NgChanges<ObjListEditor<any>>) {
    if (!isNil(changes.objList)) {
      // The selectedKVPair doesn't make sense anymore. Reset it.
      this.selectedKVPair = undefined;
      this.updateAndEmit();
    }
  }

  updateAndEmit() {
    // Update the list with the current value of selectedKVPair
    if (!isNil(this.selectedKVPair?.id) && !isNil(this.selectedKVPair?.value)) {
      this.objList[this.selectedKVPair!.id as number] = this.selectedKVPair!.value;
    }

    // Don't emit if invalid
    if (!this.isValidList()) {
      return;
    }

    if (isEqual(this.objList, this.lastEmittedValue)) {
      return;
    }

    this.objListChange.emit(this.objList);
    this.lastEmittedValue = cloneDeep(this.objList);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract methods
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  abstract objBuilder(): T;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callbacks
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  doDelete = () => this.deleteClick();

  deleteClick() {
    if (this.selectedKVPair?.id === undefined) {
      return;
    }
    this.objList.splice(this.selectedKVPair!.id as number, 1);
    this.selectedKVPair = undefined;
    this.updateAndEmit();
  }

  duplicateClick() {
    if (this.selectedKVPair?.value === undefined) {
      return;
    }
    this.objList.push(cloneDeep(this.selectedKVPair!.value));
    this.updateAndEmit();
  }

  newClick() {
    const newObj = this.objBuilder();

    if (isNil(this.objList)) {
      this.objList = [];
    }
    // Make new orderedItems
    this.objList.push(newObj);
    this.selectedKVPair = new KVPair({ id: this.objList.length - 1, value: newObj });
    this.updateAndEmit();
  }
}
