import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isEmpty, isNil } from 'lodash';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

import { CommunicationSessionId } from '@pwp-common';

import { CommunicationWidgetCreateAsyncServiceRequestService } from '../../../../services/communication/communication-widget/communication-widget-create-async-service-request/communication-widget-create-async-service-request.service';
import { CreateCallbackRequestComponent } from '../create-callback-request/create-callback-request.component';
import { CreateCallbackRequestInput, CreateCallbackRequestResult } from '../create-callback-request/interfaces';

@UntilDestroy()
@Component({
  selector: 'app-create-callback-request-button',
  templateUrl: './create-callback-request-button.component.html',
  styleUrls: ['./create-callback-request-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService, MessageService],
})
export class CreateCallbackRequestButtonComponent implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////

  @Input() communicationSessionId: CommunicationSessionId;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  widgetsLoading = true;

  hasWidgets = false;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private communicationWidgetCreateAsyncServiceRequestService: CommunicationWidgetCreateAsyncServiceRequestService,
    private dialogService: DialogService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    this.communicationWidgetCreateAsyncServiceRequestService
      .getAllDocsOfType({ limit: 1 })
      .pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.widgetsLoading = false;
        if (!isEmpty(result)) {
          this.hasWidgets = true;
        }
        this.changeDetectorRef.detectChanges();
      });
  }

  ///////////////////////////////////////////////////////////////////////
  // Toggle Handled
  ///////////////////////////////////////////////////////////////////////
  buttonClick() {
    const data: CreateCallbackRequestInput = {
      sessionId: this.communicationSessionId,
    };

    const dialog = this.dialogService.open(CreateCallbackRequestComponent, {
      data,
      header: this.translocoService.translate('create-callback-request-dialog.title'),
      width: '75%',
    });
    dialog.onClose.pipe(untilDestroyed(this)).subscribe((result: CreateCallbackRequestResult | undefined) => {
      if (!isNil(result)) {
        this.messageService.add(result.message);
      }
    });
    this.changeDetectorRef.detectChanges();
  }
}
