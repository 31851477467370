import {DBMatchRuleNameType} from '../db-match-rule-name';
import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../generic/serialization/serializable-object-schema';
import {SerializableObject} from '../../../../../generic/serialization/serializable-object';

export abstract class DBMatchRule extends SerializableObject {
  protected type!: DBMatchRuleNameType;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract DBMatchRule');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): DBMatchRuleNameType {
    return cloneDeep(this.type);
  }

  /////////////////////////////////////////////////////////////////////////////
  // QueryIds
  /////////////////////////////////////////////////////////////////////////////

  public abstract getQueryIds(): string[];
}
