import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, OnChanges, OnInit } from '@angular/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { LetModule } from '@ngrx/component';
import { isNil } from 'lodash';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatest, filter, map, ReplaySubject, share, switchMap, tap } from 'rxjs';

import { CallLogDisplay, CommunicationSessionId, getCallListPIIRetentionDuration, isPiiRetained, OrgData, UserData } from '@pwp-common';

import { CommunicationDisplayAlertType } from '../../../../../../../common/src/objects/core/communication/communication-display-alert-type';
import { NgChanges } from '../../../common/objects/ng-changes';
import { DialedCallLogService } from '../../../services/call/dialed-call-log/dialed-call-log.service';
import { InboundCallSessionEventService } from '../../../services/call/inbound-call-log-session-event/inbound-call-session-event.service';
import { RolesService } from '../../../services/user/roles/roles.service';
import { BlockedCallerComponent } from '../../call/blocked-caller/blocked-caller.component';
import { CallerInfoDialogData } from '../../call/caller-info-dialog/caller-info-dialog-data';
import { CallerInfoDialogComponent } from '../../call/caller-info-dialog/caller-info-dialog.component';
import { CommunicationWidgetModule } from '../../core/communication-widget/communication-widget.module';
import { SubmitFormButtonComponent } from '../../form/submit-form-button/submit-form-button.component';
import { CommunicationLogsSessionHuntComponent } from '../communication-logs-session-hunt/communication-logs-session-hunt.component';
import { DialOngoingCallDialogComponent } from '../dial-ongoing-call-dialog/dial-ongoing-call-dialog.component';
import { makeTableRows } from '../table-rows/make-table-rows/make-table-rows';

@Component({
  selector: 'app-communication-log-detail-call',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    CommunicationLogsSessionHuntComponent,
    CommunicationWidgetModule,
    LetModule,
    MatDialogModule,
    SubmitFormButtonComponent,
    TranslocoModule,
  ],
  templateUrl: './communication-log-detail-call.component.html',
  styleUrls: ['./communication-log-detail-call.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class CommunicationLogDetailCallComponent implements OnInit, OnChanges {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);

  private readonly dialedCallLogService = inject(DialedCallLogService);

  private readonly dialog = inject(MatDialog);

  private readonly dialogService = inject(DialogService);

  private readonly inboundCallSessionEventService = inject(InboundCallSessionEventService);

  private readonly rolesService = inject(RolesService);

  private readonly translocoService = inject(TranslocoService);

  private readonly changes = new ReplaySubject<void>(1);

  protected communicationSessionId?: CommunicationSessionId;

  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////

  @Input() public orgData: OrgData;

  @Input() public callLogDisplay: CallLogDisplay;

  @Input() public allUserDataMap: Map<string, UserData>;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  public dataExists = false;

  public showGetCallerPIIButton = false;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  public readonly isActive$ = this.changes.pipe(map(() => this.callLogDisplay?.alertType === CommunicationDisplayAlertType.inProgress));

  public readonly userRoles$ = this.rolesService.getRolesMap();

  public readonly tableRows$ = this.changes.pipe(
    filter(() => !isNil(this.callLogDisplay?.id)),
    tap(() => {
      this.dialedCallLogService.setSessionId(this.callLogDisplay.id);
      this.inboundCallSessionEventService.setSessionId(this.callLogDisplay.id);
    }),
    switchMap(() =>
      combineLatest([
        this.dialedCallLogService.getDocsArray({ takeOne: false }),
        this.inboundCallSessionEventService.getDocsArray({ takeOne: false }),
      ]),
    ),
    map(([dialedCallLogs, inboundCallSessionEvents]) =>
      makeTableRows({
        allDataUsers: this.allUserDataMap,
        dialedCallLogs,
        communicationSessionEvents: inboundCallSessionEvents,
        timezone: this.orgData.getTimezone(),
      }),
    ),
    share(),
  );

  private updateCommunicationSessionId() {
    this.communicationSessionId = new CommunicationSessionId({ inboundCallSessionId: this.callLogDisplay?.id });
  }

  public ngOnInit(): void {
    this.changes.next();
  }

  public ngOnChanges(changes: NgChanges<CommunicationLogDetailCallComponent>) {
    this.updateButtonStates();

    if (changes.callLogDisplay?.currentValue) {
      this.updateCommunicationSessionId();
    }
  }

  ///////////////////////////////////////////////////////////////////////
  // Button State
  ///////////////////////////////////////////////////////////////////////

  public updateButtonStates() {
    this.dataExists = isPiiRetained({
      objectCreateTime: this.callLogDisplay?.callLog?.getIncomingCallReceivedTime(),
      piiRetentionDuration: getCallListPIIRetentionDuration(this.callLogDisplay?.callList),
    });

    this.showGetCallerPIIButton = !isNil(this.orgData) && this.dataExists;
    this.changeDetectorRef.detectChanges();
  }

  ///////////////////////////////////////////////////////////////////////
  // Blocked Caller
  ///////////////////////////////////////////////////////////////////////

  public openBlockedCallerDialog() {
    this.dialog.open(BlockedCallerComponent, {
      hasBackdrop: true,
      minWidth: '80%',
      data: {
        callLog: this.callLogDisplay.callLog,
      },
    });
  }

  ///////////////////////////////////////////////////////////////////////
  // Caller PII
  ///////////////////////////////////////////////////////////////////////

  public showCallerPIIDialog() {
    this.dialogService.open(CallerInfoDialogComponent, {
      data: {
        callLog: this.callLogDisplay?.callLog,
        callList: this.callLogDisplay?.callList,
        orgData: this.orgData,
      } as CallerInfoDialogData,
      width: '75%',
      dismissableMask: true,
    });

    // Display data in a dialog
    this.changeDetectorRef.detectChanges();
  }

  public openDialOngoingCallDialog(): void {
    this.dialogService.open(DialOngoingCallDialogComponent, {
      width: 'clamp(32rem, 50%, 64rem)',
      header: this.translocoService.translate('dial-ongoing-call-dialog.title'),
      data: {
        callLogDisplay: this.callLogDisplay,
      },
    });
  }
}
