import { isNil } from 'lodash';
import moment from 'moment-timezone';

import { AllDataUser, CallList, CallLog, displayTime, SupportedLanguages } from '@pwp-common';


import { userDataDisplayFormat } from '../../../../common/objects/user-data-display-format';
import { RowCell } from '../../../../common/p-table/row-cell';
import { getInboxRowHandleStatus } from '../inbox-row-handle-status/get-inbox-row-handle-status/get-inbox-row-handle-status';
import { InboxRowHandleStatus } from '../inbox-row-handle-status/inbox-row-handle-status';

import { InboxRowStatus } from './inbox-row-status';
import { InboxRowType } from './inbox-row-type';
import { InboxRowConstructor } from './interfaces';

export abstract class InboxRow {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  public rowType: InboxRowType;

  public id: string;

  public day: RowCell<Date>;

  public docId: RowCell<string>;

  public callType: RowCell<string>;

  public handledBy: RowCell<string>;

  public language: RowCell<string>;

  public status: RowCell<string>;

  public time: RowCell<Date>;

  public type: RowCell<InboxRowType>;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  protected constructor(public inboxRowParams: InboxRowConstructor) {
    this.setDocId(inboxRowParams.id);
    this.setCallType(inboxRowParams.callLog, inboxRowParams.callLists);
    this.setHandledBy(inboxRowParams.handledByUserId, inboxRowParams.allUserDataMap);
    this.setLanguage(inboxRowParams.callLog);
    this.setStatus(inboxRowParams.status);
    this.setTimestamps(inboxRowParams.timestamp, inboxRowParams.orgData.getTimezone());
    this.setType(inboxRowParams.inboxRowType);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Set Id
  //////////////////////////////////////////////////////////////////////////////////////////

  private setDocId(id: string) {
    this.id = id;
    this.docId = new RowCell<string>({
      translationScope: 'inbox-row',
      translationKey: 'cellId',
      translationObj: {
        id,
      },
      sortValue: id,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Set Call Type
  //////////////////////////////////////////////////////////////////////////////////////////

  private setCallType(callLog: CallLog, callLists: Map<string, CallList>) {
    const callList = callLists.get(callLog?.getCallListId());
    if (isNil(callLog)) {
      this.callType = new RowCell<string>({
        translationScope: 'inbox-row',
        translationKey: 'cellCallTypeCallLogDeleted',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }
    if (isNil(callList)) {
      this.callType = new RowCell<string>({
        translationScope: 'inbox-row',
        translationKey: 'cellCallTypeMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }
    this.callType = new RowCell<string>({
      translationScope: 'inbox-row',
      translationKey: 'cellCallType',
      translationObj: {
        callType: callList.getDisplayName(),
      },
      sortValue: callList.getDisplayName(),
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Set Handled by
  //////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Format the assigned to userId string as a user display name.
   */
  private setHandledBy(handledByUserId: string, allUserDataMap: Map<string, AllDataUser>) {
    const status = getInboxRowHandleStatus({
      handledByUserId,
      allDataUserMap: allUserDataMap,
    });
    const displayName = userDataDisplayFormat(allUserDataMap.get(handledByUserId ?? 'missing')?.userData);

    const handledBy = {
      translationScope: 'inbox-row',
      translationKey: '',
      translationObj: {
        displayName: displayName ?? '',
      },
      sortValue: displayName,
      styleClass: undefined,
    };

    switch (status) {
      case InboxRowHandleStatus.notHandled: {
        this.handledBy = new RowCell<string>({
          ...handledBy,
          translationKey: 'cellNotHandled',
          sortValue: InboxRowHandleStatus.notHandled,
          styleClass: 'statusBadge notHandled',
        });
        return;
      }
      case InboxRowHandleStatus.handledByDeletedUser: {
        this.handledBy = new RowCell<string>({
          ...handledBy,
          translationKey: 'cellHandledByDeletedUser',
          sortValue: handledByUserId,
          styleClass: 'statusBadge handled',
        });
        return;
      }
      case InboxRowHandleStatus.handledBySelf: {
        this.handledBy = new RowCell<string>({
          ...handledBy,
          translationKey: 'cellHandledByUser',
          styleClass: 'statusBadge handled',
        });
        return;
      }
      case InboxRowHandleStatus.handledByOtherUser: {
        this.handledBy = new RowCell<string>({
          ...handledBy,
          translationKey: 'cellHandledByUser',
          styleClass: 'statusBadge handled',
        });
        
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Set Language
  //////////////////////////////////////////////////////////////////////////////////////////

  private setLanguage(callLog: CallLog) {
    if (isNil(callLog)) {
      this.language = new RowCell<string>({
        translationScope: 'inbox-row',
        translationKey: 'cellLanguageCallLogDeleted',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    const languageDefaults = SupportedLanguages.getDefaults(callLog.getLanguage());
    this.language = new RowCell<string>({
      translationScope: 'inbox-row',
      translationKey: 'cellLanguage',
      translationObj: {
        language: languageDefaults.getDisplayName(),
      },
      sortValue: languageDefaults.getDisplayName(),
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Set Status by
  //////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Format the assigned to userId string as a user display name.
   */
  private setStatus(status: InboxRowStatus) {
    switch (status) {
      case InboxRowStatus.open: {
        this.status = new RowCell<string>({
          translationScope: 'inbox-row',
          translationKey: 'cellStatusOpen',
          translationObj: {},
          sortValue: InboxRowStatus.open,
          styleClass: 'statusBadge open',
        });
        return;
      }
      case InboxRowStatus.closed: {
        this.status = new RowCell<string>({
          translationScope: 'inbox-row',
          translationKey: 'cellStatusClosed',
          translationObj: {},
          sortValue: InboxRowStatus.closed,
          styleClass: 'statusBadge closed',
        });
        return;
      }
      case InboxRowStatus.redacted: {
        this.status = new RowCell<string>({
          translationScope: 'inbox-row',
          translationKey: 'cellStatusRedacted',
          translationObj: {},
          sortValue: InboxRowStatus.redacted,
          styleClass: 'statusBadge closed',
        });
        return;
      }
      default: {
        this.status = new RowCell<string>({
          translationScope: 'inbox-row',
          translationKey: 'cellStatusClosed',
          translationObj: {},
          sortValue: InboxRowStatus.closed,
          styleClass: 'statusBadge closed',
        });
        console.error('InboxRow: Unknown status');
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Set Timestamps
  //////////////////////////////////////////////////////////////////////////////////////////

  private setTimestamps(timestamp: moment.Moment, timezone: string) {
    /**
     * Check for nil
     */
    if (isNil(timestamp)) {
      this.day = new RowCell<Date>({
        translationScope: 'inbox-row',
        translationKey: 'cellDayMissing',
        translationObj: {},
        sortValue: undefined,
      });
      this.time = new RowCell<Date>({
        translationScope: 'inbox-row',
        translationKey: 'cellTimeMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    /**
     * Display date & time
     */
    const dayFormatted = timestamp.tz(timezone).format('l');
    const timeFormatted = displayTime(timestamp, timezone, { timeOnly: true });
    this.day = new RowCell<Date>({
      translationScope: 'inbox-row',
      translationKey: 'cellDay',
      translationObj: {
        timestamp: dayFormatted,
      },
      sortValue: timestamp.toDate(),
    });
    this.time = new RowCell<Date>({
      translationScope: 'inbox-row',
      translationKey: 'cellTime',
      translationObj: {
        timestamp: timeFormatted,
      },
      sortValue: timestamp.toDate(),
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Set Type
  //////////////////////////////////////////////////////////////////////////////////////////

  private setType(type: InboxRowType) {
    this.rowType = type;
    switch (type) {
      case InboxRowType.voicemail: {
        this.type = new RowCell<InboxRowType>({
          translationScope: 'inbox-row',
          translationKey: 'cellTypeVoicemail',
          translationObj: {},
          sortValue: InboxRowType.voicemail,
        });
        return;
      }
      case InboxRowType.callbackRequest: {
        this.type = new RowCell<InboxRowType>({
          translationScope: 'inbox-row',
          translationKey: 'cellTypeCallbackRequest',
          translationObj: {},
          sortValue: InboxRowType.callbackRequest,
        });
        
      }
    }
  }
}
