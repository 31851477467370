import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { DataTableComponent } from './data-table/data-table.component';
import { DataTableCaptionComponent } from './data-table-caption/data-table-caption.component';
import { DataTableCellDirective } from './data-table-column-def/data-table-cell/data-table-cell.directive';
import { DataTableColumnDefDirective } from './data-table-column-def/data-table-column-def.directive';
import { DataTableHeaderDirective } from './data-table-column-def/data-table-header/data-table-header.directive';
import { RowCellComponent } from './row-cell/row-cell.component';

@NgModule({
  declarations: [DataTableCaptionComponent, DataTableComponent, RowCellComponent],
  imports: [
    AutoCompleteModule,
    ButtonModule,
    CommonModule,
    DataTableCellDirective,
    DataTableColumnDefDirective,
    DataTableHeaderDirective,
    FormsModule,
    InputTextModule,
    MatButtonModule,
    MatIconModule,
    MultiSelectModule,
    SharedModule,
    TableModule,
    ToastModule,
    TooltipModule,
    TranslocoModule,
  ],
  exports: [DataTableCellDirective, DataTableColumnDefDirective, DataTableComponent, DataTableHeaderDirective],
})
export class DataTableModule {}
