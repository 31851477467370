import {SerializableObjectSchema} from '../../../../../../generic/serialization/serializable-object-schema';
import {CommunicationWorkflowTargetTimeoutName} from '../../generic/communication-workflow-target-timeout-name';
import {CommunicationWorkflowTargetTimeout} from '../../generic/communication-workflow-target-timeout/communication-workflow-target-timeout';
import {CommunicationWorkflowTargetTimeoutSchema} from '../../generic/communication-workflow-target-timeout/communication-workflow-target-timeout-schema';
import {CommunicationWorkflowTargetTimeoutFixedConstructor} from './communication-workflow-target-timeout-fixed-constructor';
import {CommunicationWorkflowTargetTimeoutFixedSchema} from './communication-workflow-target-timeout-fixed-schema';
import {cloneDeep} from 'lodash';

export class CommunicationWorkflowTargetTimeoutFixed extends CommunicationWorkflowTargetTimeout {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected timeoutMS!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWorkflowTargetTimeoutFixedConstructor) {
    (parameters as any)[CommunicationWorkflowTargetTimeoutSchema.type] = CommunicationWorkflowTargetTimeoutName.fixed;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationWorkflowTargetTimeoutFixed {
    return new CommunicationWorkflowTargetTimeoutFixed(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationWorkflowTargetTimeoutFixed.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationWorkflowTargetTimeoutFixedSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getTimeoutMS(): number {
    return cloneDeep(this.timeoutMS);
  }
}
