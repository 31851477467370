import { SurveyCreatorModel } from 'survey-creator-core';

const propertiesToHide = [
  'logo',
  'logoWidth',
  'logoHeight',
  'logoFit',
  'logoPosition',
  'navigateToUrl',
  'navigateToUrlOnCondition',
  'expression',
  'url',
  'showCompletedPage',
  'completedHtml',
  'completedHtmlOnCondition',
  'expression',
  'html',
  'loadingHtml',
  'completedBeforeHtml',
  'maxTimeToFinish',
  'maxTimeToFinishPage',
  'showTimerPanel',
  'showTimerPanelMode',
];

export const hideSurveyCreatorPropertiesFromGrid = (model: SurveyCreatorModel) => {
  model.onCanShowProperty.add((sender, options) => {
    Object.assign(options, { canShow: !propertiesToHide.includes(options.property.name) });
  });
};
