<ng-container *transloco="let t; read: 'form-submission-dialog'">
  <ng-container *ngIf="stylesLoaded$ | async">
    <app-form-autocomplete [formControl]="selectedForm" [placeholder]="t('selectFormAutocompleteLabel')"></app-form-autocomplete>
    <survey *ngIf="selectedFormModel$ | async as model; else selectModel" [model]="model"></survey>

    <ng-template #selectModel>
      <div class="form-submission-dialog__content">{{ t('selectFormToStart') }}</div>
    </ng-template>
  </ng-container>
</ng-container>
