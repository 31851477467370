import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {EventType} from '../event-type/event-type';

export class OrgDataSchema extends DBDocSchema {
  // Display Name
  public static readonly displayName = 'displayName';

  // Metadata of event types that users of this org may sign up for
  public static readonly eventTypes = 'eventTypes';

  // Calls from this org will be forwarded from this list of phones
  public static readonly e164Phones = 'e164Phones';

  // Timezone
  public static readonly timezone = 'timezone';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'orgData';
  };

  static Defaults = class {
    public static readonly displayName = 'MISSING-displayName';
    public static readonly eventTypes = [EventType.deserialize({})];
    public static readonly e164Phones = ['0000000000'];
    public static readonly timezone = 'America/Los_Angeles';
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([OrgDataSchema.timezone]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[OrgDataSchema.displayName] = SchemaField.string(OrgDataSchema.Defaults.displayName);
    parameters[OrgDataSchema.eventTypes] = SchemaField.shortObjArray(EventType, OrgDataSchema.Defaults.eventTypes);
    parameters[OrgDataSchema.e164Phones] = SchemaField.shortStringArray(OrgDataSchema.Defaults.e164Phones);
    parameters[OrgDataSchema.timezone] = SchemaField.string(OrgDataSchema.Defaults.timezone);

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(): string {
    return `${DBDocSchema.GenericCollections.orgs}`;
  }
}
