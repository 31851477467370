import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isNil } from '@ngneat/transloco';

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export class CommaSepListValidator {
  public static email(): ValidatorFn {
    return CommaSepListValidator.regex(emailRegex);
  }

  public static regex(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const {value} = control;

      const invalidComponents: string[] = [];
      for (const component of CommaSepListValidator.getComponents(value)) {
        if (isNil(component.match(regex))) {
          invalidComponents.push(component);
        }
      }

      if (invalidComponents.length > 0) {
        return { invalidComponents };
      }

      return null;
    };
  }

  public static noDuplicates(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const {value} = control;
      const components = CommaSepListValidator.getComponents(value);
      const uniques = new Set(components);
      if (uniques.size !== components.length) {
        return { num: components.length, numUnique: uniques.size };
      }

      return null;
    };
  }

  private static getComponents(value: string): string[] {
    if (value.trim().length === 0) {
      return [];
    }
    return value
      .trim()
      .split(',')
      .map((z) => z.trim());
  }
}
