<ng-container *transloco="let t; read: 'caller-id-autocomplete'">
  <app-obj-autocomplete-form-control
    id="callerId"
    [dropdownAriaLabel]="t('dropdownAriaLabel')"
    [formControl]="control"
    [options$]="allOptions$"
    [placeholder]="placeholder"
  >
  </app-obj-autocomplete-form-control>
</ng-container>
