import { AbstractControl, ValidatorFn } from '@angular/forms';

export class EqualsValidator {
  public static equalsValidator(expectedValue: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = expectedValue === control.value;

      return !valid ? { equals: { value: control.value } } : null;
    };
  }
}
