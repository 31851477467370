<ng-container *transloco="let t; read: 'service-command-editor-forward'">
  <p-messages severity="info">
    <ng-template pTemplate>
      {{ t('description') }}
    </ng-template>
  </p-messages>
  <label class="service-command-editor-forward__form-field">
    <span>{{ t('toPhoneNumberLabel') }}</span>
    <app-phone-editor [formControl]="form.controls.to"></app-phone-editor>
  </label>
</ng-container>
