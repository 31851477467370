import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CallList } from '@pwp-common';

import { CallListService } from '../../../../../services/call/call-list/call-list.service';
import {
  getObjAutocompleteFormControlBaseProviders,
  ObjAutocompleteFormControlBase,
} from '../../../../generic/abstract-classes/obj-autocomplete-form-control-base';

@UntilDestroy()
@Component({
  selector: 'app-call-list-autocomplete ',
  templateUrl: './call-list-autocomplete.component.html',
  styleUrls: ['./call-list-autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...getObjAutocompleteFormControlBaseProviders(CallListAutocompleteComponent)],
})
export class CallListAutocompleteComponent extends ObjAutocompleteFormControlBase<CallList> implements OnInit {
  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public callListService: CallListService,
  ) {
    super(changeDetectorRef);
  }

  defineAllOptions() {
    return this.callListService.getDocsArray();
  }
}
