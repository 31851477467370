import type {ValidationResult} from 'joi';
import {SerializableObject} from '../../../../generic/serialization/serializable-object';
import {NoteBodyText} from '../../type/note-body-text/note-body-text';
import {NoteBodyType} from '../note-body-type';
import {NoteBody} from '../note-body/note-body';

import {AnyNoteBodySchema} from './any-note-body-schema';

export abstract class AnyNoteBody extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  public static _deserialize(validationResult: ValidationResult): NoteBody {
    const typeString = validationResult.value[AnyNoteBodySchema.type] as NoteBodyType;
    const type = NoteBodyType[typeString];

    if (type === undefined) {
      throw new Error(`AnyNoteBody._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (type) {
      case NoteBodyType.text: {
        return new NoteBodyText(validationResult.value);
      }
      default: {
        throw new Error(`AnyNoteBody._deserialize Unknown type: ${type}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): AnyNoteBodySchema {
    return new AnyNoteBodySchema();
  }
}
