import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { SharedModule } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { SelectButtonModule } from 'primeng/selectbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

import { SharedDirectiveModule } from '../../../directives/shared-directive.module';
import { AccordionWizardModule } from '../../generic/accordion-wizard/accordion-wizard.module';
import { DataTableModule } from '../../generic/data-table/data-table.module';
import { ObjAutocompleteV2Module } from '../../generic/obj-autocomplete-v2/obj-autocomplete-v2.module';
import { SequenceModule } from '../../generic/sequence/sequence.module';
import { WizardModule } from '../../generic/wizard/wizard.module';
import { ServiceOperationEditorModule } from '../service-operation-editor/service-operation-editor.module';
import { WorkersAutocompleteModule } from '../workers-autocomplete/workers-autocomplete.module';

//Components
import { CommunicationWorkflowAutocompleteComponent } from './communication-workflow-autocomplete/communication-workflow-autocomplete.component';
import { CommunicationWorkflowEditorDialogComponent } from './communication-workflow-editor-dialog/communication-workflow-editor-dialog.component';
import { CommunicationWorkflowTableEditorExpansionComponent } from './editor-table/communication-workflow-table-editor-expansion/communication-workflow-table-editor-expansion.component';
import { CommunicationWorkflowTableEditorComponent } from './editor-table/communication-workflow-table-editor/communication-workflow-table-editor.component';
import { CommunicationWorkflowEditorComponent } from './editor/communication-workflow-editor/communication-workflow-editor.component';
import { GeneralTabEditorComponent } from './editor/general-tab-editor/general-tab-editor.component';
import { ReservationOfferChannelConfigComponent } from './editor/targets-tab-editor/reservation-offer-channel-config/reservation-offer-channel-config.component';
import { TargetEditorComponent } from './editor/targets-tab-editor/target-editor/target-editor.component';
import { TargetModeEditorComponent } from './editor/targets-tab-editor/target-mode-editor/target-mode-editor.component';
import { TargetOffersEditorComponent } from './editor/targets-tab-editor/target-offers-editor/target-offers-editor.component';
import { TargetOffersSkipIfEditorComponent } from './editor/targets-tab-editor/target-offers-skip-if-editor/target-offers-skip-if-editor.component';
import { TargetsEditorComponent } from './editor/targets-tab-editor/targets-editor/targets-editor.component';

@NgModule({
  declarations: [
    CommunicationWorkflowAutocompleteComponent,
    CommunicationWorkflowEditorComponent,
    CommunicationWorkflowEditorDialogComponent,
    CommunicationWorkflowTableEditorComponent,
    CommunicationWorkflowTableEditorExpansionComponent,
    GeneralTabEditorComponent,
    ReservationOfferChannelConfigComponent,
    TargetEditorComponent,
    TargetModeEditorComponent,
    TargetOffersEditorComponent,
    TargetOffersSkipIfEditorComponent,
    TargetsEditorComponent,
  ],
  imports: [
    AccordionModule,
    AccordionWizardModule,
    AutoCompleteModule,
    ButtonModule,
    CardModule,
    CommonModule,
    DataTableModule,
    DropdownModule,
    FormsModule,
    InputNumberModule,
    InputTextareaModule,
    InputTextModule,
    MatButtonModule,
    MatIconModule,
    MessagesModule,
    ObjAutocompleteV2Module,
    ReactiveFormsModule,
    SelectButtonModule,
    SequenceModule,
    ServiceOperationEditorModule,
    SharedDirectiveModule,
    SharedModule,
    StepsModule,
    TableModule,
    ToastModule,
    TranslocoModule,
    WizardModule,
    WorkersAutocompleteModule,
  ],
  exports: [CommunicationWorkflowAutocompleteComponent, CommunicationWorkflowEditorComponent, CommunicationWorkflowTableEditorComponent],
})
export class CommunicationWorkflowModule {}
