<ng-container *transloco="let t; read: 'call-list-editor'">
  <form [formGroup]="form">
    <div class="grid-nogutter">
      <div class="grid-nogutter p-fluid">
        <!-- Display Name-->
        <div class="field mt-3">
          <span class="p-float-label">
            <input pInputText id="displayName" type="text" [formControl]="displayName" />
            <label for="displayName">{{ t('displayNameLabel') }}</label>
          </span>
          <div id="displayName-help">
            <p *ngIf="displayName.errors?.required" class="p-error">{{ t('requiredError') }}</p>
            <p *ngIf="displayName.errors?.minlength" class="p-error">{{ t('minLengthError', displayName.errors?.minlength) }}</p>
            <p *ngIf="displayName.errors?.maxlength" class="p-error">{{ t('maxLengthError', displayName.errors?.maxlength) }}</p>
          </div>
        </div>

        <!-- Description-->
        <div class="field mt-4">
          <span class="p-float-label">
            <textarea pInputTextarea class="" id="description" rows="2" [formControl]="description"> </textarea>
            <label for="description">{{ t('descriptionLabel') }}</label>
          </span>
          <div id="description-help">
            <p *ngIf="description.errors?.required" class="p-error">{{ t('requiredError') }}</p>
            <p *ngIf="description.errors?.minlength" class="p-error">{{ t('minLengthError', description.errors?.minlength) }}</p>
            <p *ngIf="description.errors?.maxlength" class="p-error">{{ t('maxLengthError', description.errors?.maxlength) }}</p>
          </div>
        </div>

        <!-- Callee Entities-->
        <div class="field mt-3">
          <app-workers-autocomplete
            class="mt-3"
            [formControl]="calleeEntities"
            [label]="t('calleeEntitiesLabel')"
            [ngClass]="{ 'ng-invalid': calleeEntities?.invalid }"
            [unique]="false"
          >
          </app-workers-autocomplete>
          <div id="calleeEntities-help">
            <p *ngIf="calleeEntities.errors?.required" class="p-error">{{ t('requiredError') }}</p>
            <p *ngIf="calleeEntities.errors?.minlength" class="p-error">{{ t('minLengthError', calleeEntities.errors?.minlength) }}</p>
            <p *ngIf="calleeEntities.errors?.maxlength" class="p-error">
              {{ t('maxLengthCalleeEntitiesError', calleeEntities.errors?.maxlength) }}
            </p>
          </div>
        </div>

        <!-- Offer Config-->
        <p-accordion class="field mb-4">
          <p-accordionTab [header]="t('callListOfferConfigLabel')">
            <app-caller-id-strategy-editor [formControl]="callerId"></app-caller-id-strategy-editor>
          </p-accordionTab>
        </p-accordion>

        <!-- Ring Time-->
        <p-accordion class="field mb-4">
          <p-accordionTab [header]="t('ringTimeLabel')">
            <app-offer-config-ring-time-editor [formControl]="ringTime"> </app-offer-config-ring-time-editor>
          </p-accordionTab>
        </p-accordion>
      </div>
    </div>
  </form>
</ng-container>
