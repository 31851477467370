/**
 * These statuses correspond 1:1 with the MessageStatus field in Twilio's MessageStatusCallback. They have been converted
 * from british to American English and to camelCase to be consistent with the rest of the codebase.
 *
 * These values are serialized to the database.
 * Possible call statuses are documented here:
 * https://www.twilio.com/docs/sms/api/message-resource#appendix
 */
export enum OperatorMessageStatus {
  /**
   * Twilio has received your API request to send a message with a
   * Messaging Service and a From number is being dynamically selected.
   * This will be the initial status when sending with a Messaging
   * Service and the From parameter.
   */
  accepted = 'accepted',
  /**
   * The message is scheduled to be sent. This will be the initial
   * status when scheduling a message with a Messaging Service
   */
  scheduled = 'scheduled',
  /**
   * The API request to send a message was successful and the
   * message is queued to be sent out. This will be the initial
   * status when you are not using a Messaging Service
   */
  queued = 'queued',
  /**
   * Twilio is in the process of dispatching your message to the
   * nearest upstream carrier in the network.
   */
  sending = 'sending',
  /**
   * The nearest upstream carrier accepted the message.
   */
  sent = 'sent',
  /**
   * The inbound message has been received by Twilio and is
   * currently being processed.
   */
  receiving = 'receiving',
  /**
   * On inbound messages only. The inbound message was received by
   * one of your Twilio numbers.
   */
  received = 'received',
  /**
   * Twilio has received confirmation of message delivery from the
   * upstream carrier, and, where available, the destination handset.
   */
  delivered = 'delivered',
  /**
   * Twilio has received a delivery receipt indicating that the
   * message was not delivered. This can happen for many reasons
   * including carrier content filtering and the availability of
   * the destination handset.
   */
  undelivered = 'undelivered',
  /**
   * The message could not be sent. This can happen for various reasons
   * including queue overflows, account suspensions and media errors
   * (in the case of MMS). Twilio does not charge you for failed
   * messages.
   */
  failed = 'failed',
  /**
   * On WhatsApp messages only. The message has been delivered and
   * opened by the recipient in the conversation. The recipient
   * must have enabled read receipts.
   */
  read = 'read',
  /**
   * The message has been canceled. This status is only accessible
   * when using a Messaging Service
   */
  canceled = 'canceled',
}

export const ORDERED_OPERATOR_MESSAGE_SEND_STATUSES: OperatorMessageStatus[] = [
  OperatorMessageStatus.scheduled,
  OperatorMessageStatus.accepted,
  OperatorMessageStatus.queued,
  OperatorMessageStatus.sending,
  OperatorMessageStatus.sent,
  OperatorMessageStatus.delivered,
  OperatorMessageStatus.undelivered,
  OperatorMessageStatus.failed,
  OperatorMessageStatus.read,
  OperatorMessageStatus.canceled,
];
