import {values} from 'lodash';
import {CommunicationLogParticipantType} from './communication-log-participant-type';
import {SchemaField} from '../../generic/serialization/schema-field';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';

export class CommunicationLogParticipantSchema extends SerializableObjectSchema {
  public static readonly type = 'type';
  public static readonly participantId = 'participantId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly participantId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationLogParticipantSchema.type] = SchemaField.string(CommunicationLogParticipantSchema.Defaults.type).allow(...values(CommunicationLogParticipantType)).required();
    parameters[CommunicationLogParticipantSchema.participantId] = SchemaField.string(CommunicationLogParticipantSchema.Defaults.participantId).optional();
    /* eslint-enable */

    return parameters;
  }
}
