export type AvailableTimesByWeekMap = Map<number, AvailableTimesByWeek>;

export interface AvailableTimesByWeek {
  timeRange?: string;
  type: AvailableTimesType;
}

export enum AvailableTimesType {
  alwaysAvailable = 'alwaysAvailable',
  neverAvailable = 'neverAvailable',
  sometimesAvailable = 'sometimesAvailable',
}
