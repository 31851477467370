<ng-container *transloco="let t; read: 'conversation-ended-dialog'">
  <legend>{{ t('title') }}</legend>

  <p [innerHTML]="t('body', { redirectURL: data.redirectURL, timeoutSecondsRemaining })"></p>

  <button pButton class="p-button-text" label="{{ t('cancelButton') }}" type="button" (click)="cancel()"></button>
  <button
    pButton
    class="p-button-raised p-button-help"
    icon="pi pi-sign-out"
    iconPos="left"
    label="{{ t('exitButton') }}"
    type="button"
    (click)="redirectNow()"
  ></button>
</ng-container>
