import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSliderModule } from '@angular/material/slider';

import { AudioPlayerComponent } from './audio-player/audio-player.component';


@NgModule({
  declarations: [AudioPlayerComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule, MatProgressSpinnerModule, MatSliderModule],
  exports: [AudioPlayerComponent],
})
export class AudioPlayerModule {}
