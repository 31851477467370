import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isNil } from 'lodash';

import { CallList, CallStatsByType, EntityStats, EntityStatsDisplayWindow, LabeledData } from '@pwp-common';

@Component({
  selector: 'app-calls-by-type',
  templateUrl: './calls-by-type.component.html',
  styleUrls: ['./calls-by-type.component.css'],
})
export class CallsByTypeComponent implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() orgStats: EntityStats;

  @Input() window: EntityStatsDisplayWindow;

  @Input() callLists: Map<string, CallList>;

  labeledData: LabeledData;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if ('orgStats' in changes || 'window' in changes || 'callLists' in changes) {
      this.refreshData();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Data
  /////////////////////////////////////////////////////////////////////////////////////////////

  private refreshData() {
    if (isNil(this.orgStats) || isNil(this.window) || isNil(this.callLists)) {
      return;
    }
    const callStatsByType = new CallStatsByType(this.orgStats, this.callLists, this.window);
    this.labeledData = callStatsByType.getLabeledData(callStatsByType.iNum);
  }
}
