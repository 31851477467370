import moment from 'moment-timezone';
import {DBTransactionType} from '../../../db/transaction/db-transaction-type';
import {DBCreateDoc} from '../../../db/transaction/actions/db-create-doc';
import {DBUploadExistingDoc} from '../../../db/transaction/actions/db-upload-existing-doc';
import {DBTransaction} from '../../../db/transaction/db-transaction';
import {EventData} from '../event-data/event-data';
import {cloneDeep} from 'lodash';
import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';

export const getSplitTransaction = (event: EventData, splitTime: moment.Moment): DBTransaction<EventData> => {
  const splits = splitEventToArray(event, splitTime);
  const actions = [new DBUploadExistingDoc(splits[0]), new DBCreateDoc(splits[1])];
  const transaction = new DBTransaction(DBTransactionType.split, actions);
  return transaction;
};

/**
 * Resize this event so that it ends at the given time, and create a new event
 * which is starts at the splitTime and ends at the end time.
 *
 * @param event
 * @param splitTime
 * @returns
 */
export const splitEventToArray = (event: EventData, splitTime: moment.Moment): EventData[] => {
  if (splitTime.isSameOrBefore(event.getStart()) || splitTime.isSameOrAfter(event.getEnd())) {
    throw new Error('Cannot split at selected time because it is <= start or >= end');
  }
  const first = cloneDeep(event).setEnd(splitTime);
  const second = cloneDeep(event).setStart(splitTime).setId(DBDocSchema.GenericDefaults.id) as EventData;

  return [first, second];
};
