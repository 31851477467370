import {SchemaField} from '../../../objects/generic/serialization/schema-field';
import {ResponseSchema} from '../../generic/response/response-schema';

export class BlockedCallerResponseSchema extends ResponseSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly sessionId = 'sessionId';
  public static readonly expireTime = 'expireTime';
  public static readonly reason = 'reason';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class extends ResponseSchema.GenericDefaults {
    public static readonly sessionId = undefined;
    public static readonly expireTime = undefined;
    public static readonly reason = undefined;
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[BlockedCallerResponseSchema.sessionId] = SchemaField.string(BlockedCallerResponseSchema.Defaults.sessionId);
    parameters[BlockedCallerResponseSchema.expireTime] = SchemaField.timestamp(BlockedCallerResponseSchema.Defaults.expireTime);
    parameters[BlockedCallerResponseSchema.reason] = SchemaField.string(BlockedCallerResponseSchema.Defaults.reason);
    /* eslint-enable */

    return parameters;
  }
}
