import {isNil} from 'lodash';
import moment from 'moment-timezone';

import {FormatDurationOptions} from './interfaces';

const ONE_DAY_IN_MILLIS = 1 * 24 * 60 * 60 * 1000;
const HOURS_IN_MILLISECONDS = 60 * 60 * 1000;
const MINUTES_IN_MILLISECONDS = 60 * 1000;

const formatDurationWithoutDays = (milliseconds: number): string => {
  if (milliseconds === 0) {
    return '0h 0m';
  }

  const numberOfHours = milliseconds / HOURS_IN_MILLISECONDS;
  const hours = Math.floor(numberOfHours);
  const minutes = Math.floor((milliseconds % HOURS_IN_MILLISECONDS) / MINUTES_IN_MILLISECONDS);

  return `${hours}h ${minutes}m`;
};

const formatDurationWithDays = (milliseconds: number): string => {
  if (milliseconds === 0) {
    return '00:00:00';
  }

  const numberOfDays = Math.floor(milliseconds / ONE_DAY_IN_MILLIS);
  const dayString = numberOfDays > 0 ? `${numberOfDays} d ` : '';

  return `${dayString}${moment.utc(milliseconds - numberOfDays * ONE_DAY_IN_MILLIS).format('HH:mm:ss')}`;
};

export const formatDuration = (milliseconds: number | undefined, options: FormatDurationOptions = {aggregateDays: true}): string => {
  if (isNil(milliseconds)) {
    return '';
  }

  if (options.aggregateDays) {
    return formatDurationWithDays(milliseconds);
  }

  return formatDurationWithoutDays(milliseconds);
};
