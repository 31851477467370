import {cloneDeep} from 'lodash';
import {ServiceLimitExecutionsConstructor} from './service-limit-executions-constructor';
import {ServiceLimitExecutionsSchema} from './service-limit-executions-schema';
import {SerializableObject} from '../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {ServiceLimitExecution} from '../service-limit-execution/service-limit-execution';

export class ServiceLimitExecutions extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected executions!: Map<string, ServiceLimitExecution>;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceLimitExecutionsConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ServiceLimitExecutions {
    const obj = new ServiceLimitExecutions(super._deserialize(validationResult));
    return obj;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ServiceLimitExecutions.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ServiceLimitExecutionsSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getExecutions() {
    return cloneDeep(this.executions);
  }
}
