import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-accordion-wizard-step-header',
  templateUrl: './accordion-wizard-step-header.component.html',
  styleUrls: ['./accordion-wizard-step-header.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionWizardStepHeaderComponent implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  @Input() label = '';

  @Input() hasError = false;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  constructor() {}

  ngOnInit(): void {}
}
