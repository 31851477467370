import { AssignedUserType, OrgData, UserData } from '@pwp-common';

import { WorkersAutocompleteEditorOutput } from '../../workers-autocomplete-editor-output';
import { WorkerEditorOutputItem } from '../interface';
import { makeEventOption } from '../make-event-option/make-event-option';
import { makeUserOption } from '../make-user-option/make-user-option';

export const makeAllOptions = (users: UserData[], orgData: OrgData): WorkersAutocompleteEditorOutput => {
  const options: WorkerEditorOutputItem[] = [];
  for (const user of users ?? []) {
    options.push(makeUserOption(user.getId(), user));
  }

  for (const eventType of orgData?.getEventTypes() ?? []) {
    options.push(makeEventOption(AssignedUserType.primary, eventType.getInternalName(), orgData));
    options.push(makeEventOption(AssignedUserType.backup, eventType.getInternalName(), orgData));
  }

  return options;
};
