import {EntityStats} from '../entity-stats/entity-stats';
import {EntityStatsDisplayWindow} from './entity-stats-display-window/enum';
import {EntityStatsDisplay} from './entity-stats-display';

export class CallStatsFunnel extends EntityStatsDisplay {
  // Raw Counts
  public iNum = 0;
  public iNumServiceRequested = 0;
  public iNumServiceNotRequested = 0;
  public iNumAnswered = 0;
  public iNumUnexpectedlyMissed = 0;
  public iNumNoCapacity = 0;
  public iNumEnforceBlockedCaller = 0;
  public iNumClientDisconnectBeforeServiceDeliveryAttemptComplete = 0;
  public iNumClientConnectedToUser = 0;
  public iNumUnanswered = 0;

  // Raw Milliseconds
  public iMsHoldDuration = 0;

  // Percentages
  public percentServiceRequested = 0;
  public percentDialedUser = 0;
  public percentAnswered = 0;

  // Funnel
  public iNumNotBlocked = 0;
  public iNumUnblockedAndHaveCapacity = 0;
  public iNumUnblockedAndHaveCapacityAndClientNotDisconnectEarly = 0;

  // Sunspiral graph
  public answeredButNotConnectedToClient = 0;

  /**
   * Determine how basic stats (eg, answered percentage etc) vary over time.
   * @param entityStats
   * @param callLists
   * @param window
   */
  constructor(entityStats: EntityStats, window: EntityStatsDisplayWindow) {
    super(entityStats, window);

    // Set Number of Calls
    this.setStats(entityStats);
  }

  private setStats(entityStats: EntityStats) {
    for (const [month, entityStatsChunk] of entityStats.getByMonth()) {
      if (!this.isMonthContainedInWindow(month)) {
        continue;
      }

      // Set raw values
      const callStats = entityStatsChunk.getCalls();

      this.iNum += callStats.getINum();
      this.iNumServiceRequested += callStats.getINumServiceRequested();
      this.iNumServiceNotRequested += callStats.getINumServiceNotRequested();
      this.iNumAnswered += callStats.getINumAnswered();
      this.iNumUnexpectedlyMissed += callStats.getINumUnexpectedlyMissed();
      this.iNumNoCapacity += callStats.getINumNoCapacity();
      this.iNumEnforceBlockedCaller += callStats.getINumEnforceBlockedCaller();
      this.iMsHoldDuration += callStats.getIMsHoldDuration();
      this.iNumClientDisconnectBeforeServiceDeliveryAttemptComplete +=
        callStats.getINumClientDisconnectBeforeServiceDeliveryAttemptComplete();
      this.iNumClientConnectedToUser += callStats.getINumClientConnectedToUser();
      this.answeredButNotConnectedToClient += callStats.getINumAnswered() - callStats.getINumClientConnectedToUser();
    }

    // Stats
    this.iNumNotBlocked = this.iNumServiceRequested - this.iNumEnforceBlockedCaller;
    this.iNumUnblockedAndHaveCapacity =
      this.iNumServiceRequested - (this.iNumNoCapacity + this.iNumEnforceBlockedCaller);
    this.iNumUnblockedAndHaveCapacityAndClientNotDisconnectEarly =
      this.iNumUnblockedAndHaveCapacity - this.iNumClientDisconnectBeforeServiceDeliveryAttemptComplete;

    this.iNumUnanswered = this.iNumUnblockedAndHaveCapacity - this.iNumAnswered;
  }
}
