import {SchemaField} from '../../../../../../../generic/serialization/schema-field';
import {CommunicationSessionEventLogSchema} from '../../../../generic/communication-session-event-log/communication-session-event-log-schema';
import {Mixin} from 'ts-mixer';
import {values} from 'lodash';
import {CommunicationSessionEventLogSendMessageType} from '../communication-session-event-log-send-message-type';
import {SentMessageSchema} from '../../../../../../../message/sent-message/sent-message-schema';

export class CommunicationSessionEventLogSendMessageSchema extends Mixin(SentMessageSchema, CommunicationSessionEventLogSchema) {
  public static readonly messageType = 'messageType';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic InboundCallEvent Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////

    public static readonly type = undefined;
    public static readonly actor = undefined;
    public static readonly timestamp = undefined;

    ///////////////////////////////////////////////////////
    // Sent Message Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////
    public static readonly errorCode = undefined;
    public static readonly from = undefined;
    public static readonly messageSid = undefined;
    public static readonly numMedia = undefined;
    public static readonly numSegments = undefined;
    public static readonly operatorTimestamps = new Map();

    ///////////////////////////////////////////////////////
    // CommunicationSessionEventLogSentMessage Properties
    ///////////////////////////////////////////////////////

    /**
     * Tracking Info
     */
    public static readonly messageType = undefined;
    public static readonly participant = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {
      ...new CommunicationSessionEventLogSchema().getSchemaDefinition(),
      ...new SentMessageSchema().getSchemaDefinition(),
    };

    /* eslint-disable */
    /**
     * Tracking Info
     */
    parameters[CommunicationSessionEventLogSendMessageSchema.messageType] = SchemaField.string(
      CommunicationSessionEventLogSendMessageSchema.messageType,
      CommunicationSessionEventLogSendMessageSchema.Defaults.messageType
    )
      .required()
      .valid(...values(CommunicationSessionEventLogSendMessageType));
    /* eslint-enable */

    return parameters;
  }
}
