import { AbstractControl } from '@angular/forms';
import { isEqual } from 'lodash';
import { defer, distinctUntilChanged, Observable, startWith } from 'rxjs';

export const controlValues = <T>(control: AbstractControl<T>): Observable<T> =>
  defer(() =>
    control.valueChanges.pipe(
      startWith(control.value),
      distinctUntilChanged((previous, current) => isEqual(previous, current)),
    ),
  );
