import { Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { isNil } from 'lodash';
import { MessageService } from 'primeng/api';

import { AuditEntry } from '../common/audit-entry/interfaces';
import { SettingsEditorRow } from '../common/settings-editor-row';

/**
 * Use directive to inherit inputs:
 * https://github.com/angular/angular/issues/32428
 */
@Directive()
export abstract class SettingsRowEditorBase<EditorOutput, T extends SettingsEditorRow<EditorOutput>> implements OnChanges, OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() row: T;

  @Output() onUploadComplete = new EventEmitter<string>();

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public auditEntry: AuditEntry;

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////////////////////////
  protected constructor(private messageService: MessageService) {}

  ngOnInit() {
    this.initialize();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.initialize();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  form = new UntypedFormGroup({
    control: new UntypedFormControl(undefined, [Validators.required]),
  });

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Audit Entry
  ///////////////////////////////////////////////////////////////////////////////////////////
  abstract updateAuditEntry(): void;

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Upload
  ///////////////////////////////////////////////////////////////////////////////////////////

  doUpload = async () => this._doUpload();

  /**
   * Upload the editor output and emit the id
   */
  async _doUpload() {
    const editorOutput: EditorOutput = this.control.value;
    const uploadResult = await this.uploadEditorOutput(editorOutput);
    if (!uploadResult.updateSucceeded) {
      this.messageService.add({
        severity: 'warn',
        summary: translate('settings.uploadFailedDueToContentionTitle'),
        detail: translate('settings.uploadFailedDueToContentionBody'),
      });
      return;
    }
    this.onUploadComplete.emit(uploadResult.objId);
  }

  protected abstract uploadEditorOutput(editorOutput: EditorOutput): Promise<{ updateSucceeded: boolean; objId: string }>;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Initialize
  /////////////////////////////////////////////////////////////////////////////////////////////

  private initialize() {
    if (isNil(this.row)) {
      return;
    }
    this.updateAuditEntry();
    this._resetForm();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Refresh Data
  ///////////////////////////////////////////////////////////////////////////////////////////

  refreshData = () => this._refreshData();

  async _refreshData() {}

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Reset Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  resetForm = () => this._resetForm();

  _resetForm() {
    this.form.reset({ control: this.row.editorOutput });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid
  /////////////////////////////////////////////////////////////////////////////////////////////

  getIsValid = (): boolean => this.isValid();

  isValid() {
    return this.form.valid;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  ///////////////////////////////////////////////////////////////////////////////////////////

  get control() {
    return this.form.get('control') as UntypedFormControl;
  }
}
