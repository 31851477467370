import {SchemaField} from '../../../../generic/serialization/schema-field';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {CommunicationSessionId} from '../../../../core/communication-session-id/communication-session-id';
import {values} from 'lodash';
import {CommunicationTaskName} from '../communication-task-name';
import {CommunicationTaskAttributesTaskQueue} from '../../communication-task-attributes/communication-task-attributes-task-queue/communication-task-attributes-task-queue';

export abstract class CommunicationTaskSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';
  public static readonly orgId = 'orgId';
  public static readonly sessionId = 'sessionId';
  public static readonly taskQueueAttributes = 'taskQueueAttributes';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly orgId = undefined;
    public static readonly sessionId = undefined;
    public static readonly taskQueueAttributes = new Map();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationTaskSchema.type] = SchemaField.string(CommunicationTaskSchema.type, CommunicationTaskSchema.Defaults.type).allow(...values(CommunicationTaskName)).required();
    parameters[CommunicationTaskSchema.orgId] = SchemaField.autoGeneratedDocId(CommunicationTaskSchema.Defaults.orgId).required();
    parameters[CommunicationTaskSchema.sessionId] = SchemaField.obj(CommunicationSessionId, CommunicationTaskSchema.Defaults.sessionId).required();
    parameters[CommunicationTaskSchema.taskQueueAttributes] = SchemaField.mapOfObj(CommunicationTaskAttributesTaskQueue, CommunicationTaskSchema.Defaults.taskQueueAttributes).optional();
    /* eslint-enable */

    return parameters;
  }
}
