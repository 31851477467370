import { Component } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { DisplayableObj } from '../../../common/objects/types';
import { ChooseObjsForm } from '../abstract-classes/choose-objs-form';

@UntilDestroy()
@Component({
  selector: 'app-obj-multi-select',
  templateUrl: './obj-multi-select.component.html',
  styleUrls: ['./obj-multi-select.component.css'],
})
export class ObjMultiSelectComponent<T extends DisplayableObj> extends ChooseObjsForm<T> {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public formControl = new UntypedFormControl();

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getFormControl() {
    return this.formControl;
  }
}
