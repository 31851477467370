import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { InputMaskModule } from 'primeng/inputmask';

import { PhoneEditorComponent } from './phone-editor.component';

@NgModule({
  declarations: [PhoneEditorComponent],
  imports: [CommonModule, InputMaskModule, ReactiveFormsModule, TranslocoModule],
  exports: [PhoneEditorComponent],
})
export class PhoneEditorModule {}
