import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {CallerIdStrategyType} from './caller-id-strategy-type';

export class CallerIdStrategySchema extends SerializableObjectSchema {
  public static readonly type = 'type';
  public static readonly callerIdObjId = 'callerIdObjId';

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly callerIdObjId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CallerIdStrategySchema.type] = SchemaField.string(CallerIdStrategySchema.Defaults.type)
      .required()
      .valid(...Object.values(CallerIdStrategyType));

    parameters[CallerIdStrategySchema.callerIdObjId] = SchemaField.autoGeneratedDocId(CallerIdStrategySchema.Defaults.callerIdObjId);
    /* eslint-enable */

    return parameters;
  }
}
