import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';

import { SubmitTicketRequest } from '@pwp-common';

import { SubmitTicketService } from '../../../services/submit-ticket/submit-ticket.service';

@Component({
  providers: [MessageService],
  selector: 'app-support-ticket',
  templateUrl: './support-ticket.component.html',
  styleUrls: ['./support-ticket.component.css'],
})
export class SupportTicketComponent implements OnInit {
  public form = new UntypedFormGroup({});

  public loading = true;

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private submitTicketService: SubmitTicketService,
    private formBuilder: UntypedFormBuilder,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.loading = true;

    // Init Support Form
    const formConfig = {} as any;
    formConfig.subject = ['', [Validators.maxLength(150), Validators.required]];
    formConfig.comment = ['', [Validators.required]];
    formConfig.orgImpact = [undefined, [Validators.required]];
    this.form = this.formBuilder.group(formConfig);

    this.loading = false;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Submit
  ///////////////////////////////////////////////////////////////////////////////////////////

  async submitRequest() {
    const message = `${this.comment!.value}`;
    const request = new SubmitTicketRequest({
      subject: this.subject?.value || 'MISSING-Subject',
      message,
      priority: this.orgImpact.value,
    });

    try {
      await this.submitTicketService.makeRequestOLD(request).toPromise();
      this.form.reset();
      this.successMessage();
    } catch (error) {
      this.failureMessage(error);
      console.error(error);
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get subject(): AbstractControl | null {
    return this.form.get('subject');
  }

  get comment(): AbstractControl | null {
    return this.form.get('comment');
  }

  get orgImpact(): AbstractControl | null {
    return this.form.get('orgImpact');
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Notifications
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  private successMessage() {
    this.messageService.add({
      severity: 'success',
      summary: 'Ticket Submitted!',
      detail: '',
    });
  }

  private failureMessage(error: any) {
    console.error(error);
    this.messageService.add({
      severity: 'error',
      summary: 'Error submitting ticket.',
      detail: JSON.stringify(error),
    });
  }
}
