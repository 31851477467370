import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';

import { getRRuleByIsoWeekday, getRRuleDtstart , rruleIsDaily, TimeSelection } from '@pwp-common';


import { formatTimeRange } from '../../time-range-str/format-time-range';

import { AvailableTimesByWeek, AvailableTimesByWeekMap, AvailableTimesType } from './available-times-by-week';
import { AVAILABLE_TIMES_BY_DOW_ALWAYS_AVAILABLE_MAP, AVAILABLE_TIMES_BY_DOW_NEVER_AVAILABLE_MAP } from './constants';
import { normalizeAvailableTimesByWeekMap } from './normalize-available-times-by-week-map';


/**
 * If this time selection object specifies the available times by weekday
 * then return those times. Else, return undefined.
 *
 * @param timeSelection
 */
export const getAvailableTimesByDayOfWeek = (timeSelection: TimeSelection) => {
  if (isNil(timeSelection)) {
    return AVAILABLE_TIMES_BY_DOW_ALWAYS_AVAILABLE_MAP;
  }

  const items = timeSelection.getItems();
  // Handle the case where items is emtpy. This is
  // the only case where we allow includeByDefault = true
  if (items.length === 0) {
    if (timeSelection.getIncludeByDefault() === true) {
      return AVAILABLE_TIMES_BY_DOW_ALWAYS_AVAILABLE_MAP;
    }
    if (timeSelection.getIncludeByDefault() === false) {
      return AVAILABLE_TIMES_BY_DOW_NEVER_AVAILABLE_MAP;
    }
  }

  if (timeSelection.getIncludeByDefault() === true) {
    console.log("getAvailableTimesByDayOfWeek: Default value can't be true if items are specified. Returning always available");
    return AVAILABLE_TIMES_BY_DOW_ALWAYS_AVAILABLE_MAP;
  }

  let availableTimes: AvailableTimesByWeekMap = new Map<number, AvailableTimesByWeek>();

  for (const item of items) {
    ///////////////////////////////////////////////////
    // Sanity Check: Only Specify Available Times
    ///////////////////////////////////////////////////
    if (item.getInclude() === false) {
      console.log('getAvailableTimesByDayOfWeek: Can only specify available times');
      return AVAILABLE_TIMES_BY_DOW_ALWAYS_AVAILABLE_MAP;
    }

    ///////////////////////////////////////////////////
    // Sanity Check: Available times are only by week
    ///////////////////////////////////////////////////
    if (!rruleIsDaily(item.getRRule(), true)) {
      console.log('getAvailableTimesByDayOfWeek: Available times must be daily. This RRule has incompatible frequency', {
        rrule: item.getRRule(),
      });
      return AVAILABLE_TIMES_BY_DOW_ALWAYS_AVAILABLE_MAP;
    }

    ///////////////////////////////////////////////////
    // Set Available Times For This Week
    ///////////////////////////////////////////////////

    const isoWeekdays = getRRuleByIsoWeekday(item.getRRule(), true);
    const dtstart = getRRuleDtstart(item.getRRule());

    for (const isoWeekday of isoWeekdays) {
      ///////////////////////////////////////////////////
      // Sanity Check: Only one time range per weekday
      ///////////////////////////////////////////////////

      if (availableTimes.has(isoWeekday)) {
        console.log('getAvailableTimesByDayOfWeek: Available times for this day of the week have already been specified', {
          rrule: item.getRRule(),
          availableTimes,
          isoWeekday,
        });
        return AVAILABLE_TIMES_BY_DOW_ALWAYS_AVAILABLE_MAP;
      }

      ///////////////////////////////////////////////////
      // These are available times, add them
      ///////////////////////////////////////////////////

      if (moment.duration(item.getDuration()).toISOString() === 'P1D') {
        availableTimes.set(isoWeekday, {
          type: AvailableTimesType.alwaysAvailable,
        });
        continue;
      }

      const startTime = dtstart;
      const endTime = cloneDeep(dtstart).add(item.getDuration());

      if (endTime.isSameOrBefore(startTime)) {
        availableTimes.set(isoWeekday, {
          type: AvailableTimesType.neverAvailable,
        });
        continue;
      }

      const timeRange = formatTimeRange(startTime, endTime);
      availableTimes.set(isoWeekday, {
        type: AvailableTimesType.sometimesAvailable,
        timeRange,
      });
    }
  }

  availableTimes = normalizeAvailableTimesByWeekMap(availableTimes);

  return availableTimes;
};
