import {CommunicationTaskQueueWorkerListNameType} from '../communication-task-queue-worker-list-name';
import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../generic/serialization/serializable-object-schema';
import {SerializableObject} from '../../../../../generic/serialization/serializable-object';

export abstract class CommunicationTaskQueueWorkerList extends SerializableObject {
  protected type!: CommunicationTaskQueueWorkerListNameType;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract CommunicationTaskQueueWorkerList');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): CommunicationTaskQueueWorkerListNameType {
    return cloneDeep(this.type);
  }

  public abstract getNumWorkerSlots(): number;
}
