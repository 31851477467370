import { ContentChild, Directive, Input } from '@angular/core';

import { DataTableCellDirective } from './data-table-cell/data-table-cell.directive';
import { DataTableHeaderDirective } from './data-table-header/data-table-header.directive';

@Directive({
  selector: '[appDataTableColumnDef]',
  standalone: true,
})
export class DataTableColumnDefDirective {
  @Input('appDataTableColumnDef') public readonly name: string;

  @ContentChild(DataTableHeaderDirective) public readonly header?: DataTableHeaderDirective;

  @ContentChild(DataTableCellDirective) public readonly cell?: DataTableCellDirective;
}
