import { EntityStats, EntityStatsChunk } from '@pwp-common';

import { ReportRowValues } from '../interfaces';

export const createValuesForEntityStats = (
  entityStats: EntityStats,
  createValue: (statusChunk: EntityStatsChunk, period: string) => string | number | undefined,
): ReportRowValues => {
  const countByMonth = entityStats.getSortedMonths().reduce(
    (acc, month) => ({
      ...acc,
      [month]: createValue(entityStats.getByMonth().get(month), month),
    }),
    {},
  );

  return {
    total: createValue(entityStats.getTotal(), 'total'),
    countByMonth,
  };
};
