import {cloneDeep} from 'lodash';
import moment from 'moment-timezone';
import {Frequency} from 'rrule';
import {validateAndGetRRuleFromStr} from './validation';

export const validateRRuleStartEndConsistent = (rruleStr: string, start: moment.Moment, end: moment.Moment) => {
  const rrule = validateAndGetRRuleFromStr(rruleStr, true);

  const hoursBetweenStartEnd = cloneDeep(end).diff(start, 'hours');
  switch (rrule!.options?.freq) {
    case Frequency.DAILY: {
      if (hoursBetweenStartEnd > 24) {
        console.error({start: start.format(), end: end.format(), hoursBetweenStartEnd});
        throw new Error('validateRRuleStartEndConsistent: Start and End cannot be 24h apart for a daily event');
      }
    }
  }
};
