import { ChangeDetectorRef, Directive, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { cloneDeep, orderBy } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { DBDocSchema, OrgData, SerializableObjectBuilder } from '@pwp-common';

import { isComplete, KVPair } from '../../../common/objects/kvpair';
import { DisplayableDocObj } from '../../../common/objects/types';
import { DbDocumentService } from '../../../services/generic/db-document-service';
import { DBQuery } from '../../../services/generic/interfaces';

import { ObjEditor } from './obj-editor';

@Directive()
export abstract class ConfigDocSelectAndEdit<T extends DisplayableDocObj> extends ObjEditor<T> implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  itemsArray: Observable<T[]>;

  selectedKVPair: KVPair<T> | undefined;

  @Input() orgData: OrgData;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private objBuilder: SerializableObjectBuilder<T>,
    private dataService: DbDocumentService<any>,
    /* eslint-disable */
    // @ts-ignore
    private __changeDetectorRef: ChangeDetectorRef,
    /* eslint-enable */
  ) {
    super(__changeDetectorRef);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.orgData?.previousValue?.getId() !== changes.orgData?.currentValue?.getId() && this.orgData !== undefined) {
      this.getData();
    }
    super.ngOnChanges(changes);
  }

  itemsQuery(): undefined | DBQuery[] {
    return undefined;
  }

  getData() {
    this.itemsArray = this.dataService.getDocsArray(this.itemsQuery()).pipe(
      map((items: T[]) => orderBy(items, (z) => z.getDisplayName(), 'asc')),
      tap(() => {
        this.onSelectedKVPairChange(undefined);
      }),
    );
    super.getData();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Make New KVPair
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Defines the new item required for the "New" button. This must be
   * an arrow function, since we need to access this, and it is passed
   * as an input property.
   */
  kvPairBuilder = (): KVPair<T> => new KVPair({ id: DBDocSchema.GenericDefaults.id, value: (this.objBuilder as any).deserialize({}) });

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // CRUD
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  doUpload = () => this.uploadClick();

  async uploadClick() {
    const updatedObj = this.getUpdatedObj();
    await this.dataService.upload({ obj: updatedObj }).toPromise();
    this.getData();
  }

  doDelete = () => this.deleteClick();

  async deleteClick() {
    await this.dataService.delete(this.selectedKVPair.value?.getId()).toPromise();
    this.getData();
  }

  duplicateClick() {
    const duplicateObj = cloneDeep(this.selectedKVPair);
    duplicateObj.value?.setId(DBDocSchema.GenericDefaults.id);

    this.onSelectedKVPairChange(duplicateObj);
  }

  doRefresh = () => this.refreshClick();

  refreshClick = () => {
    this.selectedKVPair = undefined;
    this.getData();
  };

  async newClick() {
    const selectedKVPair = this.kvPairBuilder();

    // Make new orderedItems
    const itemsArray = await this.itemsArray.toPromise();
    itemsArray.push(selectedKVPair?.value);
    this.itemsArray = of(itemsArray);
    this.onSelectedKVPairChange(selectedKVPair);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Updated Object
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public getUpdatedObj(): T | undefined {
    if (this.selectedKVPair === undefined) {
      return undefined;
    }
    if (!isComplete(this.selectedKVPair)) {
      console.error(this.selectedKVPair);
      throw new Error('ConfigDocSelectAndEdit.getUpdatedObj: User Error. The given KVPair is not complete');
    }

    return super.getUpdatedObj();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callbacks and UI Helper
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  onSelectedKVPairChange(kvPair: KVPair<T> | undefined) {
    this.selectedKVPair = kvPair;
    this.obj = kvPair?.value;
    this.initState();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Init Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  protected initState() {
    if (this.selectedKVPair === undefined) {
      // Initializing the form will fail if selectedItem is undefined
      this.loading = true;
      return;
    }

    super.initState();
  }
}
