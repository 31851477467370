import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { VoicemailBlobLocation } from '@pwp-common';

import { BlobStorageService } from '../../generic/blob-storage-service';

@Injectable({
  providedIn: 'root',
})
export class VoicemailBlobService extends BlobStorageService {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  // @ts-ignore
  constructor(private _storage: AngularFireStorage) {
    super(_storage);
  }

  protected getBlobLocation() {
    return new VoicemailBlobLocation();
  }
}
