import moment from 'moment-timezone';
import {cloneDeep} from 'lodash';

export const isPiiRetained = ({
  objectCreateTime,
  piiRetentionDuration,
}: {
  objectCreateTime: moment.Moment;
  piiRetentionDuration: string;
}): boolean => {
  const now = moment();
  const duration = moment.duration(piiRetentionDuration);
  if (!moment.isMoment(objectCreateTime) || !moment.isDuration(duration)) {
    return false;
  }

  /**
   * If input says that object is created in the future return false
   * because something went wrong.
   */
  if (objectCreateTime.isAfter(now)) {
    return false;
  }

  const accessEndTime = cloneDeep(objectCreateTime).add(piiRetentionDuration);
  if (now.isSameOrAfter(accessEndTime)) {
    return false;
  }

  return true;
};
