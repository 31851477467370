import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LetModule } from '@ngrx/component';
import { isEmpty, isNil } from 'lodash';
import { DropdownModule } from 'primeng/dropdown';

import { provideValidator } from '../../../../common/form/provide-validator';
import { provideValueAccessor } from '../../../../common/form/provide-value-accessor';
import { MenuOption } from '../../../../common/form/typings/menu-options';
import { FormFieldHelpComponent } from '../../../form/form-field-help/form-field-help.component';
import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';
import { ObjAutocompleteV2Module } from '../../../generic/obj-autocomplete-v2/obj-autocomplete-v2.module';
import { CallerIdStrategyEditorOutput } from '../editor-output/caller-id-strategy-editor-output';

import { callerIdStrategyValidator } from './validators/caller-id-strategy-validator';

@UntilDestroy()
@Component({
  selector: 'app-caller-id-strategy-editor',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    FormFieldHelpComponent,
    FormsModule,
    LetModule,
    ObjAutocompleteV2Module,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  templateUrl: './caller-id-strategy-editor.component.html',
  styleUrls: ['./caller-id-strategy-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideValueAccessor(CallerIdStrategyEditorComponent), provideValidator(CallerIdStrategyEditorComponent)],
})
export class CallerIdStrategyEditorComponent extends FormGroupControlValueAccessor<
  CallerIdStrategyEditorOutput,
  CallerIdStrategyEditorOutput
> {
  public readonly form = new FormGroup(
    {
      callerIdObjId: new FormControl(''),
      type: new FormControl(''),
    },
    { validators: callerIdStrategyValidator },
  );

  public readonly possibleValuesCallerIdStrategy: MenuOption[] = [
    {
      label: 'callerIdStrategyLabelExplicit',
      description: 'callerIdStrategyDescriptionExplicit',
      value: 'explicit',
    },
    {
      label: 'callerIdStrategyLabelForward',
      description: 'callerIdStrategyDescriptionForward',
      value: 'forward',
    },
    {
      label: 'callerIdStrategyLabelReceivedAt',
      description: 'callerIdStrategyDescriptionReceivedAt',
      value: 'receivedAt',
    },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  public writeValue(value: CallerIdStrategyEditorOutput | undefined) {
    if (isNil(value)) {
      return;
    }

    this.form.patchValue(value, { emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  public parseValueChange(value: CallerIdStrategyEditorOutput): CallerIdStrategyEditorOutput {
    const { callerIdObjId, type } = value;

    if (isEmpty(callerIdObjId) && isEmpty(type)) {
      return null;
    }

    return value;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  public makeValidationErrors(): ValidationErrors {
    return {
      'caller-id-strategy-editor': this.form.value,
    };
  }
}
