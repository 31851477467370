import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MessagesModule } from 'primeng/messages';
import { SelectButtonModule } from 'primeng/selectbutton';

import { AccordionWizardModule } from '../../generic/accordion-wizard/accordion-wizard.module';
import { SequenceModule } from '../../generic/sequence/sequence.module';
import { CallListModule } from '../call-list/call-list.module';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { RruleEditorModule } from '../rrule-editor/rrule-editor.module';

import { ServiceCommandEditorContainerComponent } from './service-command-editor-container/service-command-editor-container.component';
import { ServiceCommandEditorDialCallListComponent } from './service-command-editor-dial-call-list/service-command-editor-dial-call-list.component';
import { ServiceCommandEditorEnforceServiceLimitComponent } from './service-command-editor-enforce-service-limit/service-command-editor-enforce-service-limit.component';
import { ServiceCommandEditorFlagServiceRequestComponent } from './service-command-editor-flag-service-request/service-command-editor-flag-service-request.component';
import { ServiceCommandEditorForwardComponent } from './service-command-editor-forward/service-command-editor-forward.component';
import { ServiceCommandEditorPlayOrSayComponent } from './service-command-editor-play-or-say/service-command-editor-play-or-say.component';
import { ServiceCommandNameSelectComponent } from './service-command-name-select/service-command-name-select.component';
import { ServiceLimitEditorComponent } from './service-limit-editor/service-limit-editor.component';
import { ServiceOperationEditorComponent } from './service-operation-editor/service-operation-editor.component';
import { VrcAudioSelectComponent } from './vrc-audio-select/vrc-audio-select.component';

@NgModule({
  declarations: [
    ServiceCommandEditorContainerComponent,
    ServiceCommandEditorDialCallListComponent,
    ServiceCommandEditorEnforceServiceLimitComponent,
    ServiceCommandEditorFlagServiceRequestComponent,
    ServiceCommandEditorPlayOrSayComponent,
    ServiceCommandNameSelectComponent,
    ServiceLimitEditorComponent,
    ServiceOperationEditorComponent,
    VrcAudioSelectComponent,
  ],
  imports: [
    AccordionWizardModule,
    AutoCompleteModule,
    CallListModule,
    CommonModule,
    DropdownModule,
    InputNumberModule,
    InputSwitchModule,
    MatIconModule,
    MessagesModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    RruleEditorModule,
    SelectButtonModule,
    SequenceModule,
    ServiceCommandEditorForwardComponent,
    TranslocoModule,
  ],
  exports: [ServiceOperationEditorComponent, VrcAudioSelectComponent],
})
export class ServiceOperationEditorModule {}
