import {CallLog} from '../../call/call-log/call-log';
import {AssignedUserType} from '../../event/event-data/enums';
import {EventConfigSchema} from '../../event/event-config/event-config-schema';
import {EventData} from '../../event/event-data/event-data';
import {EventDataSchema} from '../../event/event-data/event-data-schema';
import {EntityStats} from '../entity-stats/entity-stats';
import {EntityStatsType} from '../entity-stats/enums';
import {EntityStatsChunk} from '../entity-stats-chunk/entity-stats-chunk';
import {AllDataUser} from '../../user/all-data-user/all-data-user';
import {isNilOrDefault} from '../../generic/serialization/is-nil-or-default';

/**
 * Input is the list of all completed calls and events. Output is a map of all possible entity stats
 * that can be determined.
 *
 * @param orderedCalls
 * @param events
 */
export const computeAllEntityStats = (
  orderedCalls: CallLog[],
  orderedEvents: EventData[],
  allDataUserMap: Map<string, AllDataUser>,
  timezone: string
): Map<string, EntityStats> => {
  // const orderedCalls = orderBy(orderedCalls, [z => z.getIncomingCallReceivedTime().valueOf()], ['asc']);
  // const orderedEvents = orderBy(orderedEvents, [z => z.getStart().valueOf()], ['asc']);

  // Step 1: Set the default objects
  const entityStatsMap = getDefaultEntityStatsMap(allDataUserMap);

  // Step 2: Iterate over all calls, and update the stats objects
  for (const call of orderedCalls) {
    //////////////////////////////////////////////////////////////////////
    // Update the appropriate entity stats maps with this call.
    //////////////////////////////////////////////////////////////////////
    entityStatsMap.get(EntityStatsType.org)!.updateWithCall(call, allDataUserMap, timezone);
    entityStatsMap.get(EntityStatsType.orgAdmin)!.updateWithCall(call, allDataUserMap, timezone);
    entityStatsMap.get(EntityStatsType.notOrgAdmin)!.updateWithCall(call, allDataUserMap, timezone);

    if (call.wasAnswered()) {
      const answeredBy = call.getAnsweredBy()!;
      if (entityStatsMap.get(answeredBy) === undefined) {
        entityStatsMap.set(answeredBy, getDefaultEntityStatsForUser(answeredBy));
      }
      entityStatsMap.get(answeredBy)!.updateWithCall(call, allDataUserMap, timezone);
    }
  }

  const eventDataSchema = new EventConfigSchema();
  // Step 3: Iterate over all events, and update the stats objects
  for (const event of orderedEvents) {
    //////////////////////////////////////////////////////////////////////
    // Update the appropriate entity stats maps with this call.
    //////////////////////////////////////////////////////////////////////
    for (const assignedUserTypeIndex in AssignedUserType) {
      const assignedUserType = AssignedUserType[assignedUserTypeIndex as keyof typeof AssignedUserType];
      const userId = event.getUser(assignedUserType);
      if (isNilOrDefault(userId, EventDataSchema.assignedUserId, eventDataSchema)) {
        continue;
      }

      entityStatsMap.get(EntityStatsType.org)!.updateWithEvent(event, allDataUserMap, assignedUserType, timezone);
      entityStatsMap.get(EntityStatsType.orgAdmin)!.updateWithEvent(event, allDataUserMap, assignedUserType, timezone);
      entityStatsMap.get(EntityStatsType.notOrgAdmin)!.updateWithEvent(event, allDataUserMap, assignedUserType, timezone);

      if (entityStatsMap.get(userId!) === undefined) {
        entityStatsMap.set(userId!, getDefaultEntityStatsForUser(userId!));
      }
      entityStatsMap.get(userId!)!.updateWithEvent(event, allDataUserMap, assignedUserType, timezone);
    }
  }
  return entityStatsMap;
};

//////////////////////////////////////////////////////////////////////
// Get Default Objects
//////////////////////////////////////////////////////////////////////

/**
 * Get the default entity stats map
 * @param allDataUserMap
 */
const getDefaultEntityStatsMap = (allDataUserMap: Map<string, AllDataUser>): Map<string, EntityStats> => {
  const entityStatsMap = new Map<string, EntityStats>();

  entityStatsMap.set(
    EntityStatsType.org,
    new EntityStats({
      id: EntityStatsType.org,
      bySlidingWindow: new Map<string, EntityStatsChunk>(),
      byMonth: new Map<string, EntityStatsChunk>(),
      total: EntityStatsChunk.deserialize({}),
      type: EntityStatsType.org,
      numUsersAnalyzed: 0,
    })
  );

  entityStatsMap.set(
    EntityStatsType.orgAdmin,
    new EntityStats({
      id: EntityStatsType.orgAdmin,
      bySlidingWindow: new Map<string, EntityStatsChunk>(),
      byMonth: new Map<string, EntityStatsChunk>(),
      total: EntityStatsChunk.deserialize({}),
      type: EntityStatsType.orgAdmin,
      numUsersAnalyzed: 0,
    })
  );

  entityStatsMap.set(
    EntityStatsType.notOrgAdmin,
    new EntityStats({
      id: EntityStatsType.notOrgAdmin,
      bySlidingWindow: new Map<string, EntityStatsChunk>(),
      byMonth: new Map<string, EntityStatsChunk>(),
      total: EntityStatsChunk.deserialize({}),
      type: EntityStatsType.notOrgAdmin,
      numUsersAnalyzed: 0,
    })
  );

  for (const userId in allDataUserMap.keys()) {
    entityStatsMap.set(userId, getDefaultEntityStatsForUser(userId));
  }

  return entityStatsMap;
};

const getDefaultEntityStatsForUser = (userId: string): EntityStats => {
  return new EntityStats({
    id: userId,
    bySlidingWindow: new Map<string, EntityStatsChunk>(),
    byMonth: new Map<string, EntityStatsChunk>(),
    total: EntityStatsChunk.deserialize({}),
    type: EntityStatsType.oneUser,
    numUsersAnalyzed: 1,
  });
};
