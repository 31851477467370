import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {PossibleAdminRole} from '../../../../../objects/user/admin-roles/types';
import {PossibleRole} from '../../../../../objects/user/roles/types';
import {CommunicationsRequestName} from '../../../communications-request-name';
import {CommunicationsRequest} from '../../generic/communications-request/communications-request';
import {CommunicationsRequestSchema} from '../../generic/communications-request/communications-request-schema';
import {CommunicationsRequestDialAsyncServiceRequestConstructor} from './communications-request-dial-async-service-request-constructor';
import {CommunicationsRequestDialAsyncServiceRequestSchema} from './communications-request-dial-async-service-request-schema';
import {CommunicationSessionId} from '../../../../../objects/core/communication-session-id/communication-session-id';

export class CommunicationsRequestDialAsyncServiceRequest extends CommunicationsRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected asyncServiceRequestId!: string;
  protected callerIdObjId!: string;
  protected sessionId!: CommunicationSessionId;
  protected conferenceFriendlyName!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsRequestDialAsyncServiceRequestConstructor) {
    (parameters as any)[CommunicationsRequestSchema.type] = CommunicationsRequestName.dialAsyncServiceRequest;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationsRequestDialAsyncServiceRequest {
    return new CommunicationsRequestDialAsyncServiceRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsRequestDialAsyncServiceRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsRequestDialAsyncServiceRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['voicemail'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getAsyncServiceRequestId() {
    return cloneDeep(this.asyncServiceRequestId);
  }
  public getCallerIdObjId() {
    return cloneDeep(this.callerIdObjId);
  }
  public getConferenceFriendlyName() {
    return cloneDeep(this.conferenceFriendlyName);
  }
  public getSessionId() {
    return cloneDeep(this.sessionId);
  }
}
