/**
 * List all possible values for StirVerstat
 *
 * Note that the StirVerstat parameter / X-Twilio-VerStat header are only present for incoming calls with SHAKEN PASSporT identity headers.
 */
export enum TwilioStirVerstat {
  /**
   * Twilio received the SIP INVITE, with a SHAKEN PASSporT, and was able to fetch the public certificate of the originating service provider from the Certificate Authority that signed the call to verify that no one tampered with the SIP INVITE during transit.
   *
   *     Attestation level A
   */
  TNValidationPassedA = 'TN-Validation-Passed-A',

  /**
   * Twilio received the SIP INVITE, with a SHAKEN PASSporT, and was able to fetch the public certificate of the originating service provider from the Certificate Authority that signed the call to verify that no one tampered with the SIP INVITE during transit.
   *
   *     Attestation level B
   */
  TNValidationPassedB = 'TN-Validation-Passed-B',

  /**
   * Twilio received the SIP INVITE, with a SHAKEN PASSporT, and was able to fetch the public certificate of the originating service provider from the Certificate Authority that signed the call to verify that no one tampered with the SIP INVITE during transit.
   *
   *     Attestation level C
   */
  TNValidationPassedC = 'TN-Validation-Passed-C',

  /**
   * Twilio was unable to verify the contents of the SHAKEN PASSporT.
   *
   *     This could mean tampering, or simply that Twilio could not retrieve the public certificate of the originating service provider from the Certificate Authority.
   *
   *     Attestation level A
   */
  TNValidationFailedA = 'TN-Validation-Failed-A',

  /**
   * Twilio was unable to verify the contents of the SHAKEN PASSporT.
   *
   *     This could mean tampering, or simply that Twilio could not retrieve the public certificate of the originating service provider from the Certificate Authority.
   *
   *     Attestation level B
   */
  TNValidationFailedB = 'TN-Validation-Failed-B',

  /**
   * Twilio was unable to verify the contents of the SHAKEN PASSporT.
   *
   *     This could mean tampering, or simply that Twilio could not retrieve the public certificate of the originating service provider from the Certificate Authority.
   *
   *     Attestation level C
   */
  TNValidationFailedC = 'TN-Validation-Failed-C',

  /**
   * Possible causes:
   *
   *     A malformed E.164 phone number
   * SHAKEN PASSporT format is invalid. It should consist of a header, payload, signature, and parameters.
   *     SHAKEN PASSporT does not have required fields like ppt headers or info parameter
   * SHAKEN PASSporT orig field doesn't match with actual callerid in the SIP messages (P-Asserted-Identity, Remote-Party-Identity, or From header).
   * SHAKEN PASSporT dest field doesn't match with the actual destination of the call in the SIP Request-URI.
   * SHAKEN PASSporT iat field is too old – more than 1 minutes from current timestamp or the SIP Date header value.
   */
  NoTNValidation = 'No-TN-Validation',

  /**
   * Twilio was unable to verify the contents of the SHAKEN PASSporT.
   *
   *     This could mean tampering, or simply that Twilio could not retrieve the public certificate of the originating service provider from the Certificate Authority.
   *
   *     No attestation level determined.
   */
  TNValidationFailed = 'TN-Validation-Failed',
}
