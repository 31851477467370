import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css'],
})
export class LogoutComponent implements OnInit {
  constructor(
    private angularFireAuth: AngularFireAuth,
    private router: Router,
  ) {}

  ngOnInit() {
    this.logout();
  }

  logout() {
    this.angularFireAuth.signOut().then(() => {
      console.log('Logged Out!');
      this.router.navigate(['/login']);
    });
  }
}
