import {
  CommunicationTaskQueue,
  CommunicationTaskQueueSchema,
  DBDocSchema,
  defaultReservationSkipOffersIf,
  ReservationOfferChannelConfigPhoneCall,
  ReservationOfferChannelConfigText,
  ReservationOfferConfig,
  TemplateText,
} from '@pwp-common';

export const defaultCommunicationTaskQueue = new CommunicationTaskQueue({
  id: DBDocSchema.GenericDefaults.id,
  displayName: CommunicationTaskQueueSchema.Defaults.displayName,
  description: CommunicationTaskQueueSchema.Defaults.description,
  maxReservedWorkers: 1,
  reservationOfferConfig: new ReservationOfferConfig({
    offers: [],
    offerChannelConfigPhoneCall: new ReservationOfferChannelConfigPhoneCall({
      vrcAudioMetadataId: undefined as any,
      callerIdObjId: undefined,
      dialTimeoutMS: 60000,
    }),
    offerChannelConfigText: new ReservationOfferChannelConfigText({
      callerIdObjId: undefined,
      body: new TemplateText({ text: new Map<string, string>() }),
    }),
    skipOffersIf: defaultReservationSkipOffersIf,
  }),
  sid: undefined,
  workers: [],
});
