import {MessageTemplate} from './message-template';
import {cloneDeep} from 'lodash';
import {isNilOrDefault} from '../../generic/serialization/is-nil-or-default';
import {MessageTemplateSchema} from '../message-template/message-template-schema';
import {validateAndGetRRuleFromStr} from '../../../helper/rrule/validation';

/**
 * This class transforms AllDataEvent(), into a format suitable for displaying.
 */
export class MessageTemplateDisplay {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  // Data used to initialize class
  public messageTemplate: MessageTemplate;
  public userId: string;

  id: string;
  userIdIsSubscribed: boolean;
  displayName: string;
  description: string;
  frequency: string;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(messageTemplate: MessageTemplate, userId: string) {
    this.messageTemplate = cloneDeep(messageTemplate);
    this.userId = cloneDeep(userId);

    this.id = this.messageTemplate.getId();
    this.displayName = this.messageTemplate.getDisplayName();
    this.description = this.messageTemplate.getDescription();

    this.userIdIsSubscribed = messageTemplate.getSubscribedUserIds().includes(userId);
    this.frequency = this.getPrettyFrequency();
  }

  private getPrettyFrequency() {
    const sendRRule = this.messageTemplate.getSendRRule();
    if (isNilOrDefault(sendRRule, MessageTemplateSchema.sendRRule, MessageTemplate.getSchema())) {
      return '';
    }
    const rrule = validateAndGetRRuleFromStr(sendRRule!, false);
    if (rrule === undefined) {
      return '';
    }
    return rrule.toText();
  }
}
