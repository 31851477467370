import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../../generic/serialization/schema-field';
import {AnyReservationSkipOffersIfRule} from '../reservation-skip-offers-if-rule/generic/any-reservation-skip-offers-if-rule/any-reservation-skip-offers-if-rule';

export class ReservationSkipOffersIfSchema extends SerializableObjectSchema {
  public static readonly rules = 'rules';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly rules = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ReservationSkipOffersIfSchema.rules] = SchemaField.shortObjArray(AnyReservationSkipOffersIfRule, ReservationSkipOffersIfSchema.Defaults.rules).required();
    /* eslint-enable */

    return parameters;
  }
}
