import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { LetModule } from '@ngrx/component';
import { DialogModule } from 'primeng/dialog';

import { DateRangeSelectModule } from '../../core/date-range-select/date-range-select.module';
import { ProgressSpinnerModule } from '../../core/progress-spinner/progress-spinner.module';
import { DataTableModule } from '../../generic/data-table/data-table.module';

import { ReportComponent } from './report/report.component';

@NgModule({
  declarations: [ReportComponent],
  imports: [
    CommonModule,
    DataTableModule,
    DateRangeSelectModule,
    DialogModule,
    FormsModule,
    LetModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    TranslocoModule,
  ],
  exports: [ReportComponent],
})
export class AdminReportsModule {}
