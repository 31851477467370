import {ServiceOperation} from '../service-operation/service-operation';
import {DbDataIds} from '../../../core/db-data-ids/db-data-ids';
import {EnforceServiceLimit} from '../../enforce-service-limit/enforce-service-limit';
import {isNil} from 'lodash';

export const getServiceOperationDbDataIds = (serviceOperation: ServiceOperation): DbDataIds => {
  const result = new DbDataIds({
    serviceLimits: new Set<string>(),
  });

  if (isNil(serviceOperation)) {
    return result;
  }
  for (const command of serviceOperation.getCommands()) {
    switch (command.getCommandName()) {
      case 'enforceServiceLimit': {
        const enforceServiceLimit = command as EnforceServiceLimit;
        result.params.serviceLimits.add(enforceServiceLimit.getServiceLimitId());
        break;
      }
    }
  }

  return result;
};
