import { cloneDeep, isNil } from 'lodash';

import {
  CommunicationTaskQueue,
  CommunicationWorkflowTarget,
  CommunicationWorkflowTargetSchema,
  isNilOrDefault,
  OrgData,
  UserData,
} from '@pwp-common';

import { taskQueueToEditorOutput } from '../../../../../../workers-autocomplete/editor-output/task-queue/task-queue-to-editor-output/task-queue-to-editor-output';
import { dbToReservationOfferConfigEditorOutput } from '../../../reservation-offer-config/db/db-to-reservation-offer-config-editor-output/db-to-reservation-offer-config-editor-output';
import { dbToTargetModeEditorOutput } from '../../../target-mode/db-to-target-mode-editor-output/db-to-target-mode-editor-output';
import { dbDataToTargetTimeout } from '../../../target-timeout/db-data-to-target-timeout/db-data-to-target-timeout';
import { defaultTargetEditorOutput } from '../../default-target-editor-output';
import { TargetEditorOutput } from '../../target-editor-output';

const targetSchema = new CommunicationWorkflowTargetSchema();
export const dbToTargetEditorOutput = (params: {
  target: CommunicationWorkflowTarget;
  taskQueue: CommunicationTaskQueue;
  users: Map<string, UserData>;
  orgData: OrgData;
}): TargetEditorOutput => {
  /**
   * Handle empty input
   */
  if (isNil(params) || isNil(params.target) || isNil(params.taskQueue)) {
    return cloneDeep(defaultTargetEditorOutput);
  }

  /**
   * Invalid input
   * */
  if (
    !isNilOrDefault(params.target.getQueueId(), CommunicationWorkflowTargetSchema.queueId, targetSchema) &&
    params.target.getQueueId() !== params.taskQueue.getId()
  ) {
    console.error(params);
    throw new Error('dbToTargetEditorOutput: User error, the target queueId and the task queue id do not match');
  }

  /**
   * Valid input
   */
  const result: TargetEditorOutput = {
    ...dbToReservationOfferConfigEditorOutput(params.taskQueue.getReservationOfferConfig()),
    communicationTaskQueueId: params.taskQueue.getId(),
    mode: dbToTargetModeEditorOutput({ taskQueue: params.taskQueue }),
    workers: taskQueueToEditorOutput(params.taskQueue, params.users, params.orgData),
    workflowTargetTimeoutSeconds: dbDataToTargetTimeout(params.target.getTimeout()),
  };

  return result;
};
