import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import { firstValueFrom, Observable } from 'rxjs';

import {
  AnyAsyncServiceRequest,
  AsyncServiceRequest,
  AsyncServiceRequestSchema,
  AsyncServiceRequestStatus,
  isNilOrDefault,
} from '@pwp-common';

import { DbDocumentService } from '../generic/db-document-service';
import { DBOrderBy, DBQuery } from '../generic/interfaces';
import { AuthService } from '../user/auth/auth.service';

const schema = new AsyncServiceRequestSchema();
@Injectable({
  providedIn: 'root',
})
export class AsyncServiceRequestService extends DbDocumentService<AsyncServiceRequest> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, AnyAsyncServiceRequest);
  }

  /////////////////////////////////////////////////////////////////////
  // Service Requests Created In Range
  /////////////////////////////////////////////////////////////////////

  public getAsyncServiceRequestsCreatedInRange(
    start: moment.Moment,
    end: moment.Moment,
    takeOne = true,
  ): Observable<AsyncServiceRequest[]> {
    // Log inputs
    console.log(`getAsyncServiceRequestsCreatedInRange:` + `\n\tStart:\t${start}` + `\n\tTo:\t\t${end}` + `\n\ttakeOne:${takeOne}`);

    // Make the query
    const query: DBQuery[] = [
      {
        fieldPath: AsyncServiceRequestSchema.createTime,
        opStr: '>=',
        value: start.toDate(),
      },
      {
        fieldPath: AsyncServiceRequestSchema.createTime,
        opStr: '<=',
        value: end.toDate(),
      },
    ];

    const orderBy: DBOrderBy = {
      fieldPath: AsyncServiceRequestSchema.createTime,
      directionStr: 'desc',
    };

    // Return
    return this.getDocsArray(query, orderBy, undefined, takeOne);
  }

  ///////////////////////////////////////////////////////////////////////
  // Toggle Handled By Self
  ///////////////////////////////////////////////////////////////////////

  public async toggleHandledBySelf(docId: string): Promise<AsyncServiceRequest> {
    const loggedInUserId = await firstValueFrom(this.getInjectedAuthService().getUserId(true));

    const promise = this.db.firestore.runTransaction(async (transaction: firebase.firestore.Transaction): Promise<AsyncServiceRequest> => {
      const docRef = await firstValueFrom(this.docRef(docId));
      const oldData = this.getObjectFromFirebase(await transaction.get(docRef.ref));

      /**
       * Don't do anything if the item is handled by someone else.
       */
      const assignedTo = oldData.getAssignedTo();
      if (isNilOrDefault(assignedTo, AsyncServiceRequestSchema.assignedTo, schema)) {
        oldData.setField(AsyncServiceRequestSchema.assignedTo, loggedInUserId);
        oldData.setField(AsyncServiceRequestSchema.status, AsyncServiceRequestStatus.closed);
        const uploadDictionary = await firstValueFrom(this.getSerializedObjWithGenericFields(oldData, 'update'));
        transaction.update(docRef.ref, uploadDictionary);
      }
      if (assignedTo === loggedInUserId) {
        const uploadDictionary = await firstValueFrom(this.getSerializedObjWithGenericFields(oldData, 'update'));
        uploadDictionary[AsyncServiceRequestSchema.assignedTo] = firebase.firestore.FieldValue.delete();
        uploadDictionary[AsyncServiceRequestSchema.status] = AsyncServiceRequestStatus.open;
        transaction.update(docRef.ref, uploadDictionary);
      }

      const newData = await firstValueFrom(this.getDoc(docId));
      return newData;
    });
    const result = await promise;
    return result;
  }
}
