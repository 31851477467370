import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {possibleRolesSchema} from '../../../../../objects/generic/serialization/schema-field/possible-roles-schema/possible-roles-schema';
import {UserRequestSchema} from '../../generic/user-request/user-request-schema';

export class UserRequestCreateUserSchema extends UserRequestSchema {
  public static readonly e164Phone = 'e164Phone';

  public static readonly email = 'email';

  public static readonly firstName = 'firstName';

  public static readonly lastInitial = 'lastInitial';

  public static readonly roles = 'roles';

  public static readonly orgNote = 'orgNote';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static Defaults = class extends UserRequestSchema.Defaults {
    public static readonly e164Phone = undefined;
    public static readonly email = undefined;
    public static readonly firstName = undefined;
    public static readonly lastInitial = undefined;
    public static readonly roles = undefined;
    public static readonly orgNote = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[UserRequestCreateUserSchema.e164Phone] = SchemaField.string(UserRequestCreateUserSchema.Defaults.e164Phone);
    parameters[UserRequestCreateUserSchema.email] = SchemaField.email(UserRequestCreateUserSchema.Defaults.email).required();
    parameters[UserRequestCreateUserSchema.firstName] = SchemaField.string(UserRequestCreateUserSchema.Defaults.firstName);
    parameters[UserRequestCreateUserSchema.lastInitial] = SchemaField.string(UserRequestCreateUserSchema.Defaults.lastInitial);
    parameters[UserRequestCreateUserSchema.roles] = possibleRolesSchema();
    parameters[UserRequestCreateUserSchema.orgNote] = SchemaField.string(UserRequestCreateUserSchema.Defaults.orgNote);

    return parameters;
  }
}
