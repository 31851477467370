import type {ValidationResult} from 'joi';
import {cloneDeep} from 'lodash';

import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {PossibleAdminRole} from '../../../../../objects/user/admin-roles/types';
import {PossibleRole} from '../../../../../objects/user/roles/types';
import {ConversationRequestName} from '../../../conversation-request-name';
import {ConversationRequest} from '../../generic/conversation-request/conversation-request';
import {ConversationRequestSchema} from '../../generic/conversation-request/conversation-request-schema';

import {ConversationRequestTakeConstructor} from './conversation-request-take-constructor';
import {ConversationRequestTakeSchema} from './conversation-request-take-schema';

export class ConversationRequestTake extends ConversationRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected conversationLogId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationRequestTakeConstructor) {
    super({
      ...parameters,
      [ConversationRequestSchema.type]: ConversationRequestName.take,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: ValidationResult): ConversationRequestTake {
    return new ConversationRequestTake(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationRequestTake.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationRequestTakeSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['conversation'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getConversationLogId(): string {
    return cloneDeep(this.conversationLogId);
  }
}
