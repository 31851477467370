import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CommunicationWorkflow } from '@pwp-common';

import { CommunicationWorkflowService } from '../../../../services/communication/communication-workflow/communication-workflow.service';
import {
  getObjAutocompleteFormControlBaseProviders,
  ObjAutocompleteFormControlBase,
} from '../../abstract-classes/obj-autocomplete-form-control-base';

@UntilDestroy()
@Component({
  selector: 'app-communication-workflow-select',
  templateUrl: './communication-workflow-select.component.html',
  styleUrls: ['./communication-workflow-select.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...getObjAutocompleteFormControlBaseProviders(CommunicationWorkflowSelectComponent)],
})
export class CommunicationWorkflowSelectComponent extends ObjAutocompleteFormControlBase<CommunicationWorkflow> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public communicationWorkflowService: CommunicationWorkflowService,
  ) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Set All Options
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineAllOptions() {
    return this.communicationWorkflowService.getDocsArray();
  }
}
