import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {CalleeEntityType} from './enums';

export class CalleeEntitySchema extends SerializableObjectSchema {
  // Type of the entity. Is it an a list of users, or generated from an event.
  public static readonly type = 'type';

  // 24H blockout times.
  public static readonly blockoutTimes = 'blockoutTimes';

  // Type of event. Field may be missing.
  public static readonly eventType = 'eventType';

  // Type of assignedUser. Field may be missing.
  public static readonly assignedUserTypes = 'assignedUserTypes';

  // List of users to call. Field may be missing.
  public static readonly userList = 'userList';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    // Type of the entity. Is it an a list of users, or generated from an event.
    public static readonly type = CalleeEntityType.userList;

    // 24H blockout times.
    public static readonly blockoutTimes = [];

    // Type of event. Field may be missing.
    public static readonly eventType = undefined;

    // Type of assignedUser. Field may be missing.
    public static readonly assignedUserTypes = undefined;

    // List of users to call. Field may be missing.
    public static readonly userList = [];
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default values to serialize
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([
      // Default value is userList, so always serialize
      CalleeEntitySchema.type,
    ]);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CalleeEntitySchema.type] = SchemaField.string(CalleeEntitySchema.Defaults.type);
    parameters[CalleeEntitySchema.blockoutTimes] = SchemaField.shortStringArray(CalleeEntitySchema.Defaults.blockoutTimes);
    parameters[CalleeEntitySchema.eventType] = SchemaField.string(CalleeEntitySchema.Defaults.eventType);
    parameters[CalleeEntitySchema.assignedUserTypes] = SchemaField.shortStringArray(CalleeEntitySchema.Defaults.assignedUserTypes);
    parameters[CalleeEntitySchema.userList] = SchemaField.shortStringArray(CalleeEntitySchema.Defaults.userList);
    /* eslint-enable */

    return parameters;
  }
}
