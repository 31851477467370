import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

@Component({
  selector: 'app-accordion-wizard-step',
  templateUrl: './accordion-wizard-step.component.html',
  styleUrls: ['./accordion-wizard-step.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionWizardStepComponent {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs / Outputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  @Input() label = '';

  @Input() hasError = false;

  active = false;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  constructor(public changeDetectorRef: ChangeDetectorRef) {}
}
