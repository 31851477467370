import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isNil } from 'lodash';

import { EntityStats, UserData, UserLoadSlidingWindow } from '@pwp-common';

@Component({
  selector: 'app-event-load-overview',
  templateUrl: './event-load-overview.component.html',
  styleUrls: ['./event-load-overview.component.css'],
})
export class EventLoadOverviewComponent implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input / Output
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() entityStatsMap: Map<string, EntityStats>;

  @Input() allUserDataMap: Map<string, UserData>;

  @Input() slidingWindowDurationDays: number;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  userLoads: UserLoadSlidingWindow[] = [];

  traces: any[] = [];

  numTopUsers = 50;

  title = '';

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if ('entityStatsMap' in changes || 'allUserDataMap' in changes || 'slidingWindowDurationDays' in changes) {
      this.refreshData();
    }
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  private refreshData() {
    this.userLoads = [];
    this.traces = [];
    if (isNil(this.entityStatsMap) || isNil(this.allUserDataMap) || isNil(this.slidingWindowDurationDays)) {
      return;
    }

    this.title = `Largest ${this.numTopUsers} Shift Loads / Past ${this.slidingWindowDurationDays} Days`;
    for (const entityStats of this.entityStatsMap.values()) {
      const hoursForOneEntity = new UserLoadSlidingWindow(entityStats as EntityStats, this.allUserDataMap, this.slidingWindowDurationDays);
      this.userLoads.push(hoursForOneEntity);
    }

    this.userLoads = UserLoadSlidingWindow.getTopUsersByEventLoad(this.userLoads, this.numTopUsers);
    const trace = {
      x: this.userLoads.map((z) => z.totalEventHours),
      y: this.userLoads.map((z) => z.displayName),
      name: `Shift Hours / ${this.slidingWindowDurationDays} Days`,
      orientation: 'h',
      marker: {
        color: 'rgba(255,153,51,0.6)',
        width: 1,
      },
      type: 'bar',
    };

    this.traces = [trace];
  }
}
