import {
  CommunicationSessionEventLogDialAsyncServiceRequestDelivery,
  CommunicationSessionEventLogDialConference,
  CommunicationSessionEventLogDialReservationOffer,
  CommunicationSessionEventLogName,
  CommunicationSessionEventLogSendMessageNotification,
  CommunicationSessionEventLogSendReservationOffer,
  DBDocObject,
} from '@pwp-common';

import { CommunicationSessionEventBase } from '../base/communication-session-event-base';
import { DialAsyncServiceDeliveryRow } from '../dial-async-service-delivery-row/dial-async-service-delivery-row';
import { DialConferenceRow } from '../dial-conference-row/dial-conference-row';
import { DialReservationOfferRow } from '../dial-reservation-offer-row/dial-reservation-offer-row';
import { SendMessageNotificationRow } from '../send-message-notification-row/send-message-notification-row';
import { SendReservationOfferRow } from '../send-reservation-offer-row/send-reservation-offer-row';

import { MakeCommunicationSessionEventRowInput, MakeTableRowsInput } from './interfaces';

const makeDialPhoneRow = ({
  allDataUsers,
  event,
  timezone,
}: MakeCommunicationSessionEventRowInput): CommunicationSessionEventBase<DBDocObject> | undefined => {
  if (event instanceof CommunicationSessionEventLogDialAsyncServiceRequestDelivery) {
    return new DialAsyncServiceDeliveryRow({ dialedCall: event, allDataUser: allDataUsers, timezone });
  }

  if (event instanceof CommunicationSessionEventLogDialConference) {
    return new DialConferenceRow({ dialedCall: event, allDataUser: allDataUsers, timezone });
  }

  if (event instanceof CommunicationSessionEventLogDialReservationOffer) {
    return new DialReservationOfferRow({ dialedCall: event, allDataUser: allDataUsers, timezone });
  }

  return undefined;
};

const makeSendMessageRow = ({
  allDataUsers,
  event,
  timezone,
}: MakeCommunicationSessionEventRowInput): CommunicationSessionEventBase<DBDocObject> | undefined => {
  if (event instanceof CommunicationSessionEventLogSendReservationOffer) {
    return new SendReservationOfferRow({ event, allDataUser: allDataUsers, timezone });
  }

  if (event instanceof CommunicationSessionEventLogSendMessageNotification) {
    return new SendMessageNotificationRow({ event, allDataUser: allDataUsers, timezone });
  }

  return undefined;
};

const makeCommunicationSessionEventRow = (
  params: MakeCommunicationSessionEventRowInput,
): CommunicationSessionEventBase<DBDocObject> | undefined => {
  switch (params.event.getType()) {
    case CommunicationSessionEventLogName.dialPhone:
      return makeDialPhoneRow(params);
    case CommunicationSessionEventLogName.sendMessage:
      return makeSendMessageRow(params);
    default:
      return undefined;
  }
};

export const makeCommunicationSessionEventRows = ({
  communicationSessionEvents = [],
  ...params
}: MakeTableRowsInput): CommunicationSessionEventBase<DBDocObject>[] =>
  communicationSessionEvents.reduce((acc, event) => {
    const row = makeCommunicationSessionEventRow({ event, ...params });

    return row ? [...acc, row] : acc;
  }, []);
