import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-accordion-wizard-step-content-footer',
  templateUrl: './accordion-wizard-step-content-footer.component.html',
  styleUrls: ['./accordion-wizard-step-content-footer.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionWizardStepContentFooterComponent implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs / Outputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  @Input() stepIndex = 0;

  @Input() numSteps = 0;

  @Input() hasError = false;

  @Input() continueLoadingIndicator = false;

  @Output() continue = new EventEmitter<void>();

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  ngOnInit(): void {}

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Buttons
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  continueClick() {
    this.continue.emit();
  }
}
