import { FormControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

import { SchemaField } from '@pwp-common';

export const emailArrayValidator: ValidatorFn = (control: FormControl<string[]>) => {
  const items = control.value ?? [];
  const invalidEmails = items.filter((item) => !isNil(SchemaField.email(undefined).validate(item).error));

  if (invalidEmails.length === 0) {
    return null;
  }

  return { invalidEmails };
};
