import {SubmitTicketResponseSchema} from './submit-ticket-response-schema';
import {ResponseObj} from '../../generic/response/response-obj';
import {SubmitTicketResponseConstructor} from './submit-ticket-response-constructor';

export class SubmitTicketResponse extends ResponseObj {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: SubmitTicketResponseConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): SubmitTicketResponse {
    return new SubmitTicketResponse(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    const serialized = super.serialize(SubmitTicketResponse.getSchema(), 'api');
    return serialized;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SubmitTicketResponseSchema {
    return new SubmitTicketResponseSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
