/**
 * List all possible commands the system is capable of processing.
 */
export enum VoiceResponseCommandName {
  addBlockedCaller = 'addBlockedCaller',
  autoAcceptCall = 'autoAcceptCall',
  createAsyncServiceRequest = 'createAsyncServiceRequest',
  dialCallList = 'dialCallList',
  enforceBlockedCaller = 'enforceBlockedCaller',
  enforceServiceExceptions = 'enforceServiceExceptions',
  enforceServiceLimit = 'enforceServiceLimit',
  execEntrypoint = 'execEntrypoint',
  execIVR = 'execIVR',
  flagServiceRequest = 'flagServiceRequest',
  forward = 'forward',
  hangup = 'hangup',
  joinConference = 'joinConference',
  play = 'play',
  playOrSay = 'playOrSay',
  say = 'say',
  sendMessageTemplate = 'sendMessageTemplate',
  setContext = 'setContext',
  switch = 'switch',
  takeVoicemail = 'takeVoicemail',
}
