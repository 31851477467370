import type {ValidationResult} from 'joi';
import {cloneDeep} from 'lodash';

import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {VoiceResponseCommandName} from '../any-voice-response-command/voice-response-command-name';
import {VoiceResponseCommand} from '../generic/voice-response-command';
import {VoiceResponseCommandSchema} from '../generic/voice-response-command-schema';

import {ForwardConstructor} from './forward-constructor';
import {ForwardSchema} from './forward-schema';

export class Forward extends VoiceResponseCommand {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  private to!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ForwardConstructor) {
    super(
      Object.assign(parameters, {
        [VoiceResponseCommandSchema.commandName]: VoiceResponseCommandName.forward,
      })
    );
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: ValidationResult): Forward {
    return new Forward(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(Forward.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ForwardSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getTo(): string {
    return cloneDeep(this.to);
  }
}
