import {addDuration} from './add-duration';
import {rruleIsHourly, rruleIsMinutely} from './fields';
import moment from 'moment-timezone';
import {cloneDeep} from 'lodash';
import {addDtStartAndTimezoneToRRule} from './add-dtstart-and-timezone-to-rrule';

/**
 * Determine the end date for the event if the start is as specified.
 *
 * https://momentjs.com/guides/#/lib-concepts/date-time-math/
 */
export const getEventEndTimeFor = ({
  thisEventStartTime,
  templateRRuleStr,
  templateEventStartTime,
  templateEventEndTime,
  eventTimezone,
}: {
  thisEventStartTime: moment.Moment;
  templateRRuleStr: string;
  templateEventStartTime: moment.Moment;
  templateEventEndTime: moment.Moment;
  eventTimezone: string;
}) => {
  const templateEventStartWithTzFix = cloneDeep(templateEventStartTime).tz(eventTimezone);
  const templateEventEndTimeWithTzFix = cloneDeep(templateEventEndTime).tz(eventTimezone);
  const eventStartRRule = addDtStartAndTimezoneToRRule(templateRRuleStr, templateEventStartWithTzFix, eventTimezone);
  if (rruleIsHourly(eventStartRRule.toString(), true) || rruleIsMinutely(eventStartRRule.toString(), true)) {
    const parsedOptions = eventStartRRule.options;
    const withAddedDuration = addDuration(thisEventStartTime, parsedOptions);
    return moment(withAddedDuration).tz(eventTimezone);
  }

  /**
   * Otherwise, end time is just the next timestamp that has the
   * hh:mm:ss portion equal to what is specified in endTime.
   */

  const endTime = cloneDeep(thisEventStartTime)
    .tz(eventTimezone)
    .set('hour', templateEventEndTimeWithTzFix.get('hour'))
    .set('minute', templateEventEndTimeWithTzFix.get('minute'))
    .set('second', templateEventEndTimeWithTzFix.get('second'))
    .set('millisecond', templateEventEndTimeWithTzFix.get('millisecond'));

  if (templateEventEndTimeWithTzFix.diff(templateEventStartWithTzFix, 'month') > 0) {
    // Implementation details: https://momentjs.com/docs/#/displaying/difference/
    throw new Error('EventConfig.getEndTimeFor: Repeating events with duration >= 1 month is not yet implemented.');
  }

  const dayDiff = templateEventEndTimeWithTzFix.startOf('day').diff(templateEventStartWithTzFix.startOf('day'), 'day');
  endTime.add(dayDiff, 'day');

  return endTime;
};
