import {LanguageDefaults} from './language-defaults';

/**
 *
 * This class maintains a list of supported BCP-47 language codes, as well as
 * a choice of Google text to speech model & dialect, Twilio Gather language-region code,
 * and if applicable Twilio Say language-region code and voice.
 *
 * Languages provided without region information are intended to contain suitable defaults
 * for our customers.
 *
 * For an explaination of BCP-47 see:
 * https://www.w3.org/International/questions/qa-choosing-language-tags
 *
 * Twilio Say Languages:
 * alice: https://www.twilio.com/docs/voice/twiml/say#attributes-language
 * polly: https://www.twilio.com/docs/voice/twiml/say/text-speech#amazon-polly
 *
 * Twilo Gather Languages: https://www.twilio.com/docs/voice/twiml/gather#languagetags
 * Google Voices: https://cloud.google.com/text-to-speech/docs/voices
 */
export class SupportedLanguages {
  /*eslint-disable */
  // Latest sdk (twilio@3.67.0) does not appear to support using Polly.Zhiyu for Mandarin Chinese, and no Polly voice exists for Yue Chinese/Cantonese
  public static readonly af = new LanguageDefaults('af', 'Afrikaans', 'af-ZA',undefined,undefined,'af-ZA-Standard-A','af-ZA');
  public static readonly ar = new LanguageDefaults('ar', 'Arabic', 'ar-DZ',undefined,undefined,'ar-XA-Wavenet-A','ar-XA');
  public static readonly bg = new LanguageDefaults('bg', 'Bulgarian', 'bg-BG',undefined,undefined,'bg-bg-Standard-A','bg-BG');
  public static readonly bn = new LanguageDefaults('bn', 'Bengali/Bangla', 'bn-BD',undefined,undefined,'bn-IN-Wavenet-A','bn-IN');
  public static readonly ca = new LanguageDefaults('ca', 'Catalan/Valencian', 'ca-ES',undefined,undefined,'ca-es-Standard-A','ca-ES');
  public static readonly cmn = new LanguageDefaults('cmn', 'Mandarin Chinese', 'cmn-Hans-CN', 'zh-CN', 'alice','cmn-CN-Wavenet-A','cmn-CN');
  public static readonly cs = new LanguageDefaults('cs', 'Czech', 'cs-CZ',undefined,undefined,'cs-CZ-Wavenet-A','cs-CZ');
  public static readonly da = new LanguageDefaults('da', 'Danish', 'da-DK','da-DK','Polly.Naja','da-DK-Wavenet-A','da-DK');
  public static readonly de = new LanguageDefaults('de', 'German', 'de-DE','de-DE','Polly.Marlene','de-DE-Wavenet-A','de-DE');
  public static readonly el = new LanguageDefaults('el', 'Modern Greek (1453-)', 'el-GR',undefined,undefined,'el-GR-Wavenet-A','el-GR');
  public static readonly en = new LanguageDefaults('en', 'English', 'en-US','en-US','Polly.Salli','en-US-Wavenet-C','en-US');
  public static readonly es = new LanguageDefaults('es', 'Spanish', 'es-US','es-US','Polly.Penelope','es-US-Wavenet-A','es-US');
  public static readonly fi = new LanguageDefaults('fi', 'Finnish', 'fi-FI',undefined,undefined,'fi-FI-Wavenet-A','fi-FI');
  public static readonly fil = new LanguageDefaults('fil', 'Filipino/Pilipino', 'fil-PH',undefined,undefined,'fil-PH-Wavenet-A','fil-PH');
  public static readonly fr = new LanguageDefaults('fr', 'French', 'fr-CA','fr-FR','Polly.Celine','fr-CA-Wavenet-A','fr-CA');
  public static readonly gu = new LanguageDefaults('gu', 'Gujarati', 'gu-IN',undefined,undefined,'gu-IN-Wavenet-A','gu-IN');
  public static readonly hi = new LanguageDefaults('hi', 'Hindi', 'hi-IN','hi-IN','Polly.Aditi','hi-IN-Wavenet-A','hi-IN');
  public static readonly hu = new LanguageDefaults('hu', 'Hungarian', 'hu-HU',undefined,undefined,'hu-HU-Wavenet-A','hu-HU');
  public static readonly id = new LanguageDefaults('id', 'Indonesian', 'id-ID',undefined,undefined,'id-ID-Wavenet-A','id-ID');
  public static readonly is = new LanguageDefaults('is', 'Icelandic', 'is-IS','is-IS','Polly.Dora','is-is-Standard-A','is-IS');
  public static readonly it = new LanguageDefaults('it', 'Italian', 'it-IT','it-IT','Polly.Bianca','it-IT-Wavenet-A','it-IT');
  public static readonly ja = new LanguageDefaults('ja', 'Japanese', 'ja-JP','ja-JP','Polly.Mizuki','ja-JP-Wavenet-A','ja-JP');
  public static readonly kn = new LanguageDefaults('kn', 'Kannada', 'kn-IN',undefined,undefined,'kn-IN-Wavenet-A','kn-IN');
  public static readonly ko = new LanguageDefaults('ko', 'Korean', 'ko-KR','ko-KR','Polly.Seoyeon','ko-KR-Wavenet-A','ko-KR');
  public static readonly ml = new LanguageDefaults('ml', 'Malayalam', 'ml-IN',undefined,undefined,'ml-IN-Wavenet-A','ml-IN');
  public static readonly ms = new LanguageDefaults('ms', 'Malay (macrolanguage)', 'ms-MY',undefined,undefined,'ms-MY-Wavenet-A','ms-MY');
  public static readonly nb = new LanguageDefaults('nb', 'Norwegian Bokmål', 'nb-NO','nb-NO','Polly.Liv','nb-NO-Wavenet-A','nb-NO');
  public static readonly nl = new LanguageDefaults('nl', 'Dutch/Flemish', 'nl-NL','nl-NL','Polly.Lotte','nl-BE-Wavenet-A','nl-BE');
  public static readonly pl = new LanguageDefaults('pl', 'Polish', 'pl-PL','pl-PL','Polly.Ewa','pl-PL-Wavenet-A','pl-PL');
  public static readonly pt = new LanguageDefaults('pt', 'Portuguese', 'pt-BR','pt-BR','Polly.Vitoria','pt-BR-Wavenet-A','pt-BR');
  public static readonly ro = new LanguageDefaults('ro', 'Romanian/Moldavian/Moldovan', 'ro-RO','ro-RO','Polly.Carmen','ro-RO-Wavenet-A','ro-RO');
  public static readonly ru = new LanguageDefaults('ru', 'Russian', 'ru-RU','ru-RU','Polly.Tatyana','ru-RU-Wavenet-A','ru-RU');
  public static readonly sk = new LanguageDefaults('sk', 'Slovak', 'sk-SK',undefined,undefined,'sk-SK-Wavenet-A','sk-SK');
  public static readonly sr = new LanguageDefaults('sr', 'Serbian', 'sr-RS',undefined,undefined,'sr-rs-Standard-A','sr-RS');
  public static readonly sv = new LanguageDefaults('sv', 'Swedish', 'sv-SE','sv-SE','Polly.Astrid','sv-SE-Wavenet-A','sv-SE');
  public static readonly ta = new LanguageDefaults('ta', 'Tamil', 'ta-IN',undefined,undefined,'ta-IN-Wavenet-A','ta-IN');
  public static readonly te = new LanguageDefaults('te', 'Telugu', 'te-IN',undefined,undefined,'te-IN-Standard-A','te-IN');
  public static readonly th = new LanguageDefaults('th', 'Thai', 'th-TH',undefined,undefined,'th-TH-Standard-A','th-TH');
  public static readonly tr = new LanguageDefaults('tr', 'Turkish', 'tr-TR','tr-TR','Polly.Filiz','tr-TR-Wavenet-A','tr-TR');
  public static readonly uk = new LanguageDefaults('uk', 'Ukrainian', 'uk-UA',undefined,undefined,'uk-UA-Wavenet-A','uk-UA');
  public static readonly vi = new LanguageDefaults('vi', 'Vietnamese', 'vi-VN',undefined,undefined,'vi-VN-Wavenet-A','vi-VN');
  public static readonly yue = new LanguageDefaults('yue', 'Yue Chinese/Cantonese', 'yue-Hant-HK','zh-HK','alice','yue-HK-Standard-A','yue-HK');

  public static readonly all = [SupportedLanguages.af,SupportedLanguages.ar,SupportedLanguages.bg,SupportedLanguages.bn,SupportedLanguages.ca,SupportedLanguages.cmn,SupportedLanguages.cs,SupportedLanguages.da,SupportedLanguages.de,SupportedLanguages.el,SupportedLanguages.en,SupportedLanguages.es,SupportedLanguages.fi,SupportedLanguages.fil,SupportedLanguages.fr,SupportedLanguages.gu,SupportedLanguages.hi,SupportedLanguages.hu,SupportedLanguages.id,SupportedLanguages.is,SupportedLanguages.it,SupportedLanguages.ja,SupportedLanguages.kn,SupportedLanguages.ko,SupportedLanguages.ml,SupportedLanguages.ms,SupportedLanguages.nb,SupportedLanguages.nl,SupportedLanguages.pl,SupportedLanguages.pt,SupportedLanguages.ro,SupportedLanguages.ru,SupportedLanguages.sk,SupportedLanguages.sr,SupportedLanguages.sv,SupportedLanguages.ta,SupportedLanguages.te,SupportedLanguages.th,SupportedLanguages.tr,SupportedLanguages.uk,SupportedLanguages.vi,SupportedLanguages.yue];
  /*eslint-disable */

  public static getDefaults(code: string): LanguageDefaults {
    for (const language of SupportedLanguages.all) {
      if (language.getShortCode() === code) {
        return language;
      }
    }
    throw new Error(`Not Implemented Error: The language with code='${code}' is not supported.`);
  }
}
