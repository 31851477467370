<ng-container *transloco="let t; read: 'inbox-dial-phone-confirm'">
  <p-toast position="bottom-center"></p-toast>

  <!--  Title-->
  <legend>{{ t('title') }}</legend>

  <!--  Content -->
  <form [formGroup]="form">
    <!--   Phone On & Ready -->
    <div class="field grid">
      <div>
        <p-checkbox
          ariaLabel="{{ t('phoneOnAndReadyLabel') }}"
          id="phoneOnAndReady"
          label="{{ t('phoneOnAndReadyLabel') }}"
          [binary]="true"
          [formControl]="phoneOnAndReady"
        >
        </p-checkbox>
        <div id="phoneOnAndReady-help">
          <p>{{ t('phoneOnAndReadyHelp') }}</p>
        </div>
      </div>
    </div>

    <!-- E164Phone   -->
    <div class="field grid" [hidden]="e164Phone.disabled">
      <label for="e164Phone" style="width: 200px">{{ t('e164PhoneLabel') }}</label>
      <div class="col">
        <app-phone-editor [formControl]="e164Phone" [inputId]="'e164Phone'"></app-phone-editor>
      </div>
    </div>

    <!-- CallerId   -->
    <div class="field grid">
      <label for="callerId" style="width: 200px">{{ t('callerIdLabel') }}</label>
      <div class="col">
        <app-obj-autocomplete-form-control
          id="callerId"
          [formControl]="callerId"
          [options$]="callerIds$"
        ></app-obj-autocomplete-form-control>
      </div>
    </div>
  </form>

  <!--  Footer-->
  <div class="row">
    <div *ngIf="callerId?.value" class="col flex justify-content-start align-items-end flex-wrap">
      {{ t('notice') }}
    </div>
    <div class="col flex justify-content-end flex-wrap">
      <p-button label="{{ t('cancelButtonLabel') }}" styleClass="p-button-outlined" type="button" (onClick)="cancelClick()"></p-button>
      <p-button label="{{ t('confirmButtonLabel') }}" type="button" [disabled]="!form.valid" (onClick)="confirmClick()"></p-button>
    </div>
  </div>
</ng-container>
