import { CommunicationSessionEventLogDialReservationOffer, UserData } from '@pwp-common';

import { CommunicationTaskName } from '../../../../../../../../common/src/objects/communication/communication-task';
import { RowCell } from '../../../../common/p-table/row-cell';
import { CommunicationSessionDialedCallLogBase } from '../base/communication-session-dialed-call-log-base';

export class DialReservationOfferRow extends CommunicationSessionDialedCallLogBase<CommunicationSessionEventLogDialReservationOffer> {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////
  constructor({
    dialedCall,
    allDataUser,
    timezone,
  }: {
    dialedCall: CommunicationSessionEventLogDialReservationOffer;
    allDataUser: Map<string, UserData>;
    timezone: string;
  }) {
    super({
      dialedCall,
      allDataUser,
      timezone,
      userId: dialedCall.getParticipant().getParticipantId(),
      userStatus: dialedCall.getUserStatus(),
    });
    this.updateOriginationReason(dialedCall);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Origination Reason
  //////////////////////////////////////////////////////////////////////////////////////////
  private updateOriginationReason(dialedCall: CommunicationSessionEventLogDialReservationOffer) {
    const translationKeys = {
      [CommunicationTaskName.callbackRequest]: 'cellOriginationReasonDialCallbackRequest',
      [CommunicationTaskName.conversationRequest]: 'cellOriginationReasonDialConversationRequest',
    };

    const translationKey = translationKeys[dialedCall.getCommunicationTaskType()];

    if (translationKey) {
      this.originationReason = new RowCell<string>({
        translationScope: 'communication-session-event-row',
        translationKey,
        translationObj: {
          ...this.userDisplayNameWithEmail.translationObj,
        },
        sortValue: translationKey,
        styleClass: undefined,
      });
    }
  }
}
