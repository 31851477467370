import { isNil } from 'lodash';

import { CommunicationSessionEventLogDialAsyncServiceRequestDelivery, UserData } from '@pwp-common';

import { RowCell } from '../../../../common/p-table/row-cell';
import { CommunicationSessionDialedCallLogBase } from '../base/communication-session-dialed-call-log-base';

export class DialAsyncServiceDeliveryRow extends CommunicationSessionDialedCallLogBase<CommunicationSessionEventLogDialAsyncServiceRequestDelivery> {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////
  constructor({
    dialedCall,
    allDataUser,
    timezone,
  }: {
    dialedCall: CommunicationSessionEventLogDialAsyncServiceRequestDelivery;
    allDataUser: Map<string, UserData>;
    timezone: string;
  }) {
    super({
      dialedCall,
      allDataUser,
      timezone,
      userId: undefined,
      userStatus: undefined,
    });
    this.updateUserDisplayNameWithEmail();
    this.updateOriginationReason(dialedCall, allDataUser);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // User Display Name With Email
  //////////////////////////////////////////////////////////////////////////////////////////

  private updateUserDisplayNameWithEmail() {
    this.userDisplayNameWithEmail = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellUserDisplayNameAsyncServiceRequester',
      translationObj: {},
      sortValue: undefined,
      styleClass: undefined,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Origination Reason
  //////////////////////////////////////////////////////////////////////////////////////////
  private updateOriginationReason(
    dialedCall: CommunicationSessionEventLogDialAsyncServiceRequestDelivery,
    allDataUser: Map<string, UserData>,
  ) {
    const requestedByUserId = dialedCall.getRequestedByUserId();

    const displayNameWithEmail = allDataUser.get(requestedByUserId)?.getDisplayNameWithEmail();
    if (isNil(displayNameWithEmail)) {
      this.originationReason = new RowCell<string>({
        translationScope: 'communication-session-event-row',
        translationKey: 'cellOriginationReasonAsyncServiceRequestDeliveryRequestedByDeletedUser',
        translationObj: {},
        sortValue: requestedByUserId,
      });
      return;
    }
    this.originationReason = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellOriginationReasonAsyncServiceRequestDeliveryRequestedBy',
      translationObj: {
        displayNameWithEmail,
      },
      sortValue: displayNameWithEmail,
    });
  }
}
