<ng-container *transloco="let t; read: 'modify-user-options-table'">
  <!-- Toast notifications configuration -->
  <p-toast position="bottom-center"></p-toast>

  <mat-card>
    <mat-card-content>
      <p-table
        #dt
        dataKey="userId"
        responsiveLayout="scroll"
        [columns]="selectedColumns"
        [loading]="loading"
        [resizableColumns]="true"
        [value]="rows"
      >
        <!-- Select Columns to Display -->
        <ng-template pTemplate="caption">
          <legend>{{ t('users') }}</legend>
          <div style="text-align: left">
            <p-multiSelect
              defaultLabel="{{ t('columnSelectTitle') }}"
              optionLabel="header"
              selectedItemsLabel="{0} {{ t('columnsSelected') }}"
              [options]="cols"
              [style]="{ minWidth: '200px' }"
              [(ngModel)]="selectedColumns"
            ></p-multiSelect>
            &nbsp;
            <button mat-icon-button aria-label="Download" (click)="dt.exportCSV()">
              <mat-icon>download</mat-icon>
            </button>
          </div>
          <div style="text-align: right">
            <i class="pi pi-search" style="margin: 4px 4px 0 0"></i>
            <input
              pInputText
              placeholder="{{ t('globalFilter') }}"
              size="50"
              style="width: auto"
              type="text"
              (input)="dt.filterGlobal($event.target.value, 'contains')"
            />
          </div>
        </ng-template>

        <!-- Header -->
        <ng-template pTemplate="header" let-columns>
          <tr>
            <th style="width: 3em"></th>
            <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col.field">
              {{ col.header }}
              <p-sortIcon
                ariaLabel="Activate to sort"
                ariaLabelAsc="Activate to sort in ascending order"
                ariaLabelDesc="Activate to sort in descending order"
                [field]="col.field"
              ></p-sortIcon>
            </th>
          </tr>
        </ng-template>

        <!-- Display Rows -->
        <ng-template pTemplate="body" let-columns="columns" let-expanded="expanded" let-rowData>
          <tr [ngClass]="rowData.getCSSClass()">
            <td>
              <a href="#" [pRowToggler]="rowData">
                <i [ngClass]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></i>
              </a>
            </td>
            <td *ngFor="let col of columns" class="ui-resizable-column">
              {{ rowData[col.field] }}
            </td>
          </tr>
        </ng-template>

        <!-- Expansion Row -->
        <ng-template pTemplate="rowexpansion" let-columns="columns" let-rowData>
          <tr>
            <td [attr.colspan]="columns.length + 1">
              <app-all-data-user-editor [obj]="rowData.allDataUser" (userIdUpdated)="userUpdated($event)"></app-all-data-user-editor>
            </td>
          </tr>
        </ng-template>
        <!-- Empty Message -->
        <ng-template pTemplate="emptymessage" let-columns>
          <tr>
            <td [attr.colspan]="columns.length">
              {{ t('emptyMessage') }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </mat-card-content>

    <!-- Actions -->
    <mat-card-actions>
      <button mat-button color="primary" (click)="openAddUserDialog()">{{ t('addNewUser') }}</button>
    </mat-card-actions>
  </mat-card>
</ng-container>
