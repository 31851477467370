import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { PlayOrSay, PlayOrSaySchema } from '@pwp-common';

import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ServiceCommandEditorPlayOrSayComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ServiceCommandEditorPlayOrSayComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-service-command-editor-play-or-say',
  templateUrl: './service-command-editor-play-or-say.component.html',
  styleUrls: ['./service-command-editor-play-or-say.component.css'],
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class ServiceCommandEditorPlayOrSayComponent extends FormGroupControlValueAccessor<any, any> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    const formConfig = {} as any;
    formConfig[PlayOrSaySchema.commandName] = new UntypedFormControl(PlayOrSaySchema.Defaults.commandName, [Validators.required]);
    formConfig[PlayOrSaySchema.audioId] = new UntypedFormControl(undefined, [Validators.required]);
    formConfig[PlayOrSaySchema.loop] = new UntypedFormControl(PlayOrSaySchema.Defaults.loop, [Validators.min(1), Validators.max(1000)]);
    this.form = new UntypedFormGroup(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): any {
    return PlayOrSay.deserialize(value).serialize();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get audioId() {
    return this.form.get(PlayOrSaySchema.audioId) as UntypedFormControl;
  }

  get loop() {
    return this.form.get(PlayOrSaySchema.loop) as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  public makeValidationErrors(): ValidationErrors {
    return {
      'service-command-editor-play-or-say': this.form.value,
    };
  }
}
