import {cloneDeep} from 'lodash';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {VoiceResponseCommand} from '../../../voice-response-command/generic/voice-response-command';
import {SwitchCaseConstructor} from './switch-case-constructor';
import {SwitchCaseSchema} from './switch-case-schema';
import {Displayable} from '../../../generic/db-doc/displayable';

export class SwitchCase extends SerializableObject implements Displayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected commands!: VoiceResponseCommand[];
  protected value!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: SwitchCaseConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): SwitchCase {
    return new SwitchCase(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(SwitchCase.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new SwitchCaseSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCommands(): VoiceResponseCommand[] {
    return cloneDeep(this.commands);
  }

  public getValue(): string {
    return cloneDeep(this.value);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setCommands(commands: VoiceResponseCommand[]): SwitchCase {
    this.commands = commands;
    return this;
  }

  public setValue(value: string): SwitchCase {
    this.value = value;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Displayable
  /////////////////////////////////////////////////////////////////////////////

  public getId(): string {
    return cloneDeep(this.value);
  }

  public getDisplayName(): string {
    return this.getId();
  }
}
