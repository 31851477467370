import Joi from 'joi';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';
import {VoiceResponseCommand} from '../../../voice-response-command/generic/voice-response-command';
import {AnyVoiceResponseCommandSchema} from '../../any-voice-response-command/any-voice-response-command-schema';
import {AnyVoiceResponseCommand} from '../../any-voice-response-command/any-voice-response-command';
export class SwitchCaseSchema extends SerializableObjectSchema {
  // This case statement should be executed if selected variable is equal to this value
  public static readonly value = 'value';

  // Commands to execute if this case is selected
  public static readonly commands = 'commands';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly defaultCaseIdentifier = 'SWITCH_DEFAULT_CASE_IDENTIFIER';
  };

  static Defaults = class {
    public static readonly value = SwitchCaseSchema.Constants.defaultCaseIdentifier;
    public static readonly commands: VoiceResponseCommand[] = [];
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Return the schema keys. This needs to be manually specified whenever the SchemaDefinition is a Joi.AnySchema.
   */
  public getSchemaKeys(includeDocId = false): string[] {
    return [SwitchCaseSchema.value, SwitchCaseSchema.commands];
  }

  private generateSchemaDefinition(includeShared: boolean): import('joi').AnySchema {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[SwitchCaseSchema.value] = SchemaField.string(SwitchCaseSchema.Defaults.value);
    parameters[SwitchCaseSchema.commands] = SchemaField.shortObjArray(AnyVoiceResponseCommand, SwitchCaseSchema.Defaults.commands, undefined, Joi.link(AnyVoiceResponseCommandSchema.getSchemaId()));
    /* eslint-enable */

    if (includeShared) {
      return Joi.object(parameters).shared(new AnyVoiceResponseCommandSchema().getJoiSchema());
    } else {
      return Joi.object(parameters);
    }
  }

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    return this.generateSchemaDefinition(true);
  }

  public getJoiSchemaWithoutShared(): import('joi').AnySchema {
    return this.generateSchemaDefinition(false);
  }
}
