import { isNil } from 'lodash';

import { CallList, EntityStatsChunk, formatDuration } from '@pwp-common';

import { createReportRow } from '../create-report-row/create-report-row';
import { createValuesForEntityStats } from '../create-values-for-entity-stats/create-values-for-entity-stats';
import { AdminReportCallStatsRowInput, ReportRow } from '../interfaces';

const getFormattedDuration = (statsChunk: EntityStatsChunk, callList: CallList): string => {
  const holdTime = statsChunk.getCalls(callList)?.avgHoldTimeForAnsweredCallsMS();

  return isNil(holdTime) ? undefined : formatDuration(holdTime);
};

export const createAverageHoldTimeForAnsweredCallsRow = ({ entityStats, callList }: AdminReportCallStatsRowInput): ReportRow =>
  createReportRow(
    'averageHoldTimeForAnsweredCalls',
    createValuesForEntityStats(entityStats, (statsChunk) => getFormattedDuration(statsChunk, callList)),
    { callList },
  );
