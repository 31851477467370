import { Component, OnInit } from '@angular/core';

import { ConversationMessageTemplateType } from '@pwp-common';

@Component({
  selector: 'app-conversation-message-template-editor-first-send',
  templateUrl: './conversation-message-template-editor-first-send.component.html',
  styleUrls: ['./conversation-message-template-editor-first-send.component.css'],
})
export class ConversationMessageTemplateEditorFirstSendComponent implements OnInit {
  ////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////

  firstSend = ConversationMessageTemplateType.firstSend;

  ////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////
  constructor() {}

  ngOnInit(): void {}
}
