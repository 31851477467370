import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {ConversationResponse} from '../../generic/conversation-response/conversation-response';
import {ConversationResponseSchema} from '../../generic/conversation-response/conversation-response-schema';
import {ConversationResponseConfigureConstructor} from './conversation-response-configure-constructor';
import {ConversationResponseConfigureSchema} from './conversation-response-configure-schema';
import {ConversationResponseConfigureError} from './conversation-response-configure-error';
import {ConversationRequestName} from '../../../conversation-request-name';

export class ConversationResponseConfigure extends ConversationResponse<ConversationResponseConfigureError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationResponseConfigureConstructor) {
    (parameters as any)[ConversationResponseSchema.type] = ConversationRequestName.configure;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationResponseConfigure {
    return new ConversationResponseConfigure(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationResponseConfigure.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationResponseConfigureSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
