/**
 * List of reasons call may be disconnected by our servers.
 */
export enum DisconnectReason {
  // The incoming call was disconnected because this call list has reached capacity
  noCapacity = 'noCapacity',

  // Call was disconnected because there are no users left to dial
  noUsersLeftToDial = 'noUsersLeftToDial',

  /** Counselor accepted call, but client disconnected before we could connect them. */
  incomingCallNotActiveCounselorAccepted = 'incomingCallNotActiveCounselorAccepted',

  enforceBlockedCaller = 'enforceBlockedCaller',

  error = 'error',
}
