import { orderBy } from 'lodash';

import { DBDocObject } from '@pwp-common';

import { CommunicationSessionEventBase } from '../base/communication-session-event-base';

import { MakeTableRowsInput } from './interfaces';
import { makeCommunicationSessionEventRows } from './make-communication-session-event-rows';
import { makeDialedCallLogRows } from './make-dialed-call-rows';

export const makeTableRows = (params: MakeTableRowsInput): CommunicationSessionEventBase<DBDocObject>[] => {
  const rows = [...makeDialedCallLogRows(params), ...makeCommunicationSessionEventRows(params)];

  return orderBy(rows, [(z) => z.createTime.valueOf()], ['asc']);
};
