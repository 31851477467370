import {SchemaField} from '../../../../generic/serialization/schema-field';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';

export class ServiceExceptionExecutionSchema extends SerializableObjectSchema {
  public static readonly actionExecuted = 'actionExecuted';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly actionExecuted = false;
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([ServiceExceptionExecutionSchema.actionExecuted]);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as {[key: string]: import('joi').AnySchema};

    /* eslint-disable */
    parameters[ServiceExceptionExecutionSchema.actionExecuted] = SchemaField.boolean(ServiceExceptionExecutionSchema.Defaults.actionExecuted);
    /* eslint-enable */

    return parameters;
  }
}
