<ng-template #objEditor>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Display Name -->
        <mat-form-field class="full-width">
          <textarea matInput placeholder="Display Name" class="full-width" formControlName="displayName"></textarea>
          <span *ngIf="displayName.invalid && displayName.touched"> Display Name Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Description -->
        <mat-form-field class="full-width">
          <textarea matInput placeholder="Description" class="full-width" formControlName="description"></textarea>
          <span *ngIf="description.invalid && description.touched"> Description Invalid </span>
        </mat-form-field>
      </div>
    </div>
  </form>

  <!-- Notify via SMS of VoicemailCallListId -->
  <app-obj-select
    [label]="'Notify via SMS of Voicemail Call List'"
    [orderedItems]="callListsArray | async"
    [(kvPair)]="selectedNotifyViaSMSOfVoicemailCallListKVPair"
  ></app-obj-select>
</ng-template>

<app-obj-select-and-edit
  [doDelete]="doDelete"
  [doRefresh]="doRefresh"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [label]="'Voicemail Config'"
  [modifiedSelectedItem]="getUpdatedObj()"
  [objIsValid]="isValidObjScoper"
  [orderedItems]="itemsArray | async"
  [selectedKVPair]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (selectedKVPairChange)="onSelectedKVPairChange($event)"
>
</app-obj-select-and-edit>
