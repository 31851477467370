import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../../generic/serialization/schema-field';

export class CommunicationWorkerAttributesTaskQueueSchema extends SerializableObjectSchema {
  public static readonly index = 'index';
  /*
   * WARNING: Do not delete this legacy field, as it is stored in Twilio TaskRouter configuration.
   * Legacy and deprecated field: `isLast`.
   * Can be removed upon completing PW-498
   * (Simplify Rollbacks: https://helplinesoftware.atlassian.net/browse/PW-498),
   * allowing for rollback support after schema changes were made to a document.
   *
   */
  public static readonly isLast = 'isLast';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly index = undefined;
    public static readonly isLast = false;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationWorkerAttributesTaskQueueSchema.index] = SchemaField.number(CommunicationWorkerAttributesTaskQueueSchema.Defaults.index).required()
    parameters[CommunicationWorkerAttributesTaskQueueSchema.isLast] = SchemaField.boolean(CommunicationWorkerAttributesTaskQueueSchema.Defaults.isLast).optional()
    /* eslint-enable */

    return parameters;
  }
}
