import Joi from 'joi';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {AsyncServiceRequestE164PhoneSchema} from '../../async-service-request-type/async-service-request-e164-phone/async-service-request-e164-phone-schema';
import {AsyncServiceRequestSchema} from '../async-service-request/async-service-request-schema';

export class AnyAsyncServiceRequestSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyAsyncServiceRequest';

  private static computedSchema = Joi.alternatives()
    .try(...[new AsyncServiceRequestE164PhoneSchema().getJoiSchema()])
    .id(AnyAsyncServiceRequestSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyAsyncServiceRequestSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    return AnyAsyncServiceRequestSchema.computedSchema;
  }

  public getCollection(orgId: string) {
    return new AsyncServiceRequestSchema().getCollection(orgId);
  }

  public getCollectionName() {
    return new AsyncServiceRequestSchema().getCollectionName();
  }
}
