import type {ValidationResult} from 'joi';

import {AnySerializableObject} from '../../../../../objects/generic/serialization/any-serializable-object/any-serializable-object';
import {ObjectBuilder} from '../../../../../objects/generic/serialization/interfaces';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {UserRequestName} from '../../../user-request-name';
import {UserResponseChangeOrgId} from '../../responses/user-response-change-org-id/user-response-change-org-id';
import {UserResponseCreateUser} from '../../responses/user-response-create-user/user-response-create-user';

import {AnyUserResponseSchema} from './any-user-response-schema';

export abstract class AnyUserResponse extends AnySerializableObject {
  protected static getObjectBuilder(validationResult: ValidationResult): ObjectBuilder {
    const typeString = validationResult.value[AnyUserResponseSchema.type] as keyof typeof UserRequestName;
    const type = UserRequestName[typeString];

    if (type === undefined) {
      throw new Error(`AnyUserResponse._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (type) {
      case UserRequestName.changeOrgId:
        return UserResponseChangeOrgId;
      case UserRequestName.createUser:
        return UserResponseCreateUser;
      default:
        throw new Error(`AnyUserResponse._deserialize Unknown type: ${type}`);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyUserResponseSchema();
  }
}
