import {ParsedOptions} from './private/types';
import {DateTime} from './private/datetime';
import moment from 'moment-timezone';

export const addDuration = (startTime: moment.Moment, options: ParsedOptions) => {
  // Compute the result assuming start and end are in the same timezone.
  const resultMinusOffset = DateTime.fromDate(startTime.toDate());
  resultMinusOffset.add(options, false);
  const arrNoOffset = [
    resultMinusOffset.getYear(),
    resultMinusOffset.getMonth() - 1,
    resultMinusOffset.getDay(),
    resultMinusOffset.getHours(),
    resultMinusOffset.getMinutes(),
    resultMinusOffset.getSeconds(),
  ];
  const resultMinusOffsetDate = new Date(Date.UTC(...(arrNoOffset as [any, any])));

  // Add offset if necessary
  const offset = moment(resultMinusOffsetDate).tz(options.tzid!).utcOffset() - startTime.utcOffset();

  if (offset >= 0) {
    return resultMinusOffsetDate;
  }
  const arr = [
    resultMinusOffset.getYear(),
    resultMinusOffset.getMonth() - 1,
    resultMinusOffset.getDay(),
    resultMinusOffset.getHours(),
    resultMinusOffset.getMinutes() - offset,
    resultMinusOffset.getSeconds(),
  ];
  const result = new Date(Date.UTC(...(arr as [any, any])));
  return result;
};
