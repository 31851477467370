import {SchemaField} from '../../../../generic/serialization/schema-field';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';

export class ServiceLimitExecutionSchema extends SerializableObjectSchema {
  public static readonly actionExecuted = 'actionExecuted';
  public static readonly exceeded = 'exceeded';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly actionExecuted = false;
    public static readonly exceeded = false;
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([ServiceLimitExecutionSchema.exceeded, ServiceLimitExecutionSchema.actionExecuted]);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as {[key: string]: import('joi').AnySchema};

    /* eslint-disable */
    parameters[ServiceLimitExecutionSchema.actionExecuted] = SchemaField.boolean(ServiceLimitExecutionSchema.Defaults.actionExecuted);
    parameters[ServiceLimitExecutionSchema.exceeded] = SchemaField.boolean(ServiceLimitExecutionSchema.Defaults.exceeded);
    /* eslint-enable */

    return parameters;
  }
}
