/**
 * All schema classes extend this class.
 */
export abstract class BlobLocation {
  // Constants associated with schema
  static GenericCollections = class {
    public static readonly orgs = 'orgs';
  };

  /**
   * Return the collection to which this object belongs
   */
  public abstract getStorageDir(orgId: string, ...otherFields: string[]): string;

  /**
   * Return the collection to which this object belongs
   */
  public abstract getFileFullPath(...fields: any[]): string;

  /**
   * Return the collection to which this object belongs
   */
  public abstract getAllowedFileExtensions(): string[];

  /**
   * All data specific to the given organization is stored under the specified doc.
   * @param orgId Organization Id
   */
  public orgDir(orgId: string): string {
    return `${BlobLocation.GenericCollections.orgs}/${orgId}`;
  }
}
