import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { VRCAudioMetadata } from '@pwp-common';

import { DBQuery } from '../../../../../services/generic/interfaces';
import { VRCAudioMetadataService } from '../../../../../services/vrc-audio/vrc-audio-metadata/vrc-audio-metadata.service';

@Injectable({
  providedIn: 'root',
})
export class ServiceOperationEditorService {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Cache
  /////////////////////////////////////////////////////////////////////////////////////////////

  private vrcAudioCache: Observable<VRCAudioMetadata[]> = undefined;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private vrcAudioService: VRCAudioMetadataService) {}

  /////////////////////////////////////////////////////////////////////////////////////////////
  // VRC Audio
  /////////////////////////////////////////////////////////////////////////////////////////////

  public getVRCAudio(): Observable<VRCAudioMetadata[]> {
    const query: DBQuery[] = [
      /**
       *
       * For now we don't set a query (as below), because that may cause issues with selecting audio
       * is currently being processed
       *       {fieldPath: VRCAudioMetadataSchema.wasProcessedByServer, opStr: '==', value: true}
       */
    ];
    this.vrcAudioCache = this.vrcAudioCache ?? this.vrcAudioService.getDocsArray(query, undefined, undefined, true);
    return this.vrcAudioCache;
  }
}
