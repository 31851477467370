import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {PossibleAdminRole} from '../../../../../objects/user/admin-roles/types';
import {PossibleRole} from '../../../../../objects/user/roles/types';
import {EventRequestName} from '../../../event-request-name';
import {EventRequestTransactionConstructor} from './event-request-transaction-constructor';
import {EventRequestTransactionSchema} from './event-request-transaction-schema';
import {EventRequest} from '../../generic/event-request/event-request';
import {EventRequestSchema} from '../../generic/event-request/event-request-schema';
import {EventRequestTransactionItem} from '../../../../../objects/event/event-request-transaction-item/event-request-transaction-item';

export class EventRequestTransaction extends EventRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected items!: EventRequestTransactionItem[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EventRequestTransactionConstructor) {
    (parameters as any)[EventRequestSchema.type] = EventRequestName.eventRequestTransaction;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EventRequestTransaction {
    return new EventRequestTransaction(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EventRequestTransaction.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new EventRequestTransactionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////
  public getRequiredRoles(): PossibleRole[] {
    return [];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getItems() {
    return cloneDeep(this.items);
  }
}
