import { AbstractControl, UntypedFormControl, ValidatorFn } from '@angular/forms';

import { ValidationResult } from './validation-result';

export class PasswordValidator {
  private static readonly digitValues: Set<string> = new Set(Array.from('0123456789'));

  private static readonly symbolValues: Set<string> = new Set(Array.from('!"#$%&\'()*+,-./:;<=>?@[]^_`{|}~'));

  public static strong(control: UntypedFormControl): ValidationResult {
    const hasNumber = /\d/.test(control.value);
    const hasLower = /[a-z]/.test(control.value);
    let hasSymbol = false;

    for (const char of Array.from((control.value as string) || '')) {
      if (PasswordValidator.symbolValues.has(char)) {
        hasSymbol = true;
      }
    }

    const valid = hasNumber && hasLower && hasSymbol;
    if (!valid) {
      // return what´s not valid
      return { strong: true };
    }
    return null;
  }

  public static containsDigitValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let hasChar = false;

      for (const char of Array.from((control.value as string) || '')) {
        if (PasswordValidator.digitValues.has(char)) {
          hasChar = true;
        }
      }

      return !hasChar ? { missingDigit: { value: control.value } } : null;
    };
  }

  public static containsSymbolValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      let hasSymbol = false;

      for (const char of Array.from((control.value as string) || '')) {
        if (PasswordValidator.symbolValues.has(char)) {
          hasSymbol = true;
        }
      }

      return !hasSymbol ? { missingSymbol: { value: control.value } } : null;
    };
  }
}
