import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment-timezone';
import { Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';

import { latestGitCommitHash } from '../environments/config';

import { OrgDataService } from './services/orgs/org-data/org-data.service';
import { RemoteConfigService } from './services/remote-config/remote-config.service';
import { RolesService } from './services/user/roles/roles.service';
import { UserDataService } from './services/user/user-data/user-data.service';
import { ReleaseVersionService } from './services/version/release-version.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  displayName = '';

  selectedLanguage = 'en';

  currentUrl: string | undefined;

  // Used for Side Navigation Panel
  mobileQuery: MediaQueryList;

  private _mobileQueryListener: () => void;

  ///////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////

  orgDisplayName: Observable<string>;

  userId = undefined;

  latestGitCommitHash = latestGitCommitHash;

  private ngUnsubscribe = new Subject<void>();

  public readonly roles$ = this.rolesService.getRolesMap();

  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    media: MediaMatcher,
    private changeDetectorRef: ChangeDetectorRef,
    private orgDataService: OrgDataService,
    private releaseVersionService: ReleaseVersionService,
    private rolesService: RolesService,
    private routerService: Router,
    private translocoService: TranslocoService,
    private userDataService: UserDataService,
    public remoteConfig: RemoteConfigService,
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
  }

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  ngOnInit() {
    this.getData();
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  resetUI() {
    this.displayName = '';
    this.selectedLanguage = 'en';
    this.userId = undefined;
  }

  getData() {
    this.resetUI();
    this.startUserInfoService();
    this.startVersionService();
    this.startRouteChangeService();
    this.orgDisplayName = this.orgDataService.getOrgData().pipe(
      takeUntil(this.ngUnsubscribe),
      take(1),
      map((z) => z.getDisplayName()),
    );
  }

  private startUserInfoService() {
    this.userDataService
      .getUserData()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        (userData) => {
          this.userId = userData.getId();
          this.displayName = userData?.getFirstName() || '';
          this.changeDetectorRef.detectChanges();
        },
        (err) => {
          console.log('AppComponent.startUserInfoService Error');
          console.error(err);
        },
        () => {
          console.log('AppComponent.startUserInfoService: Complete');
        },
      );
  }

  private startVersionService() {
    // Get the latest version now, and start a periodic refresh
    this.releaseVersionService.refreshVersion().pipe(takeUntil(this.ngUnsubscribe)).subscribe();
    this.releaseVersionService.loadLatestVersionPeriodically();
  }

  private startRouteChangeService() {
    this.routerService.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.ngUnsubscribe),
      )
      .subscribe(
        (event: NavigationEnd) => {
          console.log('Route Change', event);
          this.currentUrl = event.url;
          this.changeDetectorRef.detectChanges();
        },
        (err) => {
          console.log('AppComponent.startRouteChangeService Error');
          console.error(err);
        },
        () => {
          console.log('AppComponent.startRouteChangeService: Complete');
        },
      );
  }

  ///////////////////////////////////////////////////////////////////////
  // Set Language
  ///////////////////////////////////////////////////////////////////////

  setLanguage(language: string) {
    console.log(`Setting language to: ${language}`);
    this.selectedLanguage = language;
    this.translocoService.setActiveLang(language);

    // Set locale for moment globally.
    moment.locale(language);
    this.changeDetectorRef.detectChanges();
  }

  getLanguageString(language: string) {
    switch (language) {
      case 'en':
        return 'English';
      case 'es':
        return 'Español';
      default:
        throw new Error(`Unknown language code: ${language}`);
    }
  }
}
