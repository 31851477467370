import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { isNil } from 'lodash';

import { SwitchCase, SwitchCaseSchema, VoiceResponseCommand } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../../common/objects/form-helper';
import { KVPair } from '../../../../../common/objects/kvpair';
import { ObjEditor } from '../../../../generic/abstract-classes/obj-editor';

@Component({
  selector: 'app-vrc-editor-switch-case',
  templateUrl: './vrc-editor-switch-case.component.html',
  styleUrls: ['./vrc-editor-switch-case.component.css'],
})
export class VrcEditorSwitchCaseComponent extends ObjEditor<KVPair<SwitchCase>> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  commands: VoiceResponseCommand[];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(undefined, [SwitchCaseSchema.value], SwitchCaseSchema.Defaults, this);

    parameters[SwitchCaseSchema.commands] = this.commands || SwitchCaseSchema.Defaults.commands;

    const value = new SwitchCase(parameters);
    return new KVPair({ id: this.obj.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<SwitchCase>) {
    // Init Form
    const formConfig = {} as any;
    formConfig[SwitchCaseSchema.value] = [obj.value!.getValue(), [Validators.required]];

    this.form = this.formBuilder.group(formConfig);

    // Init other state
    this.commands = obj.value!.getCommands();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get value(): AbstractControl | null {
    return this.form.get(SwitchCaseSchema.value);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj() {
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.commands) || this.commands.length === 0) {
      return false;
    }

    return true;
  }
}
