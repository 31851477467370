import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { EmailTemplateConfig } from '../../../../../../common/src';
import { DbDocumentService } from '../generic/db-document-service';
import { AuthService } from '../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EmailTemplateConfigService extends DbDocumentService<EmailTemplateConfig> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, EmailTemplateConfig);
  }
}
