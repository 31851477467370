import { isEmpty, isNil } from 'lodash';

import {
  ReservationOfferChannelConfigPhoneCall,
  ReservationOfferChannelConfigText,
  ReservationOfferConfig,
  SupportedLanguages,
  TemplateText,
} from '@pwp-common';

import { reservationOfferEditorOutputToDb } from '../../objects/offers/reservation-offer/reservation-offer-editor-output-to-db/reservation-offer-editor-output-to-db';
import { reservationSkipOffersIfEditorOutputToDb } from '../../objects/reservation-skip-offers-if/db/reservation-skip-offers-if-editor-output-to-db/reservation-skip-offers-if-editor-output-to-db';
import { ReservationOfferConfigEditorOutput } from '../../reservation-offer-config-editor-output';

export const reservationOfferConfigEditorOutputToDb = (params: ReservationOfferConfigEditorOutput): ReservationOfferConfig => {
  /**
   * Handle empty input
   */
  if (isNil(params) || isNil(params.channelConfig) || isEmpty(params.offers) || isNil(params.skipOffersIf)) {
    throw new Error('reservationOfferConfigEditorOutputToDb: User Error. Required data is missing.');
  }

  const offerChannelConfigPhoneCall = new ReservationOfferChannelConfigPhoneCall({
    callerIdObjId: params.channelConfig.callerIdObjId,
    vrcAudioMetadataId: params.channelConfig.phoneCall.vrcAudioMetadataId,
    dialTimeoutMS: params.channelConfig.phoneCall.dialTimeoutSeconds * 1000,
  });

  let bodyMap = new Map();
  if (!isEmpty(params.channelConfig.text.body)) {
    bodyMap = new Map([[SupportedLanguages.en.getShortCode(), params.channelConfig.text.body ?? '']]);
  }
  const offerChannelConfigText = new ReservationOfferChannelConfigText({
    callerIdObjId: params.channelConfig.callerIdObjId,
    body: new TemplateText({ text: bodyMap }),
  });

  const dbOffers = reservationOfferEditorOutputToDb(params.offers);
  return new ReservationOfferConfig({
    offers: dbOffers,
    offerChannelConfigPhoneCall,
    offerChannelConfigText,
    skipOffersIf: reservationSkipOffersIfEditorOutputToDb(params.skipOffersIf),
  });
};
