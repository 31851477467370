import moment from 'moment-timezone';
import {AllDataUser} from './all-data-user';
import {UserPrivateDataSchema} from '../user-private-data/user-private-data-schema';
import {GLOBAL_TIMEZONE} from '../../../helper/constants';

/**
 * This class transforms UserLoad(), into a format suitable for displaying.
 */
export class AllDataUserDisplay {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  // Data used to initialize class
  public allDataUser!: AllDataUser;

  // User Data
  public userId!: string;
  public displayName!: string;
  public email!: string;
  public displayNameWithEmail!: string;

  // User Private
  public phone!: string;

  // Roles
  public roles!: string;
  public orgNote!: string;

  // Other Stats
  public tenureMonths!: number;

  // Generic Fields: Roles
  public createTime!: string;
  public createdByUserId!: string;
  public createdByDisplayName!: string;

  public lastRoleUploadTime!: string;
  public lastRoleModifiedByUserId!: string;
  public lastRoleModifiedByDisplayName!: string;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(allDataUser: AllDataUser, allDataUserMap?: Map<string, AllDataUser>) {
    this.allDataUser = allDataUser;

    // User Data
    this.displayName = this.allDataUser.userData.getDisplayName();
    this.displayNameWithEmail = this.allDataUser.userData.getDisplayNameWithEmail();
    this.email = this.allDataUser.userData.getNotificationEmail();
    this.userId = this.allDataUser.getUserId();

    // User Private
    if (this.allDataUser.userPrivateData === undefined) {
      this.phone = 'not-available';
    } else {
      this.phone = this.allDataUser.userPrivateData.getUSLocalPhone() || UserPrivateDataSchema.Defaults.e164Phone;
    }

    // Roles
    this.roles = this.allDataUser.roles.getRoles().join(',');
    this.orgNote = this.allDataUser.roles.getOrgNote();

    // Other Stats
    this.tenureMonths = moment.tz(GLOBAL_TIMEZONE).diff(this.allDataUser.roles.getCreateTime(), 'months');

    // Generic Fields: Roles
    this.createTime = this.allDataUser.roles.getCreateTime()?.tz(GLOBAL_TIMEZONE).format('L LT') || '';
    this.createdByUserId = this.allDataUser.roles.getCreatedByUserId() || '';
    this.createdByDisplayName = allDataUserMap?.get(this.createdByUserId || '')?.userData.getDisplayName() || '';

    this.lastRoleUploadTime = this.allDataUser.roles.getLastUploadTime()?.tz(GLOBAL_TIMEZONE).format('L LT') || '';
    this.lastRoleModifiedByUserId = this.allDataUser.roles.getLastModifiedByUserId() || '';
    this.lastRoleModifiedByDisplayName =
      allDataUserMap?.get(this.lastRoleModifiedByUserId || '')?.userData.getDisplayName() || '';
  }

  /////////////////////////////////////////////////////////////////////////////
  // CSS
  /////////////////////////////////////////////////////////////////////////////

  public getCSSClass(): string {
    // No special CSS
    return 'null';
  }
}
