import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsRequestSchema} from '../../generic/communications-request/communications-request-schema';

export class CommunicationsRequestDialPhoneSchema extends CommunicationsRequestSchema {
  public static readonly e164Phone = 'e164Phone';
  public static readonly callerIdObjId = 'callerIdObjId';
  public static readonly outboundCallSessionId = 'outboundCallSessionId';
  public static readonly outboundCallSessionEventId = 'outboundCallSessionEventId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly e164Phone = undefined;
    public static readonly callerIdObjId = undefined;
    public static readonly outboundCallSessionId = undefined;
    public static readonly outboundCallSessionEventId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsRequestDialPhoneSchema.e164Phone] = SchemaField.e164Phone(CommunicationsRequestDialPhoneSchema.Defaults.e164Phone).required();
    parameters[CommunicationsRequestDialPhoneSchema.callerIdObjId] = SchemaField.autoGeneratedDocId(CommunicationsRequestDialPhoneSchema.Defaults.callerIdObjId).required();
    parameters[CommunicationsRequestDialPhoneSchema.outboundCallSessionId] =  SchemaField.autoGeneratedDocId(CommunicationsRequestDialPhoneSchema.Defaults.outboundCallSessionId).required();
    parameters[CommunicationsRequestDialPhoneSchema.outboundCallSessionEventId] =  SchemaField.autoGeneratedDocId(CommunicationsRequestDialPhoneSchema.Defaults.outboundCallSessionEventId).required();
    /* eslint-enable */

    return parameters;
  }
}
