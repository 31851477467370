import { Conversation } from '@twilio/conversations';
import moment from 'moment-timezone';

import { ConversationAttributes } from '@pwp-common';

import { Room } from '../../../chat-component/interfaces';

export interface RoomWithMetadata extends Room {
  attributes: ConversationAttributes | undefined;
  sid: string;
  uniqueName: string;
}

export const getRoom = (params: {
  conversation: Conversation;
  timezone: string;
  attributes: ConversationAttributes | undefined;
}): RoomWithMetadata => {
  const timeAgo = moment(params.conversation.dateCreated).tz(params.timezone).calendar();
  const room: RoomWithMetadata = {
    roomId: params.conversation.sid,
    roomName: timeAgo,
    avatar: '',
    users: [],
    attributes: params.attributes,
    sid: params.conversation.sid,
    uniqueName: params.conversation.uniqueName,
  };

  return room;
};

// const getDisplayableParticipants = (participants: Map<string, ConversationWaitingRoomConfig>): RoomUsers => {

//     let sortedParticipantArr = Array.from(participants.values());
//     sortedParticipantArr = orderBy(sortedParticipantArr, [z => z.getParticipantId()], ['asc']);

//     const users: RoomUsers = [];
//     for (const participant of sortedParticipantArr) {
//         const user: RoomUser = {
//             _id: participant.getParticipantId(),
//             username: participant.getParticipantIdType(),
//             avatar: 'assets/imgs/doe.png',
//             status: {
//                 state: 'online',
//                 lastChanged: 'today, 14:30'
//             }
//         };
//         users.push(user);
//     }

//     return users;
// };
