import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { EMPTY, from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { RowCell } from '../../../../../../common/p-table/row-cell';
import { InboxDialPhoneConfirmResult } from '../inbox-dial-phone-confirm/inbox-dial-phone-confirm-result';
import { InboxDialPhoneConfirmComponent } from '../inbox-dial-phone-confirm/inbox-dial-phone-confirm.component';




@UntilDestroy()
@Component({
  selector: 'app-inbox-dial-phone-button',
  templateUrl: './inbox-dial-phone-button.component.html',
  styleUrls: ['./inbox-dial-phone-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService, DialogService],
})
export class InboxDialPhoneButtonComponent implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////

  @Input() byPhone = false;

  @Input() canDial = false;

  @Input() doDial: (result: InboxDialPhoneConfirmResult) => Promise<void>;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  dialInProgress = false;

  dialButtonCell: RowCell<string>;

  private timer: NodeJS.Timeout;

  timeoutSecondsRemaining = 30;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.refreshUI();
  }

  ///////////////////////////////////////////////////////////////////////
  // Toggle Handled
  ///////////////////////////////////////////////////////////////////////

  doDialClick() {
    const dialog = this.dialogService.open(InboxDialPhoneConfirmComponent, {
      data: {
        byPhone: this.byPhone,
      },
      width: '75%',
    });
    // Display data in a dialog

    dialog.onClose
      .pipe(
        untilDestroyed(this),
        take(1),
        switchMap((result: InboxDialPhoneConfirmResult | undefined) => {
          if (isNil(result)) {
            return EMPTY;
          }
          return from(this.doDialConfirmed(result));
        }),
      )
      .subscribe();
    this.changeDetectorRef.detectChanges();
  }

  async doDialConfirmed(result: InboxDialPhoneConfirmResult) {
    this.dialInProgress = true;
    this.changeDetectorRef.detectChanges();

    try {
      this.refreshUI();
      this.startTimer();
      await this.doDial(result);
    } catch (error) {
      this.dialInProgress = false;
      this.showUnknownError();
      this.refreshUI();
    }
    this.changeDetectorRef.detectChanges();
  }

  ///////////////////////////////////////////////////////////////////////
  // Update UI
  ///////////////////////////////////////////////////////////////////////

  refreshUI() {
    let translationKey = 'dial';
    if (this.dialInProgress) {
      translationKey = 'dialInProgress';
    }
    this.dialButtonCell = new RowCell<string>({
      translationScope: 'inbox-dial-phone-button',
      translationKey,
      translationObj: {
        timeoutSecondsRemaining: this.timeoutSecondsRemaining,
      },
      sortValue: '',
    });
    this.changeDetectorRef.markForCheck();
  }

  startTimer() {
    clearInterval(this.timer);
    this.timeoutSecondsRemaining = 30;
    this.changeDetectorRef.markForCheck();

    const x = setInterval(() => {
      this.timeoutSecondsRemaining--;

      if (this.timeoutSecondsRemaining <= 0) {
        this.dialInProgress = false;
        clearInterval(x);
        this.refreshUI();
      }

      this.dialButtonCell = new RowCell<string>({
        ...this.dialButtonCell,
        translationScope: 'inbox-dial-phone-button',
        translationObj: {
          timeoutSecondsRemaining: this.timeoutSecondsRemaining,
        },
      });
      this.changeDetectorRef.markForCheck();
    }, 1000);
  }

  ///////////////////////////////////////////////////////////////////////
  // Message
  ///////////////////////////////////////////////////////////////////////

  private showUnknownError() {
    const title = translate(`pwp-api.errorTimeoutTitle`);
    const body = translate(`pwp-api.errorTimeoutBody`);

    this.messageService.add({
      severity: 'warn',
      summary: title,
      detail: body,
    });
    this.changeDetectorRef.detectChanges();
  }
}
