import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';

import { CallerId } from '@pwp-common';

import { CallerIdService } from '../../../../../../services/call/caller-id/caller-id.service';

import { InboxDialPhoneConfirmInput } from './inbox-dial-phone-confirm-input';
import { InboxDialPhoneConfirmResult } from './inbox-dial-phone-confirm-result';


@UntilDestroy()
@Component({
  selector: 'app-inbox-dial-phone-confirm',
  templateUrl: './inbox-dial-phone-confirm.component.html',
  styleUrls: ['./inbox-dial-phone-confirm.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [],
})
export class InboxDialPhoneConfirmComponent implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////
  callerIds$: Observable<CallerId[]>;

  form = new UntypedFormGroup({
    phoneOnAndReady: new UntypedFormControl(false, [Validators.requiredTrue]),
    e164Phone: new UntypedFormControl(undefined, [Validators.required]),
    callerId: new UntypedFormControl(undefined, [Validators.required]),
  });

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private changeDetectorRef: ChangeDetectorRef,
    private callerIdService: CallerIdService,
  ) {}

  ngOnInit(): void {
    const data = this.config.data as InboxDialPhoneConfirmInput;
    if (data.byPhone) {
      this.e164Phone.enable();
    } else {
      this.e164Phone.disable();
    }
    this.getData();
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe();
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  getData() {
    // Set loading state
    this.callerIds$ = this.callerIdService.getDocsArray();
    this.changeDetectorRef.markForCheck();
  }

  ///////////////////////////////////////////////////////////////////////
  // Cancel
  ///////////////////////////////////////////////////////////////////////

  cancelClick() {
    this.ref.close();
  }

  confirmClick() {
    const obj: InboxDialPhoneConfirmResult = {
      callerIdObjId: this.callerId.value,
      e164Phone: this.e164Phone.value,
    };
    this.ref.close(obj);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get callerId() {
    return this.form.get('callerId') as UntypedFormControl;
  }

  get e164Phone() {
    return this.form.get('e164Phone') as UntypedFormControl;
  }

  get phoneOnAndReady() {
    return this.form.get('phoneOnAndReady') as UntypedFormControl;
  }
}
