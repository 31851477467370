<mat-tab-group>
  <mat-tab label="Overview">
    <ng-template matTabContent>
      <app-admin-overview-dashboard></app-admin-overview-dashboard>
    </ng-template>
  </mat-tab>
  <mat-tab label="Burnout Prevention">
    <ng-template matTabContent>
      <app-admin-burnout-dashboard></app-admin-burnout-dashboard>
    </ng-template>
  </mat-tab>
  <mat-tab *ngIf="orgData !== undefined" label="Shift Hours">
    <ng-template matTabContent>
      <app-admin-hours-dashboard [orgData]="orgData"></app-admin-hours-dashboard>
    </ng-template>
  </mat-tab>
  <mat-tab label="Usage">
    <ng-template matTabContent>
      <app-admin-usage-dashboard></app-admin-usage-dashboard>
    </ng-template>
  </mat-tab>
  <mat-tab label="Reports">
    <ng-template matTabContent>
      <app-report></app-report>
    </ng-template>
  </mat-tab>
</mat-tab-group>
