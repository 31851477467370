import { isNil } from 'lodash';
import moment from 'moment-timezone';

import { timeRegex24h } from './constants';
import { convertTimeRangeToMoment } from './convert-time-ranges-to-moment';

export const getTimeRangeFromStr = (timeRangeStr: string): { start: moment.Moment; end: moment.Moment } | undefined => {
  if (isNil(timeRangeStr)) {
    return undefined;
  }
  const matches = timeRangeStr.match(timeRegex24h) ?? [];

  return convertTimeRangeToMoment(matches);
};
