import {ServiceableAddressName} from '../../generic/serviceable-address-name';
import {ServiceableAddress} from '../../generic/serviceable-address/serviceable-address';
import {ServiceableAddressSchema} from '../../generic/serviceable-address/serviceable-address-schema';
import {ServiceableAddressE164PhoneConstructor} from './serviceable-address-e164-phone-constructor';
import {ServiceableAddressE164PhoneSchema} from './serviceable-address-e164-phone-schema';
import {cloneDeep} from 'lodash';

export class ServiceableAddressE164Phone extends ServiceableAddress {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected e164Phone!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceableAddressE164PhoneConstructor) {
    (parameters as any)[ServiceableAddressSchema.type] = ServiceableAddressName.e164Phone;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ServiceableAddressE164Phone {
    return new ServiceableAddressE164Phone(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ServiceableAddressE164Phone.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): ServiceableAddressE164PhoneSchema {
    return new ServiceableAddressE164PhoneSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getE164Phone(): string {
    return cloneDeep(this.e164Phone);
  }
}
