import type {ValidationResult} from 'joi';

import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {UserRequestName} from '../../../user-request-name';
import {UserResponse} from '../../generic/user-response/user-response';
import {UserResponseSchema} from '../../generic/user-response/user-response-schema';

import {UserResponseChangeOrgIdConstructor} from './user-response-change-org-id-constructor';
import {UserResponseChangeOrgIdError} from './user-response-change-org-id-error';
import {UserResponseChangeOrgIdSchema} from './user-response-change-org-id-schema';

export class UserResponseChangeOrgId extends UserResponse<UserResponseChangeOrgIdError> {
  protected userId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: UserResponseChangeOrgIdConstructor) {
    super({
      ...parameters,
      [UserResponseSchema.type]: UserRequestName.changeOrgId,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): UserResponseChangeOrgId {
    return new UserResponseChangeOrgId(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new UserResponseChangeOrgIdSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(UserResponseChangeOrgId.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getUserId(): string {
    return this.userId;
  }
}
