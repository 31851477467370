<ng-container *transloco="let t; read: 'general-tab-editor'">
  <form [formGroup]="form">
    <app-accordion-wizard-steps>
      <app-accordion-wizard-step [label]="t('chooseANameLabel')">
        <div class="grid my-4">
          <div class="col-12 lg:col-12">
            <div class="grid formgrid p-fluid">
              <!-- Display Name -->
              <div class="field mb-4 col-12">
                <span class="p-float-label">
                  <input
                    pInputText
                    class="full-width"
                    id="displayName"
                    type="text"
                    [formControl]="displayName"
                    [ngClass]="{ 'ng-invalid': displayName?.invalid }"
                  />
                  <label for="displayName">{{ t('displayNameLabel') }}</label>
                  <p *ngIf="displayName.touched && displayName.hasError('required')" class="p-error">{{ t('displayNameErrorRequired') }}</p>
                  <p *ngIf="displayName.touched && displayName.hasError('minlength')" class="p-error">
                    {{ t('displayNameErrorMinLength') }}
                  </p>
                  <p *ngIf="displayName.touched && displayName.hasError('maxlength')" class="p-error">
                    {{ t('displayNameErrorMaxLength') }}
                  </p>
                </span>
              </div>

              <!-- Description -->
              <div class="field mb-4 col-12">
                <span class="p-float-label full-width">
                  <textarea
                    pInputTextarea
                    class="full-width"
                    id="description"
                    rows="4"
                    [formControl]="description"
                    [ngClass]="{ 'ng-invalid': description?.invalid }"
                  ></textarea>
                  <label for="description">{{ t('descriptionLabel') }}</label>
                  <p *ngIf="description.touched && description.hasError('required')" class="p-error">{{ t('descriptionErrorRequired') }}</p>
                  <p *ngIf="description.touched && description.hasError('minlength')" class="p-error">
                    {{ t('descriptionErrorMinLength') }}
                  </p>
                  <p *ngIf="description.touched && description.hasError('maxlength')" class="p-error">
                    {{ t('descriptionErrorMaxLength') }}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      </app-accordion-wizard-step>

      <app-accordion-wizard-step [hasError]="taskReservationTimeoutSeconds.invalid" [label]="t('chooseWorkerResponseTimeLabel')">
        <div class="my-4">
          <div class="p-inputgroup">
            <span class="p-inputgroup-addon"><i class="pi pi-clock"></i></span>
            <span class="p-float-label">
              <p-inputNumber
                inputId="taskReservationTimeoutSeconds"
                mode="decimal"
                suffix=" {{ t('taskReservationTimeoutSecondsSuffix') }}"
                [formControl]="taskReservationTimeoutSeconds"
                [showButtons]="true"
              ></p-inputNumber>
              <label for="taskReservationTimeoutSeconds">{{ t('taskReservationTimeoutSecondsLabel') }}</label>
            </span>
          </div>

          <ng-container *ngIf="taskReservationTimeoutSeconds.touched && taskReservationTimeoutSeconds.invalid">
            <p *ngIf="taskReservationTimeoutSeconds.errors.required" class="p-error">
              {{ t('taskReservationTimeoutSecondsErrorRequired') }}
            </p>
            <p *ngIf="taskReservationTimeoutSeconds.errors.min" class="p-error">
              {{ t('taskReservationTimeoutSecondsErrorMin', { value: taskReservationTimeoutSeconds.value }) }}
            </p>
            <p *ngIf="taskReservationTimeoutSeconds.errors.max" class="p-error">
              {{ t('taskReservationTimeoutSecondsErrorMax', { value: taskReservationTimeoutSeconds.value }) }}
            </p>
          </ng-container>
          <p>
            {{ t('taskReservationTimeoutSecondsHelp', { value: taskReservationTimeoutSeconds.value }) }}
          </p>
        </div>
      </app-accordion-wizard-step>
    </app-accordion-wizard-steps>
  </form>
</ng-container>
