import {cloneDeep} from 'lodash';
import {ReservationOfferChannelConfigPhoneCallConstructor} from './reservation-offer-channel-config-phone-call-constructor';
import {ReservationOfferChannelConfigPhoneCallSchema} from './reservation-offer-channel-config-phone-call-schema';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';

export class ReservationOfferChannelConfigPhoneCall extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected dialTimeoutMS!: number;
  protected callerIdObjId!: string;
  protected vrcAudioMetadataId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ReservationOfferChannelConfigPhoneCallConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only dialTimeoutMS
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ReservationOfferChannelConfigPhoneCall {
    return new ReservationOfferChannelConfigPhoneCall(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ReservationOfferChannelConfigPhoneCall.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ReservationOfferChannelConfigPhoneCallSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getDialTimeoutMS(): number {
    return cloneDeep(this.dialTimeoutMS);
  }

  public getCallerIdObjId(): string | undefined {
    return cloneDeep(this.callerIdObjId);
  }

  public getVrcAudioMetadataId(): string {
    return cloneDeep(this.vrcAudioMetadataId);
  }
}
