export enum DialedCallStatus {
  /**
   * Operator Call Statuses
   */
  initiated = 'initiated',
  queued = 'queued',
  ringing = 'ringing',
  inProgress = 'inProgress',

  /**
   * Refined Completed Status
   */
  completedAnsweredLive = 'completedAnsweredLive',
  completedReceptionIssueAnsweredByMachine = 'completedReceptionIssueAnsweredByMachine',
  completedAnsweredByMachine = 'completedAnsweredByMachine',
  completed = 'completed',

  busy = 'busy',
  failed = 'failed',
  noAnswer = 'noAnswer',
  canceled = 'canceled',
}
