import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { isNil } from 'lodash';
import moment from 'moment-timezone';

import {
  AssignedUserType,
  CalleeEntity,
  CalleeEntitySchema,
  CalleeEntityType,
  EventType,
  parseMomentToTimeRange,
  parseTimeRangeToMoment,
  UserData,
} from '@pwp-common';

import { getFieldControlValueOrDefault, getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { getCompleteKVPair, KVPair } from '../../../../common/objects/kvpair';
import { userDataDisplayFormat } from '../../../../common/objects/user-data-display-format';
import { ObjEditor } from '../../../generic/abstract-classes/obj-editor';


@Component({
  selector: 'app-callee-entity-editor',
  templateUrl: './callee-entity-editor.component.html',
  styleUrls: ['./callee-entity-editor.component.css'],
})
export class CalleeEntityEditorComponent extends ObjEditor<KVPair<CalleeEntity>> implements OnInit {
  @Input() eventTypes: EventType[];

  @Input() userDataArray: UserData[];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  assignedUserTypeEnum = AssignedUserType;

  calleeEntityTypeEnum = CalleeEntityType;

  assignedUserTypesList = Object.keys(AssignedUserType);

  calleeEntityTypesList = Object.keys(CalleeEntityType);

  userDataDisplayFormat = userDataDisplayFormat;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  selectedEventTypeKVPair: KVPair<EventType>;

  userList: UserData[] = [];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    if (this.hasBlockedOutTimes?.value === true) {
      try {
        // Blockout times must be valid
        parseMomentToTimeRange([moment(this.blockoutStart?.value), moment(this.blockoutEnd?.value)]);
      } catch (error) {
        console.log('CalleeEntityEditorComponent.isValidObj: false');
        return false;
      }
    }

    switch (this.type?.value) {
      case CalleeEntityType.event: {
        // Event types must define a valid event
        if (isNil(this.selectedEventTypeKVPair?.value?.getId())) {
          console.log('CalleeEntityEditorComponent.isValidObj: Event type not selected');
          return false;
        }
        if (isNil(this.assignedUserTypes?.value)) {
          console.log('CalleeEntityEditorComponent.isValidObj: No assigned user types');
          return false;
        }
        break;
      }
      case CalleeEntityType.userList: {
        break;
      }
      default: {
        return false;
      }
    }
    return true;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    // Set Form Properties
    const parameters = getFieldValuesFromFormInComponent(undefined, [CalleeEntitySchema.type], CalleeEntitySchema.Defaults, this);

    // Set Blockout Times
    parameters[CalleeEntitySchema.blockoutTimes] = CalleeEntitySchema.Defaults.blockoutTimes;
    if (this.hasBlockedOutTimes?.value === true) {
      parameters[CalleeEntitySchema.blockoutTimes] = parseMomentToTimeRange([
        moment(this.blockoutStart?.value),
        moment(this.blockoutEnd?.value),
      ]);
    }

    // Set parameters specific to callee entity type
    switch (this.type.value) {
      case CalleeEntityType.event: {
        parameters[CalleeEntitySchema.eventType] = this.selectedEventTypeKVPair?.value?.getId();
        parameters[CalleeEntitySchema.assignedUserTypes] = getFieldControlValueOrDefault(
          this.assignedUserTypes,
          CalleeEntitySchema.Defaults.assignedUserTypes,
        );
        break;
      }
      case CalleeEntityType.userList: {
        parameters[CalleeEntitySchema.userList] = this.userList.map((z) => z.getId());
        break;
      }
      default: {
        throw new Error(`CalleeEntityEditorComponent.getObjFromForm: Unknown type: ${this.type.value}`);
      }
    }

    const value = new CalleeEntity(parameters);
    return new KVPair({ id: this.obj.id, value });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<CalleeEntity>) {
    // Init Form
    const formConfig = {} as any;
    formConfig[CalleeEntitySchema.type] = [obj.value!.getType(), [Validators.required]];
    formConfig[CalleeEntitySchema.assignedUserTypes] = [obj.value!.getAssignedUserTypes()];
    formConfig.hasBlockedOutTimes = [obj.value!.hasBlockedOutTimes()];

    // Set Blockout Times
    formConfig.blockoutStart = [moment().startOf('day').toDate()];
    formConfig.blockoutEnd = [moment().startOf('day').add(23, 'hour').add(59, 'minute').add(1, 'second').toDate()];
    if (obj.value!.hasBlockedOutTimes()) {
      const parsedTimes = parseTimeRangeToMoment([obj.value!.getBlockoutTimes()[0], obj.value!.getBlockoutTimes()[1]]);
      formConfig.blockoutStart = [parsedTimes[0].toDate()];
      formConfig.blockoutEnd = [parsedTimes[1].toDate()];
    }

    // Set fields specific to type of callee entity
    switch (obj.value!.getType()) {
      case CalleeEntityType.event: {
        if (obj.value!.getEventType() !== undefined && obj.value!.getEventType() !== CalleeEntitySchema.eventType) {
          this.selectedEventTypeKVPair = new KVPair({ id: obj.value!.getEventType() });
        }
        break;
      }
      case CalleeEntityType.userList: {
        if (isNil(this.userDataArray)) {
          break;
        }
        this.userList = obj
          .value!.getUserList()
          .map((z) => getCompleteKVPair(new KVPair({ id: z }), this.userDataArray as any[]))
          .map((z) => z.value);
        break;
      }
      default: {
        throw new Error(`CalleeEntityEditorComponent.setFormFromObj: Unknown type: ${this.type.value}`);
      }
    }

    this.form = this.formBuilder.group(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  get type(): AbstractControl | null {
    return this.form.get(CalleeEntitySchema.type);
  }

  get assignedUserTypes(): AbstractControl | null {
    return this.form.get(CalleeEntitySchema.assignedUserTypes);
  }

  get hasBlockedOutTimes(): AbstractControl | null {
    return this.form.get('hasBlockedOutTimes');
  }

  get blockoutStart(): AbstractControl | null {
    return this.form.get('blockoutStart');
  }

  get blockoutEnd(): AbstractControl | null {
    return this.form.get('blockoutEnd');
  }
}
