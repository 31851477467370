import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../../generic/serialization/schema-field';
import {CallListOfferChannelConfigPhoneCallSchema} from '../call-list-offer-channel-config-phone-call/call-list-offer-channel-config-phone-call-schema';
import {CallListOfferChannelConfigPhoneCall} from '../call-list-offer-channel-config-phone-call/call-list-offer-channel-config-phone-call';

export class CallListOfferConfigSchema extends SerializableObjectSchema {
  public static readonly offerChannelConfigPhoneCall = 'offerChannelConfigPhoneCall';

  static Defaults = class {
    public static readonly offerChannelConfigPhoneCall = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CallListOfferConfigSchema.offerChannelConfigPhoneCall] = SchemaField.obj(
      CallListOfferChannelConfigPhoneCall,
      CallListOfferChannelConfigPhoneCallSchema.Defaults.callerIdStrategy
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
