import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { CommunicationWidgetCreateAsyncServiceRequest } from '@pwp-common';

import { CommunicationWidgetCreateAsyncServiceRequestService } from '../../../../../services/communication/communication-widget/communication-widget-create-async-service-request/communication-widget-create-async-service-request.service';
import {
  getObjAutocompleteFormControlBaseProviders,
  ObjAutocompleteFormControlBase,
} from '../../../../generic/abstract-classes/obj-autocomplete-form-control-base';

@UntilDestroy()
@Component({
  selector: 'app-create-async-service-request-autocomplete',
  templateUrl: './create-async-service-request-autocomplete.component.html',
  styleUrls: ['./create-async-service-request-autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...getObjAutocompleteFormControlBaseProviders(CreateAsyncServiceRequestAutocompleteComponent)],
})
export class CreateAsyncServiceRequestAutocompleteComponent
  extends ObjAutocompleteFormControlBase<CommunicationWidgetCreateAsyncServiceRequest>
  implements OnInit
{
  private static id = 0;

  public readonly defaultId = `app-create-async-service-request-autocomplete-${(CreateAsyncServiceRequestAutocompleteComponent.id += 1)}`;

  @Input() public inputId?: string;

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public createAsyncServiceRequestService: CommunicationWidgetCreateAsyncServiceRequestService,
  ) {
    super(changeDetectorRef);
  }

  defineAllOptions() {
    return this.createAsyncServiceRequestService.getAllDocsOfType({});
  }
}
