import { AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask } from '@angular/fire/compat/storage';
import { UploadMetadata } from '@angular/fire/compat/storage/interfaces';
import { escape } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BlobLocation, DBDocSchema } from '@pwp-common';

export abstract class BlobStorageService {
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  protected orgId: string | undefined;

  ///////////////////////////////////////////////////////////////////////
  // Logging
  ///////////////////////////////////////////////////////////////////////

  /**
   * Return parameters appending to beginning of some logging statements for
   * debugging purposes
   */

  protected getLoggingInfo(): string {
    return `blobLocation=${this.getBlobLocation().getStorageDir(this.orgId || 'ORG_ID')}`;
  }

  ///////////////////////////////////////////////////////////////////////
  // Firebase Reference
  ///////////////////////////////////////////////////////////////////////

  protected fileRef(fileFullPath: string): AngularFireStorageReference {
    if (fileFullPath.search(DBDocSchema.GenericDefaults.id) >= 0) {
      console.error(fileFullPath);
      throw new Error('BlobStorageService.fileRef: User Error: Trying to access a file with bad path.');
    }
    return this.storage.ref(fileFullPath);
  }

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(private storage: AngularFireStorage) {}

  ///////////////////////////////////////////////////////////////////////
  // Abstract Methods
  ///////////////////////////////////////////////////////////////////////

  protected abstract getBlobLocation(): BlobLocation;

  ///////////////////////////////////////////////////////////////////////
  // Download Link
  ///////////////////////////////////////////////////////////////////////

  public getDownloadLink(fileFullPath: string, escapeURL = true): Observable<string> {
    let observable = this.fileRef(fileFullPath).getDownloadURL() as Observable<string>;
    observable = observable.pipe(
      map((downloadURL) => {
        if (escapeURL) {
          return escape(downloadURL);
        }
        return downloadURL;
      }),
    );
    return observable;
  }

  ///////////////////////////////////////////////////////////////////////
  // Upload
  ///////////////////////////////////////////////////////////////////////

  /**
   * Upload the given file
   *
   * @param fileFullPath Full path of file in the cloud
   * @param file file to upload.
   */
  public upload(fileFullPath: string, file: File, metadata?: UploadMetadata): AngularFireUploadTask {
    return this.fileRef(fileFullPath).put(file, metadata);
  }
}
