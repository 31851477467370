import {GenericDisplayableSchema} from '../../generic/displayable/generic-displayable-schema';
import {SchemaField} from '../../generic/serialization/schema-field';

export class CallerIdSchema extends GenericDisplayableSchema {
  public static readonly e164Phone = 'e164Phone'; // The phone which shows up as a callerId

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'callerIds';
  };

  static Defaults = class {
    public static readonly e164Phone = undefined;
    public static readonly description = 'missing-description';
    public static readonly displayName = 'missing-displayName';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CallerIdSchema.e164Phone] = SchemaField.e164Phone(CallerIdSchema.Defaults.e164Phone);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${CallerIdSchema.Constants.collection}`;
  }
}
