import {DBDocSchema} from '../../db-doc/db-doc-schema';
import {isNil} from 'lodash';
import {isNilOrDefaultDocId} from '../is-nil-or-default-doc-id';
import {DBDocSchemaFields} from '../../db-doc/db-doc-schema-fields';

export const isNilOrDefaultGenericField = (fieldName: DBDocSchemaFields, value: any): boolean => {
  /**
   * Handle generic fields
   */
  if (fieldName === DBDocSchema.id) {
    return isNilOrDefaultDocId(value);
  }
  if (fieldName === DBDocSchema.createTime) {
    return isNil(value) || DBDocSchema.GenericDefaults.createTime.unix() === value.unix();
  }
  if (fieldName === DBDocSchema.createdByUserId) {
    return isNil(value) || value === DBDocSchema.GenericDefaults.createdByUserId;
  }
  if (fieldName === DBDocSchema.lastUploadTime) {
    return isNil(value) || DBDocSchema.GenericDefaults.lastUploadTime.unix() === value.unix();
  }
  if (fieldName === DBDocSchema.lastModifiedByUserId) {
    return isNil(value) || value === DBDocSchema.GenericDefaults.lastModifiedByUserId;
  }
  throw new Error(`isNilOrDefaultGenericField: Unknown field name: ${fieldName}`);
};
