import {SchemaField} from '../../generic/serialization/schema-field';
import {CallLogSchema} from '../call-log/call-log-schema';
import {GenericDialedCallLogSchema} from './generic/any-dialed-call-log-schema';

export class DialedCallLogSchema extends GenericDialedCallLogSchema {
  // UserId of the user that that received this call
  public static readonly userId = 'userId';

  // An enum characterizing the user's status. For example, 'userBusy'
  public static readonly userStatus = 'userStatus';

  // Characterize dial status. For example, twilioError or incomingCallNotActive
  public static readonly dialStatus = 'dialStatus';

  // Boolean value. Indicate if this call was connected to the client.
  public static readonly connectedToClient = 'connectedToClient';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'calls';
  };

  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////
    /**
     * Timestamps
     */
    public static readonly initiatedTimestamp = undefined;
    public static readonly ringingTimestamp = undefined;
    public static readonly answeredTimestamp = undefined;
    public static readonly completedTimestamp = undefined;

    /**
     * Operator Tracking
     */
    public static readonly errorCode = undefined;
    public static readonly operatorDurationMS = undefined;
    public static readonly operatorPriceUnit = undefined;
    public static readonly operatorBasePricePM = undefined;
    public static readonly operatorCountryCode = undefined;

    /**
     * IVR
     */
    public static readonly responses = [];

    /**
     * Other
     */
    public static readonly callerIdObjId = undefined;
    public static readonly sipResponseCode = undefined;
    public static readonly operatorCallStatus = undefined;
    public static readonly callSid = undefined;

    ///////////////////////////////////////////////////////
    // DialedCallLog Properties
    ///////////////////////////////////////////////////////
    public static readonly userId = 'missing-userId';
    public static readonly userStatus = undefined;
    public static readonly dialStatus = undefined;
    public static readonly connectedToClient = false;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([...super.defaultValuesToSerialize(), DialedCallLogSchema.connectedToClient]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[DialedCallLogSchema.userId] = SchemaField.userId(DialedCallLogSchema.Defaults.userId);
    parameters[DialedCallLogSchema.userStatus] = SchemaField.string(DialedCallLogSchema.Defaults.userStatus);
    parameters[DialedCallLogSchema.dialStatus] = SchemaField.string(DialedCallLogSchema.Defaults.dialStatus);
    parameters[DialedCallLogSchema.connectedToClient] = SchemaField.boolean(DialedCallLogSchema.Defaults.connectedToClient);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string, sessionId: string): string {
    return `${this.orgDoc(orgId)}/${CallLogSchema.Constants.collection}/${sessionId}/${
      DialedCallLogSchema.Constants.collection
    }`;
  }
}
