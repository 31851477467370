import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DialogService } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { filter, map, share, Subject, switchMap, tap } from 'rxjs';

import { CommunicationSessionId, ConversationLogDisplay, isPiiRetained, OrgData, UserData } from '@pwp-common';

import { ConversationSessionEventLogService } from '../../../services/call/conversation-session-event-log/conversation-session-event-log.service';
import { ConversationInfoDialogData } from '../../conversation/conversation-info-dialog/conversation-info-dialog-data';
import { ConversationInfoDialogComponent } from '../../conversation/conversation-info-dialog/conversation-info-dialog.component';
import { SubmitFormButtonComponent } from '../../form/submit-form-button/submit-form-button.component';
import { CommunicationLogsSessionHuntComponent } from '../communication-logs-session-hunt/communication-logs-session-hunt.component';
import { makeTableRows } from '../table-rows/make-table-rows/make-table-rows';

@Component({
  selector: 'app-communication-log-detail-conversation',
  standalone: true,
  imports: [
    ButtonModule,
    CardModule,
    CommonModule,
    CommunicationLogsSessionHuntComponent,
    SubmitFormButtonComponent,
    ToastModule,
    TranslocoModule,
  ],
  templateUrl: './communication-log-detail-conversation.component.html',
  styleUrls: ['./communication-log-detail-conversation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DialogService],
})
export class CommunicationLogDetailConversationComponent implements OnInit, OnChanges {
  private changes = new Subject<void>();
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////

  @Input() public allUserDataMap: Map<string, UserData>;

  @Input() public orgData: OrgData;

  @Input() public conversationLogDisplay: ConversationLogDisplay;

  public readonly communicationSessionId$ = this.changes.pipe(
    filter(() => !isNil(this.conversationLogDisplay)),
    map(
      () =>
        new CommunicationSessionId({
          conversationLogId: this.conversationLogDisplay.conversationLog.getId(),
        }),
    ),
  );

  public readonly tableRows$ = this.changes.pipe(
    filter(() => !isNil(this.conversationLogDisplay?.id)),
    tap(() => this.conversationSessionEventLogService.setConversationLogId(this.conversationLogDisplay.id)),
    switchMap(() => this.conversationSessionEventLogService.getDocsArray({ takeOne: false })),
    map((conversationSessionEvents) =>
      makeTableRows({
        allDataUsers: this.allUserDataMap,
        communicationSessionEvents: conversationSessionEvents,
        timezone: this.orgData.getTimezone(),
      }),
    ),
    share(),
  );

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  public dataExists = false;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private conversationSessionEventLogService: ConversationSessionEventLogService,
    private dialogService: DialogService,
  ) {}

  public ngOnInit(): void {
    this.changes.next();
  }

  public ngOnChanges() {
    this.changes.next();
    this.updateButtonStates();
  }

  ///////////////////////////////////////////////////////////////////////
  // Button State
  ///////////////////////////////////////////////////////////////////////

  public updateButtonStates() {
    this.dataExists = isPiiRetained({
      objectCreateTime: this.conversationLogDisplay?.conversationLog?.getCloseTime() ?? moment(),
      piiRetentionDuration: this.conversationLogDisplay?.conversationConfig?.getPIIRetentionDuration(),
    });

    this.changeDetectorRef.detectChanges();
  }

  ///////////////////////////////////////////////////////////////////////
  // Caller PII
  ///////////////////////////////////////////////////////////////////////

  public showConversationPIIDialog() {
    const data: ConversationInfoDialogData = {
      conversationLog: this.conversationLogDisplay?.conversationLog,
      conversationConfig: this.conversationLogDisplay?.conversationConfig,
      orgData: this.orgData,
    };
    this.dialogService.open(ConversationInfoDialogComponent, {
      data,
      width: '75%',
      dismissableMask: true,
    });

    this.changeDetectorRef.detectChanges();
  }
}
