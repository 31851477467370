import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { ConversationMessageTemplate, ConversationMessageTemplateSchema, ConversationMessageTemplateType } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { DBQuery } from '../../generic/interfaces';
import { AuthService } from '../../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConversationMessageTemplateService extends DbDocumentService<ConversationMessageTemplate> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, ConversationMessageTemplate);
  }

  public getTemplateOfType(type: ConversationMessageTemplateType, takeOne = true) {
    const query: DBQuery[] = [{ fieldPath: ConversationMessageTemplateSchema.type, opStr: '==', value: type }];

    return this.getUniqueDoc({ query, takeOne });
  }
}
