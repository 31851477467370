import type {ValidationResult} from 'joi';

import {NoteBodyType} from '../../generic/note-body-type';
import {NoteBody} from '../../generic/note-body/note-body';
import {NoteBodySchema} from '../../generic/note-body/note-body-schema';

import {NoteBodyTextConstructor} from './note-body-text-constructor';
import {NoteBodyTextSchema} from './note-body-text-schema';

export class NoteBodyText extends NoteBody {
  protected value!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: NoteBodyTextConstructor) {
    super({
      ...parameters,
      [NoteBodySchema.type]: NoteBodyType.text,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: ValidationResult): NoteBodyText {
    return new NoteBodyText(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): NoteBodyTextSchema {
    return new NoteBodyTextSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(NoteBodyText.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getValue(): string {
    return this.value;
  }
}
