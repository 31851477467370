import { isNil } from 'lodash';

import { AssignedUserType, EventRequestConfig, EventRequestRole, EventType } from '@pwp-common';

import { RowCell } from '../../../../../common/p-table/row-cell';
import { DataTableRow } from '../../../../generic/data-table/common/data-table-row';
import { makeEventRequestConfigEditorOutput } from '../../editor/editor-output/make-event-request-editor-output/make-event-request-config-editor-output';
import { EventRequestConfigEditorOutput } from '../../editor/interfaces';

export class EventRequestConfigTableRow implements DataTableRow {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  id: string;

  eventType: RowCell<string>;

  rolePrimary: RowCell<number>;

  roleBackup: RowCell<number>;

  editorOutput: EventRequestConfigEditorOutput;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(public data: { eventType: EventType; eventRequestConfigData: EventRequestConfig }) {
    this.id = data.eventType.getId();
    this.setEventType(data.eventType?.getDisplayName());
    this.rolePrimary = this.computeSlotRole(AssignedUserType.primary);
    this.roleBackup = this.computeSlotRole(AssignedUserType.backup);
    this.editorOutput = makeEventRequestConfigEditorOutput({ eventType: data.eventType, eventRequestConfig: data.eventRequestConfigData });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Shift
  //////////////////////////////////////////////////////////////////////////////////////////

  private setEventType(eventType: string) {
    if (isNil(eventType)) {
      this.eventType = new RowCell<string>({
        translationScope: 'event-request-config-table-row',
        translationKey: 'cellEventTypeMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.eventType = new RowCell<string>({
      translationScope: 'event-request-config-table-row',
      translationKey: 'cellEventType',
      translationObj: {
        eventType,
      },
      sortValue: eventType,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Compute Slots
  //////////////////////////////////////////////////////////////////////////////////////////

  private computeSlotRole(assignedUserType: AssignedUserType): RowCell<number> {
    const baseCell = new RowCell<number>({
      translationScope: 'event-request-config-table-row',
      translationObj: {},
      sortValue: 0,
      translationKey: '',
      styleClass: '',
    });

    if (isNil(this.data?.eventRequestConfigData)) {
      const translationKey = assignedUserType === AssignedUserType.primary ? 'cellSlotRequestable' : 'cellSlotNotRequestable';
      const styleClass = assignedUserType === AssignedUserType.primary ? 'needsApproval' : 'notRequestable';
      return new RowCell<number>({
        ...baseCell,
        translationKey,
        styleClass: `statusBadge ${styleClass}`,
      });
    }

    if (this.data.eventRequestConfigData.hasRole(assignedUserType, EventRequestRole.approveRequest)) {
      return new RowCell<number>({
        ...baseCell,
        sortValue: 0,
        translationKey: 'cellSlotAutoApprove',
        styleClass: 'statusBadge autoApproved',
      });
    }

    if (this.data.eventRequestConfigData.hasRole(assignedUserType, EventRequestRole.allowRequest)) {
      return new RowCell<number>({
        ...baseCell,
        translationKey: 'cellSlotRequestable',
        sortValue: 1,
        styleClass: 'statusBadge needsApproval',
      });
    }

    return new RowCell<number>({
      ...baseCell,
      translationKey: 'cellSlotNotRequestable',
      styleClass: 'statusBadge notRequestable',
      sortValue: 0,
    });
  }
}
