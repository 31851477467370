<!-- Required to configure toast notifications -->
<p-toast></p-toast>

<mat-card>
  <!-- Header -->
  <mat-card-header>
    <mat-card-title>
      <h6>Change Org Id</h6>
    </mat-card-title>
  </mat-card-header>

  <!-- Content -->
  <mat-card-content>
    <div class="container">
      <div class="row">
        <div class="col">
          <app-obj-select [label]="'Org'" [orderedItems]="orgDataArray | async" [(kvPair)]="selectedOrgDataKVPair"></app-obj-select>
        </div>
      </div>
    </div>
  </mat-card-content>

  <!-- Actions -->
  <mat-card-actions>
    <button mat-button color="primary" [disabled]="selectedOrgDataKVPair === undefined" (click)="changeOrgId()">Change</button>
  </mat-card-actions>
</mat-card>
