import {cloneDeep} from 'lodash';
import {ConversationAttributesConstructor} from './conversation-attributes-constructor';
import {ConversationAttributesSchema} from './conversation-attributes-schema';
import {SerializableObject} from '../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {ConversationWaitingRoom} from '../conversation-waiting-room/conversation-waiting-room';
import {Identity} from '../../identity/identity/identity';

export class ConversationAttributes extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected externalParticipantIdentity!: Identity | undefined;
  protected redirectURL!: string | undefined;
  protected waitingRoom: ConversationWaitingRoom | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationAttributesConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationAttributes {
    return new ConversationAttributes(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationAttributes.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationAttributesSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getExternalParticipantIdentity() {
    return cloneDeep(this.externalParticipantIdentity);
  }

  public getRedirectURL() {
    return cloneDeep(this.redirectURL);
  }

  public getWaitingRoom() {
    return cloneDeep(this.waitingRoom);
  }
}
