import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isNil } from 'lodash';
import { MessageService } from 'primeng/api';
import { map } from 'rxjs/operators';

import { AllDataUser, DeleteUserRequest, RolesSchema, UserDataSchema, UserPrivateDataSchema } from '@pwp-common';

import { USLocalPhoneValidator } from '../../../../common/validators/us-local-phone-validator';
import { AdminRolesService } from '../../../../services/user/admin-roles/admin-roles.service';
import { AllDataUserService } from '../../../../services/user/all-data-user/all-data-user.service';
import { DeleteUserService } from '../../../../services/user/delete-user/delete-user.service';
import { ComponentWithEmittingForm } from '../../../generic/abstract-classes/component-with-emitting-form';
import { ConfirmWithInputComponent } from '../../../generic/confirm-with-input/confirm-with-input.component';

@UntilDestroy()
@Component({
  selector: 'app-all-data-user-editor',
  templateUrl: './all-data-user-editor.component.html',
  styleUrls: ['./all-data-user-editor.component.css'],
  providers: [MessageService],
})
export class AllDataUserEditorComponent extends ComponentWithEmittingForm<AllDataUser> {
  uploadProcessing = false;

  deleteProcessing = false;

  deleteDialogOpened = false;

  loggedInUserIsOrgAdmin: boolean;

  loggedInUserIsPwpAdmin: boolean;

  ////////////////////////////////////////////////////////////////////////
  // Emit Changes
  ////////////////////////////////////////////////////////////////////////

  @Output()
  userIdUpdated: EventEmitter<string> = new EventEmitter();

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////

  constructor(
    private adminRolesService: AdminRolesService,
    private deleteUserService: DeleteUserService,
    private allDataUserService: AllDataUserService,
    private messageService: MessageService,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private translocoService: TranslocoService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ////////////////////////////////////////////////////////////////////////
  // Get Data
  ////////////////////////////////////////////////////////////////////////

  protected getDataPromise(): Promise<any> {
    const orgAdminPromise = this.allDataUserService
      .getLoggedInData()
      .pipe(
        map((z) => {
          this.loggedInUserIsOrgAdmin = z.roles.isOrgAdmin();
          this.changeDetectorRef.detectChanges();
        }),
      )
      .toPromise();

    const pwpAdminPromise = this.adminRolesService
      .getAdminRoles()
      .pipe(
        map((z) => {
          this.loggedInUserIsPwpAdmin = z.isPwpAdmin();
          this.changeDetectorRef.detectChanges();
        }),
      )
      .toPromise();

    return Promise.all([orgAdminPromise, pwpAdminPromise]);
  }
  ////////////////////////////////////////////////////////////////////////
  // Upload
  ////////////////////////////////////////////////////////////////////////

  /**
   * Upload data defined in this form.
   */
  public async upload() {
    this.uploadProcessing = true;
    const obj = this.getObjFromForm();

    try {
      await this.allDataUserService.upload(obj);

      const retreivedUpdatedAllDataUser = await this.allDataUserService.getDoc(obj.getUserId()).toPromise();
      this.obj = cloneDeep(retreivedUpdatedAllDataUser);
    } catch (error) {
      console.error('AllDataUserEditorComponent.upload: Error');
      console.error(error);
      this.uploadProcessing = false;
      // Show Toast
      this.messageService.add({
        severity: 'error',
        summary: 'Error saving profile.',
        detail: JSON.stringify(error),
      });
    }
    this.uploadProcessing = false;
    this.userIdUpdated.emit(this.obj.getUserId());
  }

  ////////////////////////////////////////////////////////////////////////
  // Delete
  ////////////////////////////////////////////////////////////////////////

  confirmAndDelete() {
    const displayName = this.obj.userData.getDisplayName();
    const email = this.obj.userData.getNotificationEmail();
    const title = this.translocoService.translate('all-data-user-editor.confirmDeleteTitle');
    const body = this.translocoService.translate('all-data-user-editor.confirmDeleteBody', { displayName, email });

    this.deleteDialogOpened = true;
    this.dialog
      .open(ConfirmWithInputComponent, {
        hasBackdrop: true,
        minWidth: '80%',
        data: {
          title,
          body,
          requiredConfirmationString: email,
        },
      })
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(async (confirmed) => {
        this.deleteDialogOpened = false;

        if (confirmed !== true) {
          return;
        }
        await this.delete();
      });
  }

  private async delete(): Promise<void> {
    try {
      // Make API request
      const request = new DeleteUserRequest({ userId: this.obj.getUserId() });

      this.deleteProcessing = true;
      this.changeDetectorRef.detectChanges();
      const requestResult = await this.deleteUserService.makeRequest(request);
      if (isNil(requestResult) || !isNil(requestResult.getError())) {
        this.showErrorDialog(requestResult.getError());
      }
    } catch (error) {
      this.showErrorDialog(error);
    } finally {
      this.deleteProcessing = false;
      this.changeDetectorRef.detectChanges();
      this.userIdUpdated.emit(this.obj.getUserId());
    }
  }

  ////////////////////////////////////////////////////////////////////////
  // Messages
  ////////////////////////////////////////////////////////////////////////

  private showErrorDialog(error: string) {
    console.error(`AllDataUserEditorComponent.error: Error delete user API request`);
    console.error(error);
    // Show Toast
    this.messageService.add({
      severity: 'error',
      summary: 'Error deleting profile.',
      detail: JSON.stringify(error),
    });
  }

  ////////////////////////////////////////////////////////////////////////
  // Define Form
  ////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    const formParameters = {} as any;

    // User Data
    formParameters[UserDataSchema.firstName] = [
      this.obj.userData.getFirstName(),
      [Validators.required, Validators.min(1), Validators.max(500)],
    ];

    formParameters[UserDataSchema.lastInitial] = [
      this.obj.userData.getLastInitial(),
      [Validators.required, Validators.min(1), Validators.max(500)],
    ];

    // User Private
    formParameters[UserPrivateDataSchema.e164Phone] = [
      this.obj.userPrivateData.getUSLocalPhone(),
      [
        Validators.maxLength(UserPrivateDataSchema.Constants.USLocalPhoneNumDigits),
        Validators.required,
        USLocalPhoneValidator.usLocalPhoneValidator(),
      ],
    ];

    // Roles
    const roles = this.obj.roles.getRoles();
    formParameters[RolesSchema.roles] = [roles, []];
    formParameters[RolesSchema.orgNote] = [this.obj.roles.getOrgNote(), [Validators.max(500)]];

    this.form = this.formBuilder.group(formParameters);
  }

  ////////////////////////////////////////////////////////////////////////
  // Get Obj From Form
  ////////////////////////////////////////////////////////////////////////

  getObjFromForm(): AllDataUser {
    // Get User Data
    const userData = cloneDeep(this.obj.userData);
    userData.setFirstName(this.firstName.value);
    userData.setLastInitial(this.lastInitial.value);

    // Get User Private Data
    const userPrivateData = cloneDeep(this.obj.userPrivateData);
    userPrivateData.setE164Phone(this.e164Phone.value);

    // Get Roles
    const roles = cloneDeep(this.obj.roles);

    roles.setRoles(Array.from(this.roles.value));
    roles.setOrgNote(this.orgNote.value);

    const obj = new AllDataUser(userData, roles, userPrivateData);
    return obj;
  }
  ////////////////////////////////////////////////////////////////////////
  // Form Vars: User Data
  ////////////////////////////////////////////////////////////////////////

  get firstName() {
    return this.form.get(UserDataSchema.firstName);
  }

  get lastInitial() {
    return this.form.get(UserDataSchema.lastInitial);
  }

  ////////////////////////////////////////////////////////////////////////
  // Form Vars: User Private
  ////////////////////////////////////////////////////////////////////////

  get e164Phone() {
    return this.form.get(UserPrivateDataSchema.e164Phone);
  }

  ////////////////////////////////////////////////////////////////////////
  // Form Vars: Roles
  ////////////////////////////////////////////////////////////////////////

  get roles() {
    return this.form.get(RolesSchema.roles);
  }

  get orgNote() {
    return this.form.get(RolesSchema.orgNote);
  }
}
