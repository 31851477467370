<ng-container *transloco="let t; read: 'login'">
  <mat-card>
    <!---------------------------------------------------------------------------------->
    <!-- Header for each use case: login, request_password_reset, and reset_password  -->
    <!---------------------------------------------------------------------------------->

    <div [ngSwitch]="type">
      <div *ngSwitchCase="'login'">
        <h3>{{ t('login') }}</h3>
      </div>
      <div *ngSwitchCase="'request_password_reset'">
        <h3>{{ t('resetPassword') }}</h3>
        <button mat-button size="small" (click)="changeType('login')">{{ t('back') }}</button>
      </div>
      <div *ngSwitchCase="'password_reset'">
        <h3>{{ t('resetPassword') }}</h3>
      </div>
    </div>

    <!---------------------------------------------------------------------------------->
    <!-- Define the form  -->
    <!---------------------------------------------------------------------------------->
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
      <!-- Field: Email address -->
      <!-- Details: required for login and request_password_reset -->
      <mat-form-field *ngIf="isLogin || isRequestPasswordReset">
        <input matInput placeholder="Email" autocomplete="off" formControlName="email" type="email" />
        <mat-error *ngIf="email.invalid && email.dirty">
          {{ t('invalidEmail') }}
        </mat-error>
      </mat-form-field>
      <br />

      <!-- Field: Password -->
      <!-- Details: required for login and reset_password -->
      <mat-form-field *ngIf="isLogin || isPasswordReset">
        <input matInput placeholder="{{ t('password') }}" autocomplete="off" formControlName="password" type="password" />
        <mat-error *ngIf="password.errors?.required && password.dirty"> Password required </mat-error>

        <mat-error *ngIf="password.errors?.minlength && password.dirty"> Minimum length 10 </mat-error>

        <mat-error *ngIf="password.errors?.missingDigit && password.dirty"> Must include digit </mat-error>

        <mat-error *ngIf="password.errors?.missingSymbol && password.dirty"> Must include symbol </mat-error>
      </mat-form-field>
      <br />
      <br />

      <!-- Field: Confirm Password -->
      <!-- Details: required for reset_password -->
      <mat-form-field *ngIf="isPasswordReset">
        <input matInput placeholder="{{ t('password') }}" autocomplete="off" formControlName="passwordConfirm" type="password" />
        <mat-error *ngIf="!passwordDoesMatch && passwordConfirm.dirty"> Passwords do not match </mat-error>
      </mat-form-field>

      <!---------------------------------------------------------------------------------->
      <!-- Server Error  -->
      <!---------------------------------------------------------------------------------->

      <br />
      <mat-error class="server-error">{{ serverMessage }}</mat-error>

      <!---------------------------------------------------------------------------------->
      <!-- Footer for each use case: login, request_password_reset, and reset_password  -->
      <!---------------------------------------------------------------------------------->

      <div [ngSwitch]="type">
        <div *ngSwitchCase="'login'">
          <!-- Accept Terms of Use / Privacy Policy -->
          <mat-checkbox formControlName="acceptTOSPrivacyPolicy" id="acceptTOSPrivacyPolicy">
            <div class="wrap">
              By continuing, or using our services you are indicating that you accept our
              <a href="https://www.iubenda.com/terms-and-conditions/99426495">Terms of Service</a> and
              <a href="https://www.iubenda.com/privacy-policy/99426495">Privacy Policy</a>.
            </div>
          </mat-checkbox>
          <br />
          <!-- Submit Login Request -->
          <button mat-raised-button color="accent" type="submit" [disabled]="form.invalid || loading">
            {{ t('submit') }}
          </button>
          <!-- Change form type to request_password_reset -->
          <button *ngIf="isLogin" mat-button (click)="changeType('request_password_reset')">
            {{ t('forgotPassword') }}
          </button>
        </div>
        <div *ngSwitchCase="'request_password_reset'">
          <!-- Send Reset Email -->
          <button mat-stroked-button type="submit" [disabled]="email.invalid || loading">
            {{ t('sendResetEmail') }}
          </button>
        </div>
        <div *ngSwitchCase="'password_reset'">
          <!-- Submit Password Reset-->
          <button mat-raised-button color="accent" type="submit" [disabled]="form.invalid || !passwordDoesMatch || loading">
            {{ t('submit') }}
          </button>
        </div>
      </div>
    </form>
  </mat-card>
</ng-container>
