<ng-container *transloco="let t; read: 'targets-editor'">
  <form [formGroup]="form">
    <app-sequence-steps
      [defaultStep]="defaultTarget"
      [editorTemplate]="stepEditor"
      [formControl]="targets"
      [maxSteps]="20"
      [minSteps]="1"
      [required]="true"
      [stepHeaderTemplate]="stepHeader"
    >
      <ng-template #stepHeader let-formControlIndex>
        <div class="flex align-items-center flex-wrap">
          <div class="flex align-items-center justify-content-center font-bold">
            <mat-icon>build</mat-icon>
          </div>
          <div class="flex align-items-center justify-content-center font-bold">
            {{ t('stepTitle') }}
          </div>
        </div>
      </ng-template>
      <ng-template #stepEditor let-control="control">
        <app-target-editor [formControl]="control"></app-target-editor>
      </ng-template>
    </app-sequence-steps>
  </form>
</ng-container>
