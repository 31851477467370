<ng-container *transloco="let t; read: 'target-offers-editor'">
  <form [formGroup]="form">
    <ng-container formArrayName="offers">
      <div class="grid">
        <div class="col-12 formgrid p-fluid">
          <ng-container *ngIf="selectedOptions$ | async as selectedOptions">
            <div *ngFor="let offer of offers.controls; index as i" class="row field col-12" [formGroup]="offer">
              <!-- Timing -->
              <div class="col mt-3">
                <span class="p-float-label">
                  <p-dropdown
                    ariaLabel="{{ t('offerTimingLabel') }}"
                    id="offerTiming_{{ i }}"
                    optionLabel="label"
                    optionValue="isoDuration"
                    [formControlName]="'delay'"
                    [options]="timingOptions$ | async"
                  >
                    <ng-template pTemplate="selectedItem">
                      <div *ngIf="selectedOptions[i] as selectedOption" class="row w-full">
                        <div class="col">
                          <mat-icon class="pl-1">{{ selectedOption.iconName }}</mat-icon>
                        </div>
                        <div class="col">
                          {{
                            t(selectedOption.label, {
                              minutes: selectedOption.minutes,
                              seconds: selectedOption.seconds,
                              hours: selectedOption.hours
                            })
                          }}
                        </div>
                      </div>
                    </ng-template>

                    <ng-template pTemplate="item" let-timingOption>
                      <div class="row w-full">
                        <div class="col">
                          <mat-icon class="pl-1">{{ timingOption.iconName }}</mat-icon>
                        </div>
                        <div class="col">
                          {{
                            t(timingOption.label, {
                              minutes: timingOption.minutes,
                              seconds: timingOption.seconds,
                              hours: timingOption.hours
                            })
                          }}
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <label for="offerTiming_{{ i }}">{{ t('offerTimingLabel') }}</label>
                </span>
              </div>

              <!-- Via -->
              <div class="col mt-3 flex justify-content-around">
                <p>{{ t('offerTimingVia') }}</p>
              </div>

              <!-- Offer Channel -->
              <div class="col mt-3">
                <span class="p-float-label">
                  <p-dropdown
                    ariaLabel="{{ t('offerChannelLabel') }}"
                    id="offerChannel_{{ i }}"
                    optionLabel="label"
                    optionValue="value"
                    [formControlName]="'channel'"
                    [options]="channels"
                  >
                    <ng-template pTemplate="selectedItem" let-offerChannel>
                      <div class="row w-full">
                        <div class="col">
                          <mat-icon class="pl-1">{{ offerChannel.iconName }}</mat-icon>
                        </div>
                        <div class="col">
                          {{ t(offerChannel.label) }}
                        </div>
                      </div>
                    </ng-template>
                    <ng-template pTemplate="item" let-offerChannel>
                      <div class="row w-full">
                        <div class="col">
                          <mat-icon class="pl-1">{{ offerChannel.iconName }}</mat-icon>
                        </div>
                        <div class="col">
                          {{ t(offerChannel.label) }}
                        </div>
                      </div>
                    </ng-template>
                  </p-dropdown>
                  <label for="offerChannel_{{ i }}">{{ t('offerChannelLabel') }}</label>
                </span>
              </div>

              <!-- Trash -->
              <div class="col mt-3 w-min">
                <button
                  pButton
                  pTooltip="{{ t('removeOfferButtonTooltip', { index: i }) }}"
                  attr.aria-label="{{ t('removeOfferButtonLabel', { index: i }) }}"
                  class="p-button-danger p-button-text p-button-lg w-fit"
                  icon="pi pi-trash"
                  type="button"
                  [disabled]="form.disabled"
                  (click)="removeStepAtIndex(i)"
                ></button>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </form>
  <button
    pButton
    pTooltip="{{ t('addReservationOfferTooltip') }}"
    class="p-button-primary p-button-text w-fit"
    icon="pi pi-plus"
    label="{{ t('addReservationOfferButtonLabel') }}"
    type="button"
    [disabled]="form.disabled"
    (click)="addStepAtEnd()"
  ></button>
</ng-container>
