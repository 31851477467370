import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LetModule } from '@ngrx/component';
import { Call, Device } from '@twilio/voice-sdk';
import { secondsToMilliseconds } from 'date-fns';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, filter, firstValueFrom, interval, map, switchMap, takeUntil } from 'rxjs';

import { CallLogDisplay, formatDuration } from '@pwp-common';

import { CommunicationsService } from '../../../services/call/communications/communications.service';
import { AuthService } from '../../../services/user/auth/auth.service';

import { CallState } from './call-state';

@Component({
  selector: 'app-dial-ongoing-call-dialog',
  standalone: true,
  imports: [ButtonModule, CommonModule, LetModule, TranslocoModule],
  templateUrl: './dial-ongoing-call-dialog.component.html',
  styleUrls: ['./dial-ongoing-call-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialOngoingCallDialogComponent {
  private call?: Call;

  public readonly callState$ = new BehaviorSubject(CallState.notInitiated);

  private readonly authService = inject(AuthService);

  private readonly communicationsService = inject(CommunicationsService);

  private readonly data = inject<DynamicDialogConfig<{ callLogDisplay: CallLogDisplay }>>(DynamicDialogConfig).data;

  private readonly dialogRef = inject(DynamicDialogRef);

  public readonly listeningDuration$ = this.callState$.pipe(
    filter((state) => state === CallState.connected),
    switchMap(() => interval(1000)),
    map((loop) => formatDuration(secondsToMilliseconds(loop + 1))),
    takeUntil(this.callState$.pipe(filter((state) => state === CallState.terminated))),
  );

  public close(): void {
    this.call?.disconnect();
    this.dialogRef.close();
  }

  public async dial(): Promise<void> {
    this.callState$.next(CallState.connecting);

    const token = await this.communicationsService.getVoiceDeviceToken();
    const orgId = await firstValueFrom(this.authService.getOrgId());

    this.call = await new Device(token, { disableAudioContextSounds: true }).connect({
      params: {
        orgId,
        sessionId: this.data.callLogDisplay.callLog.getId(),
      },
    });

    this.call.once('accept', () => this.callState$.next(CallState.connected));
    this.call.once('disconnect', () => this.callState$.next(CallState.terminated));
    this.call.on('error', () => this.callState$.next(CallState.failed));
  }
}
