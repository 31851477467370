import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { DeleteUserRequest, DeleteUserResponse } from '@pwp-common';

import { CallableFunctionService } from '../../generic/callable-function-service/callable-function-service';

@Injectable({
  providedIn: 'root',
})
export class DeleteUserService extends CallableFunctionService<DeleteUserRequest, DeleteUserResponse> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  // @ts-ignore
  constructor(private _fns: AngularFireFunctions) {
    super(DeleteUserResponse, _fns);
  }

  ///////////////////////////////////////////////////////////////////////
  // Function Name
  ///////////////////////////////////////////////////////////////////////

  public getFunctionName() {
    return 'deleteUser';
  }
}
