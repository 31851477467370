import {cloneDeep} from 'lodash';
import {ReservationSkipOffersIfConstructor} from './reservation-skip-offers-if-constructor';
import {ReservationSkipOffersIfSchema} from './reservation-skip-offers-if-schema';
import {SerializableObject} from '../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {ReservationSkipOffersIfRule} from '../reservation-skip-offers-if-rule/generic/reservation-skip-offers-if-rule/reservation-skip-offers-if-rule';

export class ReservationSkipOffersIf extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected rules!: ReservationSkipOffersIfRule[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ReservationSkipOffersIfConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ReservationSkipOffersIf {
    return new ReservationSkipOffersIf(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ReservationSkipOffersIf.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ReservationSkipOffersIfSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getRules(): ReservationSkipOffersIfRule[] {
    return cloneDeep(this.rules);
  }
}
