import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {UserResponseSchema} from '../../generic/user-response/user-response-schema';

import {UserResponseChangeOrgIdError} from './user-response-change-org-id-error';

export class UserResponseChangeOrgIdSchema extends UserResponseSchema {
  protected static readonly userId = 'userId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class extends UserResponseSchema.Defaults {
    public static readonly userId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[UserResponseChangeOrgIdSchema.error] = SchemaField.string(UserResponseChangeOrgIdSchema.Defaults.error)
      .optional()
      .valid(...Object.values(UserResponseChangeOrgIdError));
    parameters[UserResponseChangeOrgIdSchema.userId] = SchemaField.string(UserResponseChangeOrgIdSchema.Defaults.userId).required();

    return parameters;
  }
}
