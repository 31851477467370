import type {AnySchema} from 'joi';
import Joi from 'joi';

import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {UserRequestChangeOrgIdSchema} from '../../requests/user-request-change-org-id/user-request-change-org-id-schema';
import {UserRequestCreateUserSchema} from '../../requests/user-request-create-user/user-request-create-user-schema';

export class AnyUserRequestSchema extends SerializableObjectSchema {
  private static readonly SCHEMA_ID = 'anyUserRequest';

  private static computedSchema = Joi.alternatives().try(new UserRequestChangeOrgIdSchema().getJoiSchema(), new UserRequestCreateUserSchema().getJoiSchema()).id(AnyUserRequestSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly GenericDefaults = class {
    public static readonly type = undefined;
  };

  public static getSchemaId(): string {
    return `#${AnyUserRequestSchema.SCHEMA_ID}`;
  }

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} | AnySchema {
    return AnyUserRequestSchema.computedSchema;
  }
}
