import {difference, isNil} from 'lodash';

export class DbDataIds {
  constructor(public params: {serviceLimits: Set<string>}) {}
  public add(dbDataIds: DbDataIds): DbDataIds {
    if (isNil(dbDataIds)) {
      return this;
    }
    dbDataIds.params.serviceLimits.forEach(serviceLimitId => this.params.serviceLimits.add(serviceLimitId));
    return this;
  }

  /**
   * Return the list of ids that are in this set, but not in the provided set. This conforms to
   * notation of set difference:
   *
   * A - B = {x | x ∈ A where x ∉ B}
   * @param dbDataIds
   */
  public difference(dbDataIds: DbDataIds): DbDataIds {
    if (isNil(dbDataIds)) {
      return this;
    }
    const result = new DbDataIds({serviceLimits: new Set<string>()});
    result.params.serviceLimits = new Set(difference(Array.from(this.params.serviceLimits), Array.from(dbDataIds.params.serviceLimits)));

    return result;
  }
}
