import { isNil } from '@ngneat/transloco';
import { Howl } from 'howler';
import { Subject } from 'rxjs';

export interface SoundInterface {
  sourceUrl: string;
  howl: Howl;
}

export interface SoundProgressInterface {
  secsPlayed: number;
  secsRemaining: number;
  percentComplete: number;
}

export class HowlerPlayer {
  private howl: Howl;

  private $progress: Subject<SoundProgressInterface>;

  /** */
  constructor(private playUrl: string) {
    this.$progress = new Subject();
  }

  public getDuration() {
    const duration = this.howl?.duration() || 0;
    // console.log('Duration: ', duration);
    return duration;
  }

  public isPlaying() {
    return this.howl?.playing() || false;
  }

  public cleanup() {
    this.howl.stop();
    this.howl = undefined;
  }

  public play() {
    if (isNil(this.howl)) {
      this.howl = new Howl({
        src: [this.playUrl],
        onplay: () => {
          requestAnimationFrame(this.seekStep); //  PROGRESS STEP CALL
        },
        onseek: () => {
          // Start upating the progress of the track.
          requestAnimationFrame(this.seekStep);
        },
        onend: () => {
          console.log('Done playing audio');
        },
      });
    }
    this.howl.play();
  }

  /** */
  public pause(): void {
    this.howl.pause();
  }

  /** */
  public stop(): void {
    this.howl.stop();
  }

  /** */
  public seekToSeconds(secs: number): void {
    if (secs <= 0 || secs >= this.getDuration()) {
      console.log('HowlerPlayer.seekToSeconds: Invalid seek seconds', secs);
      return;
    }

    this.howl.seek(secs);
  }

  /** */
  public fastforward(secs = 5): void {
    const sound = this.howl;
    const timeToSeek = (sound.seek() as number) + secs;

    if (timeToSeek >= sound.duration()) {
      // this.skip();
    } else {
      sound.seek(timeToSeek);
    }
  }

  /** */
  public rewind(secs = 5): void {
    const sound = this.howl;
    let timeToSeek = (sound.seek() as number) - secs;

    timeToSeek = timeToSeek <= 0 ? 0 : timeToSeek;

    sound.seek(timeToSeek);
  }

  /** */
  private seekStep = () => {
    if (this.howl?.playing()) {
      const currPos = this.howl.seek() as number;
      const totalDuration = this.getDuration();
      const progress: SoundProgressInterface = {
        secsPlayed: currPos,
        secsRemaining: totalDuration - currPos,
        percentComplete: Math.round((currPos * 100) / totalDuration),
      };
      this.$progress.next(progress);

      requestAnimationFrame(this.seekStep);
    }
  };

  /** */
  public onPlay(): Subject<SoundProgressInterface> {
    return this.$progress;
  }
}
