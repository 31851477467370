import {CommunicationTaskQueueWorkerListSchema} from '../../generic/communication-task-queue-worker-list/communication-task-queue-worker-list-schema';
import {SchemaField} from '../../../../../generic/serialization/schema-field';

export class CommunicationTaskQueueWorkerListUserSchema extends CommunicationTaskQueueWorkerListSchema {
  public static readonly userIds = 'userIds';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly userIds = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationTaskQueueWorkerListUserSchema.userIds] = SchemaField.shortStringArray(
      CommunicationTaskQueueWorkerListUserSchema.Defaults.userIds
    )
      .required()
      .items(SchemaField.userId(undefined))
      .unique();
    /* eslint-enable */

    return parameters;
  }
}
