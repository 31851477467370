import { AbstractControl } from '@angular/forms';

export interface SequenceStep {
  type: SequenceStepType;
  formControlIndex: number | undefined;
  showAddIcon?: boolean;
  control: AbstractControl | undefined;
}

export enum SequenceStepType {
  start = 'start',
  end = 'end',
  step = 'step',
}
