import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isNil } from 'lodash';

import { IVR, IVROption, IVRSchema, SupportedLanguages, VoiceResponseCommand } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { possibleSpeechModels } from '../../../../common/twilio/twilio-language';
import { IvrService } from '../../../../services/call/ivr/ivr.service';
import { ConfigDocSelectAndEdit } from '../../../generic/abstract-classes/config-doc-select-and-edit';
@Component({
  selector: 'app-ivr-select-and-edit',
  templateUrl: './ivr-select-and-edit.component.html',
  styleUrls: ['./ivr-select-and-edit.component.css'],
})
export class IvrSelectAndEditComponent extends ConfigDocSelectAndEdit<IVR> implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  options: IVROption[];

  selectedOptionKVPair: KVPair<IVROption>;

  ////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////

  possibleSpeechModels = possibleSpeechModels;

  possibleLanguages = SupportedLanguages.all;

  ////////////////////////////////////////////////////////////////////////
  // Sub Components
  ////////////////////////////////////////////////////////////////////////

  public invalidKeyPressedCommands: VoiceResponseCommand[];

  public menuOptionsCommands: VoiceResponseCommand[];

  public onFailureCommands: VoiceResponseCommand[];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _dataService: IvrService,
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(IVR, _dataService, _changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair.value.getId(),
      [
        IVRSchema.displayName,
        IVRSchema.description,
        IVRSchema.maxDigits,
        IVRSchema.numRetriesRemaining,
        IVRSchema.timeout,
        IVRSchema.speechTimeout,
        IVRSchema.speechModel,
        IVRSchema.defaultLanguage,
      ],
      IVRSchema.Defaults,
      this,
    );

    parameters[IVRSchema.invalidKeyPressedCommands] = this.invalidKeyPressedCommands || IVRSchema.Defaults.invalidKeyPressedCommands;
    parameters[IVRSchema.menuOptionsCommands] = this.menuOptionsCommands || IVRSchema.Defaults.menuOptionsCommands;
    parameters[IVRSchema.onFailure] = this.onFailureCommands || IVRSchema.Defaults.onFailure;
    parameters[IVRSchema.options] = this.options;

    const ivr = new IVR(parameters);
    return ivr;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: IVR) {
    // Init Form
    const formConfig = {} as any;
    formConfig[IVRSchema.displayName] = [obj.getDisplayName(), [Validators.required, Validators.maxLength(500)]];
    formConfig[IVRSchema.description] = [obj.getDescription(), [Validators.required, Validators.maxLength(500)]];
    formConfig[IVRSchema.maxDigits] = [obj.getMaxDigits(), [Validators.required]];
    formConfig[IVRSchema.numRetriesRemaining] = [obj.getNumRetriesRemaining()];
    formConfig[IVRSchema.timeout] = [obj.getTimeout()];
    formConfig[IVRSchema.speechTimeout] = [obj.getSpeechTimeout()];
    formConfig[IVRSchema.speechModel] = [obj.getSpeechModel()];
    formConfig[IVRSchema.defaultLanguage] = [obj.getDefaultLanguage()];

    this.form = this.formBuilder.group(formConfig);

    // Init other state
    this.invalidKeyPressedCommands = obj.getInvalidKeyPressedCommands();
    this.menuOptionsCommands = obj.getMenuOptionsCommands();
    this.onFailureCommands = obj.getOnFailure();
    this.options = obj.getOptions();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName(): AbstractControl | null {
    return this.form.get(IVRSchema.displayName);
  }

  get description(): AbstractControl | null {
    return this.form.get(IVRSchema.description);
  }

  get maxDigits(): AbstractControl | null {
    return this.form.get(IVRSchema.maxDigits);
  }

  get numRetriesRemaining(): AbstractControl | null {
    return this.form.get(IVRSchema.numRetriesRemaining);
  }

  get timeout(): AbstractControl | null {
    return this.form.get(IVRSchema.timeout);
  }

  get speechTimeout(): AbstractControl | null {
    return this.form.get(IVRSchema.speechTimeout);
  }

  get speechModel(): AbstractControl | null {
    return this.form.get(IVRSchema.speechModel);
  }

  get defaultLanguage(): AbstractControl | null {
    return this.form.get(IVRSchema.defaultLanguage);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.menuOptionsCommands) || this.menuOptionsCommands.length === 0) {
      return false;
    }

    // Check that menu options commands are only play/say
    for (const menuCommand of this.menuOptionsCommands) {
      if (!IVRSchema.Constants.allowedMenuOptionsCommands.includes(menuCommand.getCommandName())) {
        console.log(
          `IvrSelectAndEditComponent.isValidObj: Found an unsupported menu option command of type: ${menuCommand.getCommandName()}`,
        );
        return false;
      }
    }

    return true;
  }
}
