import {cloneDeep} from 'lodash';
import {ServiceLimitServiceUsageThresholdConstructor} from './service-limit-service-usage-threshold-constructor';
import {ServiceLimitServiceUsageThresholdSchema} from './service-limit-service-usage-threshold-schema';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';

export class ServiceLimitServiceUsageThreshold extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected serviceDeliveredNum!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceLimitServiceUsageThresholdConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ServiceLimitServiceUsageThreshold {
    const obj = new ServiceLimitServiceUsageThreshold(super._deserialize(validationResult));
    return obj;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ServiceLimitServiceUsageThreshold.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ServiceLimitServiceUsageThresholdSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getServiceDeliveredNum(): number {
    return cloneDeep(this.serviceDeliveredNum);
  }
}
