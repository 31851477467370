import type {ValidationResult} from 'joi';

import {DBDocObject} from '../../generic/db-doc/db-doc-object';
import {FormBuilderVendor} from '../form-builder-vendor';

import {FormVersionSchema} from './form-version-schema';
import {FormVersionConstructor} from './form-version-constructor';

export class FormVersion extends DBDocObject {
  public formId!: string;

  public formSchema!: string;

  public type!: FormBuilderVendor;

  constructor(parameters: FormVersionConstructor) {
    super(parameters);
  }

  protected static _deserialize(validationResult: ValidationResult): FormVersion {
    return new FormVersion(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(FormVersion.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): FormVersionSchema {
    return new FormVersionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getFormId(): string {
    return this.formId;
  }

  public getFormSchema(): string {
    return this.formSchema;
  }

  public getType(): FormBuilderVendor {
    return this.type;
  }
}
