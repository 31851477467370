import {GetNumBillableMinutesInput} from './interfaces';

export const getNumBillableMinutes = (params: GetNumBillableMinutesInput): number => {
  if (params.isInProgress) {
    return 0;
  }

  /**
   * The operator marks calls that are disconnected before answer as
   * duration 0, however such calls count as 1 billable minute.
   */
  const durationMS = Math.max(params.operatorDurationMS || 0, 1);
  return Math.ceil(durationMS / (1000 * 60));
};
