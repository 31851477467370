import {Roles} from '../roles/roles';
import {UserData} from '../user-data/user-data';
import {UserPrivateData} from '../user-private-data/user-private-data';
import {AllDataUser} from './all-data-user';
/**
 * @param userDataMap Map of UserData. Keys are userId, values are UserData.
 * @param rolesMap Map of UserProperties. Keys are userId, values are UserProperties.
 * @param userPrivateDataMap Map of UserPrivateData. Keys are userId, values are UserPrivateData.
 */

export const getAllDataUserMapFromMaps = (
  userDataMap: Map<string, UserData>,
  rolesMap: Map<string, Roles>,
  userPrivateDataMap: Map<string, UserPrivateData>,
  allowMissingUserPrivate = false
): Map<string, AllDataUser> => {
  const result: Map<string, AllDataUser> = new Map<string, AllDataUser>();

  // Get set of all unique userIds.
  const userIds = new Set<string>();
  for (const map of [userDataMap, rolesMap, userPrivateDataMap]) {
    for (const userId of map.keys()) {
      userIds.add(userId);
    }
  }

  for (const userId of userIds) {
    const userData = userDataMap.get(userId) as UserData;
    const roles = rolesMap.get(userId) as Roles;
    const userPrivateData = userPrivateDataMap.get(userId) as UserPrivateData;

    if (userData === undefined || roles === undefined || (allowMissingUserPrivate && userPrivateData === undefined)) {
      console.error(`getAllDataUserMapFromMaps: Skipping invalid user with 
        userData.userId=${userData?.getId()}
        roles.userId=${roles?.getId()}
        userPrivateData.userId=${userPrivateData?.getId()}`);
      continue;
    }
    const allDataUser = new AllDataUser(userData, roles, userPrivateData);
    result.set(userId, allDataUser);
  }

  return result;
};
