import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { OrgData, OrgDataSchema } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from '../../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class OrgDataService extends DbDocumentService<OrgData> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, OrgData);
  }

  ///////////////////////////////////////////////////////////////////////
  // Firebase Reference
  ///////////////////////////////////////////////////////////////////////

  protected docRef(orgId: string): Observable<AngularFirestoreDocument> {
    return of(this.db.doc(this.getSchema().orgDoc(orgId)));
  }

  ///////////////////////////////////////////////////////////////////////
  // Base Methods
  ///////////////////////////////////////////////////////////////////////

  protected getSchema(): OrgDataSchema {
    return new OrgDataSchema();
  }

  ///////////////////////////////////////////////////////////////////////
  // Logging
  ///////////////////////////////////////////////////////////////////////

  /**
   * Return parameters appending to beginning of some logging statements for
   * debugging purposes
   */
  protected getLoggingInfo(): string {
    return `collection=orgs`;
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Org Data
  ///////////////////////////////////////////////////////////////////////

  public getOrgData(): Observable<OrgData> {
    const observable = this.getInjectedAuthService()
      .getOrgId()
      .pipe(switchMap((orgId: string, _: number) => this.getDoc(orgId)));

    return observable;
  }
}
