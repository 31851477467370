<ng-container *transloco="let t; read: 'inbox-callback-detail'">
  <app-inbox-callback-detail-caller-details [row]="row"></app-inbox-callback-detail-caller-details>
  <div *ngIf="row.voicemailRow === undefined">
    <p>{{ t('noVoicemail') }}</p>
  </div>
  <div class="flex gap-2">
    <app-inbox-callback-detail-handled-by-button [row]="row"></app-inbox-callback-detail-handled-by-button>
    <p-button styleClass="p-button-outlined" (click)="openCreateNoteDialog()">{{ t('addNoteButtonLabel') }}</p-button>
    <app-inbox-callback-dial-phone-button [row]="row"></app-inbox-callback-dial-phone-button>
  </div>
  <app-inbox-voicemail-play *ngIf="row.voicemailRow" [row]="row.voicemailRow"></app-inbox-voicemail-play>
</ng-container>

<p-toast position="bottom-center"></p-toast>
