<!-- Dial Call List Command -->
<form *ngIf="!loading" [formGroup]="form">
  <mat-form-field>
    <mat-label>New Command Type</mat-label>
    <mat-select formControlName="commandName">
      <mat-option *ngFor="let name of namesList" [value]="name">{{ name }}</mat-option>
    </mat-select>
  </mat-form-field>
</form>

<div [ngSwitch]="commandName?.value">
  <!-- Create Async Service Request -->
  <app-vrc-editor-create-async-service-request
    *ngSwitchCase="names.createAsyncServiceRequest"
    [communicationWidgetAsyncServiceRequestArray]="communicationWidgetAsyncServiceRequestArray"
    [(obj)]="obj"
    (objChange)="updateObjAndEmit()"
  >
  </app-vrc-editor-create-async-service-request>
  <!-- Dial Call List -->
  <app-vrc-editor-dial-call-list
    *ngSwitchCase="names.dialCallList"
    [callListArray]="callListArray"
    [(obj)]="obj"
    (objChange)="updateObjAndEmit()"
  >
  </app-vrc-editor-dial-call-list>

  <!-- Enforce Blocked Caller -->
  <app-vrc-editor-enforce-blocked-caller *ngSwitchCase="names.enforceBlockedCaller" [(obj)]="obj" (objChange)="updateObjAndEmit()">
  </app-vrc-editor-enforce-blocked-caller>

  <!-- Play Or Say -->
  <app-vrc-editor-play-or-say
    *ngSwitchCase="names.playOrSay"
    [vrcAudioMetadataArray]="vrcAudioMetadataArray"
    [(obj)]="obj"
    (objChange)="updateObjAndEmit()"
  >
  </app-vrc-editor-play-or-say>

  <!-- Exec IVR -->
  <app-vrc-editor-exec-ivr *ngSwitchCase="names.execIVR" [ivrsArray]="ivrsArray" [(obj)]="obj" (objChange)="updateObjAndEmit()">
  </app-vrc-editor-exec-ivr>

  <!-- Exec IVR -->
  <app-vrc-editor-set-context *ngSwitchCase="names.setContext" [(obj)]="obj" (objChange)="updateObjAndEmit()"> </app-vrc-editor-set-context>

  <!-- Forward -->
  <app-vrc-editor-forward *ngSwitchCase="names.forward" [(obj)]="obj" (objChange)="updateObjAndEmit()"> </app-vrc-editor-forward>

  <!-- Switch -->
  <app-vrc-editor-switch *ngSwitchCase="names.switch" [(obj)]="obj" (objChange)="updateObjAndEmit()"> </app-vrc-editor-switch>

  <!-- Take Voicemail -->
  <app-vrc-editor-take-voicemail
    *ngSwitchCase="names.takeVoicemail"
    [voicemailConfigArray]="voicemailConfigArray"
    [(obj)]="obj"
    (objChange)="updateObjAndEmit()"
  >
  </app-vrc-editor-take-voicemail>
</div>

<!-- Debug View -->
<app-obj-debug-view [item]="obj?.value"></app-obj-debug-view>
