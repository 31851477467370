import { isNil } from 'lodash';

import {
  ReservationSkipOffersIf,
  ReservationSkipOffersIfRule,
  ReservationSkipOffersIfRuleAvailability,
  ReservationSkipOffersIfRuleChannelMax,
  ReservationSkipOffersIfRuleChannelMaxChannelName,
  ReservationSkipOffersIfRuleName,
} from '@pwp-common';

import { defaultReservationSkipOffersIfEditorOutput } from '../../default-reservation-skip-offers-if-editor-output';
import { ReservationSkipOffersIfEditorOutput } from '../../reservation-skip-offers-if-editor-output';

export const reservationSkipOffersIfEditorOutputToDb = (
  skipIfEditorOutput: ReservationSkipOffersIfEditorOutput,
): ReservationSkipOffersIf => {
  const rules: ReservationSkipOffersIfRule[] = [];

  if (isNil(skipIfEditorOutput)) {
    return reservationSkipOffersIfEditorOutputToDb(defaultReservationSkipOffersIfEditorOutput);
  }

  if (skipIfEditorOutput.skipIfUnavailable) {
    rules.push(
      new ReservationSkipOffersIfRuleAvailability({
        type: ReservationSkipOffersIfRuleName.availability,
        skipIfUnavailable: true,
      }),
    );
  }

  if (!isNil(skipIfEditorOutput.skipIfNumCallsInProgressGE)) {
    rules.push(
      new ReservationSkipOffersIfRuleChannelMax({
        type: ReservationSkipOffersIfRuleName.channelMax,
        channel: ReservationSkipOffersIfRuleChannelMaxChannelName.phone,
        skipIfInProgressGE: skipIfEditorOutput.skipIfNumCallsInProgressGE,
      }),
    );
  }

  return new ReservationSkipOffersIf({ rules });
};
