import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {CommunicationsResponse} from '../../generic/communications-response/communications-response';
import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {CommunicationsResponseDialConferenceConstructor} from './communications-response-dial-conference-constructor';
import {CommunicationsResponseDialConferenceSchema} from './communications-response-dial-conference-schema';
import {CommunicationsResponseDialConferenceError} from './communications-response-dial-conference-error';
import {CommunicationsRequestName} from '../../../communications-request-name';
import {CommunicationSessionId} from '../../../../../objects/core/communication-session-id/communication-session-id';

export class CommunicationsResponseDialConference extends CommunicationsResponse<CommunicationsResponseDialConferenceError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected sessionId!: CommunicationSessionId;
  protected conferenceFriendlyName!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsResponseDialConferenceConstructor) {
    (parameters as any)[CommunicationsResponseSchema.type] = CommunicationsRequestName.dialConference;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationsResponseDialConference {
    return new CommunicationsResponseDialConference(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsResponseDialConference.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsResponseDialConferenceSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getSessionId() {
    return cloneDeep(this.sessionId);
  }

  public getConferenceFriendlyName(): string | undefined {
    return cloneDeep(this.conferenceFriendlyName);
  }
}
