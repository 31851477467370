import { ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { defer } from 'rxjs';

import {
  ConversationRequestCloseAndDelete,
  ConversationRequestName,
  ConversationState,
  ConversationUserIdentityType,
  TwilioConversationState,
} from '@pwp-common';

import { Message } from '../../../common/conversation/chat-component/interfaces';
import { RoomWithMetadata } from '../../../common/conversation/conversation-client/conversation/room/get-room';
import { ConversationClientDisplay } from '../../../common/conversation/conversation-client-display/conversation-client-display';
import {
  PerConversationAction,
  PerConversationActionMenuItem,
  PerConversationActionType,
} from '../../../common/conversation/conversation-client-display/per-conversation-action-type';
import { NgChanges } from '../../../common/objects/ng-changes';
import { ConversationEndpointService } from '../../../services/conversation/conversation-endpoint/conversation-endpoint.service';
import { ConversationLogService } from '../../../services/conversation/conversation-log/conversation-log.service';

@UntilDestroy()
@Component({
  selector: 'app-conversation-select-and-edit',
  templateUrl: './conversation-select-and-edit.component.html',
  styleUrls: ['./conversation-select-and-edit.component.css'],
  providers: [ConfirmationService, MessageService],
})
export class ConversationSelectAndEditComponent implements OnChanges {
  ///////////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////////

  @Input()
  client: ConversationClientDisplay;

  ///////////////////////////////////////////////////////////////////////////
  // State
  ///////////////////////////////////////////////////////////////////////////

  loading = false;

  messagesLoaded = false;

  roomsLoaded = false;

  rooms: RoomWithMetadata[] = [];

  menuActions: PerConversationActionMenuItem[] = [];

  messages: Message[] = [];

  selectedRoom: RoomWithMetadata;

  showFooter = true;

  public readonly participantDisplayId = ConversationUserIdentityType.userId;

  public readonly messages$ = defer(() => this.client?.displayableMessages$);

  ///////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////////

  constructor(
    private conversationEndpointService: ConversationEndpointService,
    private changeDetectorRef: ChangeDetectorRef,
    private confirmationService: ConfirmationService,
    private translocoService: TranslocoService,
    private messageService: MessageService,
    private conversationLogService: ConversationLogService,
  ) {}

  ngOnChanges(changes: NgChanges<ConversationSelectAndEditComponent>): void {
    this.closeObservers();
    this.initClient();
  }

  public closeObservers() {}

  private initClient() {
    if (isNil(this.client)) {
      return;
    }
    console.log('ConversationSelectAndEditComponent.initClient: Starting');

    this.client.$messagesLoaded.pipe(untilDestroyed(this), untilDestroyed(this, 'closeObservers')).subscribe((messagesLoaded) => {
      this.messagesLoaded = messagesLoaded;
      this.changeDetectorRef.detectChanges();
    });
    this.client.$perConversationMenuItems.pipe(untilDestroyed(this), untilDestroyed(this, 'closeObservers')).subscribe((menuItems) => {
      for (const item of menuItems) {
        item.title = this.translocoService.translate(`vue-advanced-chat.${item.name}`);
      }
      this.menuActions = menuItems;
      this.changeDetectorRef.detectChanges();
    });
    this.client.$room.pipe(untilDestroyed(this), untilDestroyed(this, 'closeObservers')).subscribe((selectedRoom) => {
      this.selectedRoom = selectedRoom;
      this.changeDetectorRef.detectChanges();
    });
    this.client.$rooms.pipe(untilDestroyed(this), untilDestroyed(this, 'closeObservers')).subscribe((rooms) => {
      this.rooms = rooms;
      this.changeDetectorRef.detectChanges();
    });
    this.client.$roomsLoaded.pipe(untilDestroyed(this), untilDestroyed(this, 'closeObservers')).subscribe((roomsLoaded) => {
      this.roomsLoaded = roomsLoaded;
      this.changeDetectorRef.detectChanges();
    });
    this.client.$state.pipe(untilDestroyed(this), untilDestroyed(this, 'closeObservers')).subscribe((realtimeState) => {
      if (realtimeState?.state === TwilioConversationState.closed) {
        this.showFooter = false;
      } else {
        this.showFooter = true;
      }
      this.changeDetectorRef.detectChanges();
    });

    console.log('initClient: Completed');
  }

  ///////////////////////////////////////////////////////////////////////////
  // Send Message
  ///////////////////////////////////////////////////////////////////////////

  async sendMessage(message: string) {
    if (isNil(this.selectedRoom?.uniqueName)) {
      this.showError({ message: 'No Room Selected' }, 'sendMessageErrorTitle');
      return;
    }
    try {
      void this.conversationLogService.changeState(this.selectedRoom.uniqueName, ConversationState.serviceDeliveryInProgress);
    } catch (error) {
      this.showError(error, 'sendMessageErrorTitle');
    }

    try {
      this.client?.sendMessage(message);
    } catch (error) {
      this.showError(error, 'sendMessageErrorTitle');
    }
  }

  ///////////////////////////////////////////////////////////////////////////
  // Get Messages
  ///////////////////////////////////////////////////////////////////////////

  async getMessages(event: { room: RoomWithMetadata; options: { reset: boolean } }) {
    console.log('getMessages: Starting');

    const conversationSid = event.room?.sid as string;
    if (isNil(conversationSid)) {
      console.log('getMessages: Nothing to do because no room is selected', { conversationSid });
      return;
    }

    try {
      await this.client?.setSelectedConversation(conversationSid);
    } catch (error) {
      this.showError(error, 'getMessagesErrorTitle');
    }
    console.log('getMessages: Completed', { conversationSid });
  }

  ///////////////////////////////////////////////////////////////////////////
  // Menu Actions
  ///////////////////////////////////////////////////////////////////////////

  async menuActionsHandler({ action: { name: actionName }, roomId }: { action: { name: PerConversationActionType }; roomId: string }) {
    console.log('ConversationSelectAndEditComponent.menuActionsHandler: Starting', { actionName, roomId });
    switch (actionName) {
      case PerConversationAction.closeAndDeleteConversation: {
        this.deleteConversationWithConfirmation(roomId);
        break;
      }
    }
    console.log('ConversationSelectAndEditComponent.menuActionsHandler: Completed', { actionName, roomId });
  }

  ///////////////////////////////////////////////////////////////////////////
  // Delete Conversation
  ///////////////////////////////////////////////////////////////////////////

  private deleteConversationWithConfirmation(roomId: string) {
    if (this.selectedRoom?.roomId !== roomId) {
      console.error('deleteConversationWithConfirmation: Mismatch. Refusing to delete the wrong conversation.', {
        selectedRoom: this.selectedRoom,
        roomId,
      });
      return;
    }

    const title = this.translocoService.translate('conversation-select-and-edit.confirmDeleteConversationTitle');
    const body = this.translocoService.translate('conversation-select-and-edit.confirmDeleteConversationBody');

    this.confirmationService.confirm({
      message: body,
      header: title,
      accept: async () => {
        try {
          this.loading = true;
          await this.conversationEndpointService.closeAndDelete(
            new ConversationRequestCloseAndDelete({
              type: ConversationRequestName.closeAndDelete,
              conversationLogId: this.selectedRoom.uniqueName,
            }),
          );
          /**
           * Manually remove ability to delete successfully deleted conversation.
           * This will eventually happen automatically because the state will update to closed, but
           * users will try to delete conversations again during the interim.
           */
          this.menuActions = this.menuActions.filter((z) => z.name !== PerConversationAction.closeAndDeleteConversation);
          this.showDeleteConversationSuccess();
        } catch (error) {
          console.error(error);
          this.showError(error, 'deleteConversationErrorTitle');
        }
      },
    });
  }

  ///////////////////////////////////////////////////////////////////////////
  // Message
  ///////////////////////////////////////////////////////////////////////////

  private showDeleteConversationSuccess() {
    const title = this.translocoService.translate(`conversation-select-and-edit.deleteConversationSuccessTitle`);
    const body = this.translocoService.translate(`conversation-select-and-edit.deleteConversationSuccessBody`);

    this.messageService.add({
      severity: 'success',
      summary: title,
      detail: body,
    });
  }

  private showError(
    caughtError: any,
    titleKey: 'initializationErrorTitle' | 'sendMessageErrorTitle' | 'getMessagesErrorTitle' | 'deleteConversationErrorTitle',
  ) {
    console.error(caughtError);
    const title = this.translocoService.translate(`conversation-select-and-edit.${titleKey}`);

    this.messageService.add({
      severity: 'error',
      closable: true,
      sticky: true,
      summary: title,
      detail: JSON.stringify(caughtError),
    });
  }
}
