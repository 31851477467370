<ng-container *transloco="let t">
  <p>{{ t('analytics-table.multiSelectInfo') }}</p>

  <p-table
    #dt
    dataKey="{{ dataKey }}"
    responsiveLayout="scroll"
    selectionMode="single"
    sortMode="multiple"
    styleClass="p-datatable-gridlines p-datatable-striped"
    [columns]="selectedCols"
    [paginator]="true"
    [resizableColumns]="true"
    [rows]="50"
    [rowsPerPageOptions]="[25, 50, 100, 500]"
    [value]="data"
  >
    <!-- Select Columns to Display -->
    <ng-template pTemplate="caption">
      <h3>{{ t(getTranslationKey('title')) }}</h3>
      <div style="text-align: left">
        <ng-container [ngTemplateOutlet]="headerTemplate"> </ng-container>

        <mat-form-field appearance="fill">
          <mat-label>{{ t('analytics-table.columns') }}</mat-label>
          <mat-select multiple [(value)]="selectedCols">
            <mat-option *ngFor="let col of cols" [value]="col">{{ t(getTranslationKey(col.field)) }}</mat-option>
          </mat-select>
        </mat-form-field>
        <button mat-icon-button aria-label="Download" (click)="dt.exportCSV()">
          <mat-icon>download</mat-icon>
        </button>
      </div>
    </ng-template>

    <!-- Header -->
    <ng-template pTemplate="header" let-columns>
      <tr>
        <th *ngFor="let col of columns" pResizableColumn [pSortableColumn]="col">
          {{ t(getTranslationKey(col.field)) }}
          <p-sortIcon
            ariaLabel="Activate to sort"
            ariaLabelAsc="Activate to sort in ascending order"
            ariaLabelDesc="Activate to sort in descending order"
            [field]="col"
          ></p-sortIcon>
        </th>
      </tr>
    </ng-template>

    <!-- Display Rows -->
    <ng-template pTemplate="body" let-columns="columns" let-expanded="expanded" let-rowData>
      <tr>
        <td *ngFor="let col of columns" class="ui-resizable-column">
          {{ rowData[col.field] }}
        </td>
      </tr>
    </ng-template>

    <!-- Empty Message -->
    <ng-template pTemplate="emptymessage" let-columns>
      <tr>
        <td [attr.colspan]="columns.length">
          {{ t('analytics-table.emptyMessage') }}
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-container>
