<div
  class="px-4 py-8 md:px-6 lg:px-8"
  style="background: radial-gradient(69.84% 69.84% at 50% 100%, rgba(21, 101, 192, 0.15) 0%, rgba(255, 255, 255, 0) 100%)"
>
  <div class="text-blue-600 font-medium mb-3">Chat & Text</div>
  <div class="text-900 text-2xl font-bold mb-3">Add Chat & Text</div>
  <div class="text-700 text-sm">
    Create a support ticket to activate the Chat & Text feature for your hotline. Chat starts at $95/month.
  </div>
  <div class="grid mt-7">
    <div class="col-12 md:col-4">
      <div class="shadow-1 p-5 surface-card text-center">
        <div class="border-circle bg-purple-50 p-4 text-purple-600 inline-flex justify-content-center align-items-center mb-4">
          <i class="pi pi-wifi text-5xl"></i>
        </div>
        <div class="text-900 font-medium text-xl mb-4">Easy To Manage</div>
        <div class="text-700 text-sm mb-4 line-height-3">
          Admin can add or remove the Chat & Text permission from any user in your system.
        </div>
      </div>
    </div>
    <div class="col-12 md:col-4">
      <div class="shadow-1 p-5 surface-card text-center">
        <div class="border-circle bg-blue-50 p-4 text-blue-600 inline-flex justify-content-center align-items-center mb-4">
          <i class="pi pi-heart text-5xl"></i>
        </div>
        <div class="text-900 font-medium text-xl mb-4">Customizable</div>
        <div class="text-700 text-sm mb-4 line-height-3">
          Optionally restrict the times that the Chat & Text are open, and choose customized messages to be sent when it's closed. Enforce
          organization policy by starting off each conversation with a specific message, all from your dashboard.
        </div>
      </div>
    </div>
    <div class="col-12 md:col-4">
      <div class="shadow-1 p-5 surface-card text-center">
        <div class="border-circle bg-orange-50 p-4 text-orange-600 inline-flex justify-content-center align-items-center mb-4">
          <i class="pi pi-lock text-5xl"></i>
        </div>
        <div class="text-900 font-medium text-xl mb-4">Designed For Privacy</div>
        <div class="text-700 text-sm mb-4 line-height-3">
          Delete conversations with the click of a button or automatic deletion after a specified period of time.
        </div>
      </div>
    </div>
  </div>
</div>
