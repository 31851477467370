import {
  DBDocSchema,
  ServiceLimit,
  ServiceLimitEnforcementStrategyName,
  ServiceLimitEnforcementStrategyRRule,
  ServiceLimitSchema,
  ServiceLimitServiceUsage,
  ServiceLimitServiceUsageThreshold,
} from '@pwp-common';

export const defaultServiceLimit = new ServiceLimit({
  id: DBDocSchema.GenericDefaults.id,
  displayName: ServiceLimitSchema.Defaults.displayName,
  description: ServiceLimitSchema.Defaults.description,
  enforcementStrategy: new ServiceLimitEnforcementStrategyRRule({
    enforce: true,
    type: ServiceLimitEnforcementStrategyName.rrule,
    threshold: new ServiceLimitServiceUsageThreshold({
      serviceDeliveredNum: 10000,
    }),
    windowResetRRule: '',
  }),
  usage: ServiceLimitServiceUsage.deserialize({}),
});
