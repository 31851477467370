import { Component } from '@angular/core';
import { uniq } from 'lodash';

import { SupportedLanguages } from '../../../../../../../../common/src';
import { ObjListEditor } from '../../../generic/abstract-classes/obj-list-editor';
import { PhrasesLangPair } from '../generic/phrases-lang-pair';

@Component({
  selector: 'app-phrases-lang-pair-select-and-edit',
  templateUrl: './phrases-lang-pair-select-and-edit.component.html',
  styleUrls: ['./phrases-lang-pair-select-and-edit.component.css'],
})
export class PhrasesLangPairSelectAndEditComponent extends ObjListEditor<PhrasesLangPair> {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Validation
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  objBuilder() {
    return new PhrasesLangPair(SupportedLanguages.en, []);
  }

  isValidList(): boolean {
    // This checks that the form is valid.
    if (!super.isValidList()) {
      return false;
    }

    // Check that each item represents a distinct language
    const numDistinctLangs = uniq(this.objList.map((z) => z.languageDefaults.getShortCode())).length;
    if (numDistinctLangs !== this.objList.length) {
      console.log('PhrasesLangPairSelectAndEditComponent.isValidList: Invalid because duplicate languages', {
        numDistinctLangs,
        numLangs: this.objList.length,
      });
      return false;
    }

    return true;
  }
}
