/**
 * List all possible tasks the system is capable of processing.
 */
export enum ConversationRequestName {
  closeAndDelete = 'closeAndDelete',
  configure = 'configure',
  getJWT = 'getJWT',
  take = 'take',
}

export type ConversationRequestNameType = keyof typeof ConversationRequestName;
