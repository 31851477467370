import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { BlockedCallerRequest, BlockedCallerResponse } from '@pwp-common';

import { CallableFunctionService } from '../../generic/callable-function-service/callable-function-service';

@Injectable({
  providedIn: 'root',
})
export class BlockedCallerService extends CallableFunctionService<BlockedCallerRequest, BlockedCallerResponse> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  // @ts-ignore
  constructor(private _fns: AngularFireFunctions) {
    super(BlockedCallerResponse, _fns);
  }
  ///////////////////////////////////////////////////////////////////////
  // Function Name
  ///////////////////////////////////////////////////////////////////////

  public getFunctionName() {
    return 'blockedCaller';
  }
}
