/**
 * List all possible events we track
 */
export enum CommunicationSessionEventLogConferenceType {
  /**
   * Typescript 5.0 allows computed enum values. Just copy/paste
   * the values here until we upgrade to that version.
   *
   * https://github.com/microsoft/TypeScript/issues/40793#issuecomment-1474413843
   *
   * Note that a separate enum is required, because we may include a
   * ConferenceSummary event in the future, which would be a different from
   * an OperatorConferenceStatusCallbackEvent
   */
  conferenceEnd = 'conferenceEnd',
  conferenceStart = 'conferenceStart',
  participantJoin = 'participantJoin',
}
