import {DBDocSchema} from '../generic/db-doc/db-doc-schema';
import {SchemaField} from '../generic/serialization/schema-field';

export class EmailTemplateConfigSchema extends DBDocSchema {
  // Email address where replies should be directed
  public static readonly replyTo = 'replyTo';

  // Type of template, from enum
  public static readonly templateType = 'templateType';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'emailTemplateConfig';
  };

  static Defaults = class {
    public static readonly replyTo = undefined;
    public static readonly templateType = undefined;
    public static readonly sgTemplateId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[EmailTemplateConfigSchema.replyTo] = SchemaField.string(EmailTemplateConfigSchema.Defaults.replyTo);
    parameters[EmailTemplateConfigSchema.templateType] = SchemaField.string(EmailTemplateConfigSchema.Defaults.templateType);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${EmailTemplateConfigSchema.Constants.collection}`;
  }
}
