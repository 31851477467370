<ng-container *transloco="let t; read: 'service-exception-editor'">
  <form [formGroup]="form">
    <!-- Display Name-->
    <div class="form-row mt-3">
      <div class="col">
        <span class="p-float-label full-width">
          <input pInputText class="full-width" id="allDataServiceExceptionEditorDisplayName" type="text" [formControl]="displayName" />
          <label for="allDataServiceExceptionEditorDisplayName">{{ t('displayNameLabel') }}</label>
        </span>
        <div id="firstName-help">
          <p *ngIf="displayName.errors?.required" class="p-error">{{ t('requiredError') }}</p>
          <p *ngIf="displayName.errors?.minlength" class="p-error">{{ t('minLengthError', displayName.errors?.minlength) }}</p>
          <p *ngIf="displayName.errors?.maxlength" class="p-error">{{ t('maxLengthError', displayName.errors?.maxlength) }}</p>
        </div>
      </div>
    </div>

    <!-- Description-->
    <div class="form-row mt-3">
      <div class="col">
        <span class="p-float-label full-width">
          <textarea
            pInputTextarea
            class="full-width"
            id="allDataServiceExceptionEditorDescription"
            rows="2"
            [formControl]="description"
          ></textarea>
          <label for="allDataServiceExceptionEditorDescription">{{ t('descriptionLabel') }}</label>
        </span>
        <div id="description-help">
          <p *ngIf="description.errors?.required" class="p-error">{{ t('requiredError') }}</p>
          <p *ngIf="description.errors?.minlength" class="p-error">{{ t('minLengthError', description.errors?.minlength) }}</p>
          <p *ngIf="description.errors?.maxlength" class="p-error">{{ t('maxLengthError', description.errors?.maxlength) }}</p>
        </div>
      </div>
    </div>

    <!-- E164Phone Matchers-->
    <div class="form-row mt-3">
      <div class="col">
        <span class="p-float-label full-width">
          <app-array-of-phones-editor
            class="p-fluid"
            [formControl]="e164PhoneMatchers"
            [label]="t('e164MatchersLabel')"
          ></app-array-of-phones-editor>
        </span>
        <div id="e164PhoneMatchers-help">
          <p *ngIf="e164PhoneMatchers.errors?.required" class="p-error">{{ t('requiredError') }}</p>
          <p *ngIf="e164PhoneMatchers.errors?.minlength" class="p-error">{{ t('minLengthError', e164PhoneMatchers.errors?.minlength) }}</p>
          <p *ngIf="e164PhoneMatchers.errors?.maxlength" class="p-error">
            {{ t('maxLengthE164MatchersError', e164PhoneMatchers.errors?.maxlength) }}
          </p>
        </div>
      </div>
    </div>

    <!-- Inbound On Create Action-->
    <div class="form-row mt-3">
      <div class="col">
        <app-service-operation-editor
          class="full-width"
          [formControl]="onCreateServiceCommands"
          [maxCommands]="5"
          [required]="true"
        ></app-service-operation-editor>
      </div>
    </div>
  </form>
</ng-container>
