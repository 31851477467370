import { Directive, EventEmitter, inject, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { Dropdown } from 'primeng/dropdown';
import { MultiSelect } from 'primeng/multiselect';
import { Observable } from 'rxjs';

import { MenuOption } from '../typings/menu-options';

@Directive()
@UntilDestroy()
export abstract class MenuOptionSearchDirective<T> implements OnInit {
  private readonly dropdown = inject(Dropdown, { optional: true });

  private readonly multiSelect = inject(MultiSelect, { optional: true });

  public abstract readonly results$: Observable<MenuOption<T>[]>;

  @Output() public readonly menuOptionSearchCompleted = new EventEmitter<MenuOption<T>[]>();

  private getHost(): Dropdown | MultiSelect {
    const host = this.dropdown ?? this.multiSelect;

    if (isNil(host)) {
      throw new Error('Host element must be a Dropdown or MultiSelect');
    }

    return host;
  }

  public ngOnInit(): void {
    this.results$.pipe(untilDestroyed(this)).subscribe((results) => {
      this.getHost().options = results;
      this.menuOptionSearchCompleted.emit(results);
    });
  }
}
