import { Form } from '@pwp-common';

import { RowCell } from '../../../../../common/p-table/row-cell';

import { FormEditorRow } from './interfaces';

//////////////////////////////////////////////////////////////////////////////////////////
// Form editor row
//////////////////////////////////////////////////////////////////////////////////////////

export const createFormEditorRow = (form: Form): FormEditorRow => ({
  id: form.getId(),
  displayName: createValueCell(form.getDisplayName()),
  description: createValueCell(form.getDescription()),
  form,
});

//////////////////////////////////////////////////////////////////////////////////////////
// Value cell
//////////////////////////////////////////////////////////////////////////////////////////

const createValueCell = (value: string): RowCell<string> =>
  new RowCell<string>({
    translationScope: 'form-table-editor',
    translationKey: 'cellValue',
    translationObj: { value },
    sortValue: value,
  });
