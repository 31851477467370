import { NgModule } from '@angular/core';
import { Serializer, settings } from 'survey-core';
// eslint-disable-next-line no-restricted-imports
import { SurveyCreatorModule } from 'survey-creator-angular';

import { configureSurveyJS } from '@pwp-common';

// Remove the button to upload a logo
Serializer.removeProperty('survey', 'logo');
configureSurveyJS(settings);

@NgModule({
  exports: [SurveyCreatorModule],
})
export class SurveyJSCreatorModule {}
