<ng-container *transloco="let t; read: 'service-command-name-select'">
  <p-dropdown
    [ariaLabel]="t('commandNameLabel')"
    [autoDisplayFirst]="false"
    [formControl]="commandName"
    [options]="commands"
    [placeholder]="t('commandNamePlaceholder')"
  >
    <ng-template pTemplate="item" let-command>
      {{ t(command.label) }}
    </ng-template>
    <ng-template pTemplate="selectedItem" let-command>
      {{ t(command.label) }}
    </ng-template>
  </p-dropdown>
</ng-container>
