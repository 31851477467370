import { DBQuery } from '../../../generic/interfaces';
import { SerializableSchema } from '../common/interfaces';

import { QueryComposer, QueryConfig } from './interfaces';

export const where = <T extends SerializableSchema>(config: QueryConfig<T>): DBQuery[] =>
  Object.entries<QueryComposer | QueryComposer[]>(config).flatMap((entries) => {
    const [key, value] = entries;

    const createItem = (queryComposer: QueryComposer): DBQuery => ({
      fieldPath: key,
      ...queryComposer,
    });

    if (Array.isArray(value)) {
      return value.map(createItem);
    }

    return createItem(value);
  });
