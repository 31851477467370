<ng-container *transloco="let t; read: 'incoming-chat'">
  <ng-container *ngIf="readyForInitialization$ | async as ready; else confirmationDialog">
    <ng-container *transloco="let t; read: 'vue-advanced-chat'">
      <div *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>
      <vue-advanced-chat
        *ngIf="!loading"
        appShadowRootStyles
        [currentUserId]="participantDisplayId"
        [height]="'100%'"
        [messageActions]="[]"
        [messages]="messages$ | async"
        [messagesLoaded]="messagesLoaded"
        [roomId]="client?.getSelectedConversationSid() ?? 'missing'"
        [rooms]="rooms"
        [roomsLoaded]="true"
        [showAddRoom]="false"
        [showAudio]="false"
        [showEmojis]="true"
        [showFiles]="false"
        [showFooter]="showFooter"
        [showReactionEmojis]="false"
        [showSearch]="false"
        [singleRoom]="true"
        [textMessages]="{
          ROOMS_EMPTY: t('ROOMS_EMPTY'),
          ROOM_EMPTY: t('ROOM_EMPTY'),
          NEW_MESSAGES: t('NEW_MESSAGES'),
          MESSAGE_DELETED: t('MESSAGE_DELETED'),
          MESSAGES_EMPTY: t('MESSAGES_EMPTY'),
          CONVERSATION_STARTED: t('CONVERSATION_STARTED'),
          TYPE_MESSAGE: t('TYPE_MESSAGE'),
          SEARCH: t('SEARCH'),
          IS_ONLINE: t('IS_ONLINE'),
          LAST_SEEN: t('LAST_SEEN'),
          IS_TYPING: t('IS_TYPING')
        }"
        (send-message)="sendMessage($event.detail[0]?.content)"
      >
        <button
          pButton
          :slot="room-options"
          class="p-button-raised p-button-help"
          iconPos="left"
          type="button"
          [label]="t('quickExitButtonLabel')"
          (click)="quickExit()"
        >
          <span part="vue-advanced-chat__quick-exit-button-icon" class="p-button-icon p-button-icon-left pi pi-sign-out"></span>
        </button>
      </vue-advanced-chat>
    </ng-container>
  </ng-container>

  <ng-template #confirmationDialog>
    <p-dialog
      class="incoming-chat__dialog"
      [closeAriaLabel]="t('confirmationDialog.closeButtonLabel')"
      [visible]="true"
      (visibleChange)="quickExit()"
    >
      <ng-template pTemplate="header"
        ><span class="p-dialog-title">{{ t('confirmationDialog.title') }}</span>
      </ng-template>
      <div class="incoming-chat__dialog-content">
        <i aria-hidden="true" class="pi pi-exclamation-triangle incoming-chat__dialog-icon"></i>
        {{ t('confirmationDialog.content') }}
      </div>
      <ng-template pTemplate="footer">
        <button
          pButton
          class="p-button-text"
          icon="pi pi-sign-out"
          type="button"
          [label]="t('confirmationDialog.cancelButtonLabel')"
          (click)="quickExit()"
        ></button>
        <button
          pButton
          icon="pi pi-check"
          type="button"
          [label]="t('confirmationDialog.confirmButtonLabel')"
          (click)="confirmInitialization()"
        ></button>
      </ng-template>
    </p-dialog>
  </ng-template>
</ng-container>
