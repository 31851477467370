import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-exceptions',
  templateUrl: './service-exceptions.component.html',
  styleUrls: ['./service-exceptions.component.css'],
})
export class ServiceExceptionsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
