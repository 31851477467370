import { isNil, orderBy } from 'lodash';

import { CallbackRow } from '../../../row/callback-row/callback-row';
import { CallbackRowConstructor } from '../../../row/callback-row/callback-row-constructor';
import { InboxRow } from '../../../row/inbox-row/inbox-row';
import { VoicemailRow } from '../../../row/voicemail-row/voicemail-row';
import { VoicemailRowConstructor } from '../../../row/voicemail-row/voicemail-row-constructor';


export const makeInboxRows = (
  voicemailConstructors: VoicemailRowConstructor[],
  callbackConstructors: CallbackRowConstructor[],
): InboxRow[] => {
  /**
   * Shard by session id
   */
  const voicemailMap = new Map<string, VoicemailRowConstructor>();
  for (const voicemail of voicemailConstructors) {
    voicemailMap.set(voicemail.voicemailMetadata.getSessionId(), voicemail);
  }

  /**
   * Make all rows.
   *
   * If there is a voicemail, make a callback row with the voicemail.
   * else make a voicemail row.
   */
  let rows: InboxRow[] = [];
  for (const callback of callbackConstructors) {
    const sessionId = callback.asyncServiceRequest.getSessionId().getInboundCallSessionId();
    const voicemail = voicemailMap.get(sessionId);
    rows.push(new CallbackRow({ voicemail, ...callback }));
    voicemailMap.delete(sessionId);
    if (!isNil(voicemail)) {
      console.log(voicemail);
    }
  }

  for (const voicemail of voicemailMap.values()) {
    rows.push(new VoicemailRow(voicemail));
  }

  /**
   * Sort
   */
  rows = orderBy(rows, (z) => z.time.sortValue, ['desc']);

  return rows;
};
