import { CommunicationSessionEventLogDialConference, UserData } from '@pwp-common';

import { RowCell } from '../../../../common/p-table/row-cell';
import { CommunicationSessionDialedCallLogBase } from '../base/communication-session-dialed-call-log-base';

export class DialConferenceRow extends CommunicationSessionDialedCallLogBase<CommunicationSessionEventLogDialConference> {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////
  constructor({
    dialedCall,
    allDataUser,
    timezone,
  }: {
    dialedCall: CommunicationSessionEventLogDialConference;
    allDataUser: Map<string, UserData>;
    timezone: string;
  }) {
    super({
      dialedCall,
      allDataUser,
      timezone,
      userId: dialedCall.getRequestedByUserId(),
      userStatus: dialedCall.getUserStatus(),
    });
    this.updateOriginationReason(dialedCall);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Origination Reason
  //////////////////////////////////////////////////////////////////////////////////////////
  private updateOriginationReason(dialedCall: CommunicationSessionEventLogDialConference) {
    const translationKey = 'cellOriginationReasonDialConference';
    this.originationReason = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey,
      translationObj: {
        ...this.userDisplayNameWithEmail.translationObj,
      },
      sortValue: translationKey,
      styleClass: undefined,
    });
  }
}
