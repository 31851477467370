import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../../generic/serialization/schema-field';
import {AnyCommunicationWorkflowTargetTimeout} from '../communication-workflow-target-timeout/generic/any-communication-workflow-target-timeout/any-communication-workflow-target-timeout';

export class CommunicationWorkflowTargetSchema extends SerializableObjectSchema {
  public static readonly queueId = 'queueId';
  public static readonly timeout = 'timeout';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly queueId = undefined;
    public static readonly timeout = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationWorkflowTargetSchema.queueId] = SchemaField.autoGeneratedDocId(CommunicationWorkflowTargetSchema.Defaults.queueId).optional();
    parameters[CommunicationWorkflowTargetSchema.timeout] = SchemaField.obj(AnyCommunicationWorkflowTargetTimeout, CommunicationWorkflowTargetSchema.Defaults.timeout).optional();
    /* eslint-enable */

    return parameters;
  }
}
