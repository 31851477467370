import {cloneDeep} from 'lodash';
import {CommunicationWorkflowDefaultFilterConstructor} from './communication-workflow-default-filter-constructor';
import {CommunicationWorkflowDefaultFilterSchema} from './communication-workflow-default-filter-schema';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';

export class CommunicationWorkflowDefaultFilter extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected queueId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWorkflowDefaultFilterConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationWorkflowDefaultFilter {
    return new CommunicationWorkflowDefaultFilter(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationWorkflowDefaultFilter.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationWorkflowDefaultFilterSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getQueueId(): string {
    return cloneDeep(this.queueId);
  }
}
