import { translate } from '@ngneat/transloco';
import moment from 'moment-timezone';

import { formatDuration } from '@pwp-common';

import { createReportRow } from '../create-report-row/create-report-row';
import { createValuesForEntityStats } from '../create-values-for-entity-stats/create-values-for-entity-stats';
import { AdminReportEventStatsReportRowInput, ReportRow } from '../interfaces';

export const createUserMostAtRiskOfBurnoutRow = ({ entityStats, usersStatsData }: AdminReportEventStatsReportRowInput): ReportRow =>
  createReportRow(
    'userMostAtRiskOfBurnout',
    createValuesForEntityStats(entityStats, (_, period) => {
      const informationOfUsersAtRiskOfBurnout = usersStatsData.reduce((acc, { userStats, userData }) => {
        const callStats = userStats.getChunkByPeriod(period)?.getCalls();
        const periodLength = period === 'total' ? userStats.getByMonth().size : 1;

        const userTalkTimeDuringPeriod = callStats?.getIMsTalkDuration() ?? 0;
        const averageUserTalkTimeDuringPeriod = userTalkTimeDuringPeriod / periodLength;
        const talkTimeThreshold = moment.duration({ hours: 10 }).asMilliseconds();

        const numberOfCallsReceivedByUserDuringPeriod = callStats?.getINum() ?? 0;
        const averageCallsPerMonth = numberOfCallsReceivedByUserDuringPeriod / periodLength;
        const numberOfCallsThreshold = 50;

        if (averageUserTalkTimeDuringPeriod >= talkTimeThreshold && averageCallsPerMonth >= numberOfCallsThreshold) {
          const averageFormattedTalkTime = formatDuration(averageUserTalkTimeDuringPeriod, { aggregateDays: false });
          const userBurnoutInformation = translate('admin-report-row.userMostAtRiskOfBurnout.cellValue', {
            userDisplayNameWithEmail: userData.getDisplayNameWithEmail(),
            averageFormattedTalkTime,
            averageCallsPerMonth: Math.round(averageCallsPerMonth),
          });

          return [...acc, userBurnoutInformation];
        }

        return acc;
      }, []);

      return informationOfUsersAtRiskOfBurnout.length > 0 ? informationOfUsersAtRiskOfBurnout.join(', ') : undefined;
    }),
  );
