import { secondsToMinutes } from 'date-fns';
import moment from 'moment-timezone';

import { createTimingOptionDisplay } from './create-timing-option-display';
import { TimingOptionDisplay } from './interfaces';

export const getTimingOptions = (timeoutSeconds: number): TimingOptionDisplay[] => {
  const timeoutMinutes = secondsToMinutes(timeoutSeconds);
  const maxValue = moment.duration(timeoutMinutes - 1, 'minutes');

  if (maxValue.minutes() < 1) {
    return [];
  }

  const interval = timeoutMinutes >= 60 ? moment.duration(1, 'minutes') : moment.duration(15, 'seconds');
  const numberOfIntervals = maxValue.asSeconds() / interval.asSeconds();

  return Array.from({ length: numberOfIntervals + 1 }, (v, i) =>
    createTimingOptionDisplay(moment.duration({ seconds: interval.asSeconds() * i })),
  );
};
