import { isNil, isNumber } from 'lodash';

import { CommunicationTaskQueueSchema } from '@pwp-common';

import { TargetModeEditorOutput } from '../target-mode-editor-output';
import { TargetModeFormMode, TargetModeFormType } from '../target-mode-form-type';

/**
 * For a given formType return a sensible value of AllDataCommunicationWorkflowEditorTargetMode.
 */
export const formToTargetModeEditorOutput = (formType: TargetModeFormType): TargetModeEditorOutput => {
  if (
    isNil(formType) ||
    formType.mode === TargetModeFormMode.oneAtATime ||
    !isNumber(formType.maxReservedWorkers) ||
    formType.maxReservedWorkers < CommunicationTaskQueueSchema.Constants.maxReservedWorkersMin ||
    formType.maxReservedWorkers > CommunicationTaskQueueSchema.Constants.maxReservedWorkersMax
  ) {
    return {
      maxReservedWorkers: 1,
    };
  }

  return { maxReservedWorkers: formType.maxReservedWorkers };
};
