import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNil, orderBy, uniqBy } from 'lodash';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { catchError, concatMap, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';

import { ConversationConfig, ConversationLog, ConversationResponseGetJwt, ConversationState } from '@pwp-common';

import { ConversationClient } from '../../../common/conversation/conversation-client/conversation-client/conversation-client';
import { ConversationClientDisplay } from '../../../common/conversation/conversation-client-display/conversation-client-display';
import { controlValues } from '../../../common/form/control-values';
import { ConversationConfigService } from '../../../services/conversation/conversation-config/conversation-config.service';
import { ConversationEndpointService } from '../../../services/conversation/conversation-endpoint/conversation-endpoint.service';
import { ConversationLogService } from '../../../services/conversation/conversation-log/conversation-log.service';

import { InProgressSelection } from './enums';

@UntilDestroy()
@Component({
  selector: 'app-conversation-logs-query-editor',
  templateUrl: './conversation-logs-query-editor.component.html',
  styleUrls: ['./conversation-logs-query-editor.component.css'],
  providers: [MessageService],
})
export class ConversationLogsQueryEditorComponent {
  private readonly retryQuery$ = new Subject<void>();
  // /**
  //  * https://ultimatecourses.com/blog/exploring-angular-lifecycle-hooks-ondestroy#making-ngondestroy-async
  //  */
  // @HostListener('window:beforeunload')
  // async ngOnDestroy() {
  //   try {
  //     // Sometimes the client object is not defined.
  //     // Unable to add a test to verify ngOnDestroy calls
  //     // the client object with optional chaining.
  //     await this.client?.shutdown();
  //   } catch (error) {
  //     console.error(`ConversationSelectAndEditComponent.ngOnDestroy: Error shutting down`, error);
  //   }
  // }
  ////////////////////////////////////////////////////////////////////////
  // Testing
  ////////////////////////////////////////////////////////////////////////

  // eslint-disable-next-line
  __testing_client: any;

  ////////////////////////////////////////////////////////////////////////
  // Client State
  ////////////////////////////////////////////////////////////////////////

  response: ConversationResponseGetJwt;

  conversationClientDisplay: ConversationClientDisplay;

  ////////////////////////////////////////////////////////////////////////
  // Form
  ////////////////////////////////////////////////////////////////////////

  public readonly form = new FormGroup({
    showWaiting: new FormControl(true, [Validators.required]),
    inProgress: new FormControl(InProgressSelection.my, [Validators.required]),
    showEnded: new FormControl(false, [Validators.required]),
    range: new FormGroup(
      {
        start: new FormControl(moment().endOf('day').subtract(48, 'hours').toDate(), [Validators.required]),
        end: new FormControl(moment().endOf('day').toDate(), [Validators.required]),
      },
      [Validators.required],
    ),
  });

  ////////////////////////////////////////////////////////////////////////
  // Query State
  ////////////////////////////////////////////////////////////////////////

  public readonly loading$ = new BehaviorSubject(true);

  public readonly conversationLogs$ = merge(controlValues(this.form), this.retryQuery$).pipe(
    filter(() => this.form.valid),
    switchMap(() => this.getConversationLogs()),
  );

  public readonly client$ = this.conversationLogs$.pipe(
    concatMap((conversationLogs) => this.initClient(conversationLogs)),
    map(() => this.conversationClientDisplay),
    distinctUntilChanged(),
  );

  public readonly queryFailed$ = new BehaviorSubject(false);

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////

  constructor(
    private conversationLogService: ConversationLogService,
    private conversationConfigService: ConversationConfigService,
    private conversationEndpointService: ConversationEndpointService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
  ) {}

  private getInProgressConversations(): Observable<ConversationLog[]> {
    const onlyForLoggedInUser = this.form.value.inProgress === InProgressSelection.my;

    return this.conversationLogService.getInProgressConversations({ onlyForLoggedInUser }, false);
  }

  private getWaitingConversations(): Observable<ConversationLog[]> {
    if (this.form.value.showWaiting) {
      return this.conversationLogService.getConversationsWith({ state: ConversationState.serviceRequested }, false);
    }

    return of([]);
  }

  private getEndedConversations(): Observable<ConversationLog[]> {
    if (this.form.value.showEnded) {
      const { start, end } = this.form.value.range;
      const createTimeStart = moment(start).toDate();
      const createTimeEnd = moment(end).toDate();

      return this.conversationLogService.getConversationsWith({ state: ConversationState.closed, createTimeStart, createTimeEnd }, false);
    }

    return of([]);
  }

  private getConversationLogs(): Observable<ConversationLog[]> {
    return combineLatest([this.getInProgressConversations(), this.getWaitingConversations(), this.getEndedConversations()]).pipe(
      map((conversationLogsResults) => conversationLogsResults.flat()),
      map((conversationLogs) => uniqBy(conversationLogs, (conversationLog) => conversationLog.getId())),
      map((conversationLogs) => orderBy(conversationLogs, [(conversationLog) => conversationLog.getCreateTime()], ['desc'])),
      catchError((error) => {
        console.error(error);
        this.onInitFailed();
        this.notifyError('SystemOrAuth', 'queryError');
        return of([]);
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////////
  // Client
  ////////////////////////////////////////////////////////////////////////

  private ensureConversationClientIsInitialized(conversationConfig: ConversationConfig): void {
    if (isNil(this.conversationClientDisplay)) {
      const conversationClient =
        this.__testing_client?.() ??
        new ConversationClient({
          jwt: this.response.getJwt(),
          api: {
            getRefreshedJwt: () => this.conversationEndpointService.getJWT(conversationConfig.getId()).then((_) => _.getJwt()),
          },
        });

      this.conversationClientDisplay = new ConversationClientDisplay({
        client: conversationClient,
        isAdmin: true,
      });
    }
  }

  private async initClient(conversationLogs: ConversationLog[]) {
    console.log('ConversationLogsQueryEditorComponent.initClient: Starting', { conversationLogs });

    if (isNil(this.response)) {
      // Get Conversation Config
      let conversationConfig: ConversationConfig;
      try {
        conversationConfig = await this.conversationConfigService.getUniqueDoc({ query: [] }).toPromise();
        if (isNil(conversationConfig)) {
          this.notifyError('SystemOrAuth', 'conversationConfigMissing');
          this.onInitFailed();
          return;
        }
      } catch (error) {
        this.notifyError('SystemOrAuth', 'errorGettingConversationConfig');
        this.onInitFailed();
        return;
      }

      // Get JWT
      try {
        this.response = await this.conversationEndpointService.getJWT(conversationConfig.getId());
      } catch (error) {
        console.error(error);
        if (isNil(this.response)) {
          this.notifyError('NoResponse', 'getJWT');
        } else {
          this.notifyError('SystemOrAuth', this.response.getError());
        }
        this.onInitFailed();
        return;
      }
      if (isNil(this.response)) {
        this.notifyError('NoResponse', 'getJWT');
        this.onInitFailed();
        return;
      }
      if (!isNil(this.response.getError())) {
        this.notifyError('SystemOrAuth', this.response.getError());
        this.onInitFailed();
        return;
      }

      try {
        this.ensureConversationClientIsInitialized(conversationConfig);
      } catch (error) {
        console.error(error);
        this.notifyError('SystemOrAuth', JSON.stringify(error));
        this.onInitFailed();
        return;
      }
    }

    await this.conversationClientDisplay.setSubscribedConversations(new Set(conversationLogs.map((z) => z.getConversationSid())));
    this.loading$.next(false);
    console.log('ConversationLogsQueryEditorComponent.initClient: Complete');
  }

  ////////////////////////////////////////////////////////////////////////
  // Query
  ////////////////////////////////////////////////////////////////////////

  public retryQuery() {
    this.retryQuery$.next();
  }

  private onInitFailed() {
    this.loading$.next(false);
    this.queryFailed$.next(true);
  }

  ////////////////////////////////////////////////////////////////////////
  // Messages
  ////////////////////////////////////////////////////////////////////////

  private notifyError(key: 'NoResponse' | 'SystemOrAuth', error: string) {
    const title = this.translocoService.translate(`conversation-logs-query-editor.initErrorTitle${key}`);
    const details = this.translocoService.translate(`conversation-logs-query-editor.initErrorDetails${key}`, { error });

    this.messageService.add({
      severity: 'error',
      closable: true,
      sticky: true,
      summary: title,
      detail: details,
    });
  }
}
