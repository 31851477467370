<plotly-plot
  [config]="{
    displaylogo: false,
    modeBarButtonsToRemove: [
      'zoom2d',
      'pan2d',
      'select2d',
      'lasso2d',
      'zoomIn2d',
      'zoomOut2d',
      'autoScale2d',
      'resetScale2d',
      'toggleSpikelines',
      'hoverClosestCartesian',
      'hoverCompareCartesian'
    ]
  }"
  [data]="traces"
  [layout]="{
    autosize: true,
    title: title,
    showlegend: true,
    hoverlabel: {
      namelength: -1
    },
    legend: { orientation: 'h' },
    xaxis: {
      title: 'Talk Hours',
      automargin: true
    },
    yaxis: {
      title: 'User',
      automargin: true,
      autorange: 'reversed'
    }
  }"
>
</plotly-plot>
