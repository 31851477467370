<app-obj-select-and-edit
  [doDelete]="doDelete"
  [editorTemplate]="optionEditor"
  [label]="'IVROption'"
  [modifiedSelectedItem]="selectedKVPair?.value"
  [(orderedItems)]="objList"
  [(selectedKVPair)]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
>
  <ng-template #optionEditor let-index="id" let-item="item" let-kvPair>
    <p>Editing Option at Index: {{ this.selectedKVPair?.id }}</p>

    <app-ivr-option-editor
      *ngIf="selectedKVPair?.value !== undefined"
      [(obj)]="selectedKVPair"
      (objChange)="updateAndEmit()"
    ></app-ivr-option-editor>
  </ng-template>
</app-obj-select-and-edit>
