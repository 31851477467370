import { AbstractControl, ValidatorFn } from '@angular/forms';

export class NotInListValidator {
  public static notInListValidator(invalidOptions: string[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = !invalidOptions.includes(control.value);

      return !valid ? { notInList: { value: control.value } } : null;
    };
  }
}
