import {cloneDeep} from 'lodash';
import {SubmitTicketRequestSchema} from './submit-ticket-request-schema';
import {RequestObj} from '../../generic/request/request-obj';
import {SubmitTicketRequestConstructor} from './submit-ticket-request-constructor';
import {SubmitTicketPriority} from './enums';

export class SubmitTicketRequest extends RequestObj {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: SubmitTicketRequestConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected subject!: string;
  protected message!: string;
  protected priority!: SubmitTicketPriority;

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): SubmitTicketRequest {
    return new SubmitTicketRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    const serialized = super.serialize(SubmitTicketRequest.getSchema(), 'api');
    return serialized;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SubmitTicketRequestSchema {
    return new SubmitTicketRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getSubject(): string {
    return cloneDeep(this.subject);
  }

  public getMessage(): string {
    return cloneDeep(this.message);
  }

  public getPriority(): SubmitTicketPriority {
    return cloneDeep(this.priority);
  }
}
