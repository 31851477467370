import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { CallerId, CallerIdSchema } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../common/objects/form-helper';
import { e164PhoneValidators } from '../../../common/validators/misc-validators';
import { CallerIdService } from '../../../services/call/caller-id/caller-id.service';
import { ConfigDocSelectAndEdit } from '../../generic/abstract-classes/config-doc-select-and-edit';

@Component({
  selector: 'app-caller-id-select-and-edit',
  templateUrl: './caller-id-select-and-edit.component.html',
  styleUrls: ['./caller-id-select-and-edit.component.css'],
})
export class CallerIdSelectAndEditComponent extends ConfigDocSelectAndEdit<CallerId> implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _dataService: CallerIdService,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(CallerId, _dataService, _changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair?.value?.getId(),
      [CallerIdSchema.e164Phone, CallerIdSchema.displayName, CallerIdSchema.description],
      CallerIdSchema.Defaults,
      this,
    );

    return new CallerId(parameters);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: CallerId) {
    // Init Form
    const formConfig = {} as any;
    formConfig[CallerIdSchema.e164Phone] = [obj.getE164Phone(), e164PhoneValidators];
    formConfig[CallerIdSchema.displayName] = [obj.getDisplayName(), [Validators.required, Validators.maxLength(500)]];
    formConfig[CallerIdSchema.description] = [obj.getDescription(), [Validators.required, Validators.maxLength(500)]];
    this.form = this.formBuilder.group(formConfig);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get e164Phone(): AbstractControl | null {
    return this.form.get(CallerIdSchema.e164Phone);
  }

  get displayName(): AbstractControl | null {
    return this.form.get(CallerIdSchema.displayName);
  }

  get description(): AbstractControl | null {
    return this.form.get(CallerIdSchema.description);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    return true;
  }
}
