import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { NgChanges } from '../../../../common/objects/ng-changes';
import { StepChangeEvent } from '../common/step-change-event';

@Component({
  selector: 'app-wizard-step',
  templateUrl: './wizard-step.component.html',
  styleUrls: ['./wizard-step.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardStepComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Input / Output
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  @Input() label: string;

  @Input() hasError = false;

  @Output() stepInputsChange = new EventEmitter<StepChangeEvent>();

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  active = false;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: NgChanges<WizardStepComponent>) {
    /**
     * Propagate changes
     */
    this.stepInputsChange.emit({
      changes,
    });
  }
}
