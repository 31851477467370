import {isNil, uniq} from 'lodash';

import {formatDuration} from '../../../helper/time/format-duration/format-duration';
import {displayTime} from '../../../helper/time/display-time/display-time';
import {ServiceLimitExecution} from '../../communication/service-limit/service-limit-executions/service-limit-execution/service-limit-execution';
import {CommunicationDisplayAlertType} from '../../core/communication/communication-display-alert-type';
import {DisplayableCommunication} from '../../core/communication/displayable-communication';
import {CommunicationType} from '../../core/communication/types';
import {getDisplayName} from '../../user/user-data/user-data-display';
import {SupportedLanguages} from '../../voice-response-command/vrc-audio-metadata/supported-languages';
import {CallList} from '../call-list/call-list/call-list';
import {IVR} from '../ivr/ivr/ivr';

import {CallLog} from './call-log';
import {CallLogDisplayConstructor} from './call-log-display-constructor';
import {CallState} from './call-state';
import {DisconnectReason} from './disconnect-reason';
import {StoppedDialingReason} from './stopped-dialing-reason';

/**
 * This class is used by call-table.component.ts. It transforms CallData(), which simply
 * holds all the properties of a call, to a format suitable for displaying in a table.
 */
export class CallLogDisplay implements DisplayableCommunication {
  ////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////

  public callList: CallList | undefined;
  public callLog: CallLog;

  ////////////////////////////////////////////////////
  // Displayable Communication Interface
  ////////////////////////////////////////////////////

  communicationType = CommunicationType.incomingPhoneCall;
  communicationTypeDetail: string;

  id: string;
  day: string;
  receiveTime: string;

  holdDuration: string;
  talkDuration: string;

  answeredBy: string;
  end: string;
  alertType: CommunicationDisplayAlertType;
  timestamp: Date;

  ////////////////////////////////////////////////////
  // Specialized Vars
  ////////////////////////////////////////////////////

  receivedAtE164Phone: string;
  disconnectReason: string;
  stoppedDialingReason: string;
  disconnectedBy: string;
  numCounselorsOnCall: string;
  hasAnonymousCaller: string;
  language: string;
  incomingCallSID: string;
  hasCallbackRequest: string;
  forwardedTo?: string;

  /**
   * Initialize all properties based on the Call Data and UserDataMap
   * @param callLog Data associated to the call
   * @param allUserDataMap Map specifying UserData for each userId string.
   */
  constructor({allUserDataMap, asyncServiceRequest, callListMap, callLog, ivrsMap, timezone}: CallLogDisplayConstructor) {
    this.callLog = callLog;
    this.callList = callListMap.get(this.callLog.getCallListId());

    // Set all properties
    this.receivedAtE164Phone = this.callLog.getReceivedAtE164Phone();
    this.communicationTypeDetail = this.getPrettyEventType(callListMap, ivrsMap);
    this.day = this.callLog.getIncomingCallReceivedTime().tz(timezone).format('l');
    this.receiveTime = displayTime(this.callLog.getIncomingCallReceivedTime(), timezone, {timeOnly: true});
    this.holdDuration = formatDuration(this.callLog.getHoldDurationMS());
    this.end = displayTime(this.callLog.getServiceDeliveryEndTime(), timezone, {timeOnly: true});
    this.talkDuration = this.getTalkDurationString();
    this.answeredBy = getDisplayName(allUserDataMap, this.callLog.getAnsweredBy() ?? asyncServiceRequest?.getAssignedTo());
    this.id = this.callLog.getId();
    this.disconnectReason = this.getDisconnectReasonString();
    this.stoppedDialingReason = this.getStoppedDialingReasonString();
    this.disconnectedBy = this.disconnectedByString();
    this.numCounselorsOnCall = this.callLog.getState() !== CallState.inProgressServiceNotSelected ? this.getNumCounselors() : '';
    this.hasAnonymousCaller = this.callLog.getHasAnonymousCaller()?.toString() || '';
    this.language = callLog.getLanguage() === undefined ? '' : SupportedLanguages.getDefaults(callLog.getLanguage()!).getDisplayName();
    this.incomingCallSID = callLog.getIncomingCallSid();
    this.alertType = this.getCSSClass();
    this.timestamp = callLog.getIncomingCallReceivedTime().toDate();
    this.hasCallbackRequest = this.getHasCallbackRequestString();
    this.forwardedTo = callLog.getForwardedTo();
  }

  private getHasCallbackRequestString(): string {
    const hasAsyncServiceRequestId = !isNil(this.callLog.getAsyncServiceRequestId());

    return hasAsyncServiceRequestId.toString();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Pretty Event Type
  /////////////////////////////////////////////////////////////////////////////

  public getPrettyEventType = (callListMap: Map<string, CallList>, ivrsMap: Map<string, IVR>): string => {
    const callList = callListMap.get(this.callLog.getCallListId());

    if (callList !== undefined) {
      return callList.getDisplayName();
    }
    if (this.callLog.getCallListId() !== undefined) {
      return this.callLog.getCallListId();
    }

    if (!isNil(this.callLog.getForwardedTo())) {
      return `Forwarded To / ${this.callLog.getForwardedTo()}`;
    }

    if (this.callLog.getOperatorDurationMS() === 0) {
      return 'No Option Selected / Caller Disconnected Before Hearing Greeting';
    }

    for (const [_, value] of this.callLog.getServiceLimitExecutions()?.getExecutions() ?? new Map<string, ServiceLimitExecution>()) {
      if (value?.getActionExecuted() === true) {
        return 'Enforced Service Limit';
      }
    }

    // Combine options selected into an 'event type'
    const responses = this.callLog.getResponses();
    const lastResponse = responses[responses.length - 1];
    const lastIVR = ivrsMap.get(lastResponse?.getIvrId());
    const optionPresses = [];
    for (const response of this.callLog.getResponses()) {
      if (response.getSpeech() !== undefined) {
        optionPresses.push('<speech>');
      }
      if (response.getDigits() !== undefined) {
        optionPresses.push(response.getDigits());
      }
    }
    if (optionPresses.length !== 0) {
      const head = lastIVR?.getDisplayName() === undefined ? '' : `${lastIVR?.getDisplayName()} /`;
      let tail = 'Did Not Complete Selection';
      if (!isNil(this.callLog.getVoicemailMetadataId())) {
        tail = 'Left Voicemail';
      }
      if (!isNil(this.callLog.getAsyncServiceRequestId())) {
        tail = 'Requested Callback';
      }
      return `${head} Selected: ${optionPresses.join(',')} / ${tail}`.trim();
    }

    if (this.callLog.getOperatorDurationMS() !== undefined) {
      let listenDuration = (this.callLog.getOperatorDurationMS()! - this.callLog.getRingDurationMS()) / 1000.0;
      if (listenDuration < 0) {
        listenDuration = 0;
      }

      return `No Option Selected / Listened For ${listenDuration}s`;
    }

    return 'No Option Selected';
  };

  /////////////////////////////////////////////////////////////////////////////
  // Pretty Print: Talk / Hold duration
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return 'in progress' if the call is in progress. Else, return a string giving
   * the duration of the call in hours / minutes / seconds.
   */
  public getTalkDurationString = (): string => {
    if (this.callLog.getState() === CallState.inProgressServiceNotSelected) {
      return 'Waiting Client Selection';
    }

    if (this.callLog.getState() !== CallState.completed) {
      return 'In Progress';
    }

    return formatDuration(this.callLog.getTalkDurationMS());
  };

  /////////////////////////////////////////////////////////////////////////////
  // Disconnect Reason
  /////////////////////////////////////////////////////////////////////////////

  public getDisconnectReasonString = (): string => {
    if (this.callLog.wasClientConnectedToUser()) {
      return '';
    }

    if (!this.callLog.hadCapacity()) {
      return 'No Capacity';
    }

    if (this.callLog.didClientDisconnectBeforeServiceDeliveryAttemptComplete()) {
      return 'Client Disconnected Early';
    }

    if (this.callLog.wasBlocked()) {
      return 'Blocked';
    }

    if (this.callLog.getVoicemailMetadataId() !== undefined) {
      return 'Voicemail';
    }

    if (this.callLog.wasUnexpectedlyMissed()) {
      return 'No Answer';
    }

    return '';
  };

  public disconnectedByString = (): string => {
    if (this.callLog.isInProgress()) {
      return '';
    }

    return this.callLog.getDisconnectedBy() || '';
  };

  /////////////////////////////////////////////////////////////////////////////
  // Should flag call for counselor
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return true if we should flag this call for counselor attention. Else false.
   * In particular, this returns true if there is a critical system error.
   */
  public shouldFlagCallForCounselorAttention = (): boolean => {
    if (this.hasServerError()) {
      return true;
    }

    // Don't flag a call if no option was selected. Such calls always have 0 counselors listed.
    if (!this.callLog.callListSelected()) {
      return false;
    }

    /**
     * Flag if call ws disconnected and it's possible we have an organization issue. Eg,
     * too few counselors on staff, or counselors didn't answer.
     */
    if (!this.callLog.wasAnswered()) {
      /**
       * Step 1: In this case, the call may have been disconnected due to some
       * avoidable reason.
       */
      const avoidableDisconnectReasons = [DisconnectReason.error, DisconnectReason.noUsersLeftToDial];

      if (avoidableDisconnectReasons.includes(this.callLog.getDisconnectReason())) {
        return true;
      }

      /**
       * Step 2: Flag if we stopped dialing for an avoidable reason.
       */
      const avoidableStoppedDialingReasons = [StoppedDialingReason.noUsersLeftToDial];

      if (avoidableStoppedDialingReasons.includes(this.callLog.getStoppedDialingReason())) {
        return true;
      }
    }

    /**
     * If there were no users on call then we should always flag, even if
     * client disconnected before we could start dialing.
     */
    if (this.callLog.getUserIdsToCall().length === 0) {
      return true;
    }

    return false;
  };

  /////////////////////////////////////////////////////////////////////////////
  // Should flag call for server error
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return true if this call resulted in a server error.
   */
  public hasServerError = (): boolean => {
    if (this.callLog.getDisconnectReason() === DisconnectReason.error) {
      return true;
    }

    if (this.callLog.getHasCriticalServerError()) {
      return true;
    }

    return false;
  };

  /////////////////////////////////////////////////////////////////////////////
  // Stopped Dialing Reason
  /////////////////////////////////////////////////////////////////////////////

  public getStoppedDialingReasonString = (): string => {
    if (this.callLog.wasAnswered()) {
      return '';
    }

    const reason = this.callLog.getStoppedDialingReason();
    switch (reason) {
      case StoppedDialingReason.noUsersLeftToDial: {
        return 'No users left to dial';
      }
      case StoppedDialingReason.incomingCallDisconnected: {
        return 'Client Disconnected';
      }
      case StoppedDialingReason.wasAnswered: {
        return 'Was Answered';
      }
      case StoppedDialingReason.noCapacity: {
        return 'No Capacity';
      }
      case undefined: {
        return '';
      }
      default: {
        console.error(`getStoppedDialingReasonString: Unknown reason='${reason}'`);
        return '';
      }
    }
  };

  /////////////////////////////////////////////////////////////////////////////
  // Number of Counselors
  /////////////////////////////////////////////////////////////////////////////

  public getNumCounselors = (): string => {
    return uniq(this.callLog.getUserIdsToCall()).length.toString();
  };

  /////////////////////////////////////////////////////////////////////////////
  // CSS
  /////////////////////////////////////////////////////////////////////////////

  public getCSSClass = (): CommunicationDisplayAlertType => {
    if (this.callLog.didSendDigits()) {
      return CommunicationDisplayAlertType.sentDigits;
    }

    if (this.hasServerError()) {
      return CommunicationDisplayAlertType.flagServer;
    }

    if (this.callLog.getBlockedCallerId() !== undefined) {
      return CommunicationDisplayAlertType.blocked;
    }

    if (this.callLog.getState() === CallState.inProgressServiceNotSelected) {
      return CommunicationDisplayAlertType.null;
    }

    if (this.callLog.isInProgress()) {
      return CommunicationDisplayAlertType.inProgress;
    }

    if (this.shouldFlagCallForCounselorAttention()) {
      return CommunicationDisplayAlertType.unanswered;
    }

    // No special CSS
    return CommunicationDisplayAlertType.null;
  };
}
