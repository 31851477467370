import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {SchemaField} from '../../generic/serialization/schema-field';

export class VoicemailConfigSchema extends DBDocSchema {
  // Display Name
  public static readonly displayName = 'displayName';
  public static readonly description = 'description';

  // Notify the users specified in this call list that there is a new voicemail
  public static readonly notifyViaSMSOfVoicemailCallListId = 'notifyViaSMSOfVoicemailCallListId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'voicemailConfig';
  };

  static Defaults = class {
    public static readonly displayName = 'missing-displayName';
    public static readonly description = 'missing-description';
    public static readonly notifyViaSMSOfVoicemailCallListId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[VoicemailConfigSchema.displayName] = SchemaField.string(VoicemailConfigSchema.Defaults.displayName);
    parameters[VoicemailConfigSchema.description] = SchemaField.string(VoicemailConfigSchema.Defaults.description);
    parameters[VoicemailConfigSchema.notifyViaSMSOfVoicemailCallListId] = SchemaField.string(VoicemailConfigSchema.Defaults.notifyViaSMSOfVoicemailCallListId);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${VoicemailConfigSchema.Constants.collection}`;
  }
}
