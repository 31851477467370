import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';

export class ShakenHeaderSchema extends SerializableObjectSchema {
  /**
   * ES256 must be supported, but this field can other values as well
   * https://datatracker.ietf.org/doc/html/rfc8225#section-4.2
   */
  public static readonly alg = 'alg';

  /**
   * See https://datatracker.ietf.org/doc/html/rfc8225#section-8.1
   */
  public static readonly ppt = 'ppt';

  /**
   * See https://datatracker.ietf.org/doc/html/rfc8225#section-4.1
   */
  public static readonly typ = 'typ';
  public static readonly x5u = 'x5u';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly alg = undefined;
    public static readonly ppt = undefined;
    public static readonly typ = undefined;
    public static readonly x5u = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ShakenHeaderSchema.alg] = SchemaField.string(undefined).allow('ES256').required();
    parameters[ShakenHeaderSchema.ppt] = SchemaField.string(undefined).required();
    parameters[ShakenHeaderSchema.typ] = SchemaField.string(undefined).required();
    parameters[ShakenHeaderSchema.x5u] = SchemaField.uri(undefined).required();

    return parameters;
  }
}
