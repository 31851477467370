import {cloneDeep} from 'lodash';
import {MessageTemplateContextType} from './message-template-context-type';
import {MessageTemplateContextObjKeys} from './message-template-context-obj';

const coreVars = new Set<string>([
  MessageTemplateContextObjKeys.firstName,
  MessageTemplateContextObjKeys.lastInitial,
  MessageTemplateContextObjKeys.displayName,
  MessageTemplateContextObjKeys.replyTo,
  MessageTemplateContextObjKeys.templateDisplayName,
  MessageTemplateContextObjKeys.bcc,
  MessageTemplateContextObjKeys.cc,
]);

export const getValidKeyForContextType = (context: MessageTemplateContextType): Set<string> => {
  const varsInContext = cloneDeep(coreVars);
  switch (context) {
    case MessageTemplateContextType.shiftsToday: {
      varsInContext.add(MessageTemplateContextObjKeys.shiftsToday);
      break;
    }
    case MessageTemplateContextType.shiftsTomorrow: {
      varsInContext.add(MessageTemplateContextObjKeys.shiftsTomorrow);
      break;
    }
    case MessageTemplateContextType.conversationServiceRequest: {
      varsInContext.add(MessageTemplateContextObjKeys.conversationServiceRequest);
      break;
    }
  }

  return varsInContext;
};
