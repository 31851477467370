import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputTextModule } from 'primeng/inputtext';
import { SelectButtonModule } from 'primeng/selectbutton';

import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';


import { RruleEditorComponent } from './rrule-editor.component';

@NgModule({
  declarations: [RruleEditorComponent],
  imports: [
    CommonModule,
    DropdownModule,
    InputNumberModule,
    InputTextModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    SelectButtonModule,
    TranslocoModule,
  ],
  exports: [RruleEditorComponent],
})
export class RruleEditorModule {}
