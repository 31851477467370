import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';
import {values} from 'lodash';
import {ReservationOfferChannel} from '../reservation-offer-channel';

export class ReservationOfferSchema extends SerializableObjectSchema {
  public static readonly offerChannels = 'offerChannels';
  public static readonly delay = 'delay';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static Defaults = class {
    public static readonly offerChannels = undefined;
    public static readonly delay = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ReservationOfferSchema.offerChannels] = SchemaField.shortStringArray(ReservationOfferSchema.Defaults.offerChannels)
      .required()
      .items(...values(ReservationOfferChannel));
    parameters[ReservationOfferSchema.delay] = SchemaField.isoDuration(ReservationOfferSchema.delay, ReservationOfferSchema.Defaults.delay).required()
    /* eslint-enable */

    return parameters;
  }
}
