import { translate } from '@ngneat/transloco';

import { EntityStats } from '@pwp-common';

import { createReportRow } from '../create-report-row/create-report-row';
import { createValuesForEntityStats } from '../create-values-for-entity-stats/create-values-for-entity-stats';
import { formatUsersWithCount } from '../formatting/format-users-with-count';
import { getUsersWithHighestCount } from '../get-users-with-highest-count/get-users-with-highest-count';
import { AdminReportUserCallStatsRowInput, ReportRow } from '../interfaces';

export const createUserWhoAnsweredMostCallsRow = ({ entityStats, usersStatsData, callList }: AdminReportUserCallStatsRowInput): ReportRow =>
  createReportRow(
    'userWhoAnsweredMostCalls',
    createValuesForEntityStats(entityStats, (_, period) => {
      const getUserCallsCount = (userStats: EntityStats) => userStats.getChunkByPeriod(period)?.getCalls(callList)?.getINum() ?? 0;

      const usersWithMostCalls = getUsersWithHighestCount(usersStatsData, getUserCallsCount);
      const suffix = translate('admin-report-row.userWhoAnsweredMostCalls.cellSuffix');

      return formatUsersWithCount(usersWithMostCalls, suffix);
    }),
    { callList },
  );
