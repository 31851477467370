<ng-container *transloco="let t; read: 'event-requests-query-editor'">
  <p-toast position="bottom-center"></p-toast>

  <p-card>
    <ng-template pTemplate="header">
      <legend>{{ t('title') }}</legend>
    </ng-template>
    <form [formGroup]="form">
      <div class="form-row">
        <div class="col">
          <p-selectButton
            ariaLabel="{{ t('eventRequestTypeLabel') }}"
            formControlName="eventRequestType"
            [options]="[
              { label: t('eventRequestOpenLabel'), value: 'open' },
              { label: t('eventRequestDecidedLabel'), value: 'decided' }
            ]"
          ></p-selectButton>
        </div>
      </div>
      <div class="form-row">
        <div *ngIf="eventRequestType?.value === 'decided'" class="col">
          <mat-form-field appearance="fill">
            <mat-label>{{ t('dateRangeLabel') }}</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
              <input matStartDate placeholder="{{ t('start') }}" formControlName="start" />
              <input matEndDate placeholder="{{ t('end') }}" formControlName="end" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="start?.hasError('matStartDateInvalid')">{{ t('invalidStartDate') }}</mat-error>
            <mat-error *ngIf="end?.hasError('matEndDateInvalid')">{{ t('invalidEndDate') }}</mat-error>
          </mat-form-field>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <button
        pButton
        class="p-button-text"
        label="{{ t('queryButton') }}"
        [disabled]="loading || !queryIsValid()"
        [loading]="loading"
        (click)="refreshData()"
      ></button>
    </ng-template>
  </p-card>
  <mat-spinner *ngIf="loading"></mat-spinner>
  <app-event-requests-table-all
    *ngIf="!loading"
    [allDataEvent]="allDataEvent"
    [orgData]="orgDataObservable | async"
    (uploadComplete)="refreshData()"
  ></app-event-requests-table-all>
</ng-container>
