import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

import { usLocalPhone } from '@pwp-common';

export class USLocalPhoneValidator {
  public static usLocalPhoneValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const valid = !isNil(usLocalPhone(control.value || ''));

      return !valid ? { usLocalPhone: { value: control.value } } : null;
    };
  }
}
