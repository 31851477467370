import {CommunicationSessionEventLogSendReservationOfferConstructor} from './communication-session-event-log-send-reservation-offer-constructor';
import {CommunicationSessionEventLogSendReservationOfferSchema} from './communication-session-event-log-send-reservation-offer-schema';
import {CommunicationSessionEventLogSendMessage} from '../../generic/communication-session-event-log-send-message/communication-session-event-log-send-message';
import {CommunicationSessionEventLogSendMessageType} from '../../generic/communication-session-event-log-send-message-type';
import {CommunicationSessionEventLogSendMessageSchema} from '../../generic/communication-session-event-log-send-message/communication-session-event-log-send-message-schema';
import {Mixin} from 'ts-mixer';
import {CommunicationSessionEventLogReservationOffer} from '../../../../generic/communication-session-event-log-reservation-offer/communication-session-event-log-reservation-offer';

export class CommunicationSessionEventLogSendReservationOffer extends Mixin(CommunicationSessionEventLogSendMessage, CommunicationSessionEventLogReservationOffer) {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionEventLogSendReservationOfferConstructor) {
    (parameters as any)[CommunicationSessionEventLogSendMessageSchema.messageType] = CommunicationSessionEventLogSendMessageType.reservationOffer;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationSessionEventLogSendReservationOffer {
    return new CommunicationSessionEventLogSendReservationOffer(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationSessionEventLogSendReservationOffer.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): CommunicationSessionEventLogSendReservationOfferSchema {
    return new CommunicationSessionEventLogSendReservationOfferSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
