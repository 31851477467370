import {AnyCommunicationWorkflowTargetTimeoutSchema} from './any-communication-workflow-target-timeout-schema';
import {CommunicationWorkflowTargetTimeoutName} from '../communication-workflow-target-timeout-name';
import {SerializableObject} from '../../../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../../../generic/serialization/serializable-object-schema';
import {CommunicationWorkflowTargetTimeout} from '../communication-workflow-target-timeout/communication-workflow-target-timeout';
import {CommunicationWorkflowTargetTimeoutFixed} from '../../communication-workflow-target-timeouts/communication-workflow-target-timeout-fixed/communication-workflow-target-timeout-fixed';

export abstract class AnyCommunicationWorkflowTargetTimeout extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationWorkflowTargetTimeout {
    const typeString = validationResult.value[AnyCommunicationWorkflowTargetTimeoutSchema.type];
    const type = CommunicationWorkflowTargetTimeoutName[typeString as keyof typeof CommunicationWorkflowTargetTimeoutName];
    if (type === undefined) {
      throw new Error(`AnyCommunicationWorkflowTargetTimeoutUser._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (type) {
      case CommunicationWorkflowTargetTimeoutName.fixed: {
        return new CommunicationWorkflowTargetTimeoutFixed(validationResult.value);
      }
      default: {
        throw new Error(`AnyCommunicationWorkflowTargetTimeout._deserialize Unknown type: ${type}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyCommunicationWorkflowTargetTimeoutSchema();
  }
}
