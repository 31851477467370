import {cloneDeep, isEmpty} from 'lodash';
import {CommunicationSessionIdConstructor} from './communication-session-id-constructor';
import {CommunicationSessionIdSchema} from './communication-session-id-schema';
import {SerializableObject} from '../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {CommunicationSessionType} from '../../call/communication-session-type';

export class CommunicationSessionId extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected inboundCallSessionId?: string;

  protected conversationLogId?: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionIdConstructor) {
    super(parameters);
    this.sanityCheck();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationSessionId {
    return new CommunicationSessionId(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationSessionId.getSchema());
  }

  public sanityCheck(): void {
    if (!isEmpty(this.inboundCallSessionId) && !isEmpty(this.conversationLogId)) {
      throw new Error('CommunicationSessionId.sanityCheck: Cannot have both inboundCallSessionId and conversationLogId');
    }

    if (isEmpty(this.inboundCallSessionId) && isEmpty(this.conversationLogId)) {
      throw new Error('CommunicationSessionId.sanityCheck: Must have either inboundCallSessionId or conversationLogId');
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationSessionIdSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
  public getInboundCallSessionId() {
    return cloneDeep(this.inboundCallSessionId);
  }

  public getConversationLogId() {
    return cloneDeep(this.conversationLogId);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Fancier getters
  /////////////////////////////////////////////////////////////////////////////

  public getCommunicationSessionType(): CommunicationSessionType {
    if (!isEmpty(this.inboundCallSessionId)) {
      return CommunicationSessionType.inboundCall;
    }

    if (!isEmpty(this.conversationLogId)) {
      return CommunicationSessionType.inboundConversation;
    }

    throw new Error('CommunicationSessionId.getCommunicationSessionType: Unknown session type');
  }
}
