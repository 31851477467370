<div *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!loading && willSometimesExclude">
  <ng-container *transloco="let t; read: 'conversation-message-template-editor-service-closed'">
    <app-distinguished-conversation-message-template-editor
      [cardTitle]="t('title')"
      [tempateType]="serviceClosed"
      [textBoxLabel]="t('label')"
      [textBoxPlaceholder]="t('placeholder')"
    ></app-distinguished-conversation-message-template-editor>
  </ng-container>
</div>
