import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {values} from 'lodash';
import {CommunicationsResponseCreateAsyncServiceRequestError} from './communications-response-create-async-service-request-error';

export class CommunicationsResponseCreateAsyncServiceRequestSchema extends CommunicationsResponseSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly error = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsResponseCreateAsyncServiceRequestSchema.error] = SchemaField.string(CommunicationsResponseSchema.Defaults.error).optional().valid(...values(CommunicationsResponseCreateAsyncServiceRequestError));
   /* eslint-enable */

    return parameters;
  }
}
