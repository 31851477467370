// Any DB Matcher
export {AnyDBMatchRule} from './generic/any-db-match-rule/any-db-match-rule';
export {AnyDBMatchRuleSchema} from './generic/any-db-match-rule/any-db-match-rule-schema';

// DB Match Rule / Generic
export {DBMatchRule} from './generic/db-match-rule/db-match-rule';
export {DBMatchRuleSchema} from './generic/db-match-rule/db-match-rule-schema';
export {DBMatchRuleConstructor} from './generic/db-match-rule/db-match-rule-constructor';
export {DBMatchRuleName, DBMatchRuleNameType} from './generic/db-match-rule-name';

// DB Match Rule E164Phone
export {DBMatchRuleE164Phone} from './db-match-rules/db-match-rule-e164-phone/db-match-rule-e164-phone';
export {DBMatchRuleE164PhoneSchema} from './db-match-rules/db-match-rule-e164-phone/db-match-rule-e164-phone-schema';
export {DBMatchRuleE164PhoneConstructor} from './db-match-rules/db-match-rule-e164-phone/db-match-rule-e164-phone-constructor';

// DB Match Rule docPath
export {DBMatchRuleDocPath} from './db-match-rules/db-match-rule-doc-path/db-match-rule-doc-path';
export {DBMatchRuleDocPathSchema} from './db-match-rules/db-match-rule-doc-path/db-match-rule-doc-path-schema';
export {DBMatchRuleDocPathConstructor} from './db-match-rules/db-match-rule-doc-path/db-match-rule-doc-path-constructor';

////////////////////////////////////////////////////////////////////////////////////////////////
// Warning On Import
////////////////////////////////////////////////////////////////////////////////////////////////

console.warn('--------- pwp-common:objects/core/db-matcher/db-match-rule/index.ts: Imported ---------');
