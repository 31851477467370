import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {values} from 'lodash';
import {CommunicationsResponseGetCallLogPIIError} from './communications-response-get-call-log-pii-error';
import {ShakenPassport} from '../../../../../objects/call/stir-shaken/shaken-passport/shaken-passport';
import {TwilioStirVerstat} from '../../../../../objects/call/stir-shaken/twilio-stir-verstat';
import {ParentCallInfoToken} from '../../../../../objects/call/stir-shaken/parent-call-info-token/parent-call-info-token';

export class CommunicationsResponseGetCallLogPIISchema extends CommunicationsResponseSchema {
  public static readonly e164Phone = 'e164Phone';
  public static readonly carrierName = 'carrierName';
  public static readonly carrierType = 'carrierType';
  public static readonly callerName = 'callerName';
  public static readonly callerType = 'callerType';
  public static readonly iso3166Alpha2CountryCode = 'iso3166Alpha2CountryCode';
  public static readonly parentCallInfoToken = 'parentCallInfoToken';
  public static readonly shakenPassport = 'shakenPassport';
  public static readonly twilioStirVerstat = 'twilioStirVerstat';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly error = undefined;

    public static readonly e164Phone = undefined;
    public static readonly carrierName = undefined;
    public static readonly carrierType = undefined;
    public static readonly callerName = undefined;
    public static readonly callerType = undefined;
    public static readonly iso3166Alpha2CountryCode = undefined;
    public static readonly parentCallInfoToken = undefined;
    public static readonly shakenPassport = undefined;
    public static readonly twilioStirVerstat = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsResponseGetCallLogPIISchema.error] = SchemaField.string(CommunicationsResponseSchema.Defaults.error).optional().valid(...values(CommunicationsResponseGetCallLogPIIError));
    parameters[CommunicationsResponseGetCallLogPIISchema.e164Phone] =  SchemaField.e164Phone(CommunicationsResponseGetCallLogPIISchema.Defaults.e164Phone).optional();
    parameters[CommunicationsResponseGetCallLogPIISchema.carrierName] =  SchemaField.string(CommunicationsResponseGetCallLogPIISchema.Defaults.carrierName).optional();
    parameters[CommunicationsResponseGetCallLogPIISchema.carrierType] =  SchemaField.string(CommunicationsResponseGetCallLogPIISchema.Defaults.carrierType).optional();
    parameters[CommunicationsResponseGetCallLogPIISchema.callerName] =  SchemaField.string(CommunicationsResponseGetCallLogPIISchema.Defaults.callerName).optional();
    parameters[CommunicationsResponseGetCallLogPIISchema.callerType] =  SchemaField.string(CommunicationsResponseGetCallLogPIISchema.Defaults.callerType).optional();
    parameters[CommunicationsResponseGetCallLogPIISchema.iso3166Alpha2CountryCode] =  SchemaField.iso3166Alpha2CountryCode(CommunicationsResponseGetCallLogPIISchema.Defaults.iso3166Alpha2CountryCode).optional();
    parameters[CommunicationsResponseGetCallLogPIISchema.parentCallInfoToken] =  SchemaField.obj(ParentCallInfoToken, CommunicationsResponseGetCallLogPIISchema.Defaults.parentCallInfoToken).optional();
    parameters[CommunicationsResponseGetCallLogPIISchema.shakenPassport] =  SchemaField.obj(ShakenPassport, CommunicationsResponseGetCallLogPIISchema.Defaults.shakenPassport).optional();
    parameters[CommunicationsResponseGetCallLogPIISchema.twilioStirVerstat] =  SchemaField.string(CommunicationsResponseGetCallLogPIISchema.Defaults.twilioStirVerstat).optional().valid(...values(TwilioStirVerstat));
    /* eslint-enable */

    return parameters;
  }
}
