import {cloneDeep} from 'lodash';
import {IVROptionConstructor} from './ivr-option-constructor';
import {IVROptionSchema} from './ivr-option-schema';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {VoiceResponseCommand} from '../../../voice-response-command/generic/voice-response-command';
import {LanguageDefaults} from '../../../voice-response-command/vrc-audio-metadata/language-defaults';
import {IVRKeyPresses} from '../ivr/ivr-key-presses';
import {Displayable} from '../../../generic/db-doc/displayable';
import {doesMatchPhrase} from '../helper/does-match-phrase/does-match-phrase';

export class IVROption extends SerializableObject implements Displayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected displayName!: string;
  protected description!: string;
  protected keyPresses!: string[];
  protected phrases!: Map<string, string[]>;
  protected commands!: VoiceResponseCommand[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: IVROptionConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): IVROption {
    return new IVROption(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(IVROption.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new IVROptionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getId() {
    return this.getDisplayName();
  }

  public getDisplayName(): string {
    return cloneDeep(this.displayName);
  }

  public getDescription(): string {
    return cloneDeep(this.description);
  }

  public getKeyPresses(): string[] {
    return cloneDeep(this.keyPresses);
  }

  public getPhrases(): Map<string, string[]> {
    return cloneDeep(this.phrases);
  }

  public getCommands(): VoiceResponseCommand[] {
    return cloneDeep(this.commands);
  }

  public getPhrasesForLanguage(language: LanguageDefaults): string[] {
    const shortCode = language.getShortCode();
    return cloneDeep(this.phrases.get(shortCode)) || [];
  }

  /////////////////////////////////////////////////////////////////////////////
  // Any Key Press
  /////////////////////////////////////////////////////////////////////////////

  private shouldActOnAnyKeyPress(): boolean {
    if (this.getKeyPresses().includes(IVRKeyPresses.anyKey)) {
      return true;
    }

    return false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Get Commands For Input
  /////////////////////////////////////////////////////////////////////////////

  /**
   *
   * Determine if the given detected speech or detected key presses
   * should trigger this IVROption in the given language. If so, return the commands.
   * Else, return undefined.
   *
   * @param detectedSpeech Detected speech
   * @param keysPressed the keys pressed by the user if any
   * @param language language
   */
  public getCommandsForInput(detectedSpeech: string | undefined, keysPressed: string | undefined, language: LanguageDefaults): VoiceResponseCommand[] | undefined {
    // Handle case of keys pressed
    if (keysPressed !== undefined) {
      if (this.shouldActOnAnyKeyPress()) {
        return this.getCommands();
      }
      for (const keyPress of this.keyPresses) {
        if (keysPressed.trim() === keyPress.trim()) {
          return this.getCommands();
        }
      }
    }

    if (doesMatchPhrase({language, acceptedPhrases: this.getPhrasesForLanguage(language), detectedSpeech})) {
      return this.getCommands();
    }

    return undefined;
  }
}
