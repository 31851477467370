import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

import { EventRequest, EventRequestSchema } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { DBOrderBy, DBQuery } from '../../generic/interfaces';
import { AuthService } from '../../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EventRequestsService extends DbDocumentService<EventRequest> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, EventRequest);
  }

  /////////////////////////////////////////////////////////////////////
  // Open Shift Requests
  /////////////////////////////////////////////////////////////////////

  public getOpenRequests(takeOne = true): Observable<EventRequest[]> {
    console.log(`getOpenRequests`, { takeOne });

    // Make the query
    const query: DBQuery[] = [{ fieldPath: EventRequestSchema.isOpen, opStr: '==', value: true }];

    // Return
    return this.getDocsArray(query, undefined, undefined, takeOne);
  }

  /////////////////////////////////////////////////////////////////////
  // Requests created in range
  /////////////////////////////////////////////////////////////////////

  public getRequestsCreatedInRange(start: Date, end: Date, takeOne = true): Observable<EventRequest[]> {
    // Log inputs
    console.log(`getRequestsCreatedInRange:` + `\n\tStart:\t${start}` + `\n\tTo:\t\t${end}` + `\n\ttakeOne:${takeOne}`);

    // Make the query
    const query: DBQuery[] = [
      { fieldPath: EventRequestSchema.createTime, opStr: '>=', value: start },
      { fieldPath: EventRequestSchema.createTime, opStr: '<=', value: end },
    ];

    const dbOrderBy: DBOrderBy = { fieldPath: EventRequestSchema.createTime, directionStr: 'asc' };

    return this.getDocsArray(query, dbOrderBy, undefined, takeOne);
  }
}
