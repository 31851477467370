import { AvailableTimesByWeek, AvailableTimesType } from './available-times-by-week';

export const AVAILABLE_TIMES_BY_DOW_ALWAYS_AVAILABLE_MAP: Map<number, AvailableTimesByWeek> = new Map([
  [
    1,
    {
      type: AvailableTimesType.alwaysAvailable,
    },
  ],
  [
    2,
    {
      type: AvailableTimesType.alwaysAvailable,
    },
  ],
  [
    3,
    {
      type: AvailableTimesType.alwaysAvailable,
    },
  ],
  [
    4,
    {
      type: AvailableTimesType.alwaysAvailable,
    },
  ],
  [
    5,
    {
      type: AvailableTimesType.alwaysAvailable,
    },
  ],
  [
    6,
    {
      type: AvailableTimesType.alwaysAvailable,
    },
  ],
  [
    7,
    {
      type: AvailableTimesType.alwaysAvailable,
    },
  ],
]);

export const AVAILABLE_TIMES_BY_DOW_NEVER_AVAILABLE_MAP: Map<number, AvailableTimesByWeek> = new Map([
  [
    1,
    {
      type: AvailableTimesType.neverAvailable,
    },
  ],
  [
    2,
    {
      type: AvailableTimesType.neverAvailable,
    },
  ],
  [
    3,
    {
      type: AvailableTimesType.neverAvailable,
    },
  ],
  [
    4,
    {
      type: AvailableTimesType.neverAvailable,
    },
  ],
  [
    5,
    {
      type: AvailableTimesType.neverAvailable,
    },
  ],
  [
    6,
    {
      type: AvailableTimesType.neverAvailable,
    },
  ],
  [
    7,
    {
      type: AvailableTimesType.neverAvailable,
    },
  ],
]);
