import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {PossibleAdminRole} from '../../../../../objects/user/admin-roles/types';
import {PossibleRole} from '../../../../../objects/user/roles/types';
import {CommunicationsRequestName} from '../../../communications-request-name';
import {CommunicationsRequest} from '../../generic/communications-request/communications-request';
import {CommunicationsRequestSchema} from '../../generic/communications-request/communications-request-schema';
import {CommunicationsRequestCreateAsyncServiceRequestConstructor} from './communications-request-create-async-service-request-constructor';
import {CommunicationsRequestCreateAsyncServiceRequestSchema} from './communications-request-create-async-service-request-schema';
import {CommunicationSessionId} from '../../../../../objects/core/communication-session-id/communication-session-id';

export class CommunicationsRequestCreateAsyncServiceRequest extends CommunicationsRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected asyncServiceRequestId!: string;
  protected createAsyncServiceRequestWidgetId!: string;
  protected e164Phone!: string;
  protected sessionId!: CommunicationSessionId;
  protected note?: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsRequestCreateAsyncServiceRequestConstructor) {
    (parameters as any)[CommunicationsRequestSchema.type] = CommunicationsRequestName.createAsyncServiceRequest;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationsRequestCreateAsyncServiceRequest {
    return new CommunicationsRequestCreateAsyncServiceRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsRequestCreateAsyncServiceRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsRequestCreateAsyncServiceRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['voicemail'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getAsyncServiceRequestId() {
    return cloneDeep(this.asyncServiceRequestId);
  }

  public getCreateAsyncServiceRequestWidgetId() {
    return cloneDeep(this.createAsyncServiceRequestWidgetId);
  }
  public getE164Phone() {
    return cloneDeep(this.e164Phone);
  }
  public getSessionId() {
    return cloneDeep(this.sessionId);
  }
  public getNote() {
    return cloneDeep(this.note);
  }
}
