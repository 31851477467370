<ng-template #doneTemplate>
  <ng-container *transloco="let t; read: 'blocked-caller'">
    <p-toast></p-toast>

    <mat-card *ngrxLet="phoneNumberData$ | async as phoneNumberData" class="selector-card">
      <mat-card-header>
        <h2>{{ t('title') }}</h2>
      </mat-card-header>

      <mat-card-content>
        <p-messages severity="success">
          <ng-template pTemplate>{{ t('longTermBlockAnnouncement') }}</ng-template>
        </p-messages>

        <p-messages severity="info">
          <ng-template *ngIf="phoneNumberData?.isAnonymous" pTemplate>
            {{ t('anonymousCallerInfo') }}
          </ng-template>
        </p-messages>

        <form *ngIf="!loading" class="blocked-caller__form" [formGroup]="form">
          <div class="form-row">
            <div class="col">
              <!-- Reason -->
              <mat-form-field *ngrxLet="form.controls.reason as reason" class="full-width">
                <textarea matInput placeholder="{{ t('reason') }}" class="full-width" formControlName="reason"></textarea>
                <mat-error *ngIf="reason.invalid && reason.touched">
                  {{ t('requiredField') }}
                </mat-error>
              </mat-form-field>
            </div>
            <div class="col">
              <!-- Expire Time -->
              <mat-form-field *ngrxLet="form.controls.expireTime as expireTime" appearance="fill" class="full-width">
                <mat-label>{{ t('endTime') }}</mat-label>
                <input matInput formControlName="expireTime" type="datetime-local" />
                <mat-error *ngIf="expireTime.invalid && expireTime.touched">
                  {{ t('expireTimeError') }}
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <p-checkbox
            *ngIf="form.controls.allowedToBlock.enabled"
            class="p-checkbox--centered"
            formControlName="allowedToBlock"
            [binary]="true"
            [label]="t('allowedToBlock', phoneNumberData)"
          >
          </p-checkbox>
        </form>
      </mat-card-content>

      <mat-card-actions>
        <button
          mat-button
          [disabled]="isBlockExpired || apiCallInProgress || form.invalid || callIsOlderThan30Days"
          (click)="doExpireNow()"
        >
          {{ t('expireCurrentBlockButtonLabel', { isBlockExpired }) }}
        </button>
        <button
          *ngIf="form.enabled"
          mat-button
          color="accent"
          [disabled]="apiCallInProgress || form.invalid || callIsOlderThan30Days"
          (click)="doBlock()"
        >
          {{ t('blockButtonLabel', phoneNumberData) }}
        </button>
      </mat-card-actions>

      <div *ngIf="callIsOlderThan30Days">
        {{ t('cannotEdit') }}
      </div>
    </mat-card>
  </ng-container>
</ng-template>

<app-loading [doneTemplate]="doneTemplate" [loader]="loader"> </app-loading>
