import { isEmpty } from 'lodash';

import { DateRangeSelectOutput } from '../date-range-select-output';

export const makeDateArray = (dateRange: DateRangeSelectOutput | undefined): Date[] | undefined => {
  if (isEmpty(dateRange)) {
    return undefined;
  }

  return [dateRange.start, dateRange.end];
};
