<ng-container *transloco="let t; read: 'settings'">
  <p-toast position="bottom-center"></p-toast>
  <p-card class="p-card--grow-content">
    <ng-template pTemplate="header">
      <legend>{{ cardTitle }}</legend>
    </ng-template>

    <ng-template pTemplate="content">
      <ng-container [ngTemplateOutlet]="editorTemplate"> </ng-container>
    </ng-template>

    <ng-template pTemplate="footer">
      <div>
        <div class="row">
          <app-audit-entry *ngIf="auditEntry" class="col flex align-items-center wrap" [auditEntry]="auditEntry"></app-audit-entry>
          <div class="col flex justify-content-end flex-wrap">
            <p-button styleClass="p-button-text" [disabled]="uploadInProgress" [label]="t('cancelButton')" (onClick)="cancel()"></p-button>
            <p-button
              [disabled]="!isValid() || uploadInProgress"
              [label]="t('saveButton')"
              [loading]="uploadInProgress"
              (onClick)="upload()"
            ></p-button>
          </div>
        </div>
      </div>
    </ng-template>
  </p-card>
</ng-container>
