import { Pipe, PipeTransform } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { isNil } from 'lodash';

import { MenuOption } from '../../../../common/form/typings/menu-options';
import { AllRequestsForOneEventSlotSummary } from '../../common/all-requests-for-one-event-slot-summary';

import { EventRequestSpecialAction } from './event-request-special-action';
import { createApproveRequestOptions } from './helper/create-approve-event-request-options';
import { getSummaryData } from './helper/get-summary-data';

@Pipe({
  name: 'eventRequestActionOptions',
  standalone: true,
})
export class EventRequestActionOptionsPipe implements PipeTransform {
  // eslint-disable-next-line class-methods-use-this
  public transform(summary: AllRequestsForOneEventSlotSummary): MenuOption[] {
    const summaryData = getSummaryData(summary);

    const rejectAllOption: MenuOption = {
      description: translate('event-request-action-option.rejectAllRequestsDescription', summaryData),
      label: translate('event-request-action-option.rejectAllRequests', summaryData),
      value: EventRequestSpecialAction.rejectAll,
    };

    if (summaryData.isEventDeleted === true || !isNil(summaryData.assignedTo)) {
      return [rejectAllOption];
    }

    return [rejectAllOption, ...createApproveRequestOptions(summary, summaryData)];
  }
}
