import {cloneDeep} from 'lodash';
import moment from 'moment-timezone';

import {ServiceExceptionExecutions} from '../../communication/service-exception/service-exception-executions/service-exception-executions/service-exception-executions';
import {ServiceLimitExecutions} from '../../communication/service-limit/service-limit-executions/service-limit-executions/service-limit-executions';
import {DBDocObject} from '../../generic/db-doc/db-doc-object';
import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {IVRResponse} from '../ivr/ivr-response/ivr-response';
import {IVRResponseDirection} from '../ivr/ivr-response/ivr-response-direction';

import {CallLogConstructor} from './call-log-constructor';
import {CallLogSchema} from './call-log-schema';
import {CallState} from './call-state';
import {MAX_CALL_LENGTH_MS} from './constants';
import {DisconnectReason} from './disconnect-reason';
import {DisconnectedBy} from './disconnected-by';
import {StoppedDialingReason} from './stopped-dialing-reason';
import {getNumBillableMinutes} from '../helper/get-num-billable-minutes/get-num-billable-minutes';

export class CallLog extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected operatorCallCreateTime!: moment.Moment;
  protected incomingCallReceivedTime!: moment.Moment;
  protected operatorCallStartTime!: moment.Moment;
  protected serviceRequestTime: moment.Moment | undefined;
  protected dialerStartTime: moment.Moment | undefined;
  protected serviceDeliveryStartTime: moment.Moment | undefined;
  protected serviceDeliveryEndTime: moment.Moment | undefined;
  protected calleeDisconnectTime: moment.Moment | undefined;
  protected callListId!: string;
  protected answeredBy: string | undefined;
  protected incomingCallSid!: string;
  protected conferenceSid: string | undefined;
  protected disconnectedBy: DisconnectedBy | undefined;
  protected language!: string | undefined;
  protected disconnectReason!: DisconnectReason;
  protected stoppedDialingReason!: StoppedDialingReason;
  protected userIdsToCall!: string[];
  protected hasCriticalServerError!: boolean;
  protected blockedCallerId: string | undefined;
  protected didCreateNewBlockedCaller!: boolean;
  protected hasAnonymousCaller!: boolean;
  protected receivedAtE164Phone!: string;
  protected voicemailMetadataId: string | undefined;
  protected operatorDurationMS: number | undefined;
  protected operatorPriceUnit: string | undefined;
  protected calleeCallAcceptTime!: moment.Moment | undefined;
  protected responses!: IVRResponse[];
  protected asyncServiceRequestId!: string | undefined;
  protected serviceLimitExecutions!: ServiceLimitExecutions | undefined;
  protected serviceExceptionExecutions!: ServiceExceptionExecutions | undefined;
  protected forwardedTo?: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CallLogConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CallLog {
    return new CallLog(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(CallLog.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new CallLogSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getOperatorCallCreateTime(): moment.Moment {
    return cloneDeep(this.operatorCallCreateTime);
  }

  public getIncomingCallReceivedTime(): moment.Moment {
    return cloneDeep(this.incomingCallReceivedTime);
  }

  public getOperatorCallStartTime(): moment.Moment {
    return cloneDeep(this.operatorCallStartTime);
  }

  public getServiceRequestTime(): moment.Moment | undefined {
    return cloneDeep(this.serviceRequestTime);
  }

  public getDialerStartTime(): moment.Moment | undefined {
    return cloneDeep(this.dialerStartTime);
  }

  /**
   * This value is missing for calls in progress, and calls not answered.
   */
  public getServiceDeliveryStartTime(): moment.Moment | undefined {
    return cloneDeep(this.serviceDeliveryStartTime);
  }

  /**
   * This value is missing for calls in progress.
   */
  public getServiceDeliveryEndTime(): moment.Moment | undefined {
    return cloneDeep(this.serviceDeliveryEndTime);
  }

  public getCalleeDisconnectTime(): moment.Moment | undefined {
    return cloneDeep(this.calleeDisconnectTime);
  }

  /**
   * Get the call list that handles this call.
   */
  public getCallListId(): string {
    return cloneDeep(this.callListId);
  }

  public getAnsweredBy(): string | undefined {
    return cloneDeep(this.answeredBy);
  }

  public getIncomingCallSid(): string {
    return cloneDeep(this.incomingCallSid);
  }

  public getConferenceSid(): string | undefined {
    return cloneDeep(this.conferenceSid);
  }

  public getVoicemailMetadataId(): string | undefined {
    return cloneDeep(this.voicemailMetadataId);
  }

  public getDisconnectReason(): DisconnectReason {
    return cloneDeep(this.disconnectReason);
  }

  public getStoppedDialingReason(): StoppedDialingReason {
    return cloneDeep(this.stoppedDialingReason);
  }

  public getUserIdsToCall(): string[] {
    return cloneDeep(this.userIdsToCall);
  }

  public getDisconnectedBy(): DisconnectedBy | undefined {
    return cloneDeep(this.disconnectedBy);
  }

  public getLanguage(): string | undefined {
    return cloneDeep(this.language);
  }

  public getHasCriticalServerError(): boolean {
    return cloneDeep(this.hasCriticalServerError);
  }

  public getBlockedCallerId(): string | undefined {
    return cloneDeep(this.blockedCallerId);
  }

  public getDidCreateNewBlockedCaller(): boolean {
    return cloneDeep(this.didCreateNewBlockedCaller);
  }

  public getHasAnonymousCaller(): boolean {
    return cloneDeep(this.hasAnonymousCaller);
  }

  public getReceivedAtE164Phone(): string {
    return cloneDeep(this.receivedAtE164Phone);
  }

  public getOperatorDurationMS(): number | undefined {
    return cloneDeep(this.operatorDurationMS);
  }

  public getOperatorPriceUnit(): string | undefined {
    return cloneDeep(this.operatorPriceUnit);
  }

  public getCalleeCallAcceptTime(): moment.Moment | undefined {
    return cloneDeep(this.calleeCallAcceptTime);
  }

  public getResponses(): IVRResponse[] {
    return cloneDeep(this.responses);
  }

  public getAsyncServiceRequestId() {
    return cloneDeep(this.asyncServiceRequestId);
  }

  public getServiceExceptionExecutions() {
    return cloneDeep(this.serviceExceptionExecutions);
  }

  public getServiceLimitExecutions() {
    return cloneDeep(this.serviceLimitExecutions);
  }

  public getForwardedTo() {
    return cloneDeep(this.forwardedTo);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Was Blocked
  /////////////////////////////////////////////////////////////////////////////

  public wasBlocked(): boolean {
    if (this.blockedCallerId !== undefined || this.disconnectReason === DisconnectReason.enforceBlockedCaller) {
      return true;
    }
    return false;
  }

  public hadCapacity(): boolean {
    if (this.stoppedDialingReason === StoppedDialingReason.noCapacity || this.disconnectReason === DisconnectReason.noCapacity) {
      return false;
    }
    return true;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Caller Disconnect Time
  /////////////////////////////////////////////////////////////////////////////

  /**
   * The caller disconnect time is simply the service delivery
   * end time. Return that.
   */
  public getCallerDisconnectTime(): moment.Moment | undefined {
    return this.getServiceDeliveryEndTime();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Was Answered
  /////////////////////////////////////////////////////////////////////////////

  public wasAnswered(): boolean {
    // TODO: We will migrate the db so answeredBy != 'unanswered' ever. When that happens, remove this line.
    if (this.answeredBy === CallLogSchema.Defaults.answeredBy || this.answeredBy === 'unanswered') {
      return false;
    }
    return true;
  }

  /**
   * These are calls that couldn't be connected because all possible users
   * were dialed and there were no remaining users, but the client was still
   * not connected.
   *
   * @returns
   */
  public wasUnexpectedlyMissed(): boolean {
    if (!this.wasAnswered() && !this.wasBlocked() && this.hadCapacity() && this.stoppedDialingReason === StoppedDialingReason.noUsersLeftToDial) {
      return true;
    }
    return false;
  }

  public callListSelected(): boolean {
    return !(this.callListId === CallLogSchema.Defaults.callListId);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Client Connected To User
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return true if the client and user were connected, and false otherwise.
   *
   * @returns
   */
  public wasClientConnectedToUser(): boolean {
    return this.wasAnswered() && !this.didClientDisconnectBeforeServiceDeliveryAttemptComplete();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Call State
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Determine if one or both parties are currently on the phone.
   */
  public getState(): CallState {
    if (this.serviceDeliveryEndTime !== undefined || this.disconnectedBy !== undefined) {
      return CallState.completed;
    }

    // Note: The call is in progress, because serviceDeliveryEndTime is undefined.

    // serviceDeliveryStartTime is set only when the Conference starts.
    if (this.serviceDeliveryStartTime !== undefined) {
      return CallState.serviceDeliveryInProgress;
    }

    // In progress calls with a blocked callerId are currently receiving service. The caller is being informed that they are currently blocked.
    if (this.blockedCallerId !== undefined) {
      return CallState.serviceDeliveryInProgress;
    }

    if (this.serviceRequestTime !== undefined) {
      return CallState.dialerInProgress;
    }

    return CallState.inProgressServiceNotSelected;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Ring Duration
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return the number of milliseconds that the call was ringing, typically this is
   * the time before it is answered by an initial voice prompt.
   */
  public getRingDurationMS(): number {
    let result = 0;

    result = this.operatorCallStartTime.valueOf() - this.operatorCallCreateTime.valueOf();

    if (result < 0 || result > MAX_CALL_LENGTH_MS) {
      console.error(`getRingDurationMS: sessionId=${this.getId()}: Bad value for ring duration: ${result}`);
      return 0;
    }

    return result;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Hold Duration
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return the number of milliseconds that the user
   * was on hold. If the user has not started holding, then return 0.
   *
   * If the user is currently holding, then return the number
   * of milliseconds they have been on hold.
   */
  public getHoldDurationMS(): number {
    let holdDurationMS = 0;

    // Defensive code which determines value of timestamps that should never be undefined in the
    // cases they are used below.
    const valueOfServiceRequestTime = this.serviceRequestTime?.valueOf() || 0;
    const valueOfServiceDeliveryStartTime = this.serviceDeliveryStartTime?.valueOf() || 0;
    const valueOfServiceDeliveryEndTime = this.serviceDeliveryEndTime?.valueOf() || 0;

    switch (this.getState()) {
      case CallState.inProgressServiceNotSelected: {
        // Client has not started to hold.
        holdDurationMS = 0;
        break;
      }
      case CallState.dialerInProgress: {
        // Client currently on hold
        holdDurationMS = moment.now().valueOf() - valueOfServiceRequestTime;
        break;
      }
      case CallState.serviceDeliveryInProgress: {
        // Client completed entire hold duration
        holdDurationMS = valueOfServiceDeliveryStartTime - valueOfServiceRequestTime;
        break;
      }
      case CallState.completed: {
        // Client completed entire hold duration
        // Case: No service was delivered. This happens if the client was on hold for the entire duration of their call.
        if (this.wasAnswered()) {
          // Case: Service delivery was attempted (eg, picked up phone)
          if (valueOfServiceDeliveryStartTime === 0) {
            // In this case, service delivery was not started successfully, eg if DisconnectReason.incomingCallNotActiveCounselorAccepted
            holdDurationMS = valueOfServiceDeliveryEndTime - valueOfServiceRequestTime;
            break;
          }
          holdDurationMS = valueOfServiceDeliveryStartTime - valueOfServiceRequestTime;
        } else {
          if (this.callListSelected()) {
            holdDurationMS = valueOfServiceDeliveryEndTime - valueOfServiceRequestTime;
          } else {
            // If no option was selected, then the user was never on hold
            holdDurationMS = 0;
          }
        }
        break;
      }
    }

    if (holdDurationMS < 0 || holdDurationMS > MAX_CALL_LENGTH_MS) {
      console.error(`getHoldDuration: sessionId=${this.getId()}: Bad value for hold duration: ${holdDurationMS}`);
      return 0;
    }

    return holdDurationMS;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Talk Duration
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Return the duration in milliseconds that the call was connected. This excludes
   * time that the client was on hold.
   *
   * If the client is currently on hold, return 0.
   * If the client is currently talking, return the duration so far.
   */
  public getTalkDurationMS(): number {
    let endTime = this.getServiceDeliveryEndTime() || moment.now();

    switch (this.getState()) {
      case CallState.inProgressServiceNotSelected: {
        // Talking has not started yet.
        return 0;
      }
      case CallState.dialerInProgress: {
        // Talking has not started yet.
        return 0;
      }
      case CallState.serviceDeliveryInProgress: {
        // Case: User currently talking
        endTime = moment.now();
        break;
      }
      case CallState.completed: {
        if (!this.wasAnswered()) {
          return 0;
        }
        endTime = this.getServiceDeliveryEndTime()!;
        break;
      }
    }

    if (this.serviceDeliveryStartTime === undefined) {
      // In this case, service delivery never started, eg if the client disconnected before user could answer.
      return 0;
    }

    const result = (endTime?.valueOf() || 0) - this.serviceDeliveryStartTime!.valueOf();
    if (result < 0 || result > MAX_CALL_LENGTH_MS) {
      console.error(`getTalkDuration: sessionId=${this.getId()}: Bad value for talk duration: ${result}. Returning 0`);
      return 0;
    }
    return result;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Determine if call is in progress
  /////////////////////////////////////////////////////////////////////////////

  public isInProgress(): boolean {
    const state = this.getState();
    if (state === CallState.completed) {
      return false;
    }

    return true;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Billable Minutes
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Determine the number of billable minutes.
   */
  public getNumBillableMinutes(): number {
    return getNumBillableMinutes({isInProgress: this.isInProgress(), operatorDurationMS: this.operatorDurationMS});
  }

  /////////////////////////////////////////////////////////////////////////////
  // Client Disconnected Before Service Delivery Attempt Complete
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Determine if the client disconnected before we had a chance to
   * fully attempt delivering service.
   *
   * Note: Calls disconnected with noCapacity, or blocked caller return false.
   * Return true only if the client disconnected while the dialer was
   * in progress.
   */
  public didClientDisconnectBeforeServiceDeliveryAttemptComplete(): boolean {
    const hasError = this.getHasCriticalServerError() || this.getDisconnectReason() === DisconnectReason.error;
    const answeredButHungUpEarlyViaTimestamp = this.getStoppedDialingReason() === StoppedDialingReason.wasAnswered && this.getServiceDeliveryEndTime()?.isBefore(this.getCalleeCallAcceptTime());
    const hungUpWhileDialing = this.getStoppedDialingReason() === StoppedDialingReason.incomingCallDisconnected;
    const answeredButHungUpEarlyViaDisconnectReason = this.getDisconnectReason() === DisconnectReason.incomingCallNotActiveCounselorAccepted;

    if (hasError) {
      // Legacy code. DisconnectReason.error is no longer used.
      return false;
    }

    if (answeredButHungUpEarlyViaTimestamp || hungUpWhileDialing || answeredButHungUpEarlyViaDisconnectReason) {
      return true;
    }
    return false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Send Digits
  /////////////////////////////////////////////////////////////////////////////

  public didSendDigits(): boolean {
    for (const response of this.responses) {
      if (response.getDirection() === IVRResponseDirection.sent) {
        return true;
      }
    }
    return false;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
