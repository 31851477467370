import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { MatSliderChange } from '@angular/material/slider';
import { isNil } from 'lodash';

import { NgChanges } from '../../../../common/objects/ng-changes';
import { HowlerPlayer } from '../../../../common/sound/howler-player';

@Component({
  selector: 'app-audio-player',
  templateUrl: './audio-player.component.html',
  styleUrls: ['./audio-player.component.css'],
})
export class AudioPlayerComponent implements OnInit, OnChanges, OnDestroy {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////

  @Input() playURL: string;
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  currentPosSeconds = 0;

  howlerPlayer: HowlerPlayer;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle Component
  ///////////////////////////////////////////////////////////////////////

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: NgChanges<AudioPlayerComponent>) {
    if (changes.playURL.currentValue !== changes.playURL.previousValue) {
      this.howlerPlayer?.cleanup();
      this.play();
    }
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.howlerPlayer?.cleanup();
  }

  ///////////////////////////////////////////////////////////////////////
  // Audio Lifecycle
  ///////////////////////////////////////////////////////////////////////

  play() {
    if (isNil(this.howlerPlayer)) {
      this.howlerPlayer = new HowlerPlayer(this.playURL);
      this.howlerPlayer.onPlay().subscribe((progress) => {
        if (Math.round(10 * progress.secsPlayed) !== Math.round(10 * this.currentPosSeconds)) {
          this.currentPosSeconds = progress.secsPlayed;
          this.changeDetectorRef.detectChanges();
        }
      });
    }
    this.howlerPlayer.play();
    this.changeDetectorRef.detectChanges();
  }

  onSliderChange(sliderChange: MatSliderChange) {
    this.pause();
    this.howlerPlayer.seekToSeconds(sliderChange.value);
    this.play();
  }

  pause() {
    this.howlerPlayer.pause();
    this.changeDetectorRef.detectChanges();
  }
}
