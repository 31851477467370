import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  QueryList,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { MenuItem, MessageService } from 'primeng/api';

import { NgChanges } from '../../../../common/objects/ng-changes';
import { WizardStepComponent } from '../wizard-step/wizard-step.component';


@UntilDestroy()
@Component({
  selector: 'app-wizard-steps',
  templateUrl: './wizard-steps.component.html',
  styleUrls: ['./wizard-steps.component.css'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WizardStepsComponent implements AfterContentInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  @Input() complete: () => Promise<void> = () => Promise.resolve();

  @Input() completeLabel: string;

  @Input() activeIndex = 0;

  @Output() activeIndexChange: EventEmitter<any> = new EventEmitter();

  @Output() activeLabelChange = new EventEmitter();

  @Input() stepsReadonly = false;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  items: MenuItem[] = [];

  @ContentChildren(WizardStepComponent) steps: QueryList<WizardStepComponent>;

  previousButtonVisible = false;

  nextButtonVisible = true;

  nextButtonDisabled = false;

  completeButtonVisible = false;

  completeLoadingIndicator = false;

  stepInputsChangeSubscribed = false;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  /**
   * This code is executed once. It's like ngOnInit, but run after ContentChildren is initialized.
   */
  ngAfterContentInit() {
    this.updateVisibleStepComponent();
  }

  ngOnChanges(changes: NgChanges<WizardStepsComponent>) {
    this.updateVisibleStepComponent();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Update Visible Step Component
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  private updateVisibleStepComponent() {
    if (isNil(this.steps)) {
      return;
    }

    /**
     * Listen for changes to the inputs of any step. Used to
     * enable / disable buttons based on step state.
     */
    if (!this.stepInputsChangeSubscribed) {
      this.stepInputsChangeSubscribed = true;
      this.steps.map((step, index) => {
        step.stepInputsChange.pipe(untilDestroyed(this)).subscribe(() => {
          this.updateVisibleStepComponent();
        });
      });
    }

    /**
     * Show steps as necessary
     */
    this.steps.map((step: WizardStepComponent, index: number) => {
      // show / hide the step
      const selected = index === this.activeIndex;
      step.active = selected;
      step.changeDetectorRef.markForCheck();

      this.items[index] = {
        label: step.label,
      };

      if (selected) {
        // emit currently selected label
        this.activeLabelChange.next(step.label);
      }
    });

    this.updateButtons();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Update Visible Buttons
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  private updateButtons() {
    this.previousButtonVisible = this.activeIndex > 0;
    this.nextButtonVisible = this.activeIndex < this.items.length - 1;
    this.nextButtonDisabled = this.steps?.toArray()[this.activeIndex].hasError ?? false;
    this.completeButtonVisible = this.activeIndex === this.items.length - 1;
    this.changeDetectorRef.markForCheck();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Previous
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  previousClick() {
    this.activeIndex--;
    this.activeIndexChange.emit(this.activeIndex);
    this.updateVisibleStepComponent();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Next
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  nextClick() {
    this.activeIndex++;
    this.activeIndexChange.emit(this.activeIndex);
    this.updateVisibleStepComponent();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Complete
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  async completeClick() {
    try {
      this.completeLoadingIndicator = true;
      await this.complete();
    } catch (error) {
      console.error(error);
    } finally {
      this.completeLoadingIndicator = false;
    }

    this.updateVisibleStepComponent();
  }
}
