import {SchemaField} from '../../../../../../../generic/serialization/schema-field';
import {CommunicationSessionEventLogSchema} from '../../../../generic/communication-session-event-log/communication-session-event-log-schema';
import {Mixin} from 'ts-mixer';
import {GenericDialedCallLogSchema} from '../../../../../../../call/dialed-call-log/generic/any-dialed-call-log-schema';
import {CommunicationLogParticipant} from '../../../../../../communication-log-participant/communication-log-participant';
import {values} from 'lodash';
import {CommunicationSessionEventLogDialPhoneCallType} from '../communication-session-event-log-dial-phone-call-type';

export class CommunicationSessionEventLogDialPhoneSchema extends Mixin(GenericDialedCallLogSchema, CommunicationSessionEventLogSchema) {
  public static readonly callType = 'callType';
  public static readonly participant = 'participant';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic InboundCallEvent Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////

    public static readonly type = undefined;
    public static readonly actor = undefined;
    public static readonly timestamp = undefined;

    ///////////////////////////////////////////////////////
    // Generic Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////
    /**
     * Timestamps
     */
    public static readonly initiatedTimestamp = undefined;
    public static readonly ringingTimestamp = undefined;
    public static readonly answeredTimestamp = undefined;
    public static readonly completedTimestamp = undefined;

    /**
     * Operator Tracking
     */
    public static readonly errorCode = undefined;
    public static readonly operatorDurationMS = undefined;
    public static readonly operatorPriceUnit = undefined;
    public static readonly operatorBasePricePM = undefined;
    public static readonly operatorCountryCode = undefined;

    /**
     * IVR
     */
    public static readonly responses = [];

    /**
     * Other
     */
    public static readonly callerIdObjId = undefined;
    public static readonly sipResponseCode = undefined;
    public static readonly operatorCallStatus = undefined;
    public static readonly callSid = undefined;

    ///////////////////////////////////////////////////////
    // CommunicationSessionEventLogDialPhone Properties
    ///////////////////////////////////////////////////////

    /**
     * Tracking Info
     */
    public static readonly callType = undefined;
    public static readonly participant = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {
      ...new CommunicationSessionEventLogSchema().getSchemaDefinition(),
      ...new GenericDialedCallLogSchema().getSchemaDefinition(),
    };

    /* eslint-disable */
    /**
     * Tracking Info
     */
    parameters[CommunicationSessionEventLogDialPhoneSchema.callType] = SchemaField.string(
      CommunicationSessionEventLogDialPhoneSchema.callType,
      CommunicationSessionEventLogDialPhoneSchema.Defaults.callType
    )
      .required()
      .valid(...values(CommunicationSessionEventLogDialPhoneCallType));
    parameters[CommunicationSessionEventLogDialPhoneSchema.participant] = SchemaField.obj(CommunicationLogParticipant, CommunicationSessionEventLogDialPhoneSchema.Defaults.participant).required();
    /* eslint-enable */

    return parameters;
  }
}
