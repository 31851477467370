<ng-container *transloco="let t; read: 'service-command-editor-play-or-say'">
  <p-messages severity="info">
    <ng-template pTemplate>
      <p class="wrap">{{ t('description') }}</p>
    </ng-template>
  </p-messages>

  <form [formGroup]="form">
    <div class="formgrid grid p-fluid">
      <!-- Select Audio -->
      <div class="field col-12 mt-3">
        <p>{{ t('audioSelectLabel') }}</p>
        <app-vrc-audio-select
          id="audioId"
          [formControl]="audioId"
          [placeholder]="t('audioSelectPlaceholder')"
          [required]="true"
        ></app-vrc-audio-select>
        <p *ngIf="audioId.hasError('required')" class="p-error">{{ t('audioIdErrorRequired') }}</p>
      </div>

      <!-- Loop -->
      <div class="field col-12 mt-3">
        <span class="p-float-label">
          <p-inputNumber
            ariaLabel="{{ t('loopLabel') }}"
            id="loop"
            mode="decimal"
            [formControl]="loop"
            [max]="1000"
            [min]="1"
            [ngClass]="{ 'ng-invalid': loop?.invalid }"
            [showButtons]="true"
          >
          </p-inputNumber>
          <label for="loop">{{ t('loopLabel') }}</label>
          <p *ngIf="loop.hasError('required')" class="p-error">{{ t('loopErrorRequired') }}</p>
          <p *ngIf="loop.hasError('min')" class="p-error">{{ t('loopErrorMin') }}</p>
        </span>
        <p id="loop-help">{{ t('loopHelp', { loop: loop.value }) }}</p>
      </div>
    </div>
  </form>
</ng-container>
