import { cloneDeep } from 'lodash';

import { CommunicationTaskQueue, CommunicationWorkflow, CommunicationWorkflowSchema, isNilOrDefault, OrgData, UserData } from '@pwp-common';

import { RowCell } from '../../../../../common/p-table/row-cell';
import { DataTableRow } from '../../../../generic/data-table/common/data-table-row';
import { CommunicationWorkflowEditorOutput } from '../../editor/editor-output/workflow/communication-workflow-editor-output';
import { dbToEditorOutput } from '../../editor/editor-output/workflow/db-to-editor-output/db-to-editor-output';

const COMMUNICATION_WORKFLOW_SCHEMA = new CommunicationWorkflowSchema();

export class CommunicationWorkflowTableRow implements DataTableRow {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  id: string;

  description: RowCell<string>;

  displayName: RowCell<string>;

  editorOutput: CommunicationWorkflowEditorOutput;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public communicationWorkflow: CommunicationWorkflow,
    public allTaskQueues: Map<string, CommunicationTaskQueue>,
    public users: Map<string, UserData>,
    public orgData: OrgData,
  ) {
    this.id = communicationWorkflow.getId();

    this.setDescription(communicationWorkflow);
    this.setDisplayName(communicationWorkflow);
    this.editorOutput = cloneDeep(
      dbToEditorOutput({
        workflow: communicationWorkflow,
        orgData: this.orgData,
        users,
        taskQueues: this.allTaskQueues,
      }),
    );
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Description
  //////////////////////////////////////////////////////////////////////////////////////////

  private setDescription(communicationWorkflow: CommunicationWorkflow) {
    const description = communicationWorkflow.getDescription();
    if (isNilOrDefault(description, CommunicationWorkflowSchema.description, COMMUNICATION_WORKFLOW_SCHEMA)) {
      this.description = new RowCell<string>({
        translationScope: 'communication-workflow-table-editor',
        translationKey: 'cellDescriptionMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.description = new RowCell<string>({
      translationScope: 'communication-workflow-table-editor',
      translationKey: 'cellDescription',
      translationObj: {
        description,
      },
      sortValue: description,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Display Name
  //////////////////////////////////////////////////////////////////////////////////////////

  private setDisplayName(communicationWorkflow: CommunicationWorkflow) {
    const displayName = communicationWorkflow.getDisplayName();
    if (isNilOrDefault(displayName, CommunicationWorkflowSchema.displayName, COMMUNICATION_WORKFLOW_SCHEMA)) {
      this.displayName = new RowCell<string>({
        translationScope: 'communication-workflow-table-editor',
        translationKey: 'cellDisplayNameMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.displayName = new RowCell<string>({
      translationScope: 'communication-workflow-table-editor',
      translationKey: 'cellDisplayName',
      translationObj: {
        displayName,
      },
      sortValue: displayName,
    });
  }
}
