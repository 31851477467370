import { isNil } from 'lodash';
import moment from 'moment-timezone';

import { TIME_FORMAT_24H } from './constants';

export const convertTimeRangeToMoment = (timeStrs: string[]): { start: moment.Moment; end: moment.Moment } | undefined => {
  if (isNil(timeStrs) || timeStrs.length !== 2) {
    return undefined;
  }

  for (const timeStr of timeStrs) {
    if (timeStr.length !== 5) {
      return undefined;
    }
  }

  const start = moment.utc(timeStrs[0], TIME_FORMAT_24H);
  const end = moment.utc(timeStrs[1], TIME_FORMAT_24H);

  if (!start.isValid() || !end.isValid()) {
    return undefined;
  }

  if (end.isSameOrBefore(start)) {
    return undefined;
  }
  return { start, end };
};
