import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api';

import { displayTime, EventConfig, EventConfigSchema, EventData, OrgData } from '@pwp-common';

import { getFieldControlValueOrDefault } from '../../../../common/objects/form-helper';
import { EventsService } from '../../../../services/event/events/events.service';
import { ComponentWithForm } from '../../../generic/abstract-classes/component-with-form';
import { EventRepeatInfo } from '../../generic/event-repeat-info';


@Component({
  selector: 'app-event-create-dialog',
  templateUrl: './event-create-dialog.component.html',
  styleUrls: ['./event-create-dialog.component.css'],
  providers: [MessageService],
})
export class EventCreateDialogComponent extends ComponentWithForm implements OnInit {
  eventRepeatInfo = new EventRepeatInfo('RRULE:FREQ=WEEKLY;INTERVAL=1;WKST=SU;BYDAY=MO,TU,WE,TH,FR', this.data.end);

  typeName: string;

  events: EventData[];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public dialogRef: MatDialogRef<EventCreateDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      start: moment.Moment;
      end: moment.Moment;
      type: string;
      orgData: OrgData;
    },
    private eventsService: EventsService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
  ) {
    super();
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Create Shifts
  /////////////////////////////////////////////////////////////////////////////////////////////

  updateEvents() {
    let {rrule} = this.eventRepeatInfo;
    const doesRepeat = getFieldControlValueOrDefault(this.doesRepeat, false);
    if (!doesRepeat) {
      rrule = '';
    }
    const eventConfig = new EventConfig({
      id: '',
      start: this.data.start,
      end: this.data.end,
      rrule,
      generate: true,
      type: this.data.type,
      color: EventConfigSchema.Defaults.color,
      assignedUserId: EventConfigSchema.Defaults.assignedUserId,
      assignedBackupUserId: EventConfigSchema.Defaults.assignedBackupUserId,
      displayName: EventConfigSchema.Defaults.displayName,
      description: EventConfigSchema.Defaults.description,
    });

    this.events = eventConfig.generateEventsWithStartBetween(this.data.start, this.eventRepeatInfo.until, this.data.orgData.getTimezone());
  }

  async addEvents() {
    const minStart = moment.min(this.events.map((z) => z.getStart()));
    const maxStart = moment.max(this.events.map((z) => z.getEnd()));
    const alreadyExistingEvents = await this.eventsService.getEventsWithStartInRange(minStart, maxStart, this.data.type).toPromise();

    // Check new events don't overlap with existing events
    for (const aeEvent of alreadyExistingEvents.values()) {
      for (const newEvent of this.events) {
        if (aeEvent.getIntervalMs().overlaps(newEvent.getIntervalMs())) {
          this.failureAlreadyExists(aeEvent);
          return;
        }
      }
    }

    // Check new events don't overlap with each other
    for (let i = 0; i < this.events.length; i++) {
      for (let j = i + 1; j < this.events.length; j++) {
        if (i === j) {
          continue;
        }
        if (this.events[i].getIntervalMs().overlaps(this.events[j].getIntervalMs())) {
          this.failureNewEventsOverlap(this.events[i], this.events[j]);
          return;
        }
      }
    }

    await this.eventsService.uploadArray(this.events, 'create', true).toPromise();
    this.dialogRef.close();
    this.successMessage();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;
    formConfig.doesRepeat = [false];

    this.form = this.formBuilder.group(formConfig);
    this.updateEvents();
  }

  onFormChanges() {
    this.updateEvents();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get doesRepeat(): AbstractControl | null {
    return this.form.get('doesRepeat');
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Notifications
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  private successMessage() {
    const success = this.translocoService.translate('event-create-dialog.success');

    this.messageService.add({
      severity: 'success',
      summary: success,
      detail: '',
    });
  }

  private failureNewEventsOverlap(event1: EventData, event2: EventData) {
    const timezone = this.data.orgData.getTimezone();
    console.error({ event1, event2 });

    const msg = this.translocoService.translate('event-create-dialog.failureNewEventsOverlap', {
      start1: displayTime(event1.getStart(), timezone, { timeOnly: false }),
      end1: displayTime(event1.getEnd(), timezone, { timeOnly: false }),
      start2: displayTime(event2.getStart(), timezone, { timeOnly: false }),
      end2: displayTime(event2.getEnd(), timezone, { timeOnly: false }),
    });

    this.messageService.add({
      severity: 'error',
      summary: msg,
    });
  }

  private failureAlreadyExists(existingEvent: EventData) {
    console.error(existingEvent);

    const msg = this.translocoService.translate('event-create-dialog.failureAlreadyExists', {
      start: displayTime(existingEvent.getStart(), this.data.orgData.getTimezone(), { timeOnly: false }),
      end: displayTime(existingEvent.getEnd(), this.data.orgData.getTimezone(), { timeOnly: false }),
    });

    this.messageService.add({
      severity: 'error',
      summary: msg,
    });
  }
}
