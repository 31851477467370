import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {TemplateText} from '../../text/template-text/template-text';
import {conversationWaitingRoomDefaultMessage} from './constants';

export class ConversationWaitingRoomConfigSchema extends SerializableObjectSchema {
  public static readonly enabled = 'enabled';
  public static readonly waitingRoomMessage = 'waitingRoomMessage';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly enabled = false;
    public static readonly waitingRoomMessage = TemplateText.deserialize(conversationWaitingRoomDefaultMessage);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values To Serialize
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public defaultValuesToSerialize(): Set<string> {
    return new Set([ConversationWaitingRoomConfigSchema.enabled]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ConversationWaitingRoomConfigSchema.enabled] = SchemaField.boolean(ConversationWaitingRoomConfigSchema.Defaults.enabled);
    parameters[ConversationWaitingRoomConfigSchema.waitingRoomMessage] = SchemaField.obj(TemplateText, ConversationWaitingRoomConfigSchema.Defaults.waitingRoomMessage);
    /* eslint-enable */

    return parameters;
  }
}
