import { DBOrderBy } from '../../../generic/interfaces';

export const ascending = (field: string): DBOrderBy => ({
  fieldPath: field,
  directionStr: 'asc',
});
export const descending = (field: string): DBOrderBy => ({
  fieldPath: field,
  directionStr: 'desc',
});
