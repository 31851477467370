import { Component, OnInit } from '@angular/core';

import { ConversationMessageTemplateType } from '@pwp-common';

@Component({
  selector: 'app-conversation-message-template-editor-on-inactive',
  templateUrl: './conversation-message-template-editor-on-inactive.component.html',
  styleUrls: ['./conversation-message-template-editor-on-inactive.component.css'],
})
export class ConversationMessageTemplateEditorOnInactiveComponent implements OnInit {
  ////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////

  onInactive = ConversationMessageTemplateType.onInactive;

  ////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////
  constructor() {}

  ngOnInit(): void {}
}
