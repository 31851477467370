import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input, OnChanges } from '@angular/core';
import { BehaviorSubject, distinctUntilChanged, map, of, switchMap } from 'rxjs';

import { userDataDisplayFormat } from '../../../../../common/objects/user-data-display-format';
import { CorePipesModule } from '../../../../../pipes/core-pipes/core-pipes.module';
import { UserDataService } from '../../../../../services/user/user-data/user-data.service';
import { TranslocoRootModule } from '../../../../../transloco/transloco-root.module';

import { AuditEntry } from './interfaces';

@Component({
  selector: 'app-audit-entry',
  standalone: true,
  imports: [CommonModule, CorePipesModule, TranslocoRootModule],
  templateUrl: './audit-entry.component.html',
  styleUrls: ['./audit-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuditEntryComponent implements OnChanges {
  private readonly userDataService = inject(UserDataService);

  private readonly auditEntry$ = new BehaviorSubject<AuditEntry | undefined>(undefined);

  public readonly userDisplayName$ = this.auditEntry$.pipe(
    distinctUntilChanged((prev, current) => prev?.userId === current?.userId),
    switchMap((entry) => (entry ? this.userDataService.getDoc(entry.userId) : of())),
    map(userDataDisplayFormat),
  );

  @Input() public auditEntry?: AuditEntry;

  public ngOnChanges(): void {
    this.auditEntry$.next(this.auditEntry);
  }
}
