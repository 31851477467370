import { isArray, isNil } from 'lodash';
import { Table } from 'primeng/table';

export const hasFilter = (table: Table): boolean => {
  for (const filters of Object.values(table.filters)) {
    // Handle search filter
    if (Object.prototype.hasOwnProperty.call(filters, 'matchMode')) {
      return true;
    }

    if (!isArray(filters)) {
      continue;
    }
    for (const filter of filters) {
      if (!isNil(filter.value)) {
        return true;
      }
    }
  }
  return false;
};
