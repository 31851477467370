import {SchemaField} from '../../generic/serialization/schema-field';
import moment from 'moment-timezone';
import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';

export class BlockedCallerSchema extends DBDocSchema {
  // Session Id of the call from which this data was created
  public static readonly sessionId = 'sessionId';

  // Phone number of incoming call
  public static readonly from = 'from';

  // Indicate if the caller was anonymous
  public static readonly anonymousCaller = 'anonymousCaller';

  // Time when this block expires
  public static readonly expireTime = 'expireTime';

  // Time when this block expires
  public static readonly reason = 'reason';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'blockedCallers';
    public static readonly REDACTED = 'REDACTED';
  };

  static Defaults = class {
    public static readonly sessionId = 'missing-sessionId';
    public static readonly from = 'missing-from';
    public static readonly anonymousCaller = false;
    public static readonly expireTime = moment(0);
    public static readonly reason = '';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[BlockedCallerSchema.sessionId] = SchemaField.string(BlockedCallerSchema.Defaults.sessionId);
    parameters[BlockedCallerSchema.from] = SchemaField.string(BlockedCallerSchema.Defaults.from);
    parameters[BlockedCallerSchema.anonymousCaller] = SchemaField.boolean(BlockedCallerSchema.Defaults.anonymousCaller);
    parameters[BlockedCallerSchema.expireTime] = SchemaField.timestamp(BlockedCallerSchema.Defaults.expireTime);
    parameters[BlockedCallerSchema.reason] = SchemaField.string(BlockedCallerSchema.Defaults.reason);
    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${BlockedCallerSchema.Constants.collection}`;
  }
}
