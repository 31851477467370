import {EntityStats} from '../entity-stats/entity-stats';
import {CallList} from '../../call/call-list/call-list/call-list';
import {EntityStatsDisplayWindow} from './entity-stats-display-window/enum';
import {EntityStatsDisplay} from './entity-stats-display';

export class CallStatsByType extends EntityStatsDisplay {
  public iNum = new Map<string, number>();

  /**
   * Determine basic stats by call list type
   * @param entityStats
   * @param callLists
   * @param window
   */
  constructor(entityStats: EntityStats, callLists: Map<string, CallList>, window: EntityStatsDisplayWindow) {
    super(entityStats, window);

    // Set Calls by Type
    this.setINum(entityStats, callLists);
  }

  private setINum(entityStats: EntityStats, callLists: Map<string, CallList>) {
    for (const [month, entityStatsChunk] of entityStats.getByMonth()) {
      if (!this.isMonthContainedInWindow(month)) {
        continue;
      }

      for (const [callListId, callStats] of entityStatsChunk.getCallList().entries()) {
        const keyName = callLists.get(callListId)?.getDisplayName() || callListId;
        const prev = this.iNum.get(keyName) || 0;
        this.iNum.set(keyName, prev + callStats.getINum());
      }
    }
  }
}
