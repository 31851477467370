import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';

import { SupportedLanguages, TemplateTextSchema } from '@pwp-common';

import { getFieldControlValueOrDefault } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { ObjEditor } from '../../../generic/abstract-classes/obj-editor';
import { TemplateTextLangPair } from '../generic/template-text-lang-pair';

@Component({
  selector: 'app-template-text-lang-pair-editor',
  templateUrl: './template-text-lang-pair-editor.component.html',
  styleUrls: ['./template-text-lang-pair-editor.component.css'],
})
export class TemplateTextLangPairEditorComponent extends ObjEditor<KVPair<TemplateTextLangPair>> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  possibleLanguages = SupportedLanguages.all;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parsedTemplateText = getFieldControlValueOrDefault(this.templateText, TemplateTextSchema.Defaults.text);
    const languageDefaults = SupportedLanguages.getDefaults(this.language.value);
    const value = new TemplateTextLangPair(languageDefaults, parsedTemplateText);
    return new KVPair({ id: this.obj.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<TemplateTextLangPair>) {
    // Init Form
    const formConfig = {} as any;
    formConfig.language = [obj.value!.languageDefaults.getShortCode(), [Validators.required]];
    formConfig.templateText = [obj.value!.templateText, [Validators.required]];

    this.form = this.formBuilder.group(formConfig);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get language(): AbstractControl | null {
    return this.form.get('language');
  }

  get templateText(): AbstractControl | null {
    return this.form.get('templateText');
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj() {
    if (!super.isValidObj()) {
      return false;
    }

    return true;
  }
}
