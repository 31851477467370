import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, isNil } from 'lodash';
import { distinct } from 'rxjs';

import { FormGroupControlValueAccessor } from '../../../../../generic/abstract-classes/form-group-control-value-accessor';
import { defaultReservationOfferEditorOutput } from '../../editor-output/reservation-offer-config/objects/offers/reservation-offer/default-reservation-offer-editor-output';
import { defaultTargetEditorOutput } from '../../editor-output/target/default-target-editor-output';
import { formToTargetEditorOutput } from '../../editor-output/target/form/form-to-target-editor-output/form-to-target-editor-output';
import { TargetEditorFormType } from '../../editor-output/target/form/form-to-target-editor-output/target-editor-form-type';
import { TargetEditorOutput } from '../../editor-output/target/target-editor-output';
import { defaultTargetMode } from '../../editor-output/target-mode/default-target-mode';
import { TaskReservationService } from '../../task-reservation/task-reservation.service';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TargetEditorComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => TargetEditorComponent),
  multi: true,
};

const workflowTargetTimeoutSecondsBaseValidators = [Validators.required, Validators.max(31 * 24 * 60 * 60)];

@UntilDestroy()
@Component({
  selector: 'app-target-editor',
  templateUrl: './target-editor.component.html',
  styleUrls: ['./target-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class TargetEditorComponent extends FormGroupControlValueAccessor<TargetEditorFormType, TargetEditorOutput> implements OnInit {
  ////////////////////////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////////////////////////

  baseObj: TargetEditorOutput = cloneDeep(defaultTargetEditorOutput);

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private taskReservationService: TaskReservationService,
    public changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  private trackTimeout(): void {
    this.taskReservationService.timeoutSeconds$.pipe(distinct(), untilDestroyed(this)).subscribe((value) => {
      const control = this.form.get('workflowTargetTimeoutSeconds');

      control.setValidators([...workflowTargetTimeoutSecondsBaseValidators, Validators.min(value)]);
      control.updateValueAndValidity();
    });
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.trackTimeout();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    this.form = new UntypedFormGroup({
      // Step: Mode
      mode: new UntypedFormControl(cloneDeep(defaultTargetMode), [Validators.required]),
      // Step: Workers
      workers: new UntypedFormControl([], [Validators.required, Validators.minLength(1), Validators.maxLength(50)]),
      // Step: Offers
      offers: new UntypedFormControl([cloneDeep(defaultReservationOfferEditorOutput)], [Validators.required, Validators.minLength(1)]),
      // Step: Context
      channelConfig: new UntypedFormControl(undefined, [Validators.required]),
      // Step: Skip If
      skipOffersIf: new UntypedFormControl(undefined, [Validators.required]),
      // Step: Timeout
      workflowTargetTimeoutSeconds: new UntypedFormControl(600, [...workflowTargetTimeoutSecondsBaseValidators, Validators.min(10)]),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////
  writeValue(value: TargetEditorOutput) {
    if (isNil(value)) {
      return;
    }

    /**
     * Save provided input so we can re-use the id
     */
    this.baseObj = value ?? cloneDeep(defaultTargetEditorOutput);

    /**
     * Update the form
     */
    const formValue: TargetEditorFormType = {
      mode: this.baseObj.mode,
      workers: this.baseObj.workers,
      offers: this.baseObj.offers,
      channelConfig: this.baseObj.channelConfig,
      skipOffersIf: this.baseObj.skipOffersIf,
      workflowTargetTimeoutSeconds: this.baseObj.workflowTargetTimeoutSeconds,
    };

    this.form.patchValue(formValue, { emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: TargetEditorFormType): TargetEditorOutput {
    const result = formToTargetEditorOutput(this.baseObj, value);
    return result;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'target-editor': this.form.value,
    };
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  ///////////////////////////////////////////////////////////////////////////////////////////

  get mode() {
    return this.form.get('mode') as UntypedFormControl;
  }

  get workers() {
    return this.form.get('workers') as UntypedFormControl;
  }

  get offers() {
    return this.form.get('offers') as UntypedFormControl;
  }

  get channelConfig() {
    return this.form.get('channelConfig') as UntypedFormControl;
  }

  get skipOffersIf() {
    return this.form.get('skipOffersIf') as UntypedFormControl;
  }

  get workflowTargetTimeoutSeconds() {
    return this.form.get('workflowTargetTimeoutSeconds') as UntypedFormControl;
  }
}
