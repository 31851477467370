import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { WorkersAutocompleteComponent } from './workers-autocomplete/workers-autocomplete.component';


@NgModule({
  declarations: [WorkersAutocompleteComponent],
  imports: [AutoCompleteModule, CommonModule, ReactiveFormsModule, TranslocoModule],
  exports: [WorkersAutocompleteComponent],
})
export class WorkersAutocompleteModule {}
