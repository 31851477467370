import {SerializableObject} from '../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {AssignedUserTypeStatsConstructor} from './assigned-user-type-stats-constructor';
import {AssignedUserTypeStatsSchema} from './assigned-user-type-stats-schema';
import {cloneDeep} from 'lodash';
import {Interval} from '../../../helper/interval';

/**
 * This class represents statistics about a collection of events of the same type.
 */
export class AssignedUserTypeStats extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected num!: number;
  protected durationMs!: number;
  protected numUsers!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Private Vars used for calculation
  /////////////////////////////////////////////////////////////////////////////

  private eventIds = new Set();
  private userIds = new Set();
  private durationWithoutOverlap = 0;
  private lastInterval: Interval = new Interval(0, 0);

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: AssignedUserTypeStatsConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): AssignedUserTypeStats {
    return new AssignedUserTypeStats(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(AssignedUserTypeStats.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AssignedUserTypeStatsSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getNum(): number {
    return cloneDeep(this.num);
  }

  public getDurationMs(): number {
    return cloneDeep(this.durationMs);
  }

  public getDurationHours(): number {
    return cloneDeep(this.durationMs / (3600 * 1000.0));
  }

  public getNumUsers(): number {
    return cloneDeep(this.numUsers);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Stats
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Update this object to include the given event with the given id and interval.
   * In order to operate efficiencly, this method assumes and verifies that it is
   * called with events ordered in ascending order by start time.
   *
   * @param eventId Event Identifier
   * @param eventInterval Interval specifying the start and end time in millis of this event
   */
  public updateWith(eventId: string, eventInterval: Interval, userId: string): AssignedUserTypeStats {
    // Do nothing if the event was already processed
    if (this.eventIds.has(eventId)) {
      return this;
    }

    // Add this event indicating it was processed.
    this.eventIds.add(eventId);

    // Sanity Check
    if (eventInterval.getStart() < this.lastInterval.getStart()) {
      console.error('AssignedUserTypeStats.updateWith: Error, intervals were passed in out of order', {
        lastInterval: this.lastInterval,
        newInterval: eventInterval,
      });
      throw new Error('AssignedUserTypeStats.updateWith: Error, intervals were passed in out of order');
    }

    // Update durationMs
    if (this.lastInterval.overlaps(eventInterval)) {
      this.lastInterval = this.lastInterval.convexHull(eventInterval);
    } else {
      this.durationWithoutOverlap = this.durationWithoutOverlap + this.lastInterval.getLength();
      this.lastInterval = eventInterval;
    }

    // Number
    this.num = this.eventIds.size;

    // Duration
    this.durationMs = this.durationWithoutOverlap + this.lastInterval.getLength();

    // Users
    this.userIds.add(userId);
    this.numUsers = this.userIds.size;

    return this;
  }
}
