import moment from 'moment-timezone';
import {GLOBAL_TIMEZONE} from '../../constants';

export const displayTime = (
  time: moment.Moment | undefined,
  timezone: string = GLOBAL_TIMEZONE,
  params?: {timeOnly?: boolean; showSeconds?: boolean; dateOnly?: boolean}
): string => {
  if (time === undefined) {
    return '';
  }
  if (params?.dateOnly === true) {
    return moment.tz(time, timezone).format('L');
  }

  let timeSuffix = '';
  if (params?.showSeconds === true) {
    timeSuffix = ':ss';
  }

  if (params?.timeOnly === true) {
    return moment.tz(time, timezone).format(`hh:mm${timeSuffix} A`);
  } else {
    return moment.tz(time, timezone).format(`ddd MMM D, h:mm${timeSuffix} A z`);
  }
};
