import {DeleteUserResponseSchema} from './delete-user-response-schema';
import {ResponseObj} from '../../generic/response/response-obj';

export class DeleteUserResponse extends ResponseObj {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: {error?: string}) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): DeleteUserResponse {
    return new DeleteUserResponse(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(DeleteUserResponse.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DeleteUserResponseSchema {
    return new DeleteUserResponseSchema();
  }
}
