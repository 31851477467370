import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';

export class CommunicationWorkflowDefaultFilterSchema extends SerializableObjectSchema {
  public static readonly queueId = 'queueId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly queueId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationWorkflowDefaultFilterSchema.queueId] = SchemaField.autoGeneratedDocId(
      CommunicationWorkflowDefaultFilterSchema.Defaults.queueId
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
