import { DBDocSchema } from '@pwp-common';

import { ServiceExceptionEditorOutput } from '../editor-output/service-exception-editor-output';

export const defaultServiceExceptionEditorOutput: ServiceExceptionEditorOutput = {
  id: DBDocSchema.GenericDefaults.id,
  displayName: '',
  description: '',
  e164PhoneMatchers: [],
  onCreateServiceCommands: { commands: [] },
};
