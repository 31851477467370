import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isEmpty, isEqual, isNil } from 'lodash';

import { parsePhones } from '../parse-phones/parse-phones';

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
const e164PhoneRegex = /^\+[1-9]\d{10,14}$/;

export class ArrayValidator {
  public static userEnteredPhoneNumber(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const { invalidPhones } = parsePhones(control.value ?? []);

      if (invalidPhones.length > 0) {
        return { invalidItems: invalidPhones };
      }

      return null;
    };
  }

  public static e164Phone(): ValidatorFn {
    return ArrayValidator.regex(e164PhoneRegex);
  }

  public static email(): ValidatorFn {
    return ArrayValidator.regex(emailRegex);
  }

  public static regex(regex: RegExp): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const invalidItems: string[] = [];
      for (const item of control.value) {
        if (isNil(item.match(regex))) {
          invalidItems.push(item);
        }
      }

      if (invalidItems.length > 0) {
        return { invalidItems };
      }

      return null;
    };
  }

  public static maxLength(length: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const errors: { value: string; length: number; expectedLength: number }[] = [];
      for (const item of control.value) {
        if (item.length > length) {
          errors.push({
            value: item,
            length: item.length,
            expectedLength: length,
          });
        }
      }

      if (errors.length > 0) {
        return errors;
      }

      return null;
    };
  }

  public static noDuplicatesDeepEquality(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const values: any[] = control.value;
      if (isEmpty(values)) {
        return null;
      }

      for (let i = 0; i < values.length; i++) {
        for (let j = i + 1; j < values.length; j++) {
          if (isEqual(values[i], values[j])) {
            return { num: control.value.length, firstDuplicateIndex: j };
          }
        }
      }
      return null;
    };
  }
}
