import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import {
  AnyCommunicationSessionEventLog,
  CommunicationSessionEventLogSendMessageNotification,
  getConversationSessionEventsCollection,
} from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from '../../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ConversationSessionEventLogService extends DbDocumentService<CommunicationSessionEventLogSendMessageNotification> {
  private conversationLogId?: string;

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, AnyCommunicationSessionEventLog);
  }

  private resetConversationLogId() {
    this.conversationLogId = undefined;
  }

  protected getLoggingInfo(): string {
    return `collection=${getConversationSessionEventsCollection(this.orgId, this.conversationLogId)}`;
  }

  public setConversationLogId(conversationLogId: string) {
    this.conversationLogId = conversationLogId;
  }

  protected getCollectionName(orgId: string): string {
    if (!this.conversationLogId) {
      throw new Error('User Error: Please call setConversationLogId() before each run of this function.');
    }

    const collectionName = getConversationSessionEventsCollection(orgId, this.conversationLogId);

    this.resetConversationLogId();

    return collectionName;
  }
}
