import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';

let remoteConfig: firebase.remoteConfig.RemoteConfig;

@Injectable({
  providedIn: 'root',
})
export class RemoteConfigService {
  constructor() {}

  initialize(): Observable<void> {
    remoteConfig = firebase.remoteConfig();
    // remoteConfig.settings.minimumFetchIntervalMillis = 3600000;
    remoteConfig.settings.minimumFetchIntervalMillis = 1000;
    remoteConfig.defaultConfig = {};

    return from(
      remoteConfig
        .fetchAndActivate()
        .then(() => null)
        .catch((err) => {
          console.error('RemoteConfigService.initialize: Error', err);
        }),
    );
  }

  public getSupportUsers(): string[] {
    const supportStr = remoteConfig?.getValue('support').asString() || '{"authorized": []}';
    return JSON.parse(supportStr).authorized;
  }
}
