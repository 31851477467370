import firebase from 'firebase/compat/app';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

//////////////////////////////////////////////////////////////////////////////////////////
// Write an batch contained inside observable
//////////////////////////////////////////////////////////////////////////////////////////

/**
 * Commit a firebase batch transaction wrapped in an observable. Log and re-throw any errors.
 * @param batchObservable Observable of a write batch
 * @param logString If specified, include this string in log statments.
 */
export const commitBatchObservable = (batchObservable: Observable<firebase.firestore.WriteBatch>, logString?: string): Observable<void> => {
  const parsedLogString = logString === undefined ? '' : logString;
  const observable = batchObservable.pipe(
    switchMap((batch, _) => from(batch.commit())),
    catchError((err, caught) => {
      console.error(`commitBatchObservable:${parsedLogString} err`);
      console.error(`commitBatchObservable:${parsedLogString} Error`);
      console.error(err);
      console.error(`commitBatchObservable:${parsedLogString} caught`);
      console.error(caught);
      throw err;
    }),
  );
  return observable;
};
