import { Component, OnInit } from '@angular/core';
import { loadingFor } from '@ngneat/loadoff';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { ConversationConfig, ConversationLog } from '@pwp-common';

import { ConversationConfigService } from '../../services/conversation/conversation-config/conversation-config.service';

@UntilDestroy()
@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent implements OnInit {
  loader = loadingFor('conversationConfig');

  conversationConfig: Observable<ConversationConfig>;

  conversationLogs: ConversationLog[];

  constructor(private conversationConfigService: ConversationConfigService) {}

  ngOnInit(): void {
    this.conversationConfig = this.conversationConfigService
      .getUniqueDoc({ query: [] })
      .pipe(this.loader.conversationConfig.track(), untilDestroyed(this));
  }
}
