import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';

import { VRCAudioBlobLocation } from '@pwp-common';

import { BlobStorageService } from '../../generic/blob-storage-service';

@Injectable({
  providedIn: 'root',
})
export class VRCAudioBlobService extends BlobStorageService {
  // @ts-ignore
  constructor(private _storage: AngularFireStorage) {
    super(_storage);
  }

  protected getBlobLocation() {
    return new VRCAudioBlobLocation();
  }
}
