import { isNil } from 'lodash';

import { PTableCol } from '../../../../../common/p-table/p-table-col';

export const getGlobalFilterFields = (cols: PTableCol[]): string[] => {
  if (isNil(cols)) {
    return [];
  }

  const globalFilterFields: string[] = [];
  for (const col of cols) {
    if (!Object.prototype.hasOwnProperty.call(col, 'sortValue')) {
      globalFilterFields.push(`${col.field}.sortValue`);
    } else {
      console.error('getGlobalFilterFields: col does not have sortValue', col, cols);
    }
  }

  return globalFilterFields;
};
