import { Component, OnChanges } from '@angular/core';
import { uniq } from 'lodash';

import { SSMLAudio, SupportedLanguages } from '@pwp-common';

import { ObjListEditor } from '../../../generic/abstract-classes/obj-list-editor';
import { FileSSMLAudioPair } from '../generic/file-ssml-audio-pair';

@Component({
  selector: 'app-file-ssml-audio-select-and-edit',
  templateUrl: './file-ssml-audio-select-and-edit.component.html',
  styleUrls: ['./file-ssml-audio-select-and-edit.component.css'],
})
export class FileSsmlAudioSelectAndEditComponent extends ObjListEditor<FileSSMLAudioPair> implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Validation
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  objBuilder() {
    return new FileSSMLAudioPair(SupportedLanguages.en, SSMLAudio.deserialize({}), undefined);
  }

  isValidList(): boolean {
    // This checks that the form is valid.
    if (!super.isValidList()) {
      return false;
    }

    if (this.objList.length === 0) {
      return false;
    }

    // Check that each item represents a distinct language
    const numDistinctLangs = uniq(this.objList.map((z) => z.languageDefaults.getShortCode())).length;
    if (numDistinctLangs !== this.objList.length) {
      return false;
    }

    // Check that all FileSSML pairs are valid
    for (const fileSSMLPair of this.objList) {
      if (!fileSSMLPair.isValid()) {
        return false;
      }
    }

    return true;
  }
}
