import { ChangeDetectorRef, Directive, forwardRef, Input, OnInit, Provider } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { Observable, of } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

import { GenericDisplayable } from '@pwp-common';

import { ObjIdValidator } from '../../../common/validators/obj-id-validator/obj-id-validator';

import { FormGroupControlValueAccessor } from './form-group-control-value-accessor';

export const getObjAutocompleteFormControlBaseProviders = (component: any): Provider[] => {
  const VALUE_ACCESSOR: Provider = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => component),
    multi: true,
  };

  const VALIDATOR: Provider = {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => component),
    multi: true,
  };

  return [VALUE_ACCESSOR, VALIDATOR];
};

@UntilDestroy()
@Directive()
// eslint-disable-next-line @angular-eslint/component-class-suffix
export abstract class ObjAutocompleteFormControlBase<T extends GenericDisplayable>
  extends FormGroupControlValueAccessor<any, any>
  implements OnInit
{
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() placeholder: string;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected cachedAllOptions$: Observable<T[]> = of([]);

  public allOptions$: Observable<T[]> = of([]);

  public suggestedOptions$: Observable<T[]> = of([]);

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.allOptions$ = this.defineAllOptions();
    this.cachedAllOptions$ = this.allOptions$.pipe(
      map((options) => {
        this.control.addValidators(ObjIdValidator.objIdInListSync(options));
        return options;
      }),
      untilDestroyed(this),
      shareReplay(1),
    );
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    this.form = new UntypedFormGroup({
      control: new UntypedFormControl(),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  public writeValue(objId: any): void {
    this.selectObjWithId(objId);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): any {
    const controlValue = value.control;
    if (isNil(controlValue)) {
      return undefined;
    }
    if (typeof controlValue === 'string') {
      return controlValue;
    }

    // Value is an object of type T
    const objId = controlValue.getId();
    return objId;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get control() {
    return this.form.get('control') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Select Obj With Id
  /////////////////////////////////////////////////////////////////////////////////////////////

  private selectObjWithId(objId: string) {
    return this.cachedAllOptions$
      .pipe(
        map((optionsArray) => {
          for (const option of optionsArray) {
            if (option?.getId() === objId) {
              this.control.setValue(option, { emitEvent: false });
              this.changeDetectorRef.detectChanges();
              return;
            }
          }
          this.control.setValue(objId, { emitEvent: false });
          this.changeDetectorRef.detectChanges();
        }),
        untilDestroyed(this),
        take(1),
      )
      .subscribe();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Autocomplete
  /////////////////////////////////////////////////////////////////////////////////////////////

  search(event: { originalEvent: PointerEvent; query: string }) {
    this.suggestedOptions$ = this.allOptions$.pipe(
      map((allOptionsArray) =>
        allOptionsArray.filter((z) =>
          JSON.stringify([(z as any).serialize()])
            .toLowerCase()
            .includes(event.query.toLowerCase()),
        ),
      ),
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'obj-autocomplete-form-control-base': this.form.value,
    };
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  /////////////////////////////////////////////////////////////////////////////////////////////

  public abstract defineAllOptions(): Observable<T[]>;
}
