import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { isNil } from 'lodash';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { from, map, switchMap } from 'rxjs';
import { SurveyModule } from 'survey-angular-ui';
import { CompleteEvent, Model, settings } from 'survey-core';

import { configureSurveyJS, FormResponseCreateSubmission } from '@pwp-common';

import { StylesService } from '../../../ui/styles/styles.service';
import { FormAutocompleteComponent } from '../form-autocomplete/form-autocomplete.component';
import { FormSubmissionService } from '../services/form-submission.service';
import { FormVersionService } from '../services/form-version.service';

import { SaveSurveyResultInput } from './interfaces';

configureSurveyJS(settings);

@Component({
  selector: 'app-form-submission-dialog',
  standalone: true,
  imports: [CommonModule, FormAutocompleteComponent, FormsModule, ReactiveFormsModule, SurveyModule, TranslocoModule],
  templateUrl: './form-submission-dialog.component.html',
  styleUrls: ['./form-submission-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormSubmissionDialogComponent {
  private readonly data = inject(DynamicDialogConfig).data;

  private readonly dialogRef = inject(DynamicDialogRef);

  private readonly formSubmissionService = inject(FormSubmissionService);

  private readonly formVersionService = inject(FormVersionService);

  private readonly stylesService = inject(StylesService);

  private readonly translocoService = inject(TranslocoService);

  public readonly selectedForm = new FormControl<string | null>(null);

  public readonly selectedFormModel$ = this.selectedForm.valueChanges.pipe(switchMap((formId) => this.createSurveyModel(formId)));

  public readonly stylesLoaded$ = from(this.stylesService.lazyLoadGlobalStyle('survey-creator')).pipe(map(() => true));

  private displaySubmissionError(options: CompleteEvent): void {
    options.showSaveError(this.translocoService.translate('form-submission-dialog.formSubmissionError'));
  }

  private closeRefWithSuccess(): void {
    this.dialogRef.close({
      message: {
        severity: 'success',
        summary: this.translocoService.translate('form-submission-dialog.formSubmissionSuccess.summary'),
        detail: this.translocoService.translate('form-submission-dialog.formSubmissionSuccess.detail'),
      },
    });
  }

  private handleFormSubmissionResponse(response: FormResponseCreateSubmission, options: CompleteEvent): void {
    if (response.getError()) {
      this.displaySubmissionError(options);
    } else {
      this.closeRefWithSuccess();
    }
  }

  private saveSurveyResult = async ({ survey, options, formVersion }: SaveSurveyResultInput): Promise<void> => {
    options.showSaveInProgress(this.translocoService.translate('form-submission-dialog.formSubmissionInProgress'));

    const response = await this.formSubmissionService.submitForm({
      formVersionId: formVersion.getId(),
      data: survey.data,
      sessionId: this.data.sessionId,
    });

    this.handleFormSubmissionResponse(response, options);
  };

  private async createSurveyModel(formId: string | null): Promise<Model | null> {
    if (isNil(formId)) {
      return null;
    }

    const formVersion = await this.formVersionService.getActiveFormVersion(formId);

    const model = new Model(formVersion.getFormSchema());
    model.onComplete.add(async (survey, options) => this.saveSurveyResult({ formVersion, survey, options }));

    return model;
  }
}
