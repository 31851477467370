import { Component } from '@angular/core';

import { IVROption } from '@pwp-common';

import { ObjListEditor } from '../../../generic/abstract-classes/obj-list-editor';

@Component({
  selector: 'app-ivr-option-select-and-edit',
  templateUrl: './ivr-option-select-and-edit.component.html',
  styleUrls: ['./ivr-option-select-and-edit.component.css'],
})
export class IvrOptionSelectAndEditComponent extends ObjListEditor<IVROption> {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Validation
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  objBuilder() {
    return IVROption.deserialize({});
  }

  isValidList(): boolean {
    // This checks that the form is valid.
    if (!super.isValidList()) {
      return false;
    }

    if (this.objList.length === 0) {
      return false;
    }

    return true;
  }
}
