import type {AnySchema} from 'joi';

import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {surveyJSJsonSchema} from '../../generic/serialization/schema-field/survey-js-form/survey-js-json-schema';
import {FormBuilderVendor} from '../form-builder-vendor';

export class FormVersionSchema extends DBDocSchema {
  public static readonly formId = 'formId';

  public static readonly formSchema = 'formSchema';

  public static readonly type = 'type';

  public static readonly Constants = class {
    public static readonly collection = 'formVersions';
  };

  public static readonly Defaults = class {
    public static readonly formId = undefined;
    public static readonly formSchema = undefined;
    public static readonly type = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[FormVersionSchema.formId] = SchemaField.autoGeneratedDocId(FormVersionSchema.Defaults.formId);
    parameters[FormVersionSchema.formSchema] = surveyJSJsonSchema(FormVersionSchema.Defaults.formSchema);
    parameters[FormVersionSchema.type] = SchemaField.string(FormVersionSchema.Defaults.type).valid(...Object.values(FormBuilderVendor));

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${FormVersionSchema.Constants.collection}`;
  }
}
