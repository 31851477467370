import {cloneDeep, uniq, orderBy} from 'lodash';
import {RolesSchema} from './roles-schema';
import {PossibleRole} from './types';

export const ALL_POSSIBLE_ROLES = [
  RolesSchema.Roles.conversation,
  RolesSchema.Roles.orgAdmin,
  RolesSchema.Roles.support,
  RolesSchema.Roles.voicemail,
] as const;
/**
 * Given a list of possible roles, return the minimal set
 * that endow the same permissions.
 * @param roles
 */
export const simplifyRoles = (roles: string[]): PossibleRole[] => {
  let _roles = cloneDeep(uniq(roles.filter(z => isPossibleRole(z, false))));

  if (_roles.length === 0) {
    return [];
  }

  if (_roles.includes(RolesSchema.Roles.orgAdmin)) {
    _roles = _roles.filter(z => z !== RolesSchema.Roles.voicemail);
  }

  if (_roles.includes(RolesSchema.Roles.support) && !_roles.includes(RolesSchema.Roles.orgAdmin)) {
    throw new Error('simplifyRoles: User error, you are prohibited from giving support privileges to non org admin');
  }

  return orderBy(_roles) as PossibleRole[];
};

export const isPossibleRole = (role: string, throwOnError = true): boolean => {
  if (ALL_POSSIBLE_ROLES.includes(role as any)) {
    return true;
  }
  if (throwOnError) {
    throw new Error(`isPossibleRole: Invalid role: ${role}`);
  }
  return false;
};
