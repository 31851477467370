import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { cloneDeep, isEqual, isNil } from 'lodash';

import { Displayable } from '@pwp-common';

import { getCompleteKVPair, KVPair, KVPairConstructor, kvPairEqual } from '../../../common/objects/kvpair';
import { NgChanges } from '../../../common/objects/ng-changes';

@Directive()
export abstract class ChooseObjsForm<T extends Displayable> implements OnChanges, OnDestroy {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs / Outputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() label = '';

  @Input() orderedItems: T[];

  @Input() kvPairs: KVPair<T>[] | undefined;

  @Output() kvPairsChange = new EventEmitter<KVPair<T>[] | undefined>();

  private lastEmittedValue: any;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnChanges(changes: NgChanges<ChooseObjsForm<any>>) {
    if (!isNil(this.orderedItems)) {
      if (!isNil(changes.orderedItems)) {
        this.setFormControl(this.kvPairs, true);
        return;
      }

      if (!isNil(changes.kvPairs)) {
        this.setFormControl(this.kvPairs, true);
        return;
      }
    }
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Compare
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public compareObjs(o1: KVPair<T> | undefined, o2: KVPair<T> | undefined): boolean {
    return kvPairEqual(o1, o2);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Callbacks
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public selectionChanged(kvPairConstructors: KVPairConstructor<T>[]) {
    this.kvPairs = kvPairConstructors.map((z) => new KVPair(z));
    this.emitObj();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Update the form control with the given value (if specified). If
   * a string is given, then update the form with the object with this
   * id instead.
   */
  protected setFormControl(partialKVPairs: KVPair<T>[], emitChange = true) {
    const completePairs: KVPair<T>[] = partialKVPairs.map((z) => getCompleteKVPair(z, this.orderedItems));

    this.getFormControl().setValue(completePairs);
    this.kvPairs = completePairs;
    if (emitChange) {
      this.emitObj();
    }
  }

  protected emitObj() {
    if (isEqual(this.kvPairs, this.lastEmittedValue)) {
      return;
    }

    if (isNil(this.kvPairs)) {
      this.kvPairsChange.emit(this.kvPairs);
      this.lastEmittedValue = cloneDeep(this.kvPairs);
      return;
    }

    // Can't get complete kvPair if ordered items is empty
    if (isNil(this.orderedItems)) {
      return;
    }

    const completePairs: KVPair<T>[] = this.kvPairs.map((z) => getCompleteKVPair(z, this.orderedItems));

    this.kvPairsChange.emit(completePairs);
    this.lastEmittedValue = cloneDeep(completePairs);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public abstract getFormControl(): UntypedFormControl;
}
