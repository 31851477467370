import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {ConversationWaitingRoom} from '../conversation-waiting-room/conversation-waiting-room';
import {Identity} from '../../identity/identity/identity';

export class ConversationAttributesSchema extends SerializableObjectSchema {
  public static readonly externalParticipantIdentity = 'externalParticipantIdentity';
  public static readonly redirectURL = 'redirectURL';
  public static readonly waitingRoom = 'waitingRoom';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly externalParticipantIdentity = undefined;
    public static readonly redirectURL = undefined;
    public static readonly waitingRoom = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ConversationAttributesSchema.externalParticipantIdentity] = SchemaField.obj(Identity, ConversationAttributesSchema.Defaults.externalParticipantIdentity).optional();
    parameters[ConversationAttributesSchema.redirectURL] = SchemaField.uri(ConversationAttributesSchema.Defaults.redirectURL).optional();
    parameters[ConversationAttributesSchema.waitingRoom] = SchemaField.obj(ConversationWaitingRoom, ConversationAttributesSchema.Defaults.waitingRoom).optional();
    /* eslint-enable */

    return parameters;
  }
}
