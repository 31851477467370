import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessagesModule } from 'primeng/messages';
import { SelectButtonModule } from 'primeng/selectbutton';

import { OfferConfigRingTimeEditorComponent } from './editor/offer-config-ring-time-editor/offer-config-ring-time-editor.component';

@NgModule({
  declarations: [OfferConfigRingTimeEditorComponent],
  imports: [
    CheckboxModule,
    CommonModule,
    DropdownModule,
    FormsModule,
    InputNumberModule,
    MessagesModule,
    ReactiveFormsModule,
    SelectButtonModule,
    SharedModule,
    TranslocoModule,
  ],
  exports: [OfferConfigRingTimeEditorComponent],
})
export class CallListOfferConfigRingTimeModule {}
