import { isEqual, isNil } from 'lodash';

import { getTaskQueueIds, isNilOrDefaultDocId } from '@pwp-common';

import { WorkflowEditorToDbTransactionInput } from './types';

/**
 * Sanity check the inputs for a workflow transaction
 *
 */
export const sanityCheck = ({ original, update, deleteWorkflowRequested }: WorkflowEditorToDbTransactionInput): void => {
  if (isNil(update?.editorResult) && deleteWorkflowRequested !== true) {
    console.error({ original, update, deleteWorkflowRequested });
    throw new Error('sanityCheck: The editor result is empty, but delete was not requested');
  }

  /***********************************************************************************************************
   * Ensure a bijective relationship between queues specified in original.taskQueues and
   * original.workflow.
   **********************************************************************************************************/
  // Determine queues specified in the given map
  const originalTaskQueues = new Set<string>();
  for (const [key, queue] of original?.taskQueues ?? new Map()) {
    if (key !== queue.getId()) {
      console.error('sanityCheck', original, update);
      throw new Error('sanityCheck: User error. The key does not match the task queue id');
    }
    if (isNilOrDefaultDocId(queue.getId())) {
      console.error('sanityCheck', original, update);
      throw new Error('sanityCheck: User error. Default docIds are not part of the DB. Invalid input');
    }
    originalTaskQueues.add(queue.getId());
  }

  // Determine queues specified by the workflow
  const workflowTaskQueues = getTaskQueueIds(original?.workflow);

  if (!isEqual(originalTaskQueues, workflowTaskQueues)) {
    console.error('sanityCheck', original, update);
    throw new Error('sanityCheck: User error. There is a discrepancy between queues specified in the map and in the original workflow');
  }

  /***********************************************************************************************************
   * Ensure that the workflow id did not change, if it is specified.
   **********************************************************************************************************/
  if (!isNil(original?.workflow) && !isNil(update?.editorResult)) {
    if (isNilOrDefaultDocId(original.workflow?.getId())) {
      console.error('sanityCheck', original, update);
      throw new Error('sanityCheck: User error. How can the original workflow id be a default id?');
    }
    if (original.workflow.getId() !== update.editorResult.id) {
      console.error('sanityCheck', original, update);
      throw new Error('sanityCheck: User error. The workflow doc ids do not match');
    }
  }
  if (!isNilOrDefaultDocId(update.editorResult?.id)) {
    if (isNilOrDefaultDocId(original.workflow?.getId())) {
      console.error('sanityCheck', original, update);
      throw new Error('sanityCheck: User error. We can not update a workflow unless you provide the original workflow as well.');
    }
  }
};
