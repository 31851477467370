<ng-container *transloco="let t; read: 'caller-info-dialog'">
  <p-toast position="bottom-center"></p-toast>

  <legend>
    {{ t('dialogHeader') }}
  </legend>

  <app-progress-spinner *ngIf="loading"></app-progress-spinner>

  <div *ngIf="!loading && communicationsResponseGetCallLogPII?.getError() !== undefined">
    {{ t('nothingToShow') }}
  </div>

  <div *ngIf="!loading && communicationsResponseGetCallLogPII?.getError() === undefined">
    <!-- Phone Number -->
    <div class="field grid">
      <label class="caller-info-dialog__field-label" for="e164Phone" style="width: 200px">
        {{ t('e164PhoneLabel') }}
        <i
          aria-hidden="true"
          class="pi pi-question-circle caller-info-dialog__tooltip-icon"
          tooltipPosition="right"
          [pTooltip]="t('e164PhoneTooltip')"
          [tooltipStyleClass]="'p-tooltip--large'"
        ></i>
      </label>
      <div class="col">
        <input
          pInputText
          class="full-width"
          id="e164Phone"
          type="text"
          value="{{ communicationsResponseGetCallLogPII?.getCallerPhone() }}"
          [disabled]="true"
        />
      </div>
    </div>

    <!-- Caller Name -->
    <div class="field grid">
      <label for="callerName" style="width: 200px">{{ t('callerNameLabel') }}</label>
      <div class="col">
        <input
          pInputText
          class="full-width"
          id="callerName"
          type="text"
          value="{{ communicationsResponseGetCallLogPII?.getCallerName() }}"
          [disabled]="true"
        />
      </div>
    </div>

    <!-- Caller Type -->
    <div class="field grid">
      <label for="callerType" style="width: 200px">{{ t('callerTypeLabel') }}</label>
      <div class="col">
        <input
          pInputText
          class="full-width"
          id="callerType"
          type="text"
          value="{{ communicationsResponseGetCallLogPII?.getCallerType() }}"
          [disabled]="true"
        />
      </div>
    </div>

    <!-- Carrier Name -->
    <div class="field grid">
      <label for="carrierName" style="width: 200px">{{ t('carrierNameLabel') }}</label>
      <div class="col">
        <input
          pInputText
          class="full-width"
          id="carrierName"
          type="text"
          value="{{ communicationsResponseGetCallLogPII?.getCarrierName() }}"
          [disabled]="true"
        />
      </div>
    </div>

    <!-- Carrier Type -->
    <div class="field grid">
      <label for="carrierType" style="width: 200px">{{ t('carrierTypeLabel') }}</label>
      <div class="col">
        <input
          pInputText
          class="full-width"
          id="carrierType"
          type="text"
          value="{{ communicationsResponseGetCallLogPII?.getCarrierType() }}"
          [disabled]="true"
        />
      </div>
    </div>

    <!-- Country Code -->
    <div class="field grid">
      <label for="countryCode" style="width: 200px">{{ t('countryCodeLabel') }}</label>
      <div class="col">
        <input
          pInputText
          class="full-width"
          id="countryCode"
          type="text"
          value="{{ communicationsResponseGetCallLogPII?.getISO3166Alpha2CountryCode() }}"
          [disabled]="true"
        />
      </div>
    </div>

    <!-- Spoofing Warning -->
    <div class="field grid">
      <p *ngIf="communicationsResponseGetCallLogPII?.getShakenPassport() === undefined">{{ t('stirShakenMissing') }}</p>
    </div>
  </div>

  <div>
    <div class="row">
      <div *ngIf="!loading" class="col flex justify-content-start align-items-end flex-wrap">
        {{ t('dialogFooter', { dataRetentionEnd: dataRetentionEndFormatted }) }}
      </div>
      <div class="col flex justify-content-end flex-wrap">
        <p-button icon="pi pi-check" label="{{ t('closeButtonLabel') }}" styleClass="p-button-text" (click)="closeDialog()"></p-button>
      </div>
    </div>
  </div>
</ng-container>
