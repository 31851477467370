import { Component, OnInit } from '@angular/core';
import { loadingFor } from '@ngneat/loadoff';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

import { SubmitTicketService } from '../../services/submit-ticket/submit-ticket.service';

@Component({
  providers: [MessageService],
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
})
export class SupportComponent implements OnInit {
  public isSupportEnabled: Observable<boolean>;

  loader = loadingFor('isSupportEnabled');

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////////////////////////

  constructor(private submitTicketService: SubmitTicketService) {}

  ngOnInit(): void {
    this.isSupportEnabled = this.submitTicketService.isSupportEnabled().pipe(this.loader.isSupportEnabled.track());
  }
}
