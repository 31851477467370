import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class StylesService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public lazyLoadGlobalStyle(filename: string): Promise<void> {
    return new Promise((resolve) => {
      const { head } = this.document;
      const id = `style-${filename}`;
      const existingStylesheet = this.document.getElementById(id);

      if (!isNil(existingStylesheet)) {
        resolve();
        return;
      }

      const style = Object.assign(this.document.createElement('link'), {
        id,
        rel: 'stylesheet',
        href: `${filename}.css`,
        onload: () => resolve(),
      });

      head.appendChild(style);
    });
  }
}
