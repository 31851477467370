import { Participant } from '@twilio/conversations';

import { RoomUser } from './chat-component/interfaces';

export const EMPTY_ROOM_USER: RoomUser = {
  _id: 'missing',
  username: '',
  avatar: 'assets/imgs/doe.png',
  status: {
    state: 'online',
    lastChanged: 'today, 14:30',
  },
};

export const getDisplayableParticipant = (participant: Participant): RoomUser => {
  const user: RoomUser = {
    _id: participant.sid,
    username: participant.identity,
    avatar: 'assets/imgs/doe.png',
    status: {
      state: 'online',
      lastChanged: 'today, 14:30',
    },
  };

  return user;
};
