import {SchemaField} from '../../../../../../generic/serialization/schema-field';
import {ReservationSkipOffersIfRuleSchema} from '../../generic/reservation-skip-offers-if-rule/reservation-skip-offers-if-rule-schema';

export class ReservationSkipOffersIfRuleAvailabilitySchema extends ReservationSkipOffersIfRuleSchema {
  public static readonly skipIfUnavailable = 'skipIfUnavailable';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly skipIfUnavailable = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ReservationSkipOffersIfRuleAvailabilitySchema.skipIfUnavailable] = SchemaField.boolean(ReservationSkipOffersIfRuleAvailabilitySchema.Defaults.skipIfUnavailable).required()
    /* eslint-enable */

    return parameters;
  }
}
