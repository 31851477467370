import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ChipsModule } from 'primeng/chips';

import { ArrayOfPhonesEditorComponent } from './array-of-phones-editor/array-of-phones-editor.component';

@NgModule({
  declarations: [ArrayOfPhonesEditorComponent],
  imports: [ChipsModule, CommonModule, ReactiveFormsModule, TranslocoModule],
  exports: [ArrayOfPhonesEditorComponent],
})
export class ArrayOfPhonesEditorModule {}
