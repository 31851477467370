import { isNil } from 'lodash';

import { OfferChannelConfigPhoneCallMachineDetection } from '@pwp-common';

import { OfferConfigRingTimeEditorOutput } from '../interfaces';

export const editorOutputToDb = (params: {
  offerConfigHoldTime?: OfferConfigRingTimeEditorOutput;
}): OfferChannelConfigPhoneCallMachineDetection | undefined => {
  const { offerConfigHoldTime } = { ...params };

  /**************************************************************************************
   * Handle empty case
   **************************************************************************************/
  if (isNil(offerConfigHoldTime)) {
    return;
  }

  const machineDetection = new OfferChannelConfigPhoneCallMachineDetection({
    hangupOnMachine: offerConfigHoldTime.hangUpOnVoiceMail,
  });

  return machineDetection;
};
