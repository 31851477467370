<ng-container *transloco="let t; read: 'communication-workflow-select'">
  <p-autoComplete
    appendTo="body"
    dropdownAriaLabel="{{ t('autocompleteDropdownLabel') }}"
    field="displayName"
    [dropdown]="true"
    [formControl]="control"
    [ngClass]="{ 'ng-invalid': control?.invalid }"
    [placeholder]="placeholder"
    [suggestions]="suggestedOptions$ | async"
    (completeMethod)="search($event)"
  >
    <ng-template pTemplate="item" let-value>
      <mat-icon>build</mat-icon>
      <b class="wrap">{{ value.getDisplayName() }}</b>
      <br />
      <small class="wrap">{{ value.getDescription() }}</small>
    </ng-template>
    <ng-template pTemplate="selectedItem" let-value>
      <mat-icon>build</mat-icon>
      <span class="wrap">{{ value.getDisplayName() }}</span>
    </ng-template>
  </p-autoComplete>
  <p *ngIf="control.hasError('objIdNotInList')" class="p-error">{{ t('controlErrorObjIdNotInList') }}</p>
</ng-container>
