<app-org-id-change></app-org-id-change>

<ng-template #objEditor>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Display Name -->
        <mat-form-field class="full-width">
          <textarea matInput placeholder="Display Name" class="full-width" formControlName="displayName"></textarea>
          <span *ngIf="displayName.invalid && displayName.touched"> Display Name Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Timezone -->
        <mat-form-field class="full-width">
          <mat-label>Timezone</mat-label>
          <mat-select formControlName="timezone">
            <mat-option *ngFor="let v of possibleTimezones" [value]="v">{{ v }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- E164 Phones -->
        <label>
          Forwarded From E164Phones
          <p-chips
            class="full-width"
            formControlName="e164Phones"
            [addOnBlur]="true"
            [addOnTab]="true"
            [allowDuplicate]="false"
            [placeholder]="'E164 Phone'"
          ></p-chips>
        </label>
      </div>
    </div>

    <!-- Event Types -->
    <ng-container formArrayName="eventTypes">
      <h6>Event Types</h6>
      <div *ngFor="let _ of eventTypes.controls; index as i">
        <mat-form-field>
          <ng-container [formGroupName]="i">
            <input matInput placeholder="Internal Name" formControlName="internalName" />
            <input matInput placeholder="Event Display Name" formControlName="displayName" />
          </ng-container>
        </mat-form-field>

        <button mat-icon-button (click)="removeEventType(i)">
          <mat-icon matListIcon> delete </mat-icon>
        </button>
        <button mat-icon-button (click)="addEventType(i)">
          <mat-icon matListIcon> add </mat-icon>
        </button>
        <button *ngIf="i > 0" mat-icon-button (click)="moveEventTypeDown(i)">
          <mat-icon matListIcon> swap_vert </mat-icon>
        </button>
      </div>
    </ng-container>
  </form>
</ng-template>

<app-obj-select-and-edit
  [doDelete]="doDelete"
  [doRefresh]="doRefresh"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [label]="'Org Data'"
  [modifiedSelectedItem]="getUpdatedObj()"
  [objIsValid]="isValidObjScoper"
  [orderedItems]="itemsArray | async"
  [selectedKVPair]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (selectedKVPairChange)="onSelectedKVPairChange($event)"
>
</app-obj-select-and-edit>

<mat-divider></mat-divider>

<mat-accordion>
  <!-- Event Config -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Communication Widget Create Async Service Request </mat-panel-title>
    </mat-expansion-panel-header>
    <app-communication-widget-create-async-service-request-select-and-edit
      *ngIf="!loading && selectedKVPair !== undefined"
      [orgData]="selectedKVPair?.value"
    ></app-communication-widget-create-async-service-request-select-and-edit>
  </mat-expansion-panel>

  <!-- Event Config -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Event Config </mat-panel-title>
    </mat-expansion-panel-header>
    <app-event-config-select-and-edit
      *ngIf="!loading && selectedKVPair !== undefined"
      [orgData]="selectedKVPair?.value"
    ></app-event-config-select-and-edit>
  </mat-expansion-panel>

  <!-- Email Template Config -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Email Template Config </mat-panel-title>
    </mat-expansion-panel-header>
    <app-email-template-config-select-and-edit
      *ngIf="!loading && selectedKVPair !== undefined"
      [orgData]="selectedKVPair?.value"
    ></app-email-template-config-select-and-edit>
  </mat-expansion-panel>

  <!-- Call Entrypoints -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Call Entrypoints </mat-panel-title>
    </mat-expansion-panel-header>
    <app-call-entrypoints-config-select-and-edit
      *ngIf="!loading && selectedKVPair !== undefined"
      [orgData]="selectedKVPair?.value"
    ></app-call-entrypoints-config-select-and-edit>
  </mat-expansion-panel>

  <!-- Caller Ids -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Caller Ids </mat-panel-title>
    </mat-expansion-panel-header>
    <app-caller-id-select-and-edit
      *ngIf="!loading && selectedKVPair !== undefined"
      [orgData]="selectedKVPair?.value"
    ></app-caller-id-select-and-edit>
  </mat-expansion-panel>

  <!-- Call List -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Call Lists </mat-panel-title>
    </mat-expansion-panel-header>
    <app-call-list-select-and-edit
      *ngIf="!loading && selectedKVPair !== undefined"
      [orgData]="selectedKVPair?.value"
    ></app-call-list-select-and-edit>
  </mat-expansion-panel>

  <!-- Conversation Config -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Conversation Config </mat-panel-title>
    </mat-expansion-panel-header>
    <app-conversation-config-select-and-edit
      *ngIf="!loading && selectedKVPair !== undefined"
      [orgData]="selectedKVPair?.value"
    ></app-conversation-config-select-and-edit>
  </mat-expansion-panel>

  <!-- IVRs -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> IVRs </mat-panel-title>
    </mat-expansion-panel-header>
    <app-ivr-select-and-edit *ngIf="!loading && selectedKVPair !== undefined" [orgData]="selectedKVPair?.value"></app-ivr-select-and-edit>
  </mat-expansion-panel>

  <!-- Message Templates -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Message Templates </mat-panel-title>
    </mat-expansion-panel-header>
    <app-message-template-select-and-edit
      *ngIf="!loading && selectedKVPair !== undefined"
      [orgData]="selectedKVPair?.value"
    ></app-message-template-select-and-edit>
  </mat-expansion-panel>

  <!-- Voicemail Config -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Voicemail Config </mat-panel-title>
    </mat-expansion-panel-header>
    <app-voicemail-config-select-and-edit
      *ngIf="!loading && selectedKVPair !== undefined"
      [orgData]="selectedKVPair?.value"
    ></app-voicemail-config-select-and-edit>
  </mat-expansion-panel>

  <!-- VRC Audio Metadata -->
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> VRC Audio Metadata </mat-panel-title>
    </mat-expansion-panel-header>
    <app-vrc-audio-metadata-select-and-edit
      *ngIf="!loading && selectedKVPair !== undefined"
      [orgData]="selectedKVPair?.value"
    ></app-vrc-audio-metadata-select-and-edit>
  </mat-expansion-panel>
</mat-accordion>
