import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  CalleeEntity,
  ConversationConfig,
  ConversationConfigSchema,
  ConversationWaitingRoomConfig,
  DBDocSchema,
  EventType,
  UserData,
  VoiceResponseCommand,
} from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { DatetimeValidator } from '../../../../common/validators/datetime-validator/datetime-validator';
import { URLValidator } from '../../../../common/validators/url-validator/url-validator';
import { ConversationConfigService } from '../../../../services/conversation/conversation-config/conversation-config.service';
import { UserDataService } from '../../../../services/user/user-data/user-data.service';
import { ConfigDocSelectAndEdit } from '../../../generic/abstract-classes/config-doc-select-and-edit';

@Component({
  selector: 'app-conversation-config-select-and-edit',
  templateUrl: './conversation-config-select-and-edit.component.html',
  styleUrls: ['./conversation-config-select-and-edit.component.css'],
})
export class ConversationConfigSelectAndEditComponent extends ConfigDocSelectAndEdit<ConversationConfig> implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  userDataArray: UserData[];

  eventTypes: EventType[];

  serviceProviders: CalleeEntity[];

  onCannotProvideService: VoiceResponseCommand[];

  waitingRoomConfig: KVPair<ConversationWaitingRoomConfig>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private userDataService: UserDataService,
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _dataService: ConversationConfigService,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(ConversationConfig, _dataService, _changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getData() {
    super.getData();
    this.loading = true;
    this.eventTypes = this.orgData.getEventTypes();

    forkJoin([this.userDataService.getDocsArray()])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.userDataArray = value[0];
        this.loading = false;
        this.onSelectedKVPairChange(this.selectedKVPair);
      });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair.value.getId(),
      [
        ConversationConfigSchema.displayName,
        ConversationConfigSchema.description,
        ConversationConfigSchema.chatServiceSid,
        ConversationConfigSchema.pIIRetentionDuration,
        ConversationConfigSchema.roleSidAnonymousParticipant,
        ConversationConfigSchema.roleSidInternalParticipant,
        ConversationConfigSchema.closedAfterDuration,
        ConversationConfigSchema.inactiveAfterDuration,
        ConversationConfigSchema.redirectURL,
      ],
      ConversationConfigSchema.Defaults,
      this,
    );

    parameters[ConversationConfigSchema.serviceProviders] = this.serviceProviders || ConversationConfigSchema.Defaults.serviceProviders;
    parameters[ConversationConfigSchema.onCannotProvideService] =
      this.onCannotProvideService || ConversationConfigSchema.Defaults.onCannotProvideService;
    parameters[ConversationConfigSchema.waitingRoomConfig] =
      this.waitingRoomConfig?.value ?? ConversationConfigSchema.Defaults.waitingRoomConfig;

    const obj = new ConversationConfig(parameters);
    return obj;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: ConversationConfig) {
    // Init Form
    const formConfig = {} as any;
    formConfig[ConversationConfigSchema.displayName] = [obj.getDisplayName(), [Validators.required, Validators.maxLength(500)]];
    formConfig[ConversationConfigSchema.description] = [obj.getDescription(), [Validators.required, Validators.maxLength(500)]];
    formConfig[ConversationConfigSchema.closedAfterDuration] = [obj.getClosedAfterDuration(), [DatetimeValidator.isIsoDuration()]];
    formConfig[ConversationConfigSchema.chatServiceSid] = [obj.getChatServiceSid(), [Validators.required, Validators.maxLength(500)]];
    formConfig[ConversationConfigSchema.inactiveAfterDuration] = [obj.getInactiveAfterDuration(), [DatetimeValidator.isIsoDuration()]];
    formConfig[ConversationConfigSchema.pIIRetentionDuration] = [obj.getPIIRetentionDuration(), [DatetimeValidator.isIsoDuration()]];
    formConfig[ConversationConfigSchema.roleSidAnonymousParticipant] = [
      obj.getRoleSidAnonymousParticipant(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig[ConversationConfigSchema.roleSidInternalParticipant] = [
      obj.getRoleSidInternalParticipant(),
      [Validators.required, Validators.maxLength(500)],
    ];
    formConfig[ConversationConfigSchema.redirectURL] = [obj.getRedirectURL(), [URLValidator.url()]];
    this.form = this.formBuilder.group(formConfig);

    // Init other state
    this.serviceProviders = obj.getServiceProviders();
    this.onCannotProvideService = obj.getOnCannotProvideService();
    this.waitingRoomConfig = new KVPair<ConversationWaitingRoomConfig>({ value: obj.getWaitingRoomConfig() });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName(): AbstractControl | null {
    return this.form.get(ConversationConfigSchema.displayName);
  }

  get description(): AbstractControl | null {
    return this.form.get(ConversationConfigSchema.description);
  }

  get closedAfterDuration() {
    return this.form.get(ConversationConfigSchema.closedAfterDuration);
  }

  get chatServiceSid() {
    return this.form.get(ConversationConfigSchema.chatServiceSid);
  }

  get inactiveAfterDuration() {
    return this.form.get(ConversationConfigSchema.inactiveAfterDuration);
  }

  get pIIRetentionDuration() {
    return this.form.get(ConversationConfigSchema.pIIRetentionDuration);
  }

  get roleSidAnonymousParticipant() {
    return this.form.get(ConversationConfigSchema.roleSidAnonymousParticipant);
  }

  get roleSidInternalParticipant() {
    return this.form.get(ConversationConfigSchema.roleSidInternalParticipant);
  }

  get redirectURL() {
    return this.form.get(ConversationConfigSchema.redirectURL);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    return true;
  }

  kvPairBuilder = (): KVPair<ConversationConfig> => {
    const obj = new ConversationConfig({
      // Generic Properties
      id: DBDocSchema.GenericDefaults.id,

      // Displayable
      displayName: '',
      description: '',

      // Other Properties
      chatServiceSid: '',
      serviceProviders: [],
      onCannotProvideService: [],
      roleSidAnonymousParticipant: '',
      roleSidInternalParticipant: '',
      pIIRetentionDuration: cloneDeep(ConversationConfigSchema.Defaults.pIIRetentionDuration),
      waitingRoomConfig: cloneDeep(ConversationConfigSchema.Defaults.waitingRoomConfig),
      identityConfig: cloneDeep(ConversationConfigSchema.Defaults.identityConfig),
    });
    return new KVPair({ id: DBDocSchema.GenericDefaults.id, value: obj });
  };
}
