import { AllDataServiceOperation, EnforceServiceLimit, VoiceResponseCommandName } from '@pwp-common';

import { EnforceServiceLimitEditorOutput } from '../enforce-service-limit-editor-output';
import { ServiceCommandEditorOutput } from '../service-command-editor-output';
import { ServiceOperationEditorOutput } from '../service-operation-editor-output';

export const makeServiceOperationEditorOutput = (allDataServiceOperation: AllDataServiceOperation): ServiceOperationEditorOutput => {
  const result: ServiceOperationEditorOutput = { commands: [] };

  for (const command of allDataServiceOperation.serviceOperation.getCommands()) {
    const commandEditorResult: ServiceCommandEditorOutput = (command as any).serialize();
    switch (command.getCommandName()) {
      case VoiceResponseCommandName.enforceServiceLimit: {
        const enforceServiceLimit = command as EnforceServiceLimit;

        (commandEditorResult as EnforceServiceLimitEditorOutput).serviceLimit = allDataServiceOperation.data.serviceLimitMap.get(
          enforceServiceLimit.getServiceLimitId(),
        );
      }
    }
    result.commands.push(commandEditorResult);
  }

  return result;
};
