<app-events-calendar
  [editable]="true"
  [streamingData]="true"
  (eventClicked)="surgery.addEvent($event)"
  (eventDropped)="surgery.addEvent($event)"
  (eventResized)="surgery.addEvent($event)"
  (selected)="surgery.addEvents($event)"
>
</app-events-calendar>

<app-event-surgery-table #surgery [allDataUserMap]="allDataUserMap | async" [orgData]="orgData | async"> </app-event-surgery-table>
