import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import {
  CallList,
  CommunicationWidgetCreateAsyncServiceRequest,
  IVR,
  PlayOrSay,
  VoicemailConfig,
  VoiceResponseCommand,
  VRCAudioMetadata,
} from '@pwp-common';

import { CallListService } from '../../../../services/call/call-list/call-list.service';
import { IvrService } from '../../../../services/call/ivr/ivr.service';
import { CommunicationWidgetCreateAsyncServiceRequestService } from '../../../../services/communication/communication-widget/communication-widget-create-async-service-request/communication-widget-create-async-service-request.service';
import { VoicemailConfigService } from '../../../../services/voicemail/voicemail-config/voicemail-config.service';
import { VRCAudioMetadataService } from '../../../../services/vrc-audio/vrc-audio-metadata/vrc-audio-metadata.service';
import { ObjListEditor } from '../../../generic/abstract-classes/obj-list-editor';

@Component({
  selector: 'app-vrc-list-editor',
  templateUrl: './vrc-list-editor.component.html',
  styleUrls: ['./vrc-list-editor.component.css'],
})
export class VrcListEditorComponent extends ObjListEditor<VoiceResponseCommand> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Data
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  callListArray: Observable<CallList[]>;

  ivrsArray: Observable<IVR[]>;

  vrcAudioMetadataArray: Observable<VRCAudioMetadata[]>;

  voicemailConfigArray: Observable<VoicemailConfig[]>;

  communicationWidgetAsyncServiceRequestArray: Observable<CommunicationWidgetCreateAsyncServiceRequest[]>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private callListService: CallListService,
    private ivrService: IvrService,
    private vrcAudioMetadataService: VRCAudioMetadataService,
    private voicemailConfigService: VoicemailConfigService,
    private communicationWidgetAsyncServiceRequestService: CommunicationWidgetCreateAsyncServiceRequestService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.callListArray = this.callListService.getDocsArray();
    this.ivrsArray = this.ivrService.getDocsArray();
    this.vrcAudioMetadataArray = this.vrcAudioMetadataService.getDocsArray();
    this.voicemailConfigArray = this.voicemailConfigService.getDocsArray();
    this.communicationWidgetAsyncServiceRequestArray = this.communicationWidgetAsyncServiceRequestService.getAllDocsOfType({});
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Validation
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  objBuilder() {
    return PlayOrSay.deserialize({});
  }
}
