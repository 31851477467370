import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { isNil } from 'lodash';

import { AnyVoiceResponseCommand, VoiceResponseCommand, VoiceResponseCommandName, VoiceResponseCommandSchema } from '@pwp-common';

import { KVPair } from '../../../../common/objects/kvpair';
import { VRCEditor } from '../../../generic/abstract-classes/vrc-editor';

@Component({
  selector: 'app-any-vrc-editor',
  templateUrl: './any-vrc-editor.component.html',
  styleUrls: ['./any-vrc-editor.component.css'],
})
export class AnyVrcEditorComponent extends VRCEditor<VoiceResponseCommand> implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public readonly names = VoiceResponseCommandName;

  public readonly namesList = Array.from(Object.keys(VoiceResponseCommandName))
    .filter((z) => z !== VoiceResponseCommandName.play)
    .filter((z) => z !== VoiceResponseCommandName.say);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(changeDetectorRef);
  }

  public onFormChanges(value: any) {
    // Init state only if the command name changed.
    if (!isNil(value.commandName) && value.commandName !== this.obj!.value.getCommandName()) {
      this.setFormFromObj(this.makeNewCommand(value.commandName));
    }
    super.onFormChanges(value);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public getObjFromForm() {
    if (!isNil(this.obj?.value) && !isNil(this.commandName?.value) && this.commandName.value === this.obj.value.getCommandName()) {
      return this.obj;
    }

    return this.makeNewCommand(this.commandName.value);
  }

  private makeNewCommand(commandName: VoiceResponseCommandName): KVPair<VoiceResponseCommand> {
    const value = AnyVoiceResponseCommand.deserialize({
      [VoiceResponseCommandSchema.commandName]: commandName,
    });

    return new KVPair({ id: this.obj.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  public setFormFromObj(obj: KVPair<VoiceResponseCommand>) {
    if (!isNil(this.commandName?.value) && !isNil(obj.value!.getCommandName()) && obj.value!.getCommandName() === this.commandName.value) {
      return;
    }

    this.form = this.formBuilder.group({
      [VoiceResponseCommandSchema.commandName]: [obj.value!.getCommandName(), [Validators.required]],
    });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  public get commandName(): AbstractControl | null {
    return this.form.get(VoiceResponseCommandSchema.commandName);
  }
}
