import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { isNil } from 'lodash';

import { isPiiRetained } from '@pwp-common';

import { NgChanges } from '../../../../../../common/objects/ng-changes';
import { CommunicationsService } from '../../../../../../services/call/communications/communications.service';
import { CallbackRow } from '../../../../row/callback-row/callback-row';
import { InboxDialPhoneConfirmResult } from '../../generic/inbox-dial-phone-confirm/inbox-dial-phone-confirm-result';

@Component({
  selector: 'app-inbox-callback-dial-phone-button',
  templateUrl: './inbox-callback-dial-phone-button.component.html',
  styleUrls: ['./inbox-callback-dial-phone-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxCallbackDialPhoneButtonComponent implements OnInit, OnChanges {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////
  @Input() row: CallbackRow;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////
  canDial = false;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private communicationsService: CommunicationsService,
  ) {}

  ngOnInit(): void {
    this.refreshUI();
  }

  ngOnChanges(changes: NgChanges<InboxCallbackDialPhoneButtonComponent>) {
    this.refreshUI();
  }

  ///////////////////////////////////////////////////////////////////////
  // Dial Phone
  ///////////////////////////////////////////////////////////////////////

  doDial = async (result: InboxDialPhoneConfirmResult) => {
    await this.doDialHandler(result);
  };

  async doDialHandler(result: InboxDialPhoneConfirmResult) {
    await this.communicationsService.dialAsyncServiceRequest({
      asyncServiceRequestId: this.row.callbackRowParams.asyncServiceRequest.getId(),
      callerIdObjId: result.callerIdObjId,
      sessionId: this.row.callbackRowParams.asyncServiceRequest.getSessionId(),
    });
  }

  ///////////////////////////////////////////////////////////////////////
  // Refresh UI
  ///////////////////////////////////////////////////////////////////////

  refreshUI() {
    if (isNil(this.row)) {
      this.canDial = false;
    }

    this.canDial = isPiiRetained({
      objectCreateTime: this.row.callbackRowParams.asyncServiceRequest.getCreateTime(),
      piiRetentionDuration: 'P30D',
    });
    this.changeDetectorRef.markForCheck();
  }
}
