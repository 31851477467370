<ng-container *transloco="let t; read: 'call-list-table-editor'">
  <app-data-table
    [cols]="cols"
    [expansionRow]="expansionRow"
    [loading]="loading"
    [refreshClicked]="getDataWrapper"
    [selectedCols]="cols"
    [tableRows]="tableRows"
    [title]="t('title')"
    [translationScope]="'call-list-table-editor'"
  >
    <ng-template #expansionRow let-rowData>
      <app-call-list-table-editor-expansion [row]="rowData"></app-call-list-table-editor-expansion>
    </ng-template>
  </app-data-table>
  <app-progress-spinner *ngIf="loading"></app-progress-spinner>
</ng-container>
