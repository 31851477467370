import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { VoiceResponseCommandName } from '@pwp-common';

import { provideValueAccessor } from '../../../../common/form/provide-value-accessor';
import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-service-command-name-select',
  templateUrl: './service-command-name-select.component.html',
  styleUrls: ['./service-command-name-select.component.css'],
  providers: [provideValueAccessor(ServiceCommandNameSelectComponent)],
})
export class ServiceCommandNameSelectComponent extends FormGroupControlValueAccessor<any, any> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////

  public readonly commands = [
    VoiceResponseCommandName.dialCallList,
    VoiceResponseCommandName.enforceServiceLimit,
    VoiceResponseCommandName.flagServiceRequest,
    VoiceResponseCommandName.forward,
    VoiceResponseCommandName.playOrSay,
  ].map((command) => ({ label: command, value: command }));

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    this.form = new UntypedFormGroup({
      commandName: new UntypedFormControl(),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: string) {
    const patchedValue = { commandName: value };
    this.form.setValue(patchedValue, { emitEvent: false });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): any {
    return value.commandName;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get commandName() {
    return this.form.get('commandName') as UntypedFormControl;
  }
}
