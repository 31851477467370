<ng-container *transloco="let t; read: 'incoming-chat-waiting-room'">
  <div class="d-flex justify-content-center">
    <p class="text-center">{{ t('body', { queuePosition }) }} {{ t('doNotRefresh') }}</p>
  </div>
  <div class="d-flex justify-content-end">
    <button
      pButton
      class="p-button-raised p-button-help"
      icon="pi pi-sign-out"
      iconPos="left"
      label="{{ t('exitButton') }}"
      type="button"
      (click)="redirectNow()"
    ></button>
  </div>
</ng-container>
