import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Usage, UsageCategory, UsageSchema } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { DBOrderBy, DBQuery } from '../../generic/interfaces';
import { AuthService } from '../../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UsageService extends DbDocumentService<Usage> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, Usage);
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Usage
  ///////////////////////////////////////////////////////////////////////

  public getUsageWithWindowStartInRange(start: moment.Moment, end: moment.Moment, category?: UsageCategory): Observable<Usage[]> {
    // Log inputs
    console.log(`getUsageWithWindowStartInRange:` + `\n\tStart:\t${start}` + `\n\tTo:\t\t${end}` + `\n\category:\t${category}`);

    // Make the query
    const query: DBQuery[] = [
      { fieldPath: UsageSchema.windowStart, opStr: '>=', value: start.toDate() },
      { fieldPath: UsageSchema.windowStart, opStr: '<=', value: end.toDate() },
    ];

    const orderBy: DBOrderBy = { fieldPath: UsageSchema.windowStart, directionStr: 'desc' };

    // Filter manually since there is very little data. Don't need a composite index.
    return this.getDocsArray(query, orderBy, undefined, true).pipe(
      map((usageArray) => {
        if (category !== undefined) {
          return usageArray.filter((z) => z.getCategory() === category);
        }
        return usageArray;
      }),
    );
  }
}
