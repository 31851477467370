import {SchemaField} from '../../generic/serialization/schema-field';
import {VoiceResponseCommandSchema} from '../generic/voice-response-command-schema';
import {VoiceResponseCommandName} from '../any-voice-response-command/voice-response-command-name';
import {CalleeEntity} from '../../call/callee-entity/callee-entity';

export class SendMessageTemplateSchema extends VoiceResponseCommandSchema {
  public static readonly messageTemplateId = 'messageTemplateId';
  public static readonly to = 'to';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = VoiceResponseCommandName.sendMessageTemplate;
    public static readonly messageTemplateId = undefined;
    public static readonly to = [];
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(SendMessageTemplateSchema.Defaults.commandName);
    parameters[SendMessageTemplateSchema.messageTemplateId] = SchemaField.string(SendMessageTemplateSchema.Defaults.messageTemplateId).required();
    parameters[SendMessageTemplateSchema.to] = SchemaField.shortObjArray(CalleeEntity, SendMessageTemplateSchema.Defaults.to);
    /* eslint-enable */

    return parameters;
  }
}
