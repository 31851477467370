import { Directive, ElementRef, inject } from '@angular/core';
import { isNil } from 'lodash';

@Directive({
  selector: '[appShadowRootStyles]',
  standalone: true,
})
export class ShadowRootStylesDirective {
  private readonly elementRef = inject(ElementRef);

  private readonly styleSheets = ['primeng-theme', 'primeng', 'primeicons'];

  constructor() {
    const { shadowRoot } = this.elementRef.nativeElement;

    if (isNil(shadowRoot)) {
      return;
    }

    this.styleSheets.forEach((filename) => {
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href = `${filename}.css`;
      shadowRoot.appendChild(link);
    });
  }
}
