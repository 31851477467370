import {cloneDeep} from 'lodash';
import {SerializableObject} from '../../../objects/generic/serialization/serializable-object';
export class ResponseObj<ErrorType = string> extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected error!: ErrorType;

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getError(): ErrorType {
    return cloneDeep(this.error);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setError(error: any): ResponseObj<ErrorType> {
    this.error = error;
    return this;
  }
}
