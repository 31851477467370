import { ReservationOffer } from '@pwp-common';

import { ReservationOfferEditorOutput } from '../reservation-offer-editor-output';

export const reservationOfferEditorOutputToDb = (editorOffers: ReservationOfferEditorOutput[]): ReservationOffer[] => {
  const dbOffers: ReservationOffer[] = [];
  for (const editorOffer of editorOffers ?? []) {
    dbOffers.push(
      new ReservationOffer({
        offerChannels: [editorOffer.channel],
        delay: editorOffer.delay,
      }),
    );
  }

  return dbOffers;
};
