<form *transloco="let t; read: 'inbox-create-callback-note'">
  <label class="flex flex-column gap-2">
    <span>{{ t('noteLabel') }}</span>
    <textarea pInputTextarea class="w-full" rows="6" [formControl]="noteControl"></textarea>
  </label>

  <div class="mt-4 flex justify-content-end">
    <button
      pButton
      class="p-button-primary"
      type="submit"
      [disabled]="noteControl.invalid || saving"
      [label]="t('saveButtonLabel')"
      (click)="saveNoteAndClose()"
    ></button>
  </div>
</form>

<p-toast position="bottom-center"></p-toast>
