import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';

export class IdentitySchema extends SerializableObjectSchema {
  public static readonly ipAddress = 'ipAddress';
  public static readonly e164Phone = 'e164Phone';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly ipAddress = undefined;
    public static readonly e164Phone = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[IdentitySchema.ipAddress] = SchemaField.ip(IdentitySchema.Defaults.ipAddress).optional();
    parameters[IdentitySchema.e164Phone] = SchemaField.e164Phone(IdentitySchema.Defaults.e164Phone).optional();
    /* eslint-enable */

    return parameters;
  }
}
