import { Component } from '@angular/core';

import { SupportedLanguages } from '@pwp-common';

import { ObjListEditor } from '../../../generic/abstract-classes/obj-list-editor';
import { TemplateTextLangPair } from '../generic/template-text-lang-pair';

@Component({
  selector: 'app-template-text-editor',
  templateUrl: './template-text-editor.component.html',
  styleUrls: ['./template-text-editor.component.css'],
})
export class TemplateTextEditorComponent extends ObjListEditor<TemplateTextLangPair> {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Validation
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  objBuilder() {
    return new TemplateTextLangPair(SupportedLanguages.en, '');
  }

  isValidList(): boolean {
    // This checks that the form is valid.
    if (!super.isValidList()) {
      return false;
    }

    if (this.objList.length === 0) {
      return false;
    }

    return true;
  }
}
