<div *ngIf="playURL !== undefined && howlerPlayer !== undefined" class="container">
  <div class="row">
    <div class="col-auto">
      <button *ngIf="!howlerPlayer.isPlaying()" mat-icon-button (click)="this.play()">
        <mat-icon>play_arrow</mat-icon>
      </button>
      <button *ngIf="howlerPlayer.isPlaying()" mat-icon-button (click)="this.pause()">
        <mat-icon>pause</mat-icon>
      </button>
    </div>
    <div class="col-11">
      <mat-slider
        class="full-width"
        onTouched="pause()"
        [max]="howlerPlayer.getDuration()"
        [min]="0"
        [step]="0.1"
        [value]="currentPosSeconds"
        (change)="onSliderChange($event)"
      ></mat-slider>
    </div>
  </div>
</div>
<div *ngIf="playURL === undefined" class="row">
  <div class="col">
    <mat-progress-spinner diameter="24" [mode]="'indeterminate'"></mat-progress-spinner>
  </div>
</div>
