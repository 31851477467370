import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';
import {ReservationOffer} from '../reservation-offer/reservation-offer';
import {ReservationOfferChannelConfigPhoneCall} from '../reservation-offer-channel-config-phone-call/reservation-offer-channel-config-phone-call';
import {ReservationOfferChannelConfigText} from '../reservation-offer-channel-config-text/reservation-offer-channel-config-text';
import {ReservationSkipOffersIf} from '../reservation-skip-offers-if/reservation-skip-offers-if/reservation-skip-offers-if';
import {defaultReservationSkipOffersIf} from '../reservation-skip-offers-if/default-reservation-offer-skip-if';

export class ReservationOfferConfigSchema extends SerializableObjectSchema {
  public static readonly offers = 'offers';
  public static readonly skipOffersIf = 'skipOffersIf';
  public static readonly offerChannelConfigPhoneCall = 'offerChannelConfigPhoneCall';
  public static readonly offerChannelConfigText = 'offerChannelConfigText';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly offers = undefined;
    public static readonly skipOffersIf = defaultReservationSkipOffersIf;
    public static readonly offerChannelConfigPhoneCall = undefined;
    public static readonly offerChannelConfigText = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([ReservationOfferConfigSchema.skipOffersIf]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ReservationOfferConfigSchema.offers] = SchemaField.shortObjArray(
        ReservationOffer,
        ReservationOfferConfigSchema.Defaults.offers
    ).required();
    parameters[ReservationOfferConfigSchema.skipOffersIf] = SchemaField.obj(
        ReservationSkipOffersIf,
        ReservationOfferConfigSchema.Defaults.skipOffersIf
    );
    parameters[ReservationOfferConfigSchema.offerChannelConfigPhoneCall] = SchemaField.obj(
        ReservationOfferChannelConfigPhoneCall,
        ReservationOfferConfigSchema.Defaults.offerChannelConfigPhoneCall
    ).optional();
    parameters[ReservationOfferConfigSchema.offerChannelConfigText] = SchemaField.obj(
        ReservationOfferChannelConfigText,
        ReservationOfferConfigSchema.Defaults.offerChannelConfigText
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
