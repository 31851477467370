import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import {
  AnyCommunicationSessionEventLog,
  AnyCommunicationSessionEventLogSchema,
  CommunicationSessionEventLog,
  getInboundCallSessionCollection,
} from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from '../../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class InboundCallSessionEventService extends DbDocumentService<CommunicationSessionEventLog> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, AnyCommunicationSessionEventLog);
  }

  private sessionId: string;

  ///////////////////////////////////////////////////////////////////////
  // SessionId Setter / Getter
  ///////////////////////////////////////////////////////////////////////

  public setSessionId(sessionId: string) {
    this.sessionId = sessionId;
  }

  private resetSessionId() {
    this.sessionId = undefined;
  }

  ///////////////////////////////////////////////////////////////////////
  // Firebase Reference
  ///////////////////////////////////////////////////////////////////////

  protected getCollectionName(orgId: string): string {
    if (!this.orgId) {
      throw new Error('User Error: Please call setSessionId() before each run of this function.');
    }

    const collectionName = getInboundCallSessionCollection(orgId, this.sessionId);

    this.resetSessionId();

    return collectionName;
  }

  ///////////////////////////////////////////////////////////////////////
  // Base Methods
  ///////////////////////////////////////////////////////////////////////

  protected getSchema(): AnyCommunicationSessionEventLogSchema {
    return new AnyCommunicationSessionEventLogSchema();
  }

  ///////////////////////////////////////////////////////////////////////
  // Logging
  ///////////////////////////////////////////////////////////////////////

  /**
   * Return parameters appending to beginning of some logging statements for
   * debugging purposes
   */
  protected getLoggingInfo(): string {
    return `collection=${getInboundCallSessionCollection(this.orgId, this.sessionId)}`;
  }
}
