<ng-container *transloco="let t; read: 'event-split-dialog'">
  {{ t('instructions') }}
  <form *ngIf="!loading" [formGroup]="form">
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ t('label') }}</mat-label>
      <input matInput formControlName="splitTime" type="datetime-local" [max]="maxTime" [min]="minTime" [step]="900" />
      <mat-error *ngIf="splitTime?.invalid && splitTime?.touched">
        {{ t('splitTimeError') }}
      </mat-error>
    </mat-form-field>
  </form>
  <button mat-button [disabled]="form.invalid" [mat-dialog-close]="getSplitTime()">{{ t('okButton') }}</button>
</ng-container>
