<ng-container *transloco="let t; read: 'inbox-dial-phone-button'">
  <p-toast position="bottom-center"></p-toast>
  <p-button
    label="{{ t(dialButtonCell.translationKey, dialButtonCell.translationObj) }}"
    [disabled]="!canDial || dialInProgress"
    [loading]="dialInProgress"
    (onClick)="doDialClick()"
  >
  </p-button>
</ng-container>
