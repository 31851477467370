import { TimingOptionDisplay } from './interfaces';

export const createTimingOptionDisplay = (duration: moment.Duration): TimingOptionDisplay => {
  const isZero = duration.asSeconds() === 0;

  const label = isZero ? 'timingOptionImmediate' : 'timingOptionDelay';
  const iconName = isZero ? 'timer_off' : 'timer';

  return {
    label,
    seconds: duration.seconds(),
    minutes: duration.minutes(),
    hours: duration.hours(),
    isoDuration: duration.toISOString(),
    iconName,
  };
};
