import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {PossibleAdminRole} from '../../../../../objects/user/admin-roles/types';
import {PossibleRole} from '../../../../../objects/user/roles/types';
import {ConversationRequestName} from '../../../conversation-request-name';
import {ConversationRequest} from '../../generic/conversation-request/conversation-request';
import {ConversationRequestSchema} from '../../generic/conversation-request/conversation-request-schema';
import {ConversationRequestGetJWTConstructor} from './conversation-request-get-jwt-constructor';
import {ConversationRequestGetJWTSchema} from './conversation-request-get-jwt-schema';

export class ConversationRequestGetJWT extends ConversationRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected conversationConfigId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationRequestGetJWTConstructor) {
    (parameters as any)[ConversationRequestSchema.type] = ConversationRequestName.getJWT;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationRequestGetJWT {
    return new ConversationRequestGetJWT(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationRequestGetJWT.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationRequestGetJWTSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['conversation'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getConversationConfigId(): string {
    return cloneDeep(this.conversationConfigId);
  }
}
