import {VoiceResponse} from '../../../helper/twilio-types';
import {GoogleTextToSpeechLanguageCode, GoogleTextToSpeechVoice} from './google-language-types';

/**
 * Encode all defaults for each supported language. Eg, Twilio say/gather language,
 * and the model used to convert text-to-speech using Google's services.
 */
export class LanguageDefaults {
  constructor(
    private readonly shortCode: string,
    private readonly displayName: string,
    private readonly ivrGatherLanguage?: VoiceResponse.GatherLanguage,
    private readonly sayLanguage?: VoiceResponse.SayLanguage,
    private readonly sayVoice?: VoiceResponse.SayVoice,
    private readonly googleTextToSpeechVoice?: GoogleTextToSpeechVoice,
    private readonly googleTextToSpeechLanguageCode?: GoogleTextToSpeechLanguageCode
  ) {}

  /**
   * Returns a valid BCP-47 subtag specifying the language. For a list of all such
   * tags, see:
   *
   * https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
   * @returns
   */
  public getShortCode(): string {
    return this.shortCode;
  }

  public getIvrGatherLanguage(): VoiceResponse.GatherLanguage | undefined {
    return this.ivrGatherLanguage;
  }

  public getSayLanguage(): VoiceResponse.SayLanguage | undefined {
    return this.sayLanguage;
  }

  public getSayVoice(): VoiceResponse.SayVoice | undefined {
    return this.sayVoice;
  }

  public getGoogleTextToSpeechVoice(): GoogleTextToSpeechVoice | undefined {
    return this.googleTextToSpeechVoice;
  }

  public getGoogleTextToSpeechLanguageCode(): GoogleTextToSpeechLanguageCode | undefined {
    return this.googleTextToSpeechLanguageCode;
  }

  public getDisplayName(): string {
    return this.displayName;
  }

  public getId(): string {
    return this.getShortCode();
  }
}
