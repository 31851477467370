import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isEmpty } from 'lodash';

export class ObjIdValidator {
  public static objIdInListSync(optionsArray: { getId: () => string }[]) {
    return (control: AbstractControl): ValidationErrors | null => {
      const { value } = control;
      if (isEmpty(value)) {
        return null;
      }

      const selectedObjId = typeof value === 'string' ? value : value.getId();
      const selectedObjIdIsInList = optionsArray.some((option) => option.getId() === selectedObjId);

      return selectedObjIdIsInList ? null : { objIdNotInList: selectedObjId };
    };
  }
}
