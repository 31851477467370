<ng-template #objEditor>
  <ng-container *transloco="let t; read: 'distinguished-conversation-message-template-editor'">
    <form *ngIf="!loading" [formGroup]="form">
      <div class="form-row">
        <div class="col">
          <label
            >{{ textBoxLabel }}
            <textarea
              pInputTextarea
              placeholder="{{ textBoxPlaceholder }}"
              class="full-width"
              formControlName="textBox"
              rows="4"
            ></textarea>
          </label>
        </div>
      </div>
    </form>
  </ng-container>
</ng-template>

<app-settings
  [auditEntry]="auditEntry"
  [cardTitle]="cardTitle"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [refreshData]="refreshData"
  [resetForm]="resetForm"
>
</app-settings>
