import {DoesMatchPhraseInput} from './interfaces';
import {normalizeSpeechResult} from '../normalize-speech-result';

export const doesMatchPhrase = (params: DoesMatchPhraseInput): boolean => {
  if (params.detectedSpeech === undefined) {
    return false;
  }

  const detectedSpeech = normalizeSpeechResult(params.detectedSpeech, params.language);

  const acceptedPhrasesArr = (params.acceptedPhrases ?? []).map(_ => normalizeSpeechResult(_, params.language));
  const acceptedPhrases = new Set(acceptedPhrasesArr);

  /**
   * Exact match always accepted
   */
  if (acceptedPhrases.has(detectedSpeech)) {
    return true;
  }

  const eachWordAcceptable = detectedSpeech
    .split(' ')
    .map(_ => normalizeSpeechResult(_, params.language))
    .reduce((acc, curr) => acc && acceptedPhrases.has(curr), true);

  if (eachWordAcceptable) {
    return true;
  }

  return false;
};
