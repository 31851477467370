import {cloneDeep} from 'lodash';
import {ShakenHeaderConstructor} from './shaken-header-constructor';
import {ShakenHeaderSchema} from './shaken-header-schema';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';

export class ShakenHeader extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected alg!: string;
  protected ppt!: string;
  protected typ!: string;
  protected x5u!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ShakenHeaderConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ShakenHeader {
    return new ShakenHeader(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ShakenHeader.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ShakenHeaderSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getALG(): string {
    return cloneDeep(this.alg);
  }

  public getPPT(): string {
    return cloneDeep(this.ppt);
  }

  public getTYP(): string {
    return cloneDeep(this.typ);
  }

  public getX5U(): string {
    return cloneDeep(this.x5u);
  }
}
