import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {values} from 'lodash';
import {CommunicationsResponseGetConversationLogPIIError} from './communications-response-get-conversation-log-pii-error';
import {Identity} from '../../../../../objects/identity/identity/identity';

export class CommunicationsResponseGetConversationLogPIISchema extends CommunicationsResponseSchema {
  public static readonly identity = 'identity';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly error = undefined;

    public static readonly identity = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsResponseGetConversationLogPIISchema.error] = SchemaField.string(CommunicationsResponseSchema.Defaults.error).optional().valid(...values(CommunicationsResponseGetConversationLogPIIError));
    parameters[CommunicationsResponseGetConversationLogPIISchema.identity] =  SchemaField.obj(Identity, CommunicationsResponseGetConversationLogPIISchema.Defaults.identity).optional();
    /* eslint-enable */

    return parameters;
  }
}
