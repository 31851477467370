import {SchemaField} from '../../generic/serialization/schema-field';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {CallStats} from '../call-stats/call-stats';
import {EventStats} from '../event-stats/event-stats';

export class EntityStatsChunkSchema extends SerializableObjectSchema {
  public static readonly calls = 'calls';
  public static readonly events = 'events';
  public static readonly eventsByType = 'eventsByType';
  public static readonly callList = 'callList';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly calls = CallStats.deserialize({});
    public static readonly events = EventStats.deserialize({});
    public static readonly eventsByType = new Map<string, EventStats>();
    public static readonly callList = new Map<string, CallStats>();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[EntityStatsChunkSchema.calls] = SchemaField.obj(CallStats, EntityStatsChunkSchema.Defaults.calls);
    parameters[EntityStatsChunkSchema.events] = SchemaField.obj(EventStats, EntityStatsChunkSchema.Defaults.events);
    parameters[EntityStatsChunkSchema.eventsByType] = SchemaField.mapOfObj(EventStats, EntityStatsChunkSchema.Defaults.eventsByType);
    parameters[EntityStatsChunkSchema.callList] = SchemaField.mapOfObj(CallStats, EntityStatsChunkSchema.Defaults.callList);
    /* eslint-enable */

    return parameters;
  }
}
