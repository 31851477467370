import { ReservationOfferChannelConfigEditorOutput } from './reservation-offer-channel-config-editor-output';

export const defaultReservationOfferChannelConfigEditorOutput: ReservationOfferChannelConfigEditorOutput = {
  phoneCall: {
    dialTimeoutSeconds: 60,
    vrcAudioMetadataId: undefined,
  },
  text: {
    body: "Hi, thanks for working with us! There's a new call/chat waiting, could you take it?",
  },
  callerIdObjId: undefined,
};
