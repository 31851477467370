import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep, isNil } from 'lodash';

import {
  ServiceLimit,
  ServiceLimitEnforcementStrategyRRule,
  ServiceLimitEnforcementStrategyRRuleSchema,
  ServiceLimitEnforcementStrategySchema,
  ServiceLimitSchema,
  ServiceLimitServiceUsageThresholdSchema,
} from '@pwp-common';

import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';


import { defaultServiceLimit } from './default-value';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ServiceLimitEditorComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ServiceLimitEditorComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-service-limit-editor',
  templateUrl: './service-limit-editor.component.html',
  styleUrls: ['./service-limit-editor.component.css'],
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class ServiceLimitEditorComponent extends FormGroupControlValueAccessor<any, any> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  baseServiceLimit: ServiceLimit | undefined = undefined;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    const enforcementStrategy = defaultServiceLimit.getEnforcementStrategy() as ServiceLimitEnforcementStrategyRRule;
    const threshold = defaultServiceLimit.getEnforcementStrategy().getThreshold();

    this.form = new UntypedFormGroup({
      windowResetRRule: new UntypedFormControl(enforcementStrategy.getWindowResetRRule(), [Validators.required]),
      serviceDeliveredNum: new UntypedFormControl(threshold.getServiceDeliveredNum(), [Validators.min(0)]),
      enforce: new UntypedFormControl(enforcementStrategy.getEnforce()),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: ServiceLimit | undefined | null) {
    if (isNil(value)) {
      return;
    }

    /**
     * Save a copy of the input so that other properties (eg, current usage, and document id) if specified
     * are returned
     */
    this.baseServiceLimit = value;

    /**
     * Update the form with specified data
     */
    const enforcement = value?.getEnforcementStrategy() as ServiceLimitEnforcementStrategyRRule | undefined;
    const threshold = value?.getEnforcementStrategy()?.getThreshold();

    const formValue = {
      windowResetRRule: enforcement?.getWindowResetRRule(),
      serviceDeliveredNum: threshold?.getServiceDeliveredNum(),
      enforce: enforcement?.getEnforce(),
    };

    this.form.patchValue(formValue, { emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): ServiceLimit {
    const result = cloneDeep(this.baseServiceLimit ?? defaultServiceLimit);

    const enforcementStrategy = result.getEnforcementStrategy();
    enforcementStrategy.setField(
      ServiceLimitEnforcementStrategyRRuleSchema.windowResetRRule,
      value[ServiceLimitEnforcementStrategyRRuleSchema.windowResetRRule],
    );
    enforcementStrategy.setField(ServiceLimitEnforcementStrategySchema.enforce, value[ServiceLimitEnforcementStrategyRRuleSchema.enforce]);

    const threshold = enforcementStrategy.getThreshold();
    threshold.setField(
      ServiceLimitServiceUsageThresholdSchema.serviceDeliveredNum,
      value[ServiceLimitServiceUsageThresholdSchema.serviceDeliveredNum],
    );

    enforcementStrategy.setField(ServiceLimitEnforcementStrategySchema.threshold, threshold);
    result.setField(ServiceLimitSchema.enforcementStrategy, enforcementStrategy);

    return result;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////
  get windowResetRRule() {
    return this.form.get(ServiceLimitEnforcementStrategyRRuleSchema.windowResetRRule) as UntypedFormControl;
  }

  get serviceDeliveredNum() {
    return this.form.get(ServiceLimitServiceUsageThresholdSchema.serviceDeliveredNum) as UntypedFormControl;
  }

  get enforce() {
    return this.form.get(ServiceLimitEnforcementStrategySchema.enforce) as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  public makeValidationErrors(): ValidationErrors {
    return {
      'service-limit-editor': this.form.value,
    };
  }
}
