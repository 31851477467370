import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';

import { AsyncServiceRequestNotesService } from '../../../../../../services/call/note/async-service-request-notes.service';

import { InboxCreateCallbackNoteData } from './interfaces';

@Component({
  selector: 'app-inbox-create-callback-note',
  templateUrl: './inbox-create-callback-note.component.html',
  styleUrls: ['./inbox-create-callback-note.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class InboxCreateCallbackNoteComponent {
  private translocoScope = 'inbox-create-callback-note';

  private data: InboxCreateCallbackNoteData = this.config.data;

  private asyncServiceRequest = this.data.asyncServiceRequest;

  private callLog = this.data.callLog;

  public saving = false;

  public readonly noteControl = new UntypedFormControl('', { validators: Validators.required });

  constructor(
    private asyncServiceRequestNotesService: AsyncServiceRequestNotesService,
    private config: DynamicDialogConfig,
    private messageService: MessageService,
    private dialogRef: DynamicDialogRef,
    private translocoService: TranslocoService,
  ) {}

  public async saveNoteAndClose(): Promise<void> {
    try {
      this.saving = true;

      const note = this.asyncServiceRequestNotesService.createNote({
        body: this.noteControl.value,
        asyncServiceRequestId: this.asyncServiceRequest.getId(),
        callLogId: this.callLog.getId(),
      });

      await firstValueFrom(this.asyncServiceRequestNotesService.upload({ obj: note, mode: 'create' }));
      this.dialogRef.close({ success: true });
    } catch (e) {
      this.messageService.add({
        severity: 'error',
        summary: this.translocoService.translate(`${this.translocoScope}.saveError`),
        detail: e.message,
      });
    } finally {
      this.saving = false;
    }
  }
}
