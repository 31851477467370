import {getDisplayName, getEmail} from '../../user/user-data/user-data-display';
import {UserData} from '../../user/user-data/user-data';

import {AssignedUserStatTypes} from '../assigned-user-type-stats/assigned-user-stats-types';
import {EntityStats} from '../entity-stats/entity-stats';
import {EntityStatsSchema} from '../entity-stats/entity-stats-schema';
import {cloneDeep, orderBy} from 'lodash';

export class UserLoadSlidingWindow {
  ////////////////////////////////////////////////////////////////////////////////
  // Static Methods
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Return the top N users sorted by call load.
   * @param data data to sort
   * @param topNum num
   * @returns
   */
  static getTopUsersByCallLoad = (data: UserLoadSlidingWindow[], topNum: number | undefined): UserLoadSlidingWindow[] => {
    return UserLoadSlidingWindow.sliceAndSortByField(data, topNum, z => z.iTalkDurationHours);
  };

  static getTopUsersByEventLoad = (data: UserLoadSlidingWindow[], topNum: number | undefined): UserLoadSlidingWindow[] => {
    return UserLoadSlidingWindow.sliceAndSortByField(data, topNum, z => z.totalEventHours);
  };

  private static sliceAndSortByField = (
    data: UserLoadSlidingWindow[],
    topNum: number | undefined,
    sortFn: (z: UserLoadSlidingWindow) => number
  ): UserLoadSlidingWindow[] => {
    let topLoads: UserLoadSlidingWindow[] = [];
    topLoads = orderBy(cloneDeep(data), sortFn, ['desc']);
    return topLoads.slice(undefined, topNum);
  };

  ////////////////////////////////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////////////////////////////////

  displayName: string;
  email: string;

  iNum = 0;
  iTalkDurationHours = 0;

  numEvents = 0;
  totalEventHours = 0;

  ////////////////////////////////////////////////////////////////////////////////
  // Constructor
  ////////////////////////////////////////////////////////////////////////////////

  /**
   *
   * @param entityStats
   * @param slidingWindowDays
   */
  constructor(entityStats: EntityStats, allUserDataMap: Map<string, UserData>, slidingWindowDays: number) {
    this.displayName = getDisplayName(allUserDataMap, entityStats.getId());
    this.email = getEmail(allUserDataMap, entityStats.getId());

    // Set Number of Calls
    this.setStats(entityStats, slidingWindowDays);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Compute
  ////////////////////////////////////////////////////////////////////////////////

  private setStats(entityStats: EntityStats, slidingWindowDays: number) {
    const entityStatsChunk = entityStats.getBySlidingWindow().get(EntityStatsSchema.Constants.slidingWindowKeyName(slidingWindowDays));

    const calls = entityStatsChunk?.getCalls();
    this.iNum = calls?.getINum() || 0;
    this.iTalkDurationHours = Math.round((100 * (calls?.getIMsTalkDuration() || 0)) / (1000 * 3600)) / 100.0;

    const events = entityStatsChunk?.getEvents();
    this.numEvents = events?.getAssignedUserTypeStats().get(AssignedUserStatTypes.any)?.getNum() || 0;
    this.totalEventHours = events?.getAssignedUserTypeStats().get(AssignedUserStatTypes.any)?.getDurationHours() || 0;
  }
}
