import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {values} from 'lodash';
import {CommunicationsResponseCreateOutboundCallError} from './communications-response-create-outbound-call-error';

export class CommunicationsResponseOnTaskRouterConfigurationUpdatedSchema extends CommunicationsResponseSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly error = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsResponseOnTaskRouterConfigurationUpdatedSchema.error] = SchemaField.string(
      CommunicationsResponseOnTaskRouterConfigurationUpdatedSchema.Defaults.error
    )
      .optional()
      .valid(...values(CommunicationsResponseCreateOutboundCallError));
    /* eslint-enable */

    return parameters;
  }
}
