import { defaultReservationOfferChannelConfigEditorOutput } from './objects/channel/default-reservation-offer-channel-config-editor-output';
import { defaultReservationOfferEditorOutput } from './objects/offers/reservation-offer/default-reservation-offer-editor-output';
import { defaultReservationSkipOffersIfEditorOutput } from './objects/reservation-skip-offers-if/default-reservation-skip-offers-if-editor-output';
import { ReservationOfferConfigEditorOutput } from './reservation-offer-config-editor-output';

export const defaultReservationOfferConfigEditorOutput: ReservationOfferConfigEditorOutput = {
  channelConfig: defaultReservationOfferChannelConfigEditorOutput,
  offers: [defaultReservationOfferEditorOutput],
  skipOffersIf: defaultReservationSkipOffersIfEditorOutput,
};
