import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { EventConfig } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from '../../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EventConfigService extends DbDocumentService<EventConfig> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, EventConfig);
  }
}
