import {cloneDeep} from 'lodash';

import {SerializableObject} from '../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {NoteBodyType} from '../note-body-type';

import {NoteBodyConstructor} from './note-body-constructor';

export abstract class NoteBody extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected type!: NoteBodyType;
  protected value!: unknown;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: NoteBodyConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract NoteBody');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType() {
    return cloneDeep(this.type);
  }

  public abstract getValue(): unknown;
}
