import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { EmailTemplateConfig, EmailTemplateConfigSchema, EmailTemplateType } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../common/objects/form-helper';
import { EmailTemplateConfigService } from '../../../services/email-template-config/email-template-config.service';
import { ConfigDocSelectAndEdit } from '../../generic/abstract-classes/config-doc-select-and-edit';

@Component({
  selector: 'app-email-template-config-select-and-edit',
  templateUrl: './email-template-config-select-and-edit.component.html',
  styleUrls: ['./email-template-config-select-and-edit.component.css'],
})
export class EmailTemplateConfigSelectAndEditComponent extends ConfigDocSelectAndEdit<EmailTemplateConfig> implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  possibleEmailTemplateTypes = Object.keys(EmailTemplateType);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _dataService: EmailTemplateConfigService,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(EmailTemplateConfig, _dataService, _changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair?.value?.getId(),
      [EmailTemplateConfigSchema.replyTo, EmailTemplateConfigSchema.templateType],
      EmailTemplateConfigSchema.Defaults,
      this,
    );

    return new EmailTemplateConfig(parameters);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: EmailTemplateConfig) {
    // Init Form
    const formConfig = {} as any;
    formConfig[EmailTemplateConfigSchema.replyTo] = [obj.getReplyTo(), [Validators.required, Validators.email, Validators.maxLength(500)]];
    formConfig[EmailTemplateConfigSchema.templateType] = [obj.getTemplateType(), [Validators.required, Validators.maxLength(500)]];
    this.form = this.formBuilder.group(formConfig);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get replyTo(): AbstractControl | null {
    return this.form.get(EmailTemplateConfigSchema.replyTo);
  }

  get templateType(): AbstractControl | null {
    return this.form.get(EmailTemplateConfigSchema.templateType);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    return true;
  }
}
