import { Client, State, Conversation as TwilioConversation } from '@twilio/conversations';
import { isNil } from 'lodash';
import pDefer from 'p-defer';
import { BehaviorSubject } from 'rxjs';

import { Conversation } from '../conversation/conversation';

type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'silent' | null;

export interface ConversationClientConstructor {
  jwt: string;
  clientOverride?: any;
  api?: ConversationAPI;
}
export interface ConversationAPI {
  getRefreshedJwt?: () => Promise<string>;
}
export class ConversationClient {
  ////////////////////////////////////////////////////////////////////////////////
  // General Vars
  ////////////////////////////////////////////////////////////////////////////////

  private client: Client;

  private initializationComplete = pDefer();

  private numInitSdkAttempts = 0;

  ////////////////////////////////////////////////////////////////////////////////
  // Conversation
  ////////////////////////////////////////////////////////////////////////////////

  private twilioConversationObjs = new Map<string, Promise<TwilioConversation>>();

  private conversations = new Map<string, Conversation>();

  public $subscribedConversations = new BehaviorSubject<Set<string>>(new Set());

  ////////////////////////////////////////////////////////////////////////////////
  // Auth Expiration
  ////////////////////////////////////////////////////////////////////////////////

  public $authExpired = new BehaviorSubject(false);

  public $authAboutToExpire = new BehaviorSubject(false);

  ////////////////////////////////////////////////////////////////////////////////
  // Constructor
  ////////////////////////////////////////////////////////////////////////////////
  constructor(private parameters: ConversationClientConstructor) {
    this.initSdk({ jwt: parameters.jwt, clientOverride: parameters.clientOverride });
  }

  private initSdk(parameters: { jwt: string; clientOverride?: Client; logLevel?: LogLevel; force?: boolean }) {
    this.numInitSdkAttempts += 1;
    if (this.client === undefined || parameters.force === true) {
      this.client = parameters.clientOverride || new Client(parameters.jwt, { logLevel: parameters.logLevel });
    }

    this.client.on('stateChanged', async (state) => {
      await this.onSdkStateChange(state);
    });

    this.maintainTokenState();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Conversations
  ////////////////////////////////////////////////////////////////////////////////

  private addTwilioConversation(conversation: TwilioConversation): void {
    const { sid } = conversation;
    console.log('addTwilioConversation: Starting', { sid });
    if (this.conversations.has(sid)) {
      console.log('addTwilioConversation: Nothing to do.', { sid });
      return;
    }
    this.twilioConversationObjs.set(sid, Promise.resolve(conversation));
    this.conversations.set(sid, new Conversation({ conversation, identity: this.client.user.identity }));

    this.$subscribedConversations.next(new Set(this.twilioConversationObjs.keys()));
    console.log('addTwilioConversation: Added new conversation', { sid });
  }

  public removeSubscription(sid: string): void {
    console.log('removeSubscription: Starting', { sid });
    if (!this.twilioConversationObjs.has(sid)) {
      console.log('removeSubscription: Nothing to do.', { sid });
      return;
    }
    this.twilioConversationObjs.delete(sid);
    this.conversations.get(sid)?.shutdown();
    this.conversations.delete(sid);
    this.$subscribedConversations.next(new Set(this.twilioConversationObjs.keys()));
    console.log('removeSubscription: Removed conversation', { sid });
  }

  ////////////////////////////////////////////////////////////////////////////////
  // SDK Token
  ////////////////////////////////////////////////////////////////////////////////

  private maintainTokenState() {
    this.client.on('tokenExpired', () => {
      this.$authExpired.next(true);
    });
    this.client.on('tokenAboutToExpire', async () => {
      await this.tokenAboutToExpire();
    });
  }

  private async tokenAboutToExpire() {
    console.log('tokenAboutToExpire: Starting');
    if (!isNil(this.parameters.api?.getRefreshedJwt)) {
      try {
        const jwt = await this.parameters.api?.getRefreshedJwt();
        await this.client.updateToken(jwt);
      } catch (error) {
        console.error('Error in refreshing the token', error);
        this.$authAboutToExpire.next(true);
      }
    } else {
      this.$authAboutToExpire.next(true);
    }
    console.log('tokenAboutToExpire: Completed');
  }

  ////////////////////////////////////////////////////////////////////////////////
  // SDK State
  ////////////////////////////////////////////////////////////////////////////////

  private onSdkStateChange(state: State): void {
    console.log(`sdkStateChange`, { state });
    switch (state) {
      case 'initialized': {
        // Can use client.
        this.initializationComplete.resolve();
        break;
      }
      case 'failed': {
        console.log('Initialization of client failed. Retrying with verbose log level');
        if (this.numInitSdkAttempts <= 1) {
          this.initSdk({
            jwt: this.parameters.jwt,
            clientOverride: this.parameters.clientOverride,
            logLevel: 'debug',
            force: true,
          });
        } else {
          console.log('No more attempts remain.', { numInitializationAttempts: this.numInitSdkAttempts });
          this.initializationComplete.reject();
        }
      }
    }
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Conversations
  ////////////////////////////////////////////////////////////////////////////////

  public getConversation(sid: string): Conversation {
    return this.conversations.get(sid);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Conversation
  ////////////////////////////////////////////////////////////////////////////////

  private async getConversationFromTwilio(sid: string) {
    console.log('getConversationFromTwilio: Starting', { sid });
    let conversation: TwilioConversation;
    try {
      conversation = await this.client.getConversationBySid(sid);
    } catch (error) {
      console.warn(error.body);
      console.warn(error);
      throw error;
    }
    console.log('getConversationFromTwilio: Completed', { sid });
    return conversation;
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Subscribe Conversation
  ////////////////////////////////////////////////////////////////////////////////

  private async createSubscription(sid: string): Promise<TwilioConversation> {
    await this.initializationComplete.promise;
    try {
      const conversation = await this.getConversationFromTwilio(sid);
      this.addTwilioConversation(conversation);
      return conversation;
    } catch (error) {
      const errorCode = error.body?.code;
      switch (errorCode) {
        case 50350: {
          this.removeSubscription(sid);
          break;
        }
        default: {
          console.error('createSubscription: Unhandled error', error);
        }
      }
    }
  }

  public subscribeConversation(sid: string): Promise<void> {
    console.log('subscribeConversation: Creating subscription', { sid });
    if (!this.twilioConversationObjs.has(sid)) {
      this.twilioConversationObjs.set(sid, this.createSubscription(sid));
    } else {
      console.log('subscribeConversation: Skipping because subscription already created ', { sid });
    }
    console.log('subscribeConversation: Completed', { sid });
    return this.twilioConversationObjs.get(sid).then(() => null);
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Shutdown
  ////////////////////////////////////////////////////////////////////////////////

  public async shutdown() {
    console.log('ConversationClient: Shutdown starting.');
    const promises: Promise<void>[] = [];
    if (this.client) {
      promises.push(this.client.shutdown());
    }
    for (const conversation of this.conversations.values()) {
      conversation.shutdown();
    }

    await Promise.all(promises);
    this.client = undefined;
    console.log('ConversationClient: Shutdown complete.');
  }
}
