import { DialedCallLogRow } from '../dialed-call-log-row/dialed-call-log-row';

import { MakeTableRowsInput } from './interfaces';

export const makeDialedCallLogRows = ({ dialedCallLogs = [], ...params }: MakeTableRowsInput): DialedCallLogRow[] =>
  dialedCallLogs.map(
    (dialedCall) =>
      new DialedCallLogRow({
        dialedCall,
        allDataUser: params.allDataUsers,
        timezone: params.timezone,
      }),
  );
