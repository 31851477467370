import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {ResponseSchema} from '../../../../generic/v2/response/response-schema';
import {UserRequestName} from '../../../user-request-name';

export abstract class UserResponseSchema extends ResponseSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly error = 'error';

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class {
    public static readonly error = undefined;
    public static readonly type = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters: {[key: string]: AnySchema} = super.getSchemaDefinition();

    parameters[UserResponseSchema.type] = SchemaField.string(UserResponseSchema.Defaults.type)
      .required()
      .valid(...Object.values(UserRequestName));

    return parameters;
  }
}
