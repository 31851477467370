<form *ngIf="!loading" [formGroup]="form">
  <!-- Basic Form Fields -->
  <div class="form-row">
    <div class="col">
      <!-- Display Name -->
      <mat-form-field class="full-width">
        <textarea matInput placeholder="Display Name" class="full-width" formControlName="displayName"></textarea>
        <span *ngIf="displayName?.invalid && displayName?.touched"> Display Name Invalid </span>
      </mat-form-field>
    </div>
    <div class="col">
      <!-- Description -->
      <mat-form-field class="full-width">
        <textarea matInput placeholder="Description" class="full-width" formControlName="description"></textarea>
        <span *ngIf="description?.invalid && description?.touched"> Description Invalid </span>
      </mat-form-field>
    </div>
    <div class="col">
      <!-- Key Presses -->
      <mat-form-field class="full-width">
        <textarea matInput placeholder="Comma Separated Key Presses" class="full-width" formControlName="commaSepKeyPresses"></textarea>
        <span *ngIf="commaSepKeyPresses?.invalid && commaSepKeyPresses?.touched"> Key Presses Invalid </span>
      </mat-form-field>
    </div>
  </div>
</form>

<app-phrases-lang-pair-select-and-edit [(objList)]="phrases" (objListChange)="updateObjAndEmit()"></app-phrases-lang-pair-select-and-edit>

<!-- Commands -->
<mat-accordion>
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title> Commands </mat-panel-title>
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>

    <app-vrc-list-editor [(objList)]="commands" (objListChange)="updateObjAndEmit()"></app-vrc-list-editor>
  </mat-expansion-panel>
</mat-accordion>
