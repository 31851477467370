import type {AnySchema} from 'joi';

import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {AssignedUserType} from '../event-data/enums';

export class EventRequestSchema extends DBDocSchema {
  public static readonly comment = 'comment';

  public static readonly decisionTime = 'decisionTime';

  public static readonly decidedByUserId = 'decidedByUserId';

  public static readonly eventId = 'eventId';

  public static readonly isApproved = 'isApproved';

  public static readonly isOpen = 'isOpen';

  public static readonly requestedAssignedUserId = 'requestedAssignedUserId';

  public static readonly requestedSlot = 'requestedSlot';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Constants = class {
    public static readonly collection = 'eventRequests';
  };

  public static readonly Defaults = class {
    public static readonly comment = undefined;
    public static readonly decisionTime = undefined;
    public static readonly decidedByUserId = undefined;
    public static readonly eventId = 'missing-eventId';
    public static readonly isApproved = undefined;
    public static readonly isOpen = true;
    public static readonly requestedAssignedUserId = 'missing-requestedAssignedUserId';
    public static readonly requestedSlot = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[EventRequestSchema.comment] = SchemaField.string(EventRequestSchema.Defaults.comment);
    parameters[EventRequestSchema.decisionTime] = SchemaField.timestamp(EventRequestSchema.Defaults.decisionTime);
    parameters[EventRequestSchema.decidedByUserId] = SchemaField.userId(EventRequestSchema.Defaults.decidedByUserId);
    parameters[EventRequestSchema.eventId] = SchemaField.string(EventRequestSchema.Defaults.eventId);
    parameters[EventRequestSchema.isApproved] = SchemaField.boolean(EventRequestSchema.Defaults.isApproved);
    parameters[EventRequestSchema.isOpen] = SchemaField.boolean(EventRequestSchema.Defaults.isOpen);
    parameters[EventRequestSchema.requestedAssignedUserId] = SchemaField.userId(EventRequestSchema.Defaults.requestedAssignedUserId);
    parameters[EventRequestSchema.requestedSlot] = SchemaField.string(EventRequestSchema.Defaults.requestedSlot).valid(...Object.values(AssignedUserType));

    return parameters;
  }

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([EventRequestSchema.isOpen]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${EventRequestSchema.Constants.collection}`;
  }
}
