/**
 * List all possible named templates we handle.
 */
export enum ConversationMessageTemplateType {
  onInactive = 'onInactive',
  firstSend = 'firstSend',
  serviceClosed = 'serviceClosed',
}

export type ConversationMessageTemplateTypeType = keyof typeof ConversationMessageTemplateType;
