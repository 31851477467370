import type {AnySchema} from 'joi';
import {DBMatchRuleDocPath} from '../../core/db-matcher/db-match-rule/db-match-rules/db-match-rule-doc-path/db-match-rule-doc-path';
import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {AnyNoteBody} from '../note-body/generic/any-note-body/any-note-body';

export class NoteSchema extends DBDocSchema {
  public static readonly body = 'body';
  public static readonly tags = 'tags';
  public static readonly redacted = 'redacted';

  /////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////

  public static readonly Constants = class {
    public static readonly collection = 'notes';
  };

  public static readonly Defaults = class {
    public static readonly body = undefined;
    public static readonly tags = undefined;
    public static readonly redacted = undefined;
  };

  /////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  /////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[NoteSchema.body] = SchemaField.obj(AnyNoteBody, NoteSchema.Defaults.body).optional();
    parameters[NoteSchema.tags] = SchemaField.obj(DBMatchRuleDocPath, NoteSchema.Defaults.tags).required();
    parameters[NoteSchema.redacted] = SchemaField.boolean(NoteSchema.Defaults.redacted).optional();

    return parameters;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Collection
  /////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${NoteSchema.Constants.collection}`;
  }
}
