import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { AllDataServiceException } from '@pwp-common';

import { NgChanges } from '../../../../common/objects/ng-changes';
import { AllDataServiceExceptionService } from '../../../../services/communication/all-data-service-exception/all-data-service-exception.service';
import { makeAuditEntry } from '../../../generic/settings/common/audit-entry/helpers/make-audit-entry';
import { AuditEntry } from '../../../generic/settings/common/audit-entry/interfaces';
import { makeServiceExceptionEditorOutput } from '../editor/editor-output/make-service-exception-editor-output/make-service-exception-editor-output';
import { ServiceExceptionEditorOutput } from '../editor/editor-output/service-exception-editor-output';

@Component({
  selector: 'app-service-exception-editor-with-save',
  templateUrl: './service-exception-editor-with-save.component.html',
  styleUrls: ['./service-exception-editor-with-save.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceExceptionEditorWithSaveComponent implements OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() allDataServiceException: AllDataServiceException;

  @Output() onUploadComplete = new EventEmitter<string>();

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  auditEntry: AuditEntry;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  form = new UntypedFormGroup({
    control: new UntypedFormControl(undefined, [Validators.required]),
  });

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////

  constructor(private allDataServiceExceptionService: AllDataServiceExceptionService) {}

  ngOnChanges(changes: NgChanges<ServiceExceptionEditorWithSaveComponent>) {
    this.auditEntry = makeAuditEntry(this.allDataServiceException?.serviceException);
    this._resetForm();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Upload
  ///////////////////////////////////////////////////////////////////////////////////////////

  doUpload = async () => this._doUpload();

  async _doUpload() {
    /**
     * Get the editor result. If the editor was not touched then the
     * control value is not of type AllDataServiceExceptionEditorResult, so we convert it here.
     */
    const editorResult: ServiceExceptionEditorOutput = makeServiceExceptionEditorOutput(this.control.value);
    const serviceExceptionId = await this.allDataServiceExceptionService.update(
      this.allDataServiceException?.serviceException,
      editorResult,
    );
    this.onUploadComplete.emit(serviceExceptionId);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Refresh Data
  ///////////////////////////////////////////////////////////////////////////////////////////

  refreshData = () => this._refreshData();

  async _refreshData() {}

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Reset Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  resetForm = () => this._resetForm();

  _resetForm() {
    this.form.reset({ control: this.allDataServiceException });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid
  /////////////////////////////////////////////////////////////////////////////////////////////

  getIsValid = (): boolean => this.isValid();

  isValid() {
    return this.form.valid;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  ///////////////////////////////////////////////////////////////////////////////////////////

  get control() {
    return this.form.get('control') as UntypedFormControl;
  }
}
