import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { loadingFor } from '@ngneat/loadoff';
import moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { EntityStats, EntityStatsSchema, EntityStatsType, UserData } from '@pwp-common';

import { EntityStatsService } from '../../../../services/analytics/entity-stats/entity-stats.service';
import { UserDataService } from '../../../../services/user/user-data/user-data.service';
import { ComponentWithForm } from '../../../generic/abstract-classes/component-with-form';
@Component({
  selector: 'app-admin-burnout-dashboard',
  templateUrl: './admin-burnout-dashboard.component.html',
  styleUrls: ['./admin-burnout-dashboard.component.css'],
})
export class AdminBurnoutDashboardComponent extends ComponentWithForm implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////

  possibleWindows = EntityStatsSchema.Constants.slidingWindowDurationDays;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  loader = loadingFor('entityStats', 'allDataUser', 'lastRefreshTime');

  entityStatsMap: Observable<Map<string, EntityStats>>;

  allUserDataMap: Observable<Map<string, UserData>>;

  lastRefreshTime: Observable<moment.Moment>;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private formBuilder: UntypedFormBuilder,
    private userDataService: UserDataService,
    private entityStatsService: EntityStatsService,
  ) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.getData();
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  private getData() {
    // Get data
    this.entityStatsMap = this.entityStatsService.getDocs().pipe(
      map((unfilteredMap) => {
        const filteredMap = new Map<string, EntityStats>();
        const nonUserKeys: Set<string> = new Set(Array.from(Object.values(EntityStatsType)));
        for (const [key, value] of unfilteredMap.entries()) {
          if (nonUserKeys.has(key)) {
            continue;
          }
          filteredMap.set(key, value);
        }

        return filteredMap;
      }),
      this.loader.entityStats.track(),
      shareReplay(1),
    );
    this.allUserDataMap = this.userDataService.getDocs().pipe(this.loader.allDataUser.track(), shareReplay(1));
    this.lastRefreshTime = this.entityStatsMap.pipe(
      map((allEntitiesMap) => moment.max(Array.from(allEntitiesMap.values()).map((z) => z.getLastUploadTime()))),
      this.loader.lastRefreshTime.track(),
      shareReplay(1),
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;
    formConfig.slidingWindowDurationDays = [EntityStatsSchema.Constants.slidingWindowDurationDays[3], [Validators.required]];
    this.form = this.formBuilder.group(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get slidingWindowDurationDays(): AbstractControl | null {
    return this.form.get('slidingWindowDurationDays');
  }
}
