import {
  AllDataUser,
  DBUploadExistingDoc,
  EventData,
  EventDataTransactionDisplay,
  getSplitTransaction,
  makeSplitEventDataTransactionDisplay,
  OrgData,
} from '@pwp-common';

import { KeyType, KVPair } from '../objects/kvpair';

/**
 * Given a list of strings, return a list where each element is repeated N times.
 *
 * @param items Input list of strings
 * @param numRepeat Number of times to repeat each element.
 */
export const splitEventTransactionInPlace = (
  transactions: KVPair<EventDataTransactionDisplay>[],
  orgData: OrgData,
  ids: Set<KeyType>,
  allDataUserMap: Map<string, AllDataUser>,
  splitTime: moment.Moment,
): void => {
  for (let i = 0; i < transactions.length; i++) {
    const transaction = transactions[i];
    if (!ids.has(transaction.getId())) {
      continue;
    }

    if (!transaction.value.isSimpleUpdate()) {
      throw new Error('splitEventTransactionInPlace: Only one simple updates allowed.');
    }
    const lastEvent = (transaction.value.actions[0] as DBUploadExistingDoc<EventData>).obj as EventData;

    const splitTransaction = getSplitTransaction(lastEvent, splitTime);
    transactions[i] = new KVPair({
      id: transaction.getId(),
      value: makeSplitEventDataTransactionDisplay(allDataUserMap, orgData, splitTransaction),
    });
  }
};
