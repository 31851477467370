import {cloneDeep} from 'lodash';
import {DBDocObject} from '../../generic/db-doc/db-doc-object';
import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {VoicemailMetadataSchema} from './voicemail-metadata-schema';
import {VoicemailMetadataConstructor} from './voicemail-metadata-constructor';

export class VoicemailMetadata extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected sessionId!: string;
  protected recordingSid!: string;
  protected recordingStartTime!: moment.Moment;
  protected durationMS!: number;
  protected recordingStatus!: string;
  protected errorCode!: number;
  protected assignedTo: string | undefined;
  protected listenedToBy!: string[];
  protected voicemailConfigId!: string;
  protected blobDeleted!: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: VoicemailMetadataConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): VoicemailMetadata {
    return new VoicemailMetadata(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(VoicemailMetadata.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new VoicemailMetadataSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getSessionId(): string {
    return cloneDeep(this.sessionId);
  }

  public getRecordingSid(): string {
    return cloneDeep(this.recordingSid);
  }

  public getRecordingStartTime(): moment.Moment {
    return cloneDeep(this.recordingStartTime);
  }

  public getDurationMS(): number {
    return cloneDeep(this.durationMS);
  }

  public getRecordingStatus(): string {
    return cloneDeep(this.recordingStatus);
  }

  public getErrorCode(): number {
    return cloneDeep(this.errorCode);
  }

  public getAssignedTo(): string | undefined {
    return cloneDeep(this.assignedTo);
  }

  public getListenedToBy(): string[] {
    return cloneDeep(this.listenedToBy);
  }

  public getVoicemailConfigId(): string {
    return cloneDeep(this.voicemailConfigId);
  }

  public getBlobDeleted(): boolean {
    return cloneDeep(this.blobDeleted);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setAssignedTo(userId: string): VoicemailMetadata {
    this.assignedTo = userId;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
