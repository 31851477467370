import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from '@ngneat/transloco';
import Joi from 'joi';

export class URLValidator {
  private static urlSchema = Joi.object({
    url: Joi.string()
      .uri({ scheme: ['http', 'https'] })
      .required(),
  });

  ////////////////////////////////////////////////////////////////////////
  // URL
  ////////////////////////////////////////////////////////////////////////

  /**
   *
   * @param value
   * @param timezone Datetime controls return timestamp as strings without timezone information. Interpret in the given timezone.
   * Use local timezone if undefined
   * @returns
   */
  public static url(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const error: Record<string, unknown> = { url: { value: control.value, errorCode: undefined } };
      const inputStr: string = control.value;
      if (inputStr.length === 0) {
        return null;
      }

      if (!inputStr.startsWith('https://') && !inputStr.startsWith('http://')) {
        error.errorCode = 'isNotHttpOrHttps';
        return error;
      }

      const validation = URLValidator.urlSchema.validate({ url: inputStr });
      let valid = false;
      if (isNil(validation.error) && isNil(validation.errors) && isNil(validation.warning) && validation.value?.url === inputStr) {
        valid = true;
      }

      if (!valid) {
        return error;
      }
      return null;
    };
  }
}
