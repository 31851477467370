import {DBTransaction} from '../../../db/transaction/db-transaction';
import {DBTransactionType} from '../../../db/transaction/db-transaction-type';
import {DBUploadExistingDoc} from '../../../db/transaction/actions/db-upload-existing-doc';
import {AllDataUser} from '../../user/all-data-user/all-data-user';
import {EventData} from '../event-data/event-data';
import {EventDataTransactionDisplay} from './event-data-transaction-display';
import {OrgData} from '../../org/org-data/org-data';

export const makeSimpleUploadEventDataTransactionDisplay = (
  allDataUserMap: Map<string, AllDataUser>,
  orgData: OrgData,
  eventData: EventData
): EventDataTransactionDisplay => {
  return new EventDataTransactionDisplay(
    allDataUserMap,
    orgData,
    DBTransactionType.update,
    [new DBUploadExistingDoc(eventData)],
    eventData.getId(),
    eventData.getStart(),
    eventData.getEnd(),
    eventData.getAssignedUserId(),
    eventData.getAssignedBackupUserId(),
    eventData.getColor(),
    eventData.getType()
  );
};

export const makeSplitEventDataTransactionDisplay = (
  allDataUserMap: Map<string, AllDataUser>,
  orgData: OrgData,
  transaction: DBTransaction<EventData>
): EventDataTransactionDisplay => {
  const firstEventData = (transaction.actions[0] as DBUploadExistingDoc<EventData>).obj;
  const secondEventData = (transaction.actions[1] as DBUploadExistingDoc<EventData>).obj;

  return new EventDataTransactionDisplay(
    allDataUserMap,
    orgData,
    DBTransactionType.split,
    transaction.actions,
    firstEventData.getId(),
    firstEventData.getStart(),
    secondEventData.getEnd(),
    firstEventData.getAssignedUserId(),
    firstEventData.getAssignedBackupUserId(),
    firstEventData.getColor(),
    firstEventData.getType()
  );
};
