<ng-container *transloco="let t; read: 'service-exceptions-table'">
  <app-data-table
    [actionsTemplate]="actionsTemplate"
    [cols]="cols"
    [expansionRow]="expansionRow"
    [loading]="loading"
    [refreshClicked]="getDataWrapper"
    [selectedCols]="cols"
    [tableRows]="tableRows"
    [title]="t('title')"
    [translationScope]="'service-exceptions-table'"
  >
    <ng-template #actionsTemplate>
      <p-button
        pTooltip="{{ t('createServiceExceptionButtonTooltip') }}"
        icon="pi pi-plus"
        styleClass="p-button-outlined"
        [ariaLabel]="t('createServiceExceptionButtonLabel')"
        [label]="t('createServiceExceptionButtonLabel')"
        (onClick)="createServiceException()"
      >
      </p-button>
    </ng-template>
    <ng-template #expansionRow let-rowData>
      <app-service-exception-editor-with-save [allDataServiceException]="rowData.allDataServiceException">
      </app-service-exception-editor-with-save>
    </ng-template>
  </app-data-table>
</ng-container>
