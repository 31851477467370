import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { isEmpty } from 'lodash';

export const callerIdStrategyValidator = (
  control: FormGroup<{ callerIdObjId: FormControl<string>; type: FormControl<string> }>,
): ValidationErrors | null => {
  const { callerIdObjId, type } = control.value;

  const isCallerIdEmpty = isEmpty(callerIdObjId);
  const isTypeEmpty = isEmpty(type);

  if (isCallerIdEmpty === isTypeEmpty) {
    return null;
  }

  return {
    incomplete: {
      callerIdObjId: isCallerIdEmpty,
      type: isTypeEmpty,
    },
  };
};
