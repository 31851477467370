export enum WebchatActionType {
  chatLoaded = 'webchat:chatLoaded',
  redirectFromChat = 'webchat:redirectFromChat',
}

interface WebchatActionBase {
  type: WebchatActionType;
}

export interface WebchatActionChatLoaded {
  type: WebchatActionType.chatLoaded;
}

export interface WebchatActionRedirectFromChat extends WebchatActionBase {
  url: string;
  type: WebchatActionType.redirectFromChat;
}

export type WebchatAction = WebchatActionChatLoaded | WebchatActionRedirectFromChat;
