import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { Form } from '@pwp-common';

import { DbDocumentService } from '../../../services/generic/db-document-service';
import { AuthService } from '../../../services/user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class FormService extends DbDocumentService<Form> {
  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, Form);
  }
}
