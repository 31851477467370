import { Directive, inject } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { map, Observable } from 'rxjs';

import { MenuOptionSearchDirective } from '../../../common/form/directives/menu-option-search.directive';
import { MenuOption } from '../../../common/form/typings/menu-options';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';

@UntilDestroy()
@Directive({
  selector: '[appEventTypeSearch]',
  standalone: true,
})
export class EventTypeSearchDirective extends MenuOptionSearchDirective<string> {
  private readonly orgDataService = inject(OrgDataService);

  public readonly results$: Observable<MenuOption<string>[]> = this.orgDataService.getOrgData().pipe(
    map((orgData) =>
      orgData.getEventTypes().map((eventType) => ({
        label: eventType.getDisplayName(),
        value: eventType.getId(),
      })),
    ),
  );
}
