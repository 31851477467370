import { CustomAction } from '../chat-component/interfaces';

export enum PerConversationAction {
  closeAndDeleteConversation = 'closeAndDeleteConversation',
}

export type PerConversationActionType = keyof typeof PerConversationAction;

export interface PerConversationActionMenuItem extends CustomAction {
  name: PerConversationActionType;
  title: PerConversationActionType;
}
