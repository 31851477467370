import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { EventDataSchema, FieldChangeEnum } from '@pwp-common';
@Component({
  selector: 'app-color-dropdown',
  templateUrl: './color-dropdown.component.html',
  styleUrls: ['./color-dropdown.component.css'],
})
export class ColorDropdownComponent {
  ///////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////

  myControl = new UntypedFormControl();

  noChange = FieldChangeEnum.noChange;

  resetDefault = FieldChangeEnum.resetDefault;

  reservedColor = EventDataSchema.Colors.reserved;

  ///////////////////////////////////////////////////////////////////////
  // Get/Set Selected Color
  ///////////////////////////////////////////////////////////////////////

  _selectedColor: string | undefined;

  @Input()
  get selectedColor(): string | undefined {
    return this._selectedColor;
  }

  set selectedColor(value) {
    this._selectedColor = value;
    this.selectedColorChange.emit(value);

    // Update the value displayed in the UI if necessary
    const uiValue = this.myControl.value;
    if (uiValue !== this._selectedColor) {
      this.myControl.setValue(this._selectedColor);
    }
  }

  @Output()
  selectedColorChange = new EventEmitter<string | undefined>();
}
