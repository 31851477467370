export enum FieldChangeEnum {
  noChange = 'noChange',
  resetDefault = 'resetDefault',
}

export type FieldChangeTypes = FieldChangeEnum.noChange | FieldChangeEnum.resetDefault;

export const isFieldChange = (value: string): boolean => {
  const values = [FieldChangeEnum.noChange, FieldChangeEnum.resetDefault] as string[];
  return values.includes(value);
};
