/**
 * Each user we dial is a callee entity. For example, we can dial a list
 * of users (eg, a hardcoded list of backup users). We call this calleeEntity
 * a 'userList'. We can also dial all users assigned as 'primary' for an
 * event of some type. We call this an 'event'.
 */
export enum CalleeEntityType {
  event = 'event',
  userList = 'userList',
}
