import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {UserRequestName} from '../../../user-request-name';

export abstract class UserRequestSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class {
    public static readonly type = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters: {[key: string]: AnySchema} = {};

    parameters[UserRequestSchema.type] = SchemaField.string(UserRequestSchema.Defaults.type)
      .required()
      .valid(...Object.values(UserRequestName));

    return parameters;
  }
}
