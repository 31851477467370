import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs/operators';

import { ConversationMessageTemplateType } from '@pwp-common';

import { ConversationConfigService } from '../../../../services/conversation/conversation-config/conversation-config.service';

@UntilDestroy()
@Component({
  selector: 'app-conversation-message-template-editor-service-closed',
  templateUrl: './conversation-message-template-editor-service-closed.component.html',
  styleUrls: ['./conversation-message-template-editor-service-closed.component.css'],
})
export class ConversationMessageTemplateEditorServiceClosedComponent implements OnInit {
  ////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////

  serviceClosed = ConversationMessageTemplateType.serviceClosed;

  loading = true;

  ////////////////////////////////////////////////////////////////////////////
  // Variables
  ////////////////////////////////////////////////////////////////////////////

  willSometimesExclude: boolean;

  ////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////
  constructor(
    private conversationConfigService: ConversationConfigService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.conversationConfigService
      .getUniqueDoc({ query: [] })
      .pipe(
        untilDestroyed(this),
        map((obj) => {
          const result = !obj.getAvailableTimes().willAlways(true);
          this.loading = false;
          this.willSometimesExclude = result;
          this.changeDetectorRef.detectChanges();
          
        }),
      )
      .subscribe();
  }
}
