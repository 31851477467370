<!-- Dial Call List Command -->
<form *ngIf="!loading" [formGroup]="form">
  <mat-form-field class="full-width">
    <input
      matInput
      placeholder="Number of times to dial each user"
      class="full-width"
      formControlName="numTimesDialEachUser"
      type="number"
    />
    <mat-error *ngIf="numTimesDialEachUser.errors?.min && numTimesDialEachUser.dirty">
      Min is {{ numTimesDialEachUser.errors?.min.min }}
    </mat-error>
    <mat-error *ngIf="numTimesDialEachUser.errors?.max && numTimesDialEachUser.dirty">
      Max is {{ numTimesDialEachUser.errors?.max.max }}
    </mat-error>
  </mat-form-field>

  <!-- Call List Select -->
  <app-obj-select
    [label]="'Call List Select'"
    [orderedItems]="callListArray"
    [(kvPair)]="callListKVPair"
    (kvPairChange)="updateObjAndEmit()"
  ></app-obj-select>
</form>

<!-- Is Valid Indicator -->
<app-obj-is-valid *ngIf="!loading" [isValidObj]="isValidObjScoper"></app-obj-is-valid>
