import { isNil } from 'lodash';

import { RowCell } from '../../../../../common/p-table/row-cell';

export const createValueCell = <T>(rowName: string, value: T): RowCell<T> => {
  if (!isNil(value)) {
    return new RowCell({
      translationScope: 'admin-report-row',
      translationKey: 'cellValue',
      translationObj: { value },
      sortValue: value,
    });
  }

  return new RowCell({
    translationScope: 'admin-report-row',
    translationKey: 'cellValueMissing',
    sortValue: value,
  });
};
