<ng-container *transloco="let t; read: 'event-requests-table-one-event'">
  <!-- Required to configure toast notifications -->
  <p-toast position="bottom-center"></p-toast>

  <p-card>
    <div class="grid">
      <div class="col-6">
        <div class="field grid">
          <label class="col-12 mb-2 md:col-2 md:mb-0">{{ t('assignedPrimary') }}:</label>
          <div class="col-12 md:col-10">
            {{ allRequestsForOneEvent?.allDataEventDisplay?.primaryAllDataUserDisplay?.displayName ?? '-' }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="field grid">
          <label class="col-12 mb-2 md:col-2 md:mb-0">{{ t('assignedBackup') }}:</label>
          <div class="col-12 md:col-10">
            {{ allRequestsForOneEvent?.allDataEventDisplay?.backupAllDataUserDisplay?.displayName ?? '-' }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="field grid">
          <label class="col-12 mb-2 md:col-2 md:mb-0">{{ t('lastModifiedBy') }}:</label>
          <div class="col-12 md:col-10">
            {{ allRequestsForOneEvent?.allDataEventDisplay?.lastModifiedBy?.displayName ?? '-' }}
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="field grid">
          <label class="col-12 mb-2 md:col-2 md:mb-0">{{ t('lastModifiedTimestamp') }}:</label>
          <div class="col-12 md:col-10">
            {{
              t(
                allRequestsForOneEvent?.eventLastUploadTime['translationKey'],
                allRequestsForOneEvent?.eventLastUploadTime['translationObj']
              )
            }}
          </div>
        </div>
      </div>
    </div>

    <legend>{{ t('title') }}</legend>
    {{ t('sortedByRequestTime') }}
    <p-table
      #dt
      dataKey="id"
      responsiveLayout="scroll"
      styleClass="p-datatable-gridlines"
      [columns]="cols"
      [loading]="loading"
      [value]="allRequestsForOneEvent?.decisionRows"
    >
      <!-- Header -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns">
            {{ t(col.header) }}
          </th>
        </tr>
      </ng-template>

      <!-- Display Rows -->
      <ng-template pTemplate="body" let-columns="columns" let-decisionRow let-expanded="expanded" let-index="rowIndex">
        <tr [ngClass]="decisionRow.alertType">
          <td *ngFor="let col of columns" class="ui-resizable-column">
            <div [ngSwitch]="col.field">
              <div *ngSwitchCase="'action'">
                <div *ngIf="!decisionRow.isOpen">
                  <span [class]="decisionRow['requestStatus'].class">{{
                    t(decisionRow['requestStatus'].translationKey, decisionRow['requestStatus'].translationObj)
                  }}</span>
                </div>
              </div>
              <div *ngSwitchDefault>
                <span [class]="decisionRow[col.field].styleClass">{{
                  t(decisionRow[col.field].translationKey, decisionRow[col.field].translationObj)
                }}</span>
              </div>
            </div>
          </td>
        </tr>
      </ng-template>

      <!-- Empty Message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td>
            {{ t('emptyMessage') }}
          </td>
        </tr>
      </ng-template>
    </p-table>
  </p-card>
</ng-container>
