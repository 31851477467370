<ng-container *transloco="let t; read: 'create-callback-request-dialog'">
  <p-toast position="bottom-center"></p-toast>

  <!--  Content -->
  <form [formGroup]="form">
    <!-- E164Phone   -->
    <div class="flex flex-column mb-3">
      <label for="e164Phone">{{ t('e164PhoneLabel') }}</label>
      <div>
        <app-phone-editor [formControl]="e164Phone" [inputId]="'e164Phone'"></app-phone-editor>
        <span class="block text-sm" id="e164Phone-help">{{ t('e164PhoneHelp') }}</span>
      </div>
    </div>

    <!-- Communication Widget   -->
    <div class="flex flex-column mb-3">
      <label for="communicationWidgetId">{{ t('communicationWidgetIdLabel') }}</label>
      <div>
        <app-create-async-service-request-autocomplete
          [formControl]="communicationWidgetId"
          [inputId]="'communicationWidgetId'"
        ></app-create-async-service-request-autocomplete>
      </div>
    </div>

    <!-- Note -->

    <div class="flex flex-column mb-3">
      <label for="create-callback-request-note">{{ t('noteLabel') }}</label>
      <div>
        <textarea pInputTextarea class="w-full" id="create-callback-request-note" rows="6" [formControl]="note"></textarea>
        <span class="block text-sm">{{ t('noteHelp') }}</span>
      </div>
    </div>
  </form>

  <!--  Footer-->
  <div class="flex flex-wrap gap-3 justify-content-end">
    <div class="flex-grow-1 flex-shrink-1 w-max">{{ t('notice') }}</div>
    <div class="flex gap-2">
      <p-button label="{{ t('cancelButtonLabel') }}" styleClass="p-button-outlined" type="button" (onClick)="cancelClick()"></p-button>
      <p-button
        label="{{ t('confirmButtonLabel') }}"
        type="button"
        [disabled]="!form.valid || createCallbackAPIPending"
        [loading]="createCallbackAPIPending"
        (onClick)="confirmClick()"
      ></p-button>
    </div>
  </div>
</ng-container>
