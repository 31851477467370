import {SchemaField} from '../../generic/serialization/schema-field';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {AssignedUserStatTypes} from '../assigned-user-type-stats/assigned-user-stats-types';
import {AssignedUserTypeStats} from '../assigned-user-type-stats/assigned-user-type-stats';

export class EventStatsSchema extends SerializableObjectSchema {
  public static readonly assignedUserTypeStats = 'assignedUserTypeStats';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly assignedUserTypeStats = new Map<AssignedUserStatTypes, AssignedUserTypeStats>([
      [AssignedUserStatTypes.any, AssignedUserTypeStats.deserialize({})],
      [AssignedUserStatTypes.primary, AssignedUserTypeStats.deserialize({})],
      [AssignedUserStatTypes.backup, AssignedUserTypeStats.deserialize({})],
    ]);
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[EventStatsSchema.assignedUserTypeStats] = SchemaField.mapOfObj(AssignedUserTypeStats, EventStatsSchema.Defaults.assignedUserTypeStats);
    /* eslint-enable */

    return parameters;
  }
}
