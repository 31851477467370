import {MessageTemplateContextType} from './message-template-context/message-template-context-type';

/**
 * Window of expected trigger time where messages will
 * still be sent.
 *
 * Note: Saw one example where cron job ran 3 minutes and 2 seconds
 * after the hour.
 **/
export const MESSAGE_TEMPLATE_SEND_WINDOW_ERR_MINUTES = 4;
export const SCHEDULED_MESSAGE_TEMPLATES = [
  MessageTemplateContextType.shiftsToday,
  MessageTemplateContextType.shiftsTomorrow,
];
