import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AdminRoles } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from "../auth/auth.service";

@Injectable({
  providedIn: 'root',
})
export class AdminRolesService extends DbDocumentService<AdminRoles> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, AdminRoles);
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Org Data
  ///////////////////////////////////////////////////////////////////////

  public getAdminRoles(): Observable<AdminRoles> {
    const observable = this.getInjectedAuthService()
      .getUserId()
      .pipe(switchMap((userId: string, _) => this.getDoc(userId, AdminRoles.deserialize({}))));
    return observable;
  }
}
