import { AbstractControl, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

export class DateRangeValidator {
  public static isComplete(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const dateRange: Date[] = control.value;

      if (isNil(dateRange)) {
        return null;
      }

      if (dateRange.length !== 2 || isNil(dateRange[0]) || isNil(dateRange[1])) {
        return { isComplete: { dateRange } };
      }

      return null;
    };
  }
}
