<form>
  <mat-form-field>
    <mat-label> {{ label }} </mat-label>
    <mat-select multiple [compareWith]="compareObjs" [formControl]="formControl" (selectionChange)="selectionChanged($event.value)">
      <mat-option *ngFor="let item of orderedItems; let i = index" [value]="{ id: i, value: item }">
        {{ item?.getDisplayName() || 'MISSING_DISPLAY_NAME' }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</form>
