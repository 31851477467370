<ng-container *transloco="let t; read: 'conversation-settings'">
  <!---------------------------------------------->
  <!-- Getting Started -->
  <!---------------------------------------------->

  <app-conversation-getting-started></app-conversation-getting-started>

  <!---------------------------------------------->
  <!-- First Send -->
  <!---------------------------------------------->

  <app-conversation-message-template-editor-first-send></app-conversation-message-template-editor-first-send>

  <!---------------------------------------------->
  <!-- On Inactive -->
  <!---------------------------------------------->

  <app-conversation-message-template-editor-on-inactive></app-conversation-message-template-editor-on-inactive>

  <!---------------------------------------------->
  <!-- Available Times Config -->
  <!---------------------------------------------->

  <app-conversation-config-editor></app-conversation-config-editor>
  <app-conversation-message-template-editor-service-closed></app-conversation-message-template-editor-service-closed>
</ng-container>
