/**
 * For details see
 * https://support.twilio.com/hc/en-us/articles/223179988-Why-am-I-getting-calls-from-these-strange-numbers-
 */
export const ANONYMOUS_PHONES_SORTED = [
  '2562533', // BLOCKED
  '266696687', // ANONYMOUS
  '464', // EMPTY or No Caller ID
  '7378742833', // RESTRICTED
  '86282452253', // UNAVAILABLE
  '8656696', // UNKNOWN
];
