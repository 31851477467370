import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {TemplateText} from '../../text/template-text/template-text';

export class ConversationWaitingRoomSchema extends SerializableObjectSchema {
  public static readonly queuePosition = 'queuePosition';
  public static readonly waitingRoomMessage = 'waitingRoomMessage';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly queuePosition = undefined;
    public static readonly waitingRoomMessage = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ConversationWaitingRoomSchema.queuePosition] = SchemaField.number(ConversationWaitingRoomSchema.Defaults.queuePosition).required();
    parameters[ConversationWaitingRoomSchema.waitingRoomMessage] = SchemaField.obj(TemplateText, ConversationWaitingRoomSchema.Defaults.waitingRoomMessage).required();
    /* eslint-enable */

    return parameters;
  }
}
