import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';

export class ReservationOfferChannelConfigPhoneCallSchema extends SerializableObjectSchema {
  public static readonly dialTimeoutMS = 'dialTimeoutMS';
  public static readonly callerIdObjId = 'callerIdObjId';
  public static readonly vrcAudioMetadataId = 'vrcAudioMetadataId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly dialTimeoutMS = undefined;
    public static readonly callerIdObjId = undefined;
    public static readonly vrcAudioMetadataId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ReservationOfferChannelConfigPhoneCallSchema.dialTimeoutMS] = SchemaField.number(ReservationOfferChannelConfigPhoneCallSchema.Defaults.dialTimeoutMS).required()
    // TODO: make callerIdObjId required. Need to investigated UI to ensure that it's not possible to save this object without a callerIdObjId
    parameters[ReservationOfferChannelConfigPhoneCallSchema.callerIdObjId] = SchemaField.autoGeneratedDocId(ReservationOfferChannelConfigPhoneCallSchema.Defaults.callerIdObjId, ReservationOfferChannelConfigPhoneCallSchema.Defaults.callerIdObjId).optional()
    parameters[ReservationOfferChannelConfigPhoneCallSchema.vrcAudioMetadataId] = SchemaField.autoGeneratedDocId(ReservationOfferChannelConfigPhoneCallSchema.Defaults.vrcAudioMetadataId, ReservationOfferChannelConfigPhoneCallSchema.Defaults.vrcAudioMetadataId).required()
    /* eslint-enable */

    return parameters;
  }
}
