import { isNil } from 'lodash';

import { UserData } from '@pwp-common';

import { WorkerEditorOutputItem } from '../interface';

export const makeUserOption = (userId: string, user: UserData | undefined): WorkerEditorOutputItem => {
  if (isNil(user)) {
    return {
      type: 'deletedUser',
      userId,
      displayName: 'deletedUser',
      searchString: `deletedUser ${userId}`,
    };
  }

  if (isNil(userId) || user.getId() !== userId) {
    console.error(userId, user);
    throw new Error('makeUserOption: User Error. The userId is nil, or does not match the passed in user object');
  }

  return {
    type: 'user',
    userId: user.getId(),
    displayName: user.getDisplayNameWithEmail(),
    searchString: `user ${user.getDisplayNameWithEmail().toLowerCase()}`,
  };
};
