import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { ToastModule } from 'primeng/toast';

import { PhoneEditorModule } from '../phone-editor/phone-editor.module';

import { CreateCallbackRequestButtonComponent } from './create-callback-request-button/create-callback-request-button.component';
import { CreateCallbackRequestComponent } from './create-callback-request/create-callback-request.component';
import { CreateAsyncServiceRequestAutocompleteComponent } from './select/create-async-service-request-autocomplete/create-async-service-request-autocomplete.component';

@NgModule({
  declarations: [CreateAsyncServiceRequestAutocompleteComponent, CreateCallbackRequestButtonComponent, CreateCallbackRequestComponent],
  exports: [CreateCallbackRequestButtonComponent],
  imports: [
    AutoCompleteModule,
    ButtonModule,
    CommonModule,
    InputTextareaModule,
    MatIconModule,
    PhoneEditorModule,
    ReactiveFormsModule,
    ToastModule,
    TranslocoModule,
  ],
})
export class CommunicationWidgetModule {}
