import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {values} from 'lodash';
import {ConversationUserIdentityType} from './conversation-participant-id-type';
import {ConversationParticipantSummaryChannel} from './conversation-participant-channel';
import {ConversationMessageDigest} from '../conversation-message-digest/conversation-message-digest';

export class ConversationParticipantSummarySchema extends SerializableObjectSchema {
  public static readonly participantIdType = 'participantIdType';
  public static readonly participantId = 'participantId';
  public static readonly channel = 'channel';

  public static readonly participantCreateTime = 'participantCreateTime';
  public static readonly participantUpdateTime = 'participantUpdateTime';

  public static readonly firstSend = 'firstSend';
  public static readonly lastSend = 'lastSend';
  public static readonly lastRead = 'lastRead';
  public static readonly numSent = 'numSent';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly participantId = undefined;
    public static readonly participantIdType = undefined;
    public static readonly channel = undefined;

    public static readonly participantCreateTime = undefined;
    public static readonly participantUpdateTime = undefined;

    public static readonly firstSend = undefined;
    public static readonly lastSend = undefined;
    public static readonly lastRead = undefined;

    public static readonly numSent = 0;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ConversationParticipantSummarySchema.participantIdType] = SchemaField.string(ConversationParticipantSummarySchema.Defaults.participantIdType).allow(...values(ConversationUserIdentityType)).required();
    parameters[ConversationParticipantSummarySchema.participantId] = SchemaField.string(ConversationParticipantSummarySchema.Defaults.participantId);
    parameters[ConversationParticipantSummarySchema.channel] = SchemaField.string(ConversationParticipantSummarySchema.Defaults.channel).allow(...values(ConversationParticipantSummaryChannel)).required();

    parameters[ConversationParticipantSummarySchema.participantCreateTime] = SchemaField.timestamp(ConversationParticipantSummarySchema.Defaults.participantCreateTime);
    parameters[ConversationParticipantSummarySchema.participantUpdateTime] = SchemaField.timestamp(ConversationParticipantSummarySchema.Defaults.participantUpdateTime);

    parameters[ConversationParticipantSummarySchema.firstSend] = SchemaField.obj(ConversationMessageDigest, ConversationParticipantSummarySchema.Defaults.firstSend).required();
    parameters[ConversationParticipantSummarySchema.lastSend] = SchemaField.obj(ConversationMessageDigest, ConversationParticipantSummarySchema.Defaults.lastSend).required();
    parameters[ConversationParticipantSummarySchema.lastRead] = SchemaField.obj(ConversationMessageDigest, ConversationParticipantSummarySchema.Defaults.lastRead);

    parameters[ConversationParticipantSummarySchema.numSent] = SchemaField.number(ConversationParticipantSummarySchema.Defaults.numSent).required();
    /* eslint-enable */

    return parameters;
  }
}
