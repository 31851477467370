import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {EventRequestSchema} from '../../generic/event-request/event-request-schema';
import {EventRequestTransactionItem} from '../../../../../objects/event/event-request-transaction-item/event-request-transaction-item';

export class EventRequestTransactionSchema extends EventRequestSchema {
  public static readonly items = 'items';
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly items = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[EventRequestTransactionSchema.items] = SchemaField.shortObjArray(EventRequestTransactionItem,EventRequestTransactionSchema.Defaults.items).required();
    /* eslint-enable */

    return parameters;
  }
}
