import Joi from 'joi';
import type {AnySchema} from 'joi';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {NoteBodyTextSchema} from '../../type/note-body-text/note-body-text-schema';

export class AnyNoteBodySchema extends SerializableObjectSchema {
  /////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyNote';

  private static readonly computedSchema = Joi.alternatives().try(new NoteBodyTextSchema().getJoiSchema()).id(AnyNoteBodySchema.SCHEMA_ID);

  /////////////////////////////////////////////////////////////////////////////
  // Defaults
  /////////////////////////////////////////////////////////////////////////////

  public static readonly GenericDefaults = class {
    public static readonly type = undefined;
  };

  public static getSchemaId(): string {
    return `#${AnyNoteBodySchema.SCHEMA_ID}`;
  }

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  /////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): AnySchema {
    return AnyNoteBodySchema.computedSchema;
  }
}
