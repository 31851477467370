import { EntityStats } from '@pwp-common';

import { createReportRow } from '../create-report-row/create-report-row';
import { createValuesForEntityStats } from '../create-values-for-entity-stats/create-values-for-entity-stats';
import { formatUsersWithDuration } from '../formatting/format-users-with-duration';
import { getUsersWithHighestCount } from '../get-users-with-highest-count/get-users-with-highest-count';
import { AdminReportUserCallStatsRowInput, ReportRow } from '../interfaces';

export const createUserWhoHasTheMostTalkMinutesRow = ({
  entityStats,
  usersStatsData,
  callList,
}: AdminReportUserCallStatsRowInput): ReportRow =>
  createReportRow(
    'userWhoHasTheMostTalkMinutes',
    createValuesForEntityStats(entityStats, (_, period) => {
      const getUserTalkTime = (userStats: EntityStats) => userStats.getChunkByPeriod(period)?.getCalls(callList)?.getIMsTalkDuration() ?? 0;

      const usersWithMostTalkMinutes = getUsersWithHighestCount(usersStatsData, getUserTalkTime);

      return formatUsersWithDuration(usersWithMostTalkMinutes);
    }),
    { callList },
  );
