import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import parsePhoneNumber from 'libphonenumber-js';
import { isNil } from 'lodash';

import { PhoneValidator } from '../../../common/validators/phone-validator/phone-validator';
import { FormGroupControlValueAccessor } from '../../generic/abstract-classes/form-group-control-value-accessor';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PhoneEditorComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PhoneEditorComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-phone-editor',
  templateUrl: './phone-editor.component.html',
  styleUrls: ['./phone-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class PhoneEditorComponent extends FormGroupControlValueAccessor<any, any> {
  private static id = 0;
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  public defaultId = `app-phone-editor-${(PhoneEditorComponent.id += 1)}`;

  @Input() public inputId?: string;

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    const formConfig = {} as any;
    formConfig.e164Phone = new UntypedFormControl(undefined, [PhoneValidator.isValidPhone()]);
    this.form = new UntypedFormGroup(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  public writeValue(value: any): void {
    const parsedPhone = parsePhoneNumber(value ?? '', 'US');
    if (isNil(parsedPhone) || parsedPhone.number.length !== 12 || parsedPhone.country !== 'US') {
      this.form.patchValue({ e164Phone: '' }, { emitEvent: true });
      this.changeDetectorRef.detectChanges();
      return;
    }
    const nationalPhone = parsedPhone?.format('NATIONAL');
    this.form.patchValue({ e164Phone: nationalPhone }, { emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): any {
    if (isNil(this.e164Phone.value)) {
      return undefined;
    }
    const parsedPhone = parsePhoneNumber(this.e164Phone.value, 'US');
    const e164Phone = parsedPhone?.format('E.164');
    if (isNil(parsedPhone) || parsedPhone.number.length !== 12 || parsedPhone.country !== 'US') {
      return undefined;
    }
    return e164Phone;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get e164Phone() {
    return this.form.get('e164Phone') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'e164-phone-editor': this.form.value,
    };
  }
}
