import { inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import { SurveyCreatorModel } from 'survey-creator-core';

import { FormBuilderVendor, FormRequestCreateVersion, FormVersion } from '@pwp-common';

import { serializeToJsonString } from '../../../../../../../common/src/objects/generic/serialization/serialize-to-json-string/serialize-to-json-string';
import { DbDocumentService } from '../../../services/generic/db-document-service';
import { AuthService } from '../../../services/user/auth/auth.service';

import { FormEndpointService } from './form-endpoint.service';
import { FormService } from './form.service';

@Injectable({
  providedIn: 'root',
})
export class FormVersionService extends DbDocumentService<FormVersion> {
  private formEndpointService = inject(FormEndpointService);

  private formService = inject(FormService);

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, FormVersion);
  }

  public async getActiveFormVersion(formId: string): Promise<FormVersion | null> {
    const formVersion = await firstValueFrom(this.formService.getDoc(formId));

    if (!formVersion) {
      return null;
    }

    return firstValueFrom(this.getDoc(formVersion.getActiveVersionId()));
  }

  public async createSurveyJSVersion(formId: string, surveyJSModel: SurveyCreatorModel): Promise<void> {
    const { title, description } = surveyJSModel.JSON;

    await this.formEndpointService.makeRequest(
      new FormRequestCreateVersion({
        description,
        displayName: title,
        formId,
        formVersionId: this.createId(),
        value: serializeToJsonString(surveyJSModel.JSON),
        vendor: FormBuilderVendor.surveyJS,
      }),
    );
  }
}
