import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { map } from 'rxjs';

import { OrgDataService } from '../../services/orgs/org-data/org-data.service';

@Component({
  selector: 'app-org-settings',
  templateUrl: './org-settings.component.html',
  styleUrls: ['./org-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrgSettingsComponent {
  private readonly orgDataService = inject(OrgDataService);

  public readonly isTestingOrg$ = this.orgDataService.getOrgData().pipe(map((orgData) => orgData?.getId() === 'S6E04dqIvOg0ljfAeLgr'));
}
