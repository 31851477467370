import {cloneDeep} from 'lodash';
import {DBDocObject} from '../../../../../generic/db-doc/db-doc-object';
import {DBDocSchema} from '../../../../../generic/db-doc/db-doc-schema';
import {UserStatus} from '../../../../../call/dialed-call-log/user-status';
import {CommunicationTaskName} from '../../../../communication-task/generic/communication-task-name';

export abstract class CommunicationSessionEventLogReservationOffer extends DBDocObject {
  protected communicationTaskType!: CommunicationTaskName;
  protected userStatus!: UserStatus | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    throw new Error('Error: Cannot get schema for abstract CommunicationSessionEventLogReservationOffer');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
  public getCommunicationTaskType() {
    return cloneDeep(this.communicationTaskType);
  }
  /**
   * This value should always be defined. It is marked as optional because
   * the log is written to the DB in parts, and this field may be missing depending
   * on which part of the log is written first.
   */
  public getUserStatus() {
    return cloneDeep(this.userStatus);
  }
}
