import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsRequestSchema} from '../../generic/communications-request/communications-request-schema';

export class CommunicationsRequestHandleAsyncServiceRequestSchema extends CommunicationsRequestSchema {
  public static readonly asyncServiceRequestId = 'asyncServiceRequestId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly asyncServiceRequestId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsRequestHandleAsyncServiceRequestSchema.asyncServiceRequestId] =  SchemaField.autoGeneratedDocId(CommunicationsRequestHandleAsyncServiceRequestSchema.Defaults.asyncServiceRequestId).required();
    /* eslint-enable */

    return parameters;
  }
}
