<ng-container *transloco="let t; read: 'caller-id-strategy-editor'">
  <form [formGroup]="form">
    <!-- CallerId strategy -->
    <div *ngrxLet="form.controls.type as type" class="field mt-3">
      <p-dropdown
        appendTo="body"
        attr.aria-label="{{ t('callerIdStrategyDropdownLabel') }}"
        id="callerIdStrategy"
        [formControl]="type"
        [options]="possibleValuesCallerIdStrategy"
        [placeholder]="t('callerIdStrategyLabel')"
      >
        <ng-template pTemplate="item" let-callerIdStrategy>
          <b class="wrap">{{ t(callerIdStrategy.label) }}</b
          ><br />
          <small class="wrap">{{ t(callerIdStrategy.description) }}</small>
        </ng-template>
        <ng-template pTemplate="selectedItem" let-callerIdStrategy>
          <span class="wrap">{{ t(callerIdStrategy.label) }}</span>
        </ng-template>
      </p-dropdown>

      <app-form-field-help [control]="form">
        <ng-container form-field-help-hint>
          {{ t('callerIdObjIdHelp') }}
        </ng-container>
        <ng-container form-field-help-errors>
          <ng-container *ngIf="form.errors?.incomplete?.type">
            {{ t('callerIdStrategyRequiredError') }}
          </ng-container>
        </ng-container>
      </app-form-field-help>
    </div>

    <!-- Caller Id -->
    <div *ngrxLet="form.controls.callerIdObjId as callerIdObjId" class="field mt-3">
      <span>
        <app-caller-id-autocomplete id="callerIdObjId" [formControl]="callerIdObjId" [placeholder]="t('callerIdObjIdPlaceholder')">
        </app-caller-id-autocomplete>

        <app-form-field-help [control]="form">
          <ng-container form-field-help-hint>
            {{ t('callerIdObjIdHelp') }}
          </ng-container>
          <ng-container form-field-help-errors>
            <ng-container *ngIf="form.errors?.incomplete?.callerIdObjId">
              {{ t('callerIdObjIdErrorRequired') }}
            </ng-container>
          </ng-container>
        </app-form-field-help>
      </span>
    </div>
  </form>
</ng-container>
