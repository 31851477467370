<ng-container *transloco="let t; read: 'confirmed-events-table'">
  <p-card>
    <ng-template pTemplate="header">
      <legend class="flex flex-wrap p-3">{{ t('confirmedEvents') }}</legend>
    </ng-template>
    <table mat-table [dataSource]="dataSource">
      <!-- Start Time -->
      <ng-container matColumnDef="startTime">
        <th *matHeaderCellDef mat-header-cell>{{ t('startTime') }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.startTime }}</td>
      </ng-container>

      <!-- End Time -->
      <ng-container matColumnDef="endTime">
        <th *matHeaderCellDef mat-header-cell>{{ t('endTime') }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.endTime }}</td>
      </ng-container>

      <!-- Type -->
      <ng-container matColumnDef="type">
        <th *matHeaderCellDef mat-header-cell>{{ t('type') }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.type }}</td>
      </ng-container>

      <!-- Assigned User -->
      <ng-container matColumnDef="assignedUser">
        <th *matHeaderCellDef mat-header-cell>{{ t('assignedUser') }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.primaryAllDataUserDisplay?.displayName || '' }}</td>
      </ng-container>

      <!-- Assigned Backup -->
      <ng-container matColumnDef="assignedBackup">
        <th *matHeaderCellDef mat-header-cell>{{ t('assignedBackup') }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.backupAllDataUserDisplay?.displayName || '' }}</td>
      </ng-container>

      <!-- Assigned Backup -->
      <ng-container matColumnDef="trainLevel">
        <th *matHeaderCellDef mat-header-cell>{{ t('backupUserOrgNote') }}</th>
        <td *matCellDef="let element" mat-cell>{{ element.backupAllDataUserDisplay?.orgNote }}</td>
      </ng-container>

      <!-- Header -->
      <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
    </table>
  </p-card>
</ng-container>
