import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-obj-is-valid',
  templateUrl: './obj-is-valid.component.html',
  styleUrls: ['./obj-is-valid.component.css'],
})
export class ObjIsValidComponent implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Input / Output
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() isValidObj: () => boolean;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit(): void {}
}
