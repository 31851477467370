import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { isNil } from 'lodash';

import { PlayOrSay, PlayOrSaySchema } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { VRCEditor } from '../../../generic/abstract-classes/vrc-editor';

@Component({
  selector: 'app-vrc-editor-play-or-say',
  templateUrl: './vrc-editor-play-or-say.component.html',
  styleUrls: ['./vrc-editor-play-or-say.component.css'],
})
export class VrcEditorPlayOrSayComponent extends VRCEditor<PlayOrSay> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  selectedVrcAudioMetadataKVPair: KVPair<PlayOrSay>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(undefined, [PlayOrSaySchema.loop], PlayOrSaySchema.Defaults, this);

    parameters[PlayOrSaySchema.audioId] = this.selectedVrcAudioMetadataKVPair?.value?.getId() || PlayOrSaySchema.Defaults.audioId;

    const value = new PlayOrSay(parameters);
    return new KVPair({ id: this.obj.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<PlayOrSay>) {
    // Init Form
    const formConfig = {} as any;
    formConfig[PlayOrSaySchema.loop] = [obj.value!.getLoop(), [Validators.max(10), Validators.min(1)]];

    this.form = this.formBuilder.group(formConfig);

    // Init other state
    if (obj.value!.getAudioId() !== PlayOrSaySchema.Defaults.audioId) {
      this.selectedVrcAudioMetadataKVPair = new KVPair({ id: obj.value!.getAudioId() });
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get loop(): AbstractControl | null {
    return this.form.get(PlayOrSaySchema.loop);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj() {
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.selectedVrcAudioMetadataKVPair?.value?.getId())) {
      return false;
    }

    return true;
  }
}
