import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { translate, TranslocoModule } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';

import { CommunicationsResponseGetCallLogPII, displayTime, getCallListPIIRetentionDuration } from '@pwp-common';

import { CommunicationsService } from '../../../services/call/communications/communications.service';
import { ProgressSpinnerModule } from '../../core/progress-spinner/progress-spinner.module';

@Component({
  selector: 'app-caller-info-dialog',
  standalone: true,
  imports: [ButtonModule, CommonModule, ProgressSpinnerModule, ToastModule, TooltipModule, TranslocoModule],
  templateUrl: './caller-info-dialog.component.html',
  styleUrls: ['./caller-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class CallerInfoDialogComponent implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  public loading = true;

  public dataRetentionEndFormatted: string;

  public communicationsResponseGetCallLogPII: CommunicationsResponseGetCallLogPII;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private changeDetectorRef: ChangeDetectorRef,
    private communicationsService: CommunicationsService,
    private messageService: MessageService,
  ) {}

  public async ngOnInit(): Promise<void> {
    await this.getData();
  }

  ///////////////////////////////////////////////////////////////////////
  // Close
  ///////////////////////////////////////////////////////////////////////

  public closeDialog() {
    this.ref.close();
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  private async getData() {
    // Set loading state
    this.loading = true;

    const { data } = this.config;

    // Fetch data
    try {
      this.communicationsResponseGetCallLogPII = await this.communicationsService.getCallLogPII(data.callLog.getId());

      if (this.communicationsResponseGetCallLogPII?.getError() !== undefined) {
        this.showError('noData');
        return;
      }
    } catch (error) {
      this.showError('timeout');
      return;
    }

    this.dataRetentionEndFormatted = displayTime(
      data.callLog.getIncomingCallReceivedTime().add(getCallListPIIRetentionDuration(data.callList)),
      data.orgData.getTimezone(),
    );
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  ///////////////////////////////////////////////////////////////////////
  // Errors
  ///////////////////////////////////////////////////////////////////////

  private showError(errorKey: 'timeout' | 'noData') {
    const messageConfigs = {
      timeout: {
        title: translate('pwp-api.errorTimeoutTitle'),
        body: translate('pwp-api.errorTimeoutBody'),
      },
      noData: {
        title: translate('caller-info-dialog.errorNoDataTitle'),
        body: translate('caller-info-dialog.errorNoDataBody'),
      },
    };

    const { title, body } = messageConfigs[errorKey];

    this.messageService.add({
      severity: 'error',
      closable: true,
      sticky: true,
      summary: title,
      detail: body,
    });

    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }
}
