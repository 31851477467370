import type {AnySchema} from 'joi';

import {FormBuilderVendor} from '../../../../../objects/form/form-builder-vendor';
import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {surveyJSJsonSchema} from '../../../../../objects/generic/serialization/schema-field/survey-js-form/survey-js-json-schema';
import {FormRequestSchema} from '../../generic/form-request/form-request-schema';

export class FormRequestCreateVersionSchema extends FormRequestSchema {
  public static readonly description = 'description';

  public static readonly displayName = 'displayName';

  public static readonly formId = 'formId';

  public static readonly formVersionId = 'formVersionId';

  public static readonly vendor = 'vendor';

  public static readonly value = 'value';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class extends FormRequestSchema.Defaults {
    public static readonly description = undefined;
    public static readonly displayName = undefined;
    public static readonly formId = undefined;
    public static readonly formVersionId = undefined;
    public static readonly vendor = undefined;
    public static readonly value = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[FormRequestCreateVersionSchema.description] = SchemaField.string(FormRequestCreateVersionSchema.Defaults.description);
    parameters[FormRequestCreateVersionSchema.displayName] = SchemaField.string(FormRequestCreateVersionSchema.Defaults.displayName);
    parameters[FormRequestCreateVersionSchema.formId] = SchemaField.autoGeneratedDocId(FormRequestCreateVersionSchema.Defaults.formId).required();
    parameters[FormRequestCreateVersionSchema.formVersionId] = SchemaField.autoGeneratedDocId(FormRequestCreateVersionSchema.Defaults.formVersionId).required();
    parameters[FormRequestCreateVersionSchema.vendor] = SchemaField.string(FormRequestCreateVersionSchema.Defaults.formId)
      .required()
      .valid(...Object.values(FormBuilderVendor));
    parameters[FormRequestCreateVersionSchema.value] = surveyJSJsonSchema(FormRequestCreateVersionSchema.Defaults.value).required();

    return parameters;
  }
}
