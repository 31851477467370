import {cloneDeep} from 'lodash';
import {CallerIdStrategy} from '../../../../communication/caller-id-strategy/caller-id-strategy';
import {CallListOfferChannelConfigPhoneCallConstructor} from './call-list-offer-channel-config-phone-call-constructor';
import {CallListOfferChannelConfigPhoneCallSchema} from './call-list-offer-channel-config-phone-call-schema';
import {SerializableObject} from '../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {OfferChannelConfigPhoneCallMachineDetection} from '../../../../communication/reservation-offer/offer-channel-config-phone-call-machine-detection/offer-channel-config-phone-call-machine-detection';

export class CallListOfferChannelConfigPhoneCall extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected callerIdStrategy!: CallerIdStrategy | undefined;
  protected machineDetection!: OfferChannelConfigPhoneCallMachineDetection | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CallListOfferChannelConfigPhoneCallConstructor) {
    super(parameters);
  }
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is protected, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CallListOfferChannelConfigPhoneCall {
    return new CallListOfferChannelConfigPhoneCall(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(CallListOfferChannelConfigPhoneCall.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CallListOfferChannelConfigPhoneCallSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCallerIdStrategy() {
    return cloneDeep(this.callerIdStrategy);
  }

  public getMachineDetection() {
    return cloneDeep(this.machineDetection);
  }
}
