<ng-container *transloco="let t; read: 'call-list-table-editor-expansion'">
  <p-toast position="bottom-center"></p-toast>
  <ng-template #objEditor>
    <app-call-list-editor [formControl]="control"></app-call-list-editor>
  </ng-template>

  <app-settings
    [auditEntry]="auditEntry"
    [doUpload]="doUpload"
    [editorTemplate]="objEditor"
    [isValid]="getIsValid"
    [refreshData]="refreshData"
    [resetForm]="resetForm"
  >
  </app-settings>
</ng-container>
