<ng-container *transloco="let t; read: 'service-operation-editor'">
  <form [formGroup]="form">
    <ng-container formArrayName="commands">
      <app-sequence-steps
        [defaultStep]="defaultStep"
        [editorTemplate]="stepEditor"
        [formControl]="commands"
        [maxSteps]="maxCommands"
        [minSteps]="minCommands"
        [required]="required"
        [stepHeaderTemplate]="stepHeader"
      >
        <ng-template #stepHeader let-formControlIndex>
          <div class="flex align-items-center flex-wrap">
            <div class="flex align-items-center justify-content-center font-bold">
              <mat-icon>{{ stepHeaders[formControlIndex].materialIconName }}</mat-icon>
            </div>
            <div class="flex align-items-center justify-content-center font-bold">
              {{ t(stepHeaders[formControlIndex].title) }}
            </div>
          </div>
        </ng-template>
        <ng-template #stepEditor let-control="control">
          <app-service-command-editor-container [formControl]="control"></app-service-command-editor-container>
        </ng-template>
      </app-sequence-steps>
    </ng-container>
    <!-- Display error messages-->
    <div class="error-messages m-1">
      <p *ngIf="form.hasError('maxCommandsExceeded')" class="p-error">
        {{ t('stepsErrorMaxLength', { maxSteps: 5 }) }}
      </p>
      <p *ngIf="form.hasError('dialCallListNotLast')" class="p-error">
        {{ t('isLastStepDialCallList') }}
      </p>
    </div>
  </form>
</ng-container>
