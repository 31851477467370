<ng-container *transloco="let t; read: 'form-table-editor'">
  <p-messages severity="warn">
    <ng-template pTemplate>
      {{ t('betaWarning') }}
    </ng-template>
  </p-messages>

  <app-data-table
    [actionsTemplate]="actions"
    [cols]="cols"
    [expansionRow]="expansionRow"
    [selectedCols]="cols"
    [tableRows]="tableRows$"
    [translationScope]="'form-table-editor'"
  >
    <ng-template #actions>
      <p-button
        icon="pi pi-plus"
        styleClass="p-button-outlined"
        [label]="t('createFormButtonLabel')"
        (onClick)="openNewFormDialog()"
      ></p-button>
    </ng-template>
    <ng-template #expansionRow let-rowData>
      <app-form-editor [form]="rowData.form"></app-form-editor>
    </ng-template>
  </app-data-table>
</ng-container>
