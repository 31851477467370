import {SchemaField} from '../../../../../generic/serialization/schema-field';
import {ServiceLimitEnforcementStrategySchema} from '../../generic/service-limit-enforcement-strategy/service-limit-enforcement-strategy-schema';
import {ServiceLimitServiceUsageThreshold} from '../../../service-limit-service-usage-threshold/service-limit-service-usage-threshold';

export class ServiceLimitEnforcementStrategyRRuleSchema extends ServiceLimitEnforcementStrategySchema {
  public static readonly windowResetRRule = 'windowResetRRule';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly enforce = undefined;
    public static readonly type = undefined;
    public static readonly threshold = ServiceLimitServiceUsageThreshold.deserialize({});
    public static readonly windowResetRRule = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set(...super.defaultValuesToSerialize());
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ServiceLimitEnforcementStrategyRRuleSchema.windowResetRRule] = SchemaField.string(ServiceLimitEnforcementStrategyRRuleSchema.Defaults.windowResetRRule).required();
    /* eslint-enable */

    return parameters;
  }
}
