import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import moment from 'moment-timezone';

export class VoicemailMetadataSchema extends DBDocSchema {
  // Session Id of the call from which a recording was generated
  public static readonly sessionId = 'sessionId';

  public static readonly recordingSid = 'recordingSid';

  // Start time when recording was made
  public static readonly recordingStartTime = 'recordingStartTime';

  // Duration of recording in milliseconds
  public static readonly durationMS = 'durationMS';

  // Status of recording
  public static readonly recordingStatus = 'recordingStatus';

  // Error code associated with recording
  public static readonly errorCode = 'errorCode';

  // Array of users that have listened to this file.
  public static readonly listenedToBy = 'listenedToBy';

  // UserId that this voicemail is assigned to
  public static readonly assignedTo = 'assignedTo';

  // ID of the voicemail config object.
  public static readonly voicemailConfigId = 'voicemailConfigId';

  // Indicates if the associated audio file was deleted.
  public static readonly blobDeleted = 'blobDeleted';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'voicemails';
  };

  static Defaults = class {
    public static readonly sessionId = 'missing-sessionId';
    public static readonly recordingSid = 'missing-recordingSid';
    public static readonly recordingStartTime = moment(0);
    public static readonly durationMS = 0;
    public static readonly recordingStatus = 'completed';
    public static readonly errorCode = 0;
    public static readonly listenedToBy = [];
    public static readonly assignedTo = undefined;
    public static readonly voicemailConfigId = 'missing-voicemailConfigId';
    public static readonly blobDeleted = false;
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([
      // Default value is the empty list, so always serialize
      VoicemailMetadataSchema.durationMS,
      VoicemailMetadataSchema.listenedToBy,
      VoicemailMetadataSchema.blobDeleted,
    ]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[VoicemailMetadataSchema.sessionId] = SchemaField.string(VoicemailMetadataSchema.Defaults.sessionId);
    parameters[VoicemailMetadataSchema.recordingSid] = SchemaField.string(VoicemailMetadataSchema.Defaults.recordingSid);
    parameters[VoicemailMetadataSchema.recordingStartTime] = SchemaField.timestamp(VoicemailMetadataSchema.Defaults.recordingStartTime);
    parameters[VoicemailMetadataSchema.durationMS] = SchemaField.number(VoicemailMetadataSchema.Defaults.durationMS);
    parameters[VoicemailMetadataSchema.recordingStatus] = SchemaField.string(VoicemailMetadataSchema.Defaults.recordingStatus);
    parameters[VoicemailMetadataSchema.errorCode] = SchemaField.number(VoicemailMetadataSchema.Defaults.errorCode);
    parameters[VoicemailMetadataSchema.listenedToBy] = SchemaField.shortStringArray(VoicemailMetadataSchema.Defaults.listenedToBy);
    parameters[VoicemailMetadataSchema.assignedTo] = SchemaField.string(VoicemailMetadataSchema.Defaults.assignedTo);
    parameters[VoicemailMetadataSchema.voicemailConfigId] = SchemaField.string(VoicemailMetadataSchema.Defaults.voicemailConfigId);
    parameters[VoicemailMetadataSchema.blobDeleted] = SchemaField.boolean(VoicemailMetadataSchema.Defaults.blobDeleted);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${VoicemailMetadataSchema.Constants.collection}`;
  }
}
