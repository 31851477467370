import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

import { displayTime } from '@pwp-common';

@Pipe({
  name: 'momentPipe',
})
export class MomentPipePipe implements PipeTransform {
  transform(value: moment.Moment, timezone?: string | undefined, timeOnly?: boolean): string {
    return displayTime(value, timezone, { timeOnly: timeOnly ?? false });
  }
}
