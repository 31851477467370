import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { defer, forkJoin, from, map, Observable, of, shareReplay } from 'rxjs';
import { SurveyCreatorModel } from 'survey-creator-core';

import { Form, FormVersion } from '@pwp-common';

import { StylesService } from '../../../ui/styles/styles.service';
import { ProgressSpinnerModule } from '../../core/progress-spinner/progress-spinner.module';
import { AuditEntryComponent } from '../../generic/settings/common/audit-entry/audit-entry.component';
import { makeAuditEntry } from '../../generic/settings/common/audit-entry/helpers/make-audit-entry';
import { SettingsModule } from '../../generic/settings/settings.module';
import { FormVersionService } from '../services/form-version.service';
import { SurveyJSCreatorModule } from '../survey-js-creator.module';

import { allowedSurveyJSQuestionTypes } from './constants/allowed-survey-js-question-types';
import { hideSurveyCreatorPropertiesFromGrid } from './helpers/hide-survey-creator-properties-from-grid';

@Component({
  selector: 'app-form-editor',
  standalone: true,
  templateUrl: './form-editor.component.html',
  styleUrls: ['./form-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [ButtonModule, CommonModule, SurveyJSCreatorModule, ProgressSpinnerModule, SettingsModule, AuditEntryComponent],
})
export class FormEditorComponent {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Services
  //////////////////////////////////////////////////////////////////////////////////////////

  private readonly dialogConfig = inject(DynamicDialogConfig, { optional: true });

  private readonly dialogRef = inject(DynamicDialogRef, { optional: true });

  private readonly formVersionService = inject(FormVersionService);

  private readonly stylesService = inject(StylesService);

  //////////////////////////////////////////////////////////////////////////////////////////
  // Data
  //////////////////////////////////////////////////////////////////////////////////////////

  private readonly formVersion$ = defer(() => this.getFormVersion()).pipe(shareReplay({ refCount: true, bufferSize: 1 }));

  private readonly auditEntry$ = this.formVersion$.pipe(map((formVersion) => makeAuditEntry(formVersion)));

  private readonly creatorModel$ = this.formVersion$.pipe(map((formVersion) => this.getCreatorModel(formVersion)));

  private readonly stylesLoaded$ = from(this.stylesService.lazyLoadGlobalStyle('survey-creator')).pipe(map(() => true));

  public readonly data$ = defer(() =>
    forkJoin({
      auditEntry: this.auditEntry$,
      creatorModel: this.creatorModel$,
      stylesLoaded: this.stylesLoaded$,
    }),
  );

  //////////////////////////////////////////////////////////////////////////////////////////
  // Inputs
  //////////////////////////////////////////////////////////////////////////////////////////

  @Input() public form?: Form;

  private getFormId(): string {
    return this.form?.getId() ?? this.dialogConfig?.data.formId;
  }

  private async saveFormVersion(model: SurveyCreatorModel, updateSurveySaveState: (success: boolean) => void): Promise<void> {
    await this.formVersionService.createSurveyJSVersion(this.getFormId(), model);

    this.dialogRef?.close();
    updateSurveySaveState(true);
  }

  private getFormVersion(): Observable<FormVersion | undefined> {
    return this.form ? this.formVersionService.getDoc(this.form.getActiveVersionId()) : of(undefined);
  }

  private getCreatorModel(formVersion?: FormVersion): SurveyCreatorModel {
    const model = new SurveyCreatorModel({
      showJSONEditorTab: false,
      questionTypes: allowedSurveyJSQuestionTypes,
    });

    hideSurveyCreatorPropertiesFromGrid(model);
    model.saveSurveyFunc = (saveNo, updateSurveySaveState) =>
      this.saveFormVersion(model, (success) => updateSurveySaveState(saveNo, success));
    model.text = formVersion?.formSchema ?? '';

    return model;
  }
}
