import { ChangeDetectorRef, Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { isNil } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SerializableObject } from '@pwp-common';

import { ObjWithId } from '../../../common/objects/types';

@Directive()
export abstract class ObjEditor<T extends ObjWithId> implements OnInit, OnChanges, OnDestroy {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() obj: T;

  @Output() objChange = new EventEmitter<T>();

  loading = true;

  protected ngUnsubscribe = new Subject<void>();

  form = new UntypedFormGroup({});

  // private lastEmittedValue: any;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.getData();
    this.initState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (Object.keys(changes).includes('obj')) {
      this.onObjChanged(changes.obj?.previousValue, changes.obj?.currentValue);
    }
  }

  ngOnDestroy() {
    this.changeDetectorRef.detach();

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getData() {}

  onObjChanged(previousValue?: T, currentValue?: T) {
    if (this.didMateriallyChange(previousValue, currentValue)) {
      // If the object Id changed then re-initialize
      this.initState();
    }
  }

  /**
   * An object is said to materially change if its id changes. This triggers
   * us to reset the associated form. DBDocObjects have a natural id. Similarly,
   * KVPairs have an id. Displayable objects don't always have an id.
   *
   * @param obj any object that is eventually written to our database
   */
  public didMateriallyChange(previousValue: T, currentValue: T): any {
    if (previousValue?.getId() !== currentValue?.getId()) {
      return true;
    }

    return false;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  // public abstract getForm(): FormGroup;
  onFormChanges(value: any): void {
    this.updateObjAndEmit();
  }

  subscribeFormChanges() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.ngUnsubscribe = new Subject();
    this.form.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => {
      this.onFormChanges(value);
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Updated Object
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  abstract getObjFromForm(): T;

  public getUpdatedObj(): T | undefined {
    if (isNil(this.obj) || !this.isValidObj()) {
      return undefined;
    }
    return this.getObjFromForm();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Init Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  abstract setFormFromObj(obj: T): void;

  protected initState() {
    this.loading = true;
    if (this.obj === undefined) {
      return;
    }

    this.setFormFromObj(this.obj);
    this.subscribeFormChanges();

    // Show results
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Emit Command Change
  ///////////////////////////////////////////////////////////////////////////////////////////

  protected isValidObj(): boolean {
    if (isNil(this.form)) {
      return true;
    }

    if (!this.form.valid && !isNil(this.form.errors)) {
      console.log(`ObjEditor:Form errors.`, this.form.errors);
    }

    const obj = this.getObjFromForm();
    try {
      if (obj instanceof SerializableObject) {
        obj.sanityCheck();
      }
    } catch (error) {
      return false;
    }

    return this.form.valid;
  }

  updateObjAndEmit() {
    // Don't update the local object if the form specifies an invalid item
    if (!this.isValidObj()) {
      return;
    }

    const obj = this.getUpdatedObj();

    // if (isEqual(this.obj, this.lastEmittedValue)) {
    //   return;
    // }

    this.objChange.emit(obj);
    // this.lastEmittedValue = cloneDeep(this.obj);
  }
}
