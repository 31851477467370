import { isNil } from '@ngneat/transloco';

import { UserData } from '@pwp-common';

import { KVPair } from './kvpair';

export const userDataDisplayFormat = (obj: UserData | undefined | KVPair<UserData>): string => {
  if (isNil(obj)) {
    return '';
  }
  let userData: UserData;
  if (obj instanceof KVPair) {
    userData = obj.value;
  } else {
    userData = obj;
  }

  return `${userData.getDisplayName()} <${userData.getNotificationEmail()}>`;
};
