import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { EMPTY, forkJoin, Observable, Subscription } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';

import { AllDataEvent, OrgData } from '@pwp-common';

import { AllDataEventService } from '../../../services/event/all-data-event/all-data-event.service';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';

import { EventRequestTypeSelection } from './enums';

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-event-requests-query-editor',
  templateUrl: './event-requests-query-editor.component.html',
  styleUrls: ['./event-requests-query-editor.component.css'],
  providers: [MessageService],
})
export class EventRequestsQueryEditorComponent implements OnInit {
  ////////////////////////////////////////////////////////////////////////
  // Query State
  ////////////////////////////////////////////////////////////////////////

  loading = false;

  querySubscription: Subscription;

  allDataEvent: AllDataEvent[] = [];

  orgDataObservable: Observable<OrgData>;

  ////////////////////////////////////////////////////////////////////////
  // Form
  ////////////////////////////////////////////////////////////////////////

  form = new UntypedFormGroup({
    eventRequestType: new UntypedFormControl(EventRequestTypeSelection.open, [Validators.required]),
    range: new UntypedFormGroup(
      {
        start: new UntypedFormControl(moment().endOf('day').subtract(48, 'hours').toDate(), [Validators.required]),
        end: new UntypedFormControl(moment().endOf('day').toDate(), [Validators.required]),
      },
      [Validators.required],
    ),
  });

  ////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////

  eventRequestTypeControlName = 'eventRequestType';

  rangeControlName = 'range';

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////

  constructor(
    private allDataEventService: AllDataEventService,
    private messageService: MessageService,
    private translocoService: TranslocoService,
    private orgDataService: OrgDataService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.orgDataObservable = this.orgDataService.getOrgData().pipe(shareReplay(1));
    this.refreshData();
  }

  ////////////////////////////////////////////////////////////////////////
  // Form Controls
  ////////////////////////////////////////////////////////////////////////

  get eventRequestType() {
    return this.form.get(this.eventRequestTypeControlName);
  }

  get start() {
    return this.form.get(`${this.rangeControlName}.start`);
  }

  get end() {
    return this.form.get(`${this.rangeControlName}.end`);
  }

  get range() {
    return this.form.get(this.rangeControlName) as UntypedFormGroup;
  }

  ////////////////////////////////////////////////////////////////////////
  // Query
  ////////////////////////////////////////////////////////////////////////

  queryIsValid() {
    return this.form.valid;
  }

  private updateQuery(): Observable<void> {
    console.log('EventRequestsQueryEditorComponent.updateQuery: Starting');
    this.loading = false;
    this.unsubscribeQuery();
    if (!this.queryIsValid()) {
      console.log('EventRequestsQueryEditorComponent.updateQuery: Invalid Query. Exiting');
      return;
    }
    this.loading = true;

    const eventRequestTypeSelection: EventRequestTypeSelection = this.eventRequestType?.value;
    const showPendingOnly = eventRequestTypeSelection === EventRequestTypeSelection.open;
    const showDecidedOnly: boolean = eventRequestTypeSelection === EventRequestTypeSelection.decided;
    const start = moment(this.start?.value).toDate();
    const end = moment(this.end?.value).toDate();

    const observables: Observable<any>[] = [];
    if (showPendingOnly) {
      observables.push(this.allDataEventService.getByRequest());
    }

    if (showDecidedOnly) {
      observables.push(
        this.allDataEventService.getByRequest({
          createStart: start,
          createEnd: end,
        }),
      );
    }

    return forkJoin(observables).pipe(
      map((allDataEvent) => {
        this.loading = false;
        this.allDataEvent = allDataEvent.flat() ?? [];
        this.notifyQuerySuccess(this.allDataEvent.length);
        this.changeDetectorRef.detectChanges();
        return null;
      }),
      catchError((error: unknown, _) => {
        this.loading = false;
        this.notifyQueryError(error);
        return EMPTY;
      }),
    );
  }

  ////////////////////////////////////////////////////////////////////////
  // Unsubscribe
  ////////////////////////////////////////////////////////////////////////

  unsubscribeQuery() {
    this.querySubscription?.unsubscribe();
  }

  ////////////////////////////////////////////////////////////////////////
  // Query Click
  ////////////////////////////////////////////////////////////////////////

  public refreshData() {
    this.unsubscribeQuery();
    this.querySubscription = this.updateQuery().pipe(untilDestroyed(this))?.subscribe();
  }

  ////////////////////////////////////////////////////////////////////////
  // Messages
  ////////////////////////////////////////////////////////////////////////

  private notifyQuerySuccess(numRequests) {
    const title = this.translocoService.translate('event-requests-query-editor.querySuccessTitle');
    const body = this.translocoService.translate('event-requests-query-editor.querySuccessBody', { numRequests });

    this.messageService.add({
      severity: 'success',
      summary: title,
      detail: body,
    });
  }

  private notifyQueryError(error: any) {
    console.error(error);
    const title = this.translocoService.translate('event-requests-query-editor.queryErrorTitle');

    this.messageService.add({
      severity: 'error',
      closable: true,
      sticky: false,
      life: 3000,
      summary: title,
      detail: JSON.stringify(error),
    });
  }
}
