import { Timestamp } from '@angular/fire/firestore';
import moment from 'moment-timezone';

import { NumericInput } from '../interfaces';

export const coerceNumericInput = (input: NumericInput): number | Timestamp => {
  if (moment.isMoment(input)) {
    return Timestamp.fromDate(input.toDate());
  }

  if (input instanceof Date) {
    return Timestamp.fromDate(input);
  }

  return input;
};
