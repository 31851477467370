import {ServiceableAddressNameType} from '../serviceable-address-name';
import {cloneDeep} from 'lodash';
import {DBDocSchema} from '../../../../generic/db-doc/db-doc-schema';
import {DBDocObject} from '../../../../generic/db-doc/db-doc-object';

export abstract class ServiceableAddress extends DBDocObject {
  protected type!: ServiceableAddressNameType;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    throw new Error('Error: Cannot get schema for abstract ServiceableAddress');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): ServiceableAddressNameType {
    return cloneDeep(this.type);
  }
}
