import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {ConversationRequestSchema} from '../../generic/conversation-request/conversation-request-schema';

export class ConversationRequestGetJWTSchema extends ConversationRequestSchema {
  public static readonly conversationConfigId = 'conversationConfigId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly conversationConfigId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ConversationRequestGetJWTSchema.conversationConfigId] = SchemaField.autoGeneratedDocId(ConversationRequestGetJWTSchema.Defaults.conversationConfigId).required();
    /* eslint-enable */

    return parameters;
  }
}
