import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import parsePhoneNumber from 'libphonenumber-js';
import { isNil } from 'lodash';

export class PhoneValidator {
  public static isValidPhone(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const phoneStr = control.value;
      if (isNil(phoneStr) || phoneStr === '') {
        return null;
      }

      const parsedPhone = parsePhoneNumber(phoneStr, 'US');
      if (isNil(parsedPhone) || !parsedPhone.isValid() || parsedPhone.country !== 'US') {
        return { isValidPhone: { value: phoneStr } };
      }

      return null;
    };
  }
}
