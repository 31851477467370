import { CommunicationWorkflow, CommunicationWorkflowFilter, DBDocSchema } from '@pwp-common';

export const defaultCommunicationWorkflowTemplate = new CommunicationWorkflow({
  id: DBDocSchema.GenericDefaults.id,
  displayName: 'Missing',
  description: 'Missing',
  defaultFilter: undefined,
  sid: undefined,
  filters: [new CommunicationWorkflowFilter({ targets: [] })],
  taskReservationTimeoutMS: 120 * 1000,
});
