import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';

export class OfferChannelConfigPhoneCallMachineDetectionSchema extends SerializableObjectSchema {
  public static readonly hangupOnMachine = 'hangupOnMachine';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly hangupOnMachine = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // prettier-ignore
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[OfferChannelConfigPhoneCallMachineDetectionSchema.hangupOnMachine] = SchemaField.boolean(OfferChannelConfigPhoneCallMachineDetectionSchema.Defaults.hangupOnMachine).required()
    /* eslint-enable */

    return parameters;
  }
}
