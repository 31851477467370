import {EventData} from '../event-data/event-data';
import {cloneDeep} from 'lodash';
import {EventDataTransactionDisplay} from './event-data-transaction-display';
import {DBUploadExistingDoc} from '../../../db/transaction/actions/db-upload-existing-doc';

/**
 * The primary case of arrays that can't be repeated weekly are those
 * containing events that are already shifted by a week.
 * @param transactions
 * @returns
 */
export function canBeRepeatedWeekly(transactions: EventDataTransactionDisplay[]): boolean {
  if (transactions.length === 0) {
    return false;
  }

  const events: EventData[] = [];
  for (const transaction of transactions) {
    if (!transaction.isSimpleUpdate()) {
      return false;
    }

    const event = (transaction.actions[0] as DBUploadExistingDoc<EventData>).obj;
    events.push(event);
  }

  for (let i = 0; i < events.length; i++) {
    for (let j = i + 1; j < events.length; j++) {
      if (events[i].isShiftedByWeek(events[j])) {
        return false;
      }
    }
  }

  // Check that only one event type is represented in the list
  const types = Array.from(new Set(events.map(z => z.getType())));
  if (types.length > 1) {
    return false;
  }

  return true;
}

export function assignEventsWeeklyInFuture(
  transactions: EventDataTransactionDisplay[],
  allEvents: EventData[]
): EventData[] {
  const futureAssignments: EventData[] = [];

  if (!canBeRepeatedWeekly(transactions)) {
    throw new Error('Bad Input! This function assumes that events can be repeated weekly!');
  }

  console.log(allEvents);
  for (const futureEvent of allEvents) {
    for (const givenTransaction of transactions) {
      const givenEvent = (givenTransaction.actions[0] as DBUploadExistingDoc<EventData>).obj;

      if (futureEvent.isShiftedByWeek(givenEvent)) {
        const updatedFutureEvent = cloneDeep(futureEvent);
        updatedFutureEvent.setColor(givenEvent.getColor());
        updatedFutureEvent.setAssignedUserId(givenEvent.getAssignedUserId());
        updatedFutureEvent.setAssignedBackupUserId(givenEvent.getAssignedBackupUserId());
        futureAssignments.push(updatedFutureEvent);
        break;
      }
    }
  }

  console.log('Returning future assignments');
  console.log(futureAssignments);
  return futureAssignments;
}
