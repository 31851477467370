import { isNil, upperFirst } from 'lodash';

import { DBDocObject, displayTime, UserData, UserStatus } from '@pwp-common';

import { RowCell } from '../../../../common/p-table/row-cell';
import { DataTableRow } from '../../../generic/data-table/common/data-table-row';

export abstract class CommunicationSessionEventBase<T extends DBDocObject> implements DataTableRow {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  id: string;

  createTime: Date;

  channel: RowCell<string>;

  userDisplayNameWithEmail: RowCell<string>;

  createDate: RowCell<Date>;

  originationReason: RowCell<string>;

  userStatus: RowCell<string>;

  sid: RowCell<string>;

  summary: RowCell<string>;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  protected constructor({
    sid,
    doc,
    allDataUser,
    timezone,
    userId,
    userStatus,
    channel,
  }: {
    channel: 'phone' | 'chat' | 'text';
    sid: string;
    doc: T;
    allDataUser: Map<string, UserData>;
    timezone: string;
    userId?: string;
    userStatus?: UserStatus;
  }) {
    this.id = doc.getId();
    this.createTime = doc.getCreateTime().toDate();
    this.setChannel(channel);
    this.setUserDisplayNameWithEmail(userId, allDataUser);
    this.setOriginationReason();
    this.setCreateDate(doc, timezone);
    this.setUserStatus(userStatus);
    this.setSid(sid);
    this.setSummary(doc);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Channel
  //////////////////////////////////////////////////////////////////////////////////////////

  private setChannel(channel: 'phone' | 'chat' | 'text') {
    switch (channel) {
      case 'phone': {
        this.channel = new RowCell<string>({
          translationScope: 'communication-session-event-row',
          translationKey: `cellChannel`,
          sortValue: channel,
          icon: 'phone',
        });
        return;
      }
      case 'chat': {
        this.channel = new RowCell<string>({
          translationScope: 'communication-session-event-row',
          translationKey: `cellChannel`,
          sortValue: channel,
          icon: 'chat_bubble_outline',
        });
        return;
      }
      case 'text': {
        this.channel = new RowCell<string>({
          translationScope: 'communication-session-event-row',
          translationKey: `cellChannel`,
          sortValue: channel,
          icon: 'sms',
        });
      }
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // User Display Name With Email
  //////////////////////////////////////////////////////////////////////////////////////////

  private setUserDisplayNameWithEmail(userId, allDataUser: Map<string, UserData>) {
    if (isNil(userId)) {
      this.userDisplayNameWithEmail = new RowCell<string>({
        translationScope: 'communication-session-event-row',
        translationKey: 'cellUserDisplayNameNA',
        translationObj: {},
        sortValue: undefined,
        styleClass: undefined,
      });
      return;
    }
    const displayNameWithEmail = allDataUser.get(userId)?.getDisplayNameWithEmail();
    if (isNil(displayNameWithEmail)) {
      this.userDisplayNameWithEmail = new RowCell<string>({
        translationScope: 'communication-session-event-row',
        translationKey: 'cellUserDisplayNameDeletedUser',
        translationObj: {},
        sortValue: undefined,
        styleClass: 'statusBadge alert',
      });
      return;
    }
    this.userDisplayNameWithEmail = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellUserDisplayName',
      translationObj: {
        displayNameWithEmail,
      },
      sortValue: displayNameWithEmail,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Origination Reason
  //////////////////////////////////////////////////////////////////////////////////////////

  protected abstract setOriginationReason();

  //////////////////////////////////////////////////////////////////////////////////////////
  // Create Date
  //////////////////////////////////////////////////////////////////////////////////////////

  private setCreateDate(dialedCall: T, timezone: string) {
    const createDate = dialedCall.getCreateTime();
    if (isNil(createDate)) {
      this.createDate = new RowCell<Date>({
        translationScope: 'communication-session-event-row',
        translationKey: 'cellCreateDateMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.createDate = new RowCell<Date>({
      translationScope: 'communication-session-event-row',
      translationKey: 'cellCreateDate',
      translationObj: {
        timestamp: displayTime(createDate, timezone, { dateOnly: true }),
      },
      sortValue: createDate.toDate(),
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // User Status & Dial Status
  //////////////////////////////////////////////////////////////////////////////////////////

  private setUserStatus(userStatus: UserStatus | undefined) {
    if (isNil(userStatus)) {
      this.userStatus = new RowCell<string>({
        translationScope: 'communication-session-event-row',
        translationKey: `cellUserStatusNA`,
        translationObj: {},
        sortValue: '',
      });
      return;
    }
    this.userStatus = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey: `cellUserStatus${upperFirst(userStatus)}`,
      translationObj: {},
      sortValue: userStatus,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Call Sid
  //////////////////////////////////////////////////////////////////////////////////////////

  private setSid(sid: string) {
    this.sid = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey: `cellCallSID`,
      translationObj: {
        value: sid,
      },
      sortValue: sid,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Summary
  //////////////////////////////////////////////////////////////////////////////////////////

  protected abstract setSummary(dialedCall: T, ...other: any[]);
}
