import { isNil } from 'lodash';
import { RRule } from 'rrule';

import { addDtStartAndTimezoneToRRule, TimeSelection, TimeSelectionItem } from '@pwp-common';

import { getIsoDurationFromTimeRangeStr } from '../../time-range-str/get-iso-duration-from-time-range-str';
import { getTimeRangeFromStr } from '../../time-range-str/get-time-range-from-str';

import { AvailableTimesByWeekMap, AvailableTimesType } from './available-times-by-week';
import { getCanonicalDayByIsoWeekday } from './get-canonical-day-by-iso-weekday';
import { normalizeAvailableTimesByWeek } from './normalize-available-times-by-week';

export const makeTimeSelectionFromAvailableTimesByDayOfWeek = (
  availableTimes: AvailableTimesByWeekMap,
  timezone: string,
): TimeSelection => {
  if (isNil(timezone)) {
    console.error({ timezone, availableTimes });
    throw new Error('makeTimeSelectionFromAvailableTimesByDayOfWeek: User error, timezone is missing');
  }
  /////////////////////////////////////////////
  // Normalize The Map
  /////////////////////////////////////////////

  const normalizedAvailableTimes: AvailableTimesByWeekMap = new Map();
  for (const [key, value] of availableTimes.entries()) {
    const normalizedValue = normalizeAvailableTimesByWeek(value.type, value.timeRange);
    normalizedAvailableTimes.set(key, normalizedValue);
  }

  /////////////////////////////////////////////
  // Cases: Always & Never Available
  /////////////////////////////////////////////

  let isAlwaysAvailable = true;
  let isNeverAvailable = true;
  for (const value of normalizedAvailableTimes.values()) {
    if (value.type !== AvailableTimesType.alwaysAvailable) {
      isAlwaysAvailable = false;
    }
    if (value.type !== AvailableTimesType.neverAvailable) {
      isNeverAvailable = false;
    }
  }

  if (isAlwaysAvailable) {
    return TimeSelection.deserialize({ includeByDefault: true });
  }
  if (isNeverAvailable) {
    return TimeSelection.deserialize({ includeByDefault: false });
  }

  /////////////////////////////////////////////
  // Group By The Selected Time
  /////////////////////////////////////////////

  const groupByTime = new Map<string, number[]>();
  for (const [isoWeekday, timeSelection] of normalizedAvailableTimes.entries()) {
    // Case: Never Available
    if (timeSelection.type === AvailableTimesType.neverAvailable) {
      // Nothing to do since this is the default
      continue;
    }

    let gbValue = groupByTime.get(AvailableTimesType.alwaysAvailable) ?? [];

    // Case: Always Available
    if (timeSelection.type === AvailableTimesType.alwaysAvailable) {
      gbValue.push(isoWeekday);
      gbValue.sort();
      groupByTime.set(AvailableTimesType.alwaysAvailable, gbValue);
      continue;
    }

    if (isNil(timeSelection.timeRange)) {
      console.error(timeSelection);
      throw new Error('makeTimeSelectionFromAvailableTimesByDayOfWeek: User Error, timeRange is undefined');
    }

    gbValue = groupByTime.get(timeSelection.timeRange) ?? [];
    gbValue.push(isoWeekday);
    gbValue.sort();
    groupByTime.set(timeSelection.timeRange, gbValue);
  }

  // Make the Time Selection Items
  const items: TimeSelectionItem[] = [];
  for (const [timeRange, isoWeekdays] of groupByTime.entries()) {
    const dtStart = getCanonicalDayByIsoWeekday(isoWeekdays[0], timezone);
    if (timeRange === AvailableTimesType.neverAvailable) {
      console.error({ timeRange, isoWeekdays });
      throw new Error('makeTimeSelectionFromAvailableTimesByDayOfWeek: Programming error, this case should never have been called');
    }

    const baseRRule = new RRule({ freq: RRule.DAILY, byweekday: isoWeekdays.map((z) => z - 1) }).toString();
    if (timeRange === AvailableTimesType.alwaysAvailable) {
      items.push(
        new TimeSelectionItem({
          rrule: addDtStartAndTimezoneToRRule(baseRRule, dtStart, timezone).toString(),
          duration: 'P1D',
          include: true,
        }),
      );
      continue;
    }

    const { start } = getTimeRangeFromStr(timeRange);
    dtStart.set('hours', start.get('hours'));
    dtStart.set('minutes', start.get('minutes'));

    const duration = getIsoDurationFromTimeRangeStr(timeRange);
    items.push(
      new TimeSelectionItem({
        rrule: addDtStartAndTimezoneToRRule(baseRRule, dtStart, timezone).toString(),
        duration,
        include: true,
      }),
    );
  }

  return new TimeSelection({
    includeByDefault: false,
    items,
  });
};
