import {SchemaField} from '../../generic/serialization/schema-field';
import {VoiceResponseCommandSchema} from '../generic/voice-response-command-schema';
import {VoiceResponseCommandName} from '../any-voice-response-command/voice-response-command-name';

export class JoinConferenceSchema extends VoiceResponseCommandSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = VoiceResponseCommandName.joinConference;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(JoinConferenceSchema.Defaults.commandName);
    /* eslint-enable */

    return parameters;
  }
}
