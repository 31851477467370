////////////////////////////////////////////////////////////
// Types of Context
////////////////////////////////////////////////////////////

export enum MessageTemplateContextType {
  shiftsToday = 'shiftsToday',
  shiftsTomorrow = 'shiftsTomorrow',
  conversationServiceRequest = 'conversationServiceRequest',
  callListCallAnswered = 'callListCallAnswered',
}
