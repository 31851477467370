import { VoiceResponse } from '@pwp-common';

////////////////////////////////////////////////////////////////////////////////////////
// Language Constants
////////////////////////////////////////////////////////////////////////////////////////

export const possibleSayLanguages: VoiceResponse.SayLanguage[] = [
  'arb',
  'ca-ES',
  'cy-GB',
  'da-DK',
  'de-DE',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-GB-WLS',
  'en-IN',
  'en-US',
  'es-ES',
  'es-MX',
  'es-US',
  'fi-FI',
  'fr-CA',
  'fr-FR',
  'hi-IN',
  'is-IS',
  'it-IT',
  'ja-JP',
  'ko-KR',
  'nb-NO',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'ru-RU',
  'sv-SE',
  'tr-TR',
  'zh-CN',
  'zh-HK',
  'zh-TW',
];

export const possibleSpeechModels: VoiceResponse.GatherSpeechModel[] = ['default', 'numbers_and_commands', 'phone_call'];
