import {cloneDeep, isNil} from 'lodash';
import {ConversationSummaryConstructor} from './conversation-summary-constructor';
import {ConversationSummarySchema} from './conversation-summary-schema';
import {SerializableObject} from '../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import moment from 'moment-timezone';
import {ConversationParticipantSummary} from '../conversation-participant-summary/conversation-participant-summary';
import {ConversationMessageDigest} from '../conversation-message-digest/conversation-message-digest';
import {ConversationParticipantSummaryConstructor} from '../conversation-participant-summary/conversation-participant-summary-constructor';
import {normalizeConversationParticipantChannelName} from '../conversation-participant-summary/normalize-channel-name';
import {ConversationUserAttributes} from '../conversation-user-attributes/conversation-user-attributes';
import {ConversationUserIdentityType} from '../conversation-participant-summary/conversation-participant-id-type';

export class ConversationSummary extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected byParticipant!: Map<string, ConversationParticipantSummary>;
  protected numSent!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationSummaryConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationSummary {
    return new ConversationSummary(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationSummary.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationSummarySchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getByParticipant(): Map<string, ConversationParticipantSummary> {
    return cloneDeep(this.byParticipant);
  }

  public getNumSent(): number {
    return cloneDeep(this.numSent);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // First Answered
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getFirstAnsweredBy(): ConversationParticipantSummary | undefined {
    let minimalIndex: number | undefined;
    let minimalParticipant: ConversationParticipantSummary | undefined;

    for (const participant of this.byParticipant.values()) {
      if (participant.getParticipantIdType() !== ConversationUserIdentityType.userId) {
        continue;
      }
      const index = participant.getFirstSend()?.getIndex();
      if (index !== undefined && (minimalIndex === undefined || index < minimalIndex)) {
        minimalParticipant = participant;
        minimalIndex = index;
      }
    }
    return cloneDeep(minimalParticipant);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Update With Message
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Update this object to include the given message
   * @param params info about the message to update the object with
   */
  public updateWithSentMessage(params: {
    index: number;
    sendByParticipantSid: string | undefined | null;
    sendTimestamp: moment.Moment;
  }): ConversationSummary {
    this.numSent += 1;
    if (isNil(params.sendByParticipantSid)) {
      return this;
    }
    const currentValue = this.byParticipant.get(params.sendByParticipantSid);
    if (currentValue === undefined) {
      throw new Error('ConversationSummary.updateWithMessage: User error. Participant has not been added yet.');
    }
    currentValue.updateWithSentMessage({index: params.index, sendTimestamp: params.sendTimestamp});
    this.byParticipant.set(params.sendByParticipantSid, currentValue);
    return this;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Update With Participant
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Update this object to include the given message
   * @param params info about the message to update the object with
   */
  public updateWithParticipant(params: {
    sid: string;
    userAttributes?: ConversationUserAttributes;
    // Non-Chat participants won't have a participantId (identity) and Twilio will return null for this field. All participants will have a sid.
    participantId: string | null | undefined;
    participantCreateTime: moment.Moment;
    participantUpdateTime: moment.Moment;
    channel: string;
    lastReadMessageIndex?: number;
    lastReadTimestamp?: moment.Moment;
  }): ConversationSummary {
    const channel = normalizeConversationParticipantChannelName(params.channel);

    const constructorParameters: ConversationParticipantSummaryConstructor = {
      participantCreateTime: params.participantCreateTime,
      participantUpdateTime: params.participantUpdateTime,
      participantId: params.participantId ?? undefined,
      participantIdType: params.userAttributes?.getIdentityType() ?? ConversationUserIdentityType.anonymousExternalId,
      channel,
    };

    if (params.lastReadMessageIndex !== undefined) {
      constructorParameters['lastRead'] = new ConversationMessageDigest({
        index: params.lastReadMessageIndex!,
        timestamp: params.lastReadTimestamp!,
      });
    }

    this.byParticipant.set(params.sid, new ConversationParticipantSummary(constructorParameters));
    return this;
  }
}
