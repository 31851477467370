import {values} from 'lodash';
import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {ResponseSchema} from '../../../../generic/v2/response/response-schema';
import {EventRequestName} from '../../../event-request-name';

export abstract class EventRequestResponseSchema extends ResponseSchema {
  // The name of the command
  public static readonly type = 'type';
  public static readonly error = 'error';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly error = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[EventRequestResponseSchema.type] = SchemaField.string(EventRequestResponseSchema.Defaults.type).required().valid(...values(EventRequestName));
    /* eslint-enable */

    return parameters;
  }
}
