import { ChangeDetectorRef, Component, Input, TemplateRef } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { MessageService } from 'primeng/api';

import { AuditEntry } from '../common/audit-entry/interfaces';

@UntilDestroy()
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  providers: [MessageService],
})
export class SettingsComponent {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input()
  cardTitle: string;

  @Input() auditEntry: AuditEntry = undefined;

  @Input() isValid: () => boolean = () => true;

  @Input() doUpload: () => Promise<void>;

  @Input() refreshData: () => Promise<void>;

  @Input() resetForm: () => void;

  @Input() editorTemplate: TemplateRef<any>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  uploadInProgress = false;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private messageService: MessageService,
    private translocoService: TranslocoService,
  ) {}

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Buttons
  /////////////////////////////////////////////////////////////////////////////////////////////

  async upload() {
    try {
      this.uploadInProgress = true;
      await this.doUpload();
      await this.refreshData();
      this.successMessage('uploadSuccessTitle', 'success');
    } catch (error) {
      console.error(error);
      this.failureMessage(error);
    } finally {
      this.uploadInProgress = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  cancel() {
    this.resetForm();
    this.changeDetectorRef.detectChanges();
    this.successMessage('clearedSuccessTitle', 'info');
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Success & Failure Messages
  /////////////////////////////////////////////////////////////////////////////////////////////

  successMessage(title: 'uploadSuccessTitle' | 'clearedSuccessTitle', severity: 'success' | 'info') {
    const uploadSuccessTitle = this.translocoService.translate(`settings.${title}`);
    this.messageService.add({ severity, summary: uploadSuccessTitle, life: 3000 });
  }

  failureMessage(errorJson: any) {
    const uploadErrorTitle = this.translocoService.translate('settings.uploadErrorTitle');
    this.messageService.add({ severity: 'error', summary: uploadErrorTitle, detail: JSON.stringify(errorJson) });
  }
}
