import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {ConversationParticipantSummary} from '../conversation-participant-summary/conversation-participant-summary';

export class ConversationSummarySchema extends SerializableObjectSchema {
  public static readonly byParticipant = 'byParticipant';
  public static readonly numSent = 'numSent';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly byParticipant = new Map();
    public static readonly numSent = 0;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ConversationSummarySchema.byParticipant] = SchemaField.mapOfObj(ConversationParticipantSummary, ConversationSummarySchema.Defaults.byParticipant).required();
    parameters[ConversationSummarySchema.numSent] = SchemaField.number(ConversationSummarySchema.Defaults.numSent)
    /* eslint-enable */

    return parameters;
  }
}
