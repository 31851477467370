import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsRequestSchema} from '../../generic/communications-request/communications-request-schema';

export class CommunicationsRequestGetCallLogPIISchema extends CommunicationsRequestSchema {
  public static readonly sessionId = 'sessionId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly sessionId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsRequestGetCallLogPIISchema.sessionId] = SchemaField.autoGeneratedDocId(CommunicationsRequestGetCallLogPIISchema.Defaults.sessionId).required();
    /* eslint-enable */

    return parameters;
  }
}
