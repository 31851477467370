import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isEqual } from 'lodash';

import { DisplayableObj } from '../../../common/objects/types';

@Component({
  selector: 'app-obj-debug-view',
  templateUrl: './obj-debug-view.component.html',
  styleUrls: ['./obj-debug-view.component.css'],
})
export class ObjDebugViewComponent<T extends DisplayableObj> implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Input / Output
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() item: T;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  jsonValue: Object;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    const itemChange = changes.item;
    if (itemChange === undefined) {
      return;
    }

    const newValueSerialized = itemChange.currentValue?.serialize() || {};
    if (!isEqual(newValueSerialized, this.jsonValue)) {
      this.jsonValue = (this.item as any)?.serialize() || {};
    }
  }
}
