import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnInit, Provider } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { combineLatestWith, Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { OrgData, UserData } from '@pwp-common';

import { NgChanges, varUnchanged } from '../../../../common/objects/ng-changes';
import { ArrayValidator } from '../../../../common/validators/array-validator/array-validator';
import { OrgDataService } from '../../../../services/orgs/org-data/org-data.service';
import { UserDataService } from '../../../../services/user/user-data/user-data.service';
import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';
import { WorkerEditorOutputItem } from '../editor-output/core/interface';
import { makeAllOptions } from '../editor-output/core/make-all-options/make-all-options';
import { WorkersAutocompleteEditorOutput } from '../editor-output/workers-autocomplete-editor-output';
import { CommunicationWorkerValidator } from '../validators/no-deleted-workers-validator/communication-worker-validator';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => WorkersAutocompleteComponent),
  multi: true,
};

const VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => WorkersAutocompleteComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-workers-autocomplete',
  templateUrl: './workers-autocomplete.component.html',
  styleUrls: ['./workers-autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class WorkersAutocompleteComponent
  extends FormGroupControlValueAccessor<WorkersAutocompleteEditorOutput, WorkersAutocompleteEditorOutput>
  implements OnInit, OnChanges
{
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////

  @Input() label: string;

  @Input() unique = true;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  private orgData$: Observable<OrgData>;

  private userData$: Observable<Map<string, UserData>>;

  public allOptions$: Observable<WorkerEditorOutputItem[]> = of([]);

  protected cachedAllOptions$: Observable<WorkerEditorOutputItem[]> = of([]);

  public suggestedOptions$: Observable<WorkerEditorOutputItem[]> = of([]);

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private userDataService: UserDataService,
    private orgDataService: OrgDataService,
  ) {
    super(changeDetectorRef);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    this.userData$ = this.userDataService.getDocs().pipe(shareReplay(1), untilDestroyed(this));
    this.orgData$ = this.orgDataService.getOrgData().pipe(shareReplay(1), untilDestroyed(this));

    this.allOptions$ = this.defineAllOptions();
    this.cachedAllOptions$ = this.allOptions$.pipe(
      map((options) => options),
      untilDestroyed(this),
      shareReplay(1),
    );
    this.setValidators();
    this.changeDetectorRef.markForCheck();
  }

  ngOnChanges(changes: NgChanges<WorkersAutocompleteComponent>) {
    if (!varUnchanged(changes?.unique)) {
      this.setValidators();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Set validators
  /////////////////////////////////////////////////////////////////////////////////////////////

  private setValidators() {
    const validators = [CommunicationWorkerValidator.noDeletedWorkers()];
    if (this.unique) {
      this.control.setValidators([...validators, ArrayValidator.noDuplicatesDeepEquality()]);
    } else {
      this.control.setValidators([...validators]);
    }
    this.form.updateValueAndValidity({ emitEvent: true });
    this.changeDetectorRef.markForCheck();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    this.form = new UntypedFormGroup({
      control: new UntypedFormControl([], [CommunicationWorkerValidator.noDeletedWorkers()]),
    });
    this.setValidators();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): any {
    const controlValue = value.control ?? undefined;
    return controlValue;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  public writeValue(value: any): void {
    if (isNil(value)) {
      return;
    }
    super.writeValue({ control: value });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get control() {
    return this.form.get('control') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Set All Options
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineAllOptions() {
    return this.orgData$.pipe(
      combineLatestWith(this.userData$),
      map((value) => {
        const orgData = value[0];
        const userData = Array.from(value[1].values());
        return makeAllOptions(userData, orgData);
      }),
      shareReplay(1),
      untilDestroyed(this),
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Autocomplete
  /////////////////////////////////////////////////////////////////////////////////////////////

  search(event: { originalEvent: PointerEvent; query: string }) {
    this.suggestedOptions$ = this.allOptions$.pipe(
      map((allOptionsArray) => allOptionsArray.filter((z) => z.searchString.includes(event.query.toLowerCase()))),
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'workers-autocomplete': this.form.value,
    };
  }
}
