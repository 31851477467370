import { NgModule } from '@angular/core';

import { IfRoleDirective } from './if-role/if-role.directive';
import { LoadingDirective } from './loading/loading.directive';

@NgModule({
  declarations: [IfRoleDirective, LoadingDirective],
  exports: [IfRoleDirective, LoadingDirective],
})
export class SharedDirectiveModule {}
