import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';

export class EventTypeSchema extends SerializableObjectSchema {
  // Display Name
  public static readonly internalName = 'internalName';

  // Timestamp when this data was last modified
  public static readonly displayName = 'displayName';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly internalName = 'MISSING-internalName';
    public static readonly displayName = 'MISSING-displayName';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    parameters[EventTypeSchema.internalName] = SchemaField.string(EventTypeSchema.Defaults.internalName);
    parameters[EventTypeSchema.displayName] = SchemaField.string(EventTypeSchema.Defaults.displayName);
    return parameters;
  }
}
