import {Mixin} from 'ts-mixer';
import {DBDocSchema} from '../../../../../../../generic/db-doc/db-doc-schema';
import {CommunicationSessionEventLogName} from '../../../../generic/communication-session-event-log-name';
import {CommunicationSessionEventLog} from '../../../../generic/communication-session-event-log/communication-session-event-log';
import {CommunicationSessionEventLogSchema} from '../../../../generic/communication-session-event-log/communication-session-event-log-schema';
import {CommunicationSessionEventLogDialPhoneConstructor} from './communication-session-event-log-dial-phone-constructor';
import {GenericDialedCallLog} from '../../../../../../../call/dialed-call-log/generic/any-dialed-call-log';
import {cloneDeep} from 'lodash';
import {CommunicationLogParticipant} from '../../../../../../communication-log-participant/communication-log-participant';
import {CommunicationSessionEventLogDialPhoneCallType} from '../communication-session-event-log-dial-phone-call-type';

export abstract class CommunicationSessionEventLogDialPhone extends Mixin(CommunicationSessionEventLog, GenericDialedCallLog) {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected callType!: CommunicationSessionEventLogDialPhoneCallType;
  protected participant!: CommunicationLogParticipant;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionEventLogDialPhoneConstructor) {
    (parameters as any)[CommunicationSessionEventLogSchema.type] = CommunicationSessionEventLogName.dialPhone;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    throw new Error('Error: Cannot get schema for abstract CommunicationSessionEventLogDialPhone');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Tracking Info
   */
  public getCallType() {
    return cloneDeep(this.callType);
  }

  public getParticipant() {
    return cloneDeep(this.participant);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Did Dial
  /////////////////////////////////////////////////////////////////////////////

  public didDial(): boolean {
    return true;
  }
}
