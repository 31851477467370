import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { translate } from '@ngneat/transloco';
import { isNil } from 'lodash';
import { MessageService } from 'primeng/api';
import { Table } from 'primeng/table';

import { hasFilter } from '../../../../common/p-table/has-filter';
import { PTableCol } from '../../../../common/p-table/p-table-col';

@Component({
  selector: 'app-data-table-caption',
  templateUrl: './data-table-caption.component.html',
  styleUrls: ['./data-table-caption.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class DataTableCaptionComponent {
  public globalFilter = '';

  ///////////////////////////////////////////////////////////////////////
  // Inputs & Outputs
  ///////////////////////////////////////////////////////////////////////

  @Input()
  table: Table;

  @Input()
  cols: PTableCol[];

  @Input()
  translationScope: string;

  @Input()
  selectedCols: PTableCol[] = [];

  @Output()
  selectedColsChange = new EventEmitter<PTableCol[]>();

  @Input() actionsTemplate: TemplateRef<any>;

  @Input() rightActionsTemplate: TemplateRef<any>;

  @Input() refreshClicked: () => void;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////
  constructor(private messageService: MessageService) {}

  ///////////////////////////////////////////////////////////////////////
  // Update Columns
  ///////////////////////////////////////////////////////////////////////

  emitUpdatedCols() {
    this.selectedColsChange.emit(this.selectedCols);
  }

  ///////////////////////////////////////////////////////////////////////
  // Clear
  ///////////////////////////////////////////////////////////////////////
  clearTable(table: Table) {
    table.clear();
    this.globalFilter = '';
    const title = translate('data-table-caption.filtersClearedTitle');

    this.messageService.add({
      severity: 'success',
      summary: title,
      closable: true,
      sticky: false,
      life: 3000,
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Filter
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  hasFilter(): boolean {
    if (isNil(this.table)) {
      return false;
    }
    return hasFilter(this.table);
  }
}
