import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

import { AsyncServiceRequestE164Phone, CallLog, DBMatchRuleDocPath, DBMatchRuleName, Note, NoteBodyText, NoteSchema } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { DBQuery, GetDocsArrayOptions } from '../../generic/interfaces';
import { AuthService } from '../../user/auth/auth.service';

import { CreateNoteInput } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class AsyncServiceRequestNotesService extends DbDocumentService<Note> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, Note);
  }

  public getNotesByAsyncRequestId(asyncServiceRequestId: string, options: Omit<GetDocsArrayOptions, 'query'> = {}): Observable<Note[]> {
    const orgScopedPaths = new DBMatchRuleDocPath({
      orgScopedPaths: [AsyncServiceRequestE164Phone.getSchema().getOrgScopedPath(asyncServiceRequestId)],
      type: DBMatchRuleName.docPath,
    }).getOrgScopedPaths();

    const query: DBQuery[] = [
      {
        fieldPath: `${NoteSchema.tags}.orgScopedPaths`,
        opStr: 'array-contains-any',
        value: orgScopedPaths,
      },
    ];

    return this.getDocsArray({ query, ...options });
  }

  public createNote({ body, asyncServiceRequestId, callLogId }: CreateNoteInput): Note {
    return new Note({
      id: this.createId(),
      body: new NoteBodyText({ value: body }),
      tags: new DBMatchRuleDocPath({
        orgScopedPaths: [
          CallLog.getSchema().getOrgScopedPath(callLogId),
          AsyncServiceRequestE164Phone.getSchema().getOrgScopedPath(asyncServiceRequestId),
        ],
        type: DBMatchRuleName.docPath,
      }),
    });
  }
}
