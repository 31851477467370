import { isNil, isNumber } from 'lodash';

import { CommunicationTaskQueueSchema } from '@pwp-common';

import { TargetModeEditorOutput } from '../target-mode-editor-output';
import { TargetModeFormMode, TargetModeFormType } from '../target-mode-form-type';

export const targetModeToFormType = (targetMode: TargetModeEditorOutput): TargetModeFormType => {
  if (
    isNil(targetMode) ||
    !isNumber(targetMode.maxReservedWorkers) ||
    targetMode.maxReservedWorkers < CommunicationTaskQueueSchema.Constants.maxReservedWorkersMin ||
    targetMode.maxReservedWorkers > CommunicationTaskQueueSchema.Constants.maxReservedWorkersMax
  ) {
    return {
      mode: TargetModeFormMode.oneAtATime,
      maxReservedWorkers: 1,
    };
  }

  if (targetMode.maxReservedWorkers === 1) {
    return {
      mode: TargetModeFormMode.oneAtATime,
      maxReservedWorkers: 1,
    };
  }

  return {
    mode: TargetModeFormMode.severalAtATime,
    maxReservedWorkers: targetMode.maxReservedWorkers,
  };
};
