import { isNil } from 'lodash';
import moment from 'moment-timezone';

import { getTimeRangeFromStr } from './get-time-range-from-str';

export const getIsoDurationFromTimeRangeStr = (timeRangeStr: string): string => {
  const result = getTimeRangeFromStr(timeRangeStr);
  if (isNil(result)) {
    console.error({ timeRangeStr, result });
    throw new Error('getIsoDurationFromTimeRangeStr: User error, the provided inout is not a valid time range');
  }

  const isoDuration = moment.duration(result.end.diff(result.start)).toISOString();
  return isoDuration;
};
