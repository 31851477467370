import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isNil } from 'lodash';

import { VoiceResponseCommandName } from '@pwp-common';

export const serviceOperationValidator =
  () =>
  (control: AbstractControl): ValidationErrors | null => {
    const commands = control?.get('commands')?.value;
    if (isNil(commands)) {
      return null;
    }
    const hasDialCallList = commands.some((command) => command?.commandName === VoiceResponseCommandName.dialCallList);

    if (hasDialCallList && commands[commands.length - 1]?.commandName !== VoiceResponseCommandName.dialCallList) {
      return { dialCallListNotLast: true };
    }
    return null;
  };
