import { translate } from '@ngneat/transloco';

import { MenuOption } from '../../../../../common/form/typings/menu-options';
import { AllRequestsForOneEventSlotSummary } from '../../../common/all-requests-for-one-event-slot-summary';

import { AllRequestsForOneEventSlotSummaryData } from './interface';

export const createApproveRequestOptions = (
  summary: AllRequestsForOneEventSlotSummary,
  summaryData: AllRequestsForOneEventSlotSummaryData,
): MenuOption[] =>
  summary.allDataEvent.getRequests().map((request) => {
    const translationParams = {
      ...summaryData,
      requestedBy: summary.allDataEvent.getAllDataUserMap().get(request.getRequestedAssignedUserId()).userData.getDisplayNameWithEmail(),
    };

    return {
      label: translate('event-request-action-option.approveRequest', translationParams),
      description: translate('event-request-action-option.approveRequestDescription', translationParams),
      value: request.getId(),
    };
  });
