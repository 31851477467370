<ng-container *transloco="let t; read: 'make-event-request-table'">
  <p-toast></p-toast>
  <p-card *ngrxLet="eventRequestConfigs$ as eventRequestConfigs" class="selector-card">
    <ng-template pTemplate="content">
      <app-data-table
        [cols]="cols"
        [loading]="loading"
        [refreshClicked]="getDataWrapper"
        [rowIconButtons]="[{ icon: 'pi pi-trash', name: 'Delete' }]"
        [selectedCols]="cols"
        [tableRows]="tableRows$"
        [title]="t('title')"
        [translationScope]="'make-event-request-table'"
        (rowIconButtonClicked)="onRowRemoved($event)"
      >
        <ng-container appDataTableColumnDef="requestedSlot">
          <th *appDataTableHeader>{{ t('colRequestedSlot') }}</th>
          <td *appDataTableCell="let rowData">
            <app-requested-slot-select
              *ngIf="requestedSlotForm.controls[rowData.id] as slotControl"
              [control]="slotControl"
              [eventRequestConfig]="eventRequestConfigs[rowData.eventType]"
              [rowData]="rowData"
            >
            </app-requested-slot-select>
          </td>
        </ng-container>
      </app-data-table>
    </ng-template>
    <ng-template pTemplate="footer">
      <button
        pButton
        class="p-button-raised"
        label="{{ t('actionButtonTitle', { num: selectedAllDataEventDisplay.size }) }}"
        [disabled]="selectedAllDataEventDisplay.size === 0 || uploadingRequest"
        [loading]="uploadingRequest"
        (click)="submit()"
      ></button>
    </ng-template>
  </p-card>
</ng-container>
