import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { isNil } from 'lodash';

import { TakeVoicemail, TakeVoicemailSchema, VoicemailConfig } from '@pwp-common';

import { KVPair } from '../../../../common/objects/kvpair';
import { VRCEditor } from '../../../generic/abstract-classes/vrc-editor';

@Component({
  selector: 'app-vrc-editor-take-voicemail',
  templateUrl: './vrc-editor-take-voicemail.component.html',
  styleUrls: ['./vrc-editor-take-voicemail.component.css'],
})
export class VrcEditorTakeVoicemailComponent extends VRCEditor<TakeVoicemail> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  selectedVoicemailConfigKVPair: KVPair<VoicemailConfig>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = {} as any;
    parameters[TakeVoicemailSchema.voicemailConfigId] =
      this.selectedVoicemailConfigKVPair?.value?.getId() || TakeVoicemailSchema.Defaults.voicemailConfigId;

    const value = new TakeVoicemail(parameters);
    return new KVPair({ id: this.obj.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<TakeVoicemail>) {
    // Init Form
    const formConfig = {} as any;
    this.form = this.formBuilder.group(formConfig);

    this.selectedVoicemailConfigKVPair = new KVPair({ id: obj.value!.getVoicemailConfigId() });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj() {
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.selectedVoicemailConfigKVPair?.value)) {
      return false;
    }

    return true;
  }
}
