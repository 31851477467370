import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { CommunicationWidgetCreateAsyncServiceRequest, CommunicationWidgetName } from '@pwp-common';

import { AuthService } from '../../../user/auth/auth.service';
import { CommunicationWidgetService } from '../communication-widget/communication-widget.service';

@Injectable({
  providedIn: 'root',
})
export class CommunicationWidgetCreateAsyncServiceRequestService extends CommunicationWidgetService<CommunicationWidgetCreateAsyncServiceRequest> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, CommunicationWidgetCreateAsyncServiceRequest);
  }

  ///////////////////////////////////////////////////////////////////////
  // Abstract Methods
  ///////////////////////////////////////////////////////////////////////

  protected getType(): CommunicationWidgetName {
    return CommunicationWidgetName.createAsyncServiceRequest;
  }
}
