import { formatDuration } from '@pwp-common';

import { UserStatsRecord } from '../interfaces';

export const formatUsersWithDuration = ({ users, count }: UserStatsRecord): string | undefined => {
  if (users.length === 0) {
    return undefined;
  }

  const duration = formatDuration(count, { aggregateDays: false });

  return users.map((user) => `${user.getDisplayNameWithEmail()} (${duration})`).join(', ');
};
