import {DBMatchRuleSchema} from '../../generic/db-match-rule/db-match-rule-schema';
import {SchemaField} from '../../../../../generic/serialization/schema-field';

export class DBMatchRuleE164PhoneSchema extends DBMatchRuleSchema {
  public static readonly e164Phones = 'e164Phones';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly e164Phones = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[DBMatchRuleE164PhoneSchema.e164Phones] = SchemaField.arrayOfType(SchemaField.e164Phone(undefined), DBMatchRuleE164PhoneSchema.Defaults.e164Phones).required();
    /* eslint-enable */

    return parameters;
  }
}
