
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {VoiceResponseCommandName} from '../../any-voice-response-command/voice-response-command-name';
import {VoiceResponseCommand} from '../../generic/voice-response-command';
import {VoiceResponseCommandSchema} from '../../generic/voice-response-command-schema';
import {SwitchConstructor} from './switch-constructor';
import {SwitchSchema} from './switch-schema';
import {SwitchCase} from '../switch-case/switch-case';
import {SwitchOnField} from './switch-on-field';
import {cloneDeep} from 'lodash';
import {SwitchCaseSchema} from '../switch-case/switch-case-schema';

export class Switch extends VoiceResponseCommand {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  private switchOnField!: SwitchOnField;

  private cases!: SwitchCase[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructors
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: SwitchConstructor) {
    // This hack ensures that deserializing a VoiceCommand is equal to instanting it directly.
    // The difference is trivial, and only required for intuitive unit testing.
    (parameters as any)[VoiceResponseCommandSchema.commandName] = VoiceResponseCommandName.switch;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): Switch {
    return new Switch(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(Switch.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new SwitchSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getSwitchOnField(): SwitchOnField {
    return cloneDeep(this.switchOnField);
  }

  public getCase(value: string, throwIfMissing = true): SwitchCase | undefined {
    for (const _case of this.cases) {
      if (_case.getValue() === value) {
        return _case;
      }
    }
    if (throwIfMissing) {
      throw new Error(`There is no switch case with value='${value}'`);
    }
    return undefined;
  }

  public getDefaultCase(): SwitchCase | undefined {
    return this.getCase(SwitchCaseSchema.Constants.defaultCaseIdentifier, false);
  }

  public getCases(): SwitchCase[] {
    return cloneDeep(this.cases);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
