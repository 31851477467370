import { TranslocoService } from '@ngneat/transloco';
import { untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';

/**
 * PTable requires that columns conform to this format.
 */
export interface PTableCol {
  field: string;
  header: string;
  label?: string;
}

/**
 * @deprecated
 * use function located in 'app/common/p-table/p-table-col.ts' instead
 */
const makePTableCols = (translocoService: TranslocoService, translocoPrefix: string, cols: string[]): PTableCol[] =>
  cols.map((col) => {
    const header = translocoService.translate(`${translocoPrefix}.${col}`);
    return {
      field: col,
      header,
    };
  });

/**
 * PTable requires columns to be PTableCols. This function make sure they are
 * filled with translated info in the current language
 *
 * @param component
 * @param translocoService
 * @param translocoPrefix
 * @param cols
 * @param selectedCols
 * @returns
 */
export const subscribePTableTranslatedCols = (
  component: any,
  translocoService: TranslocoService,
  translocoPrefix: string,
  cols: string[],
  selectedCols: string[],
): void => {
  if (isNil(cols)) {
    return;
  }

  component.translocoService.langChanges$.pipe(untilDestroyed(component)).subscribe((lang: string) => {
    component.cols = makePTableCols(translocoService, translocoPrefix, cols) as PTableCol[];
    component.selectedCols = component.cols.filter((z: PTableCol) => (selectedCols || cols).includes(z.field));
  });
};
