import {cloneDeep} from 'lodash';
import {DeleteUserRequestSchema} from './delete-user-request-schema';
import {RequestObj} from '../../generic/request/request-obj';
import {DeleteUserRequestConstructor} from './delete-user-request-constructor';

export class DeleteUserRequest extends RequestObj {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: DeleteUserRequestConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected userId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): DeleteUserRequest {
    return new DeleteUserRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(DeleteUserRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DeleteUserRequestSchema {
    return new DeleteUserRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getUserId(): string {
    return cloneDeep(this.userId);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setUserId(userId: string): DeleteUserRequest {
    this.userId = userId;
    return this;
  }
}
