import {
  CommunicationTaskQueue,
  CommunicationTaskQueueWorkerListEvent,
  CommunicationTaskQueueWorkerListName,
  CommunicationTaskQueueWorkerListUser,
  OrgData,
  UserData,
} from '@pwp-common';

import { WorkerEditorOutputItem } from '../../core/interface';
import { makeEventOption } from '../../core/make-event-option/make-event-option';
import { makeUserOption } from '../../core/make-user-option/make-user-option';

export const taskQueueToEditorOutput = (
  taskQueue: CommunicationTaskQueue,
  users: Map<string, UserData>,
  orgData: OrgData,
): WorkerEditorOutputItem[] => {
  const options: WorkerEditorOutputItem[] = [];

  for (const workerList of taskQueue.getWorkers()) {
    switch (workerList.getType()) {
      case CommunicationTaskQueueWorkerListName.userList: {
        const typedWorkerList = workerList as CommunicationTaskQueueWorkerListUser;
        for (const userId of typedWorkerList.getUserIds()) {
          options.push(makeUserOption(userId, users.get(userId)));
        }
        break;
      }
      case CommunicationTaskQueueWorkerListName.event: {
        const typedWorkerList = workerList as CommunicationTaskQueueWorkerListEvent;
        for (const eventTypeId of typedWorkerList.getEventTypes()) {
          for (const assignedUserType of typedWorkerList.getAssignedUserTypes()) {
            options.push(makeEventOption(assignedUserType, eventTypeId, orgData));
          }
        }
        break;
      }
      default: {
        console.error(workerList, taskQueue, users, orgData);
        throw new Error('taskQueueToEditorOutput: User Error: unknown type');
      }
    }
  }

  return options;
};
