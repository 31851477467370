import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../generic/serialization/schema-field';
import {NoteBodySchema} from '../../generic/note-body/note-body-schema';

export class NoteBodyTextSchema extends NoteBodySchema {
  /////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class {
    public static readonly type = undefined;
    public static readonly value = undefined;
  };

  /////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  /////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[NoteBodySchema.value] = SchemaField.string(NoteBodyTextSchema.Defaults.value).required();

    return parameters;
  }
}
