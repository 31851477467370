import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { chunk } from 'lodash';
import { EMPTY, firstValueFrom, Observable } from 'rxjs';

import { AsyncServiceRequestSchema, CallLog, CallLogSchema, CommunicationSessionId, DialedCallLog, mergeMaps } from '@pwp-common';

import { AsyncServiceRequestService } from '../../async-service-request/async-service-request.service';
import { descending } from '../../core/queries/order-by/order-by';
import { equalTo, greaterThanOrEqualTo, isOneOf, lessThanOrEqualTo } from '../../core/queries/where/operations';
import { where } from '../../core/queries/where/where';
import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from '../../user/auth/auth.service';
import { RolesService } from '../../user/roles/roles.service';

import { GetCallsWithStartInRangeOptions } from './interface';

@Injectable({
  providedIn: 'root',
})
export class CallLogService extends DbDocumentService<CallLog> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private asyncServiceRequestService: AsyncServiceRequestService,
    private rolesService: RolesService,
    db: AngularFirestore,
    authService: AuthService,
  ) {
    super(db, authService, CallLog);
  }

  private async getVoicemailUserCallLogs(callLogs: CallLog[], userId: string): Promise<CallLog[]> {
    const callLogSessionIds = Array.from(
      callLogs.map((callLog) => new CommunicationSessionId({ inboundCallSessionId: callLog.getId() }).serialize()),
    );
    const asyncServiceRequestsSessionIdChunks = chunk(callLogSessionIds, 30);

    const asyncServiceRequestsByUser = mergeMaps(
      await Promise.all(
        asyncServiceRequestsSessionIdChunks.map((asyncServiceRequestSessionIds) =>
          firstValueFrom(
            this.asyncServiceRequestService.getDocs(
              where<typeof AsyncServiceRequestSchema>({
                assignedTo: equalTo(userId),
                sessionId: isOneOf(asyncServiceRequestSessionIds),
              }),
            ),
          ),
        ),
      ),
    );

    return callLogs.filter(
      (callLog) => callLog.getAnsweredBy() === userId || asyncServiceRequestsByUser.has(callLog.getAsyncServiceRequestId()),
    );
  }

  /////////////////////////////////////////////////////////////////////
  // Events with start in range
  /////////////////////////////////////////////////////////////////////

  public getCallsWithStartInRange(
    start: moment.Moment,
    end: moment.Moment,
    { takeOne = true, additionalConditions = {} }: GetCallsWithStartInRangeOptions = {},
  ): Observable<CallLog[]> {
    console.log(`getCallsWithStartInRange:\n\tStart:\t${start}\n\tTo:\t\t${end}\n\ttakeOne:${takeOne}`);

    return this.getDocsArray({
      query: where<typeof CallLogSchema>({
        ...additionalConditions,
        incomingCallReceivedTime: [greaterThanOrEqualTo(start), lessThanOrEqualTo(end)],
      }),
      orderBy: descending(CallLogSchema.incomingCallReceivedTime),
      takeOne,
    });
  }

  public async getCallLogsVisibleByUser(start: moment.Moment, end: moment.Moment): Promise<CallLog[]> {
    const [roles, userId] = await Promise.all([
      firstValueFrom(this.rolesService.getRolesMap()),
      firstValueFrom(this.authService.getUserId()),
    ]);

    const canOnlySeeOwnCalls = !roles.orgAdmin && !roles.voicemail;

    const callLogs = await firstValueFrom(
      this.getCallsWithStartInRange(start, end, {
        additionalConditions: canOnlySeeOwnCalls ? { answeredBy: equalTo(userId) } : {},
      }),
    );

    if (!roles.orgAdmin && roles.voicemail) {
      return this.getVoicemailUserCallLogs(callLogs, userId);
    }

    return callLogs;
  }

  ///////////////////////////////////////////////////////////////////////
  // Get all dialed calls made for a given sessionId
  ///////////////////////////////////////////////////////////////////////

  public getDialedCalls(sessionId: string): Observable<DialedCallLog[]> {
    // const observable = from(this.dialedCallsCollectionRef(sessionId).get()).pipe(
    //   map((snapshot, _) => getDialedCallListFromFirebase(snapshot))
    // );
    // return observableTakeOne(observable, true);
    return EMPTY;
  }
}
