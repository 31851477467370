<ng-container *transloco="let t; read: 'admin-reports'">
  <app-data-table
    *ngrxLet="tableCols$ as tableCols"
    [cols]="tableCols"
    [loading]="loading$ | async"
    [rightActionsTemplate]="dateRangeSelect"
    [selectedCols]="tableCols"
    [tableRows]="tableRows$"
    [title]="t('title')"
    [translationScope]="'admin-reports'"
  >
  </app-data-table>

  <ng-template #dateRangeSelect>
    <app-date-range-select [formControl]="dateRange" [label]="t('dateRangeSelectLabel')"></app-date-range-select>
  </ng-template>

  <p-dialog [closable]="false" [header]="t('loadingDialog.header')" [modal]="true" [visible]="displayLoadingDialog$ | async">
    <div *ngIf="dateRange.value as range">
      {{
        t('loadingDialog.body', {
          start: (range.start | date: 'MMM d, y'),
          end: (range.end | date: 'MMM d, y')
        })
      }}
    </div>

    <div class="flex flex-column align-items-center justify-content-center gap-3 mt-3">
      <span>{{ t('loadingDialog.remainingTime') }}: {{ remainingLoadingTime$ | async | date: 'mm:ss' }}</span>

      <app-progress-spinner></app-progress-spinner>
    </div>
  </p-dialog>
</ng-container>
