import { isEmpty, isNil } from 'lodash';

import { DateRangeSelectOutput } from '../date-range-select-output';

export const parseDateArray = (dateRange: Date[]): DateRangeSelectOutput | undefined => {
  if (isEmpty(dateRange) || dateRange.length !== 2) {
    return undefined;
  }

  if (isNil(dateRange[0]) || isNil(dateRange[1])) {
    return undefined;
  }

  return {
    start: dateRange[0],
    end: dateRange[1],
  };
};

// const convertStrToMoment = (timeStr: string | undefined): moment.Moment | undefined => {
//   if (isNil(timeStr)) {
//     return undefined;
//   }
//
//   const localTime = moment(timeStr, CONTROL_STR_FORMAT);
//   if (!localTime.isValid()) {
//     return undefined;
//   }
//
//   return localTime;
// };
