import { Component, Input } from '@angular/core';

import { CalleeEntity, EventType, UserData } from '@pwp-common';

import { ObjListEditor } from '../../../generic/abstract-classes/obj-list-editor';

@Component({
  selector: 'app-callee-entity-select-and-edit',
  templateUrl: './callee-entity-select-and-edit.component.html',
  styleUrls: ['./callee-entity-select-and-edit.component.css'],
})
export class CalleeEntitySelectAndEditComponent extends ObjListEditor<CalleeEntity> {
  @Input()
  eventTypes: EventType[];

  @Input()
  userDataArray: UserData[];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Validation
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  objBuilder() {
    return CalleeEntity.deserialize({});
  }

  isValidList(): boolean {
    // This checks that the form is valid.
    if (!super.isValidList()) {
      return false;
    }

    if (this.objList.length === 0) {
      return false;
    }

    return true;
  }
}
