<ng-container *transloco="let t; read: 'color-dropdown'">
  <form class="full-width">
    <mat-form-field class="half-width">
      <mat-select placeholder="Color" [(value)]="selectedColor">
        <mat-option [value]="reservedColor"> {{ t('reserved') }} </mat-option>
        <mat-option [value]="resetDefault"> {{ t('default') }} </mat-option>
        <mat-option [value]="noChange"> {{ t('noChange') }} </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</ng-container>
