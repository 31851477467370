import {CommunicationWorkflow} from '../../communication-workflow/communication-workflow/communication-workflow';
import {CommunicationWorkflowDefaultFilterSchema} from '../../communication-workflow/communication-workflow-default-filter/communication-workflow-default-filter-schema';
import {CommunicationWorkflowTargetSchema} from '../../communication-workflow/communication-workflow-target/communication-workflow-target/communication-workflow-target-schema';

import {isNil} from 'lodash';
import {isNilOrDefault} from '../../../generic/serialization/is-nil-or-default';

const targetSchema = new CommunicationWorkflowTargetSchema();
const defaultFilterSchema = new CommunicationWorkflowDefaultFilterSchema();

export const getTaskQueueIds = (workflow: CommunicationWorkflow): Set<string> => {
  const queueIds = new Set<string>();
  if (isNil(workflow)) {
    return queueIds;
  }
  // Default filter
  const defaultFilter = workflow.getDefaultFilter();
  if (!isNil(defaultFilter)) {
    const queueId = defaultFilter.getQueueId();
    if (!isNilOrDefault(queueId, CommunicationWorkflowDefaultFilterSchema.queueId, defaultFilterSchema)) {
      queueIds.add(queueId!);
    }
  }

  // Filter
  for (const filter of workflow.getFilters()) {
    for (const target of filter.getTargets()) {
      const queueId = target.getQueueId();
      if (!isNilOrDefault(queueId, CommunicationWorkflowTargetSchema.queueId, targetSchema)) {
        queueIds.add(queueId!);
      }
    }
  }

  return queueIds;
};
