import { RouterModule, Routes } from '@angular/router';

import { CommunicationLogsTableComponent } from './components/communication-logs/communication-logs-table/communication-logs-table.component';
import { OrgDataSelectAndEditComponent } from './components/config/org-data-select-and-edit/org-data-select-and-edit.component';
import { LogoutComponent } from './components/logout/logout.component';
import { AdminGuard } from './guards/admin/admin.guard';
import { AuthGuard } from './guards/auth/auth.guard';
import { VoicemailGuard } from './guards/voicemail/voicemail.guard';
import { AdminAnalyticsComponent } from './pages/admin-analytics/admin-analytics.component';
import { ChatComponent } from './pages/chat/chat.component';
import { EventRequestsComponent } from './pages/event-requests/event-requests.component';
import { EventSelectorComponent } from './pages/event-selector/event-selector.component';
import { InboxComponent } from './pages/inbox/inbox.component';
import { IncomingChatComponent } from './pages/incoming-chat/incoming-chat.component';
import { LoginComponent } from './pages/login/login.component';
import { ModifyScheduleComponent } from './pages/modify-schedule/modify-schedule.component';
import { OrgSettingsComponent } from './pages/org-settings/org-settings.component';
import { ServiceExceptionsComponent } from './pages/service-exceptions/service-exceptions.component';
import { SupportComponent } from './pages/support/support.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { UsersAdminComponent } from './pages/users-admin/users-admin.component';

// Route Configuration
export const routes: Routes =
  // prettier-ignore
  [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login',                component: LoginComponent },
    { path: 'incomingChat',         component: IncomingChatComponent },
    { path: 'logout',               component: LogoutComponent},
    { path: 'user-profile',         component: UserProfileComponent,        canActivate: [AuthGuard]},
    { path: 'chat',                 component: ChatComponent,               canActivate: [AuthGuard]},
    { path: 'events-calendar',      component: EventSelectorComponent,      canActivate: [AuthGuard]},
    { path: 'event-requests',       component: EventRequestsComponent,      canActivate: [AuthGuard, AdminGuard]},
    { path: 'modify-schedule',      component: ModifyScheduleComponent,     canActivate: [AuthGuard, AdminGuard]},
    { path: 'users',                component: UsersAdminComponent,         canActivate: [AuthGuard, AdminGuard]},
    { path: 'call-logs',            component: CommunicationLogsTableComponent,canActivate: [AuthGuard]},
    { path: 'stats',                component: AdminAnalyticsComponent,     canActivate: [AuthGuard, AdminGuard]},
    { path: 'support',              component: SupportComponent,            canActivate: [AuthGuard, AdminGuard]},
    { path: 'serviceExceptions',    component: ServiceExceptionsComponent,  canActivate: [AuthGuard, AdminGuard]},
    { path: 'orgSettings',          component: OrgSettingsComponent,        canActivate: [AuthGuard, AdminGuard]},
    { path: 'inbox',                component: InboxComponent,              canActivate: [AuthGuard, VoicemailGuard]},
    { path: 'communicationWorkflow',component: OrgSettingsComponent,        canActivate: [AuthGuard, AdminGuard]},
    // PWP Admin Options
    { path: 'org-select-and-edit',  component: OrgDataSelectAndEditComponent,canActivate: [AuthGuard, AdminGuard]},
];
export const routing = RouterModule.forRoot(routes, {
  relativeLinkResolution: 'legacy',
});
