import {CommunicationSessionEventLogDialConferenceConstructor} from './communication-session-event-log-dial-conference-constructor';
import {CommunicationSessionEventLogDialConferenceSchema} from './communication-session-event-log-dial-conference-schema';
import {CommunicationSessionEventLogDialPhone} from '../../generic/communication-session-event-log-dial-phone/communication-session-event-log-dial-phone';
import {CommunicationSessionEventLogDialPhoneCallType} from '../../generic/communication-session-event-log-dial-phone-call-type';
import {CommunicationSessionEventLogDialPhoneSchema} from '../../generic/communication-session-event-log-dial-phone/communication-session-event-log-dial-phone-schema';
import {UserStatus} from '../../../../../../../call/dialed-call-log/user-status';
import {cloneDeep} from 'lodash';

export class CommunicationSessionEventLogDialConference extends CommunicationSessionEventLogDialPhone {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected requestedByUserId!: string;
  protected userStatus!: UserStatus | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionEventLogDialConferenceConstructor) {
    (parameters as any)[CommunicationSessionEventLogDialPhoneSchema.callType] = CommunicationSessionEventLogDialPhoneCallType.dialConference;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationSessionEventLogDialConference {
    return new CommunicationSessionEventLogDialConference(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationSessionEventLogDialConference.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): CommunicationSessionEventLogDialConferenceSchema {
    return new CommunicationSessionEventLogDialConferenceSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
  public getRequestedByUserId() {
    return cloneDeep(this.requestedByUserId);
  }
  /**
   * This value should always be defined. It is marked as optional because
   * the log is written to the DB in parts, and this field may be missing depending
   * on which part of the log is written first.
   */
  public getUserStatus() {
    return cloneDeep(this.userStatus);
  }
}
