import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import moment from 'moment-timezone';

import { GLOBAL_TIMEZONE } from '@pwp-common';

import { getFieldControlValueOrDefault } from '../../../../common/objects/form-helper';
import { ComponentWithForm } from '../../../generic/abstract-classes/component-with-form';

@Component({
  selector: 'app-repeat-schedule-dialog',
  templateUrl: './repeat-schedule-dialog.component.html',
  styleUrls: ['./repeat-schedule-dialog.component.css'],
})
export class RepeatScheduleDialogComponent extends ComponentWithForm implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public dialogRef: MatDialogRef<RepeatScheduleDialogComponent>,
    private formBuilder: UntypedFormBuilder,
  ) {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Close Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  public getEndDate() {
    const dateValue = getFieldControlValueOrDefault(this.endDate, new Date());
    const momentValue = moment(dateValue).tz(GLOBAL_TIMEZONE).endOf('day');
    return momentValue;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;
    formConfig.endDate = [new Date(), [Validators.required]];
    this.form = this.formBuilder.group(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get endDate(): AbstractControl | null {
    return this.form.get('endDate');
  }
}
