import { cloneDeep } from 'lodash';

import { defaultReservationOfferChannelConfigEditorOutput } from '../reservation-offer-config/objects/channel/default-reservation-offer-channel-config-editor-output';
import { defaultReservationOfferEditorOutput } from '../reservation-offer-config/objects/offers/reservation-offer/default-reservation-offer-editor-output';
import { defaultReservationSkipOffersIfEditorOutput } from '../reservation-offer-config/objects/reservation-skip-offers-if/default-reservation-skip-offers-if-editor-output';
import { defaultTargetMode } from '../target-mode/default-target-mode';
import { defaultTargetTimeoutSeconds } from '../target-timeout/default-target-timeout-seconds';

import { TargetEditorOutput } from './target-editor-output';

export const defaultTargetEditorOutput: TargetEditorOutput = {
  communicationTaskQueueId: undefined,
  mode: cloneDeep(defaultTargetMode),
  workers: [],
  workflowTargetTimeoutSeconds: defaultTargetTimeoutSeconds,
  offers: [cloneDeep(defaultReservationOfferEditorOutput)],
  channelConfig: cloneDeep(defaultReservationOfferChannelConfigEditorOutput),
  skipOffersIf: defaultReservationSkipOffersIfEditorOutput,
};
