import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { CalendarModule } from 'primeng/calendar';

import { DateRangeSelectComponent } from './date-range-select/date-range-select.component';

@NgModule({
  declarations: [DateRangeSelectComponent],
  exports: [DateRangeSelectComponent],
  imports: [CalendarModule, CommonModule, ReactiveFormsModule, TranslocoModule],
})
export class DateRangeSelectModule {}
