import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';

import { AllDataServiceException, ServiceExceptionSchema } from '@pwp-common';

import { FormGroupControlValueAccessor } from '../../../../generic/abstract-classes/form-group-control-value-accessor';
import { makeServiceExceptionEditorOutput } from '../editor-output/make-service-exception-editor-output/make-service-exception-editor-output';
import { ServiceExceptionEditorOutput } from '../editor-output/service-exception-editor-output';

import { defaultServiceExceptionEditorOutput } from './default-value';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ServiceExceptionEditorComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ServiceExceptionEditorComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-service-exception-editor',
  templateUrl: './service-exception-editor.component.html',
  styleUrls: ['./service-exception-editor.component.css'],
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class ServiceExceptionEditorComponent extends FormGroupControlValueAccessor<any, ServiceExceptionEditorOutput> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  baseObj: ServiceExceptionEditorOutput | undefined = undefined;

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////
  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    const formConfig = {} as any;
    formConfig[ServiceExceptionSchema.displayName] = new UntypedFormControl(undefined, [
      Validators.required,
      Validators.minLength(3),
      Validators.maxLength(500),
    ]);
    formConfig[ServiceExceptionSchema.description] = new UntypedFormControl(undefined, [Validators.maxLength(500)]);
    formConfig.e164PhoneMatchers = new UntypedFormControl([], [Validators.maxLength(100)]);
    formConfig.onCreateServiceCommands = new UntypedFormControl();

    this.form = new UntypedFormGroup(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: AllDataServiceException | ServiceExceptionEditorOutput | undefined | null) {
    /**
     * Preserve any properties of the input, if it is specified
     */
    this.baseObj = makeServiceExceptionEditorOutput(value);

    /**
     * Update the default obj with values specified in the form
     */

    const formValue = {
      description: this.baseObj.description,
      displayName: this.baseObj.displayName,
      e164PhoneMatchers: this.baseObj.e164PhoneMatchers,
      onCreateServiceCommands: this.baseObj.onCreateServiceCommands,
    };

    this.form.setValue(formValue, { emitEvent: true });
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): ServiceExceptionEditorOutput {
    const result = cloneDeep(this.baseObj ?? defaultServiceExceptionEditorOutput);

    result.description = this.description.value ?? result.description;
    result.displayName = this.displayName.value ?? result.displayName;
    result.e164PhoneMatchers = this.e164PhoneMatchers.value ?? result?.e164PhoneMatchers;
    result.onCreateServiceCommands = this.onCreateServiceCommands.value ?? result.onCreateServiceCommands;

    return result;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName() {
    return this.form.get(ServiceExceptionSchema.displayName) as UntypedFormControl;
  }

  get description() {
    return this.form.get(ServiceExceptionSchema.description) as UntypedFormControl;
  }

  get e164PhoneMatchers() {
    return this.form.get('e164PhoneMatchers') as UntypedFormControl;
  }

  get onCreateServiceCommands() {
    return this.form.get('onCreateServiceCommands') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  public makeValidationErrors(): ValidationErrors {
    return {
      'service-exception-editor': this.form.value,
    };
  }
}
