import {cloneDeep} from 'lodash';
import {CalleeEntityConstructor} from './callee-entity-constructor';
import {CalleeEntitySchema} from './callee-entity-schema';
import {SerializableObject} from '../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {CalleeEntityType} from './enums';
import {AssignedUserType} from '../../event/event-data/enums';
import {isValidTimeRange, timeRangeContains, parseTimeRangeToMoment} from '../../../helper/time-range';
import moment from 'moment-timezone';
import {GLOBAL_TIMEZONE} from '../../../helper/constants';
import {Displayable} from '../../generic/db-doc/displayable';

export class CalleeEntity extends SerializableObject implements Displayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected type!: CalleeEntityType;
  protected eventType: string | undefined;
  protected assignedUserTypes: AssignedUserType[] | undefined;
  protected userList: string[] | undefined;
  protected blockoutTimes!: string[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CalleeEntityConstructor) {
    super(parameters);

    if (!isValidTimeRange(this.getBlockoutTimes())) {
      throw new Error(`CalleeEntity: Invalid blockout times! ${this.getBlockoutTimes()}`);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CalleeEntity {
    return new CalleeEntity(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CalleeEntity.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CalleeEntitySchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getType(): CalleeEntityType {
    return cloneDeep(this.type);
  }

  public getEventType(): string | undefined {
    return cloneDeep(this.eventType);
  }

  public getAssignedUserTypes(): AssignedUserType[] | undefined {
    return cloneDeep(this.assignedUserTypes);
  }

  public getUserList(): string[] | undefined {
    return cloneDeep(this.userList);
  }

  public getBlockoutTimes(): string[] {
    return cloneDeep(this.blockoutTimes);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Blocked Out
  /////////////////////////////////////////////////////////////////////////////

  public hasBlockedOutTimes(): boolean {
    return this.getBlockoutTimes().length === 2;
  }

  public isBlockedOut(): boolean {
    if (!this.hasBlockedOutTimes()) {
      return false;
    }

    const [start, end] = parseTimeRangeToMoment(this.getBlockoutTimes());
    const now = moment.tz(new Date(), GLOBAL_TIMEZONE);
    return timeRangeContains({start: start, end: end}, now);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Displayble Methods
  /////////////////////////////////////////////////////////////////////////////

  public getDisplayName() {
    return cloneDeep(this.type);
  }

  public getId() {
    return this.getDisplayName();
  }
}
