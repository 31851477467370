import { SetOptions } from '@angular/fire/compat/firestore';

/**
 * When uploading an object to firebase we can tell firebase to either merge fields or not.
 * Those options are specified in this dictionary
 *
 * @param mode
 */
export const getSetOptionsFromMode = (mode: 'create' | 'update'): SetOptions => {
  const mergeFlag: boolean | undefined = mode === 'update' || undefined;
  return { merge: mergeFlag };
};
