
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {VoiceResponseCommandName} from '../any-voice-response-command/voice-response-command-name';
import {VoiceResponseCommand} from '../generic/voice-response-command';
import {VoiceResponseCommandSchema} from '../generic/voice-response-command-schema';
import {AutoAcceptCallConstructor} from './auto-accept-call-constructor';
import {AutoAcceptCallSchema} from './auto-accept-call-schema';

export class AutoAcceptCall extends VoiceResponseCommand {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: AutoAcceptCallConstructor) {
    // This hack ensures that deserializing a VoiceCommand is equal to instanting it directly.
    // The difference is trivial, and only required for intuitive unit testing.
    (parameters as any)[VoiceResponseCommandSchema.commandName] = VoiceResponseCommandName.autoAcceptCall;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): AutoAcceptCall {
    return new AutoAcceptCall(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(AutoAcceptCall.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AutoAcceptCallSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
