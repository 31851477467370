import {values} from 'lodash';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {EventRequestName} from '../../../event-request-name';

export abstract class EventRequestSchema extends SerializableObjectSchema {
  // The name of the command
  public static readonly type = 'type';
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////
  static Defaults = class {
    public static readonly type = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[EventRequestSchema.type] = SchemaField.string(EventRequestSchema.Defaults.type).required().valid(...values(EventRequestName));
    /* eslint-enable */

    return parameters;
  }
}
