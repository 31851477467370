import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';

import { CorePipesModule } from '../../../pipes/core-pipes/core-pipes.module';

import { AuditEntryComponent } from './common/audit-entry/audit-entry.component';
import { SettingsComponent } from './settings/settings.component';

@NgModule({
  declarations: [SettingsComponent],
  imports: [AuditEntryComponent, ButtonModule, CardModule, CommonModule, CorePipesModule, ToastModule, TranslocoModule],
  exports: [SettingsComponent],
})
export class SettingsModule {}
