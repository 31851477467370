import {EventData} from '../event-data/event-data';
import {AllDataUser} from '../../user/all-data-user/all-data-user';
import {EventRequest} from '../event-request/event-request';
import {isNil, orderBy} from 'lodash';

/**
 * For a given event, collect the associated event requests, and user data.
 */
export class AllDataEvent {
  protected allDataUserMap: Map<string, AllDataUser>;
  protected eventData: EventData | undefined;
  protected requests: EventRequest[];

  constructor(eventData: EventData | undefined, allDataUserMap: Map<string, AllDataUser>, requests: Map<string, EventRequest>) {
    this.allDataUserMap = allDataUserMap;
    this.eventData = eventData;

    this.requests = Array.from(requests.values());
    this.requests = orderBy(this.requests, [z => z.getCreateTime()!.valueOf()], ['asc']);

    this.sanityCheck();
  }

  public getEventId(): string {
    return this.eventData?.getId() ?? this.requests[0].getEventId();
  }

  public getEventData(): EventData | undefined {
    return this.eventData;
  }

  public getAllDataUserMap(): Map<string, AllDataUser> {
    return this.allDataUserMap;
  }

  /**
   * Return requests sorted by increasing request time
   */
  public getRequests(): EventRequest[] {
    return this.requests;
  }

  public setEventData(eventData: EventData | undefined): AllDataEvent {
    const oldEventData = this.eventData;
    this.eventData = eventData;
    try {
      this.sanityCheck();
    } catch (error) {
      this.eventData = oldEventData;
      throw error;
    }
    return this;
  }

  public setRequests(requests: EventRequest[] = []): AllDataEvent {
    const oldRequests = this.requests;
    this.requests = requests;
    try {
      this.sanityCheck();
    } catch (error) {
      this.requests = oldRequests;
      throw error;
    }
    return this;
  }

  private sanityCheck() {
    if (isNil(this.eventData)) {
      return;
    }

    for (const request of this.requests.values()) {
      if (request.getEventId() !== this.eventData.getId()) {
        console.error('AllDataEvent.constructor: User error. Given requests do not belong to this event', {
          eventData: this.eventData,
          allDataUserMap: this.allDataUserMap,
          requests: this.requests,
        });
        throw new Error('AllDataEvent.constructor: User error. Given requests do not belong to this event');
      }
    }
  }
}
