<ng-container *transloco="let t; read: 'events-calendar'">
  <p-multiSelect
    *ngIf="multiSelect; else dropdown"
    appEventTypeSearch
    [defaultLabel]="t('eventTypeSelectLabel')"
    [formControl]="selectedEventTypes"
    [maxSelectedLabels]="2"
    [selectedItemsLabel]="'{0} ' + t('eventTypesSelected') | lowercase"
    (menuOptionSearchCompleted)="selectedEventTypes.setValue([$event.at(0)?.value])"
  >
  </p-multiSelect>

  <ng-template #dropdown>
    <p-dropdown
      appEventTypeSearch
      [formControl]="selectedEventTypes"
      [placeholder]="t('eventTypeSelectLabel')"
      (menuOptionSearchCompleted)="selectedEventTypes.setValue($event.at(0)?.value)"
    >
    </p-dropdown>
  </ng-template>

  <div [appLoading]="loading$ | async">
    <full-calendar #calendar class="calendar-container" [options]="options$ | async"> </full-calendar>
  </div>
</ng-container>
