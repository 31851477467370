<div *ngIf="!loading">
  <form [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Language -->
        <mat-form-field class="full-width">
          <mat-label>Language</mat-label>
          <mat-select formControlName="language" value="obj.languageDefaults.getId()">
            <mat-option *ngFor="let language of possibleLanguages" [value]="language.getShortCode()">{{
              language.getDisplayName()
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Phrases -->
        <label>
          Phrases
          <p-chips
            class="full-width"
            formControlName="phrases"
            [addOnBlur]="true"
            [addOnTab]="true"
            [allowDuplicate]="false"
            [placeholder]="'Phrases'"
          ></p-chips>
        </label>
      </div>
    </div>
  </form>
</div>
