import {SerializableObject} from '../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {EventStatsConstructor} from './event-stats-constructor';
import {EventStatsSchema} from './event-stats-schema';
import {cloneDeep} from 'lodash';
import {AssignedUserType} from '../../event/event-data/enums';
import {EventData} from '../../event/event-data/event-data';
import {AssignedUserTypeStats} from '../assigned-user-type-stats/assigned-user-type-stats';
import {AssignedUserStatTypes} from '../assigned-user-type-stats/assigned-user-stats-types';

/**
 * This class represents statistics about a collection of events of the same type.
 */
export class EventStats extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  public assignedUserTypeStats!: Map<AssignedUserStatTypes, AssignedUserTypeStats>;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EventStatsConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EventStats {
    return new EventStats(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EventStats.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new EventStatsSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getAssignedUserTypeStats(): Map<AssignedUserStatTypes, AssignedUserTypeStats> {
    return cloneDeep(this.assignedUserTypeStats);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Stats
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Update this object to include the given event
   * @param event eventData to update the object with
   */
  public updateWith(event: EventData, assignedUserType: AssignedUserType): EventStats {
    const userId = event.getUser(assignedUserType);
    if (EventData.isNilOrDefaultUser(userId)) {
      return this;
    }

    const interval = event.getIntervalMs();
    const eventId = event.getId();

    ///////////////
    // Default obj ensures map always has required keys.
    // Update
    ///////////////
    this.assignedUserTypeStats.get(AssignedUserStatTypes.any)!.updateWith(eventId, interval, userId!);
    this.assignedUserTypeStats.get(assignedUserType as any)!.updateWith(eventId, interval, userId!);

    return this;
  }
}
