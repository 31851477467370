import {SchemaField} from '../../../../generic/serialization/schema-field';
import {CommunicationTaskSchema} from '../../generic/communication-task/communication-task-schema';

export class CommunicationTaskConversationRequestSchema extends CommunicationTaskSchema {
  public static readonly conversationConfigId = 'conversationConfigId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly orgId = undefined;
    public static readonly sessionId = undefined;
    public static readonly taskQueueAttributes = new Map();

    public static readonly conversationConfigId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationTaskConversationRequestSchema.conversationConfigId] = SchemaField.autoGeneratedDocId(CommunicationTaskConversationRequestSchema.Defaults.conversationConfigId);
    /* eslint-enable */

    return parameters;
  }
}
