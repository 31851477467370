import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {ConversationRequestSchema} from '../../generic/conversation-request/conversation-request-schema';

export class ConversationRequestTakeSchema extends ConversationRequestSchema {
  public static readonly conversationLogId = 'conversationLogId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class extends ConversationRequestSchema.Defaults {
    public static readonly conversationLogId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[ConversationRequestTakeSchema.conversationLogId] = SchemaField.autoGeneratedDocId(ConversationRequestTakeSchema.Defaults.conversationLogId).required();

    return parameters;
  }
}
