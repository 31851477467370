import { BreakpointObserver } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FullCalendarComponent, FullCalendarModule } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg, DatesSetArg, EventApi, EventClickArg, EventDropArg, EventInput } from '@fullcalendar/core';
import esLocale from '@fullcalendar/core/locales/es';
import dayGridPlugin from '@fullcalendar/daygrid';
import adaptivePlugin from '@fullcalendar/adaptive';
import interactionPlugin, { EventResizeDoneArg } from '@fullcalendar/interaction'; // for dateClick
import listPlugin from '@fullcalendar/list';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import { loadingFor } from '@ngneat/loadoff';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LetModule } from '@ngrx/component';
import { isEqual, orderBy } from 'lodash';
import moment from 'moment-timezone';
import { DropdownModule } from 'primeng/dropdown';
import { MultiSelectModule } from 'primeng/multiselect';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  defer,
  distinctUntilChanged,
  firstValueFrom,
  map,
  Observable,
  of,
  share,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  tap,
} from 'rxjs';

import { AllDataEventDisplay, Interval, makeAllDataEventMap, OrgData } from '@pwp-common';

import { environment } from '../../../../environments/environment';
import { calendarEventToAllDataEventDisplay } from '../../../common/event/calendar-event/calendar-event-to-all-data-event-display';
import { makeCalendarEvent } from '../../../common/event/calendar-event/make-calendar-event/make-calendar-event';
import { controlValues } from '../../../common/form/control-values';
import { SharedDirectiveModule } from '../../../directives/shared-directive.module';
import { EventTypeSearchDirective } from '../../../modules/events/directives/event-type-search.directive';
import { EventRequestsService } from '../../../services/event/event-requests/event-requests.service';
import { EventsService } from '../../../services/event/events/events.service';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';
import { AllDataUserService } from '../../../services/user/all-data-user/all-data-user.service';

@UntilDestroy()
@Component({
  selector: 'app-events-calendar',
  standalone: true,
  imports: [
    CommonModule,
    DropdownModule,
    EventTypeSearchDirective,
    FormsModule,
    FullCalendarModule,
    LetModule,
    MultiSelectModule,
    ReactiveFormsModule,
    SharedDirectiveModule,
    TranslocoModule,
  ],
  templateUrl: './events-calendar.component.html',
  styleUrls: ['./events-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventsCalendarComponent implements OnInit {
  private orgData: OrgData;

  private allEventDataDisplayMap: Map<string, AllDataEventDisplay> = new Map();

  private readonly defaultCalendarOptions: CalendarOptions = {
    select: this.onTimeRangeSelected.bind(this),
    eventClick: this.onEventClicked.bind(this),
    datesSet: this.calendarViewChanged.bind(this),
    eventDrop: this.onEventDrop.bind(this),
    eventResize: this.onEventResize.bind(this),
    nowIndicator: true,
    events: [],
    locales: [esLocale],
    locale: 'en',
    eventDisplay: 'block',
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin, momentTimezonePlugin, resourceTimeGridPlugin, adaptivePlugin],
    contentHeight: 'auto',
    selectable: true, // Setting this globaly allows selecting datetime range in each view.
    eventOverlap: false,
    snapDuration: '00:15',
    /**
     * Customer service issue was raised that events without AM/PM may cause confusion. This formatting
     * aims to resolve that issue.
     */
    eventTimeFormat: {
      hour: 'numeric',
      minute: 'numeric',
      omitZeroMinute: true,
      hour12: true,
    },
    schedulerLicenseKey: environment.fullCalendar.licenseKey,
    /**
     * In month view, whether dates in the previous or next month should be rendered at all.
     * A customer service issuue was raised explaining that they were rendered but greyed out.
     * Setting showNonCurrentDates to false aims to remove that issue.
     **/
    showNonCurrentDates: false,
    ...this.getCalendarViewOptions(),
  };

  private readonly loader = loadingFor('allDataUserMap', 'openEventRequests');

  private readonly allDataUserMap$ = this.allDataUserService
    .getDocs(false)
    .pipe(this.loader.allDataUserMap.track(), shareReplay({ bufferSize: 1, refCount: true }));

  private readonly calendarViewUpdate$ = new Subject<Record<string, string>>();

  private readonly events$ = defer(() => combineLatest([this.calendarViewUpdate$, controlValues(this.selectedEventTypes)])).pipe(
    debounceTime(500),
    distinctUntilChanged((a, b) => isEqual(a, b)),
    switchMap(() => this.getEvents()),
    share(),
  );

  private readonly loadingEvents$ = new BehaviorSubject(false);

  private readonly openEventRequests$ = this.eventRequestsService
    .getOpenRequests()
    .pipe(this.loader.openEventRequests.track(), shareReplay({ bufferSize: 1, refCount: true }));

  @Input() public editable: boolean;

  @Input() public streamingData: boolean;

  @Input() public multiSelect = true;

  @ViewChild('calendar') public calendarComponent: FullCalendarComponent;

  public readonly selectedEventTypes = new FormControl<string | string[]>([]);

  public readonly loading$ = combineLatest([this.loadingEvents$, this.loader.allDataUserMap.inProgress$]).pipe(
    map(([loadingEvents, loadingAllDataUserMap]) => loadingEvents || loadingAllDataUserMap),
  );

  public readonly options$: Observable<CalendarOptions> = combineLatest({
    events: this.events$,
    locale: this.translocoService.langChanges$,
  }).pipe(
    startWith({
      events: this.defaultCalendarOptions.events,
      locale: this.defaultCalendarOptions.locale,
    }),
    map((dynamicOptions) => ({
      ...this.defaultCalendarOptions,
      ...dynamicOptions,
      resources: this.orgData
        ?.getEventTypes()
        .filter((eventType) => this.getSelectedEventTypes().includes(eventType.getInternalName()))
        .map((eventType) => ({
          id: eventType.getInternalName(),
          title: eventType.getDisplayName(),
        })),
    })),
  );

  @Output() public readonly eventClicked = new EventEmitter<AllDataEventDisplay>();

  @Output() public readonly eventResized = new EventEmitter<AllDataEventDisplay>();

  @Output() public readonly eventDropped = new EventEmitter<AllDataEventDisplay>();

  @Output() public readonly selected = new EventEmitter<{
    start: moment.Moment;
    end: moment.Moment;
    events: AllDataEventDisplay[];
    type: string;
  }>();

  ////////////////////////////////////////////////////////////////////////
  // Constructor
  ////////////////////////////////////////////////////////////////////////

  constructor(
    private allDataUserService: AllDataUserService,
    private breakpointObserver: BreakpointObserver,
    private eventRequestsService: EventRequestsService,
    private eventsService: EventsService,
    private orgDataService: OrgDataService,
    private translocoService: TranslocoService,
  ) {}

  ////////////////////////////////////////////////////////////////////////
  // Responsiveness
  ////////////////////////////////////////////////////////////////////////

  private isMobileResolution(): boolean {
    return this.breakpointObserver.isMatched('(max-width: 767px)');
  }

  private getCalendarViewOptions(): CalendarOptions {
    if (this.isMobileResolution()) {
      console.log('updateVarsForMobile: displaying simplified calendar for mobile.');

      return {
        headerToolbar: {
          left: 'prev,next',
          center: 'title',
          right: 'resourceTimeGridWeek,resourceTimeGridDay',
        },
        initialView: 'resourceTimeGridDay',
      };
    }

    console.log('updateVarsForMobile: displaying full calendar for desktop.');

    return {
      headerToolbar: {
        left: 'prev,next,today',
        center: 'title',
        right: 'dayGridMonth,resourceTimeGridWeek,resourceTimeGridDay,listWeek',
      },
      initialView: 'resourceTimeGridWeek',
    };
  }

  ////////////////////////////////////////////////////////////////////////
  // Event helpers
  ////////////////////////////////////////////////////////////////////////

  private getUpdatedEventInfo(event: EventApi): AllDataEventDisplay {
    const newStart = moment(event.start);
    const newEnd = moment(event.end);

    const oldAllDataEventDisplay: AllDataEventDisplay = calendarEventToAllDataEventDisplay(event);
    const { orgData } = oldAllDataEventDisplay;
    const { allDataEvent } = oldAllDataEventDisplay;

    const newEventData = oldAllDataEventDisplay.allDataEvent.getEventData().setStart(newStart).setEnd(newEnd);
    allDataEvent.setEventData(newEventData);

    const newAllDataEventDisplay = new AllDataEventDisplay(allDataEvent, orgData);
    return newAllDataEventDisplay;
  }

  ////////////////////////////////////////////////////////////////////////
  // Form
  ////////////////////////////////////////////////////////////////////////

  private getSelectedEventTypes(): string[] {
    const { value } = this.selectedEventTypes;

    return Array.isArray(value) ? value : [value];
  }

  ////////////////////////////////////////////////////////////////////////
  // Get Data
  ////////////////////////////////////////////////////////////////////////

  private getEvents(): Observable<EventInput[]> {
    if (this.getSelectedEventTypes().length === 0) {
      console.log('Event type not selected. Not getting data.');
      return of([]);
    }

    // calendarComponent === undefined until when ngOnInit is called.
    const calendarApi = this.calendarComponent.getApi();
    const viewStart = calendarApi.view.activeStart;
    const viewEnd = calendarApi.view.activeEnd;

    this.loadingEvents$.next(true);

    return combineLatest([
      this.eventsService.getEventsWithStartOrEndInRange(
        moment(viewStart),
        moment(viewEnd),
        !this.streamingData,
        ...this.getSelectedEventTypes(),
      ),
      this.allDataUserMap$,
      this.openEventRequests$,
    ]).pipe(
      // Step 1: Hydrate the event data
      map(([eventsArr, allDataUserMap, eventRequests]) => makeAllDataEventMap(eventsArr, allDataUserMap, eventRequests)),
      // Step 2: Convert to Calendar Events, and update calendarEvents object
      map((allEventDataMap) => {
        this.allEventDataDisplayMap = new Map();
        return Array.from(allEventDataMap.entries()).reduce<EventInput[]>((acc, [eventId, allDataEvent]) => {
          if (allDataEvent.getEventData() === undefined) {
            return acc;
          }

          this.allEventDataDisplayMap.set(eventId, new AllDataEventDisplay(allDataEvent, this.orgData));

          return [...acc, makeCalendarEvent(allDataEvent, this.orgData)];
        }, []);
      }),
      tap(() => this.loadingEvents$.next(false)),
    );
  }

  ////////////////////////////////////////////////////////////////////////
  // Calendar event callbacks
  ////////////////////////////////////////////////////////////////////////

  /**
   * This function is called by FullCalendar whenever the view changes
   * Eg, next/prev pressed, or the view is changed between
   * month/week/day/list. We simply get the data for the corresponding
   * view.
   *
   * @param _ Unused parameter. Full API preserved in case it is needed
   * in the future.
   */
  private calendarViewChanged({ startStr, endStr, view }: DatesSetArg): void {
    this.calendarViewUpdate$.next({
      startStr,
      endStr,
      view: view.type,
    });
  }

  private onEventClicked(clickObject: EventClickArg): void {
    const eventData = calendarEventToAllDataEventDisplay(clickObject.event);
    this.eventClicked.emit(eventData);
  }

  private onTimeRangeSelected(selectionInfo: DateSelectArg): void {
    const start = moment.tz(selectionInfo.startStr, this.orgData.getTimezone());
    const end = moment.tz(selectionInfo.endStr, this.orgData.getTimezone());

    const selectedInterval = new Interval(start, end);

    const selectedEvents = Array.from(this.allEventDataDisplayMap.values()).reduce<AllDataEventDisplay[]>((acc, allDataEventDisplay) => {
      const eventData = allDataEventDisplay.allDataEvent.getEventData();
      const eventInterval = eventData.getIntervalMs();
      const eventType = eventData.getType();

      if (eventType === selectionInfo.resource.id && eventInterval.overlaps(selectedInterval)) {
        return [...acc, allDataEventDisplay];
      }

      return acc;
    }, []);

    const events = orderBy(selectedEvents, (event) => event.allDataEvent.getEventData().getStart().valueOf(), ['asc']);

    this.selected.emit({
      start,
      end,
      events,
      type: selectionInfo.resource.id,
    });
  }

  private onEventDrop(eventDropInfo: EventDropArg) {
    this.eventDropped.emit(this.getUpdatedEventInfo(eventDropInfo.event));
  }

  private onEventResize(eventResizeInfo: EventResizeDoneArg) {
    this.eventResized.emit(this.getUpdatedEventInfo(eventResizeInfo.event));
  }

  ////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////

  public async ngOnInit() {
    this.orgData = await firstValueFrom(this.orgDataService.getOrgData());
    this.defaultCalendarOptions.timeZone = this.orgData.getTimezone();
    this.defaultCalendarOptions.editable = this.editable;
  }
}
