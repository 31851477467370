import { EventInput } from '@fullcalendar/core';

import { AllDataEventDisplay } from '@pwp-common';

//////////////////////////////////////////////////////////////////////////////////////////
// Convert To-From Calendar Events
//////////////////////////////////////////////////////////////////////////////////////////
/**
 * Extract AllDataEventDisplay from object representing event in a calendar.
 *
 * @param event Object representing event in calendar.
 */
export const calendarEventToAllDataEventDisplay = (event: EventInput): AllDataEventDisplay => event.extendedProps as AllDataEventDisplay;
