<ng-container *transloco="let t; read: 'form-autocomplete'">
  <p-autoComplete
    appendTo="body"
    dropdownAriaLabel="{{ t('dropdownLabel') }}"
    field="displayName"
    [dropdown]="true"
    [emptyMessage]="t('noOptionsFound')"
    [formControl]="control"
    [ngClass]="{ 'ng-invalid': control?.invalid }"
    [placeholder]="placeholder"
    [showEmptyMessage]="true"
    [suggestions]="suggestedOptions$ | async"
    (completeMethod)="search($event)"
  >
    <ng-template #itemDisplay let-value>
      <div class="form-autocomplete__item-name">
        <span>{{ value.getDisplayName() }}</span>
      </div>
    </ng-template>

    <ng-template pTemplate="item" let-value>
      <div class="form-autocomplete__item">
        <ng-container *ngTemplateOutlet="itemDisplay; context: { $implicit: value }"></ng-container>
        <span class="form-autocomplete__item-description">{{ value.getDescription() }}</span>
      </div>
    </ng-template>

    <ng-template pTemplate="selectedItem" let-value>
      <ng-container *ngTemplateOutlet="itemDisplay; context: { $implicit: value }"></ng-container>
    </ng-template>
  </p-autoComplete>

  <div *ngIf="control.errors as errors" class="p-error">
    <span *ngIf="errors.objIdNotInList">{{ t('controlErrorObjIdNotInList') }}</span>
  </div>
</ng-container>
