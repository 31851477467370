import {SchemaField} from '../../generic/serialization/schema-field';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';

export class CallStatsSchema extends SerializableObjectSchema {
  /**
   * These variables define statistics about calls dialed to a fixed group of one or more users.
   * Naming convention:
   *
   * i*: statistics about incoming calls
   * d*: statistics about calls dialed in response to an incoming call.
   *
   * Data Not Tracked: We intentionally do not determine the percentage of calls that
   * a particular user / group of users did not answer, as this may adversely affect
   * the culture of organizations viewing this information. Sufficient information of
   * this nature is already provided in call logs.
   */
  public static readonly iNum = 'iNum';
  public static readonly iNumServiceRequested = 'iNumServiceRequested';
  public static readonly iNumServiceNotRequested = 'iNumServiceNotRequested';
  public static readonly iNumAnswered = 'iNumAnswered';
  public static readonly iNumUnexpectedlyMissed = 'iNumUnexpectedlyMissed';
  public static readonly iNumEnforceBlockedCaller = 'iNumEnforceBlockedCaller';
  public static readonly iNumNoCapacity = 'iNumNoCapacity';
  public static readonly iMsOperatorDuration = 'iMsOperatorDuration';
  public static readonly iMsRingDuration = 'iMsRingDuration';
  public static readonly iMsHoldDuration = 'iMsHoldDuration';
  public static readonly iMsHoldDurationForAnsweredCalls = 'iMsHoldDurationForAnsweredCalls';
  public static readonly iMsHoldDurationForCallersThatHungUpEarly = 'iMsHoldDurationForCallersThatHungUpEarly';
  public static readonly iMsTalkDuration = 'iMsTalkDuration';
  public static readonly iNumUsersAnswered = 'iNumUsersAnswered';
  public static readonly iNumUserIdsToCallWasEmpty = 'iNumUserIdsToCallWasEmpty';
  public static readonly iNumBillableMinutes = 'iNumBillableMinutes';
  public static readonly iNumClientDisconnectBeforeServiceDeliveryAttemptComplete = 'iNumClientDisconnectBeforeServiceDeliveryAttemptComplete';
  public static readonly iNumClientConnectedToUser = 'iNumClientConnectedToUser';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly iNum = 0;
    public static readonly iNumServiceRequested = 0;
    public static readonly iNumServiceNotRequested = 0;
    public static readonly iNumAnswered = 0;
    public static readonly iNumUnexpectedlyMissed = 0;
    public static readonly iNumEnforceBlockedCaller = 0;
    public static readonly iNumNoCapacity = 0;
    public static readonly iMsOperatorDuration = 0;
    public static readonly iMsRingDuration = 0;
    public static readonly iMsHoldDuration = 0;
    public static readonly iMsHoldDurationForAnsweredCalls = 0;
    public static readonly iMsHoldDurationForCallersThatHungUpEarly = 0;
    public static readonly iMsTalkDuration = 0;
    public static readonly iNumUsersAnswered = 0;
    public static readonly iNumUserIdsToCallWasEmpty = 0;
    public static readonly iNumBillableMinutes = 0;
    public static readonly iNumClientDisconnectBeforeServiceDeliveryAttemptComplete = 0;
    public static readonly iNumClientConnectedToUser = 0;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    parameters[CallStatsSchema.iNum] = SchemaField.number(CallStatsSchema.Defaults.iNum);
    parameters[CallStatsSchema.iNumServiceRequested] = SchemaField.number(CallStatsSchema.Defaults.iNumServiceRequested);
    parameters[CallStatsSchema.iNumServiceNotRequested] = SchemaField.number(CallStatsSchema.Defaults.iNumServiceNotRequested);
    parameters[CallStatsSchema.iNumAnswered] = SchemaField.number(CallStatsSchema.Defaults.iNumAnswered);
    parameters[CallStatsSchema.iNumUnexpectedlyMissed] = SchemaField.number(CallStatsSchema.Defaults.iNumUnexpectedlyMissed);
    parameters[CallStatsSchema.iNumEnforceBlockedCaller] = SchemaField.number(CallStatsSchema.Defaults.iNumEnforceBlockedCaller);
    parameters[CallStatsSchema.iNumNoCapacity] = SchemaField.number(CallStatsSchema.Defaults.iNumNoCapacity);
    parameters[CallStatsSchema.iMsOperatorDuration] = SchemaField.number(CallStatsSchema.Defaults.iMsOperatorDuration);
    parameters[CallStatsSchema.iMsRingDuration] = SchemaField.number(CallStatsSchema.Defaults.iMsRingDuration);
    parameters[CallStatsSchema.iMsHoldDuration] = SchemaField.number(CallStatsSchema.Defaults.iMsHoldDuration);
    parameters[CallStatsSchema.iMsHoldDurationForAnsweredCalls] = SchemaField.number(CallStatsSchema.Defaults.iMsHoldDurationForAnsweredCalls);
    parameters[CallStatsSchema.iMsHoldDurationForCallersThatHungUpEarly] = SchemaField.number(CallStatsSchema.Defaults.iMsHoldDurationForCallersThatHungUpEarly);
    parameters[CallStatsSchema.iMsTalkDuration] = SchemaField.number(CallStatsSchema.Defaults.iMsTalkDuration);
    parameters[CallStatsSchema.iNumUsersAnswered] = SchemaField.number(CallStatsSchema.Defaults.iNumUsersAnswered);
    parameters[CallStatsSchema.iNumUserIdsToCallWasEmpty] = SchemaField.number(CallStatsSchema.Defaults.iNumUserIdsToCallWasEmpty);
    parameters[CallStatsSchema.iNumBillableMinutes] = SchemaField.number(CallStatsSchema.Defaults.iNumBillableMinutes);
    parameters[CallStatsSchema.iNumClientDisconnectBeforeServiceDeliveryAttemptComplete] = SchemaField.number(CallStatsSchema.Defaults.iNumClientDisconnectBeforeServiceDeliveryAttemptComplete);
    parameters[CallStatsSchema.iNumClientConnectedToUser] = SchemaField.number(CallStatsSchema.Defaults.iNumClientConnectedToUser);

    return parameters;
  }
}
