import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {CommunicationsResponse} from '../../generic/communications-response/communications-response';
import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {CommunicationsResponseDialPhoneConstructor} from './communications-response-dial-phone-constructor';
import {CommunicationsResponseDialPhoneSchema} from './communications-response-dial-phone-schema';
import {CommunicationsResponseDialPhoneError} from './communications-response-dial-phone-error';
import {CommunicationsRequestName} from '../../../communications-request-name';

export class CommunicationsResponseDialPhone extends CommunicationsResponse<CommunicationsResponseDialPhoneError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected outboundCallSessionId!: string;
  protected outboundCallSessionEventId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsResponseDialPhoneConstructor) {
    (parameters as any)[CommunicationsResponseSchema.type] = CommunicationsRequestName.dialPhone;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationsResponseDialPhone {
    return new CommunicationsResponseDialPhone(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsResponseDialPhone.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsResponseDialPhoneSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getOutboundCallSessionId(): string | undefined {
    return cloneDeep(this.outboundCallSessionId);
  }

  public getOutboundCallSessionEventId(): string | undefined {
    return cloneDeep(this.outboundCallSessionEventId);
  }
}
