import {cloneDeep} from 'lodash';
import {SSMLAudioConstructor} from './ssml-audio-constructor';
import {SSMLAudioSchema} from './ssml-audio-schema';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';

export class SSMLAudio extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  private ssml!: string;
  private audioURL!: string;
  private lastChangeTimeSSML!: moment.Moment | undefined;
  private lastChangeTimeAudio!: moment.Moment | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: SSMLAudioConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): SSMLAudio {
    return new SSMLAudio(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(SSMLAudio.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new SSMLAudioSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getSSML(): string {
    return cloneDeep(this.ssml);
  }

  public getAudioURL(): string {
    return cloneDeep(this.audioURL);
  }

  public getLastChangeTimeSSML(): moment.Moment | undefined {
    return cloneDeep(this.lastChangeTimeSSML);
  }

  public getLastChangeTimeAudio(): moment.Moment | undefined {
    return cloneDeep(this.lastChangeTimeAudio);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setSSML(ssml: string): SSMLAudio {
    this.ssml = ssml;
    return this;
  }

  public setAudioURL(audioURL: string): SSMLAudio {
    this.audioURL = audioURL;
    return this;
  }

  public setLastChangeTimeAudio(lastChangeTimeAudio: moment.Moment | undefined): SSMLAudio {
    this.lastChangeTimeAudio = lastChangeTimeAudio;
    return this;
  }

  public setLastChangeTimeSSML(lastChangeTimeSSML: moment.Moment | undefined): SSMLAudio {
    this.lastChangeTimeSSML = lastChangeTimeSSML;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
