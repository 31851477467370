import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { StepsModule } from 'primeng/steps';
import { ToastModule } from 'primeng/toast';

import { WizardStepComponent } from './wizard-step/wizard-step.component';
import { WizardStepsComponent } from './wizard-steps/wizard-steps.component';

@NgModule({
  declarations: [WizardStepComponent, WizardStepsComponent],
  imports: [ButtonModule, CardModule, CommonModule, StepsModule, ToastModule, TranslocoModule],
  exports: [WizardStepComponent, WizardStepsComponent],
})
export class WizardModule {}
