<ng-container *transloco="let t; read: 'event-requests-table-all'">
  <!-- Required to configure toast notifications -->
  <p-toast position="bottom-center"></p-toast>
  <app-data-table
    [actionsTemplate]="actionsTemplate"
    [cols]="cols"
    [expansionRow]="expansionRow"
    [loading]="loading || uploadInProgress"
    [selectedCols]="cols"
    [tableRows]="tableRows$"
    [title]="t('title')"
    [translationScope]="'event-requests-table-all'"
  >
    <ng-container appDataTableColumnDef="action">
      <th *appDataTableHeader>{{ t('colAction') }}</th>
      <td *appDataTableCell="let rowData" class="event-requests-table-all__action-cell">
        <p-dropdown
          placeholder=" "
          appendTo="body"
          [formControl]="decisionForm.controls[rowData.id]"
          [options]="rowData | eventRequestActionOptions"
          [styleClass]="'p-dropdown--empty-placeholder p-dropdown--full-width'"
        >
          <div *pTemplate="'item'; let item" class="event-requests-table-all__dropdown-item">
            <span>{{ item.label }}</span>
            <span class="event-requests-table-all__dropdown-item-description">{{ item.description }}</span>
          </div>
        </p-dropdown>
      </td>
    </ng-container>
    <ng-template #actionsTemplate>
      <div>
        <button
          pButton
          label="{{ t('uploadButton', { numDecisions: numDecisions$ | async }) }}"
          type="button"
          [disabled]="uploadInProgress || (numDecisions$ | async) <= 0"
          [loading]="uploadInProgress"
          (click)="upload()"
        ></button>
      </div>
    </ng-template>

    <ng-template #expansionRow let-index="index" let-rowData>
      <app-event-requests-table-one-event [allRequestsForOneEvent]="rowData"></app-event-requests-table-one-event>
    </ng-template>
  </app-data-table>
</ng-container>
