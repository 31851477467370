export enum EntityStatsDisplayWindow {
  thisMonth = 'thisMonth',
  thisQuarter = 'thisQuarter',
  thisYear = 'thisYear',
  lastMonth = 'lastMonth',
  lastQuarter = 'lastQuarter',
  lastYear = 'lastYear',
  past = 'past',
  custom = 'custom',
}
