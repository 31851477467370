import {
  ConversationParticipantSummaryChannel,
  ConversationParticipantSummaryChannelType,
} from './conversation-participant-channel';

export const normalizeConversationParticipantChannelName = (
  name: string
): ConversationParticipantSummaryChannelType => {
  const lowercaseName = name.toLowerCase();
  switch (lowercaseName) {
    case 'chat': {
      return ConversationParticipantSummaryChannel.chat;
    }
    case 'sms': {
      return ConversationParticipantSummaryChannel.sms;
    }
    case 'whatsapp': {
      return ConversationParticipantSummaryChannel.whatsApp;
    }
  }
  throw new Error(`normalizeConversationParticipantChannelName: User Error. Unsupported channel provided: ${name}`);
};
