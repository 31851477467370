import Joi from 'joi';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {EventRequestTransactionResponseSchema} from '../../responses/event-request-transaction-response/event-request-transaction-response-schema';

export class AnyEventRequestResponseSchema extends SerializableObjectSchema {
  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'eventRequestResponse';

  private static computedSchema = Joi.alternatives()
    .try(...[new EventRequestTransactionResponseSchema().getJoiSchema()])
    .id(AnyEventRequestResponseSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyEventRequestResponseSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    return AnyEventRequestResponseSchema.computedSchema;
  }
}
