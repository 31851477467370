import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {values} from 'lodash';
import {CommunicationsResponseCreateOutboundCallError} from './communications-response-create-outbound-call-error';

export class CommunicationsResponseCreateOutboundCallSessionSchema extends CommunicationsResponseSchema {
  public static readonly outboundCallSessionId = 'outboundCallSessionId';
  public static readonly outboundCallSessionEventId = 'outboundCallSessionEventId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly error = undefined;
    public static readonly outboundCallSessionId = undefined;
    public static readonly outboundCallSessionEventId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsResponseCreateOutboundCallSessionSchema.error] = SchemaField.string(CommunicationsResponseCreateOutboundCallSessionSchema.Defaults.error).optional().valid(...values(CommunicationsResponseCreateOutboundCallError));
    parameters[CommunicationsResponseCreateOutboundCallSessionSchema.outboundCallSessionId] = SchemaField.autoGeneratedDocId(CommunicationsResponseCreateOutboundCallSessionSchema.Defaults.outboundCallSessionId).required();
    parameters[CommunicationsResponseCreateOutboundCallSessionSchema.outboundCallSessionEventId] = SchemaField.autoGeneratedDocId(CommunicationsResponseCreateOutboundCallSessionSchema.Defaults.outboundCallSessionEventId).required();
    /* eslint-enable */

    return parameters;
  }
}
