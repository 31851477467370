import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isNil } from 'lodash';
import { forkJoin } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CalleeEntity, CallList, CallListSchema, EventType, IVR, UserData, VoiceResponseCommand } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { DatetimeValidator } from '../../../../common/validators/datetime-validator/datetime-validator';
import { CallListService } from '../../../../services/call/call-list/call-list.service';
import { IvrService } from '../../../../services/call/ivr/ivr.service';
import { UserDataService } from '../../../../services/user/user-data/user-data.service';
import { ConfigDocSelectAndEdit } from '../../../generic/abstract-classes/config-doc-select-and-edit';
@Component({
  selector: 'app-call-list-select-and-edit',
  templateUrl: './call-list-select-and-edit.component.html',
  styleUrls: ['./call-list-select-and-edit.component.css'],
})
export class CallListSelectAndEditComponent extends ConfigDocSelectAndEdit<CallList> implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  ivrsArray: IVR[];

  userDataArray: UserData[];

  selectedConfirmJoinIVRKVPair: KVPair<IVR> | undefined;

  selectedCalleeEntityKVPair: KVPair<CalleeEntity>;

  eventTypes: EventType[];

  calleeEntities: CalleeEntity[];

  onNoUserLeftToDialModIncCallCommands: VoiceResponseCommand[];

  callerHangupOnStarCommands: VoiceResponseCommand[];

  calleeHangupOnStarCommands: VoiceResponseCommand[];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private userDataService: UserDataService,
    private ivrService: IvrService,
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _dataService: CallListService,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(CallList, _dataService, _changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getData() {
    super.getData();
    this.loading = true;
    this.eventTypes = this.orgData.getEventTypes();

    forkJoin([this.ivrService.getDocsArray(), this.userDataService.getDocsArray()])
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.ivrsArray = value[0];
        this.userDataArray = value[1];
        this.loading = false;
        this.onSelectedKVPairChange(this.selectedKVPair);
      });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair?.value.getId(),
      [
        CallListSchema.displayName,
        CallListSchema.description,
        CallListSchema.capacity,
        CallListSchema.dialTimeoutSeconds,
        CallListSchema.pIIRetentionDuration,
      ],
      CallListSchema.Defaults,
      this,
    );

    parameters[CallListSchema.confirmJoinIVRId] =
      this.selectedConfirmJoinIVRKVPair?.value?.getId() || CallListSchema.Defaults.confirmJoinIVRId;

    parameters[CallListSchema.calleeEntities] = this.calleeEntities || CallListSchema.Defaults.calleeEntities;
    parameters[CallListSchema.onNoUserLeftToDialModIncCallCommands] =
      this.onNoUserLeftToDialModIncCallCommands || CallListSchema.Defaults.onNoUserLeftToDialModIncCallCommands;
    parameters[CallListSchema.callerHangupOnStarCommands] =
      this.callerHangupOnStarCommands || CallListSchema.Defaults.callerHangupOnStarCommands;
    parameters[CallListSchema.calleeHangupOnStarCommands] =
      this.calleeHangupOnStarCommands || CallListSchema.Defaults.calleeHangupOnStarCommands;

    const callList = new CallList(parameters);
    return callList;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: CallList) {
    // Init Form
    const formConfig = {} as any;
    formConfig[CallListSchema.displayName] = [obj.getDisplayName(), [Validators.required, Validators.maxLength(500)]];
    formConfig[CallListSchema.description] = [obj.getDescription(), [Validators.required, Validators.maxLength(500)]];
    formConfig[CallListSchema.capacity] = [obj.getCapacity(), [Validators.required]];
    formConfig[CallListSchema.dialTimeoutSeconds] = [obj.getDialTimeoutSeconds(), [Validators.min(5), Validators.max(600)]];
    formConfig[CallListSchema.pIIRetentionDuration] = [obj.getPIIRetentionDuration(), DatetimeValidator.isIsoDuration()];

    this.form = this.formBuilder.group(formConfig);

    // Init other state
    this.calleeEntities = obj.getCalleeEntities();
    this.onNoUserLeftToDialModIncCallCommands = obj.getOnNoUserLeftToDialModIncCallCommands();
    this.callerHangupOnStarCommands = obj.getCallerHangupOnStarCommands();
    this.calleeHangupOnStarCommands = obj.getCalleeHangupOnStarCommands();

    if (obj.getConfirmJoinIVRId() !== CallListSchema.Defaults.confirmJoinIVRId) {
      this.selectedConfirmJoinIVRKVPair = new KVPair({ id: obj.getConfirmJoinIVRId() });
    }
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName(): AbstractControl | null {
    return this.form.get(CallListSchema.displayName);
  }

  get description(): AbstractControl | null {
    return this.form.get(CallListSchema.description);
  }

  get capacity() {
    return this.form.get(CallListSchema.capacity);
  }

  get dialTimeoutSeconds() {
    return this.form.get(CallListSchema.dialTimeoutSeconds);
  }

  get pIIRetentionDuration() {
    return this.form.get(CallListSchema.pIIRetentionDuration);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Callee Entities Callbacks
  ///////////////////////////////////////////////////////////////////////////////////////////

  deleteCalleeEntityClick() {
    if (this.selectedCalleeEntityKVPair.id === undefined) {
      return;
    }
    this.calleeEntities.splice(this.selectedCalleeEntityKVPair.id as number, 1);
  }

  duplicateCalleeEntityClick() {
    if (this.selectedCalleeEntityKVPair.value === undefined) {
      return;
    }
    this.calleeEntities.push(this.selectedCalleeEntityKVPair.value);
    this.selectedCalleeEntityKVPair = new KVPair({ id: this.calleeEntities.length, value: this.selectedCalleeEntityKVPair.value });
  }

  newCalleeEntityClick() {
    const selectedKVPair = new KVPair({ id: this.calleeEntities?.length || 0, value: CalleeEntity.deserialize({}) });

    // Make new orderedItems
    this.calleeEntities.push(selectedKVPair?.value);
    this.selectedCalleeEntityKVPair = selectedKVPair;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.selectedConfirmJoinIVRKVPair?.value)) {
      return false;
    }

    return true;
  }
}
