import { isNil } from 'lodash';

import { getTimeRangeFromStr } from '../../time-range-str/get-time-range-from-str';

import { AvailableTimesByWeek, AvailableTimesType } from './available-times-by-week';

export const normalizeAvailableTimesByWeek = (timeRangeType: AvailableTimesType, timeRange?: string): AvailableTimesByWeek => {
  if (isNil(timeRangeType)) {
    return { type: AvailableTimesType.neverAvailable };
  }

  switch (timeRangeType) {
    case AvailableTimesType.alwaysAvailable: {
      return { type: AvailableTimesType.alwaysAvailable };
    }
    case AvailableTimesType.neverAvailable: {
      return { type: AvailableTimesType.neverAvailable };
    }
    case AvailableTimesType.sometimesAvailable: {
      const parsedRange = getTimeRangeFromStr(timeRange);
      if (isNil(parsedRange)) {
        return { type: AvailableTimesType.neverAvailable };
      }
      return { type: AvailableTimesType.sometimesAvailable, timeRange };
    }
  }
};
