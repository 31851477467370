import type {AnySchema} from 'joi';

import {SchemaField} from '../../generic/serialization/schema-field';
import {VoiceResponseCommandSchema} from '../generic/voice-response-command-schema';

export class ForwardSchema extends VoiceResponseCommandSchema {
  public static readonly to = 'to';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = undefined;
    public static readonly to = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition() as {[key: string]: AnySchema};

    parameters[ForwardSchema.to] = SchemaField.e164Phone(ForwardSchema.Defaults.to).required();

    return parameters;
  }
}
