export const allowedSurveyJSQuestionTypes = [
  'text',
  'comment',
  'multipletext',
  'dropdown',
  'tagbox',
  'checkbox',
  'radiogroup',
  'boolean',
  'matrix',
  'matrixdropdown',
  'matrixdynamic',
];
