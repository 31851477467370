import {cloneDeep} from 'lodash';

import {DBDocObject} from '../../generic/db-doc/db-doc-object';
import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {AssignedUserType} from '../event-data/enums';

import {EventRequestRole} from './enums';
import {EventRequestConfigConstructor} from './event-request-config-constructor';
import {EventRequestConfigSchema} from './event-request-config-schema';

export class EventRequestConfig extends DBDocObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected primary!: EventRequestRole[];
  protected backup!: EventRequestRole[];
  protected emailOnNewRequestCreated?: string[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EventRequestConfigConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EventRequestConfig {
    return new EventRequestConfig(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EventRequestConfig.getSchema());
  }
  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new EventRequestConfigSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getPrimary() {
    return cloneDeep(this.primary);
  }

  public getBackup() {
    return cloneDeep(this.backup);
  }

  public getEmailOnNewRequestCreated() {
    return cloneDeep(this.emailOnNewRequestCreated);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Has Role
  /////////////////////////////////////////////////////////////////////////////

  public hasRole(assignedUser: AssignedUserType, role: EventRequestRole): boolean {
    const allowedRoles = this.getAllowedRoles(assignedUser);

    switch (role) {
      case EventRequestRole.allowRequest:
        return allowedRoles.includes(EventRequestRole.allowRequest) || allowedRoles.includes(EventRequestRole.approveRequest);

      case EventRequestRole.approveRequest:
        return allowedRoles.includes(EventRequestRole.approveRequest);

      default:
        console.error('EventRequestConfig.hasRole: Invalid role', {assignedUser, role});
        return false;
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Private Helper
  /////////////////////////////////////////////////////////////////////////////
  private getAllowedRoles(assignedUser: AssignedUserType): EventRequestRole[] {
    switch (assignedUser) {
      case AssignedUserType.primary:
        return this.getPrimary();

      case AssignedUserType.backup:
        return this.getBackup();

      default:
        console.error('EventRequestConfig.hasRole: Invalid assignedUser', {assignedUser});
        return [];
    }
  }
}
