import { WebchatActionType } from '@pwp-common';

import { isInIframe } from '../../global/is-in-iframe/is-in-iframe';
import { sendWebchatAction } from '../send-webchat-action/send-webchat-action';

export const quickExit = (redirectUrl: string) => {
  if (isInIframe()) {
    sendWebchatAction({
      type: WebchatActionType.redirectFromChat,
      url: redirectUrl,
    });
  } else {
    window.open(redirectUrl, '_self');
  }
};
