<ng-container *transloco="let t; read: 'service-exception-editor-with-save'">
  <ng-template #objEditor>
    <div class="form-row">
      <div class="col">
        <app-service-exception-editor [formControl]="control"></app-service-exception-editor>
      </div>
    </div>
  </ng-template>

  <app-settings
    [auditEntry]="auditEntry"
    [doUpload]="doUpload"
    [editorTemplate]="objEditor"
    [isValid]="getIsValid"
    [refreshData]="refreshData"
    [resetForm]="resetForm"
  >
  </app-settings>
</ng-container>
