import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';

import { Forward, ForwardSchema } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { e164PhoneValidators } from '../../../../common/validators/misc-validators';
import { VRCEditor } from '../../../generic/abstract-classes/vrc-editor';

@Component({
  selector: 'app-vrc-editor-forward',
  templateUrl: './vrc-editor-forward.component.html',
  styleUrls: ['./vrc-editor-forward.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VrcEditorForwardComponent extends VRCEditor<Forward> implements OnInit {
  public to!: UntypedFormControl;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(changeDetectorRef);
  }

  public getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(undefined, [ForwardSchema.to], ForwardSchema.Defaults, this);
    const value = new Forward(parameters);

    return new KVPair({ id: this.obj.id, value });
  }

  public setFormFromObj(obj: KVPair<Forward>) {
    this.form = this.formBuilder.group({
      [ForwardSchema.to]: [obj.value?.getTo(), e164PhoneValidators],
    });

    this.to = this.form.controls.to as UntypedFormControl;
  }

  public isValidObj = super.isValidObj.bind(this);
}
