import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { ToastModule } from 'primeng/toast';
import { BehaviorSubject, firstValueFrom, map, startWith } from 'rxjs';

import { AllDataEvent, EventRequest, OrgData } from '@pwp-common';

import { makePTableCols } from '../../../common/p-table/make-p-table-cols';
import { PTableCol } from '../../../common/p-table/p-table-col';
import { EventRequestsService } from '../../../services/event/event-requests/event-requests.service';
import { AuthService } from '../../../services/user/auth/auth.service';
import { DataTableModule } from '../../generic/data-table/data-table.module';
import { AllRequestsForOneEventSlotSummary } from '../common/all-requests-for-one-event-slot-summary';
import { EventRequestsTableOneEventComponent } from '../event-requests-table-one-event/event-requests-table-one-event.component';

import { EventRequestActionOptionsPipe } from './event-request-action-options/event-request-action-options.pipe';
import { getEventRequestRows } from './helper/get-event-request-rows/get-event-request-rows';

@Component({
  selector: 'app-event-requests-table-all',
  standalone: true,
  imports: [
    ButtonModule,
    CommonModule,
    DataTableModule,
    DropdownModule,
    FormsModule,
    EventRequestActionOptionsPipe,
    EventRequestsTableOneEventComponent,
    ReactiveFormsModule,
    ToastModule,
    TranslocoModule,
  ],
  templateUrl: './event-requests-table-all.component.html',
  styleUrls: ['./event-requests-table-all.component.scss'],
  providers: [MessageService],
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0,
        }),
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        }),
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventRequestsTableAllComponent implements OnChanges {
  public readonly decisionForm = new FormGroup<Record<string, FormControl<string>>>({});

  public readonly tableRows$ = new BehaviorSubject<AllRequestsForOneEventSlotSummary[]>([]);

  public readonly numDecisions$ = this.decisionForm.valueChanges.pipe(
    startWith(this.decisionForm.value),
    map((decisions) => Object.values(decisions).filter((decision) => !isNil(decision)).length),
  );

  @Output() public readonly uploadComplete = new EventEmitter<void>();

  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////

  @Input()
  allDataEvent: AllDataEvent[];

  @Input()
  orgData: OrgData;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  loading = true;

  uploadInProgress = false;

  ///////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////

  public readonly cols: PTableCol[] = makePTableCols({
    columns: ['eventType', 'eventSummary', 'numRequests', 'requestedBySummary', 'requestedSlot', 'action'],
    translationScope: 'event-requests-table-all',
  });

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private messageService: MessageService,
    private translocoService: TranslocoService,
    private authService: AuthService,
    private eventRequestsService: EventRequestsService,
  ) {}

  private updateRows(): void {
    const rows = getEventRequestRows(this.allDataEvent, this.orgData);

    this.tableRows$.next(rows);

    Object.keys(this.decisionForm.controls).forEach((name) => {
      this.decisionForm.removeControl(name, { emitEvent: false });
    });

    rows.forEach((row, index) => this.decisionForm.addControl(row.id, new FormControl(), { emitEvent: index === rows.length - 1 }));
  }

  ngOnChanges() {
    if (!isNil(this.allDataEvent) && !isNil(this.orgData)) {
      this.updateUI();
    }
  }
  ///////////////////////////////////////////////////////////////////////
  // Update UI
  ///////////////////////////////////////////////////////////////////////

  updateUI() {
    this.loading = true;
    this.updateRows();
    this.loading = false;
  }

  ///////////////////////////////////////////////////////////////////////
  // Upload
  ///////////////////////////////////////////////////////////////////////

  private async getEventRequestsToUpload(): Promise<EventRequest[]> {
    const rows = this.tableRows$.value;
    const decisionTime = moment();
    const decisions = this.decisionForm.value;
    const loggedInUserId = await firstValueFrom(this.authService.getUserId());

    return rows.flatMap((row) => {
      const approvedRequestId = decisions[row.allDataEvent.getEventId()];

      if (!approvedRequestId) {
        return [];
      }

      return row.decisionRows.map((decisionRow) =>
        decisionRow.request.decide({
          isApproved: approvedRequestId === decisionRow.request.getId(),
          comment: decisionRow.comment,
          decidedByUserId: loggedInUserId,
          decisionTime,
        }),
      );
    }, []);
  }

  async upload() {
    console.log('EventRequestsDecideTableComponent.upload: Starting');
    this.uploadInProgress = true;

    const eventRequestsToUpload = await this.getEventRequestsToUpload();

    if (eventRequestsToUpload.length > 0) {
      await firstValueFrom(this.eventRequestsService.uploadArray(eventRequestsToUpload, 'update', false));
      this.uploadComplete.emit();
      this.notifyUploadSuccess(eventRequestsToUpload.length);
    }

    this.uploadInProgress = false;
    console.log('EventRequestsDecideTableComponent.upload: Completed');
  }

  ///////////////////////////////////////////////////////////////////////
  // Notify Upload Success
  ///////////////////////////////////////////////////////////////////////

  private notifyUploadSuccess(numRequests: number) {
    const title = this.translocoService.translate('event-requests-table-all.uploadSuccessTitle');
    const body = this.translocoService.translate('event-requests-table-all.uploadSuccessBody', { numRequests });

    this.messageService.add({
      severity: 'success',
      summary: title,
      detail: body,
    });
  }
}
