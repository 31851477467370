import { Displayable, LanguageDefaults } from '@pwp-common';

/**
 * This object encodes a pair of objects, a language and an array of
 * strings (phrases).
 */
export class TemplateTextLangPair implements Displayable {
  ////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////////

  constructor(
    public languageDefaults: LanguageDefaults,
    public templateText: string,
  ) {}

  ////////////////////////////////////////////////////////////////////////////////
  // Displayable Interface
  ////////////////////////////////////////////////////////////////////////////////

  public getId() {
    return this.languageDefaults.getId();
  }

  public getDisplayName() {
    return this.languageDefaults.getDisplayName();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Serialize
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Used to display the object for the debug view.
   */
  public serialize() {
    return { language: this.languageDefaults.getShortCode(), templateText: this.templateText };
  }
}
