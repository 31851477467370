import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';

import { CallList, VoicemailConfig, VoicemailConfigSchema } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../common/objects/form-helper';
import { KVPair } from '../../../common/objects/kvpair';
import { CallListService } from '../../../services/call/call-list/call-list.service';
import { VoicemailConfigService } from '../../../services/voicemail/voicemail-config/voicemail-config.service';
import { ConfigDocSelectAndEdit } from '../../generic/abstract-classes/config-doc-select-and-edit';

@Component({
  selector: 'app-voicemail-config-select-and-edit',
  templateUrl: './voicemail-config-select-and-edit.component.html',
  styleUrls: ['./voicemail-config-select-and-edit.component.css'],
})
export class VoicemailConfigSelectAndEditComponent extends ConfigDocSelectAndEdit<VoicemailConfig> implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  callListsArray: Observable<CallList[]>;

  selectedNotifyViaSMSOfVoicemailCallListKVPair: KVPair<CallList> | undefined;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private callListService: CallListService,
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _dataService: VoicemailConfigService,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(VoicemailConfig, _dataService, _changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Get Data
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getData() {
    super.getData();
    this.callListsArray = this.callListService.getDocsArray();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair.value.getId(),
      [VoicemailConfigSchema.displayName, VoicemailConfigSchema.description],
      VoicemailConfigSchema.Defaults,
      this,
    );

    parameters[VoicemailConfigSchema.notifyViaSMSOfVoicemailCallListId] =
      this.selectedNotifyViaSMSOfVoicemailCallListKVPair?.value?.getId() ||
      VoicemailConfigSchema.Defaults.notifyViaSMSOfVoicemailCallListId;

    const obj = new VoicemailConfig(parameters);
    return obj;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: VoicemailConfig) {
    // Init Form
    const formConfig = {} as any;
    formConfig[VoicemailConfigSchema.displayName] = [obj.getDisplayName(), [Validators.required, Validators.maxLength(500)]];
    formConfig[VoicemailConfigSchema.description] = [obj.getDescription(), [Validators.required, Validators.maxLength(500)]];

    if (obj?.getNotifyViaSMSOfVoicemailCallListId() !== VoicemailConfigSchema.Defaults.notifyViaSMSOfVoicemailCallListId) {
      this.selectedNotifyViaSMSOfVoicemailCallListKVPair = new KVPair({ id: obj?.getNotifyViaSMSOfVoicemailCallListId() });
    }

    this.form = this.formBuilder.group(formConfig);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName(): AbstractControl | null {
    return this.form.get(VoicemailConfigSchema.displayName);
  }

  get description(): AbstractControl | null {
    return this.form.get(VoicemailConfigSchema.description);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.selectedNotifyViaSMSOfVoicemailCallListKVPair?.value)) {
      return false;
    }

    return true;
  }
}
