import {CommunicationSessionEventLogDialReservationOfferConstructor} from './communication-session-event-log-dial-reservation-offer-constructor';
import {CommunicationSessionEventLogDialReservationOfferSchema} from './communication-session-event-log-dial-reservation-offer-schema';
import {CommunicationSessionEventLogDialPhone} from '../../generic/communication-session-event-log-dial-phone/communication-session-event-log-dial-phone';
import {CommunicationSessionEventLogDialPhoneCallType} from '../../generic/communication-session-event-log-dial-phone-call-type';
import {CommunicationSessionEventLogDialPhoneSchema} from '../../generic/communication-session-event-log-dial-phone/communication-session-event-log-dial-phone-schema';
import {Mixin} from 'ts-mixer';
import {CommunicationSessionEventLogReservationOffer} from '../../../../generic/communication-session-event-log-reservation-offer/communication-session-event-log-reservation-offer';

export class CommunicationSessionEventLogDialReservationOffer extends Mixin(CommunicationSessionEventLogDialPhone, CommunicationSessionEventLogReservationOffer) {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionEventLogDialReservationOfferConstructor) {
    (parameters as any)[CommunicationSessionEventLogDialPhoneSchema.callType] = CommunicationSessionEventLogDialPhoneCallType.reservationOffer;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationSessionEventLogDialReservationOffer {
    return new CommunicationSessionEventLogDialReservationOffer(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationSessionEventLogDialReservationOffer.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): CommunicationSessionEventLogDialReservationOfferSchema {
    return new CommunicationSessionEventLogDialReservationOfferSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
