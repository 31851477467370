import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';

export class EventRequestTransactionItemSchema extends SerializableObjectSchema {
  public static readonly type = 'type';
  public static readonly eventId = 'eventId';
  public static readonly requested = 'requested';
  public static readonly eventRequestId = 'eventRequestId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly eventId = undefined;
    public static readonly requested = undefined;
    public static readonly eventRequestId = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[EventRequestTransactionItemSchema.type] = SchemaField.string(EventRequestTransactionItemSchema.Defaults.type);
    parameters[EventRequestTransactionItemSchema.eventId] = SchemaField.autoGeneratedDocId(EventRequestTransactionItemSchema.Defaults.eventId);
    parameters[EventRequestTransactionItemSchema.requested] = SchemaField.string(EventRequestTransactionItemSchema.Defaults.requested).required();
    parameters[EventRequestTransactionItemSchema.eventRequestId] = SchemaField.autoGeneratedDocId(EventRequestTransactionItemSchema.Defaults.eventRequestId);
    /* eslint-enable */

    return parameters;
  }
}
