import Joi from 'joi';
import {SerializableObjectSchema} from '../../../../../../generic/serialization/serializable-object-schema';
import {CommunicationWorkflowTargetTimeoutFixedSchema} from '../../communication-workflow-target-timeouts/communication-workflow-target-timeout-fixed/communication-workflow-target-timeout-fixed-schema';

export class AnyCommunicationWorkflowTargetTimeoutSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyCommunicationWorkflowTargetTimeout';

  private static computedSchema = Joi.alternatives()
    .try(...[new CommunicationWorkflowTargetTimeoutFixedSchema().getJoiSchema()])
    .id(AnyCommunicationWorkflowTargetTimeoutSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyCommunicationWorkflowTargetTimeoutSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    return AnyCommunicationWorkflowTargetTimeoutSchema.computedSchema;
  }
}
