import { coerceNumericInput } from './coercion/coerce-numeric-input';
import { NumericInput, QueryComposer } from './interfaces';

export const equalTo = (value: unknown): QueryComposer => ({ opStr: '==', value });
export const notEqualTo = (value: unknown): QueryComposer => ({ opStr: '!=', value });

export const isOneOf = (value: unknown[]): QueryComposer => ({ opStr: 'in', value });
export const isNoneOf = (value: unknown[]): QueryComposer => ({ opStr: 'not-in', value });

export const containsAll = (value: unknown[]): QueryComposer => ({ opStr: 'array-contains', value });
export const containsAny = (value: unknown[]): QueryComposer => ({ opStr: 'array-contains-any', value });

export const greaterThan = (input: NumericInput): QueryComposer => ({ opStr: '>', value: coerceNumericInput(input) });
export const greaterThanOrEqualTo = (input: NumericInput): QueryComposer => ({ opStr: '>=', value: coerceNumericInput(input) });
export const lessThan = (input: NumericInput): QueryComposer => ({ opStr: '<', value: coerceNumericInput(input) });
export const lessThanOrEqualTo = (input: NumericInput): QueryComposer => ({ opStr: '<=', value: coerceNumericInput(input) });
