<ng-container *transloco="let t; read: 'array-of-phones-editor'">
  <div [formGroup]="form">
    <span class="p-float-label full-width">
      <p-chips
        ariaLabelledBy="arrayOfPhonesLabel"
        class="p-fluid"
        id="arrayOfPhones"
        separator=","
        [addOnBlur]="true"
        [addOnTab]="true"
        [formControl]="arrayOfPhones"
      >
        <ng-template pTemplate="item" let-item>
          <div *ngIf="parse(item)?.isValid() ?? false">
            <span class="fi fi-{{ parse(item).country?.toLowerCase() }}"></span>
            {{ parse(item).formatNational() }}
          </div>
          <div *ngIf="!(parse(item)?.isValid() ?? false)">
            {{ t('invalidPhone', { phoneStr: item }) }}
          </div>
        </ng-template>
      </p-chips>
      <label for="arrayOfPhones" id="arrayOfPhonesLabel">{{ label }}</label>
    </span>
  </div>
</ng-container>
