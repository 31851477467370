import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { PossibleRole } from '@pwp-common';

import { AdminRolesService } from '../../services/user/admin-roles/admin-roles.service';
import { RolesService } from '../../services/user/roles/roles.service';

@Directive({
  selector: '[appIfRole]',
})
export class IfRoleDirective implements OnInit, OnDestroy {
  @Input('appIfRole') ifRole: PossibleRole | 'pwpAdmin' | 'loggedIn';

  roles: Subscription;

  adminRoles: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private rolesService: RolesService,
    private adminRolesService: AdminRolesService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.viewContainer.clear();

    this.roles = this.rolesService.getRoles().subscribe((roles) => {
      switch (this.ifRole) {
        case 'loggedIn': {
          this.viewContainer.createEmbeddedView(this.templateRef);
          break;
        }
        default: {
          if (roles.hasRoles([this.ifRole as any])) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
          break;
        }
      }
      this.changeDetectorRef.detectChanges();
    });

    this.adminRoles = this.adminRolesService.getAdminRoles().subscribe((adminRoles) => {
      switch (this.ifRole) {
        case 'pwpAdmin': {
          if (adminRoles.isPwpAdmin()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          }
          break;
        }
        default: {
          // Nothing to do.
        }
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    this.roles.unsubscribe();
    this.adminRoles.unsubscribe();
  }
}
