import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { PTableCol } from '../../../../common/p-table/p-table-col';
import { RowCell } from '../../../../common/p-table/row-cell';

@Component({
  selector: 'app-row-cell',
  templateUrl: './row-cell.component.html',
  styleUrls: ['./row-cell.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RowCellComponent {
  @Input() rowData: RowCell<any>;

  @Input() col: PTableCol;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////
  constructor() {}
}
