import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import parsePhoneNumber from 'libphonenumber-js';

import { ArrayValidator } from '../../../../common/validators/array-validator/array-validator';
import { parsePhones } from '../../../../common/validators/parse-phones/parse-phones';
import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ArrayOfPhonesEditorComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ArrayOfPhonesEditorComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-array-of-phones-editor',
  templateUrl: './array-of-phones-editor.component.html',
  styleUrls: ['./array-of-phones-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class ArrayOfPhonesEditorComponent extends FormGroupControlValueAccessor<any, string[]> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() label: string;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    this.form = new UntypedFormGroup({
      arrayOfPhones: new UntypedFormControl([], [ArrayValidator.userEnteredPhoneNumber()]),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: string[]): void {
    this.arrayOfPhones.setValue(value ?? []);
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): string[] {
    const {arrayOfPhones} = value;
    const parsedArrayOfPhones = parsePhones(arrayOfPhones);
    return parsedArrayOfPhones.e164Phones;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get arrayOfPhones() {
    return this.form.get('arrayOfPhones') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse
  /////////////////////////////////////////////////////////////////////////////////////////////

  parse(phoneStr: string) {
    return parsePhoneNumber(phoneStr, 'US');
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'array-of-phones': this.form.value,
    };
  }
}
