import { AbstractControl, ValidatorFn } from '@angular/forms';

import { WorkerEditorOutputItem } from '../../editor-output/core/interface';

export class CommunicationWorkerValidator {
  public static noDeletedWorkers(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const selectedWorkers: WorkerEditorOutputItem[] = control.value ?? [];
      const deletedWorkers: WorkerEditorOutputItem[] = [];
      for (const selectedWorker of selectedWorkers) {
        switch (selectedWorker.type) {
          case 'deletedUser': {
            deletedWorkers.push(selectedWorker);
            break;
          }
          case 'deletedEvent': {
            deletedWorkers.push(selectedWorker);
            break;
          }
          default: {
          }
        }
      }
      const valid = deletedWorkers.length === 0;

      return !valid ? { noDeletedWorkers: { deletedWorkers } } : null;
    };
  }
}
