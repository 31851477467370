import {SchemaField} from '../../../generic/serialization/schema-field';
import {GenericDisplayableSchema} from '../../../generic/displayable/generic-displayable-schema';
import {ServiceLimitServiceUsage} from '../service-limit-service-usage/service-limit-service-usage';
import {AnyServiceLimitEnforcementStrategy} from '../service-limit-enforcement-strategy/generic/any-service-limit-enforcement-strategy/any-service-limit-enforcement-strategy';

export class ServiceLimitSchema extends GenericDisplayableSchema {
  public static readonly enforcementStrategy = 'enforcementStrategy';
  public static readonly usage = 'usage';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'serviceLimits';
  };

  static Defaults = class {
    public static readonly description = 'missing-description';
    public static readonly displayName = 'missing-displayName';
    public static readonly enforcementStrategy = undefined;
    public static readonly usage = ServiceLimitServiceUsage.deserialize({});
  };

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([ServiceLimitSchema.usage]);
  }
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ServiceLimitSchema.enforcementStrategy] = SchemaField.obj(AnyServiceLimitEnforcementStrategy, ServiceLimitSchema.Defaults.enforcementStrategy).required();
    parameters[ServiceLimitSchema.usage] = SchemaField.obj(ServiceLimitServiceUsage, ServiceLimitSchema.Defaults.usage);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${ServiceLimitSchema.Constants.collection}`;
  }
}
