import { isNil } from 'lodash';

import { AllDataEvent, AllDataEventDisplay, AssignedUserType, displayTime, displayTimeRangeShort, OrgData } from '@pwp-common';

import { RowCell } from '../../../common/p-table/row-cell';

import { RequestDecisionRow } from './request-decision-row';

export class AllRequestsForOneEventSlotSummary {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  id: string;

  allDataEventDisplay: AllDataEventDisplay;

  eventType: RowCell<string>;

  eventSummary: RowCell<Date>;

  status: RowCell<number>;

  numRequests: RowCell<number>;

  requestedBySummary: RowCell<string>;

  eventLastUploadTime: RowCell<Date>;

  decisionRows: RequestDecisionRow[] = [];

  public readonly requestedSlot = new RowCell<string>({
    translationScope: 'event-requests-table-all',
    translationKey: 'cellRequestedSlot',
    translationObj: { slot: this.assignedUserType },
    sortValue: this.assignedUserType,
  });

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public allDataEvent: AllDataEvent,
    public orgData: OrgData,
    public assignedUserType: AssignedUserType,
  ) {
    this.allDataEventDisplay = new AllDataEventDisplay(allDataEvent, orgData);
    this.id = allDataEvent.getEventId();
    this.setEventType(allDataEvent, orgData);
    this.setEventSummary(allDataEvent, orgData);
    this.setStatus(allDataEvent);
    this.setNumRequests(allDataEvent);
    this.setRequestedBySummary(allDataEvent);
    this.setEventLastUploadTime(allDataEvent, orgData);

    for (const request of allDataEvent.getRequests()) {
      const row = new RequestDecisionRow(request, allDataEvent, orgData);
      this.decisionRows.push(row);
    }
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Event Type
  //////////////////////////////////////////////////////////////////////////////////////////

  private setEventType(allDataEvent: AllDataEvent, orgData: OrgData) {
    const eventData = allDataEvent.getEventData();

    if (isNil(eventData)) {
      this.eventType = new RowCell<string>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellEventTypeDeletedEvent',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    const eventType = orgData.getEventTypeWithInternalName(eventData.getType()).getDisplayName();
    this.eventType = new RowCell<string>({
      translationScope: 'event-requests-table-all',
      translationKey: 'cellEventType',
      translationObj: {
        eventType,
      },
      sortValue: eventType,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Num Requests
  //////////////////////////////////////////////////////////////////////////////////////////

  private setNumRequests(allDataEvent: AllDataEvent) {
    const numRequests = allDataEvent.getRequests().length;

    this.numRequests = new RowCell<number>({
      translationScope: 'event-requests-table-all',
      translationKey: 'cellNumRequests',
      translationObj: {
        numRequests,
      },
      sortValue: numRequests,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Requested By
  //////////////////////////////////////////////////////////////////////////////////////////

  private setRequestedBySummary(allDataEvent: AllDataEvent) {
    let wasRequestedByDeletedUser = false;
    const users: Set<string> = new Set([]);
    for (const request of allDataEvent.getRequests()) {
      const allDataUser = allDataEvent.getAllDataUserMap().get(request.getRequestedAssignedUserId());
      if (allDataUser === undefined) {
        wasRequestedByDeletedUser = true;
      }
      users.add(allDataUser?.userData.getDisplayName() ?? '');
    }
    users.delete('');
    const usersString = Array.from(users).sort().join(',').trim();

    if (!wasRequestedByDeletedUser) {
      this.requestedBySummary = new RowCell<string>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellRequestedBySummary',
        translationObj: {
          users: usersString,
        },
        sortValue: usersString,
      });
      return;
    }

    this.requestedBySummary = new RowCell<string>({
      translationScope: 'event-requests-table-all',
      translationKey: 'cellRequestedBySummaryDeletedUser',
      translationObj: {
        users: usersString,
      },
      sortValue: usersString,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Summary
  //////////////////////////////////////////////////////////////////////////////////////////

  private setEventSummary(allDataEvent: AllDataEvent, orgData: OrgData) {
    const eventData = allDataEvent.getEventData();

    if (isNil(eventData)) {
      this.eventSummary = new RowCell<Date>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellEventSummaryDeletedEvent',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    const sortValue = eventData.getStart().toDate();

    this.eventSummary = new RowCell<Date>({
      translationScope: 'event-requests-table-all',
      translationKey: 'cellEventSummaryHasPendingRequest',
      translationObj: {
        eventName: orgData.getEventTypeWithInternalName(eventData.getType()).getDisplayName(),
        formattedEventTimes: displayTimeRangeShort(eventData.getStart(), eventData.getEnd(), orgData.getTimezone()),
      },
      sortValue,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Status
  //////////////////////////////////////////////////////////////////////////////////////////

  private setStatus(allDataEvent: AllDataEvent) {
    const requests = allDataEvent.getRequests();
    let numOpenRequests = 0;
    for (const request of requests) {
      if (request.getIsOpen()) {
        numOpenRequests += 1;
      }
    }

    if (numOpenRequests > 0) {
      this.status = new RowCell<number>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellStatusHasOpenRequests',
        translationObj: { numOpenRequests },
        sortValue: 1,
        styleClass: 'statusHasOpenRequests',
      });
      return;
    }

    this.status = new RowCell<number>({
      translationScope: 'event-requests-table-all',
      translationKey: 'cellStatusDecided',
      translationObj: {},
      sortValue: 0,
      styleClass: 'statusDecided',
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Event Last Update Time
  //////////////////////////////////////////////////////////////////////////////////////////

  private setEventLastUploadTime(allDataEvent: AllDataEvent, orgData: OrgData) {
    const eventData = allDataEvent.getEventData();

    if (isNil(eventData)) {
      this.eventLastUploadTime = new RowCell<Date>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellEventLastUploadTimeDeletedEvent',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    const lastUploadTime = eventData.getLastUploadTime();
    const createTime = eventData.getCreateTime();

    if (isNil(lastUploadTime) || isNil(createTime) || createTime.isSame(lastUploadTime)) {
      this.eventLastUploadTime = new RowCell<Date>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellEventLastUploadTimeNeverUpdated',
        translationObj: {},
        sortValue: createTime?.toDate(),
      });
      return;
    }

    this.eventLastUploadTime = new RowCell<Date>({
      translationScope: 'event-requests-table-all',
      translationKey: 'cellEventLastUploadUpdatedAt',
      translationObj: {
        updateTime: displayTime(lastUploadTime, orgData.getTimezone()),
      },
      sortValue: createTime.toDate(),
    });
  }
}
