import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {CommunicationsResponse} from '../../generic/communications-response/communications-response';
import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {CommunicationsResponseDialAsyncServiceRequestConstructor} from './communications-response-dial-async-service-request-constructor';
import {CommunicationsResponseDialAsyncServiceRequestSchema} from './communications-response-dial-async-service-request-schema';
import {CommunicationsResponseDialAsyncServiceRequestError} from './communications-response-dial-async-service-request-error';
import {CommunicationsRequestName} from '../../../communications-request-name';

export class CommunicationsResponseDialAsyncServiceRequest extends CommunicationsResponse<CommunicationsResponseDialAsyncServiceRequestError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsResponseDialAsyncServiceRequestConstructor) {
    (parameters as any)[CommunicationsResponseSchema.type] = CommunicationsRequestName.dialAsyncServiceRequest;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationsResponseDialAsyncServiceRequest {
    return new CommunicationsResponseDialAsyncServiceRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsResponseDialAsyncServiceRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsResponseDialAsyncServiceRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
