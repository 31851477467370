<!-- Create Async Service Request Command -->
<form *ngIf="!loading" [formGroup]="form">
  <!-- Call List Select -->
  <app-obj-select
    [label]="'Create Async Service Request Widget'"
    [orderedItems]="communicationWidgetAsyncServiceRequestArray"
    [(kvPair)]="widgetKvPair"
    (kvPairChange)="updateObjAndEmit()"
  ></app-obj-select>
</form>

<!-- Is Valid Indicator -->
<app-obj-is-valid *ngIf="!loading" [isValidObj]="isValidObjScoper"></app-obj-is-valid>
