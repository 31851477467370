import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { translate } from '@ngneat/transloco';
import { MessageService } from 'primeng/api';
import { Message } from 'primeng/api/message';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { AsyncServiceRequestService } from '../../../../services/async-service-request/async-service-request.service';
import { CommunicationsService } from '../../../../services/call/communications/communications.service';

import { CreateCallbackRequestInput } from './interfaces';

@Component({
  selector: 'app-create-callback-request',
  templateUrl: './create-callback-request.component.html',
  styleUrls: ['./create-callback-request.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class CreateCallbackRequestComponent {
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  createCallbackAPIPending = false;

  asyncServiceRequestId: string;

  form = new UntypedFormGroup({
    e164Phone: new UntypedFormControl(undefined, [Validators.required]),
    communicationWidgetId: new UntypedFormControl(undefined, [Validators.required]),
    note: new UntypedFormControl(undefined),
  });

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private communicationsService: CommunicationsService,
    private asyncServiceRequestService: AsyncServiceRequestService,
    private messageService: MessageService,
  ) {}

  ///////////////////////////////////////////////////////////////////////
  // Cancel
  ///////////////////////////////////////////////////////////////////////

  cancelClick() {
    this.ref.close();
  }

  async confirmClick() {
    this.createCallbackAPIPending = true;
    this.asyncServiceRequestId = this.asyncServiceRequestService.createId();
    try {
      const data = this.config.data as CreateCallbackRequestInput;

      await this.communicationsService.createAsyncServiceRequest({
        sessionId: data.sessionId,
        e164Phone: this.e164Phone.value,
        createAsyncServiceRequestWidgetId: this.communicationWidgetId.value,
        asyncServiceRequestId: this.asyncServiceRequestId,
        note: this.note.value || undefined,
      });

      this.ref.close({ message: this.getSuccessMessage() });
    } catch (error) {
      this.showAPIError();
    } finally {
      this.createCallbackAPIPending = false;
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get communicationWidgetId() {
    return this.form.get('communicationWidgetId') as UntypedFormControl;
  }

  get e164Phone() {
    return this.form.get('e164Phone') as UntypedFormControl;
  }

  get note() {
    return this.form.get('note') as UntypedFormControl;
  }

  ///////////////////////////////////////////////////////////////////////
  // Message
  ///////////////////////////////////////////////////////////////////////

  private getSuccessMessage(): Message {
    const summary = translate(`create-callback-request-dialog.successTitle`);
    const detail = translate(`create-callback-request-dialog.successBody`);

    return {
      summary,
      detail,
      severity: 'success',
    };
  }

  private showAPIError() {
    const summary = translate(`pwp-api.errorTimeoutTitle`);
    const detail = translate(`pwp-api.errorTimeoutBody`);

    this.messageService.add({
      severity: 'warn',
      summary,
      detail,
      sticky: true,
    });
  }
}
