import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Router } from '@angular/router';
// Do not import from 'firebase' as you'd lose the tree shaking benefits
import firebase from 'firebase/compat/app';
import { EMPTY, from, Observable, Subject } from 'rxjs';
import { catchError, delayWhen, filter, map, publishReplay, refCount, switchMap, take, tap } from 'rxjs/operators';

import { RolesSchema } from '@pwp-common';

import { observableTakeOne } from '../../generic/take-one';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  //////////////////////////////////////////////////////
  // Config
  //////////////////////////////////////////////////////

  // If userId not available after this time, assume user not logged in.
  static readonly timeoutMilliseconds = 10000;

  private ngUnsubscribe = new Subject<void>();

  constructor(
    private router: Router,
    private angularFireAuthService: AngularFireAuth,
  ) {
    this.setAuthPersistence();
    // this.maintainUserLoggedIn();
  }

  public currentUser(): Observable<firebase.User> {
    const observable = this.angularFireAuthService.user.pipe(
      filter((user) => user != null),
      publishReplay(1),
      refCount(),
    );

    return observableTakeOne(observable, true);
  }

  public getOrgId(): Observable<string> {
    const observable = this.currentUser().pipe(
      switchMap((user, _) => from(user.getIdTokenResult(false))),
      map((idTokenResult, _) => {
        const orgId = idTokenResult.claims[RolesSchema.CustomClaims.orgId] as string | undefined;
        return orgId;
      }),
    );

    return observableTakeOne(observable, true);
  }

  public getUserId(takeOne = true): Observable<string> {
    const observable = this.angularFireAuthService.user.pipe(
      filter((user) => user != null),
      map((user: firebase.User, _: any): string => user.uid),
    );

    return observableTakeOne(observable, takeOne);
  }

  public isLoggedIn(takeOne = true): Observable<boolean> {
    const observable = this.angularFireAuthService.user.pipe(
      map((user: firebase.User): boolean => {
        if (user === null || user.uid === undefined) {
          return false;
        }
        return true;
      }),
    );

    return observableTakeOne(observable, takeOne);
  }

  ////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////

  ngOnDestroy() {
    console.log('AuthService: OnDestroy');
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ////////////////////////////////////////////////////////////////
  // Firebase Auth Persistence
  ////////////////////////////////////////////////////////////////

  private setAuthPersistence(): void {
    from(this.angularFireAuthService.setPersistence('session'))
      .pipe(
        take(1),
        map(() => {
          console.log(`setAuthPersistence: Success`);
        }),
        catchError((error, _) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.error(`setAuthPersistence: Error. errorCode=${errorCode}, errorMessage=${errorMessage}`);
          console.error(error);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  ////////////////////////////////////////////////////////////////
  // Maintain Logged In Status
  ////////////////////////////////////////////////////////////////

  public maintainUserLoggedIn() {
    console.log('Starting Service: maintainUserLoggedIn');
    this.angularFireAuthService.user
      .pipe(
        filter((user: firebase.User, _: any) => user === null),
        switchMap((result: null, _: any) => this.notLoggedInAction()),
      )
      .subscribe(
        (result) => {
          console.log('maintainUserLoggedIn: Successful.');
          console.log(result);
        },
        (err) => {
          console.error('maintainUserLoggedIn: Error');
          console.error(err);
          return this.notLoggedInAction();
        },
        () => {
          console.log('maintainUserLoggedIn: Complete');
        },
      );
  }

  private notLoggedInAction(): Observable<boolean> {
    console.log('notLoggedInAction: No user is authenticated! Requesting login.');
    const observable = from(this.router.navigate(['/login'])).pipe(
      tap((next) => {
        console.log(`notLoggedInAction: ${next}`);
      }),
      delayWhen((_) => from(this.angularFireAuthService.signOut())),
    );

    return observable;
  }
}
