<ng-container *transloco="let t; read: 'org-settings-enforce-service-limit'">
  <ng-template #objEditor>
    <app-progress-spinner *ngIf="loading.cwESL.inProgress$ | async"></app-progress-spinner>
    <div *ngIf="(loading.cwESL.inProgress$ | async) === false" class="formgrid grid p-fluid">
      <!-- Select Audio -->
      <div class="field col-12">
        <p>{{ t('serviceLimitExceededAudioIdLabel') }}</p>
        <app-vrc-audio-select
          id="serviceLimitExceededAudioId"
          [formControl]="serviceLimitExceededAudioId"
          [placeholder]="t('audioSelectPlaceholder')"
          [required]="true"
        ></app-vrc-audio-select>
        <p *ngIf="serviceLimitExceededAudioId.hasError('required')" class="p-error">{{ t('audioIdErrorRequired') }}</p>
        <p id="serviceLimitExceededAudioId-help">{{ t('serviceLimitExceededAudioIdHelp') }}</p>
      </div>
    </div>
  </ng-template>

  <app-settings
    [auditEntry]="auditEntry"
    [doUpload]="doUpload"
    [editorTemplate]="objEditor"
    [refreshData]="refreshData"
    [resetForm]="resetForm"
  >
  </app-settings>
</ng-container>
