import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import moment from 'moment-timezone';
import { map } from 'rxjs/operators';

import { PTableCol } from '../../../../common/p-table/p-table-col';
import { DateRangeSelectOutput } from '../../../core/date-range-select/common/date-range-select-output';
import { SettingsEditorTableBase } from '../../../generic/settings/abstract-classes/settings-editor-table-base';
import { InboxRow } from '../../row/inbox-row/inbox-row';
import { InboxService } from '../../services/inbox-service/inbox.service';





@UntilDestroy()
@Component({
  selector: 'app-inbox-table',
  templateUrl: './inbox-table.component.html',
  styleUrls: ['./inbox-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InboxTableComponent extends SettingsEditorTableBase<InboxRow> implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  dateRange: DateRangeSelectOutput = {
    start: moment().subtract(48, 'hours').toDate(),
    end: moment().toDate(),
  };

  ///////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////
  cols: PTableCol[] = [
    { field: 'docId', header: 'colDocId' },
    { field: 'type', header: 'colType' },
    { field: 'day', header: 'colDay' },
    { field: 'time', header: 'colTime' },
    { field: 'handledBy', header: 'colHandledBy' },
    { field: 'status', header: 'colStatus' },
    { field: 'language', header: 'colLanguage' },
    { field: 'callType', header: 'colCallType' },
  ];

  selectedCols = [
    { field: 'day', header: 'colDay' },
    { field: 'time', header: 'colTime' },
    { field: 'type', header: 'colType' },
    { field: 'handledBy', header: 'colHandledBy' },
    { field: 'status', header: 'colStatus' },
    { field: 'language', header: 'colLanguage' },
    { field: 'callType', header: 'colCallType' },
  ];

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////
  constructor(
    private inboxService: InboxService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    super();
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  public getData() {
    if (isNil(this.dateRange)) {
      return;
    }

    this.loading = true;

    this.tableRows = this.inboxService.getInboxRows({ start: moment(this.dateRange.start), end: moment(this.dateRange.end) }).pipe(
      map((tableRows) => {
        this.loading = false;
        this.changeDetectorRef.detectChanges();
        return tableRows;
      }),
      untilDestroyed(this),
    );
    this.changeDetectorRef.detectChanges();
  }
}
