<app-obj-select-and-edit
  [doDelete]="doDelete"
  [editorTemplate]="optionEditor"
  [label]="'Template Text'"
  [modifiedSelectedItem]="selectedKVPair?.value"
  [(orderedItems)]="objList"
  [(selectedKVPair)]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
>
  <ng-template #optionEditor let-index="id" let-item="item" let-kvPair>
    <p>Editing Option at Index: {{ this.selectedKVPair?.id }}</p>

    <app-template-text-lang-pair-editor
      *ngIf="selectedKVPair?.value !== undefined"
      [(obj)]="selectedKVPair"
      (objChange)="updateAndEmit()"
    ></app-template-text-lang-pair-editor>
  </ng-template>
</app-obj-select-and-edit>
