import {SerializableObjectSchema} from '../../../../../generic/serialization/serializable-object-schema';
import {CommunicationTaskQueueWorkerListName} from '../../generic/communication-task-queue-worker-list-name';
import {CommunicationTaskQueueWorkerList} from '../../generic/communication-task-queue-worker-list/communication-task-queue-worker-list';
import {CommunicationTaskQueueWorkerListSchema} from '../../generic/communication-task-queue-worker-list/communication-task-queue-worker-list-schema';
import {CommunicationTaskQueueWorkerListUserConstructor} from './communication-task-queue-worker-list-user-constructor';
import {CommunicationTaskQueueWorkerListUserSchema} from './communication-task-queue-worker-list-user-schema';
import {cloneDeep} from 'lodash';

export class CommunicationTaskQueueWorkerListUser extends CommunicationTaskQueueWorkerList {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected userIds!: string[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationTaskQueueWorkerListUserConstructor) {
    (parameters as any)[CommunicationTaskQueueWorkerListSchema.type] = CommunicationTaskQueueWorkerListName.userList;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationTaskQueueWorkerListUser {
    return new CommunicationTaskQueueWorkerListUser(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationTaskQueueWorkerListUser.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationTaskQueueWorkerListUserSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getUserIds(): string[] {
    return cloneDeep(this.userIds);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Num Worker Slots
  /////////////////////////////////////////////////////////////////////////////

  public getNumWorkerSlots(): number {
    return this.userIds?.length ?? 0;
  }
}
