<ng-container *transloco="let t; read: 'conversation-logs-query-editor'">
  <p-toast position="bottom-center"></p-toast>

  <p-card>
    <ng-template pTemplate="header">
      <legend>{{ t('title') }}</legend>
    </ng-template>
    <form [formGroup]="form">
      <div class="form-row">
        <div class="col">
          <label
            >{{ t('showWaitingLabel') }}
            <p-inputSwitch ariaLabel="{{ t('showWaitingLabel') }}" formControlName="showWaiting"></p-inputSwitch>
          </label>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <label
            >{{ t('inProgressLabel') }}
            <p-selectButton
              ariaLabel="{{ t('inProgressLabel') }}"
              formControlName="inProgress"
              [options]="[
                { label: t('myInProgressLabel'), value: 'my' },
                { label: t('allInProgressLabel'), value: 'all' }
              ]"
            ></p-selectButton>
          </label>
        </div>
      </div>
      <div class="form-row">
        <div class="col">
          <label
            >{{ t('showEndedLabel') }}
            <p-inputSwitch ariaLabel="{{ t('showEndedLabel') }}" formControlName="showEnded"></p-inputSwitch>
          </label>
        </div>
        <div *ngIf="form.value.showEnded === true" class="col">
          <ng-container *ngrxLet="form.controls.range as range">
            <mat-form-field appearance="fill">
              <mat-label>{{ t('dateRangeLabel') }}</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate placeholder="{{ t('start') }}" formControlName="start" />
                <input matEndDate placeholder="{{ t('end') }}" formControlName="end" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">{{ t('invalidStartDate') }}</mat-error>
              <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">{{ t('invalidEndDate') }}</mat-error>
            </mat-form-field>
          </ng-container>
        </div>
      </div>
    </form>
    <ng-template pTemplate="footer">
      <button
        *ngrxLet="queryFailed$ as queryFailed"
        pButton
        class="p-button-text"
        [disabled]="!queryFailed || form.invalid"
        [label]="t('queryButton', { queryFailed })"
        [loading]="!queryFailed"
        (click)="retryQuery()"
      ></button>
    </ng-template>
  </p-card>

  <app-conversation-select-and-edit *ngIf="client$ | async as client" [client]="client"> </app-conversation-select-and-edit>
  <mat-spinner *ngIf="loading$ | async"></mat-spinner>
</ng-container>
