import {SerializableObject} from './serializable-object';
import moment from 'moment-timezone';
import {SerializationTarget} from './serialization-target';
import {momentToTimestamp} from './timestamp';

/**
 * Serialize a value
 * @param obj any object that is eventually written to our database
 */
export const serializeValue = (obj: any, target: SerializationTarget): any => {
  if (obj instanceof SerializableObject) {
    return (obj as any).serialize();
  }

  if (Array.isArray(obj)) {
    return obj.map(v => serializeValue(v, target));
  }

  if (typeof obj !== 'object') {
    return obj;
  }

  if (obj instanceof Date) {
    switch (target) {
      case 'api': {
        return momentToTimestamp(moment(obj));
      }
      case 'firebase': {
        return obj;
      }
    }
  }

  if (moment.isMoment(obj)) {
    switch (target) {
      case 'api': {
        return momentToTimestamp(moment(obj));
      }
      case 'firebase': {
        return obj.toDate();
      }
    }
  }

  if (obj instanceof Map) {
    const serializedMap = {} as any;
    for (const key of obj.keys()) {
      serializedMap[key] = serializeValue(obj.get(key), target);
    }
    return serializedMap;
  }

  if (obj === null || obj === undefined) {
    console.error('Serialization of null/undefined is not currently allowed', {obj: obj});
  }

  throw new Error('SerializableObject.serializeValue: Cannot serialize the given value');
};
