<ng-container *transloco="let t; read: 'requested-slot-select'">
  <p-radioButton
    [ariaLabel]="t('primary')"
    [disabled]="!requestableSlots.primary"
    [formControl]="control"
    [label]="t('primary')"
    [name]="rowData.id"
    [value]="assignedUserType.primary"
  ></p-radioButton>
  <p-radioButton
    [ariaLabel]="t('backup')"
    [disabled]="!requestableSlots.backup"
    [formControl]="control"
    [label]="t('backup')"
    [name]="rowData.id"
    [value]="assignedUserType.backup"
  ></p-radioButton>
</ng-container>
