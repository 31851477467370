import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conversations-feature-not-enabled',
  templateUrl: './conversations-feature-not-enabled.component.html',
  styleUrls: ['./conversations-feature-not-enabled.component.css'],
})
export class ConversationsFeatureNotEnabledComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
