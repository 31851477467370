import {RRule} from 'rrule';

//////////////////////////////////////////////////////////////////////////////////////////
// Sanity
//////////////////////////////////////////////////////////////////////////////////////////

export const rruleIsValid = (rruleStr: string, throwOnError = true): boolean => {
  const rrule = validateAndGetRRuleFromStr(rruleStr, throwOnError);
  if (rrule === undefined) {
    return false;
  }
  return true;
};

/**
 * This method is basically RRule.fromString() but with validation.
 *
 * We need this method because the RRule library does not throw an error
 * when it is unable to parse an RRule from a string, and instead returns
 * a significantly incorrect RRule object.
 *
 * @param rruleStr
 * @param throwOnError
 * @returns
 */
export const validateAndGetRRuleFromStr = (rruleStr: string, throwOnError = true): RRule | undefined => {
  try {
    const rrule = RRule.fromString(rruleStr);
    const parsedRRuleStr = rrule.toString();
    if (![rruleStr, `RRULE:${rruleStr}`].includes(parsedRRuleStr)) {
      console.error(
        'validateAndGetRRuleFromStr: Error: Parsing the RRule may have failed, it may not be a valid RFC string',
        {originalRRuleStr: rruleStr, parsedRRuleStr}
      );
      throw new Error(
        'validateAndGetRRuleFromStr: Error: Parsing the RRule may have failed, it may not be a valid RFC string'
      );
    }
    return rrule;
  } catch (error) {
    if (throwOnError) {
      throw error;
    }
    return undefined;
  }
};
