import { isNil } from 'lodash';

import {
  CommunicationWorkflowTargetTimeout,
  CommunicationWorkflowTargetTimeoutFixed,
  CommunicationWorkflowTargetTimeoutName,
} from '@pwp-common';

import { defaultTargetTimeoutSeconds } from '../default-target-timeout-seconds';

export const dbDataToTargetTimeout = (targetTimeout: CommunicationWorkflowTargetTimeout): number => {
  if (isNil(targetTimeout)) {
    return defaultTargetTimeoutSeconds;
  }
  switch (targetTimeout.getType()) {
    case CommunicationWorkflowTargetTimeoutName.fixed: {
      const typedObj = targetTimeout as CommunicationWorkflowTargetTimeoutFixed;
      return typedObj.getTimeoutMS() / 1000;
    }
    default: {
      console.error(targetTimeout);
      throw new Error('dbDataToTargetTimeout: Not implemented');
    }
  }
};
