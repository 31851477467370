import { CommunicationSessionEventLogSendMessageNotification, UserData } from '@pwp-common';

import { RowCell } from '../../../../common/p-table/row-cell';
import { CommunicationSessionSendMessageLogBase } from '../base/communication-session-send-message-log-base';

export class SendMessageNotificationRow extends CommunicationSessionSendMessageLogBase<CommunicationSessionEventLogSendMessageNotification> {
  constructor({
    event,
    allDataUser,
    timezone,
  }: {
    event: CommunicationSessionEventLogSendMessageNotification;
    allDataUser: Map<string, UserData>;
    timezone: string;
  }) {
    super({
      event,
      allDataUser,
      timezone,
      userId: event.getParticipant().getParticipantId(),
      userStatus: event.getUserStatus(),
    });
    this.updateOriginationReason();
  }

  private updateOriginationReason() {
    const translationKey = 'cellOriginationReasonNotification';
    this.originationReason = new RowCell<string>({
      translationScope: 'communication-session-event-row',
      translationKey,
      translationObj: {
        ...this.userDisplayNameWithEmail.translationObj,
      },
      sortValue: translationKey,
      styleClass: undefined,
    });
  }
}
