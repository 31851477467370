import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsRequestSchema} from '../../generic/communications-request/communications-request-schema';
import {CommunicationSessionId} from '../../../../../objects/core/communication-session-id/communication-session-id';

export class CommunicationsRequestDialConferenceSchema extends CommunicationsRequestSchema {
  public static readonly dialUserId = 'dialUserId';
  public static readonly callerIdObjId = 'callerIdObjId';
  public static readonly sessionId = 'sessionId';
  public static readonly conferenceFriendlyName = 'conferenceFriendlyName';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly dialUserId = undefined;
    public static readonly callerIdObjId = undefined;
    public static readonly sessionId = undefined;
    public static readonly conferenceFriendlyName = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsRequestDialConferenceSchema.dialUserId] = SchemaField.userId(CommunicationsRequestDialConferenceSchema.Defaults.dialUserId).required();
    parameters[CommunicationsRequestDialConferenceSchema.callerIdObjId] = SchemaField.autoGeneratedDocId(CommunicationsRequestDialConferenceSchema.Defaults.callerIdObjId).required();
    parameters[CommunicationsRequestDialConferenceSchema.sessionId] =  SchemaField.obj(CommunicationSessionId, CommunicationsRequestDialConferenceSchema.Defaults.sessionId).required();
    parameters[CommunicationsRequestDialConferenceSchema.conferenceFriendlyName] =  SchemaField.string(CommunicationsRequestDialConferenceSchema.Defaults.conferenceFriendlyName).optional();
    /* eslint-enable */

    return parameters;
  }
}
