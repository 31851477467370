import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-general-tab-editor',
  templateUrl: './general-tab-editor.component.html',
  styleUrls: ['./general-tab-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralTabEditorComponent {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input
  /////////////////////////////////////////////////////////////////////////////////////////////
  @Input() form: UntypedFormGroup;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////
  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName() {
    return this.form.get('general.displayName') as UntypedFormControl;
  }

  get description() {
    return this.form.get('general.description') as UntypedFormControl;
  }

  get taskReservationTimeoutSeconds() {
    return this.form.get('taskReservationTimeoutSeconds') as UntypedFormControl;
  }
}
