import type {ValidationResult} from 'joi';
import {cloneDeep} from 'lodash';

import {PossibleAdminRole} from '../../../../../objects/user/admin-roles/types';
import {PossibleRole} from '../../../../../objects/user/roles/types';
import {UserRequestName} from '../../../user-request-name';
import {UserRequest} from '../../generic/user-request/user-request';
import {UserRequestSchema} from '../../generic/user-request/user-request-schema';

import {UserRequestCreateUserConstructor} from './user-request-create-user-constructor';
import {UserRequestCreateUserSchema} from './user-request-create-user-schema';

export class UserRequestCreateUser extends UserRequest {
  protected e164Phone?: string;

  protected email!: string;

  protected firstName?: string;

  protected lastInitial?: string;

  protected roles?: PossibleRole[];

  protected orgNote?: string;

  constructor(parameters: UserRequestCreateUserConstructor) {
    super({
      ...parameters,
      [UserRequestSchema.type]: UserRequestName.createUser,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): UserRequestCreateUser {
    return new UserRequestCreateUser(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): UserRequestCreateUserSchema {
    return new UserRequestCreateUserSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(UserRequestCreateUser.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['orgAdmin'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getE164Phone() {
    return cloneDeep(this.e164Phone);
  }

  public getEmail() {
    return cloneDeep(this.email);
  }

  public getFirstName() {
    return cloneDeep(this.firstName);
  }

  public getLastInitial() {
    return cloneDeep(this.lastInitial);
  }

  public getRoles() {
    return cloneDeep(this.roles);
  }

  public getOrgNote() {
    return cloneDeep(this.orgNote);
  }
}
