<ng-container *transloco="let t; read: 'offer-config-ring-time-editor'">
  <!--  Warning -->
  <p-messages severity="warn">
    <ng-template pTemplate>
      <p class="wrap">{{ t('description') }}</p>
    </ng-template>
  </p-messages>
  <form [formGroup]="form">
    <!--  Preset -->
    <div class="field mt-3">
      <p>
        <b>{{ t('presetButtonLabel') }}</b>
      </p>
      <p-selectButton
        ariaLabel="{{ t('presetButtonLabel') }}"
        id="presetValue"
        [formControl]="preset"
        [options]="possiblePresetValues"
        (onChange)="handlePresetChange($event.value)"
      >
        <ng-template let-item>
          {{ t(item.label) }}
        </ng-template>
      </p-selectButton>
      <p-messages *ngIf="preset.value === presetValues.answerQuickly" severity="info">
        <ng-template pTemplate>
          <p class="wrap">{{ t('answerQuicklyDescription') }}</p>
        </ng-template>
      </p-messages>
    </div>
    <!-- Ring Time Seconds -->
    <div class="field mt-3">
      <div class="p-inputgroup">
        <span class="p-inputgroup-addon"><i class="pi pi-arrow-down"></i></span>
        <span class="p-float-label">
          <p-inputNumber
            ariaLabel="{{ t('ringTimeoutSecondsLabel') }}"
            id="ringTimeoutSecondsLabel"
            mode="decimal"
            suffix=" {{ t('ringTimeoutSecondsSuffix') }}"
            [formControl]="ringTimeoutSeconds"
            [max]="600"
            [min]="5"
            [ngClass]="{ 'ng-invalid': ringTimeoutSeconds?.invalid }"
            [showButtons]="true"
            (onInput)="handleTimeoutChange($event.value)"
          >
          </p-inputNumber>
        </span>
      </div>
    </div>
    <!-- Hangup On VoiceMail -->
    <div class="field mt-3">
      <p-checkbox
        ariaLabel="{{ t('hangUpOnVoiceMailLabel') }}"
        id="hangUpOnVoiceMail"
        label="{{ t('hangUpOnVoiceMailLabel') }}"
        [binary]="true"
        [formControl]="hangUpOnVoiceMail"
        (onChange)="handleHangUpChange($event.checked)"
      >
      </p-checkbox>
      <span *ngIf="!hangUpOnVoiceMail.value" class="description" id="hangUpOnVoiceMailCheckedLabel-help">
        <p class="wrap">{{ t('hangUpOnVoiceMailCheckedLabel') }}</p>
      </span>
      <span *ngIf="hangUpOnVoiceMail.value" class="description" id="hangUpOnVoiceMailNotCheckedLabel-help">
        <p class="wrap">{{ t('hangUpOnVoiceMailNotCheckedHelpLabel') }}</p>
      </span>
    </div>
  </form>
</ng-container>
