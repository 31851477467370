import { isNil } from 'lodash';

import {
  CallerIdStrategy,
  CallListOfferChannelConfigPhoneCall,
  CallListOfferChannelConfigPhoneCallSchema,
  CallListOfferConfig,
} from '@pwp-common';

import { defaultOfferConfigRingTimeEditorOutput } from '../../../../call-list-offer-config-ring-time/editor/editor-output/default-offer-config-ring-time-editor-output';
import { editorOutputToDb } from '../../../../call-list-offer-config-ring-time/editor/editor-output/editor-output-to-db/editor-output-to-db';
import { OfferConfigRingTimeEditorOutput } from '../../../../call-list-offer-config-ring-time/editor/editor-output/interfaces';
import { CallerIdStrategyEditorOutput } from '../../../../caller-id-strategy/editor-output/caller-id-strategy-editor-output';

export const editorOutputToOfferConfig = (params: {
  callListOfferConfigCallerIdStrategy?: CallerIdStrategyEditorOutput;
  callListOfferConfigMachineDetection?: OfferConfigRingTimeEditorOutput;
}) => {
  const { callListOfferConfigCallerIdStrategy, callListOfferConfigMachineDetection } = { ...params };

  /**************************************************************************************
   * Handle empty case
   **************************************************************************************/
  if (isNil(callListOfferConfigCallerIdStrategy) && isNil(callListOfferConfigMachineDetection)) {
    return;
  }

  const callListOfferConfigPhoneCall = new CallListOfferChannelConfigPhoneCall({});

  /**************************************************************************************
   * Parse callerIdStrategy
   **************************************************************************************/
  if (!isNil(callListOfferConfigCallerIdStrategy)) {
    const callerIdStrategy = new CallerIdStrategy({
      type: callListOfferConfigCallerIdStrategy.type,
      callerIdObjId: callListOfferConfigCallerIdStrategy.callerIdObjId,
    });
    callListOfferConfigPhoneCall.setField(CallListOfferChannelConfigPhoneCallSchema.callerIdStrategy, callerIdStrategy);
  }

  /**************************************************************************************
   * Parse machineDetection
   **************************************************************************************/
  if (!isNil(callListOfferConfigMachineDetection)) {
    // Check if ringTimeoutSeconds is 60 and hangUpOnVoiceMail is false (default values)
    const isDefaultMachineDetection =
      callListOfferConfigMachineDetection.ringTimeoutSeconds === defaultOfferConfigRingTimeEditorOutput.ringTimeoutSeconds &&
      callListOfferConfigMachineDetection.hangUpOnVoiceMail === defaultOfferConfigRingTimeEditorOutput.hangUpOnVoiceMail;

    if (!isDefaultMachineDetection) {
      const machineDetection = editorOutputToDb({ offerConfigHoldTime: callListOfferConfigMachineDetection });
      callListOfferConfigPhoneCall.setField(CallListOfferChannelConfigPhoneCallSchema.machineDetection, machineDetection);
    }
  }

  /**************************************************************************************
   * Combine data
   **************************************************************************************/
  if (!isNil(callListOfferConfigPhoneCall.getCallerIdStrategy()) || !isNil(callListOfferConfigPhoneCall.getMachineDetection())) {
    const callListOfferConfig = new CallListOfferConfig({
      offerChannelConfigPhoneCall: callListOfferConfigPhoneCall,
    });
    return callListOfferConfig;
  }
  return undefined;
};
