import {SerializableObject} from '../../../../../objects/generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {ConversationRequestName} from '../../../conversation-request-name';
import {ConversationResponseCloseAndDelete} from '../../responses/conversation-response-close-and-delete/conversation-response-close-and-delete';
import {ConversationResponseConfigure} from '../../responses/conversation-response-configure/conversation-response-configure';
import {ConversationResponseGetJwt} from '../../responses/conversation-response-get-jwt/conversation-response-get-jwt';
import {ConversationResponseTake} from '../../responses/conversation-response-take/conversation-response-take';
import {ConversationResponse} from '../conversation-response/conversation-response';

import {AnyConversationResponseSchema} from './any-conversation-response-schema';

export abstract class AnyConversationResponse extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationResponse<any> {
    const typeString = validationResult.value[AnyConversationResponseSchema.type];
    const type = ConversationRequestName[typeString as keyof typeof ConversationRequestName];
    if (type === undefined) {
      throw new Error(`AnyConversationResponse._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (type) {
      case ConversationRequestName.closeAndDelete:
        return new ConversationResponseCloseAndDelete(validationResult.value);
      case ConversationRequestName.configure:
        return new ConversationResponseConfigure(validationResult.value);
      case ConversationRequestName.getJWT:
        return new ConversationResponseGetJwt(validationResult.value);
      case ConversationRequestName.take:
        return new ConversationResponseTake(validationResult.value);
      default:
        throw new Error(`AnyConversationResponse._deserialize Unknown type: ${type}`);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyConversationResponseSchema();
  }
}
