import {cloneDeep} from 'lodash';
import {Displayable} from '../../generic/db-doc/displayable';
import {DBDocObject} from '../../generic/db-doc/db-doc-object';
import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {OrgDataConstructor} from './org-data-constructor';
import {OrgDataSchema} from './org-data-schema';
import {EventType} from '../event-type/event-type';

export class OrgData extends DBDocObject implements Displayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected displayName!: string;
  protected eventTypes!: EventType[];
  protected e164Phones!: string[];
  protected timezone!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: OrgDataConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): OrgData {
    return new OrgData(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(OrgData.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new OrgDataSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getDisplayName(): string {
    return cloneDeep(this.displayName);
  }

  public getEventTypes(): EventType[] {
    return cloneDeep(this.eventTypes);
  }

  public getE164Phones(): string[] {
    return cloneDeep(this.e164Phones);
  }

  public getTimezone(): string {
    return cloneDeep(this.timezone);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////

  public getEventTypeWithInternalName(internalName: string): EventType {
    const types = this.getEventTypes().filter(z => z.getId() === internalName);

    if (types.length !== 1) {
      throw new Error(
        `OrgData.getEventTypeWithInternalName: There are ${types.length} event types with internalName=${internalName}`
      );
    }
    return types[0];
  }
}
