import {isNil} from 'lodash';
import {ServiceException} from '../service-exception/service-exception/service-exception';
import {DbDataIds} from '../../../core/db-data-ids/db-data-ids';
import {getServiceOperationDbDataIds} from '../../../voice-response-command/service-operation/get-service-operation-db-data-ids/get-service-operation-db-data-ids';

export const getServiceExceptionDbDataIds = (serviceException: ServiceException): DbDataIds => {
  const result = new DbDataIds({
    serviceLimits: new Set<string>(),
  });

  if (isNil(serviceException)) {
    return result;
  }

  result.add(getServiceOperationDbDataIds(serviceException.getInboundCallSessionAction().getOnCreate()));
  return result;
};
