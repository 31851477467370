import {SerializableObject} from '../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {VoiceResponseCommandName} from '../any-voice-response-command/voice-response-command-name';
import {cloneDeep} from 'lodash';
import {Displayable} from '../../generic/db-doc/displayable';

/**
 * Subclasses of this class parse a command from firebase and produce TwiML which
 * direct Twilio's servers to play audio / etc. (via raw TwiML), or direct our
 * servers to do something (like dial all users in a list). In the former case the
 * Twilio receives all parameters inside the TwiML. In the latter case, our HTTPS
 * endpoints receive their required parameters inside the URL parameters.
 */
export abstract class VoiceResponseCommand extends SerializableObject implements Displayable {
  protected commandName!: VoiceResponseCommandName;

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    throw new Error('Error: Cannot get schema for abstract VoiceResponseCommand');
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCommandName(): VoiceResponseCommandName {
    return cloneDeep(this.commandName);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Displayable Methods
  /////////////////////////////////////////////////////////////////////////////

  public getDisplayName(): string {
    return cloneDeep(this.commandName);
  }

  public getId(): string {
    return this.getDisplayName();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////
}
