<ng-container *transloco="let t; read: 'sequence-steps'">
  <p-confirmDialog
    header="{{ t('confirmDeleteHeader') }}"
    icon="pi pi-exclamation-triangle"
    message="{{ t('confirmDeleteBody') }}"
  ></p-confirmDialog>

  <div *ngFor="let sequenceStep of sequenceSteps">
    <div [ngSwitch]="sequenceStep.type">
      <!-- Start -->
      <p-panel *ngSwitchCase="'start'" [collapsed]="true" [header]="t('startHeader')"> </p-panel>

      <!-- End -->
      <p-panel *ngSwitchCase="'end'" [collapsed]="true" [header]="t('endHeader')"> </p-panel>

      <!-- Step -->
      <p-panel *ngSwitchCase="'step'" [collapsed]="true" [toggleable]="true" [toggler]="'header'">
        <!-- Panel Header -->
        <ng-template pTemplate="header">
          <p *ngIf="!stepHeaderTemplate">
            {{ t('sequenceStepHeader', { formControlIndex: sequenceStep.formControlIndex }) }}
          </p>
          <ng-container
            [ngTemplateOutlet]="stepHeaderTemplate"
            [ngTemplateOutletContext]="{ $implicit: sequenceStep.formControlIndex, formControlIndex: sequenceStep.formControlIndex }"
          >
          </ng-container>
        </ng-template>

        <!-- Panel Content -->
        <ng-template pTemplate="content">
          <ng-container
            [ngTemplateOutlet]="editorTemplate"
            [ngTemplateOutletContext]="{
              $implicit: sequenceStep.formControlIndex,
              formControlIndex: sequenceStep.formControlIndex,
              control: sequenceStep.control
            }"
          >
          </ng-container>
        </ng-template>

        <!-- Panel Footer -->
        <ng-template pTemplate="footer">
          <button
            pButton
            pTooltip="{{ t('removeStepButtonTooltip') }}"
            class="p-button-text p-button-danger"
            icon="pi pi-trash"
            type="button"
            [attr.aria-label]="t('removeStepButtonLabel')"
            (click)="removeStepAtIndex(sequenceStep.formControlIndex)"
          ></button>
        </ng-template>
      </p-panel>

      <!-- Step Error -->
      <p *ngIf="steps.hasError('maxlength') && sequenceStep.formControlIndex + 1 > steps.errors.maxlength.requiredLength" class="p-error">
        {{ t('stepsErrorMaxLength', { num: steps.controls.length, maxSteps }) }}
      </p>

      <!-- Add Icon -->
      <div *ngIf="sequenceStep.showAddIcon === true" class="flex justify-content-center flex-wrap card-container timeline">
        <div class="flex align-items-center justify-content-center w-4rem h-4rem m-2">
          <p-button
            pTooltip="{{ t('addStepButtonTooltip') }}"
            ariaLabel="{{ t('addStepButtonLabel') }}"
            icon="pi pi-plus-circle"
            styleClass="p-button-rounded p-button-secondary p-button-text z-1 p-button-lg add-button bg-white"
            [disabled]="steps.disabled"
            (onClick)="addStepAtIndex(sequenceStep.formControlIndex)"
          >
          </p-button>
        </div>
      </div>
    </div>
  </div>

  <!--  Sequence Error -->
  <p *ngIf="steps.hasError('required')" class="p-error">{{ t('stepsErrorRequired') }}</p>
  <p *ngIf="steps.hasError('minlength')" class="p-error">{{ t('stepsErrorMinLength') }}</p>
</ng-container>
