import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { isNil } from 'lodash';

import { CommunicationWidgetCreateAsyncServiceRequest, CreateAsyncServiceRequest, CreateAsyncServiceRequestSchema } from '@pwp-common';

import { KVPair } from '../../../../common/objects/kvpair';
import { VRCEditor } from '../../../generic/abstract-classes/vrc-editor';

@Component({
  selector: 'app-vrc-editor-create-async-service-request',
  templateUrl: './vrc-editor-create-async-service-request.component.html',
  styleUrls: ['./vrc-editor-create-async-service-request.component.css'],
})
export class VrcEditorCreateAsyncServiceRequestComponent extends VRCEditor<CreateAsyncServiceRequest> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  widgetKvPair: KVPair<CommunicationWidgetCreateAsyncServiceRequest>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = {} as any;

    parameters[CreateAsyncServiceRequestSchema.createAsyncServiceRequestWidgetId] =
      this.widgetKvPair?.value?.getId() || CreateAsyncServiceRequestSchema.Defaults.createAsyncServiceRequestWidgetId;

    const value = new CreateAsyncServiceRequest(parameters);
    return new KVPair({ id: this.obj.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<CreateAsyncServiceRequest>) {
    // Init Form
    const formConfig = {} as any;
    this.form = this.formBuilder.group(formConfig);

    // Init other state
    this.widgetKvPair = new KVPair({ id: obj.value!.getCreateAsyncServiceRequestWidgetId() });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj() {
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.widgetKvPair?.value)) {
      return false;
    }

    return true;
  }
}
