import {cloneDeep} from 'lodash';
import {DBMatcherConstructor} from './db-matcher-constructor';
import {DBMatcherSchema} from './db-matcher-schema';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {DBMatchRule} from '../db-match-rule/generic/db-match-rule/db-match-rule';
import {DBMatchRuleE164Phone} from '../db-match-rule/db-match-rules/db-match-rule-e164-phone/db-match-rule-e164-phone';

export class DBMatcher extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected queryIds!: string[];
  protected rules!: DBMatchRule[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: DBMatcherConstructor) {
    super(parameters);
    this.calculateIds();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): DBMatcher {
    return new DBMatcher(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(DBMatcher.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new DBMatcherSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getQueryIds(): string[] {
    return cloneDeep(this.queryIds);
  }

  public getRules(): DBMatchRule[] {
    return cloneDeep(this.rules);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Calculate Ids
  /////////////////////////////////////////////////////////////////////////////

  private calculateIds(): void {
    // Overwrite the query identifiers with
    this.queryIds = [];
    for (const matcher of this.rules) {
      this.queryIds = [...this.queryIds, ...matcher.getQueryIds()];
    }
    this.queryIds = this.queryIds.sort();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Matched Rules
  /////////////////////////////////////////////////////////////////////////////

  public getMatchedE164Phones(): string[] {
    const e164Phones: string[] = [];
    for (const rule of this.rules) {
      if (rule instanceof DBMatchRuleE164Phone) {
        e164Phones.push(...rule.getE164Phones());
      }
    }
    return e164Phones.sort();
  }
}
