import { AbstractControl } from '@angular/forms';

export const setControlEnabled = (
  control: AbstractControl,
  condition: unknown,
  options?: Parameters<AbstractControl['enable']>[0],
): void => {
  if (condition) {
    control.enable(options);
  } else {
    control.disable(options);
  }
};
