import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { isNil } from 'lodash';

import { CallList, DialCallList, DialCallListSchema } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../../common/objects/form-helper';
import { KVPair } from '../../../../common/objects/kvpair';
import { VRCEditor } from '../../../generic/abstract-classes/vrc-editor';

@Component({
  selector: 'app-vrc-editor-dial-call-list',
  templateUrl: './vrc-editor-dial-call-list.component.html',
  styleUrls: ['./vrc-editor-dial-call-list.component.css'],
})
export class VrcEditorDialCallListComponent extends VRCEditor<DialCallList> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  callListKVPair: KVPair<CallList>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
    private formBuilder: UntypedFormBuilder,
  ) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      undefined,
      [DialCallListSchema.numTimesDialEachUser],
      DialCallListSchema.Defaults,
      this,
    );

    parameters[DialCallListSchema.callListId] = this.callListKVPair?.value?.getId() || DialCallListSchema.Defaults.callListId;

    const value = new DialCallList(parameters);
    return new KVPair({ id: this.obj.id, value });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: KVPair<DialCallList>) {
    // Init Form
    const formConfig = {} as any;
    formConfig[DialCallListSchema.numTimesDialEachUser] = [obj.value!.getNumTimesDialEachUser(), [Validators.max(10), Validators.min(1)]];

    this.form = this.formBuilder.group(formConfig);

    // Init other state
    this.callListKVPair = new KVPair({ id: obj.value!.getCallListId() });
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get numTimesDialEachUser(): AbstractControl | null {
    return this.form.get(DialCallListSchema.numTimesDialEachUser);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj() {
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.callListKVPair?.value)) {
      return false;
    }
    return true;
  }
}
