import {DBDocSchema} from '../../../generic/db-doc/db-doc-schema';
import {VoiceResponse} from '../../../../helper/twilio-types';
import {AnyVoiceResponseCommand} from '../../../voice-response-command/any-voice-response-command/any-voice-response-command';
import {SchemaField} from '../../../generic/serialization/schema-field';
import {IVROption} from '../ivr-option/ivr-option';
import {SupportedLanguages} from '../../../voice-response-command/vrc-audio-metadata/supported-languages';
import {VoiceResponseCommandName} from '../../../voice-response-command/any-voice-response-command/voice-response-command-name';

export class IVRSchema extends DBDocSchema {
  public static readonly displayName = 'displayName';

  public static readonly description = 'description';

  // Respond with this TwiML to tell the user the menu options for this IVR.
  // See: https://www.twilio.com/docs/voice/twiml/gather#nest-other-verbs
  public static readonly menuOptionsCommands = 'menuOptionsCommands';

  // Respond with this TwiML if user made an invalid selection.
  public static readonly invalidKeyPressedCommands = 'invalidKeyPressedCommands';

  public static readonly maxDigits = 'maxDigits';

  // A map of how to handle each key press or speech input
  public static readonly options = 'options';

  public static readonly onFailure = 'onFailure';

  public static readonly numRetriesRemaining = 'numRetriesRemaining';

  // If no language is available, then use this language.
  public static readonly defaultLanguage = 'defaultLanguage';

  ////////////////////////////////////////////////////////////////////////
  /**
   * These fields map to fields in Twilio
   * https://www.twilio.com/docs/voice/twiml/gather
   */
  ////////////////////////////////////////////////////////////////////////

  public static readonly speechModel = 'speechModel';
  public static readonly speechTimeout = 'speechTimeout';
  public static readonly timeout = 'timeout';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'ivrs';
    public static readonly allowedMenuOptionsCommands = [
      VoiceResponseCommandName.switch,
      VoiceResponseCommandName.play,
      VoiceResponseCommandName.playOrSay,
      VoiceResponseCommandName.say,
      VoiceResponseCommandName.setContext,
    ];
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly displayName = 'missing-displayName';
    public static readonly description = 'missing-description';
    public static readonly menuOptionsCommands = [];
    public static readonly invalidKeyPressedCommands = [];
    public static readonly maxDigits = 1;
    public static readonly options: IVROption[] = [];
    public static readonly onFailure = [];
    public static readonly numRetriesRemaining = 2;
    public static readonly speechTimeout = 'auto';
    public static readonly speechModel: VoiceResponse.GatherSpeechModel = 'numbers_and_commands';
    public static readonly timeout = 5;
    public static readonly defaultLanguage = SupportedLanguages.en.getShortCode();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([IVRSchema.invalidKeyPressedCommands, IVRSchema.onFailure, IVRSchema.options, IVRSchema.maxDigits]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[IVRSchema.displayName] = SchemaField.string(IVRSchema.Defaults.displayName);
    parameters[IVRSchema.description] = SchemaField.string(IVRSchema.Defaults.description);
    parameters[IVRSchema.menuOptionsCommands] = SchemaField.shortObjArray(AnyVoiceResponseCommand, IVRSchema.Defaults.menuOptionsCommands);
    parameters[IVRSchema.invalidKeyPressedCommands] = SchemaField.shortObjArray(AnyVoiceResponseCommand, IVRSchema.Defaults.invalidKeyPressedCommands);
    parameters[IVRSchema.maxDigits] = SchemaField.number(IVRSchema.Defaults.maxDigits);
    parameters[IVRSchema.options] = SchemaField.shortObjArray(IVROption, IVRSchema.Defaults.options);
    parameters[IVRSchema.onFailure] = SchemaField.shortObjArray(AnyVoiceResponseCommand, IVRSchema.Defaults.onFailure);
    parameters[IVRSchema.numRetriesRemaining] = SchemaField.number(IVRSchema.Defaults.numRetriesRemaining);
    parameters[IVRSchema.speechTimeout] = SchemaField.string(IVRSchema.Defaults.speechTimeout);
    parameters[IVRSchema.speechModel] = SchemaField.string(IVRSchema.Defaults.speechModel);
    parameters[IVRSchema.timeout] = SchemaField.number(IVRSchema.Defaults.timeout);
    parameters[IVRSchema.defaultLanguage] = SchemaField.string(IVRSchema.Defaults.defaultLanguage);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${IVRSchema.Constants.collection}`;
  }
}
