import type {ValidationResult} from 'joi';

import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {FormRequestName} from '../../../form-request-name';
import {FormResponse} from '../../generic/form-response/form-response';
import {FormResponseSchema} from '../../generic/form-response/form-response-schema';

import {FormResponseCreateVersionConstructor} from './form-response-create-version-constructor';
import {FormResponseCreateVersionError} from './form-response-create-version-error';
import {FormResponseCreateVersionSchema} from './form-response-create-version-schema';

export class FormResponseCreateVersion extends FormResponse<FormResponseCreateVersionError> {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: FormResponseCreateVersionConstructor) {
    super({
      ...parameters,
      [FormResponseSchema.type]: FormRequestName.createVersion,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): FormResponseCreateVersion {
    return new FormResponseCreateVersion(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new FormResponseCreateVersionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(FormResponseCreateVersion.getSchema());
  }
}
