import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {UserRequestSchema} from '../../generic/user-request/user-request-schema';

export class UserRequestChangeOrgIdSchema extends UserRequestSchema {
  public static readonly requestedNewOrgId = 'requestedNewOrgId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class extends UserRequestSchema.Defaults {
    public static readonly requestedNewOrgId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[UserRequestChangeOrgIdSchema.requestedNewOrgId] = SchemaField.autoGeneratedDocId(UserRequestChangeOrgIdSchema.Defaults.requestedNewOrgId).required();

    return parameters;
  }
}
