<!-- In Progress -->
<div *ngIf="inProgress | async" class="d-flex justify-content-center">
  <!-- See: https://stackoverflow.com/questions/35163164/how-to-center-content-in-a-bootstrap-column -->
  <mat-spinner [mode]="'indeterminate'"></mat-spinner>
</div>

<!-- Done -->
<div [hidden]="inProgress | async">
  <ng-container [ngTemplateOutlet]="doneTemplate"> </ng-container>
</div>
