import {SchemaField} from '../../../../../../generic/serialization/schema-field';
import {SerializableObjectSchema} from '../../../../../../generic/serialization/serializable-object-schema';

export abstract class CommunicationWorkflowTargetTimeoutSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The name of the command
  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationWorkflowTargetTimeoutSchema.type] = SchemaField.string(CommunicationWorkflowTargetTimeoutSchema.type, CommunicationWorkflowTargetTimeoutSchema.Defaults.type);
    /* eslint-enable */

    return parameters;
  }
}
