import {ReservationSkipOffersIf} from './reservation-skip-offers-if/reservation-skip-offers-if';
import {ReservationSkipOffersIfRuleAvailability} from './reservation-skip-offers-if-rule/reservation-skip-offers-if-rules/reservation-skip-offers-if-rule-availability/reservation-skip-offers-if-rule-availability';
import {ReservationSkipOffersIfRuleChannelMaxChannelName} from './reservation-skip-offers-if-rule/reservation-skip-offers-if-rules/reservation-skip-offers-if-rule-channel-max/reservation-skip-offers-if-rule-channel-max-channel-name';
import {ReservationSkipOffersIfRuleName} from './reservation-skip-offers-if-rule/generic/reservation-skip-offers-if-rule-name';
import {ReservationSkipOffersIfRuleChannelMax} from './reservation-skip-offers-if-rule/reservation-skip-offers-if-rules/reservation-skip-offers-if-rule-channel-max/reservation-skip-offers-if-rule-channel-max';

export const defaultReservationSkipOffersIf = new ReservationSkipOffersIf({
  rules: [
    new ReservationSkipOffersIfRuleAvailability({type: ReservationSkipOffersIfRuleName.availability, skipIfUnavailable: true}),
    new ReservationSkipOffersIfRuleChannelMax({
      type: ReservationSkipOffersIfRuleName.channelMax,
      channel: ReservationSkipOffersIfRuleChannelMaxChannelName.phone,
      skipIfInProgressGE: 1,
    }),
  ],
});
