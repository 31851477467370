import {
  AllDataUser,
  DBUploadExistingDoc,
  EventData,
  EventDataSchema,
  EventDataTransactionDisplay,
  EventPropertiesBulkUpdate,
  eventPropertiesBulkUpdateIsValid,
  makeSimpleUploadEventDataTransactionDisplay,
  OrgData,
} from '@pwp-common';

import { KeyType, KVPair } from '../objects/kvpair';

/**
 * Given a list of strings, return a list where each element is repeated N times.
 *
 * @param items Input list of strings
 * @param numRepeat Number of times to repeat each element.
 */
export const editEventTransactionsInPlace = (
  transactions: KVPair<EventDataTransactionDisplay>[],
  orgData: OrgData,
  ids: Set<KeyType>,
  allDataUserMap: Map<string, AllDataUser>,
  update: EventPropertiesBulkUpdate,
): void => {
  // Sanity Check
  eventPropertiesBulkUpdateIsValid(update, allDataUserMap);

  for (let i = 0; i < transactions.length; i++) {
    const transaction = transactions[i];
    if (!ids.has(transaction.getId())) {
      continue;
    }

    if (!transaction.value?.isSimpleUpdate()) {
      throw new Error('editTransactionsInPlace: Only simple updates allowed.');
    }
    const lastEvent = (transaction.value.actions[0] as DBUploadExistingDoc<EventData>).obj as EventData;

    lastEvent.setField(EventDataSchema.assignedUserId, update.assignedUserId);
    lastEvent.setField(EventDataSchema.assignedBackupUserId, update.assignedBackupUserId);
    lastEvent.setField(EventDataSchema.color, update.color);

    transactions[i] = new KVPair({
      id: transaction.getId(),
      value: makeSimpleUploadEventDataTransactionDisplay(allDataUserMap, orgData, lastEvent),
    });
  }
};
