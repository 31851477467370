import {SchemaField} from '../../generic/serialization/schema-field';
import {VoiceResponseCommandSchema} from '../generic/voice-response-command-schema';
import {VoiceResponseCommandName} from '../any-voice-response-command/voice-response-command-name';

export class PlaySchema extends VoiceResponseCommandSchema {
  // Unique identifier of the audio file internally to the database
  public static readonly audioId = 'audioId';

  // Default value 1. Number of times to loop
  public static readonly loop = 'loop';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly commandName = VoiceResponseCommandName.play;
    public static readonly audioId = undefined;
    public static readonly loop = 1;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[VoiceResponseCommandSchema.commandName] = SchemaField.string(PlaySchema.Defaults.commandName);
    parameters[PlaySchema.audioId] = SchemaField.string(PlaySchema.Defaults.audioId);
    parameters[PlaySchema.loop] = SchemaField.number(PlaySchema.Defaults.loop);
    /* eslint-enable */

    return parameters;
  }
}
