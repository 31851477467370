<mat-card>
  <mat-card-header>
    <ng-container [ngTemplateOutlet]="dashboardConfigTemplate"></ng-container>
  </mat-card-header>
  <!-- Content -->
  <mat-card-content>
    <ng-container [ngTemplateOutlet]="dashboardTemplate"></ng-container>
  </mat-card-content>

  <mat-card-footer>
    <div style="text-align: right">Data updated: {{ lastRefreshTime | momentPipe }}</div>
  </mat-card-footer>
</mat-card>
