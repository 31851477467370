import {cloneDeep} from 'lodash';
import {CommunicationWorkerAttributesTaskQueueConstructor} from './communication-worker-attributes-task-queue-constructor';
import {CommunicationWorkerAttributesTaskQueueSchema} from './communication-worker-attributes-task-queue-schema';
import {SerializableObject} from '../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';

export class CommunicationWorkerAttributesTaskQueue extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected index!: number;
  /*
   * WARNING: Do not delete this legacy field, as it is stored in Twilio TaskRouter configuration.
   * Legacy and deprecated field: `isLast`.
   * Can be removed upon completing PW-498
   * (Simplify Rollbacks: https://helplinesoftware.atlassian.net/browse/PW-498),
   * allowing for rollback support after schema changes were made to a document.
   *
   */
  protected isLast!: boolean | undefined;
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationWorkerAttributesTaskQueueConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only offerChannels
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationWorkerAttributesTaskQueue {
    return new CommunicationWorkerAttributesTaskQueue(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationWorkerAttributesTaskQueue.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationWorkerAttributesTaskQueueSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getIndex(): number {
    return cloneDeep(this.index);
  }
}
