import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';
import {AnyVoiceResponseCommand} from '../../../voice-response-command/any-voice-response-command/any-voice-response-command';
import {VoiceResponseCommand} from '../../../voice-response-command/generic/voice-response-command';
export class IVROptionSchema extends SerializableObjectSchema {
  // Display Name
  public static readonly displayName = 'displayName';

  // Description
  public static readonly description = 'description';

  // Commands should be executed if any of these key presses are detected.
  public static readonly keyPresses = 'keyPresses';

  // Commands should be executed if any of these phrases are detected.
  public static readonly phrases = 'phrases';

  // Commands to execute
  public static readonly commands = 'commands';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly displayName: string = 'missing-displayName';
    public static readonly description: string = 'missing-description';
    public static readonly keyPresses: string[] = [];
    public static readonly phrases = new Map<string, string[]>();
    public static readonly commands: VoiceResponseCommand[] = [];
  };

  static Constants = class {
    // Each hint can be at most 100 chars: https://www.twilio.com/docs/voice/twiml/gather#hints
    public static readonly ivrHintItemMaxNumChars: 100;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([IVROptionSchema.keyPresses, IVROptionSchema.phrases]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[IVROptionSchema.displayName] = SchemaField.string(IVROptionSchema.Defaults.displayName);
    parameters[IVROptionSchema.description] = SchemaField.string(IVROptionSchema.Defaults.description);
    parameters[IVROptionSchema.keyPresses] = SchemaField.arrayOfType(SchemaField.sanitizedString(), IVROptionSchema.Defaults.keyPresses);
    parameters[IVROptionSchema.phrases] = SchemaField.mapOfType(SchemaField.arrayOfType(SchemaField.sanitizedString(IVROptionSchema.Constants.ivrHintItemMaxNumChars), []), IVROptionSchema.Defaults.phrases);
    parameters[IVROptionSchema.commands] = SchemaField.shortObjArray(AnyVoiceResponseCommand,IVROptionSchema.Defaults.commands);
    /* eslint-enable */

    return parameters;
  }
}
