import { ChangeDetectionStrategy, Component, OnChanges, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';

import { SettingsRowEditorBase } from '../../../../generic/settings/abstract-classes/settings-row-editor-base';
import { CommunicationWorkflowEditorOutput } from '../../editor/editor-output/workflow/communication-workflow-editor-output';
import { CommunicationWorkflowTableRow } from '../communication-workflow-table-row/communication-workflow-table-row';


@Component({
  selector: 'app-call-list-table-editor-expansion',
  templateUrl: './communication-workflow-table-editor-expansion.component.html',
  styleUrls: ['./communication-workflow-table-editor-expansion.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class CommunicationWorkflowTableEditorExpansionComponent
  extends SettingsRowEditorBase<CommunicationWorkflowEditorOutput, CommunicationWorkflowTableRow>
  implements OnChanges, OnInit
{
  constructor(messageService: MessageService) {
    super(messageService);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Audit Entry
  ///////////////////////////////////////////////////////////////////////////////////////////
  updateAuditEntry() {
    // Do nothing
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Upload
  ///////////////////////////////////////////////////////////////////////////////////////////
  protected async uploadEditorOutput(
    editorOutput: CommunicationWorkflowEditorOutput,
  ): Promise<{ updateSucceeded: boolean; objId: string }> {
    throw new Error('Not Implemented');
  }
}
