import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';

export class IdentityConfigSchema extends SerializableObjectSchema {
  public static readonly captureTarget = 'captureTarget';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly captureTarget = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[IdentityConfigSchema.captureTarget] = SchemaField.string(IdentityConfigSchema.Defaults.captureTarget).optional();
    /* eslint-enable */

    return parameters;
  }
}
