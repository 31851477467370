import { Directive, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

import { PTableCol } from '../../../../common/p-table/p-table-col';
import { DataTableRow } from '../../data-table/common/data-table-row';

@Directive()
export abstract class SettingsEditorTableBase<RowType extends DataTableRow> implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  tableRows: Observable<RowType[]>;

  loading = true;

  ///////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////

  cols: PTableCol[];

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit() {
    this.getData();
  }
  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  getDataWrapper = () => this.getData();

  protected abstract getData();
}
