import {CalleeEntity} from '../callee-entity';
import {cloneDeep, isEmpty, isNil} from 'lodash';
import {CalleeEntityType} from '../enums';
import {CalleeEntitySchema} from '../callee-entity-schema';

export const compressCalleeEntityArray = (calleeEntities: CalleeEntity[]): CalleeEntity[] => {
  if (isEmpty(calleeEntities)) {
    return [];
  }
  const result: CalleeEntity[] = [];
  for (let i = 0; i < calleeEntities.length; i++) {
    const prevItem = result[result.length - 1];
    const currItem = cloneDeep(calleeEntities[i]);
    if (isNil(prevItem)) {
      result.push(currItem);
      continue;
    }
    if (prevItem.getType() !== currItem.getType()) {
      result.push(currItem);
      continue;
    }
    switch (prevItem.getType()) {
      case CalleeEntityType.event: {
        if (prevItem.getEventType() !== currItem.getEventType()) {
          result.push(currItem);
          continue;
        } else {
          prevItem.setField(CalleeEntitySchema.assignedUserTypes, [
            ...prevItem.getAssignedUserTypes()!,
            ...currItem.getAssignedUserTypes()!,
          ]);
          continue;
        }
      }
      case CalleeEntityType.userList: {
        prevItem.setField(CalleeEntitySchema.userList, [...prevItem.getUserList()!, ...currItem.getUserList()!]);
        continue;
      }
      default: {
        throw new Error(`compressCalleeEntityArray: Unknown CalleeEntityType: ${prevItem.getType()}`);
      }
    }
  }
  return result;
};
