import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Provider } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';

import { AnyVoiceResponseCommandSchema, VoiceResponseCommandName } from '@pwp-common';

import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';
import { ServiceCommandEditorOutput } from '../editor-output/service-command-editor-output';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ServiceCommandEditorContainerComponent),
  multi: true,
};

const VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ServiceCommandEditorContainerComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  selector: 'app-service-command-editor-container',
  templateUrl: './service-command-editor-container.component.html',
  styleUrls: ['./service-command-editor-container.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class ServiceCommandEditorContainerComponent extends FormGroupControlValueAccessor<
  ServiceCommandEditorOutput,
  ServiceCommandEditorOutput
> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
    this.commandName.valueChanges.pipe(untilDestroyed(this)).subscribe((value: VoiceResponseCommandName) => {
      if (isNil(value)) {
        return;
      }
      const commandFormValue: ServiceCommandEditorOutput = { commandName: value };
      // Do nothing if this command was already selected
      if (this.command?.value?.commandName === value) {
        return;
      }
      this.writeValue(commandFormValue);
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    this.form = new UntypedFormGroup({
      command: new UntypedFormControl(),
      commandName: new UntypedFormControl(),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: any) {
    if (isNil(value)) {
      return;
    }
    const patchedValue = { command: value, commandName: value[AnyVoiceResponseCommandSchema.commandName] } as any;
    return super.writeValue(patchedValue);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: ServiceCommandEditorOutput): any {
    return value.command;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get command() {
    return this.form.get('command') as UntypedFormControl;
  }

  get commandName() {
    return this.form.get('commandName') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'service-command-editor-container': this.form.value,
    };
  }
}
