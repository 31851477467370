import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { isNil } from 'lodash';
import { Observable } from 'rxjs';
import { map, startWith, takeUntil } from 'rxjs/operators';

import { getCompleteKVPairs, isEmptyKVPair, KVPair } from '../../../common/objects/kvpair';
import { DisplayableObj } from '../../../common/objects/types';
import { ChooseObjForm } from '../abstract-classes/choose-obj-form';

@Component({
  selector: 'app-obj-autocomplete',
  templateUrl: './obj-autocomplete.component.html',
  styleUrls: ['./obj-autocomplete.component.css'],
})
export class ObjAutocompleteComponent<T extends DisplayableObj> extends ChooseObjForm<T> implements OnInit, OnChanges, OnDestroy {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs / Outputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() displayFormat: (obj: T | undefined) => string;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public formControl = new UntypedFormControl();

  filteredItems: Observable<(string | KVPair<T>)[]>;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  ngOnInit() {
    this.filteredItems = this.formControl.valueChanges.pipe(
      startWith(''),
      map((substr) => {
        const result = this.filter(substr);
        return result;
      }),
      takeUntil(this.ngUnsubscribe),
    );
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getFormControl() {
    return this.formControl;
  }

  ///////////////////////////////////////////////////////////////////////
  // Filter
  ///////////////////////////////////////////////////////////////////////

  filter(searchValue: string | null | undefined | KVPair<T>): (KVPair<T> | string)[] {
    if (isNil(this.orderedItems)) {
      return [];
    }

    if (isNil(searchValue)) {
      return [searchValue];
    }

    if (typeof searchValue === 'string') {
      const filterValue = searchValue.toLowerCase();

      const itemsMatchingFilter: (KVPair<T> | string)[] = [];
      const completeKVPairs = getCompleteKVPairs(this.orderedItems);
      for (const item of completeKVPairs) {
        const itemAsString = Object.values(item?.value.serialize())
          .map((v) => this.normalizeString(v.toString()))
          .join(' ');

        if (itemAsString.includes(filterValue)) {
          itemsMatchingFilter.push(item);
        }
      }

      for (const [key, value] of Object.entries(this.specialKVPairs)) {
        if (this.normalizeString(value).includes(searchValue)) {
          itemsMatchingFilter.push(key);
        }
      }

      return itemsMatchingFilter;
    }

    return [searchValue];
  }

  private normalizeString(value: string): string {
    return (value?.toString() || '').toLocaleLowerCase();
  }

  displayFn = (obj: KVPair<T> | string): string => {
    if (typeof obj === 'string') {
      return this.specialKVPairs[obj];
    }

    if (isEmptyKVPair(obj)) {
      return '';
    }

    if (obj.value === undefined) {
      return `${obj.id}`;
    }

    if (this.displayFormat !== undefined) {
      return this.displayFormat(obj.value);
    }
    return obj.value.getDisplayName();
  };
}
