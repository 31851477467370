import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { forkJoin, Observable, of } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';

import { SubmitTicketRequest, SubmitTicketResponse } from '@pwp-common';

import { CallableFunctionService } from '../generic/callable-function-service/callable-function-service';
import { RemoteConfigService } from '../remote-config/remote-config.service';
import { AuthService } from '../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class SubmitTicketService extends CallableFunctionService<SubmitTicketRequest, SubmitTicketResponse> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    fns: AngularFireFunctions,
    private authService: AuthService,
    private remoteConfigService: RemoteConfigService,
  ) {
    super(SubmitTicketResponse, fns);
  }
  ///////////////////////////////////////////////////////////////////////
  // Function Name
  ///////////////////////////////////////////////////////////////////////

  public getFunctionName() {
    return 'submitTicket';
  }

  public isSupportEnabled(): Observable<boolean> {
    return this.authService.getUserId().pipe(
      switchMap((userId) => forkJoin([this.remoteConfigService.initialize(), of(userId)])),
      map((value) => this.remoteConfigService.getSupportUsers().includes(value[1])),
      shareReplay(1),
    );
  }
}
