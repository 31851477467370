import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { VoicemailRow } from '../../../../row/voicemail-row/voicemail-row';

@Component({
  selector: 'app-inbox-voicemail-detail',
  templateUrl: './inbox-voicemail-detail.component.html',
  styleUrls: ['./inbox-voicemail-detail.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxVoicemailDetailComponent implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////

  @Input() row: VoicemailRow;

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit(): void {}
}
