import {isNil} from 'lodash';
import {UserPrivateDataSchema} from '../../objects/user/user-private-data/user-private-data-schema';

/**
 * Given a sequence of digits, return a 10 digit string that may represent a US
 * phone number. If such a string cannot be found, return undefined.
 *
 * The string is sanitized to insure it contains only digits, and is
 * exactly 10 digits.
 *
 * @param unsafePhone String identifying a phone number.
 */

export const usLocalPhone = (unsafePhone: string): string | undefined => {
  if (isNil(unsafePhone)) {
    return undefined;
  }

  let phoneOnlyDigits = unsafePhone.replace(/[^\d]/g, '');

  // Remove the leading 1 (corresponds to country code US / Canada) because the phone
  // may appear with or without the country code.
  if (phoneOnlyDigits.length > 0 && phoneOnlyDigits.charAt(0) === '1') {
    phoneOnlyDigits = phoneOnlyDigits.substr(1);
  }

  const validPhoneLength = UserPrivateDataSchema.Constants.USLocalPhoneNumDigits;
  const localNumber = phoneOnlyDigits.substr(-1 * validPhoneLength, validPhoneLength);

  if (localNumber.length !== validPhoneLength) {
    return undefined;
  }

  return localNumber;
};
