import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { combineLatestWith } from 'rxjs';
import { map } from 'rxjs/operators';

import { PTableCol } from '../../../../../common/p-table/p-table-col';
import { CallListService } from '../../../../../services/call/call-list/call-list.service';
import { OrgDataService } from '../../../../../services/orgs/org-data/org-data.service';
import { UserDataService } from '../../../../../services/user/user-data/user-data.service';
import { SettingsEditorTableBase } from '../../../../generic/settings/abstract-classes/settings-editor-table-base';
import { CallListTableRow } from '../call-list-table-row/call-list-table-row';

@Component({
  selector: 'app-call-list-table-editor',
  templateUrl: './call-list-table-editor.component.html',
  styleUrls: ['./call-list-table-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CallListTableEditorComponent extends SettingsEditorTableBase<CallListTableRow> implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////

  cols: PTableCol[] = [
    { field: 'displayName', header: 'colDisplayName' },
    { field: 'description', header: 'colDescription' },
  ];

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private orgDataService: OrgDataService,
    private changeDetectorRef: ChangeDetectorRef,
    private userDataService: UserDataService,
    private callListService: CallListService,
  ) {
    super();
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  getData() {
    this.loading = true;
    this.tableRows = this.callListService.getDocsArray().pipe(
      combineLatestWith(this.orgDataService.getOrgData()),
      combineLatestWith(this.userDataService.getDocs()),
      map(([[callLists, orgData], userDataMap]) => {
        const tableRows: CallListTableRow[] = [];
        for (const callList of callLists) {
          const row = new CallListTableRow({ callList, userDataMap, orgData });
          tableRows.push(row);
        }
        this.loading = false;
        this.changeDetectorRef.detectChanges();
        return tableRows;
      }),
    );
  }
}
