import {DBTransactionType} from './db-transaction-type';
import {DBAction} from './actions/db-action';
import {DBDocObject} from '../../objects/generic/db-doc/db-doc-object';
import {DBUploadExistingDoc} from './actions/db-upload-existing-doc';

export class DBTransaction<T extends DBDocObject> {
  public dbTransactionType: DBTransactionType;
  public actions: DBAction<T>[] = [];

  ////////////////////////////////////////////////////////////////////////
  // Constructor
  ////////////////////////////////////////////////////////////////////////

  constructor(dbTransactionType: DBTransactionType, actions: DBAction<T>[]) {
    this.dbTransactionType = dbTransactionType;
    this.actions = actions;
  }

  /**
   * Return true if this is a simple update, and false otherwise.
   */
  public isSimpleUpdate() {
    if (this.actions.length !== 1) {
      return false;
    }
    if (!(this.actions[0] instanceof DBUploadExistingDoc)) {
      return false;
    }

    return true;
  }
}
