import type {ValidationResult} from 'joi';
import {cloneDeep} from 'lodash';

import {PossibleAdminRole} from '../../../../../objects/user/admin-roles/types';
import {PossibleRole} from '../../../../../objects/user/roles/types';
import {UserRequestName} from '../../../user-request-name';
import {UserRequest} from '../../generic/user-request/user-request';
import {UserRequestSchema} from '../../generic/user-request/user-request-schema';

import {UserRequestChangeOrgIdConstructor} from './user-request-change-org-id-constructor';
import {UserRequestChangeOrgIdSchema} from './user-request-change-org-id-schema';

export class UserRequestChangeOrgId extends UserRequest {
  protected requestedNewOrgId!: string;

  constructor(parameters: UserRequestChangeOrgIdConstructor) {
    super({
      ...parameters,
      [UserRequestSchema.type]: UserRequestName.changeOrgId,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  protected static _deserialize(validationResult: ValidationResult): UserRequestChangeOrgId {
    return new UserRequestChangeOrgId(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): UserRequestChangeOrgIdSchema {
    return new UserRequestChangeOrgIdSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(UserRequestChangeOrgId.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['orgAdmin'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return ['pwpAdmin'];
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getRequestedNewOrgId(): string {
    return cloneDeep(this.requestedNewOrgId);
  }
}
