/**
 * Here are the possible states that a call can be in:
 *
 * incoming -->
 *
 * 1. inProgressServiceNotSelected:         Client has not selected how to route their call.
 * 2. dialerInProgress:                     Client has decided how call should be routed. Currently looking for user to accept call.
 * 3. serviceDeliveryInProgress:            Client is presently speaking with a user.
 * 4. completed:                            Call is complete.
 */
export enum CallState {
  // Client has not selected how to route their call.
  inProgressServiceNotSelected = 'serviceNotSelected',

  // Client is on hold, and waiting to be connected
  dialerInProgress = 'dialerInProgress',

  // Client is currently connected to a user.
  serviceDeliveryInProgress = 'serviceDeliveryInProgress',

  // The call has completed
  completed = 'completed',
}
