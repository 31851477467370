import { isNil } from 'lodash';

import { AllDataEvent, displayTime, EventRequest, OrgData } from '@pwp-common';

import { EventRequestDecision } from '../../../common/event/event-request-decision';
import { RowCell } from '../../../common/p-table/row-cell';

export class RequestDecisionRow {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  id: string;

  isOpen: boolean;

  requestedOperation: RowCell<string>;

  requestTime: RowCell<Date>;

  requestStatus: RowCell<string>;

  requesterOrgNote: RowCell<string>;

  decision: EventRequestDecision | undefined;

  comment: string | undefined;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public request: EventRequest,
    allDataEvent: AllDataEvent,
    orgData: OrgData,
  ) {
    this.id = request.getId();
    this.setRequestedOperation(request, allDataEvent);
    this.setRequestTime(request, orgData);
    this.setRequestStatus(request);
    this.setRequesterOrgNote(request, allDataEvent);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Requested Operation
  //////////////////////////////////////////////////////////////////////////////////////////

  private setRequestedOperation(request: EventRequest, allDataEvent: AllDataEvent) {
    const eventData = allDataEvent.getEventData();

    if (isNil(eventData)) {
      this.requestedOperation = new RowCell<string>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellRequestedOperationDeletedEvent',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    const allDataUser = allDataEvent.getAllDataUserMap().get(request.getRequestedAssignedUserId());
    if (isNil(allDataUser)) {
      this.requestedOperation = new RowCell<string>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellRequestedOperationDeletedUser',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    const displayNameWithEmail = allDataUser.userData.getDisplayNameWithEmail();
    this.requestedOperation = new RowCell<string>({
      translationScope: 'event-requests-table-all',
      translationKey: 'cellRequestedOperationChangePrimary',
      translationObj: {
        displayNameWithEmail,
      },
      sortValue: displayNameWithEmail,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Summary
  //////////////////////////////////////////////////////////////////////////////////////////

  private setRequestTime(request: EventRequest, orgData: OrgData) {
    const sortValue = request.getCreateTime()?.toDate();
    const timestampStr = displayTime(request.getCreateTime(), orgData.getTimezone());
    this.requestTime = new RowCell<Date>({
      translationScope: 'event-requests-table-all',
      translationKey: 'cellRequestTime',
      translationObj: {
        timestampStr,
      },
      sortValue,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Status
  //////////////////////////////////////////////////////////////////////////////////////////

  private setRequestStatus(request: EventRequest) {
    this.isOpen = request.getIsOpen();
    if (request.getIsOpen()) {
      this.requestStatus = new RowCell<string>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellRequestStatusIsOpen',
        translationObj: {},
        sortValue: 'open',
        styleClass: 'statusBadge requestStatusOpen',
      });
      return;
    }

    if (request.getIsApproved() === true) {
      this.requestStatus = new RowCell<string>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellRequestStatusApproved',
        translationObj: {},
        sortValue: 'approved',
        styleClass: 'statusBadge requestStatusApproved',
      });
      return;
    }
    this.requestStatus = new RowCell<string>({
      translationScope: 'event-requests-table-all',
      translationKey: 'cellRequestStatusDenied',
      translationObj: {},
      sortValue: 'denied',
      styleClass: 'statusBadge requestStatusDenied',
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Requester Org note
  //////////////////////////////////////////////////////////////////////////////////////////

  private setRequesterOrgNote(request: EventRequest, allDataEvent: AllDataEvent) {
    const allDataUser = allDataEvent.getAllDataUserMap().get(request.getRequestedAssignedUserId());
    if (isNil(allDataUser)) {
      this.requesterOrgNote = new RowCell<string>({
        translationScope: 'event-requests-table-all',
        translationKey: 'cellRequesterOrgNoteDeletedUser',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    const orgNote = allDataUser.roles.getOrgNote();
    this.requesterOrgNote = new RowCell<string>({
      translationScope: 'event-requests-table-all',
      translationKey: 'cellRequesterOrgNote',
      translationObj: {
        orgNote,
      },
      sortValue: orgNote,
    });
  }
}
