import { cloneDeep, isNil } from 'lodash';

import { CommunicationTaskQueue } from '@pwp-common';

import { defaultTargetMode } from '../default-target-mode';
import { TargetModeEditorOutput } from '../target-mode-editor-output';

export const dbToTargetModeEditorOutput = (params: { taskQueue: CommunicationTaskQueue }): TargetModeEditorOutput => {
  /**
   * Handle empty input
   */
  if (isNil(params) || isNil(params.taskQueue)) {
    return cloneDeep(defaultTargetMode);
  }

  return {
    maxReservedWorkers: params.taskQueue.getMaxReservedWorkers(),
  };
};
