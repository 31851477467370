import { isUnsentMessage } from '../../message/helper/is-unsent-message';

import { getDisplayableTwilioMessage } from './get-displayable-twilio-message';
import { getDisplayableUnsentMessage } from './get-displayable-unsent-message';
import { CreateDisplayableMessageInput } from './interfaces';

export const createDisplayableMessage = async ({ message, ...params }: CreateDisplayableMessageInput) => {
  if (isUnsentMessage(message)) {
    return getDisplayableUnsentMessage({ ...params, message });
  }

  return getDisplayableTwilioMessage({ ...params, message });
};
