import type {AnySchema} from 'joi';

import {CommunicationSessionId} from '../../core/communication-session-id/communication-session-id';
import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {jsonStringSchema} from '../../generic/serialization/schema-field/json-string-schema/json-string-schema';
import {FormBuilderVendor} from '../form-builder-vendor';

export class FormSubmissionSchema extends DBDocSchema {
  public static readonly formVersionId = 'formVersionId';

  public static readonly sessionId = 'sessionId';

  public static readonly vendor = 'vendor';

  public static readonly value = 'value';

  public static readonly Constants = class {
    public static readonly collection = 'formSubmissions';
  };

  public static readonly Defaults = class {
    public static readonly formVersionId = undefined;
    public static readonly sessionId = undefined;
    public static readonly vendor = undefined;
    public static readonly value = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[FormSubmissionSchema.formVersionId] = SchemaField.autoGeneratedDocId(FormSubmissionSchema.Defaults.formVersionId);
    parameters[FormSubmissionSchema.sessionId] = SchemaField.obj(CommunicationSessionId, FormSubmissionSchema.Defaults.sessionId).required();
    parameters[FormSubmissionSchema.vendor] = SchemaField.string(FormSubmissionSchema.Defaults.vendor).valid(...Object.values(FormBuilderVendor));
    parameters[FormSubmissionSchema.value] = jsonStringSchema(FormSubmissionSchema.Defaults.value);

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${FormSubmissionSchema.Constants.collection}`;
  }
}
