import {CommunicationSessionEventLogConferenceSchema} from '../../generic/communication-session-event-log-conference/communication-session-event-log-conference-schema';
import {SchemaField} from '../../../../../../../generic/serialization/schema-field';

export class CommunicationSessionEventLogConferenceParticipantJoinSchema extends CommunicationSessionEventLogConferenceSchema {
  public static readonly callSid = 'callSid';
  public static readonly coaching = 'coaching';
  public static readonly endConferenceOnExit = 'endConferenceOnExit';
  public static readonly hold = 'hold';
  public static readonly muted = 'muted';
  public static readonly startConferenceOnEnter = 'startConferenceOnEnter';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    ///////////////////////////////////////////////////////
    // Generic InboundCallEvent Properties, Copy/Pasted Here
    ///////////////////////////////////////////////////////

    public static readonly type = undefined;
    public static readonly actor = undefined;
    public static readonly timestamp = undefined;

    ///////////////////////////////////////////////////////
    // CommunicationSessionEventLogConference Properties
    ///////////////////////////////////////////////////////

    public static readonly conferenceEventType = undefined;
    public static readonly conferenceSid = undefined;
    public static readonly eventTimestamp = undefined;
    public static readonly friendlyName = undefined;
    public static readonly sequenceNumber = undefined;

    ///////////////////////////////////////////////////////
    // Participant Join Properties
    ///////////////////////////////////////////////////////

    public static readonly callSid = undefined;
    public static readonly coaching = undefined;
    public static readonly endConferenceOnExit = undefined;
    public static readonly hold = undefined;
    public static readonly muted = undefined;
    public static readonly startConferenceOnEnter = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationSessionEventLogConferenceParticipantJoinSchema.callSid] = SchemaField.string(CommunicationSessionEventLogConferenceParticipantJoinSchema.Defaults.callSid).required();
    parameters[CommunicationSessionEventLogConferenceParticipantJoinSchema.coaching] = SchemaField.boolean(CommunicationSessionEventLogConferenceParticipantJoinSchema.Defaults.coaching).required();
    parameters[CommunicationSessionEventLogConferenceParticipantJoinSchema.endConferenceOnExit] = SchemaField.boolean(
      CommunicationSessionEventLogConferenceParticipantJoinSchema.Defaults.endConferenceOnExit
    ).required();
    parameters[CommunicationSessionEventLogConferenceParticipantJoinSchema.hold] = SchemaField.boolean(CommunicationSessionEventLogConferenceParticipantJoinSchema.Defaults.hold).required();
    parameters[CommunicationSessionEventLogConferenceParticipantJoinSchema.muted] = SchemaField.boolean(CommunicationSessionEventLogConferenceParticipantJoinSchema.Defaults.muted).required();
    parameters[CommunicationSessionEventLogConferenceParticipantJoinSchema.startConferenceOnEnter] = SchemaField.boolean(
      CommunicationSessionEventLogConferenceParticipantJoinSchema.Defaults.startConferenceOnEnter
    ).required();
    /* eslint-enable */

    return parameters;
  }
}
