import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';

import { defaultTargetEditorOutput } from '../../editor-output/target/default-target-editor-output';

@Component({
  selector: 'app-targets-editor',
  templateUrl: './targets-editor.component.html',
  styleUrls: ['./targets-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TargetsEditorComponent {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input
  /////////////////////////////////////////////////////////////////////////////////////////////
  @Input() form: UntypedFormGroup;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////

  defaultTarget = cloneDeep(defaultTargetEditorOutput);

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////
  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Array
  /////////////////////////////////////////////////////////////////////////////////////////////

  get targets() {
    return this.form.get('targets') as UntypedFormControl;
  }
}
