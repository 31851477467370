import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../generic/serialization/serializable-object-schema';
import {DBMatchRuleName} from '../../generic/db-match-rule-name';
import {DBMatchRule} from '../../generic/db-match-rule/db-match-rule';
import {DBMatchRuleSchema} from '../../generic/db-match-rule/db-match-rule-schema';
import {DBMatchRuleAnonymousPhoneConstructor} from './db-match-rule-anonymous-phone-constructor';
import {DBMatchRuleAnonymousPhoneSchema} from './db-match-rule-anonymous-phone-schema';

export class DBMatchRuleAnonymousPhone extends DBMatchRule {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected anonymousPhonesDigitsOnly!: string[];
  protected receivedAtE164Phones!: string[];
  protected forwardedFromE164Phones!: string[];
  protected x5us!: string[];
  protected origIds!: string[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: DBMatchRuleAnonymousPhoneConstructor) {
    (parameters as any)[DBMatchRuleSchema.type] = DBMatchRuleName.anonymousPhone;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): DBMatchRuleAnonymousPhone {
    return new DBMatchRuleAnonymousPhone(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(DBMatchRuleAnonymousPhone.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new DBMatchRuleAnonymousPhoneSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Query IDs
  /////////////////////////////////////////////////////////////////////////////

  public getQueryIds(): string[] {
    return [];
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getAnonymousPhonesDigitsOnly(): string[] {
    return cloneDeep(this.anonymousPhonesDigitsOnly);
  }

  public getReceivedAtE164Phones(): string[] {
    return cloneDeep(this.receivedAtE164Phones);
  }

  public getForwardedFromE164Phones(): string[] {
    return cloneDeep(this.forwardedFromE164Phones);
  }

  public getX5us(): string[] {
    return cloneDeep(this.x5us);
  }

  public getOrigIds(): string[] {
    return cloneDeep(this.origIds);
  }
}
