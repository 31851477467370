<ng-container *transloco="let t; read: 'dial-ongoing-call-dialog'">
  <ng-container *ngrxLet="callState$ as callState">
    <div *ngrxLet="listeningDuration$ | async as listeningDuration" class="dial-ongoing-call-dialog__content">
      <i
        class="dial-ongoing-call-dialog__content-icon pi"
        [ngClass]="{
          'pi-exclamation-triangle': callState === 'notInitiated' || callState === 'failed',
          'pi-phone': callState === 'connecting' || callState === 'connected',
          'dial-ongoing-call-dialog__content-icon--success': callState === 'connected',
          'pi-stop-circle': callState === 'terminated',
          'dial-ongoing-call-dialog__content-icon--error': callState === 'failed'
        }"
      ></i>

      <span>{{ t('body.' + callState) }}</span>

      <ng-container *ngIf="listeningDuration">
        <span *ngIf="callState === 'terminated'">{{ t('listeningDurationPrefix') }}</span> {{ listeningDuration }}
      </ng-container>
    </div>

    <div class="dial-ongoing-call-dialog__actions">
      <ng-container *ngIf="callState === 'notInitiated'; else callActions">
        <button pButton class="p-button-text" type="button" (click)="close()">{{ t('negateJoinButtonLabel') }}</button>
        <button pButton type="button" (click)="dial()">{{ t('confirmJoinButtonLabel') }}</button>
      </ng-container>

      <ng-template #callActions>
        <button pButton class="p-button-text" type="button" (click)="close()">{{ t('disconnectButtonLabel') }}</button>
      </ng-template>
    </div>
  </ng-container>
</ng-container>
