import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, USE_EMULATOR } from '@angular/fire/compat/functions';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DialogModule } from '@ngneat/dialog';
import { LetModule } from '@ngrx/component';
import { PlotlyModule } from 'angular-plotly.js';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import * as PlotlyJS from 'plotly.js-dist-min';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { BadgeModule } from 'primeng/badge';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule as PrimeNgDialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { ProgressSpinnerModule as PrimeProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TimelineModule } from 'primeng/timeline';
import { ToastModule } from 'primeng/toast';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { routing } from './app.routes';
import { AdminBurnoutDashboardComponent } from './components/analytics/admin-burnout-dashboard/admin-burnout-dashboard/admin-burnout-dashboard.component';
import { CallLoadOverviewComponent } from './components/analytics/admin-burnout-dashboard/call-load-overview/call-load-overview.component';
import { EventLoadOverviewComponent } from './components/analytics/admin-burnout-dashboard/event-load-overview/event-load-overview.component';
import { UserLoadTableComponent } from './components/analytics/admin-burnout-dashboard/user-load-table/user-load-table.component';
import { AdminHoursDashboardComponent } from './components/analytics/admin-hours-dashboard/admin-hours-dashboard/admin-hours-dashboard.component';
import { EventHoursTableComponent } from './components/analytics/admin-hours-dashboard/event-hours-table/event-hours-table.component';
import { AdminOverviewDashboardComponent } from './components/analytics/admin-overview-dashboard/admin-overview-dashboard/admin-overview-dashboard.component';
import { CallsByStatusComponent } from './components/analytics/admin-overview-dashboard/calls-by-status/calls-by-status.component';
import { CallsByTypeComponent } from './components/analytics/admin-overview-dashboard/calls-by-type/calls-by-type.component';
import { CallsFunnelComponent } from './components/analytics/admin-overview-dashboard/calls-funnel/calls-funnel.component';
import { CallsOverTimeComponent } from './components/analytics/admin-overview-dashboard/calls-over-time/calls-over-time.component';
import { AdminReportsModule } from './components/analytics/admin-reports/admin-reports.module';
import { AdminUsageDashboardComponent } from './components/analytics/admin-usage-dashboard/admin-usage-dashboard/admin-usage-dashboard.component';
import { AllUsageComponent } from './components/analytics/admin-usage-dashboard/all-usage/all-usage.component';
import { AnalyticsDashboardComponent } from './components/analytics/generic/analytics-dashboard/analytics-dashboard.component';
import { AnalyticsTableComponent } from './components/analytics/generic/analytics-table/analytics-table.component';
import { HoursAndCallsComponent } from './components/analytics/hours-and-calls/hours-and-calls.component';
import { CallModule } from './components/call/call.module';
import { CommunicationLogsTableComponent } from './components/communication-logs/communication-logs-table/communication-logs-table.component';
import { CallEntrypointsConfigSelectAndEditComponent } from './components/config/call-entrypoints-config-select-and-edit/call-entrypoints-config-select-and-edit.component';
import { CallListSelectAndEditComponent } from './components/config/call-list/call-list-select-and-edit/call-list-select-and-edit.component';
import { CalleeEntityEditorComponent } from './components/config/call-list/callee-entity-editor/callee-entity-editor.component';
import { CalleeEntitySelectAndEditComponent } from './components/config/call-list/callee-entity-select-and-edit/callee-entity-select-and-edit.component';
import { CallerIdSelectAndEditComponent } from './components/config/caller-id-select-and-edit/caller-id-select-and-edit.component';
import { CommunicationWidgetCreateAsyncServiceRequestSelectAndEditComponent } from './components/config/communication-widget-create-async-service-request-select-and-edit/communication-widget-create-async-service-request-select-and-edit.component';
import { ConversationConfigSelectAndEditComponent } from './components/config/conversation-config/conversation-config-select-and-edit/conversation-config-select-and-edit.component';
import { ConversationWaitingRoomConfigComponent } from './components/config/conversation-config/conversation-waiting-room-config/conversation-waiting-room-config.component';
import { EmailTemplateConfigSelectAndEditComponent } from './components/config/email-template-config-select-and-edit/email-template-config-select-and-edit.component';
import { EventConfigSelectAndEditComponent } from './components/config/event-config-select-and-edit/event-config-select-and-edit.component';
import { IvrOptionEditorComponent } from './components/config/ivr/ivr-option-editor/ivr-option-editor.component';
import { IvrOptionSelectAndEditComponent } from './components/config/ivr/ivr-option-select-and-edit/ivr-option-select-and-edit.component';
import { IvrSelectAndEditComponent } from './components/config/ivr/ivr-select-and-edit/ivr-select-and-edit.component';
import { PhrasesLangPairEditorComponent } from './components/config/ivr/phrases-lang-pair-editor/phrases-lang-pair-editor.component';
import { PhrasesLangPairSelectAndEditComponent } from './components/config/ivr/phrases-lang-pair-select-and-edit/phrases-lang-pair-select-and-edit.component';
import { MessageTemplateRruleEditorComponent } from './components/config/message-template/message-template-rrule-editor/message-template-rrule-editor.component';
import { MessageTemplateSelectAndEditComponent } from './components/config/message-template/message-template-select-and-edit/message-template-select-and-edit.component';
import { TemplateTextEditorComponent } from './components/config/message-template/template-text-editor/template-text-editor.component';
import { TemplateTextLangPairEditorComponent } from './components/config/message-template/template-text-lang-pair-editor/template-text-lang-pair-editor.component';
import { ObjIsValidComponent } from './components/config/obj-is-valid/obj-is-valid.component';
import { OrgDataSelectAndEditComponent } from './components/config/org-data-select-and-edit/org-data-select-and-edit.component';
import { OrgIdChangeComponent } from './components/config/org-id-change/org-id-change.component';
import { AnyVrcEditorComponent } from './components/config/voice-command/any-vrc-editor/any-vrc-editor.component';
import { VrcEditorCreateAsyncServiceRequestComponent } from './components/config/voice-command/vrc-editor-create-async-service-request/vrc-editor-create-async-service-request.component';
import { VrcEditorDialCallListComponent } from './components/config/voice-command/vrc-editor-dial-call-list/vrc-editor-dial-call-list.component';
import { VrcEditorEnforceBlockedCallerComponent } from './components/config/voice-command/vrc-editor-enforce-blocked-caller/vrc-editor-enforce-blocked-caller.component';
import { VrcEditorExecIvrComponent } from './components/config/voice-command/vrc-editor-exec-ivr/vrc-editor-exec-ivr.component';
import { VrcEditorForwardComponent } from './components/config/voice-command/vrc-editor-forward/vrc-editor-forward.component';
import { VrcEditorPlayOrSayComponent } from './components/config/voice-command/vrc-editor-play-or-say/vrc-editor-play-or-say.component';
import { VrcEditorSetContextComponent } from './components/config/voice-command/vrc-editor-set-context/vrc-editor-set-context.component';
import { SwitchCaseSelectAndEditComponent } from './components/config/voice-command/vrc-editor-switch/switch-case-select-and-edit/switch-case-select-and-edit.component';
import { VrcEditorSwitchComponent } from './components/config/voice-command/vrc-editor-switch/vrc-editor-switch/vrc-editor-switch.component';
import { VrcEditorSwitchCaseComponent } from './components/config/voice-command/vrc-editor-switch/vrc-editor-switch-case/vrc-editor-switch-case.component';
import { VrcEditorTakeVoicemailComponent } from './components/config/voice-command/vrc-editor-take-voicemail/vrc-editor-take-voicemail.component';
import { VrcListEditorComponent } from './components/config/voice-command/vrc-list-editor/vrc-list-editor.component';
import { VoicemailConfigSelectAndEditComponent } from './components/config/voicemail-config-select-and-edit/voicemail-config-select-and-edit.component';
import { FileSsmlAudioPairEditorComponent } from './components/config/vrc-audio-metadata/file-ssml-audio-pair-editor/file-ssml-audio-pair-editor.component';
import { FileSsmlAudioSelectAndEditComponent } from './components/config/vrc-audio-metadata/file-ssml-audio-select-and-edit/file-ssml-audio-select-and-edit.component';
import { VrcAudioMetadataSelectAndEditComponent } from './components/config/vrc-audio-metadata/vrc-audio-metadata-select-and-edit/vrc-audio-metadata-select-and-edit.component';
import { ConversationConfigEditorComponent } from './components/conversation/conversation-config-editor/conversation-config-editor.component';
import { ConversationGettingStartedComponent } from './components/conversation/conversation-getting-started/conversation-getting-started.component';
import { ConversationInfoDialogComponent } from './components/conversation/conversation-info-dialog/conversation-info-dialog.component';
import { ConversationLogsQueryEditorComponent } from './components/conversation/conversation-logs-query-editor/conversation-logs-query-editor.component';
import { ConversationMessageTemplateEditorFirstSendComponent } from './components/conversation/conversation-message-templates/conversation-message-template-editor-first-send/conversation-message-template-editor-first-send.component';
import { ConversationMessageTemplateEditorOnInactiveComponent } from './components/conversation/conversation-message-templates/conversation-message-template-editor-on-inactive/conversation-message-template-editor-on-inactive.component';
import { ConversationMessageTemplateEditorServiceClosedComponent } from './components/conversation/conversation-message-templates/conversation-message-template-editor-service-closed/conversation-message-template-editor-service-closed.component';
import { DistinguishedConversationMessageTemplateEditorComponent } from './components/conversation/conversation-message-templates/distinguished-conversation-message-template-editor/distinguished-conversation-message-template-editor.component';
import { ConversationSelectAndEditComponent } from './components/conversation/conversation-select-and-edit/conversation-select-and-edit.component';
import { ConversationSettingsComponent } from './components/conversation/conversation-settings/conversation-settings.component';
import { ConversationsFeatureNotEnabledComponent } from './components/conversation/conversations-feature-not-enabled/conversations-feature-not-enabled.component';
import { ConversationEndedDialogComponent } from './components/conversation/incoming-chat/conversation-ended-dialog/conversation-ended-dialog.component';
import { IncomingChatWaitingRoomComponent } from './components/conversation/incoming-chat/incoming-chat-waiting-room/incoming-chat-waiting-room.component';
import { ArrayOfPhonesEditorModule } from './components/core/array-of-phones-editor/array-of-phones-editor.module';
import { AudioPlayerModule } from './components/core/audio-player/audio-player.module';
import { CallListModule } from './components/core/call-list/call-list.module';
import { CommunicationWorkflowModule } from './components/core/communication-workflow/communication-workflow.module';
import { DateRangeSelectModule } from './components/core/date-range-select/date-range-select.module';
import { EditAvailableHoursByDowComponent } from './components/core/edit-available-hours-by-dow/edit-available-hours-by-dow.component';
import { EventRequestConfigModule } from './components/core/event-request-config/event-request-config.module';
import { ProgressSpinnerModule } from './components/core/progress-spinner/progress-spinner.module';
import { ServiceExceptionModule } from './components/core/service-exception/service-exception.module';
import { ServiceOperationEditorModule } from './components/core/service-operation-editor/service-operation-editor.module';
import { TtlEditorComponent } from './components/core/ttl-editor/ttl-editor.component';
import { ConfirmedEventsTableComponent } from './components/event/confirmed-events-table/confirmed-events-table.component';
import { EventsCalendarComponent } from './components/event/events-calendar/events-calendar.component';
import { ColorDropdownComponent } from './components/event/modify-schedule/color-dropdown/color-dropdown.component';
import { EditEventPropertiesDialogComponent } from './components/event/modify-schedule/edit-event-properties-dialog/edit-event-properties-dialog.component';
import { EventCreateDialogComponent } from './components/event/modify-schedule/event-create-dialog/event-create-dialog.component';
import { EventRepeatComponent } from './components/event/modify-schedule/event-repeat/event-repeat.component';
import { EventSplitDialogComponent } from './components/event/modify-schedule/event-split-dialog/event-split-dialog.component';
import { EventSurgeryTableComponent } from './components/event/modify-schedule/event-surgery-table/event-surgery-table.component';
import { RepeatScheduleDialogComponent } from './components/event/modify-schedule/repeat-schedule-dialog/repeat-schedule-dialog.component';
import { RruleGeneratorComponent } from './components/event/rrule-generator/rrule-generator.component';
import { EventRequestsQueryEditorComponent } from './components/event-requests/event-requests-query-editor/event-requests-query-editor.component';
import { EventRequestsTableAllComponent } from './components/event-requests/event-requests-table-all/event-requests-table-all.component';
import { MakeEventRequestTableComponent } from './components/event-requests/make-event-request-table/make-event-request-table.component';
import { FormTableEditorComponent } from './components/form/form-table-editor/form-table-editor.component';
import { ConfirmWithInputComponent } from './components/generic/confirm-with-input/confirm-with-input.component';
import { DataTableModule } from './components/generic/data-table/data-table.module';
import { LoadingOverlayComponent } from './components/generic/loading-overlay/loading-overlay.component';
import { LoadingModule } from './components/generic/loading.module';
import { ObjAutocompleteComponent } from './components/generic/obj-autocomplete/obj-autocomplete.component';
import { ObjAutocompleteV2Module } from './components/generic/obj-autocomplete-v2/obj-autocomplete-v2.module';
import { ObjDebugViewComponent } from './components/generic/obj-debug-view/obj-debug-view.component';
import { ObjMultiSelectComponent } from './components/generic/obj-multi-select/obj-multi-select.component';
import { ObjSelectComponent } from './components/generic/obj-select/obj-select.component';
import { ObjSelectAndEditComponent } from './components/generic/obj-select-and-edit/obj-select-and-edit.component';
import { SequenceModule } from './components/generic/sequence/sequence.module';
import { SettingsModule } from './components/generic/settings/settings.module';
import { WizardModule } from './components/generic/wizard/wizard.module';
import { InboxModule } from './components/inbox/inbox.module';
import { LogoutComponent } from './components/logout/logout.component';
import { MessageTemplateSubscribeComponent } from './components/message-template/message-template-subscribe/message-template-subscribe.component';
import { OrgSettingsCallListComponent } from './components/org-settings/org-settings-call-list/org-settings-call-list.component';
import { OrgSettingsCommunicationWorkflowComponent } from './components/org-settings/org-settings-communication-workflow/org-settings-communication-workflow.component';
import { OrgSettingsEnforceServiceLimitComponent } from './components/org-settings/org-settings-enforce-service-limit/org-settings-enforce-service-limit.component';
import { OrgSettingsEventRequestConfigComponent } from './components/org-settings/org-settings-event-request-config/org-settings-event-request-config.component';
import { ReferralComponent } from './components/referral/referral.component';
import { BetaIndicatorComponent } from './components/shared/beta-indicator/beta-indicator.component';
import { SupportTicketComponent } from './components/support/support-ticket/support-ticket.component';
import { ChangeMyPrimaryEmailComponent } from './components/user/profile/change-my-primary-email/change-my-primary-email.component';
import { MyUserProfileEditorComponent } from './components/user/profile/my-user-profile-editor/my-user-profile-editor.component';
import { AllDataUserEditorComponent } from './components/user/user-admin-options/all-data-user-editor/all-data-user-editor.component';
import { ModifyUserOptionsTableComponent } from './components/user/user-admin-options/modify-user-options-table/modify-user-options-table.component';
import { SharedDirectiveModule } from './directives/shared-directive.module';
import { AdminAnalyticsComponent } from './pages/admin-analytics/admin-analytics.component';
import { ChatComponent } from './pages/chat/chat.component';
import { EventRequestsComponent } from './pages/event-requests/event-requests.component';
import { EventSelectorComponent } from './pages/event-selector/event-selector.component';
import { InboxComponent } from './pages/inbox/inbox.component';
import { IncomingChatComponent } from './pages/incoming-chat/incoming-chat.component';
import { LoginComponent } from './pages/login/login.component';
import { ModifyScheduleComponent } from './pages/modify-schedule/modify-schedule.component';
import { OrgSettingsComponent } from './pages/org-settings/org-settings.component';
import { ServiceExceptionsComponent } from './pages/service-exceptions/service-exceptions.component';
import { SupportComponent } from './pages/support/support.component';
import { UserProfileComponent } from './pages/user-profile/user-profile.component';
import { UsersAdminComponent } from './pages/users-admin/users-admin.component';
import { CorePipesModule } from './pipes/core-pipes/core-pipes.module';
import { TranslocoRootModule } from './transloco/transloco-root.module';

PlotlyModule.plotlyjs = PlotlyJS;

//////////////////////////////////////////////////////////
// Constants
//////////////////////////////////////////////////////////

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AdminAnalyticsComponent,
    AdminBurnoutDashboardComponent,
    AdminHoursDashboardComponent,
    AdminOverviewDashboardComponent,
    AdminUsageDashboardComponent,
    AllDataUserEditorComponent,
    AllUsageComponent,
    AnalyticsDashboardComponent,
    AnalyticsTableComponent,
    AnyVrcEditorComponent,
    AppComponent,
    CalleeEntityEditorComponent,
    CalleeEntitySelectAndEditComponent,
    CallEntrypointsConfigSelectAndEditComponent,
    CallerIdSelectAndEditComponent,
    CallListSelectAndEditComponent,
    CallLoadOverviewComponent,
    CallsByStatusComponent,
    CallsByTypeComponent,
    CallsFunnelComponent,
    CallsOverTimeComponent,
    ChangeMyPrimaryEmailComponent,
    ChatComponent,
    ColorDropdownComponent,
    CommunicationWidgetCreateAsyncServiceRequestSelectAndEditComponent,
    ConfirmedEventsTableComponent,
    ConfirmWithInputComponent,
    ConversationConfigEditorComponent,
    ConversationConfigSelectAndEditComponent,
    ConversationEndedDialogComponent,
    ConversationInfoDialogComponent,
    ConversationLogsQueryEditorComponent,
    ConversationMessageTemplateEditorFirstSendComponent,
    ConversationMessageTemplateEditorOnInactiveComponent,
    ConversationMessageTemplateEditorServiceClosedComponent,
    ConversationSelectAndEditComponent,
    ConversationSettingsComponent,
    ConversationsFeatureNotEnabledComponent,
    ConversationWaitingRoomConfigComponent,
    DistinguishedConversationMessageTemplateEditorComponent,
    EditAvailableHoursByDowComponent,
    EditEventPropertiesDialogComponent,
    EmailTemplateConfigSelectAndEditComponent,
    EventConfigSelectAndEditComponent,
    EventCreateDialogComponent,
    EventHoursTableComponent,
    EventLoadOverviewComponent,
    EventRepeatComponent,
    EventRequestsComponent,
    EventRequestsQueryEditorComponent,
    EventSelectorComponent,
    EventSplitDialogComponent,
    FileSsmlAudioPairEditorComponent,
    FileSsmlAudioSelectAndEditComponent,
    HoursAndCallsComponent,
    InboxComponent,
    IncomingChatWaitingRoomComponent,
    IvrOptionEditorComponent,
    IvrOptionSelectAndEditComponent,
    IvrSelectAndEditComponent,
    LoadingOverlayComponent,
    LoginComponent,
    LogoutComponent,
    MessageTemplateRruleEditorComponent,
    MessageTemplateSelectAndEditComponent,
    MessageTemplateSubscribeComponent,
    ModifyScheduleComponent,
    ModifyUserOptionsTableComponent,
    MyUserProfileEditorComponent,
    ObjAutocompleteComponent,
    ObjDebugViewComponent,
    ObjIsValidComponent,
    ObjMultiSelectComponent,
    ObjSelectAndEditComponent,
    ObjSelectComponent,
    OrgDataSelectAndEditComponent,
    OrgIdChangeComponent,
    OrgSettingsCallListComponent,
    OrgSettingsCommunicationWorkflowComponent,
    OrgSettingsComponent,
    OrgSettingsEnforceServiceLimitComponent,
    OrgSettingsEventRequestConfigComponent,
    PhrasesLangPairEditorComponent,
    PhrasesLangPairSelectAndEditComponent,
    ReferralComponent,
    RepeatScheduleDialogComponent,
    RruleGeneratorComponent,
    ServiceExceptionsComponent,
    SupportComponent,
    SupportTicketComponent,
    SwitchCaseSelectAndEditComponent,
    TemplateTextEditorComponent,
    TemplateTextLangPairEditorComponent,
    TtlEditorComponent,
    UserLoadTableComponent,
    UserProfileComponent,
    UsersAdminComponent,
    VoicemailConfigSelectAndEditComponent,
    VrcAudioMetadataSelectAndEditComponent,
    VrcEditorCreateAsyncServiceRequestComponent,
    VrcEditorDialCallListComponent,
    VrcEditorEnforceBlockedCallerComponent,
    VrcEditorExecIvrComponent,
    VrcEditorForwardComponent,
    VrcEditorPlayOrSayComponent,
    VrcEditorSetContextComponent,
    VrcEditorSwitchCaseComponent,
    VrcEditorSwitchComponent,
    VrcEditorTakeVoicemailComponent,
    VrcListEditorComponent,
  ],
  imports: [
    AdminReportsModule,
    AngularFireAuthModule,
    AngularFireFunctionsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireStorageModule,
    AngularFirestoreModule,
    ArrayOfPhonesEditorModule,
    AudioPlayerModule,
    AutoCompleteModule,
    AvatarGroupModule,
    AvatarModule,
    BadgeModule,
    BetaIndicatorComponent,
    BrowserAnimationsModule,
    BrowserModule,
    ButtonModule,
    CalendarModule,
    CallListModule,
    CallModule,
    CardModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    ClipboardModule,
    CommonModule,
    CommunicationLogsTableComponent,
    CommunicationWorkflowModule,
    ConfirmDialogModule,
    ConversationGettingStartedComponent,
    CorePipesModule,
    DataTableModule,
    DateRangeSelectModule,
    DropdownModule,
    DialogModule.forRoot(),
    DynamicDialogModule,
    EventRequestConfigModule,
    EventRequestsTableAllComponent,
    EventsCalendarComponent,
    EventSurgeryTableComponent,
    FileUploadModule,
    FormsModule,
    FormTableEditorComponent,
    FullCalendarModule,
    InboxModule,
    IncomingChatComponent,
    InputMaskModule,
    InputNumberModule,
    InputSwitchModule,
    InputTextareaModule,
    InputTextModule,
    KeyFilterModule,
    LetModule,
    LoadingModule,
    MakeEventRequestTableComponent,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatMomentDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatStepperModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MultiSelectModule,
    NgxJsonViewerModule,
    ObjAutocompleteV2Module,
    PanelModule,
    PickListModule,
    PlotlyModule,
    PrimeNgDialogModule,
    PrimeProgressSpinnerModule,
    ProgressBarModule,
    ProgressSpinnerModule,
    RadioButtonModule,
    ReactiveFormsModule,
    routing,
    ScrollPanelModule,
    SelectButtonModule,
    SequenceModule,
    ServiceExceptionModule,
    ServiceOperationEditorModule,
    SettingsModule,
    SharedDirectiveModule,
    SplitterModule,
    TableModule,
    TabViewModule,
    TimelineModule,
    ToastModule,
    ToolbarModule,
    TooltipModule,
    TranslocoRootModule,
    WizardModule,
  ],
  providers: [{ provide: USE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5000] : undefined }],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
