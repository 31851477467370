export enum CallerIdStrategyType {
  /**
   * If the communication was received at some phone number
   * then use that number as the outgoing caller-id in calls offering offering that
   * communication to a user
   **/
  receivedAt = 'receivedAt',
  /**
   * Use an explicitly specified caller-id as the outgoing caller-id in
   * calls offering offering a communication to a user
   **/
  explicit = 'explicit',
  /**
   * Forward the incoming caller-id as the outgoing caller-id in
   * calls offering offering a communication to a user
   **/
  forward = 'forward',
}
