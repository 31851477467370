import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../generic/serialization/schema-field';

export class ConversationMessageDigestSchema extends SerializableObjectSchema {
  public static readonly timestamp = 'timestamp';
  public static readonly index = 'index';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly timestamp = undefined;
    public static readonly index = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ConversationMessageDigestSchema.timestamp] = SchemaField.timestamp(ConversationMessageDigestSchema.Defaults.timestamp).required();
    parameters[ConversationMessageDigestSchema.index] = SchemaField.number(ConversationMessageDigestSchema.Defaults.index).required();
    /* eslint-enable */

    return parameters;
  }
}
