<ng-container *transloco="let t; read: 'edit-available-hours-by-dow'">
  <form *ngIf="!loading" [formGroup]="form">
    <legend>{{ t('title') }}</legend>
    <p>{{ description }}</p>
    <div *ngFor="let isoWeekday of isoWeekdays" class="field grid">
      <!-- Select Available/Not Available/Custom -->
      <div class="col">
        <label class="col-fixed" for="timeRangeType{{ isoWeekday }}" style="width: 100px">{{ t(isoWeekday.toString()) }}</label>
        <p-dropdown
          appendTo="body"
          ariaLabel="{{ t(isoWeekday.toString()) }}"
          class="inputfield"
          formControlName="timeRangeType{{ isoWeekday }}"
          id="timeRangeType{{ isoWeekday }}"
          [options]="[
            { label: t('alwaysAvailable'), value: 'alwaysAvailable' },
            { label: t('neverAvailable'), value: 'neverAvailable' },
            { label: t('sometimesAvailable'), value: 'sometimesAvailable' }
          ]"
        >
        </p-dropdown>
      </div>

      <!-- Specify Custom Available Hours -->
      <label *ngIf="isSometimesAvailable(isoWeekday)" class="col-fixed" for="timeRange{{ isoWeekday }}" style="width: 200px">{{
        t('availableTimes')
      }}</label>
      <div *ngIf="isSometimesAvailable(isoWeekday)" class="col">
        <!-- Time Range in 24h Format -->
        <p-inputMask
          placeholder="09:00 - 17:00"
          ariaLabel="{{ t('timeRange' + isoWeekday) }}"
          class="inputfield"
          formControlName="timeRange{{ isoWeekday }}"
          id="timeRange{{ isoWeekday }}"
          mask="99:99 - 99:99"
          [characterPattern]="timeRangeRegex"
        >
        </p-inputMask>

        <!-- Error Message -->
        <p-messages *ngIf="isInvalidTimeRange(isoWeekday)" severity="error">
          <ng-template pTemplate>
            {{ t('invalidTimeRange') }}
          </ng-template>
        </p-messages>
      </div>
    </div>
  </form>
</ng-container>
