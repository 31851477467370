import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {ConversationResponse} from '../../generic/conversation-response/conversation-response';
import {ConversationResponseSchema} from '../../generic/conversation-response/conversation-response-schema';
import {ConversationResponseGetJwtConstructor} from './conversation-response-get-jwt-constructor';
import {ConversationResponseGetJwtSchema} from './conversation-response-get-jwt-schema';
import {cloneDeep} from 'lodash';
import {ConversationResponseGetJwtError} from './conversation-response-get-jwt-error';
import {ConversationRequestName} from '../../../conversation-request-name';

export class ConversationResponseGetJwt extends ConversationResponse<ConversationResponseGetJwtError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected jwt: string | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationResponseGetJwtConstructor) {
    (parameters as any)[ConversationResponseSchema.type] = ConversationRequestName.getJWT;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationResponseGetJwt {
    return new ConversationResponseGetJwt(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationResponseGetJwt.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationResponseGetJwtSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getJwt(): string | undefined {
    return cloneDeep(this.jwt);
  }
}
