import {cloneDeep} from 'lodash';
import {EventRequestTransactionItemConstructor} from './event-request-transaction-item-constructor';
import {EventRequestTransactionItemSchema} from './event-request-transaction-item-schema';
import {SerializableObject} from '../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {AssignedUserType} from '../event-data/enums';
import {EventRequestTransactionItemType} from './event-request-transaction-item-type';

export class EventRequestTransactionItem extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected type!: EventRequestTransactionItemType;
  protected eventId!: string;
  protected requested!: AssignedUserType;
  protected eventRequestId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EventRequestTransactionItemConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EventRequestTransactionItem {
    return new EventRequestTransactionItem(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////
  public serialize() {
    return super.serialize(EventRequestTransactionItem.getSchema());
  }
  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new EventRequestTransactionItemSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
  public getType() {
    return cloneDeep(this.type);
  }

  public getEventId() {
    return cloneDeep(this.eventId);
  }

  public getRequested() {
    return cloneDeep(this.requested);
  }

  public getEventRequestId() {
    return cloneDeep(this.eventRequestId);
  }
}
