import {CalleeEntity} from '../../call/callee-entity/callee-entity';
import {SchemaField} from '../../generic/serialization/schema-field';
import {TemplateText} from '../template-text/template-text';
import {MessageDeliveryChannel} from './channel';
import {GenericDisplayableSchema} from '../../generic/displayable/generic-displayable-schema';

/**
 *
 */
export class MessageTemplateSchema extends GenericDisplayableSchema {
  /**
   * Content: Used to render template, eventTypes used to determine
   * context
   */
  public static readonly context = 'context';
  public static readonly eventTypes = 'eventTypes';

  /**
   * Content: The actual text body (used for all channels) and the
   * subject (used if applicable to delivery channel).
   */
  public static readonly subject = 'subject';
  public static readonly body = 'body';

  /**
   * Target: Determine who receives this message.
   * cc, bcc and replyTo are used for email channel
   *
   * subscribedUserIds: Users can subscribe to recieve a message of this template.
   * assignedUsers: Users can be assigned to receive messages of this template based on assignment to a schedule
   */
  public static readonly assignedUsers = 'assignedUsers';
  public static readonly subscribedUserIds = 'subscribedUserIds';
  public static readonly cc = 'cc';
  public static readonly bcc = 'bcc';
  public static readonly replyTo = 'replyTo';

  /**
   * When: Info on when to send messages that are periodic.
   */
  public static readonly sendRRule = 'sendRRule';
  public static readonly sendEnabled = 'sendEnabled';

  // Channel used to deliver message
  public static readonly messageDeliveryChannel = 'messageDeliveryChannel';

  public static readonly callLists = 'callLists';
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'messageTemplates';
    public static readonly sgTemplateId = 'd-01a3b58d6dd942e582f7ffb9631de104';
  };

  static Defaults = class {
    // Generic
    public static readonly displayName = 'missing-displayName';
    public static readonly description = 'missing-description';

    // Context
    public static readonly context = undefined;
    public static readonly eventTypes = [];

    // Content
    public static readonly subject = TemplateText.deserialize({});
    public static readonly body = TemplateText.deserialize({});

    // Target
    public static readonly assignedUsers = [];
    public static readonly subscribedUserIds = [];
    public static readonly cc = [];
    public static readonly bcc = [];
    public static readonly replyTo = '';

    // When
    public static readonly sendRRule = '';
    public static readonly sendEnabled = false;

    // Other
    public static readonly messageDeliveryChannel = MessageDeliveryChannel.email;
    public static readonly callLists = [];
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values to Serialize
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([
      // Required to enable resetting these fields
      MessageTemplateSchema.cc,
      MessageTemplateSchema.bcc,
      MessageTemplateSchema.messageDeliveryChannel,
      MessageTemplateSchema.subscribedUserIds,
      MessageTemplateSchema.eventTypes,
      MessageTemplateSchema.subject,
      MessageTemplateSchema.body,
      MessageTemplateSchema.sendEnabled,
    ]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    // Context
    parameters[MessageTemplateSchema.context] = SchemaField.string(MessageTemplateSchema.Defaults.context);
    parameters[MessageTemplateSchema.eventTypes] = SchemaField.arrayOfType(SchemaField.sanitizedString(), MessageTemplateSchema.Defaults.eventTypes);

    // Content
    parameters[MessageTemplateSchema.subject] = SchemaField.obj(TemplateText, MessageTemplateSchema.Defaults.subject);
    parameters[MessageTemplateSchema.body] = SchemaField.obj(TemplateText, MessageTemplateSchema.Defaults.body);

    // Target
    parameters[MessageTemplateSchema.assignedUsers] = SchemaField.shortObjArray(CalleeEntity, MessageTemplateSchema.Defaults.assignedUsers);
    parameters[MessageTemplateSchema.subscribedUserIds] = SchemaField.arrayOfType(SchemaField.sanitizedString(), MessageTemplateSchema.Defaults.subscribedUserIds);
    parameters[MessageTemplateSchema.cc] = SchemaField.arrayOfType(SchemaField.sanitizedString(), MessageTemplateSchema.Defaults.cc);
    parameters[MessageTemplateSchema.bcc] = SchemaField.arrayOfType(SchemaField.sanitizedString(), MessageTemplateSchema.Defaults.bcc);
    parameters[MessageTemplateSchema.replyTo] = SchemaField.string(MessageTemplateSchema.Defaults.replyTo);

    // When
    parameters[MessageTemplateSchema.sendRRule] = SchemaField.string(MessageTemplateSchema.Defaults.sendRRule);
    parameters[MessageTemplateSchema.sendEnabled] = SchemaField.boolean(MessageTemplateSchema.Defaults.sendEnabled);

    // Other
    parameters[MessageTemplateSchema.messageDeliveryChannel] = SchemaField.string(MessageTemplateSchema.Defaults.messageDeliveryChannel);
    parameters[MessageTemplateSchema.callLists] = SchemaField.arrayOfType(SchemaField.sanitizedString(), MessageTemplateSchema.Defaults.callLists).optional();
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${MessageTemplateSchema.Constants.collection}`;
  }
}
