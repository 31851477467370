import {AllDataUser} from '../all-data-user/all-data-user';
import {UserData} from './user-data';

/**
 * Return the display name associated to a given userId.
 * @param dataMap Map giving userData associated to each userId
 * @param userId Return display name of this user
 */
export const getDisplayName = (dataMap: Map<string, UserData | AllDataUser>, userId: string | undefined): string => {
  if (userId === undefined) {
    return '';
  }

  const obj = dataMap.get(userId);

  if (obj instanceof UserData) {
    return obj.getDisplayName();
  }

  if (obj instanceof AllDataUser) {
    return obj.userData.getDisplayName();
  }

  return userId;
};

/**
 * Return the email associated to a given userId.
 * @param objMap Map giving userData associated to each userId
 * @param userId Return email of this user
 */
export const getEmail = (objMap: Map<string, UserData | AllDataUser>, userId: string | undefined): string => {
  if (userId === undefined) {
    return '';
  }

  const obj = objMap.get(userId);

  if (obj instanceof UserData) {
    return obj.getNotificationEmail();
  }

  if (obj instanceof AllDataUser) {
    return obj.userData.getNotificationEmail();
  }

  return userId;
};
