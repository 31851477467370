import Joi from 'joi';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {ConversationRequestCloseAndDeleteSchema} from '../../requests/conversation-request-close-and-delete/conversation-request-close-and-delete-schema';
import {ConversationRequestConfigureSchema} from '../../requests/conversation-request-configure/conversation-request-configure-schema';
import {ConversationRequestGetJWTSchema} from '../../requests/conversation-request-get-jwt/conversation-request-get-jwt-schema';
import {ConversationRequestTakeSchema} from '../../requests/conversation-request-take/conversation-request-take-schema';

export class AnyConversationRequestSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  // The type of the entity
  public static readonly type = 'type';

  private static readonly SCHEMA_ID = 'anyConversationRequest';

  private static computedSchema = Joi.alternatives()
    .try(
      ...[
        new ConversationRequestCloseAndDeleteSchema().getJoiSchema(),
        new ConversationRequestConfigureSchema().getJoiSchema(),
        new ConversationRequestGetJWTSchema().getJoiSchema(),
        new ConversationRequestTakeSchema().getJoiSchema(),
      ]
    )
    .id(AnyConversationRequestSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    // The name of the command
    public static readonly type = undefined;
  };

  /**
   * Always serialize the command name
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  public static getSchemaId(): string {
    return `#${AnyConversationRequestSchema.SCHEMA_ID}`;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} | import('joi').AnySchema {
    return AnyConversationRequestSchema.computedSchema;
  }
}
