import {cloneDeep} from 'lodash';
import {ParentCallInfoTokenConstructor} from './parent-call-info-token-constructor';
import {ParentCallInfoTokenSchema} from './parent-call-info-token-schema';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';

export class ParentCallInfoToken extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected callSid!: string;
  protected from!: string;
  protected to!: string;
  protected iat!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ParentCallInfoTokenConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ParentCallInfoToken {
    return new ParentCallInfoToken(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ParentCallInfoToken.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ParentCallInfoTokenSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCallSid(): string {
    return cloneDeep(this.callSid);
  }

  public getFrom(): string {
    return cloneDeep(this.from);
  }

  public getTo(): string {
    return cloneDeep(this.to);
  }

  public getIAT(): number {
    return cloneDeep(this.iat);
  }
}
