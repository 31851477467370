<ng-container *transloco="let t; read: 'service-command-editor-dial-call-list'">
  <p-messages severity="info">
    <ng-template pTemplate>
      <p class="wrap">{{ t('description') }}</p>
    </ng-template>
  </p-messages>

  <form [formGroup]="form">
    <div class="field col-12 mt-3">
      <p>Select call list</p>
      <app-call-list-autocomplete
        id="callListId"
        [formControl]="callListId"
        [placeholder]="t('dialCallListSelectPlaceholder')"
        [required]="true"
      ></app-call-list-autocomplete>
      <p *ngIf="callListId.hasError('required')" class="p-error">{{ t('callListIdErrorRequired') }}</p>
    </div>
  </form>
</ng-container>
