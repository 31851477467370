import {BlockedCallerResponseSchema} from './blocked-caller-response-schema';
import {ResponseObj} from '../../generic/response/response-obj';
import {cloneDeep} from 'lodash';
import {BlockedCallerResponseConstructor} from './blocked-caller-response-constructor';

export class BlockedCallerResponse extends ResponseObj {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected sessionId!: string;
  protected expireTime!: moment.Moment;
  protected reason!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: BlockedCallerResponseConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): BlockedCallerResponse {
    return new BlockedCallerResponse(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    const serialized = super.serialize(BlockedCallerResponse.getSchema(), 'api');
    return serialized;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): BlockedCallerResponseSchema {
    return new BlockedCallerResponseSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getSessionId(): string {
    return cloneDeep(this.sessionId);
  }

  public getExpireTime(): moment.Moment {
    return cloneDeep(this.expireTime);
  }

  public getReason(): string {
    return cloneDeep(this.reason);
  }
}
