import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PossibleRole, Roles } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService extends DbDocumentService<Roles> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, Roles);
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Org Data
  ///////////////////////////////////////////////////////////////////////

  public getRoles(): Observable<Roles> {
    const observable = this.getInjectedAuthService()
      .getUserId()
      .pipe(switchMap((userId: string, _) => this.getDoc(userId)));
    return observable;
  }

  public getRolesMap(): Observable<Record<PossibleRole, boolean>> {
    return this.getRoles().pipe(
      map((roles) => ({
        conversation: roles.canAccessConversation(),
        orgAdmin: roles.isOrgAdmin(),
        support: roles.canAccessSupport(),
        voicemail: roles.canAccessVoicemail(),
      })),
    );
  }
}
