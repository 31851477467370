import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {PossibleAdminRole} from '../../../../../objects/user/admin-roles/types';
import {PossibleRole} from '../../../../../objects/user/roles/types';
import {CommunicationsRequestName} from '../../../communications-request-name';
import {CommunicationsRequest} from '../../generic/communications-request/communications-request';
import {CommunicationsRequestSchema} from '../../generic/communications-request/communications-request-schema';
import {CommunicationsRequestGetCallLogPIIConstructor} from './communications-request-get-call-log-pii-constructor';
import {CommunicationsRequestGetCallLogPIISchema} from './communications-request-get-call-log-pii-schema';

export class CommunicationsRequestGetCallLogPII extends CommunicationsRequest {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected sessionId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsRequestGetCallLogPIIConstructor) {
    (parameters as any)[CommunicationsRequestSchema.type] = CommunicationsRequestName.getCallLogPII;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationsRequestGetCallLogPII {
    return new CommunicationsRequestGetCallLogPII(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsRequestGetCallLogPII.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsRequestGetCallLogPIISchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Roles
  /////////////////////////////////////////////////////////////////////////////

  public getRequiredRoles(): PossibleRole[] {
    return ['orgAdmin'];
  }

  public getRequiredAdminRoles(): PossibleAdminRole[] | undefined {
    return undefined;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getSessionId(): string {
    return cloneDeep(this.sessionId);
  }
}
