<form *ngIf="!loading" [formGroup]="form">
  <!-- Type -->
  <mat-label>Type: </mat-label>
  <mat-button-toggle-group formControlName="type">
    <mat-button-toggle *ngFor="let item of calleeEntityTypesList" value="{{ item }}">
      {{ item }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-error *ngIf="type.errors?.required"> Callee Entity type is required. </mat-error>

  <!-- Blockout times -->
  <div class="form-row">
    <div class="col">
      <!-- Has Blockout Times -->
      <mat-checkbox formControlName="hasBlockedOutTimes">Blocked Out Times</mat-checkbox>
    </div>
    <div *ngIf="hasBlockedOutTimes?.value" class="col">
      <p>Start</p>
      <p-calendar formControlName="blockoutStart" [maxDate]="blockoutEnd?.value" [timeOnly]="true"></p-calendar>
    </div>
    <div *ngIf="hasBlockedOutTimes?.value" class="col">
      <p>End</p>
      <p-calendar formControlName="blockoutEnd" [minDate]="blockoutStart?.value" [timeOnly]="true"></p-calendar>
    </div>
  </div>

  <!-- Event Fields -->
  <div *ngIf="type?.value === calleeEntityTypeEnum.event" class="form-row">
    <div class="col">
      <!-- Assigned User Type -->
      <mat-label>Assigned User Type: </mat-label>
      <mat-button-toggle-group formControlName="assignedUserTypes" multiple="true">
        <mat-button-toggle *ngFor="let type of assignedUserTypesList" value="{{ type }}">
          {{ type }}
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
    <div class="col">
      <!-- Event Type -->
      <app-obj-select
        [label]="'Event Type'"
        [orderedItems]="eventTypes"
        [(kvPair)]="selectedEventTypeKVPair"
        (kvPairChange)="updateObjAndEmit()"
      ></app-obj-select>
    </div>
  </div>

  <!-- UserList Fields -->
  <div *ngIf="type?.value === calleeEntityTypeEnum.userList" class="form-row">
    <div class="col">
      <mat-label>User List</mat-label>
      <p-pickList
        dragdrop="true"
        sourceHeader="Pick From"
        targetHeader="Chosen List"
        [responsive]="true"
        [source]="userDataArray"
        [(target)]="userList"
        (onMoveAllToSource)="updateObjAndEmit()"
        (onMoveAllToTarget)="updateObjAndEmit()"
        (onMoveToSource)="updateObjAndEmit()"
        (onMoveToTarget)="updateObjAndEmit()"
        (onTargetReorder)="updateObjAndEmit()"
      >
        <ng-template pTemplate="item" let-user>
          <div class="ui-helper-clearfix">
            <div style="font-size: 14px; float: right; margin: 15px 5px 0 0">
              {{ userDataDisplayFormat(user) }}
            </div>
          </div>
        </ng-template>
      </p-pickList>
    </div>
  </div>
</form>

<app-obj-is-valid *ngIf="!loading" [isValidObj]="isValidObjScoper"></app-obj-is-valid>
