import {DBMatchRuleSchema} from '../../generic/db-match-rule/db-match-rule-schema';
import {SchemaField} from '../../../../../generic/serialization/schema-field';

export class DBMatchRuleDocPathSchema extends DBMatchRuleSchema {
  public static readonly orgScopedPaths = 'orgScopedPaths';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly orgScopedPaths = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[DBMatchRuleDocPathSchema.orgScopedPaths] = SchemaField.arrayOfType(SchemaField.string(undefined), DBMatchRuleDocPathSchema.Defaults.orgScopedPaths).required();
    /* eslint-enable */

    return parameters;
  }
}
