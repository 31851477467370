import { EntityStats } from '@pwp-common';

import { UserStatsData, UserStatsRecord } from '../interfaces';

export const getUsersWithHighestCount = (
  usersStatsData: UserStatsData[],
  getUserCount: (userStats?: EntityStats) => number,
): UserStatsRecord =>
  usersStatsData.reduce(
    (acc, { userData, userStats }) => {
      const userCount = getUserCount(userStats);

      if (userCount > 0 && userCount === acc.count) {
        return {
          ...acc,
          users: [...acc.users, userData],
        };
      }

      if (userCount > acc.count) {
        return {
          count: userCount,
          users: [userData],
        };
      }

      return acc;
    },

    { count: 0, users: [] },
  );
