import {cloneDeep} from 'lodash';
import {ConversationMessageDigestConstructor} from './conversation-message-digest-constructor';
import {ConversationMessageDigestSchema} from './conversation-message-digest-schema';
import {SerializableObject} from '../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';

export class ConversationMessageDigest extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected timestamp!: moment.Moment;
  protected index!: number;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationMessageDigestConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationMessageDigest {
    return new ConversationMessageDigest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationMessageDigest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationMessageDigestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getTimestamp(): moment.Moment {
    return cloneDeep(this.timestamp);
  }

  public getIndex(): number {
    return cloneDeep(this.index);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Comparison
  /////////////////////////////////////////////////////////////////////////////

  /**
   * Use index for comparison because:
   * "The index of the message within the Conversation. Indices may skip numbers,
   * but will always be in order of when the message was received."
   *
   * https://www.twilio.com/docs/conversations/api/conversation-message-resource
   * @returns
   */
  public valueOf() {
    return this.index;
  }
}
