import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DialogService } from 'primeng/dynamicdialog';
import { combineLatestWith, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PTableCol } from '../../../../../common/p-table/p-table-col';
import { AllDataServiceExceptionService } from '../../../../../services/communication/all-data-service-exception/all-data-service-exception.service';
import { OrgDataService } from '../../../../../services/orgs/org-data/org-data.service';
import { ServiceExceptionEditorDialogData } from '../../service-exception-editor-dialog/service-exception-editor-dialog-data';
import { ServiceExceptionEditorDialogComponent } from '../../service-exception-editor-dialog/service-exception-editor-dialog.component';
import { ServiceExceptionsTableRow } from '../service-exceptions-table-row/service-exceptions-table-row';


@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-service-exceptions-table',
  templateUrl: './service-exceptions-table.component.html',
  styleUrls: ['./service-exceptions-table.component.css'],
  providers: [DialogService],
  encapsulation: ViewEncapsulation.None,
})
export class ServiceExceptionsTableComponent implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  tableRows: Observable<ServiceExceptionsTableRow[]>;

  loading = true;

  ///////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////

  cols: PTableCol[] = [
    { field: 'displayName', header: 'colDisplayName' },
    { field: 'description', header: 'colDescription' },
    { field: 'rules', header: 'colRules' },
    { field: 'ttl', header: 'colTTL' },
    { field: 'action', header: 'colAction' },
    { field: 'numServiceRequests', header: 'colNumServiceRequests' },
    { field: 'numServiceDeliveries', header: 'colNumServiceDeliveries' },
  ];

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private dialog: DialogService,
    private translocoService: TranslocoService,
    private orgDataService: OrgDataService,
    private allDataServiceExceptionService: AllDataServiceExceptionService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.getData();
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  getDataWrapper = () => this.getData();

  getData() {
    this.loading = true;
    this.tableRows = this.allDataServiceExceptionService.getDocs().pipe(
      combineLatestWith(this.orgDataService.getOrgData()),
      map(([allDataServiceExceptions, orgData]) => {
        const tableRows: ServiceExceptionsTableRow[] = [];
        for (const allDataServiceException of allDataServiceExceptions.values()) {
          const row = new ServiceExceptionsTableRow(allDataServiceException, orgData);
          tableRows.push(row);
        }
        this.loading = false;
        this.changeDetectorRef.detectChanges();
        return tableRows;
      }),
    );
  }
  ///////////////////////////////////////////////////////////////////////
  // Create A Service Exception
  ///////////////////////////////////////////////////////////////////////

  createServiceException() {
    const data: ServiceExceptionEditorDialogData = {
      allDataServiceException: undefined,
    };
    const dialogRef = this.dialog.open(ServiceExceptionEditorDialogComponent, {
      width: '80%',
      data,
      header: this.translocoService.translate('service-exceptions-table.createServiceExceptionDialogHeader'),
    });
    dialogRef.onClose.subscribe(() => {
      this.getData();
      this.changeDetectorRef.detectChanges();
    });
  }
}
