import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../../generic/serialization/schema-field';
import Joi from 'joi';

export class CommunicationTaskAttributesTaskQueueSchema extends SerializableObjectSchema {
  public static readonly iteration = 'iteration';
  public static readonly reservedWorkerIndices = 'reservedWorkerIndices';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly iteration = 0;
    public static readonly reservedWorkerIndices = [];
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////
  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([
      /**
       * TaskRouter expressions don't treat missing values as empty set,
       * hence NOT IN does not work unless reservedWorkerIndices is present
       */
      CommunicationTaskAttributesTaskQueueSchema.reservedWorkerIndices,
    ]);
  }
  // prettier-ignore
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[CommunicationTaskAttributesTaskQueueSchema.iteration] = SchemaField.number(CommunicationTaskAttributesTaskQueueSchema.Defaults.iteration).optional()
    parameters[CommunicationTaskAttributesTaskQueueSchema.reservedWorkerIndices] = SchemaField.arrayOfType(Joi.number(), CommunicationTaskAttributesTaskQueueSchema.Defaults.reservedWorkerIndices).optional()
    /* eslint-enable */

    return parameters;
  }
}
