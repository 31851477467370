import {SchemaField} from '../../../generic/serialization/schema-field';
import {DBDocSchema} from '../../../generic/db-doc/db-doc-schema';
import {IVRResponse} from '../../ivr/ivr-response/ivr-response';
import {values} from 'lodash';
import {OperatorCallStatus} from './operator-call-status';

export class GenericDialedCallLogSchema extends DBDocSchema {
  /**
   * Timestamps
   */
  public static readonly initiatedTimestamp = 'initiatedTimestamp';
  public static readonly ringingTimestamp = 'ringingTimestamp';
  public static readonly answeredTimestamp = 'answeredTimestamp';
  public static readonly completedTimestamp = 'completedTimestamp';

  /**
   * Operator Tracking
   */
  public static readonly errorCode = 'errorCode'; // Twilio error code, if any
  public static readonly operatorDurationMS = 'operatorDurationMS'; // Duration of the call, according to the operator
  public static readonly operatorPriceUnit = 'operatorPriceUnit'; // Price unit that the call is billed in.
  public static readonly operatorBasePricePM = 'operatorBasePricePM'; // Base price per minute for this call
  public static readonly operatorCountryCode = 'operatorCountryCode'; // Country code of dialed phone number in ISO 3166-1 alpha-2 format [https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2]

  /**
   * IVR
   */
  public static readonly responses = 'responses'; // IVR Responses received for this call

  /**
   * Other
   */
  public static readonly callerIdObjId = 'callerIdObjId'; // CallerId
  public static readonly sipResponseCode = 'sipResponseCode'; // SIP Response code, set after the call is complete
  public static readonly operatorCallStatus = 'operatorCallStatus'; // Call status as determined by the operator
  public static readonly callSid = 'callSid'; // Sid for the associated call

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    /**
     * Timestamps
     */
    public static readonly initiatedTimestamp = undefined;
    public static readonly ringingTimestamp = undefined;
    public static readonly answeredTimestamp = undefined;
    public static readonly completedTimestamp = undefined;

    /**
     * Operator Tracking
     */
    public static readonly errorCode = undefined;
    public static readonly operatorDurationMS = undefined;
    public static readonly operatorPriceUnit = undefined;
    public static readonly operatorBasePricePM = undefined;
    public static readonly operatorCountryCode = undefined;

    /**
     * IVR
     */
    public static readonly responses = [];

    /**
     * Other
     */
    public static readonly callerIdObjId = undefined;
    public static readonly sipResponseCode = undefined;
    public static readonly operatorCallStatus = undefined;
    public static readonly callSid = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */

    /**
     * Timestamps
     */
     parameters[GenericDialedCallLogSchema.initiatedTimestamp] = SchemaField.timestamp(GenericDialedCallLogSchema.Defaults.initiatedTimestamp);
     parameters[GenericDialedCallLogSchema.ringingTimestamp] = SchemaField.timestamp(GenericDialedCallLogSchema.Defaults.ringingTimestamp);
     parameters[GenericDialedCallLogSchema.answeredTimestamp] = SchemaField.timestamp(GenericDialedCallLogSchema.Defaults.answeredTimestamp);
     parameters[GenericDialedCallLogSchema.completedTimestamp] = SchemaField.timestamp(GenericDialedCallLogSchema.Defaults.completedTimestamp);
  
    /**
    * Operator Tracking
    */
    parameters[GenericDialedCallLogSchema.errorCode] = SchemaField.number(GenericDialedCallLogSchema.Defaults.errorCode);
    parameters[GenericDialedCallLogSchema.operatorDurationMS] = SchemaField.number(GenericDialedCallLogSchema.Defaults.operatorDurationMS);
    parameters[GenericDialedCallLogSchema.operatorPriceUnit] = SchemaField.string(GenericDialedCallLogSchema.Defaults.operatorPriceUnit);
    parameters[GenericDialedCallLogSchema.operatorBasePricePM] = SchemaField.string(GenericDialedCallLogSchema.Defaults.operatorBasePricePM);
    parameters[GenericDialedCallLogSchema.operatorCountryCode] = SchemaField.iso3166Alpha2CountryCode(GenericDialedCallLogSchema.Defaults.operatorCountryCode);

    /**
     * IVR
     */
    parameters[GenericDialedCallLogSchema.responses] = SchemaField.shortObjArray(IVRResponse,GenericDialedCallLogSchema.Defaults.responses);

    /**
     * Other
     */
    parameters[GenericDialedCallLogSchema.callerIdObjId] = SchemaField.autoGeneratedDocId(GenericDialedCallLogSchema.Defaults.callerIdObjId).optional();
    parameters[GenericDialedCallLogSchema.sipResponseCode] = SchemaField.number(GenericDialedCallLogSchema.Defaults.sipResponseCode).optional();
    parameters[GenericDialedCallLogSchema.operatorCallStatus] = SchemaField.string(GenericDialedCallLogSchema.Defaults.operatorCallStatus).optional().valid(...values(OperatorCallStatus));
    parameters[GenericDialedCallLogSchema.callSid] = SchemaField.string(GenericDialedCallLogSchema.Defaults.callSid).optional();

    /* eslint-enable */

    return parameters;
  }

  getCollection(orgId: string, ...otherFields: string[]): string {
    throw new Error('This method should never be called.');
  }
}
