import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy } from '@ngneat/until-destroy';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Observable } from 'rxjs';

import { Form } from '@pwp-common';

import { TranslocoRootModule } from '../../../transloco/transloco-root.module';
import {
  getObjAutocompleteFormControlBaseProviders,
  ObjAutocompleteFormControlBase,
} from '../../generic/abstract-classes/obj-autocomplete-form-control-base';
import { FormService } from '../services/form.service';

@UntilDestroy()
@Component({
  selector: 'app-form-autocomplete',
  standalone: true,
  templateUrl: './form-autocomplete.component.html',
  styleUrls: ['./form-autocomplete.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [AutoCompleteModule, CommonModule, FormsModule, MatIconModule, ReactiveFormsModule, TranslocoRootModule],
  providers: [...getObjAutocompleteFormControlBaseProviders(FormAutocompleteComponent)],
})
export class FormAutocompleteComponent extends ObjAutocompleteFormControlBase<Form> {
  private static id = 0;

  private formService = inject(FormService);

  public readonly defaultId = `app-create-async-service-request-autocomplete-${(FormAutocompleteComponent.id += 1)}`;

  @Input() public inputId?: string;

  public defineAllOptions(): Observable<Form[]> {
    return this.formService.getDocsArray();
  }
}
