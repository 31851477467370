import {AnyCommunicationSessionEventLogSchema} from './any-communication-session-event-log-schema';
import {CommunicationSessionEventLogName} from '../communication-session-event-log-name';
import {SerializableObject} from '../../../../../generic/serialization/serializable-object';
import {CommunicationSessionEventLog} from '../communication-session-event-log/communication-session-event-log';
import {AnyCommunicationSessionEventLogDialPhone} from '../../events/communication-session-event-log-dial-phone/generic/any-communication-session-event-log-event-dial-phone/any-communication-session-event-log-event-dial-phone';
import {AnyCommunicationSessionEventLogSendMessage} from '../../events/communication-session-event-log-send-message/generic/any-communication-session-event-log-send-message/any-communication-session-event-log-send-message';
import {AnyCommunicationSessionEventLogConference} from '../../events/communication-session-event-log-conference/generic/any-communication-session-event-log-conference/any-communication-session-event-log-conference';

export abstract class AnyCommunicationSessionEventLog extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationSessionEventLog {
    const typeString = validationResult.value[AnyCommunicationSessionEventLogSchema.type];
    const type = CommunicationSessionEventLogName[typeString as keyof typeof CommunicationSessionEventLogName];
    if (type === undefined) {
      throw new Error(`AnyCommunicationSessionEventLog._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (type) {
      case CommunicationSessionEventLogName.conference: {
        return AnyCommunicationSessionEventLogConference._deserialize(validationResult);
      }
      case CommunicationSessionEventLogName.dialPhone: {
        return AnyCommunicationSessionEventLogDialPhone._deserialize(validationResult);
      }
      case CommunicationSessionEventLogName.sendMessage: {
        return AnyCommunicationSessionEventLogSendMessage._deserialize(validationResult);
      }
      default: {
        throw new Error(`AnyCommunicationSessionEventLog._deserialize Unknown type: ${type}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema() {
    return new AnyCommunicationSessionEventLogSchema();
  }
}
