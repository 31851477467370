import { Directive, Input } from '@angular/core';

import {
  CallList,
  CommunicationWidgetCreateAsyncServiceRequest,
  IVR,
  VoicemailConfig,
  VoiceResponseCommand,
  VRCAudioMetadata,
} from '@pwp-common';

import { KVPair } from '../../../common/objects/kvpair';

import { ObjEditor } from './obj-editor';

@Directive()
export abstract class VRCEditor<T extends VoiceResponseCommand> extends ObjEditor<KVPair<T>> {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() ivrsArray: IVR[];

  @Input() callListArray: CallList[];

  @Input() vrcAudioMetadataArray: VRCAudioMetadata[];

  @Input() voicemailConfigArray: VoicemailConfig[];

  @Input() communicationWidgetAsyncServiceRequestArray: CommunicationWidgetCreateAsyncServiceRequest[];
}
