import {AnyServiceLimitEnforcementStrategySchema} from './any-service-limit-enforcement-strategy-schema';
import {ServiceLimitEnforcementStrategyName} from '../service-limit-enforcement-strategy-name';
import {SerializableObject} from '../../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../../generic/serialization/serializable-object-schema';
import {ServiceLimitEnforcementStrategy} from '../service-limit-enforcement-strategy/service-limit-enforcement-strategy';
import {ServiceLimitEnforcementStrategyRRule} from '../../strategies/service-limit-enforcement-strategy-rrule/service-limit-enforcement-strategy-rrule';

export abstract class AnyServiceLimitEnforcementStrategy extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ServiceLimitEnforcementStrategy {
    const typeString = validationResult.value[AnyServiceLimitEnforcementStrategySchema.type];
    const type = ServiceLimitEnforcementStrategyName[typeString as keyof typeof ServiceLimitEnforcementStrategyName];
    if (type === undefined) {
      throw new Error(
        `AnyServiceLimitEnforcementStrategyUser._deserialize: User Error: Cannot deserialize obj with type=${typeString}`
      );
    }

    switch (type) {
      case ServiceLimitEnforcementStrategyName.rrule: {
        return new ServiceLimitEnforcementStrategyRRule(validationResult.value);
      }
      default: {
        throw new Error(`AnyServiceLimitEnforcementStrategy._deserialize Unknown type: ${type}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new AnyServiceLimitEnforcementStrategySchema();
  }
}
