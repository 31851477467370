import {cloneDeep, orderBy} from 'lodash';
import {CommunicationTaskAttributesTaskQueueConstructor} from './communication-task-attributes-task-queue-constructor';
import {CommunicationTaskAttributesTaskQueueSchema} from './communication-task-attributes-task-queue-schema';
import {SerializableObject} from '../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';

export class CommunicationTaskAttributesTaskQueue extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected iteration!: number;
  protected reservedWorkerIndices!: number[];

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationTaskAttributesTaskQueueConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only offerChannels
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationTaskAttributesTaskQueue {
    return new CommunicationTaskAttributesTaskQueue(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationTaskAttributesTaskQueue.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationTaskAttributesTaskQueueSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getIteration(): number {
    return cloneDeep(this.iteration ?? 0);
  }
  public getReservedWorkerIndices(): number[] {
    return cloneDeep(this.reservedWorkerIndices ?? []);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Mutation
  /////////////////////////////////////////////////////////////////////////////

  public markWorkerAsReserved(params: {index: number; maxIndex: number}): CommunicationTaskAttributesTaskQueue {
    const {index, maxIndex} = params;
    if (this.reservedWorkerIndices.includes(index)) {
      console.error(
        `Error: markWorkerAsReserved called with worker index that is already in reservedWorkerIndices. 
        This should never happen. "${index}" is already in reservedWorkerIndices.`
      );
      return this;
    }
    const unsortedIndices = new Set([...this.reservedWorkerIndices, index]);
    this.reservedWorkerIndices = orderBy(Array.from(unsortedIndices), [], ['asc']);
    if (index > maxIndex) {
      console.error('Error: markWorkerAsReserved called with worker index that is greater than maxIndex');
    }
    /*
     * It's possible for numbers greater than maxIndex to be included in the reservedWorkerIndices array.
     * That rare situation can happen when CommunicationWorkflow configuration is edited while a TaskRouter task is in progress.
     * We only increment the iteration and reset the reservedWorker indices if all workers from 0 until maxIndex were reserved.
     * The line below explicitly checks for that.
     */
    if (Array.from({length: maxIndex + 1}, (_, i) => i).every(i => this.reservedWorkerIndices.includes(i))) {
      this.reservedWorkerIndices = [];
      this.iteration++;
    }

    return this;
  }
}
