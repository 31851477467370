import moment from 'moment-timezone';
import {cloneDeep, isNil} from 'lodash';

const yearMonthDayFormat = 'YYYY-MM-DD';

export const isMultiDayTimeRange = (start: moment.Moment, end: moment.Moment, timezone: string): boolean => {
  if (isNil(start) || isNil(end) || isNil(timezone) || !start.isValid() || !end.isValid()) {
    throw new Error('isMultiDayTimeRange: User Error. Input is nil');
  }
  const startInTz = cloneDeep(start).tz(timezone);
  const endInTz = cloneDeep(end).tz(timezone);

  const startEndSameDay = startInTz.format(yearMonthDayFormat) === endInTz.format(yearMonthDayFormat);
  const endAtMidnightOnStartDay = Math.abs(endInTz.diff(cloneDeep(startInTz).endOf('day'))) === 1;

  return !(startEndSameDay || endAtMidnightOnStartDay);
};
