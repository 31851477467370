import { AssignedUserType, createDefaultEventRequestConfig, EventRequestRole } from '@pwp-common';

import { EventRequestConfigEditorOutput } from '../../interfaces';

import { MakeEventRequestConfigEditorOutputInput } from './interfaces';

export const makeEventRequestConfigEditorOutput = (params: MakeEventRequestConfigEditorOutputInput): EventRequestConfigEditorOutput => {
  const eventRequestConfig = params.eventRequestConfig ?? createDefaultEventRequestConfig();

  return {
    id: params.eventType.getId(),
    displayName: params.eventType.getDisplayName(),
    allowRequestBackup: eventRequestConfig.hasRole(AssignedUserType.backup, EventRequestRole.allowRequest),
    allowRequestPrimary: eventRequestConfig.hasRole(AssignedUserType.primary, EventRequestRole.allowRequest),
    autoApproveBackup: eventRequestConfig.hasRole(AssignedUserType.backup, EventRequestRole.approveRequest),
    autoApprovePrimary: eventRequestConfig.hasRole(AssignedUserType.primary, EventRequestRole.approveRequest),
    emailOnNewRequestCreated: eventRequestConfig.getEmailOnNewRequestCreated(),
  };
};
