import { Message, Participant } from '@twilio/conversations';

import { ConversationUserIdentityType, EXTERNAL_PARTICIPANT_ID_PREFIX } from '@pwp-common';

import { UnsentMessage } from '../../conversation-client/message-state/unsent-message';
import { isUnsentMessage } from '../../message/helper/is-unsent-message';

const isParticipantExternal = (participant: Participant) => participant.identity.startsWith(EXTERNAL_PARTICIPANT_ID_PREFIX);

const isMessageFromExternalParticipant = async (message: Message | UnsentMessage) => {
  if (isUnsentMessage(message)) {
    return isParticipantExternal(message.participant);
  }

  if (message.author === 'system') {
    return false;
  }

  // Note: cache is not necessary on our end as it's handled by Twilio (last checked on @twilio/conversations v^2.1.0)
  try {
    return isParticipantExternal(await message.getParticipant());
  } catch (e) {
    return true;
  }
};

export const getMessageParticipantDisplayId = async (message: Message | UnsentMessage): Promise<string> => {
  const isFromExternalParticipant = await isMessageFromExternalParticipant(message);

  return isFromExternalParticipant ? ConversationUserIdentityType.anonymousExternalId : ConversationUserIdentityType.userId;
};
