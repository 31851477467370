import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { LetModule } from '@ngrx/component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { combineLatest, map } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ConversationConfigService } from '../../../services/conversation/conversation-config/conversation-config.service';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';

@Component({
  selector: 'app-conversation-getting-started',
  standalone: true,
  imports: [ButtonModule, ClipboardModule, CommonModule, InputTextModule, LetModule, ProgressSpinnerModule, TranslocoModule],
  templateUrl: './conversation-getting-started.component.html',
  styleUrls: ['./conversation-getting-started.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConversationGettingStartedComponent {
  private readonly orgId$ = this.orgDataService.getOrgData().pipe(map((orgData) => orgData.getId()));

  private readonly chatUrl$ = combineLatest([this.orgId$, this.conversationConfigService.getUniqueDoc({ query: [] })]).pipe(
    map(([orgId, conversationConfig]) => conversationConfig.getChatURL(orgId)),
  );

  public readonly webchatScriptURL = `${environment.webchatBaseURL}/main.js`;

  public readonly data$ = combineLatest({
    orgId: this.orgId$,
    chatUrl: this.chatUrl$,
  });

  constructor(
    private conversationConfigService: ConversationConfigService,
    private orgDataService: OrgDataService,
  ) {}
}
