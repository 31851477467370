import {cloneDeep} from 'lodash';
import {ServiceLimitExecutionConstructor} from './service-limit-execution-constructor';
import {ServiceLimitExecutionSchema} from './service-limit-execution-schema';
import {SerializableObject} from '../../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../../generic/serialization/serializable-object-schema';

export class ServiceLimitExecution extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected actionExecuted!: boolean;
  protected exceeded!: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceLimitExecutionConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ServiceLimitExecution {
    const obj = new ServiceLimitExecution(super._deserialize(validationResult));
    return obj;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ServiceLimitExecution.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ServiceLimitExecutionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getActionExecuted() {
    return cloneDeep(this.actionExecuted);
  }

  public getExceeded() {
    return cloneDeep(this.exceeded);
  }
}
