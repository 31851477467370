<ng-container *transloco="let t; read: 'communication-log-detail-conversation'">
  <p-toast position="bottom-center"></p-toast>
  <p-card>
    <ng-template pTemplate="header">
      <legend>{{ t('title') }}</legend>
    </ng-template>

    <ng-template pTemplate="content">
      <app-communication-logs-session-hunt [tableRows$]="tableRows$" [tableTitle]="t('tableTitle')"></app-communication-logs-session-hunt>
    </ng-template>

    <ng-template pTemplate="footer">
      <div class="communication-log-detail-conversation__actions">
        <button
          pButton
          class="p-button-secondary"
          label="{{ dataExists ? t('getConversationPIIButtonEnabledLabel') : t('getConversationPIIButtonDisabledLabel') }}"
          type="button"
          [disabled]="!this.dataExists"
          (click)="showConversationPIIDialog()"
        ></button>
        <button pButton app-submit-form-button type="button" [sessionId]="communicationSessionId$ | async"></button>
      </div>
    </ng-template>
  </p-card>
</ng-container>
