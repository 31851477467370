import type {AnySchema} from 'joi';
import Joi from 'joi';

import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {UserResponseChangeOrgIdSchema} from '../../responses/user-response-change-org-id/user-response-change-org-id-schema';
import {UserResponseCreateUserSchema} from '../../responses/user-response-create-user/user-response-create-user-schema';

export class AnyUserResponseSchema extends SerializableObjectSchema {
  private static readonly SCHEMA_ID = 'anyUserResponse';

  private static computedSchema = Joi.alternatives().try(new UserResponseChangeOrgIdSchema().getJoiSchema(), new UserResponseCreateUserSchema().getJoiSchema()).id(AnyUserResponseSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly GenericDefaults = class {
    public static readonly type = undefined;
  };

  public static getSchemaId(): string {
    return `#${AnyUserResponseSchema.SCHEMA_ID}`;
  }

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} | AnySchema {
    return AnyUserResponseSchema.computedSchema;
  }
}
