<ng-container *transloco="let t; read: 'rrule-generator'">
  <form *ngIf="!loading" [formGroup]="form">
    <!-- Repeat Every [number] (Day, Week, Month) -->
    <mat-label>{{ t('repeatEvery') }}</mat-label>
    <mat-form-field appearance="fill">
      <input matInput formControlName="interval" type="number" [step]="1" />
      <mat-error *ngIf="interval?.invalid && interval?.touched">
        {{ t('intervalError') }}
      </mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-select formControlName="freq">
        <mat-option [value]="hourly"> {{ t('hour') }} </mat-option>
        <mat-option [value]="daily"> {{ t('day') }} </mat-option>
        <mat-option [value]="weekly"> {{ t('week') }} </mat-option>
        <mat-option [value]="monthly"> {{ t('month') }} </mat-option>
        <mat-option [value]="yearly"> {{ t('year') }} </mat-option>
      </mat-select>
    </mat-form-field>
    <br />
    <br />

    <!-- Repeat On: Select Weekdays -->
    <mat-label>{{ t('repeatOn') }}</mat-label>
    <mat-button-toggle-group formControlName="byweekday" multiple>
      <mat-button-toggle [value]="monday">{{ t('monday') }}</mat-button-toggle>
      <mat-button-toggle [value]="tuesday">{{ t('tuesday') }}</mat-button-toggle>
      <mat-button-toggle [value]="wednesday">{{ t('wednesday') }}</mat-button-toggle>
      <mat-button-toggle [value]="thursday">{{ t('thursday') }}</mat-button-toggle>
      <mat-button-toggle [value]="friday">{{ t('friday') }}</mat-button-toggle>
      <mat-button-toggle [value]="saturday">{{ t('saturday') }}</mat-button-toggle>
      <mat-button-toggle [value]="sunday">{{ t('sunday') }}</mat-button-toggle>
    </mat-button-toggle-group>

    <mat-error *ngIf="byweekday?.invalid && byweekday?.touched">
      {{ t('repeatOnError') }}
    </mat-error>

    <br /><br />

    <!-- Week Start:  -->
    <mat-form-field appearance="fill" class="full-width">
      <mat-label>{{ t('wkst') }}</mat-label>
      <mat-select formControlName="wkst">
        <mat-option [value]="monday">{{ t('monday') }}</mat-option>
        <mat-option [value]="tuesday">{{ t('tuesday') }}</mat-option>
        <mat-option [value]="wednesday">{{ t('wednesday') }}</mat-option>
        <mat-option [value]="thursday">{{ t('thursday') }}</mat-option>
        <mat-option [value]="friday">{{ t('friday') }}</mat-option>
        <mat-option [value]="saturday">{{ t('saturday') }}</mat-option>
        <mat-option [value]="sunday">{{ t('sunday') }}</mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</ng-container>
