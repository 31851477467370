import {cloneDeep, uniq, orderBy, values} from 'lodash';
import {EventRequestRole} from './enums';

export const ALL_POSSIBLE_ROLES = values(EventRequestRole);
/**
 * Given a list of possible roles, return the minimal set
 * that endow the same permissions.
 * @param roles
 */
export const simplifyEventRequestConfigRoles = (roles: string[]): EventRequestRole[] => {
  let _roles = cloneDeep(uniq(roles.filter(z => isPossibleRole(z, false))));

  if (_roles.length === 0) {
    return [];
  }

  if (_roles.includes(EventRequestRole.approveRequest)) {
    _roles = _roles.filter(z => z !== EventRequestRole.allowRequest);
  }

  return orderBy(_roles) as EventRequestRole[];
};

export const isPossibleRole = (role: string, throwOnError = true): boolean => {
  if (ALL_POSSIBLE_ROLES.includes(role as any)) {
    return true;
  }
  if (throwOnError) {
    throw new Error(`simplifyEventRequestConfigRoles.isPossibleRole: Invalid role: ${role}`);
  }
  return false;
};
