import { isNil } from 'lodash';

import { RowCell } from '../../../../../common/p-table/row-cell';
import { createValueCell } from '../create-value-cell/create-value-cell';
import { generateReportRowId } from '../generate-report-row-id';
import { MonthValues, ReportRow, ReportRowValues } from '../interfaces';

import { RowFilters } from './interfaces';

const createFilterCell = ({ callList, eventType }: RowFilters) => {
  if (!isNil(callList)) {
    const displayName = callList.getDisplayName();

    return new RowCell<string>({
      translationScope: 'admin-report-row',
      translationKey: 'filter.callListType',
      translationObj: { displayName },
      sortValue: displayName,
    });
  }

  if (!isNil(eventType)) {
    const displayName = eventType.getDisplayName();

    return new RowCell<string>({
      translationScope: 'admin-report-row',
      translationKey: 'filter.eventType',
      translationObj: { displayName },
      sortValue: displayName,
    });
  }

  return new RowCell<string>({
    translationScope: 'admin-report-row',
    translationKey: 'filter.none',
    sortValue: undefined,
  });
};

const createMonthCells = (rowName: string, countByMonth: MonthValues) =>
  Object.entries(countByMonth).reduce(
    (acc, [month, value]) => ({
      ...acc,
      [month]: createValueCell(rowName, value),
    }),
    {},
  );

export const createReportRow = (rowName: string, values: ReportRowValues, filters: RowFilters = {}): ReportRow => {
  const { total, countByMonth } = values;

  return {
    id: generateReportRowId({ prefix: rowName }),
    type: new RowCell<string>({
      translationScope: 'admin-report-row',
      translationKey: `${rowName}.cellType`,
      sortValue: `${rowName}.cellType`,
    }),
    question: new RowCell<string>({
      translationScope: 'admin-report-row',
      translationKey: `${rowName}.cellQuestion`,
      sortValue: `${rowName}.cellQuestion`,
    }),
    filter: createFilterCell(filters),
    total: createValueCell(rowName, total),
    ...createMonthCells(rowName, countByMonth),
  };
};
