import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {SchemaField} from '../../generic/serialization/schema-field';

export class UserDataSchema extends DBDocSchema {
  public static readonly firstName = 'firstName';
  public static readonly lastInitial = 'lastInitial';
  public static readonly notificationEmail = 'notificationEmail';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'users';
  };

  static Defaults = class {
    public static readonly firstName = 'FirstNameNotSet';
    public static readonly lastInitial = 'LastInitialNotSet';
    public static readonly notificationEmail = 'InvalidEmail@example.com';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[UserDataSchema.firstName] = SchemaField.string(UserDataSchema.Defaults.firstName);
    parameters[UserDataSchema.lastInitial] = SchemaField.string(UserDataSchema.Defaults.lastInitial);
    parameters[UserDataSchema.notificationEmail] = SchemaField.email(UserDataSchema.Defaults.notificationEmail);

    return parameters;
  }

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${UserDataSchema.Constants.collection}`;
  }
}
