import {cloneDeep} from 'lodash';
import {CallEntrypointConstructor} from './call-entrypoint-constructor';
import {Displayable} from '../../generic/db-doc/displayable';
import {DBDocObject} from '../../generic/db-doc/db-doc-object';
import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {CallEntrypointSchema} from './call-entrypoint-schema';
import {VoiceResponseCommand} from '../../voice-response-command/generic/voice-response-command';

export class CallEntrypoint extends DBDocObject implements Displayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected commands!: VoiceResponseCommand[];
  protected e164Phones!: string[];
  protected description!: string;
  protected displayName!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CallEntrypointConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CallEntrypoint {
    return new CallEntrypoint(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CallEntrypoint.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new CallEntrypointSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCommands(): VoiceResponseCommand[] {
    return cloneDeep(this.commands);
  }

  public getE164Phones(): string[] {
    return cloneDeep(this.e164Phones);
  }

  public getDescription(): string {
    return cloneDeep(this.description);
  }

  public getDisplayName(): string {
    return cloneDeep(this.displayName);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  public setCommands(commands: VoiceResponseCommand[]): CallEntrypoint {
    this.commands = commands;
    return this;
  }

  public setDescription(description: string): CallEntrypoint {
    this.description = description;
    return this;
  }

  public setDisplayName(displayName: string): CallEntrypoint {
    this.displayName = displayName;
    return this;
  }

  public setE164Phones(e164Phones: string[]): CallEntrypoint {
    this.e164Phones = e164Phones;
    return this;
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
