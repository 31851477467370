import { isNil } from 'lodash';

import { CalleeEntity, CalleeEntityType, compressCalleeEntityArray } from '@pwp-common';

import { WorkerEditorOutputItem } from '../../core/interface';
import { WorkersAutocompleteEditorOutput } from '../../workers-autocomplete-editor-output';

export const editorOutputToCalleeEntities = (options: WorkersAutocompleteEditorOutput): CalleeEntity[] => {
  let result: CalleeEntity[] = (options ?? []).map((z) => editorOutputItemToCalleeEntity(z)).filter((z) => !isNil(z));
  result = compressCalleeEntityArray(result);

  return result;
};

const editorOutputItemToCalleeEntity = (option: WorkerEditorOutputItem): CalleeEntity | undefined => {
  if (isNil(option)) {
    return undefined;
  }
  switch (option.type) {
    case 'deletedUser': {
      return undefined;
    }
    case 'deletedEvent': {
      return undefined;
    }
    case 'user': {
      return new CalleeEntity({
        type: CalleeEntityType.userList,
        blockoutTimes: [],
        userList: [option.userId],
      });
    }
    case 'event': {
      return new CalleeEntity({
        type: CalleeEntityType.event,
        eventType: option.eventTypeId,
        blockoutTimes: [],
        assignedUserTypes: [option.assignedUserType],
      });
    }
    default: {
      console.error(option);
      throw new Error('editorOutputItemToCalleeEntity: Not implemented!');
    }
  }
};
