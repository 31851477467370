import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-conversation-settings',
  templateUrl: './conversation-settings.component.html',
  styleUrls: ['./conversation-settings.component.css'],
  providers: [],
})
export class ConversationSettingsComponent implements OnInit {
  ////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////
  constructor() {}

  ngOnInit(): void {}
}
