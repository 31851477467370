import {cloneDeep} from 'lodash';
import {DBDocSchema} from '../../../../generic/db-doc/db-doc-schema';
import {ServiceExceptionConstructor} from './service-exception-constructor';
import {ServiceExceptionSchema} from './service-exception-schema';
import {GenericDisplayable} from '../../../../generic/displayable/generic-displayable';
import {TTL} from '../../../../core/ttl/ttl';
import {ServiceExceptionActionInboundCallSession} from '../service-exception-action/service-exception-action-inbound-call-session/service-exception-action-inbound-call-session';
import {DBMatcher} from '../../../../core/db-matcher/db-matcher/db-matcher';

export class ServiceException extends GenericDisplayable {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected inboundCallSessionAction!: ServiceExceptionActionInboundCallSession;
  protected matcher!: DBMatcher;
  protected ttl!: TTL | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ServiceExceptionConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ServiceException {
    return new ServiceException(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize(): any {
    return super.serialize(ServiceException.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): DBDocSchema {
    return new ServiceExceptionSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getInboundCallSessionAction(): ServiceExceptionActionInboundCallSession {
    return cloneDeep(this.inboundCallSessionAction);
  }

  public getMatcher(): DBMatcher {
    return cloneDeep(this.matcher);
  }

  public getTTL(): TTL | undefined {
    return cloneDeep(this.ttl);
  }
}
