import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';

import { CommunicationsRequestHandleAsyncServiceRequest, CommunicationsRequestName } from '@pwp-common';

import { AsyncServiceRequestService } from '../../../../../../services/async-service-request/async-service-request.service';
import { CommunicationsService } from '../../../../../../services/call/communications/communications.service';
import { CallbackRow } from '../../../../row/callback-row/callback-row';
import { InboxService } from '../../../../services/inbox-service/inbox.service';


@Component({
  selector: 'app-inbox-callback-detail-handled-by-button',
  templateUrl: './inbox-callback-detail-handled-by-button.component.html',
  styleUrls: ['./inbox-callback-detail-handled-by-button.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InboxCallbackDetailHandledByButtonComponent implements OnInit {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////
  @Input() row: CallbackRow;
  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private asyncServiceRequestService: AsyncServiceRequestService,
    private communicationService: CommunicationsService,
    private inboxService: InboxService,
  ) {}

  ngOnInit(): void {}

  ///////////////////////////////////////////////////////////////////////
  // Toggle Handled
  ///////////////////////////////////////////////////////////////////////

  toggleHandledBySelf = async () => {
    await this.toggleHandledBySelfHandler();
  };

  async toggleHandledBySelfHandler() {
    await this.asyncServiceRequestService.toggleHandledBySelf(this.row.id);
    await this.communicationService.handleAsyncServiceRequest(
      new CommunicationsRequestHandleAsyncServiceRequest({
        type: CommunicationsRequestName.handleAsyncServiceRequest,
        asyncServiceRequestId: this.row.callbackRowParams.asyncServiceRequest.getId(),
      }),
    );
    this.inboxService.markAsyncServiceRequestUpdated(this.row.callbackRowParams.asyncServiceRequest.getId());
    this.changeDetectorRef.detectChanges();
  }
}
