import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';

import { OrgData } from '../../../../../../../common/src';
import { KVPair } from '../../../common/objects/kvpair';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';
import { UserEndpointService } from '../../../services/user/user-endpoint/user-endpoint.service';

@Component({
  providers: [MessageService],
  selector: 'app-org-id-change',
  templateUrl: './org-id-change.component.html',
  styleUrls: ['./org-id-change.component.css'],
})
export class OrgIdChangeComponent implements OnInit {
  selectedOrgDataKVPair: KVPair<OrgData>;

  orgDataArray: Observable<OrgData[]>;

  constructor(
    private orgDataService: OrgDataService,
    private userEndpointService: UserEndpointService,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {
    this.orgDataArray = this.orgDataService.getDocsArray();
  }

  async changeOrgId() {
    const newOrgId = this.selectedOrgDataKVPair?.value?.getId();
    await this.userEndpointService.changeOrgIdOfLoggedInUser(newOrgId);
    this.messageService.add({ severity: 'success', summary: 'OrgId changed!' });
  }
}
