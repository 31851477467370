import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {ConversationRequestName} from '../../../conversation-request-name';
import {ConversationResponse} from '../../generic/conversation-response/conversation-response';
import {ConversationResponseSchema} from '../../generic/conversation-response/conversation-response-schema';

import {ConversationResponseTakeConstructor} from './conversation-response-take-constructor';
import {ConversationResponseTakeError} from './conversation-response-take-error';
import {ConversationResponseTakeSchema} from './conversation-response-take-schema';

export class ConversationResponseTake extends ConversationResponse<ConversationResponseTakeError> {
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ConversationResponseTakeConstructor) {
    super({
      ...parameters,
      [ConversationResponseSchema.type]: ConversationRequestName.take,
    });
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ConversationResponseTake {
    return new ConversationResponseTake(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ConversationResponseTake.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ConversationResponseTakeSchema();
  }
}
