import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Provider } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { DialCallList, DialCallListSchema } from '@pwp-common';

import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';
import { ServiceCommandEditorOutput } from '../editor-output/service-command-editor-output';

const VALUE_ACCESSOR: Provider = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ServiceCommandEditorDialCallListComponent),
  multi: true,
};

const VALIDATOR: Provider = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ServiceCommandEditorDialCallListComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-service-command-editor-dial-call-list',
  templateUrl: './service-command-editor-dial-call-list.component.html',
  styleUrls: ['./service-command-editor-dial-call-list.component.css'],
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class ServiceCommandEditorDialCallListComponent extends FormGroupControlValueAccessor<
  ServiceCommandEditorOutput,
  ServiceCommandEditorOutput
> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    const formConfig = {} as any;
    formConfig[DialCallListSchema.commandName] = new UntypedFormControl(DialCallListSchema.Defaults.commandName, [Validators.required]);
    formConfig[DialCallListSchema.callListId] = new UntypedFormControl(undefined, [Validators.required]);
    this.form = new UntypedFormGroup(formConfig);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): any {
    return DialCallList.deserialize(value).serialize();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get callListId() {
    return this.form.get(DialCallListSchema.callListId) as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors(): ValidationErrors {
    return {
      'service-command-editor-dial-call-list': this.form.value,
    };
  }
}
