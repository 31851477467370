<ng-container *transloco="let t; read: 'message-template-subscribe'">
  <form *ngIf="formGroup" [formGroup]="formGroup">
    <!-- Required to configure toast notifications -->
    <p-toast></p-toast>

    <ng-template #doneTemplate>
      <div class="flex flex-column row-gap-3">
        <p-table
          #dt
          dataKey="id"
          responsiveLayout="scroll"
          styleClass="p-datatable-gridlines"
          [columns]="selectedCols"
          [value]="messageTemplateDisplays$ | async"
        >
          <!-- Header -->
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{ t(col.field) }}
              </th>
            </tr>
          </ng-template>
          <!-- Display Rows -->
          <ng-template pTemplate="body" let-columns="columns" let-rowData>
            <tr>
              <td>
                <div class="d-flex justify-content-center">
                  <p-checkbox
                    [ariaLabel]="rowData.displayName"
                    [binary]="true"
                    [formControl]="formGroup.controls.subscriptions.controls[rowData.id]"
                  ></p-checkbox>
                </div>
              </td>
              <td>
                {{ rowData['displayName'] }}
              </td>
              <td>
                {{ rowData['description'] }}
              </td>
              <td>
                {{ rowData['frequency'] }}
              </td>
            </tr>
          </ng-template>
          <!-- Empty Message -->
          <ng-template pTemplate="emptymessage" let-columns>
            <tr>
              <td [attr.colspan]="columns.length + 1">
                {{ t('nothingToShow') }}
              </td>
            </tr>
          </ng-template>
        </p-table>
        <ul>
          <li [innerHTML]="t('consentTerms.contactPhoneNumbers', { orgPhoneNumbers: orgPhoneNumbers$ | async })"></li>
          <li [innerHTML]="t('consentTerms.cancelSmsService', {orgName: orgName$ | async})"></li>
          <li [innerHTML]="t('consentTerms.assistance')"></li>
          <li [innerHTML]="t('consentTerms.carriersResponsibility')"></li>
          <li [innerHTML]="t('consentTerms.ratesApplied')"></li>
          <li [innerHTML]="t('consentTerms.privacyPolicy')"></li>
        </ul>

        <p-checkbox
          class="block p-checkbox--centered"
          [ariaLabel]="t('agreeWithConsentTerms')"
          [binary]="true"
          [formControl]="formGroup.controls.agreeWithConsentTerms"
          [label]="t('agreeWithConsentTerms')"
        ></p-checkbox>

        <p-button [disabled]="(updating$ | async) || formGroup.invalid" (onClick)="updateUserSubscriptions()">
          {{ t('updatePreferences') }}
        </p-button>
      </div>
    </ng-template>

    <app-loading [doneTemplate]="doneTemplate" [loader]="loader"> </app-loading>
  </form>
</ng-container>
