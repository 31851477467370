import { UntypedFormGroup } from '@angular/forms';
import { isNil } from '@ngneat/transloco';

import { AvailableTimesType } from '../objects/time-selection/available-times-by-week';
import { getTimeRangeFromStr } from '../time-range-str/get-time-range-from-str';

export class TimeRangeStrValidator {
  public static timeRange(timeRangeTypeControlName: string, timeRangeControlName: string): any {
    return (formGroup: UntypedFormGroup): void => {
      const timeRangeTypeControl = formGroup.controls[timeRangeTypeControlName];
      const timeRangeControl = formGroup.controls[timeRangeControlName];

      if (timeRangeTypeControl?.value !== AvailableTimesType.sometimesAvailable.valueOf()) {
        timeRangeControl.setErrors(null);
        return;
      }

      const parsedResult = getTimeRangeFromStr(timeRangeControl.value);
      if (isNil(parsedResult)) {
        timeRangeControl.setErrors({ invalidTimeRange: timeRangeControl.value });
      }

      timeRangeControl.setErrors(null);
      
    };
  }
}
