import { CallList, CallListSchema, isNilOrDefault, OrgData, UserData } from '@pwp-common';

import { RowCell } from '../../../../../common/p-table/row-cell';
import { SettingsEditorRow } from '../../../../generic/settings/common/settings-editor-row';
import { CallListEditorOutput } from '../../editor/editor-output/call-list-editor-output';
import { makeCallListEditorOutput } from '../../editor/editor-output/make-call-list-editor-output/make-call-list-editor-output';

const CALL_LIST_SCHEMA = new CallListSchema();

export class CallListTableRow implements SettingsEditorRow<CallListEditorOutput> {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  //////////////////////////////////////////////////////////////////////////////////////////

  id: string;

  description: RowCell<string>;

  displayName: RowCell<string>;

  editorOutput: CallListEditorOutput;

  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(public data: { callList: CallList; userDataMap: Map<string, UserData>; orgData: OrgData }) {
    this.id = data.callList.getId();
    this.editorOutput = makeCallListEditorOutput(data.callList, data.userDataMap, data.orgData);

    this.setDescription(data.callList);
    this.setDisplayName(data.callList);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Description
  //////////////////////////////////////////////////////////////////////////////////////////

  private setDescription(callList: CallList) {
    const description = callList.getDescription();
    if (isNilOrDefault(description, CallListSchema.description, CALL_LIST_SCHEMA)) {
      this.description = new RowCell<string>({
        translationScope: 'call-list-table-row',
        translationKey: 'cellDescriptionMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.description = new RowCell<string>({
      translationScope: 'call-list-table-row',
      translationKey: 'cellDescription',
      translationObj: {
        description,
      },
      sortValue: description,
    });
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  // Display Name
  //////////////////////////////////////////////////////////////////////////////////////////

  private setDisplayName(callList: CallList) {
    const displayName = callList.getDisplayName();
    if (isNilOrDefault(displayName, CallListSchema.displayName, CALL_LIST_SCHEMA)) {
      this.displayName = new RowCell<string>({
        translationScope: 'call-list-table-row',
        translationKey: 'cellDisplayNameMissing',
        translationObj: {},
        sortValue: undefined,
      });
      return;
    }

    this.displayName = new RowCell<string>({
      translationScope: 'call-list-table-row',
      translationKey: 'cellDisplayName',
      translationObj: {
        displayName,
      },
      sortValue: displayName,
    });
  }
}
