import { AbstractControl, UntypedFormArray } from '@angular/forms';
import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';

import { DBDocSchema, Displayable } from '@pwp-common';

export const DATETIME_LOCAL_CONTROL_STR_FORMAT = 'yyyy-MM-DDTHH:mm';

/**
 * Determine if two displayable objects are equal.
 *
 * @param o1
 * @param o2
 */
export const compareDisplayableObjs = <T extends Displayable>(o1: T | undefined, o2: T | undefined): boolean => {
  if (isNil(o1) || isNil(o2)) {
    return o1 === o2;
  }
  return o1.getId() === o2.getId();
};

/**
 * Each field of a form has an associated getter in the associated component, which looks like this.
 * We parse those fields in the given component, and replace null values with the corresponding default.
 *
 * get input(): AbstractControl | null {
 *   return this.form.get(IVRSchema.input);
 * }
 */
export const getFieldValuesFromFormInComponent = (
  id: string | undefined,
  fieldValues: string[],
  defaults: { [key: string]: any },
  component: any,
): any => {
  // Check for no duplicates in fieldValues
  if (new Set(fieldValues).size !== fieldValues.length) {
    throw new Error('User Error: fieldValues has duplicates');
  }

  const result = {} as { [key: string]: any };

  if (id !== undefined) {
    result[DBDocSchema.id] = id;
  }
  for (const field of fieldValues) {
    result[field] = getFieldControlValueOrDefault(component[field], defaults[field]);
  }

  return cloneDeep(result);
};

export const getFieldControlValueOrDefault = (fieldControl: any, defaultValue: any): any => {
  // Handle Array Values First
  if (fieldControl instanceof UntypedFormArray) {
    const arrayValue = [];
    for (const control of fieldControl.controls) {
      arrayValue.push(control.value);
    }
    return arrayValue;
  }

  if (!isNil(fieldControl) && !(fieldControl instanceof AbstractControl)) {
    console.error(fieldControl);
    throw new Error(`User Error: Cannot get value from component. Object is not an AbstractControl.`);
  }

  // All other form types
  if (isNil(fieldControl?.value)) {
    return defaultValue;
  }

  const {value} = fieldControl;
  if (value instanceof Date) {
    return moment(value);
  }

  return value;
};

export const getTimestampFromDateTimeLocalFieldControl = (
  fieldControl: any,
  timezone: string | undefined,
  defaultValue: moment.Moment | undefined,
): moment.Moment | undefined => {
  const timeStr = getFieldControlValueOrDefault(fieldControl, undefined);
  const localTime = moment(timeStr, DATETIME_LOCAL_CONTROL_STR_FORMAT);
  if (isNil(timeStr) || !localTime.isValid()) {
    return defaultValue;
  }

  if (isNil(timezone)) {
    return localTime;
  }
  return moment.tz(timeStr, DATETIME_LOCAL_CONTROL_STR_FORMAT, timezone);
};

export const getCommaSeparatedValue = (formField: AbstractControl | null, defaultValue: any): any => {
  if (isNil(formField)) {
    return defaultValue;
  }

  return (formField.value as string)
    .split(',')
    .map((z) => z.trim())
    .filter((z) => z.length > 0);
};
