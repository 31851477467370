import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { cloneDeep, isNil } from 'lodash';
import moment from 'moment-timezone';
import { distinctUntilChanged, map } from 'rxjs';

import { ReservationOfferChannel } from '@pwp-common';

import { controlValues } from '../../../../../../common/form/control-values';
import { FormGroupControlValueAccessor } from '../../../../../generic/abstract-classes/form-group-control-value-accessor';
import { defaultReservationOfferEditorOutput } from '../../editor-output/reservation-offer-config/objects/offers/reservation-offer/default-reservation-offer-editor-output';
import { formToReservationOfferEditorOutput } from '../../editor-output/reservation-offer-config/objects/offers/reservation-offer/form-to-reservation-offer-editor-output/form-to-reservation-offer-editor-output';
import { ReservationOfferFormType } from '../../editor-output/reservation-offer-config/objects/offers/reservation-offer/form-to-reservation-offer-editor-output/reservation-offer-form-type';
import { ReservationOfferEditorOutput } from '../../editor-output/reservation-offer-config/objects/offers/reservation-offer/reservation-offer-editor-output';
import { TaskReservationService } from '../../task-reservation/task-reservation.service';

import { createTimingOptionDisplay } from './get-timing-options/create-timing-option-display';
import { getTimingOptions } from './get-timing-options/get-timing-options';

@UntilDestroy()
@Component({
  selector: 'app-target-offers-editor',
  templateUrl: './target-offers-editor.component.html',
  styleUrls: ['./target-offers-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: NG_VALIDATORS, useExisting: TargetOffersEditorComponent, multi: true },
    { provide: NG_VALUE_ACCESSOR, useExisting: TargetOffersEditorComponent, multi: true },
  ],
})
export class TargetOffersEditorComponent extends FormGroupControlValueAccessor<any, ReservationOfferEditorOutput[]> {
  protected readonly timingOptions$ = this.taskReservationService.timeoutSeconds$.pipe(
    distinctUntilChanged(),
    map((timeoutSeconds) => getTimingOptions(timeoutSeconds)),
  );

  /*
    Note: this is necessary due to a bug on PrimeNG
    PrimeNG dropdown overrides its internal value when the options provided do not contain the current form value
    Then, when the options are updated to provide an corresponding to the current form value, PrimeNG doesn't use the correct form value
    Removing the first emission from taskReservationService.timeoutSeconds fixes the issue for existing workflows, but will break in new workflow dialog, because there is no initial value set
    Will be potentially fixed by this issue: https://github.com/primefaces/primeng/issues/14734
  */
  public readonly selectedOptions$ = controlValues(this.form).pipe(
    map(() => this.form.value.offers.map(({ delay }) => createTimingOptionDisplay(moment.duration(delay)))),
  );

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////
  channels = [
    { label: 'offerChannelPhoneCall', value: ReservationOfferChannel.phoneCall, iconName: 'phone' },
    { label: 'offerChannelText', value: ReservationOfferChannel.text, iconName: 'sms' },
  ];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////
  constructor(
    private taskReservationService: TaskReservationService,
    public changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    // Define Form
    this.form = new UntypedFormGroup({
      offers: new UntypedFormArray([], [Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: ReservationOfferFormType): any {
    const result = formToReservationOfferEditorOutput(value);
    return result;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: ReservationOfferEditorOutput[]) {
    if (isNil(value)) {
      return;
    }

    this.offers.clear({ emitEvent: false });
    for (const offer of value) {
      const newControl = this.makeStepControl(offer);
      this.offers.insert(this.offers.length, newControl, { emitEvent: false });
    }
    this.updateUI();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected makeValidationErrors() {
    return {
      'target-offers-editor': this.form.value,
    };
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Array
  /////////////////////////////////////////////////////////////////////////////////////////////

  get offers(): UntypedFormArray {
    return this.form.get('offers') as UntypedFormArray;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Update UI
  /////////////////////////////////////////////////////////////////////////////////////////////

  private updateUI(): void {
    this.offers.updateValueAndValidity();
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Make Step Control
  /////////////////////////////////////////////////////////////////////////////////////////////

  private makeStepControl(offer: ReservationOfferEditorOutput): UntypedFormGroup {
    return new UntypedFormGroup({
      delay: new UntypedFormControl(offer.delay, [Validators.required]),
      channel: new UntypedFormControl(offer.channel, [Validators.required]),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Remove Step
  /////////////////////////////////////////////////////////////////////////////////////////////

  removeStepAtIndex(index: number) {
    this.offers.removeAt(index);
    this.updateUI();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Add Step
  /////////////////////////////////////////////////////////////////////////////////////////////
  addStepAtEnd() {
    const newControl = this.makeStepControl(cloneDeep(defaultReservationOfferEditorOutput));
    this.offers.insert(this.offers.length, newControl);
    this.updateUI();
  }
}
