import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import { AppModule } from './app/app.module';
import { latestGitCommitHash } from './environments/config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Sentry.init({
  enabled: environment.production,
  dsn: 'https://3dbcf7d7d300421c46705ba10649e09d@o4507873587232768.ingest.us.sentry.io/4507873588674560',
  integrations: [
    Sentry.breadcrumbsIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: `website@${latestGitCommitHash}`,
  tracesSampleRate: 0,
  tracePropagationTargets: ['localhost', /^https:\/\/pwp\.helplinesoftware\.com/],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
  sendClientReports: false,
});

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
