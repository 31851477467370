<ng-container *transloco="let t; read: 'communication-logs-session-hunt'">
  <app-data-table
    [actionsTemplate]="actionsTemplate"
    [cols]="cols"
    [loading]="(tableRows$ | async) === null"
    [selectedCols]="selectedCols"
    [tableRows]="tableRows$"
    [title]="tableTitle"
    [translationScope]="'communication-logs-session-hunt'"
  >
    <ng-template #actionsTemplate>
      <i class="ml-2 text-primary pi pi-spin pi-spinner"></i>
    </ng-template>
  </app-data-table>
</ng-container>
