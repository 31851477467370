/**
 * List all possible commands the system is capable of processing.
 */
export enum DBMatchRuleName {
  anonymousPhone = 'anonymousPhone',
  e164Phone = 'e164Phone',
  docPath = 'docPath',
}

export type DBMatchRuleNameType = keyof typeof DBMatchRuleName;
