import {AnyAsyncServiceRequestSchema} from './any-async-service-request-schema';
import {AsyncServiceRequestName} from '../async-service-request-name';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {AsyncServiceRequest} from '../async-service-request/async-service-request';
import {AsyncServiceRequestE164Phone} from '../../async-service-request-type/async-service-request-e164-phone/async-service-request-e164-phone';

export abstract class AnyAsyncServiceRequest extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): AsyncServiceRequest {
    const typeString = validationResult.value[AnyAsyncServiceRequestSchema.type];
    const type = AsyncServiceRequestName[typeString as keyof typeof AsyncServiceRequestName];
    if (type === undefined) {
      throw new Error(
        `AnyAsyncServiceRequestUser._deserialize: User Error: Cannot deserialize obj with type=${typeString}`
      );
    }

    switch (type) {
      case AsyncServiceRequestName.e164Phone: {
        return new AsyncServiceRequestE164Phone(validationResult.value);
      }
      default: {
        throw new Error(`AnyAsyncServiceRequest._deserialize Unknown type: ${type}`);
      }
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): AnyAsyncServiceRequestSchema {
    return new AnyAsyncServiceRequestSchema();
  }
}
