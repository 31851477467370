export class Time {
  public hour: number;
  public minute: number;
  public second: number;
  public millisecond: number;

  constructor(hour: number, minute: number, second: number, millisecond: number) {
    this.hour = hour;
    this.minute = minute;
    this.second = second;
    this.millisecond = millisecond || 0;
  }

  getHours() {
    return this.hour;
  }

  getMinutes() {
    return this.minute;
  }

  getSeconds() {
    return this.second;
  }

  getMilliseconds() {
    return this.millisecond;
  }

  getTime() {
    return (this.hour * 60 * 60 + this.minute * 60 + this.second) * 1000 + this.millisecond;
  }
}
