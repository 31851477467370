import { inject, Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import {
  FormBuilderVendor,
  FormRequestCreateSubmission,
  FormResponseCreateSubmission,
  FormSubmission,
  serializeToJsonString,
} from '@pwp-common';

import { DbDocumentService } from '../../../services/generic/db-document-service';
import { AuthService } from '../../../services/user/auth/auth.service';

import { FormEndpointService } from './form-endpoint.service';
import { SubmitFormInput } from './interfaces';

@Injectable({
  providedIn: 'root',
})
export class FormSubmissionService extends DbDocumentService<FormSubmission> {
  private readonly formEndpointService = inject(FormEndpointService);

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, FormSubmission);
  }

  public async submitForm({ formVersionId, sessionId, data }: SubmitFormInput): Promise<FormResponseCreateSubmission> {
    const submission = new FormRequestCreateSubmission({
      formVersionId,
      sessionId,
      submission: serializeToJsonString(data),
      submissionId: this.createId(),
      vendor: FormBuilderVendor.surveyJS,
    });

    return this.formEndpointService.makeRequest<FormResponseCreateSubmission>(submission);
  }
}
