import type {ValidationResult} from 'joi';

import {GenericDisplayable} from '../../generic/displayable/generic-displayable';

import {FormConstructor} from './form-constructor';
import {FormSchema} from './form-schema';

export class Form extends GenericDisplayable {
  public activeVersionId!: string;

  protected static _deserialize(validationResult: ValidationResult): Form {
    return new Form(super._deserialize(validationResult));
  }

  constructor(params: FormConstructor) {
    super(params);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(Form.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): FormSchema {
    return new FormSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getActiveVersionId(): string {
    return this.activeVersionId;
  }
}
