<ng-template #dashboardConfigTemplate>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <mat-form-field class="full-width">
          <mat-label>Window</mat-label>
          <mat-select formControlName="window">
            <mat-option *ngFor="let w of possibleWindows" [value]="w">{{ w }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
  Showing plots for:
  <br />
  Start: {{ windowStart | momentPipe }} <br />
  End: {{ windowEnd | momentPipe }}
</ng-template>

<ng-template #dashboardTemplate>
  <div class="row">
    <div class="col">
      <app-calls-by-status [orgStats]="orgStats | async" [window]="window?.value"></app-calls-by-status>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <app-calls-over-time [orgStats]="orgStats | async" [window]="window?.value"></app-calls-over-time>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <app-calls-by-type [callLists]="callLists | async" [orgStats]="orgStats | async" [window]="window?.value"></app-calls-by-type>
    </div>
    <div class="col">
      <app-calls-funnel [orgStats]="orgStats | async" [window]="window?.value"></app-calls-funnel>
    </div>
  </div>
</ng-template>

<app-loading [doneTemplate]="doneTemplate" [loader]="loader">
  <ng-template #doneTemplate>
    <app-analytics-dashboard
      [dashboardConfigTemplate]="dashboardConfigTemplate"
      [dashboardTemplate]="dashboardTemplate"
      [lastRefreshTime]="lastRefreshTime | async"
    >
    </app-analytics-dashboard>
  </ng-template>
</app-loading>
