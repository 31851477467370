import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PanelModule } from 'primeng/panel';
import { TooltipModule } from 'primeng/tooltip';

import { SequenceStepsComponent } from './sequence-steps/sequence-steps.component';

@NgModule({
  declarations: [SequenceStepsComponent],
  exports: [SequenceStepsComponent],
  imports: [CommonModule, ConfirmDialogModule, PanelModule, ReactiveFormsModule, TooltipModule, TranslocoModule],
})
export class SequenceModule {}
