import { Directive, OnChanges, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';
import { Subscription } from 'rxjs';

@UntilDestroy()
@Directive()
export abstract class ComponentWithForm implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  private subscription: Subscription;

  public form = new UntypedFormGroup({});

  public loading = true;

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  ///////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm?(): void;

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Get Data Promise
  ///////////////////////////////////////////////////////////////////////////////////////////

  /**
   * The form is set to loading until this promise completes.
   */
  protected getDataPromise(): Promise<unknown> {
    return Promise.resolve();
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Init Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  protected async initState(): Promise<void> {
    this.loading = true;

    await this.getDataPromise();

    this.updateFormUI();
  }

  protected updateFormUI() {
    this.loading = true;
    this.defineForm?.();
    this.subscribeFormChanges();
    this.loading = false;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public ngOnInit(): void {
    this.initState();
  }

  public ngOnChanges(): void {
    this.updateFormUI();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public onFormChanges(value: unknown): void {}

  public subscribeFormChanges(): void {
    if (!isNil(this.subscription)) {
      this.subscription.unsubscribe();
    }

    this.subscription = this.form.valueChanges
      .pipe(
        // Remove because this breaks tests, and it's not clear how to fix it with jasmine.clock()
        // debounceTime(40),
        untilDestroyed(this),
      )
      .subscribe((value) => {
        this.onFormChanges(value);
      });
  }
}
