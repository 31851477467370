import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../generic/serialization/schema-field';
import {SchemaFieldConstants} from '../../../generic/serialization/constants';

/**
 * This object encodes the parameters used to make an API call to Google
 * Cloud Text-To-Speeech.
 *
 * Voices w/ Sample Audio: https://cloud.google.com/text-to-speech/docs/voices
 * Demo for Testing SSML: https://cloud.google.com/text-to-speech
 */
export class SSMLAudioSchema extends SerializableObjectSchema {
  // The SSML text to say:
  public static readonly ssml = 'ssml';

  // URL where file can be downloaded
  public static readonly audioURL = 'audioURL';

  // Track when this object was last modified.
  public static readonly lastChangeTimeSSML = 'lastChangeTimeSSML';

  // Last time when audio was last generated
  public static readonly lastChangeTimeAudio = 'lastChangeTimeAudio';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly ssml = '';
    public static readonly audioURL = '';
    public static readonly lastChangeTimeSSML = undefined; // This field is set only by the server.
    public static readonly lastChangeTimeAudio = undefined; // This field is set only by the server.
    public static readonly isProcessedByServer = false; // This field is set only by the server.
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default values to serialize
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([
      // Serialize these two values so that we can overwrite any existing value by simply setting the default value.
      SSMLAudioSchema.ssml,
      SSMLAudioSchema.audioURL,
    ]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[SSMLAudioSchema.ssml] = SchemaField.string(SSMLAudioSchema.Defaults.audioURL, undefined, SchemaFieldConstants.longStringMaxLength);
    parameters[SSMLAudioSchema.audioURL] = SchemaField.string(SSMLAudioSchema.Defaults.audioURL, undefined, SchemaFieldConstants.longStringMaxLength);
    parameters[SSMLAudioSchema.lastChangeTimeSSML] = SchemaField.timestamp(SSMLAudioSchema.Defaults.lastChangeTimeSSML);
    parameters[SSMLAudioSchema.lastChangeTimeAudio] = SchemaField.timestamp(SSMLAudioSchema.Defaults.lastChangeTimeAudio);
    /* eslint-enable */

    return parameters;
  }
}
