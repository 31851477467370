import {Ajv, ErrorObject} from 'ajv';

import surveyJSSchema from './survey-js-schema.json';

/**
 * @description Validate that the SurveyJS schema did not change from the expected version.
 * Avoids unexpected schema changes that can happen accidentally with library updates.
 */
export const getSurveyJSJsonSchemaErrors = (value: string): ErrorObject[] => {
  const ajv = new Ajv();
  const validate = ajv.compile(surveyJSSchema);
  validate(JSON.parse(value));

  return validate.errors ?? [];
};
