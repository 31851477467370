import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';
import { isNil } from 'lodash';

import { FlagServiceRequest, FlagServiceRequestSchema } from '@pwp-common';

import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';

const VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ServiceCommandEditorFlagServiceRequestComponent),
  multi: true,
};

const VALIDATOR = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => ServiceCommandEditorFlagServiceRequestComponent),
  multi: true,
};

@UntilDestroy()
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-service-command-editor-flag-service-request',
  templateUrl: './service-command-editor-flag-service-request.component.html',
  styleUrls: ['./service-command-editor-flag-service-request.component.css'],
  providers: [VALUE_ACCESSOR, VALIDATOR],
})
export class ServiceCommandEditorFlagServiceRequestComponent extends FormGroupControlValueAccessor<any, any> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineForm() {
    this.form = new UntypedFormGroup({
      commandName: new UntypedFormControl(FlagServiceRequestSchema.Defaults.commandName, [Validators.required]),
      deny: new UntypedFormControl(undefined, [Validators.requiredTrue]),
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): any {
    if (isNil(value) || isNil(value[FlagServiceRequestSchema.deny])) {
      // The default value for this form is not a valid selection, so filter it out here.
      return undefined;
    }
    return FlagServiceRequest.deserialize(value).serialize();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get deny() {
    return this.form.get(FlagServiceRequestSchema.deny) as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  public makeValidationErrors(): ValidationErrors {
    return {
      'service-command-editor-flag-service-request': this.form.value,
    };
  }
}
