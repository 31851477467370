<ng-container *transloco="let t; read: 'confirm-with-input'">
  <legend>{{ data?.title }}</legend>

  <p [innerHTML]="data?.body"></p>

  <form *ngIf="!loading" [formGroup]="form">
    {{ t('confirmStr', { value: data.requiredConfirmationString }) }}
    <!-- New Email Address -->
    <div class="form-row">
      <div class="col">
        <mat-form-field class="full-width">
          <input matInput placeholder="{{ data.requiredConfirmationString }}" class="full-width" formControlName="confirmationString" />
          <div *ngIf="confirmationString.invalid && confirmationString.dirty">
            <mat-error *ngIf="confirmationString.errors?.required">
              {{ t('errorRequired') }}
            </mat-error>
            <mat-error *ngIf="confirmationString.errors?.equals">
              {{ t('errorNotEqual', { value: data.requiredConfirmationString }) }}
            </mat-error>
          </div>
        </mat-form-field>
      </div>
    </div>
  </form>

  <button mat-button color="warn" [disabled]="!form.valid" (click)="confirm()">{{ t('confirmButton') }}</button>
  <button mat-button color="primary" (click)="cancel()">{{ t('cancelButton') }}</button>
</ng-container>
