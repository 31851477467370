import { translate } from '@ngneat/transloco';
import { upperFirst } from 'lodash';

import { MakePTableColsInput } from './interfaces';
import { PTableCol } from './p-table-col';

export const makePTableCols = ({ columns, translationScope }: MakePTableColsInput): PTableCol[] =>
  columns.map((col) => {
    const header = `col${upperFirst(col)}`;

    return {
      field: col,
      header,
      label: translate(`${translationScope}.${header}`),
    };
  });
