import {ServiceException} from '../service-exception/service-exception/service-exception';
import {ServiceOperationData} from '../../../voice-response-command/service-operation/service-operation-data/service-operation-data';
import {AllDataServiceException} from '../all-data-service-exception/all-data-service-exception';
import {AllDataServiceExceptionActionInboundCallSession} from '../all-data-service-exception-action-inbound-call-session/all-data-service-exception-action-inbound-call-session';
import {AllDataServiceOperation} from '../../../voice-response-command/service-operation/all-data-service-operation/all-data-service-operation';

export const makeAllDataServiceExceptionMap = (
  serviceExceptions: ServiceException[],
  initialData?: ServiceOperationData
): Map<string, AllDataServiceException> => {
  const allDataServiceExceptionsMap = new Map<string, AllDataServiceException>();
  for (const serviceException of serviceExceptions) {
    try {
      const inboundCallSessionAction = new AllDataServiceExceptionActionInboundCallSession({
        onCreate: new AllDataServiceOperation(serviceException.getInboundCallSessionAction().getOnCreate(), initialData),
      });
      const allDataServiceException: AllDataServiceException = new AllDataServiceException(serviceException, inboundCallSessionAction);
      allDataServiceExceptionsMap.set(serviceException.getId(), allDataServiceException);
    } catch (error) {
      continue;
    }
  }
  return allDataServiceExceptionsMap;
};
