import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { isNil } from 'lodash';

import { CallStatsOverTime, EntityStats, EntityStatsDisplayWindow } from '@pwp-common';

@Component({
  selector: 'app-calls-over-time',
  templateUrl: './calls-over-time.component.html',
  styleUrls: ['./calls-over-time.component.css'],
})
export class CallsOverTimeComponent implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() orgStats: EntityStats;

  @Input() window: EntityStatsDisplayWindow;

  traces: any = [];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor() {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if ('orgStats' in changes || 'window' in changes) {
      this.refreshData();
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Data
  /////////////////////////////////////////////////////////////////////////////////////////////

  private refreshData() {
    this.resetData();
    if (isNil(this.orgStats) || isNil(this.window)) {
      return;
    }

    const callStatsOverTime = new CallStatsOverTime(this.orgStats, this.window);

    // Number Calls
    const iNumServiceRequested = callStatsOverTime.getLabeledData(callStatsOverTime.iNumServiceRequested);
    // const iNumServiceRequestedText = [];
    // for (const [_, value] of zip(iNumServiceRequested.getLabels(), iNumServiceRequested.getData())) {
    //   iNumServiceRequestedText.push(`Service was requested in ${value} calls. These are calls where <br> the caller selects an option.`);
    // }

    this.traces.push({
      x: iNumServiceRequested.getLabels(),
      y: iNumServiceRequested.getData(),
      type: 'bar',
      name: 'Requested Service',
      text: iNumServiceRequested.getData(),
      textposition: 'auto',
    });

    // Number Answered
    const iNumAnswered = callStatsOverTime.getLabeledData(callStatsOverTime.iNumAnswered);
    // const iNumAnsweredText = [];
    // for (const [_, __] of zip(iNumAnswered.getLabels(), iNumAnswered.getData())) {
    //   iNumAnsweredText.push(`These calls were connected.`);
    // }
    this.traces.push({
      x: iNumAnswered.getLabels(),
      y: iNumAnswered.getData(),
      type: 'line',
      name: 'Answered',
      // text: iNumAnsweredText,
    });

    // Unexpectedly Missed
    const iNumUnexpectedlyMissed = callStatsOverTime.getLabeledData(callStatsOverTime.iNumUnexpectedlyMissed);
    // const iNumUnexpectedlyMissedText = [];
    // for (const [_, value] of zip(iNumUnexpectedlyMissed.getLabels(), iNumUnexpectedlyMissed.getData())) {
    //   iNumUnexpectedlyMissedText.push(`${value} of the calls put through were missed. This excludes <br> blocked calls and calls rejected due to no capacity.`);
    // }
    this.traces.push({
      x: iNumUnexpectedlyMissed.getLabels(),
      y: iNumUnexpectedlyMissed.getData(),
      type: 'line',
      name: 'Unexpectedly Missed',
      // text: iNumUnexpectedlyMissedText,
    });
  }

  private resetData() {
    this.traces = [];
  }
}
