import { isEmpty } from 'lodash';

import { CalleeEntity, CalleeEntityType, OrgData, UserData } from '@pwp-common';

import { WorkerEditorOutputItem } from '../../core/interface';
import { makeEventOption } from '../../core/make-event-option/make-event-option';
import { makeUserOption } from '../../core/make-user-option/make-user-option';
import { WorkersAutocompleteEditorOutput } from '../../workers-autocomplete-editor-output';

export const calleeEntitiesToEditorOutput = (
  calleeEntities: CalleeEntity[],
  users: Map<string, UserData>,
  orgData: OrgData,
): WorkersAutocompleteEditorOutput => {
  const editorOutput: WorkerEditorOutputItem[] = [];

  if (isEmpty(calleeEntities)) {
    return editorOutput;
  }

  for (const calleeEntity of calleeEntities) {
    switch (calleeEntity.getType()) {
      case CalleeEntityType.userList: {
        for (const userId of calleeEntity.getUserList()) {
          editorOutput.push(makeUserOption(userId, users.get(userId)));
        }
        break;
      }
      case CalleeEntityType.event: {
        for (const assignedUserType of calleeEntity.getAssignedUserTypes()) {
          editorOutput.push(makeEventOption(assignedUserType, calleeEntity.getEventType(), orgData));
        }
        break;
      }
      default: {
        console.error(calleeEntity, calleeEntities, users, orgData);
        throw new Error('calleeEntitiesToEditorOutput: User Error: unknown type');
      }
    }
  }

  return editorOutput;
};
