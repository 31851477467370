import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { EventDataSchema, FieldChangeEnum, FieldChangeTypes, UserData } from '@pwp-common';

import { KVPair } from '../../../../common/objects/kvpair';
import { userDataDisplayFormat } from '../../../../common/objects/user-data-display-format';

@Component({
  selector: 'app-edit-event-properties-dialog',
  templateUrl: './edit-event-properties-dialog.component.html',
  styleUrls: ['./edit-event-properties-dialog.component.css'],
})
export class EditEventPropertiesDialogComponent {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  selectedColor: string = FieldChangeEnum.noChange;

  selectedAssignedUser: KVPair<UserData> | FieldChangeTypes = FieldChangeEnum.noChange;

  selectedAssignedBackupUser: KVPair<UserData> | FieldChangeTypes = FieldChangeEnum.noChange;

  userDataDisplayFormat = userDataDisplayFormat;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditEventPropertiesDialogComponent>,
  ) {}

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Close Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[EventDataSchema.color] = this.selectedColor;

    // We use noChange as a special value to indicating that no change should be made.
    if (typeof this.selectedAssignedUser === 'string') {
      parameters[EventDataSchema.assignedUserId] = this.selectedAssignedUser;
    } else {
      parameters[EventDataSchema.assignedUserId] = this.selectedAssignedUser?.value?.getId();
    }

    if (typeof this.selectedAssignedBackupUser === 'string') {
      parameters[EventDataSchema.assignedBackupUserId] = this.selectedAssignedBackupUser;
    } else {
      parameters[EventDataSchema.assignedBackupUserId] = this.selectedAssignedBackupUser?.value?.getId();
    }
    /* eslint-enable */

    return parameters;
  }
}
