import {cloneDeep} from 'lodash';
import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {CommunicationsResponse} from '../../generic/communications-response/communications-response';
import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {CommunicationsResponseGetCallLogPIIConstructor} from './communications-response-get-call-log-pii-constructor';
import {CommunicationsResponseGetCallLogPIISchema} from './communications-response-get-call-log-pii-schema';
import {CommunicationsResponseGetCallLogPIIError} from './communications-response-get-call-log-pii-error';
import {CommunicationsRequestName} from '../../../communications-request-name';
import {ShakenPassport} from '../../../../../objects/call/stir-shaken/shaken-passport/shaken-passport';
import {TwilioStirVerstat} from '../../../../../objects/call/stir-shaken/twilio-stir-verstat';
import {ParentCallInfoToken} from '../../../../../objects/call/stir-shaken/parent-call-info-token/parent-call-info-token';
import {isAnonymousPhone} from '../../../../../helper/phone-utils/is-anonymous-phone/is-anonymous-phone';

export class CommunicationsResponseGetCallLogPII extends CommunicationsResponse<CommunicationsResponseGetCallLogPIIError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected e164Phone!: string | undefined;
  protected carrierName!: string | undefined;
  protected carrierType!: string | undefined;
  protected callerName!: string | undefined;
  protected callerType!: string | undefined;
  protected iso3166Alpha2CountryCode!: string | undefined;
  protected shakenPassport!: ShakenPassport | undefined;
  protected twilioStirVerstat!: TwilioStirVerstat | undefined;
  protected parentCallInfoToken!: ParentCallInfoToken | undefined;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsResponseGetCallLogPIIConstructor) {
    (parameters as any)[CommunicationsResponseSchema.type] = CommunicationsRequestName.getCallLogPII;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationsResponseGetCallLogPII {
    return new CommunicationsResponseGetCallLogPII(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsResponseGetCallLogPII.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsResponseGetCallLogPIISchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getE164Phone(): string | undefined {
    return cloneDeep(this.e164Phone);
  }

  public getCarrierName(): string | undefined {
    return cloneDeep(this.carrierName);
  }

  public getCarrierType(): string | undefined {
    return cloneDeep(this.carrierType);
  }

  public getCallerName(): string | undefined {
    return cloneDeep(this.callerName);
  }

  public getCallerType(): string | undefined {
    return cloneDeep(this.callerType);
  }

  public getISO3166Alpha2CountryCode(): string | undefined {
    return cloneDeep(this.iso3166Alpha2CountryCode);
  }

  public getParentCallInfoToken(): ParentCallInfoToken | undefined {
    return cloneDeep(this.parentCallInfoToken);
  }

  public getShakenPassport(): ShakenPassport | undefined {
    return cloneDeep(this.shakenPassport);
  }

  public getTwilioStirVerstat(): TwilioStirVerstat | undefined {
    return cloneDeep(this.twilioStirVerstat);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////

  public getCallerPhone(): string | undefined {
    if (!isAnonymousPhone(this.e164Phone)) {
      return this.getE164Phone();
    }

    if (!isAnonymousPhone(this.shakenPassport?.getPayload()?.getOrig()?.getTN())) {
      return this.shakenPassport?.getPayload()?.getOrig().getTN();
    }

    if (!isAnonymousPhone(this.parentCallInfoToken?.getFrom())) {
      return this.parentCallInfoToken?.getFrom();
    }

    /**
     * All known phones associated to this call are anonymous or nil. Select one.
     */
    return this.getE164Phone() ?? this.shakenPassport?.getPayload()?.getOrig().getTN() ?? this.parentCallInfoToken?.getFrom();
  }
}
