import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { TranslocoModule } from '@ngneat/transloco';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';

import { AccordionWizardModule } from '../../generic/accordion-wizard/accordion-wizard.module';
import { DataTableModule } from '../../generic/data-table/data-table.module';
import { ObjAutocompleteV2Module } from '../../generic/obj-autocomplete-v2/obj-autocomplete-v2.module';
import { SettingsModule } from '../../generic/settings/settings.module';
import { ArrayOfPhonesEditorModule } from '../array-of-phones-editor/array-of-phones-editor.module';
import { CallListOfferConfigRingTimeModule } from '../call-list-offer-config-ring-time/call-list-offer-config-ring-time.module';
import { CallerIdStrategyEditorComponent } from '../caller-id-strategy/caller-id-strategy-editor/caller-id-strategy-editor.component';
import { ProgressSpinnerModule } from '../progress-spinner/progress-spinner.module';
import { WorkersAutocompleteModule } from '../workers-autocomplete/workers-autocomplete.module';

import { CallListEditorComponent } from './editor/call-list-editor/call-list-editor.component';
import { CallListTableEditorComponent } from './editor-table/call-list-table-editor/call-list-table-editor.component';
import { CallListTableEditorExpansionComponent } from './editor-table/call-list-table-editor-expansion/call-list-table-editor-expansion.component';
import { CallListAutocompleteComponent } from './select/call-list-autocomplete/call-list-autocomplete.component';

@NgModule({
  declarations: [
    CallListAutocompleteComponent,
    CallListEditorComponent,
    CallListTableEditorComponent,
    CallListTableEditorExpansionComponent,
  ],
  imports: [
    AccordionModule,
    AccordionWizardModule,
    ArrayOfPhonesEditorModule,
    AutoCompleteModule,
    CallerIdStrategyEditorComponent,
    CallListOfferConfigRingTimeModule,
    CommonModule,
    DataTableModule,
    DropdownModule,
    FormsModule,
    InputTextareaModule,
    InputTextModule,
    MatIconModule,
    MessagesModule,
    ObjAutocompleteV2Module,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    SettingsModule,
    ToastModule,
    TranslocoModule,
    WorkersAutocompleteModule,
  ],
  exports: [CallListAutocompleteComponent, CallListTableEditorComponent],
})
export class CallListModule {}
