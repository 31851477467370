import {SerializableObjectSchema} from '../../../objects/generic/serialization/serializable-object-schema';
import {SchemaField} from '../../../objects/generic/serialization/schema-field';

export class ResponseSchema extends SerializableObjectSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly error = 'error';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static GenericDefaults = class {
    public static readonly error = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = {} as any;

    /* eslint-disable */
    parameters[ResponseSchema.error] = SchemaField.string(ResponseSchema.GenericDefaults.error);
    /* eslint-enable */

    return parameters;
  }
}
