import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { isNil } from 'lodash';
import { MessageService } from 'primeng/api';
import { CardModule } from 'primeng/card';
import { Table, TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';

import { NgChanges } from '../../../common/objects/ng-changes';
import { PTableCol } from '../../../common/p-table/p-table-col';
import { AllRequestsForOneEventSlotSummary } from '../common/all-requests-for-one-event-slot-summary';
import { EventRequestDecisionComponent } from '../event-request-decision/event-request-decision.component';

@Component({
  selector: 'app-event-requests-table-one-event',
  standalone: true,
  imports: [CardModule, CommonModule, EventRequestDecisionComponent, TableModule, ToastModule, TranslocoModule],
  templateUrl: './event-requests-table-one-event.component.html',
  styleUrls: ['./event-requests-table-one-event.component.css'],
  providers: [MessageService],
})
export class EventRequestsTableOneEventComponent implements OnChanges {
  ///////////////////////////////////////////////////////////////////////
  // Input
  ///////////////////////////////////////////////////////////////////////

  @Input()
  allRequestsForOneEvent: AllRequestsForOneEventSlotSummary;

  ///////////////////////////////////////////////////////////////////////
  // Variables
  ///////////////////////////////////////////////////////////////////////

  loading = true;

  ///////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////

  cols: PTableCol[] = [
    { field: 'requestedOperation', header: 'colRequestedOperation' },
    { field: 'requestTime', header: 'colRequestTime' },
    { field: 'requesterOrgNote', header: 'colRequesterOrgNote' },
    { field: 'action', header: 'colAction' },
  ];

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  constructor(
    private translocoService: TranslocoService,
    private messageService: MessageService,
  ) {}

  ngOnChanges(changes: NgChanges<EventRequestsTableOneEventComponent>) {
    if (isNil(this.allRequestsForOneEvent)) {
      return;
    }
    this.updateUI();
  }

  ///////////////////////////////////////////////////////////////////////
  // Update UI
  ///////////////////////////////////////////////////////////////////////

  updateUI() {
    this.loading = false;
  }

  ///////////////////////////////////////////////////////////////////////
  // Clear
  ///////////////////////////////////////////////////////////////////////

  clearTable(table: Table) {
    table.clear();
    const title = this.translocoService.translate('event-requests-table-one-event.filtersClearedTitle');

    this.messageService.add({
      severity: 'success',
      summary: title,
      closable: true,
      sticky: false,
      life: 3000,
    });
  }
}
