<ng-template #objEditor>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Reply To -->
        <mat-form-field class="full-width">
          <textarea matInput placeholder="Reply To" class="full-width" formControlName="replyTo"></textarea>
          <span *ngIf="replyTo.invalid && replyTo.touched"> Reply To Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Email Template Types -->
        <mat-form-field class="full-width">
          <mat-label>Email Template Type</mat-label>
          <mat-select formControlName="templateType">
            <mat-option *ngFor="let v of possibleEmailTemplateTypes" [value]="v">{{ v }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-template>

<app-obj-select-and-edit
  [doDelete]="doDelete"
  [doRefresh]="doRefresh"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [label]="'Email Template'"
  [modifiedSelectedItem]="getUpdatedObj()"
  [objIsValid]="isValidObjScoper"
  [orderedItems]="itemsArray | async"
  [selectedKVPair]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (selectedKVPairChange)="onSelectedKVPairChange($event)"
>
</app-obj-select-and-edit>
