import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder } from '@angular/forms';
import { Frequency, RRule, Weekday } from 'rrule';

import { getFieldControlValueOrDefault } from '../../../common/objects/form-helper';
import { ComponentWithEmittingForm } from '../../generic/abstract-classes/component-with-emitting-form';

@Component({
  selector: 'app-rrule-generator',
  templateUrl: './rrule-generator.component.html',
  styleUrls: ['./rrule-generator.component.css'],
})
export class RruleGeneratorComponent extends ComponentWithEmittingForm<string> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  hourly = Frequency.HOURLY;

  daily = Frequency.DAILY;

  weekly = Frequency.WEEKLY;

  monthly = Frequency.MONTHLY;

  yearly = Frequency.YEARLY;

  monday = RRule.MO;

  tuesday = RRule.TU;

  wednesday = RRule.WE;

  thursday = RRule.TH;

  friday = RRule.FR;

  saturday = RRule.SA;

  sunday = RRule.SU;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Close Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  public getObjFromForm(): string {
    const rrule = new RRule({
      freq: getFieldControlValueOrDefault(this.freq, undefined),
      interval: getFieldControlValueOrDefault(this.interval, undefined),
      wkst: getFieldControlValueOrDefault(this.wkst, undefined),
      byweekday: getFieldControlValueOrDefault(this.byweekday, undefined),
    }).toString();

    return rrule;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;

    const _rrule = RRule.fromString(this.obj);

    formConfig.byweekday = [_rrule.options.byweekday.map((z) => this.getDay(z))];
    formConfig.interval = [_rrule.options.interval];
    formConfig.freq = [_rrule.options.freq];
    formConfig.wkst = [this.getDay(_rrule.options.wkst)];

    this.form = this.formBuilder.group(formConfig);
  }

  private getDay(dayNum: number): Weekday {
    const genDay = new Weekday(dayNum);
    for (const day of [this.sunday, this.monday, this.tuesday, this.wednesday, this.thursday, this.friday, this.saturday]) {
      if (genDay.weekday === day.weekday) {
        return day;
      }
    }
    console.error(dayNum);
    throw new Error('getDay: User Error, invalid day:');
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Form Field Getters
  /////////////////////////////////////////////////////////////////////////////////////////////

  get byweekday(): AbstractControl | null {
    return this.form.get('byweekday');
  }

  get interval(): AbstractControl | null {
    return this.form.get('interval');
  }

  get freq(): AbstractControl | null {
    return this.form.get('freq');
  }

  get wkst(): AbstractControl | null {
    return this.form.get('wkst');
  }
}
