import { cloneDeep, isNil } from 'lodash';

import { CallList, OrgData, UserData } from '@pwp-common';

import { makeCallListOfferConfigRingTimeEditorOutput } from '../../../../call-list-offer-config-ring-time/editor/editor-output/make-offer-config-ring-time-editor-output/make-call-list-offer-config-ring-time-editor-output';
import { dbToCallerIdStrategyEditorOutput } from '../../../../caller-id-strategy/editor-output/db-to-caller-id-strategy-editor-output/db-to-caller-id-strategy-editor-output';
import { calleeEntitiesToEditorOutput } from '../../../../workers-autocomplete/editor-output/callee-entities/callee-entities-to-editor-output/callee-entities-to-editor-output';
import { CallListEditorOutput } from '../call-list-editor-output';
import { defaultCallListEditorOutput } from '../default-call-list-editor-output';

export const makeCallListEditorOutput = (
  input: CallList | CallListEditorOutput,
  users: Map<string, UserData>,
  orgData: OrgData,
): CallListEditorOutput => {
  if (isNil(input)) {
    return cloneDeep(defaultCallListEditorOutput);
  }

  if (input instanceof CallList) {
    const result: CallListEditorOutput = {
      id: input.getId(),
      displayName: input.getDisplayName(),
      description: input.getDescription(),
      calleeEntities: calleeEntitiesToEditorOutput(input.getCalleeEntities(), users, orgData),
      offerConfig: {
        ringTime: makeCallListOfferConfigRingTimeEditorOutput(input),
        callerId: dbToCallerIdStrategyEditorOutput(input?.getOfferConfig()),
      },
    };

    return result;
  }

  return input;
};
