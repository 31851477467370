import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { firstValueFrom } from 'rxjs';

import {
  AnyCommunicationsResponse,
  CommunicationSessionId,
  CommunicationsRequest,
  CommunicationsRequestCreateAsyncServiceRequest,
  CommunicationsRequestCreateAsyncServiceRequestConstructor,
  CommunicationsRequestCreateOutboundCallSession,
  CommunicationsRequestDialAsyncServiceRequest,
  CommunicationsRequestDialConference,
  CommunicationsRequestDialPhone,
  CommunicationsRequestGetCallLogPII,
  CommunicationsRequestGetConversationLogPII,
  CommunicationsRequestGetVoiceDeviceToken,
  CommunicationsRequestHandleAsyncServiceRequest,
  CommunicationsRequestName,
  CommunicationsRequestOnTaskRouterConfigurationUpdated,
  CommunicationsResponse,
  CommunicationsResponseCreateAsyncServiceRequest,
  CommunicationsResponseCreateOutboundCallSession,
  CommunicationsResponseDialAsyncServiceRequest,
  CommunicationsResponseDialConference,
  CommunicationsResponseDialPhone,
  CommunicationsResponseGetCallLogPII,
  CommunicationsResponseGetConversationLogPII,
  CommunicationsResponseGetVoiceDeviceToken,
  CommunicationsResponseHandleAsyncServiceRequest,
  CommunicationsResponseOnTaskRouterConfigurationUpdated,
} from '@pwp-common';

import { CallableFunctionService } from '../../generic/callable-function-service/callable-function-service';
import { AuthService } from '../../user/auth/auth.service';

type CreateAsyncServiceRequestInput = Omit<CommunicationsRequestCreateAsyncServiceRequestConstructor, 'type'>;

@Injectable({
  providedIn: 'root',
})
export class CommunicationsService extends CallableFunctionService<CommunicationsRequest, CommunicationsResponse<any>> {
  ///////////////////////////////////////////////////////////////////////
  // Cache
  ///////////////////////////////////////////////////////////////////////

  private getCallLogPIICache: Map<string, CommunicationsResponseGetCallLogPII> = new Map();

  private getConversationLogPIICache: Map<string, CommunicationsResponseGetConversationLogPII> = new Map();

  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(
    _fns: AngularFireFunctions,
    private authService: AuthService,
  ) {
    super(AnyCommunicationsResponse, _fns);
  }
  ///////////////////////////////////////////////////////////////////////
  // Function Name
  ///////////////////////////////////////////////////////////////////////

  public getFunctionName() {
    return 'communications';
  }

  ///////////////////////////////////////////////////////////////////////
  // Dial Async Service Request
  ///////////////////////////////////////////////////////////////////////
  public async createAsyncServiceRequest(params: CreateAsyncServiceRequestInput): Promise<CommunicationsResponseCreateAsyncServiceRequest> {
    const result: CommunicationsResponseCreateAsyncServiceRequest = (await this.makeRequest(
      new CommunicationsRequestCreateAsyncServiceRequest({
        ...params,
        type: CommunicationsRequestName.createAsyncServiceRequest,
      }),
    )) as CommunicationsResponseCreateAsyncServiceRequest;

    return result;
  }

  ///////////////////////////////////////////////////////////////////////
  // Create Outbound Call Session
  ///////////////////////////////////////////////////////////////////////
  public async createOutboundCallSession(
    request: CommunicationsRequestCreateOutboundCallSession,
  ): Promise<CommunicationsResponseCreateOutboundCallSession> {
    const result = (await this.makeRequestOLD(request).toPromise()) as CommunicationsResponseCreateOutboundCallSession;
    return result;
  }

  ///////////////////////////////////////////////////////////////////////
  // Dial Async Service Request
  ///////////////////////////////////////////////////////////////////////
  public async dialAsyncServiceRequest(params: {
    callerIdObjId: string;
    asyncServiceRequestId: string;
    sessionId: CommunicationSessionId;
  }): Promise<CommunicationsResponseDialAsyncServiceRequest> {
    const loggedInUserId = await firstValueFrom(this.authService.getUserId());
    // Start by creating an outbound call session
    const dialConferenceResult = (await this.makeRequest(
      new CommunicationsRequestDialConference({
        type: CommunicationsRequestName.dialConference,
        dialUserId: loggedInUserId,
        sessionId: params.sessionId,
        callerIdObjId: params.callerIdObjId,
        conferenceFriendlyName: undefined,
      }),
      { numRetries: 0, retryTimeoutMS: 60 * 1000 },
    )) as CommunicationsResponseDialConference;

    // Dial the target into this phone.
    const dialAsyncServiceRequest: CommunicationsResponseDialAsyncServiceRequest = (await this.makeRequest(
      new CommunicationsRequestDialAsyncServiceRequest({
        type: CommunicationsRequestName.dialAsyncServiceRequest,
        asyncServiceRequestId: params.asyncServiceRequestId,
        callerIdObjId: params.callerIdObjId,
        sessionId: params.sessionId,
        conferenceFriendlyName: dialConferenceResult.getConferenceFriendlyName(),
      }),
      { numRetries: 0, retryTimeoutMS: 60 * 1000 },
    )) as CommunicationsResponseDialAsyncServiceRequest;

    return dialAsyncServiceRequest;
  }

  ///////////////////////////////////////////////////////////////////////
  // Dial By Phone
  ///////////////////////////////////////////////////////////////////////
  public async dialByPhone(params: { callerIdObjId: string; e164Phone: string }): Promise<CommunicationsResponseDialPhone> {
    // Start by creating an outbound call session
    const outboundCallSession: CommunicationsResponseCreateOutboundCallSession = (await this.makeRequest(
      new CommunicationsRequestCreateOutboundCallSession({
        type: CommunicationsRequestName.createOutboundCallSession,
        callerIdObjId: params.callerIdObjId,
      }),
      { numRetries: 0, retryTimeoutMS: 60 * 1000 },
    )) as CommunicationsResponseCreateOutboundCallSession;

    // Dial the target into this phone.
    const dialPhoneResult: CommunicationsResponseDialPhone = (await this.makeRequest(
      new CommunicationsRequestDialPhone({
        type: CommunicationsRequestName.dialPhone,
        callerIdObjId: params.callerIdObjId,
        e164Phone: params.e164Phone,
        outboundCallSessionEventId: outboundCallSession.getOutboundCallSessionEventId(),
        outboundCallSessionId: outboundCallSession.getOutboundCallSessionId(),
      }),
      { numRetries: 0, retryTimeoutMS: 60 * 1000 },
    )) as CommunicationsResponseDialPhone;

    return dialPhoneResult;
  }

  ///////////////////////////////////////////////////////////////////////
  // Dial Phone
  ///////////////////////////////////////////////////////////////////////

  public async dialPhone(request: CommunicationsRequestDialPhone): Promise<CommunicationsResponseDialPhone> {
    const result = (await this.makeRequestOLD(request).toPromise()) as CommunicationsResponseDialPhone;
    return result;
  }
  ///////////////////////////////////////////////////////////////////////
  // Get Call Log PII
  ///////////////////////////////////////////////////////////////////////

  public async getCallLogPII(sessionId: string): Promise<CommunicationsResponseGetCallLogPII> {
    if (this.getCallLogPIICache.has(sessionId)) {
      return this.getCallLogPIICache.get(sessionId);
    }

    const request = new CommunicationsRequestGetCallLogPII({
      type: CommunicationsRequestName.getCallLogPII,
      sessionId,
    });

    const value = (await this.makeRequest(request)) as CommunicationsResponseGetCallLogPII;
    this.getCallLogPIICache.set(sessionId, value);

    return this.getCallLogPIICache.get(sessionId);
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Conversation Log PII
  ///////////////////////////////////////////////////////////////////////
  public async getConversationLogPII(
    request: CommunicationsRequestGetConversationLogPII,
  ): Promise<CommunicationsResponseGetConversationLogPII> {
    const key = request.getConversationLogId();
    if (this.getConversationLogPIICache.has(key)) {
      return this.getConversationLogPIICache.get(key);
    }
    const value = (await this.makeRequest(request)) as CommunicationsResponseGetConversationLogPII;
    this.getConversationLogPIICache.set(key, value);
    return this.getConversationLogPIICache.get(key);
  }

  public async getVoiceDeviceToken(): Promise<string> {
    const request = new CommunicationsRequestGetVoiceDeviceToken({});
    const response = await this.makeRequest<CommunicationsResponseGetVoiceDeviceToken>(request);

    return response.getToken();
  }

  ///////////////////////////////////////////////////////////////////////
  // Handle Async ServiceRequest
  ///////////////////////////////////////////////////////////////////////
  public async handleAsyncServiceRequest(
    request: CommunicationsRequestHandleAsyncServiceRequest,
  ): Promise<CommunicationsResponseHandleAsyncServiceRequest> {
    return (await this.makeRequest(request, {
      numRetries: 0,
      retryTimeoutMS: 60 * 1000,
    })) as CommunicationsResponseHandleAsyncServiceRequest;
  }

  ///////////////////////////////////////////////////////////////////////
  // Task Router Configuration Updated
  ///////////////////////////////////////////////////////////////////////
  public async onTaskRouterConfigurationUpdated(): Promise<CommunicationsResponseOnTaskRouterConfigurationUpdated> {
    const request = new CommunicationsRequestOnTaskRouterConfigurationUpdated({
      type: CommunicationsRequestName.onTaskRouterConfigurationUpdated,
    });
    return (await this.makeRequest(request, {
      numRetries: 0,
      retryTimeoutMS: 60 * 1000,
    })) as CommunicationsResponseOnTaskRouterConfigurationUpdated;
  }
}
