import { cloneDeep, isEmpty } from 'lodash';

import {
  EventRequestConfig,
  EventRequestConfigSchema,
  EventRequestRole,
  isNilOrDefaultDocId,
  simplifyEventRequestConfigRoles,
} from '@pwp-common';

import { EventRequestConfigEditorOutput } from '../../interfaces';

export const editorOutputToDb = (params: {
  eventRequestConfig: EventRequestConfig;
  editorOutput: EventRequestConfigEditorOutput;
  eventRequestConfigId: string;
}): EventRequestConfig => {
  /*****************************************************************************************
   * Sanity checks
   *****************************************************************************************/
  if (isNilOrDefaultDocId(params.eventRequestConfigId) || isEmpty(params.eventRequestConfigId)) {
    console.error(params);
    throw new Error('editorOutputToDb: eventRequestConfig id is missing');
  }

  /*****************************************************************************************
   * Convert editor output to EventRequestConfig
   *****************************************************************************************/
  const editorOutput = cloneDeep(params.editorOutput);
  const newEventRequestConfig = new EventRequestConfig({
    id: params.eventRequestConfigId,
    primary: [],
    backup: [],
  });
  const updatedObj = cloneDeep(params.eventRequestConfig ? params.eventRequestConfig : newEventRequestConfig);

  // Set the primary
  const primary: EventRequestRole[] = [];
  if (editorOutput.allowRequestPrimary) {
    primary.push(EventRequestRole.allowRequest);
  }
  if (editorOutput.autoApprovePrimary) {
    primary.push(EventRequestRole.approveRequest);
  }

  // Set the backup
  const backup: EventRequestRole[] = [];
  if (editorOutput.allowRequestBackup) {
    backup.push(EventRequestRole.allowRequest);
  }
  if (editorOutput.autoApproveBackup) {
    backup.push(EventRequestRole.approveRequest);
  }

  updatedObj.setField(EventRequestConfigSchema.id, params.eventRequestConfigId);
  updatedObj.setField(EventRequestConfigSchema.backup, simplifyEventRequestConfigRoles(backup));
  updatedObj.setField(EventRequestConfigSchema.primary, simplifyEventRequestConfigRoles(primary));
  updatedObj.setField(EventRequestConfigSchema.emailOnNewRequestCreated, editorOutput.emailOnNewRequestCreated);
  updatedObj.sanityCheck();
  return updatedObj;
};
