import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { ExternalChatRequest, ExternalChatRequestAction, ExternalChatResponse } from '@pwp-common';

import { CallableFunctionService } from '../../generic/callable-function-service/callable-function-service';

@Injectable({
  providedIn: 'root',
})
export class ExternalChatEndpointService extends CallableFunctionService<ExternalChatRequest, ExternalChatResponse> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(fns: AngularFireFunctions) {
    super(ExternalChatResponse, fns);
  }
  ///////////////////////////////////////////////////////////////////////
  // Function Name
  ///////////////////////////////////////////////////////////////////////

  public getFunctionName() {
    return 'externalChat';
  }

  ///////////////////////////////////////////////////////////////////////
  // Jwt
  ///////////////////////////////////////////////////////////////////////

  public async getNewAnonymousChatJwt(orgId: string) {
    const request = new ExternalChatRequest({ action: ExternalChatRequestAction.newAnonymousChat, orgId });
    const response = await this.makeRequest(request, { log: true });
    console.log(response);
    return response;
  }
}
