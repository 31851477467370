import { isNil } from 'lodash';

import { AllDataUser } from '@pwp-common';

import { InboxRowHandleStatus } from '../inbox-row-handle-status';

export const getInboxRowHandleStatus = (params: {
  loggedInUserId?: string;
  handledByUserId: string;
  allDataUserMap: Map<string, AllDataUser>;
}): InboxRowHandleStatus => {
  if (isNil(params.handledByUserId) || params.handledByUserId === '') {
    return InboxRowHandleStatus.notHandled;
  }

  if (params.handledByUserId === params.loggedInUserId) {
    return InboxRowHandleStatus.handledBySelf;
  }

  if (params.allDataUserMap.has(params.handledByUserId)) {
    return InboxRowHandleStatus.handledByOtherUser;
  }

  return InboxRowHandleStatus.handledByDeletedUser;
};
