<ng-container *transloco="let t; read: 'communication-workflow-editor'">
  <p-toast position="bottom-center"></p-toast>
  <app-wizard-steps [complete]="completeWrapper" [completeLabel]="t('completeButtonLabel')" [stepsReadonly]="true">
    <app-wizard-step [hasError]="general.invalid" [label]="t('generalStepLabel')">
      <app-general-tab-editor [form]="form"></app-general-tab-editor>
    </app-wizard-step>

    <app-wizard-step [hasError]="form.invalid" [label]="t('targetsStepLabel')">
      <app-targets-editor [form]="form"></app-targets-editor>
    </app-wizard-step>
  </app-wizard-steps>
</ng-container>
