import {cloneDeep} from 'lodash';
import {ShakenPassportConstructor} from './shaken-passport-constructor';
import {ShakenPassportSchema} from './shaken-passport-schema';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {ShakenHeader} from '../shaken-header/shaken-header';
import {ShakenPayload} from '../shaken-payload/shaken-payload';

export class ShakenPassport extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected header!: ShakenHeader;
  protected payload!: ShakenPayload;
  protected signature!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ShakenPassportConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ShakenPassport {
    return new ShakenPassport(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ShakenPassport.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ShakenPassportSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getHeader(): ShakenHeader {
    return cloneDeep(this.header);
  }

  public getPayload(): ShakenPayload {
    return cloneDeep(this.payload);
  }

  public getSignature(): string {
    return cloneDeep(this.signature);
  }
}
