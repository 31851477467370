import {ServiceExceptionActionSchema} from '../service-exception-action/service-exception-action-schema';

export class ServiceExceptionActionInboundCallSessionSchema extends ServiceExceptionActionSchema {
  public static readonly onCreate = 'onCreate';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly onCreate = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    /* eslint-enable */

    return parameters;
  }
}
