import type {AnySchema} from 'joi';
import Joi from 'joi';

import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {FormResponseCreateVersionSchema} from '../../responses/form-response-create-version/form-response-create-version-schema';
import {FormResponseCreateSubmissionSchema} from '../../responses/form-response-create-submission/form-response-create-submission-schema';

export class AnyFormResponseSchema extends SerializableObjectSchema {
  private static readonly SCHEMA_ID = 'anyFormResponse';

  private static computedSchema = Joi.alternatives()
    .try(new FormResponseCreateSubmissionSchema().getJoiSchema(), new FormResponseCreateVersionSchema().getJoiSchema())
    .id(AnyFormResponseSchema.SCHEMA_ID);

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Defaults
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly GenericDefaults = class {
    public static readonly type = undefined;
  };

  public static getSchemaId(): string {
    return `#${AnyFormResponseSchema.SCHEMA_ID}`;
  }

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} | AnySchema {
    return AnyFormResponseSchema.computedSchema;
  }
}
