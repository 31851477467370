<ng-template #objEditor>
  <form *ngIf="!loading" [formGroup]="form">
    <div class="form-row">
      <div class="col">
        <!-- Display Name -->
        <mat-form-field class="full-width">
          <textarea matInput placeholder="Display Name" class="full-width" formControlName="displayName"></textarea>
          <span *ngIf="displayName.invalid && displayName.touched"> Display Name Invalid </span>
        </mat-form-field>
      </div>
      <div class="col">
        <!-- Description -->
        <mat-form-field class="full-width">
          <textarea matInput placeholder="Description" class="full-width" formControlName="description"></textarea>
          <span *ngIf="description.invalid && description.touched"> Description Invalid </span>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row full-width">
      <div class="col full-width">
        <!-- Communication Workflow Id -->
        <p>Communication Workflow</p>
        <app-communication-workflow-select
          class="full-width"
          id="communicationWorkflowId"
          [formControl]="communicationWorkflowId"
          [placeholder]="'Communication Workflow'"
          [required]="false"
        ></app-communication-workflow-select>
      </div>
    </div>
  </form>

  <mat-accordion>
    <!-- On Complete -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> On Complete </mat-panel-title>
        <mat-panel-description> </mat-panel-description>
      </mat-expansion-panel-header>
      <app-vrc-list-editor [(objList)]="onCompleteCommands"></app-vrc-list-editor>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>

<app-obj-select-and-edit
  [doDelete]="doDelete"
  [doRefresh]="doRefresh"
  [doUpload]="doUpload"
  [editorTemplate]="objEditor"
  [label]="'Communication Widget Create Async Service Request'"
  [modifiedSelectedItem]="getUpdatedObj()"
  [objIsValid]="isValidObjScoper"
  [orderedItems]="itemsArray | async"
  [selectedKVPair]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (selectedKVPairChange)="onSelectedKVPairChange($event)"
>
</app-obj-select-and-edit>
