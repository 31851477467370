<app-obj-select-and-edit
  [doDelete]="doDelete"
  [editorTemplate]="objEditor"
  [label]="'VRC List'"
  [(orderedItems)]="objList"
  [(selectedKVPair)]="selectedKVPair"
  (duplicateClick)="duplicateClick()"
  (newClick)="newClick()"
  (orderedItemsChange)="updateAndEmit()"
>
  <ng-template #objEditor let-index="id" let-item="item" let-kvPair>
    <p>Editing Command at Index: {{ this.selectedKVPair?.id }}</p>

    <app-any-vrc-editor
      *ngIf="selectedKVPair !== undefined && selectedKVPair.value !== undefined"
      [callListArray]="callListArray | async"
      [communicationWidgetAsyncServiceRequestArray]="communicationWidgetAsyncServiceRequestArray | async"
      [ivrsArray]="ivrsArray | async"
      [voicemailConfigArray]="voicemailConfigArray | async"
      [vrcAudioMetadataArray]="vrcAudioMetadataArray | async"
      [(obj)]="selectedKVPair"
      (objChange)="updateAndEmit()"
    >
    </app-any-vrc-editor>
  </ng-template>
</app-obj-select-and-edit>
