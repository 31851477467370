/**
 * List of reasons we stop dialing for a given sessionId.
 */

export enum StoppedDialingReason {
  noCapacity = 'noCapacity',

  // No users remaining to dial
  noUsersLeftToDial = 'noUsersLeftToDial',

  // We stop dialing because the associated incoming call is not active.
  incomingCallDisconnected = 'incomingCallDisconnected',

  // Stopped dialing because the call was answered
  wasAnswered = 'wasAnswered',
}
