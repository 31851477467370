import {DBDocObject} from '../../../objects/generic/db-doc/db-doc-object';
import {DBAction} from './db-action';

export class DBUploadExistingDoc<T extends DBDocObject> extends DBAction<T> {
  public obj: T;

  ////////////////////////////////////////////////////////////////////////
  // Constructor
  ////////////////////////////////////////////////////////////////////////

  constructor(obj: T) {
    super();
    this.obj = obj;
  }
}
