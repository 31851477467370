import { isNil } from 'lodash';

import { Displayable, LanguageDefaults, SSMLAudio, SSMLAudioSchema } from '@pwp-common';

/**
 * This object encodes a pair of objects, an AudioSSML object and possibly
 * a file. We have a form that produces valid instances of this class, which
 * are ultimately used to construct a VRCAudioMetadata object along with any
 * backing audio files.
 */
export class FileSSMLAudioPair implements Displayable {
  ////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  ////////////////////////////////////////////////////////////////////////////////

  constructor(
    public languageDefaults: LanguageDefaults,
    public ssmlAudio: SSMLAudio,
    public file?: File,
  ) {}

  ////////////////////////////////////////////////////////////////////////////////
  // Displayable Interface
  ////////////////////////////////////////////////////////////////////////////////

  public getId() {
    return this.languageDefaults.getId();
  }

  public getDisplayName() {
    return this.languageDefaults.getDisplayName();
  }

  ////////////////////////////////////////////////////////////////////////////////
  // Serialize
  ////////////////////////////////////////////////////////////////////////////////

  /**
   * Used to display the object for the debug view.
   */
  public serialize() {
    return { file: this.file, ssmlAudio: this.ssmlAudio.serialize() };
  }

  public isValid(): boolean {
    const fileIsDefault = isNil(this.file);
    const audioURLIsDefault = this.ssmlAudio.getAudioURL() === SSMLAudioSchema.Defaults.audioURL;
    const ssmlIsDefault = this.ssmlAudio.getSSML() === SSMLAudioSchema.Defaults.ssml;

    if (fileIsDefault && audioURLIsDefault && ssmlIsDefault) {
      return false;
    }
    return true;
  }
}
