import { orderBy } from 'lodash';

import { Note, UserData } from '@pwp-common';

import { CallerDetailsNoteDisplay } from './interfaces';

export const createNotesDisplay = (notes: Note[], users: Map<string, UserData>): CallerDetailsNoteDisplay[] =>
  orderBy(notes, (note) => note.getCreateTime()).map((note) => ({
    createdBy: users.get(note.getCreatedByUserId())?.getDisplayName(),
    createdAt: note.getCreateTime().format('L LT'),
    isRedacted: note.isRedacted(),
    value: note.getBody()?.getValue() as string,
  }));
