<ng-container *transloco="let t; read: 'conversation-config-editor'">
  <div *ngIf="!loading">
    <!---------------------------------------------->
    <!-- Available Times -->
    <!---------------------------------------------->

    <ng-template #objEditor>
      <!-- Available Times Editor-->
      <app-edit-available-hours-by-dow [description]="t('availableTimesDescription')" [(obj)]="availableTimesEditorInput">
      </app-edit-available-hours-by-dow>
      <!-- Durations -->
      <form [formGroup]="form">
        <!-- Inactive After Duration -->
        <div class="field">
          <label for="inactiveAfterDuration">{{ t('inactiveAfterDurationLabel') }}</label>
          <div class="inputfield w-full">
            <p-inputNumber
              ariaLabel="{{ t('inactiveAfterDurationLabel') }}"
              id="inactiveAfterDuration"
              mode="decimal"
              suffix=" {{ t('minutes') }}"
              [formControl]="inactiveAfterDuration"
              [max]="1440"
              [min]="5"
              [showButtons]="true"
            >
            </p-inputNumber>
            <p id="inactiveAfterDuration-help">
              {{ t('inactiveAfterDurationHelp', { numMinutes: form.get('inactiveAfterDuration').value }) }}
            </p>
          </div>
        </div>
        <!-- Closed After Duration -->
        <div class="field">
          <label for="closedAfterDuration">{{ t('closedAfterDurationLabel') }}</label>
          <div class="inputfield w-full">
            <p-inputNumber
              ariaLabel="{{ t('closedAfterDurationLabel') }}"
              id="closedAfterDuration"
              mode="decimal"
              suffix=" {{ t('minutes') }}"
              [formControl]="closedAfterDuration"
              [max]="240"
              [min]="15"
              [showButtons]="true"
            >
            </p-inputNumber>
            <p id="closedAfterDuration-help">{{ t('closedAfterDurationHelp', { numMinutes: form.get('closedAfterDuration').value }) }}</p>
          </div>
        </div>
        <!-- Message Retention Duration -->
        <div class="field">
          <label for="pIIRetentionDuration">{{ t('pIIRetentionDurationLabel') }}</label>
          <div class="inputfield w-full">
            <p-inputNumber
              ariaLabel="{{ t('pIIRetentionDurationLabel') }}"
              id="pIIRetentionDuration"
              mode="decimal"
              suffix=" {{ t('hours') }}"
              [formControl]="pIIRetentionDuration"
              [max]="168"
              [min]="0"
              [showButtons]="true"
            >
            </p-inputNumber>
            <p id="pIIRetentionDuration-help">{{ t('pIIRetentionDurationHelp', { numHours: pIIRetentionDuration?.value }) }}</p>
          </div>
        </div>
        <!-- Quick Exit URL -->
        <div class="field">
          <label for="redirectURL">{{ t('redirectURLLabel') }}</label>
          <div class="inputfield w-full">
            <div class="p-inputgroup">
              <input
                pInputText
                placeholder="https://"
                ariaLabel="{{ t('redirectURLLabel') }}"
                id="redirectURL"
                type="text"
                [formControl]="redirectURL"
              />
            </div>
            <div id="redirectURL-help">
              <p *ngIf="form.get('redirectURL').errors?.errorCode" class="p-error">{{ t(form.get('redirectURL').errors?.errorCode) }}</p>
              <p>{{ t('redirectURLHelp', { url: form.get('redirectURL').value }) }}</p>
            </div>
          </div>
        </div>

        <!-- Waiting Room -->
        <div class="field">
          <p-checkbox
            ariaLabel="{{ t('waitingRoomConfigEnabledLabel') }}"
            id="waitingRoomConfigEnabled"
            label="{{ t('waitingRoomConfigEnabledLabel') }}"
            [binary]="true"
            [formControl]="waitingRoomConfigEnabled"
          >
          </p-checkbox>
          <div id="waitingRoomConfigEnabled-help">
            <p>{{ t('waitingRoomConfigEnabledHelp') }}</p>
          </div>
        </div>

        <!-- Capture Identity -->
        <div class="field">
          <p-checkbox
            ariaLabel="{{ t('captureIdentityToVendorEnabledLabel') }}"
            id="captureIdentityToVendor"
            label="{{ t('captureIdentityToVendorEnabledLabel') }}"
            [binary]="true"
            [formControl]="captureIdentityToVendorEnabled"
          >
          </p-checkbox>
          <div id="captureIdentityToVendorEnabled-help">
            <p>{{ t('captureIdentityToVendorEnabledHelp', { numHours: pIIRetentionDuration?.value }) }}</p>
          </div>
        </div>

        <label class="flex flex-column gap-2">
          <span>{{ t('communicationWorkflowLabel') }}</span>
          <app-communication-workflow-autocomplete [formControl]="communicationWorkflowId"></app-communication-workflow-autocomplete>
        </label>
      </form>
    </ng-template>

    <app-settings
      [auditEntry]="auditEntry"
      [cardTitle]="t('title')"
      [doUpload]="upload"
      [editorTemplate]="objEditor"
      [isValid]="isValid"
      [refreshData]="refreshData"
      [resetForm]="updateUI"
    >
    </app-settings>
  </div>
  <p-progressSpinner *ngIf="loading"></p-progressSpinner>
</ng-container>
