////////////////////////////////////////////////////////////////////////////////////////////////
// Timing
////////////////////////////////////////////////////////////////////////////////////////////////

const loadStartTimeMS = new Date();

////////////////////////////////////////////////////////////////////////////////////////////////
// Helper Functions
////////////////////////////////////////////////////////////////////////////////////////////////

export * from './helper/index';
export * from './helper/phone-utils';

////////////////////////////////////////////////////////////////////////////////////////////////
// Objects
////////////////////////////////////////////////////////////////////////////////////////////////

export * from './objects/async-service-request';
export * from './objects/call';
export * from './objects/communication';
export * from './objects/conversation';
export * from './objects/core';
export * from './objects/email-template-config';
export * from './objects/event';
export * from './objects/form';
export * from './objects/identity';
export * from './objects/generic';
export * from './objects/message';
export * from './objects/org';
export * from './objects/stats';
export * from './objects/text';
export * from './objects/user';
export * from './objects/release-version';
export * from './objects/voice-response-command';
export * from './objects/voicemail';
export * from './objects/notes';

export * from './typings';

////////////////////////////////////////////////////////////////////////////////////////////////
// Callable Functions
////////////////////////////////////////////////////////////////////////////////////////////////

export * from './callable-functions';

////////////////////////////////////////////////////////////////////////////////////////////////
// Transaction
////////////////////////////////////////////////////////////////////////////////////////////////

export * from './db/transaction';

////////////////////////////////////////////////////////////////////////////////////////////////
// Testing
////////////////////////////////////////////////////////////////////////////////////////////////

export * from './spec';

////////////////////////////////////////////////////////////////////////////////////////////////
// Webchat
////////////////////////////////////////////////////////////////////////////////////////////////

export * from './webchat';

////////////////////////////////////////////////////////////////////////////////////////////////
// Warning On Import
////////////////////////////////////////////////////////////////////////////////////////////////

console.warn(`--------- pwp-common:index.ts: Imported from global scope in ${new Date().valueOf() - loadStartTimeMS.valueOf()}ms ---------`);

// Uncomment to disallow import
// throw new Error('User Error! Import of pwp-common is not supported');
