<ng-container *transloco="let t; read: 'conversation-select-and-edit'">
  <p-confirmDialog
    acceptLabel="{{ t('confirmDialogAcceptLabel') }}"
    rejectButtonStyleClass="p-button-text"
    rejectLabel="{{ t('confirmDialogRejectLabel') }}"
  >
  </p-confirmDialog>

  <p-toast position="bottom-center"></p-toast>
</ng-container>

<ng-container *transloco="let t; read: 'vue-advanced-chat'">
  <vue-advanced-chat
    [currentUserId]="participantDisplayId"
    [height]="'70vh'"
    [menuActions]="menuActions"
    [messageActions]="[]"
    [messages]="messages$ | async"
    [messagesLoaded]="messagesLoaded"
    [roomId]="selectedRoom?.roomId"
    [rooms]="rooms"
    [roomsLoaded]="roomsLoaded"
    [showAddRoom]="false"
    [showAudio]="false"
    [showEmojis]="true"
    [showFiles]="false"
    [showFooter]="showFooter"
    [showReactionEmojis]="false"
    [showSearch]="false"
    [singleRoom]="false"
    [textMessages]="{
      ROOMS_EMPTY: t('ROOMS_EMPTY'),
      ROOM_EMPTY: t('ROOM_EMPTY'),
      NEW_MESSAGES: t('NEW_MESSAGES'),
      MESSAGE_DELETED: t('MESSAGE_DELETED'),
      MESSAGES_EMPTY: t('MESSAGES_EMPTY'),
      CONVERSATION_STARTED: t('CONVERSATION_STARTED'),
      TYPE_MESSAGE: t('TYPE_MESSAGE'),
      SEARCH: t('SEARCH'),
      IS_ONLINE: t('IS_ONLINE'),
      LAST_SEEN: t('LAST_SEEN'),
      IS_TYPING: t('IS_TYPING')
    }"
    (fetch-messages)="getMessages($event.detail[0])"
    (menu-action-handler)="menuActionsHandler($event.detail[0])"
    (send-message)="sendMessage($event.detail[0]?.content)"
  >
  </vue-advanced-chat>
</ng-container>
