import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {ConversationResponseSchema} from '../../generic/conversation-response/conversation-response-schema';

import {ConversationResponseTakeError} from './conversation-response-take-error';

export class ConversationResponseTakeSchema extends ConversationResponseSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly Defaults = class extends ConversationResponseSchema.Defaults {};

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[ConversationResponseTakeSchema.error] = SchemaField.string(ConversationResponseTakeSchema.Defaults.error)
      .optional()
      .valid(...Object.values(ConversationResponseTakeError));

    return parameters;
  }
}
