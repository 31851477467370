import { EventDataSchema } from '../../../../../../../../common/src';
import { DBQuery } from '../../../generic/interfaces';

export const getEventsQuery = (type?: string | string[]): DBQuery[] => {
  if (typeof type === 'string') {
    return [{ fieldPath: EventDataSchema.type, opStr: '==', value: type }];
  }

  if (Array.isArray(type)) {
    return [{ fieldPath: EventDataSchema.type, opStr: 'in', value: type }];
  }

  return [];
};
