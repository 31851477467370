import { AvailableTimesByWeekMap } from './available-times-by-week';
import { normalizeAvailableTimesByWeek } from './normalize-available-times-by-week';

export const normalizeAvailableTimesByWeekMap = (availableTimesByWeekMap: AvailableTimesByWeekMap): AvailableTimesByWeekMap => {
  // Fill in missing values
  for (const isoWeekday of [1, 2, 3, 4, 5, 6, 7]) {
    const currValue = availableTimesByWeekMap.get(isoWeekday);
    availableTimesByWeekMap.set(isoWeekday, normalizeAvailableTimesByWeek(currValue?.type, currValue?.timeRange));
  }

  return availableTimesByWeekMap;
};
