import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsRequestSchema} from '../../generic/communications-request/communications-request-schema';

export class CommunicationsRequestGetConversationLogPIISchema extends CommunicationsRequestSchema {
  public static readonly conversationLogId = 'conversationLogId';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;

    public static readonly conversationLogId = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationsRequestGetConversationLogPIISchema.conversationLogId] = SchemaField.autoGeneratedDocId(CommunicationsRequestGetConversationLogPIISchema.Defaults.conversationLogId).required();
    /* eslint-enable */

    return parameters;
  }
}
