import { ChangeDetectorRef, Component, OnChanges, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { DisplayableObj } from '../../../common/objects/types';
import { ChooseObjForm } from '../abstract-classes/choose-obj-form';
@Component({
  selector: 'app-obj-select',
  templateUrl: './obj-select.component.html',
  styleUrls: ['./obj-select.component.css'],
})
export class ObjSelectComponent<T extends DisplayableObj> extends ChooseObjForm<T> implements OnChanges, OnDestroy {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public formControl = new UntypedFormControl();

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(_changeDetectorRef: ChangeDetectorRef) {
    super(_changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Abstract Methods
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getFormControl() {
    return this.formControl;
  }
}
