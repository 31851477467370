import {AnyCommunicationSessionEventLogSendMessageSchema} from './any-communication-session-event-log-send-message-schema';
import {SerializableObject} from '../../../../../../../generic/serialization/serializable-object';
import {CommunicationSessionEventLogSendMessageType} from '../communication-session-event-log-send-message-type';
import {CommunicationSessionEventLogSendReservationOffer} from '../../type/communication-session-event-log-send-reservation-offer/communication-session-event-log-send-reservation-offer';
import {CommunicationSessionEventLogSendMessage} from '../communication-session-event-log-send-message/communication-session-event-log-send-message';
import {CommunicationSessionEventLogSendMessageNotification} from '../../type/communication-session-event-log-send-message-notification/communication-session-event-log-send-message-notification';

export abstract class AnyCommunicationSessionEventLogSendMessage extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by SerializableObject.
   *
   * @param validationResult
   */
  public static _deserialize(validationResult: import('joi').ValidationResult): CommunicationSessionEventLogSendMessage {
    const typeString = validationResult.value[AnyCommunicationSessionEventLogSendMessageSchema.messageType];
    const messageType = CommunicationSessionEventLogSendMessageType[typeString as keyof typeof CommunicationSessionEventLogSendMessageType];
    if (messageType === undefined) {
      throw new Error(`AnyCommunicationSessionEventLog._deserialize: User Error: Cannot deserialize obj with type=${typeString}`);
    }

    switch (messageType) {
      case CommunicationSessionEventLogSendMessageType.reservationOffer:
        return new CommunicationSessionEventLogSendReservationOffer(validationResult.value);
      case CommunicationSessionEventLogSendMessageType.notification:
        return new CommunicationSessionEventLogSendMessageNotification(validationResult.value);
      default:
        throw new Error(`AnyCommunicationSessionEventLog._deserialize Unknown type: ${messageType}`);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema() {
    return new AnyCommunicationSessionEventLogSendMessageSchema();
  }
}
