<!-- Take Voicemail Command -->
<div *ngIf="!loading">
  <!-- Call List Select -->
  <app-obj-select
    [label]="'Voicemail Config'"
    [orderedItems]="voicemailConfigArray"
    [(kvPair)]="selectedVoicemailConfigKVPair"
    (kvPairChange)="updateObjAndEmit()"
  ></app-obj-select>
</div>

<!-- Is Valid Indicator -->
<app-obj-is-valid *ngIf="!loading" [isValidObj]="isValidObjScoper"></app-obj-is-valid>
