import { Message } from '@twilio/conversations';
import moment from 'moment-timezone';

import { getMessageParticipantDisplayId } from '../../participant/helper/get-message-participant-display-id';

import { DisplayableMessage } from './displayable-message';
import { CreateDisplayableMessageInput } from './interfaces';

export const getDisplayableTwilioMessage = async ({
  conversation,
  message,
  indexId,
}: CreateDisplayableMessageInput<Message>): Promise<DisplayableMessage> => {
  const seen = conversation.messageState.wasSeenByEveryone(message) ?? false;

  return {
    _id: message.sid,
    indexId,
    content: message.body,
    senderId: await getMessageParticipantDisplayId(message),
    date: moment(message.dateCreated).format('MMMM D, YYYY'),
    timestamp: moment(message.dateCreated).format('h:mm A'),
    serverTimestamp: message.dateCreated,
    saved: true,
    seen,
    distributed: seen,
  };
};
