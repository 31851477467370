import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import moment from 'moment-timezone';
import {GLOBAL_TIMEZONE} from '../../../helper/constants';

export class EventConfigSchema extends DBDocSchema {
  // Information used to define the repeating calendar event/
  public static readonly start = 'start';
  public static readonly end = 'end';
  public static readonly rrule = 'rrule';

  // If true, then cron job will generate these events
  public static readonly generate = 'generate';

  // All events generated by this object will have these properties set.
  public static readonly type = 'type';
  public static readonly color = 'color';

  public static readonly assignedUserId = 'assignedUserId';
  public static readonly assignedBackupUserId = 'assignedBackupUserId';

  // Display Name
  public static readonly displayName = 'displayName';
  public static readonly description = 'description';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'eventConfig';
  };

  static Defaults = class {
    public static readonly start = moment.tz(GLOBAL_TIMEZONE);
    public static readonly end = moment.tz(GLOBAL_TIMEZONE).add(1, 'year');
    public static readonly rrule = '';

    public static readonly generate = false;

    public static readonly type = undefined;
    public static readonly color = undefined;

    public static readonly assignedUserId = '';
    public static readonly assignedBackupUserId = '';

    public static readonly displayName = 'missing-displayName';
    public static readonly description = 'missing-description';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([EventConfigSchema.assignedUserId, EventConfigSchema.assignedBackupUserId, EventConfigSchema.generate]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[EventConfigSchema.start] = SchemaField.timestamp(EventConfigSchema.Defaults.start);
    parameters[EventConfigSchema.end] = SchemaField.timestamp(EventConfigSchema.Defaults.end);
    parameters[EventConfigSchema.rrule] = SchemaField.string(EventConfigSchema.Defaults.rrule);
    parameters[EventConfigSchema.generate] = SchemaField.boolean(EventConfigSchema.Defaults.generate);

    parameters[EventConfigSchema.type] = SchemaField.string(EventConfigSchema.Defaults.type);
    parameters[EventConfigSchema.color] = SchemaField.string(EventConfigSchema.Defaults.color);
    parameters[EventConfigSchema.assignedUserId] = SchemaField.string(EventConfigSchema.Defaults.assignedUserId);
    parameters[EventConfigSchema.assignedBackupUserId] = SchemaField.string(EventConfigSchema.Defaults.assignedBackupUserId);
    parameters[EventConfigSchema.displayName] = SchemaField.string(EventConfigSchema.Defaults.displayName);
    parameters[EventConfigSchema.description] = SchemaField.string(EventConfigSchema.Defaults.description);

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${EventConfigSchema.Constants.collection}`;
  }
}
