<ng-container *ngIf="data$ | async as data; else loading">
  <survey-creator [model]="data.creatorModel"></survey-creator>
  <app-audit-entry [auditEntry]="data.auditEntry"> </app-audit-entry>
</ng-container>

<ng-template #loading>
  <div class="form-editor__loading-overlay">
    <app-progress-spinner></app-progress-spinner>
  </div>
</ng-template>
