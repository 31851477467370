import Joi from 'joi';
import moment from 'moment-timezone';
import {SerializableObject} from './serializable-object';
import {SerializableObjectBuilder} from './serializable-obj-builder';
import {SchemaFieldConstants} from './constants';
import {sanitizeString} from '../sanitize/do-nothing';
import {rawTimestampToMoment} from './timestamp';

/**
 * All schema fields are optional and must be provided with a sensible default. Passing in a
 * null or invalid value will not result in a parsing error.
 */
export class SchemaField {
  ///////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ///////////////////////////////////////////////////////////////////////////////////////////

  public static readonly userIdRegexPattern = '^[a-zA-Z0-9_-]{1,128}$';

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Obj
  ///////////////////////////////////////////////////////////////////////////////////////////

  public static obj<T extends SerializableObject>(objBuilder: SerializableObjectBuilder<T>, defaultValue: any | undefined, defaultReference?: import('joi').Reference): import('joi').AnySchema {
    const result = Joi.object().custom((value: any | undefined) => {
      // Pass along missing value
      if (value === undefined) {
        return value;
      }

      const parsedValue = objBuilder.deserialize(value) as T;
      return parsedValue;
    });
    //objBuilder.getSchema().getJoiSchema().optional().allow(null).empty([null]);

    return this.addDefaults(result, defaultValue, defaultReference) as Joi.AnySchema;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Array
  ///////////////////////////////////////////////////////////////////////////////////////////

  public static shortObjArray<T extends SerializableObject>(
    objBuilder: SerializableObjectBuilder<T>,
    defaultValue: any[] | undefined,
    defaultReference?: import('joi').Reference,
    itemSchema?: import('joi').AnySchema
  ): import('joi').ArraySchema {
    const result = Joi.array()
      .optional()
      .items(itemSchema || objBuilder.getSchema().getJoiSchema())
      .allow(null)
      .empty([null])
      .max(SchemaFieldConstants.shortArrayMaxLength)
      .custom((values: any[] | undefined) => {
        // Pass along missing value
        if (values === undefined) {
          return values;
        }

        const parsedValues: T[] = [];
        for (const value of values) {
          const parsedValue = (objBuilder as any)._deserialize({value: value});
          parsedValues.push(parsedValue);
        }

        return parsedValues;
      });

    return this.addDefaults(result, defaultValue, defaultReference) as Joi.ArraySchema;
  }

  public static arrayOfType(valueType: import('joi').AnySchema, defaultValue: any[] | undefined, defaultReference?: import('joi').Reference): import('joi').ArraySchema {
    const result = Joi.array().optional().items(valueType).allow(null).empty([null]).max(SchemaFieldConstants.shortArrayMaxLength);

    return this.addDefaults(result, defaultValue, defaultReference) as Joi.ArraySchema;
  }

  public static shortStringArray(defaultValue: string[] | undefined, defaultReference?: import('joi').Reference, maxStringLength?: number) {
    const result = Joi.array()
      .optional()
      .items(SchemaField.sanitizedString(maxStringLength))
      // Allow empty string and null and replace them with the default value.
      .allow('', null)
      .empty(['', null])
      .max(SchemaFieldConstants.shortArrayMaxLength);

    return this.addDefaults(result, defaultValue, defaultReference) as Joi.ArraySchema;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Map
  ///////////////////////////////////////////////////////////////////////////////////////////

  public static mapOfType(valueType: import('joi').AnySchema, defaultValue: Map<string, any> | undefined, defaultReference?: import('joi').Reference) {
    const result = Joi.object()
      .pattern(SchemaField.sanitizedString(), valueType)
      .optional()
      .allow(null)
      .empty([null])
      .custom((parsedObj: object | undefined) => {
        // Pass along missing value
        if (parsedObj === undefined) {
          return parsedObj;
        }

        const result = new Map<string, any>();
        for (const [key, value] of Object.entries(parsedObj)) {
          result.set(key.toString(), value);
        }

        return result;
      });

    return this.addDefaults(result, defaultValue, defaultReference) as Joi.ArraySchema;
  }

  public static mapOfObj<T extends SerializableObject>(
    objBuilder: SerializableObjectBuilder<T>,
    defaultValue: Map<string, T> | undefined,
    defaultReference?: import('joi').Reference
  ): import('joi').AnySchema {
    const result = Joi.object()
      .pattern(Joi.alternatives(SchemaField.sanitizedString(), Joi.number(), Joi.bool()), Joi.any())
      .optional()
      .allow(null)
      .empty([null])
      .custom((value: object | undefined) => {
        // Pass along missing value
        if (value === undefined) {
          return value;
        }

        // Convert the object to a map
        const result = new Map<string, T>();
        for (const [key, parsedJsonTree] of Object.entries(value)) {
          result.set(key, objBuilder.deserialize(parsedJsonTree));
        }
        return result;
      });

    return this.addDefaults(result, defaultValue, defaultReference) as Joi.ArraySchema;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // String
  ///////////////////////////////////////////////////////////////////////////////////////////

  public static sanitizedString(maxLength = SchemaFieldConstants.maxSafeStringLength): import('joi').StringSchema {
    const result = Joi.string()
      .optional()
      // Allow empty string and null and replace them with the default value.
      .allow('', null)
      .empty(['', null])
      .max(maxLength)
      .truncate()
      .custom((value: string | undefined) => {
        return sanitizeString(value);
      });

    return result;
  }

  /**
   * Validate a string field. If the field is missing, or the value
   * it contains is not a valid string of given max length then substitute
   * the defaultValue.
   *
   * @param defaultValue: Default value to use when the field is missing or fails validation
   * @param defaultReference If specified, then the default value of this field should be
   * taken as the value in another field, defined by this Joi.Reference.
   */
  public static string(defaultValue: string | undefined, defaultReference?: import('joi').Reference, maxLength: number = SchemaFieldConstants.shortStringMaxLength): import('joi').StringSchema {
    const result = SchemaField.sanitizedString().max(maxLength);
    return this.addDefaults(result, defaultValue, defaultReference) as Joi.StringSchema;
  }

  public static email(defaultValue: string | undefined, defaultReference?: import('joi').Reference) {
    return SchemaField.string(defaultValue, defaultReference).email({
      tlds: {allow: false},
    });
  }

  public static ip(defaultValue: string | undefined, defaultReference?: import('joi').Reference) {
    return SchemaField.string(defaultValue, defaultReference).ip();
  }
  public static userId(defaultValue: string | undefined, defaultReference?: import('joi').Reference) {
    return SchemaField.string(defaultValue, defaultReference).pattern(SchemaFieldConstants.userIdRegex);
  }

  public static autoGeneratedDocId(defaultValue: string | undefined, defaultReference?: import('joi').Reference) {
    return SchemaField.string(defaultValue, defaultReference).max(20).truncate().pattern(SchemaFieldConstants.autoGeneratedDocIdRegex).required();
  }

  public static uri(defaultValue: string | undefined, defaultReference?: import('joi').Reference) {
    return SchemaField.string(defaultValue, defaultReference).uri();
  }

  public static shortAlphaNumeric(defaultValue: string | undefined, defaultReference?: import('joi').Reference) {
    return SchemaField.string(defaultValue, defaultReference).pattern(SchemaFieldConstants.shortAlphaNumericRegex);
  }

  public static jwt(defaultValue: string | undefined, defaultReference?: import('joi').Reference) {
    return SchemaField.string(defaultValue, defaultReference, SchemaFieldConstants.longStringMaxLength).pattern(SchemaFieldConstants.jwtRegex);
  }

  public static isoDuration(defaultValue: string | undefined, defaultReference?: import('joi').Reference) {
    return SchemaField.string(defaultValue, defaultReference).isoDuration();
  }

  /**
   * This preforms very validation following the regex here: https://www.twilio.com/docs/glossary/what-e164
   * @param defaultValue
   * @param defaultReference
   */
  public static e164Phone(defaultValue: string | undefined, defaultReference?: import('joi').Reference) {
    return SchemaField.string(defaultValue, defaultReference).pattern(SchemaFieldConstants.e164Regex);
  }

  /**
   * ISO 3166 Alpha-2 Country Code
   * @param defaultValue
   * @param defaultReference
   */
  public static iso3166Alpha2CountryCode(defaultValue: string | undefined, defaultReference?: import('joi').Reference) {
    return SchemaField.string(defaultValue, defaultReference).valid(...SchemaFieldConstants.iSO3166Alpha2CountryCodes);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Number
  ///////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Validate a number field. If the field is missing, or the value
   * it contains is not a number then substitute the defaultValue.
   *
   * @param defaultValue: Default value to use when the field is missing or fails validation
   * @param defaultReference If specified, then the default value of this field should be
   * taken as the value in another field, defined by this Joi.Reference.
   */
  public static number(defaultValue: number | undefined, defaultReference?: import('joi').Reference) {
    const result = Joi.number()
      .optional()
      // Allow empty string and null and replace them with the default value.
      .allow('', null)
      .empty(['', null]);

    return this.addDefaults(result, defaultValue, defaultReference);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Boolean
  ///////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Validate a boolean field. If the field is missing, or the value
   * it contains is not a number then substitute the defaultValue.
   *
   * @param defaultValue: Default value to use when the field is missing or fails validation
   * @param defaultReference If specified, then the default value of this field should be
   * taken as the value in another field, defined by this Joi.Reference.
   */
  public static boolean(defaultValue: boolean | undefined, defaultReference?: import('joi').Reference) {
    const result = Joi.bool()
      .optional()
      // Allow empty string and null and replace them with the default value.
      .allow('', null)
      .empty(['', null]);

    return this.addDefaults(result, defaultValue, defaultReference);
  }
  ///////////////////////////////////////////////////////////////////////////////////////////
  // Timestamp
  ///////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Validate a timestamp parsed as a firebase object. If the field is missing, or the value
   * it contains is not a valid firebase timestamp then substitute the defaultValue.
   *
   * @param defaultValue: Default value to use when the field is missing or fails validation.
   * @param defaultReference If specified, then the default value of this field should be
   * taken as the value in another field, defined by this Joi.Reference.
   */
  public static timestamp(defaultValue: moment.Moment | undefined, defaultReference?: import('joi').Reference): import('joi').AnySchema {
    const result = Joi.any()
      .allow(null)
      .empty([null])
      .custom((value: any | undefined) => {
        // Pass along missing value
        if (value === undefined) {
          return value;
        }

        return rawTimestampToMoment(value);
      });

    // return result;
    return this.addDefaults(result, defaultValue, defaultReference);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Helper Methods
  ///////////////////////////////////////////////////////////////////////////////////////////

  private static addDefaults(schemaWithoutDefaults: import('joi').AnySchema, defaultValue: any | undefined, defaultReference?: import('joi').Reference) {
    if (defaultValue !== undefined) {
      return schemaWithoutDefaults.default(defaultReference === undefined ? defaultValue : defaultReference).failover(defaultValue);
    }

    return schemaWithoutDefaults;
  }
}
