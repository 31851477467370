import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {VoiceResponseCommandName} from '../any-voice-response-command/voice-response-command-name';
import {VoiceResponseCommand} from '../generic/voice-response-command';
import {VoiceResponseCommandSchema} from '../generic/voice-response-command-schema';
import {CreateAsyncServiceRequestConstructor} from './create-async-service-request-constructor';
import {CreateAsyncServiceRequestSchema} from './create-async-service-request-schema';
import {cloneDeep} from 'lodash';

export class CreateAsyncServiceRequest extends VoiceResponseCommand {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected createAsyncServiceRequestWidgetId!: string;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CreateAsyncServiceRequestConstructor) {
    // This hack ensures that deserializing a VoiceCommand is equal to instanting it directly.
    // The difference is trivial, and only required for intuitive unit testing.
    (parameters as any)[VoiceResponseCommandSchema.commandName] = VoiceResponseCommandName.createAsyncServiceRequest;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CreateAsyncServiceRequest {
    return new CreateAsyncServiceRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CreateAsyncServiceRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CreateAsyncServiceRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCreateAsyncServiceRequestWidgetId(): string {
    return cloneDeep(this.createAsyncServiceRequestWidgetId);
  }
  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
