import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, ValidatorFn } from '@angular/forms';
import { isNil } from 'lodash';

import { AvailableTimesByWeekMap, AvailableTimesType } from '../../../common/objects/time-selection/available-times-by-week';
import { getAvailableTimesByDayOfWeek } from '../../../common/objects/time-selection/get-available-times-by-day-of-week';
import { makeTimeSelectionFromAvailableTimesByDayOfWeek } from '../../../common/objects/time-selection/make-time-selection-from-available-times-by-day-of-week';
import { normalizeAvailableTimesByWeekMap } from '../../../common/objects/time-selection/normalize-available-times-by-week-map';
import { TimeRangeStrValidator } from '../../../common/validators/time-range-str-validator';
import { ComponentWithEmittingForm } from '../../generic/abstract-classes/component-with-emitting-form';

import { EditAvailableHoursByDowInput } from './edit-available-hours-by-dow-input';
@Component({
  selector: 'app-edit-available-hours-by-dow',
  templateUrl: './edit-available-hours-by-dow.component.html',
  styleUrls: ['./edit-available-hours-by-dow.component.css'],
})
export class EditAvailableHoursByDowComponent extends ComponentWithEmittingForm<EditAvailableHoursByDowInput> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input/Output
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input()
  description = '';

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  /////////////////////////////////////////////////////////////////////////////////////////////

  timeRangeRegex = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]-(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  isoWeekdays = [1, 2, 3, 4, 5, 6, 7];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(private formBuilder: UntypedFormBuilder) {
    super();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  protected defineForm() {
    // Init Form
    const formConfig = {} as any;
    const validators: ValidatorFn[] = [];
    const availableTimes = getAvailableTimesByDayOfWeek(this.obj?.timeSelection);
    for (const isoWeekday of this.isoWeekdays) {
      const availableTimesForWeekday = availableTimes.get(isoWeekday)!;
      formConfig[this.timeRangeControlName(isoWeekday)] = [availableTimesForWeekday.timeRange];
      formConfig[this.timeRangeTypeControlName(isoWeekday)] = [availableTimesForWeekday.type];

      const validator = TimeRangeStrValidator.timeRange(this.timeRangeTypeControlName(isoWeekday), this.timeRangeControlName(isoWeekday));
      validators.push(validator);
    }

    this.form = this.formBuilder.group(formConfig, { validators });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Get Obj From Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    // Make the Map from Form
    let availableTimes: AvailableTimesByWeekMap = new Map();

    for (const isoWeekday of this.isoWeekdays) {
      const timeRangeType = this.form?.get(this.timeRangeTypeControlName(isoWeekday))?.value;
      const timeRange: string = this.form?.get(this.timeRangeControlName(isoWeekday))?.value;
      availableTimes.set(isoWeekday, { type: timeRangeType, timeRange });
    }
    availableTimes = normalizeAvailableTimesByWeekMap(availableTimes);

    const timeSelection = makeTimeSelectionFromAvailableTimesByDayOfWeek(availableTimes, this.obj.timezone);
    return {
      timezone: this.obj.timezone,
      timeSelection,
    };
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Get Obj From Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  isSometimesAvailable(isoWeekday: number): boolean {
    return this.form?.get(this.timeRangeTypeControlName(isoWeekday))?.value === AvailableTimesType.sometimesAvailable;
  }

  isInvalidTimeRange(isoWeekday: number): boolean {
    return this.form?.get(this.timeRangeControlName(isoWeekday))?.invalid;
  }

  timeRangeControlName(isoWeekday: number) {
    return `timeRange${isoWeekday}`;
  }

  timeRangeTypeControlName(isoWeekday: number) {
    return `timeRangeType${isoWeekday}`;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid
  /////////////////////////////////////////////////////////////////////////////////////////////

  public isValidObj(): boolean {
    if (isNil(this.obj?.timezone)) {
      return false;
    }

    return super.isValidObj();
  }
}
