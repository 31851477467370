import { DBOrderBy, DBQuery, GetDocsArrayOptions } from '../../interfaces';

export const parseGetDocsArrayOptions = (
  queryOrOptions: DBQuery[] | GetDocsArrayOptions = {},
  orderBy: DBOrderBy = undefined,
  limit: number = undefined,
  takeOne = true,
): GetDocsArrayOptions => {
  if (Array.isArray(queryOrOptions)) {
    return { query: queryOrOptions, orderBy, limit, takeOne };
  }

  return {
    query: queryOrOptions.query ?? [],
    orderBy: queryOrOptions.orderBy ?? orderBy,
    limit: queryOrOptions.limit ?? limit,
    takeOne: queryOrOptions.takeOne ?? takeOne,
  };
};
