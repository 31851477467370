export const conversationWaitingRoomDefaultMessage = {
  text: {
    en: 'Please wait, this conversation will start as soon when somebody is available. There are {{queuePosition}} visitors ahead of you.',
    es: 'Espere, esta conversación comenzará tan pronto como haya alguien disponible. Hay {{queuePosition}} visitantes delante de ti.',
  },
};

export const conversationWaitingRoomConfigDefault = {
  enabled: false,
  waitingRoomMessage: conversationWaitingRoomDefaultMessage,
};
