import {values} from 'lodash';
import {GenericDisplayableSchema} from '../../generic/displayable/generic-displayable-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {TemplateText} from '../../text/template-text/template-text';
import {ConversationMessageTemplateType} from './conversation-message-template-type';

export class ConversationMessageTemplateSchema extends GenericDisplayableSchema {
  public static readonly body = 'body';
  public static readonly type = 'type';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'conversationMessageTemplates';
  };

  static Defaults = class {
    public static readonly description = 'missing-description';
    public static readonly displayName = 'missing-displayName';

    public static readonly body = TemplateText.deserialize({});
    public static readonly type = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Default Values
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Specify when to serialize default values
   */
  public defaultValuesToSerialize(): Set<string> {
    return new Set([ConversationMessageTemplateSchema.body]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[ConversationMessageTemplateSchema.body] = SchemaField.obj(TemplateText, ConversationMessageTemplateSchema.Defaults.body);
    parameters[ConversationMessageTemplateSchema.type] = SchemaField.string(ConversationMessageTemplateSchema.type, ConversationMessageTemplateSchema.Defaults.type).optional().valid(...values(ConversationMessageTemplateType));

    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${ConversationMessageTemplateSchema.Constants.collection}`;
  }
}
