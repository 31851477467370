import { minutesToSeconds } from 'date-fns';
import { cloneDeep } from 'lodash';

import { DBDocSchema } from '@pwp-common';

import { defaultTargetEditorOutput } from '../../target/default-target-editor-output';
import { CommunicationWorkflowEditorOutput } from '../communication-workflow-editor-output';

export const defaultEditorOutput: CommunicationWorkflowEditorOutput = {
  id: DBDocSchema.GenericDefaults.id,
  displayName: '',
  description: '',
  targets: [cloneDeep(defaultTargetEditorOutput)],
  taskReservationTimeoutSeconds: minutesToSeconds(2),
};
