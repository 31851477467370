import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { PlayOrSay } from '@pwp-common';

import { provideValidator } from '../../../../common/form/provide-validator';
import { provideValueAccessor } from '../../../../common/form/provide-value-accessor';
import { FormGroupControlValueAccessor } from '../../../generic/abstract-classes/form-group-control-value-accessor';
import { ServiceExceptionEditorOutput } from '../../service-exception/editor/editor-output/service-exception-editor-output';
import { ServiceCommandEditorOutput } from '../editor-output/service-command-editor-output';
import { ServiceOperationEditorOutput } from '../editor-output/service-operation-editor-output';

import { makeServiceOperationEditorStepHeaders } from './service-operation-editor-step-header/make-service-operation-editor-step-headers/make-service-operation-editor-step-headers';
import { ServiceOperationEditorStepHeader } from './service-operation-editor-step-header/service-operation-editor-step-header';
import { serviceOperationValidator } from './validators/service-operation-commands-validator/service-operation-commands-validator';

@UntilDestroy()
@Component({
  selector: 'app-service-operation-editor',
  templateUrl: './service-operation-editor.component.html',
  styleUrls: ['./service-operation-editor.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [provideValueAccessor(ServiceOperationEditorComponent), provideValidator(ServiceOperationEditorComponent)],
})
export class ServiceOperationEditorComponent extends FormGroupControlValueAccessor<
  ServiceExceptionEditorOutput,
  ServiceOperationEditorOutput
> {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Input
  /////////////////////////////////////////////////////////////////////////////////////////////

  @Input() maxCommands: number;

  @Input() minCommands = 0;

  @Input() required = false;

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////////////////////

  defaultStep = PlayOrSay.deserialize({}).serialize();

  stepHeaders: ServiceOperationEditorStepHeader[];

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(public changeDetectorRef: ChangeDetectorRef) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Define Form
  /////////////////////////////////////////////////////////////////////////////////////////////

  public defineForm(): void {
    const commandsValidators: ValidatorFn[] = this.required ? [Validators.required] : [];

    this.form = new UntypedFormGroup(
      {
        commands: new UntypedFormControl(undefined, commandsValidators),
      },
      { validators: [serviceOperationValidator()] },
    );

    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      const editorResults: ServiceCommandEditorOutput[] = this.commands?.value ?? [];
      this.stepHeaders = makeServiceOperationEditorStepHeaders(editorResults);
    });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Parse Value Change
  /////////////////////////////////////////////////////////////////////////////////////////////

  parseValueChange(value: any): any {
    return value;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Write Value
  /////////////////////////////////////////////////////////////////////////////////////////////

  writeValue(value: ServiceOperationEditorOutput): void {
    const serviceOperationEditorResult: ServiceOperationEditorOutput = value ?? { commands: [] };
    this.commands.setValue(serviceOperationEditorResult.commands);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Controls
  /////////////////////////////////////////////////////////////////////////////////////////////

  get commands() {
    return this.form.get('commands') as UntypedFormControl;
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Validation Errors
  /////////////////////////////////////////////////////////////////////////////////////////////

  public makeValidationErrors(): ValidationErrors {
    return {
      'service-operation-editor': this.form.value,
    };
  }
}
