import { isNil } from 'lodash';

import { DBDocObject } from '@pwp-common';

import { AuditEntry } from '../interfaces';

export const makeAuditEntry = (obj: DBDocObject): AuditEntry | undefined => {
  if (isNil(obj)) {
    return undefined;
  }

  return {
    userId: obj.getLastModifiedByUserId(),
    timestamp: obj.getLastUploadTime(),
  };
};
