import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { RadioButtonModule } from 'primeng/radiobutton';

import { AssignedUserType, canRequestSlot, EventRequestConfig } from '@pwp-common';

import { NgChanges } from '../../../../common/objects/ng-changes';
import { EventRequestTableRow } from '../event-request-table-row/event-request-table-row';

@Component({
  selector: 'app-requested-slot-select',
  standalone: true,
  imports: [CommonModule, FormsModule, RadioButtonModule, ReactiveFormsModule, TranslocoModule],
  templateUrl: './requested-slot-select.component.html',
  styleUrls: ['./requested-slot-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RequestedSlotSelectComponent implements OnChanges {
  @Input() public control: FormControl<AssignedUserType>;

  @Input() public eventRequestConfig: EventRequestConfig;

  @Input() public rowData: EventRequestTableRow;

  public requestableSlots: Record<AssignedUserType, boolean> = {
    backup: false,
    primary: false,
  };

  public readonly assignedUserType = AssignedUserType;

  public ngOnChanges(changes: NgChanges<RequestedSlotSelectComponent>): void {
    if (changes.eventRequestConfig || changes.rowData) {
      const commonCanRequestSlotParams = {
        eventData: this.rowData.allDataEvent.getEventData(),
        eventRequestConfig: this.eventRequestConfig,
      };

      this.requestableSlots = {
        primary: canRequestSlot({ ...commonCanRequestSlotParams, slot: AssignedUserType.primary }),
        backup: canRequestSlot({ ...commonCanRequestSlotParams, slot: AssignedUserType.backup }),
      };
    }
  }
}
