import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { CallerId } from '@pwp-common';

import { CallerIdService } from '../../../../services/call/caller-id/caller-id.service';
import {
  getObjAutocompleteFormControlBaseProviders,
  ObjAutocompleteFormControlBase,
} from '../../abstract-classes/obj-autocomplete-form-control-base';

@Component({
  selector: 'app-caller-id-autocomplete',
  templateUrl: './caller-id-autocomplete.component.html',
  styleUrls: ['./caller-id-autocomplete.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [...getObjAutocompleteFormControlBaseProviders(CallerIdAutocompleteComponent)],
})
export class CallerIdAutocompleteComponent extends ObjAutocompleteFormControlBase<CallerId> implements OnInit {
  /////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    public callerIdService: CallerIdService,
  ) {
    super(changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////
  // Set All Options
  /////////////////////////////////////////////////////////////////////////////////////////////

  defineAllOptions() {
    return this.callerIdService.getDocsArray();
  }
}
