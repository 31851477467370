import {cloneDeep, deburr} from 'lodash';
import {LanguageDefaults} from '../../../voice-response-command/vrc-audio-metadata/language-defaults';
/**
 * Normalize speech by substituting known errors in speech transcription.
 *
 * @param speechResult
 * @param language
 */
export const normalizeSpeechResult = (speechResult: string, language: LanguageDefaults): string => {
  return deburr(cloneDeep(speechResult))
    .toLocaleLowerCase(language.getShortCode())
    .replace(/\./g, ' ') // Note we use regexes to replace all known occurance. .replace('.', ' ') will only replace the first occurance
    .replace(/,/g, ' ')
    .replace(/\s\s+/g, ' ') // Replace repeating whitespace characters with a single space
    .replace(/past/g, 'press')
    .replace(/first/g, 'press')
    .replace(/except/g, 'accept')
    .trim();
};
