<ng-container *transloco="let t; read: 'accordion-wizard-step-header'">
  <div class="col flex-grow-1 flex justify-content-begin">{{ label }}</div>
  <div class="col flex-grow-1 flex justify-content-end">
    <mat-icon *ngIf="!hasError" aria-hidden="false" attr.aria-label="{{ t('stepValidIndicatorLabel') }}" class="text-green-500"
      >check_circle</mat-icon
    >
    <mat-icon *ngIf="hasError" aria-hidden="false" attr.aria-label="{{ t('stepErrorIndicatorLabel') }}" class="text-yellow-500"
      >error</mat-icon
    >
  </div>
</ng-container>
