export enum ConversationState {
  serviceRequested = 'serviceRequested',
  serviceDeliveryInProgress = 'serviceDeliveryInProgress',
  // The conversation is closed. Additional service cannot be delivered via this conversation.
  closed = 'closed',
  // Messages have been deleted
  pIIDeleted = 'pIIDeleted',
}

export type ConversationStateType = keyof typeof ConversationState;
