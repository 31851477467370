import {SchemaField} from '../../../../../generic/serialization/schema-field';
import {CommunicationTaskQueueWorkerListSchema} from '../../generic/communication-task-queue-worker-list/communication-task-queue-worker-list-schema';
import {values} from 'lodash';
import {AssignedUserType} from '../../../../../event/event-data/enums';

export class CommunicationTaskQueueWorkerListEventSchema extends CommunicationTaskQueueWorkerListSchema {
  public static readonly assignedUserTypes = 'assignedUserTypes';
  public static readonly eventTypes = 'eventTypes';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class {
    public static readonly type = undefined;
    public static readonly assignedUserTypes = undefined;
    public static readonly eventTypes = undefined;
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  /**
   * Schema for this object is encoded into a command name, and in parameters
   */
  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CommunicationTaskQueueWorkerListEventSchema.assignedUserTypes] = SchemaField.shortStringArray(
      CommunicationTaskQueueWorkerListEventSchema.Defaults.assignedUserTypes
    )
      .required()
      .items(...values(AssignedUserType))
      .unique();

    parameters[CommunicationTaskQueueWorkerListEventSchema.eventTypes] = SchemaField.shortStringArray(
      CommunicationTaskQueueWorkerListEventSchema.Defaults.eventTypes
    )
      .required()
      .items(SchemaField.autoGeneratedDocId(undefined))
      .unique();
    /* eslint-enable */

    return parameters;
  }
}
