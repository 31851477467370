import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { loadingFor } from '@ngneat/loadoff';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { parsePhoneNumber } from 'libphonenumber-js';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, firstValueFrom, forkJoin, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { MessageTemplateDisplay } from '@pwp-common';

import { PTableCol, subscribePTableTranslatedCols } from '../../../common/p-table/p-table-col';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';
import { MessageTemplateService } from '../../../services/text/message-template/message-template.service';
import { AuthService } from '../../../services/user/auth/auth.service';

interface MessageTemplateSubscriptionForm {
  subscriptions: FormGroup<Record<string, FormControl<boolean>>>;
  agreeWithConsentTerms: FormControl<boolean>;
}

@UntilDestroy()
@Component({
  selector: 'app-message-template-subscribe',
  templateUrl: './message-template-subscribe.component.html',
  styleUrls: ['./message-template-subscribe.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [MessageService],
})
export class MessageTemplateSubscribeComponent implements OnInit {
  private readonly translocoScope = 'message-template-subscribe';

  protected formGroup?: FormGroup<MessageTemplateSubscriptionForm>;

  protected readonly loader = loadingFor('messageTemplates', 'userId');

  protected readonly selectedCols: PTableCol[] = [];

  protected readonly updating$ = new BehaviorSubject(false);

  protected readonly orgName$ = this.orgDataService.getOrgData().pipe(map((orgData) => orgData.getDisplayName()));

  protected readonly orgPhoneNumbers$ = this.orgDataService.getOrgData().pipe(
    map((orgData) =>
      orgData
        .getE164Phones()
        .map((phoneNumber) => parsePhoneNumber(phoneNumber, 'US').formatInternational())
        .join(', '),
    ),
  );

  protected readonly messageTemplateDisplays$ = this.getMessageTemplateDisplays().pipe(share());

  constructor(
    private authService: AuthService,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private messageService: MessageService,
    private messageTemplateService: MessageTemplateService,
    private orgDataService: OrgDataService,
    private translocoService: TranslocoService,
  ) {}

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  private getMessageTemplateDisplays(): Observable<MessageTemplateDisplay[]> {
    return forkJoin([
      this.messageTemplateService.getDocsArray().pipe(this.loader.messageTemplates.track()),
      this.authService.getUserId().pipe(this.loader.userId.track()),
    ]).pipe(
      map(([messageTemplates, userId]) =>
        messageTemplates.filter((z) => z.getSendEnabled()).map((z) => new MessageTemplateDisplay(z, userId)),
      ),
      untilDestroyed(this),
    );
  }

  private async createFormGroup(): Promise<void> {
    const messageTemplateDisplays = await firstValueFrom(this.messageTemplateDisplays$);

    const subscriptions = messageTemplateDisplays.reduce<Record<string, FormControl<boolean>>>(
      (acc, messageTemplateDisplay) => ({
        ...acc,
        [messageTemplateDisplay.id]: this.fb.control(messageTemplateDisplay.userIdIsSubscribed),
      }),
      {},
    );

    this.formGroup = new FormGroup({
      subscriptions: this.fb.group(subscriptions),
      agreeWithConsentTerms: this.fb.control<boolean>(false, { validators: [Validators.requiredTrue] }),
    });

    this.cdr.detectChanges();
  }

  ///////////////////////////////////////////////////////////////////////
  // Change Subscription
  ///////////////////////////////////////////////////////////////////////

  protected async updateUserSubscriptions() {
    try {
      this.updating$.next(true);

      await this.messageTemplateService.updateUserSubscriptions(this.formGroup?.value.subscriptions);

      this.messageService.add({
        severity: 'success',
        summary: this.translocoService.translate(`${this.translocoScope}.preferencesUpdateSuccess.summary`),
        detail: this.translocoService.translate(`${this.translocoScope}.preferencesUpdateSuccess.detail`),
      });
    } catch (e) {
      console.error(e);
      this.messageService.add({
        severity: 'error',
        summary: this.translocoService.translate(`${this.translocoScope}.preferencesUpdateError.summary`),
        detail: this.translocoService.translate(`${this.translocoScope}.preferencesUpdateError.detail`),
      });
    } finally {
      this.updating$.next(false);
    }
  }

  ///////////////////////////////////////////////////////////////////////
  // Lifecycle
  ///////////////////////////////////////////////////////////////////////

  public async ngOnInit(): Promise<void> {
    await this.createFormGroup();
    subscribePTableTranslatedCols(
      this,
      this.translocoService,
      'message-template-subscribe',
      ['id', 'userIdIsSubscribed', 'displayName', 'description', 'frequency'],
      ['userIdIsSubscribed', 'frequency', 'displayName', 'description'],
    );
  }
}
