import { ChangeDetectorRef, Component, OnChanges, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { isNil } from 'lodash';

import { CallEntrypoint, CallEntrypointSchema, VoiceResponseCommand } from '@pwp-common';

import { getFieldValuesFromFormInComponent } from '../../../common/objects/form-helper';
import { ArrayValidator } from '../../../common/validators/array-validator/array-validator';
import { CallEntrypointsService } from '../../../services/call/call-entrypoints/call-entrypoints.service';
import { ConfigDocSelectAndEdit } from '../../generic/abstract-classes/config-doc-select-and-edit';

@Component({
  selector: 'app-call-entrypoints-config-select-and-edit',
  templateUrl: './call-entrypoints-config-select-and-edit.component.html',
  styleUrls: ['./call-entrypoints-config-select-and-edit.component.css'],
})
export class CallEntrypointsConfigSelectAndEditComponent extends ConfigDocSelectAndEdit<CallEntrypoint> implements OnInit, OnChanges {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  form: UntypedFormGroup = new UntypedFormGroup({});

  commands: VoiceResponseCommand[] = [];

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private formBuilder: UntypedFormBuilder,
    // @ts-ignore
    private _dataService: CallEntrypointsService,
    // @ts-ignore
    private _changeDetectorRef: ChangeDetectorRef,
  ) {
    super(CallEntrypoint, _dataService, _changeDetectorRef);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Read Form
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  getObjFromForm() {
    const parameters = getFieldValuesFromFormInComponent(
      this.selectedKVPair.value.getId(),
      [CallEntrypointSchema.displayName, CallEntrypointSchema.description, CallEntrypointSchema.e164Phones],
      CallEntrypointSchema.Defaults,
      this,
    );

    parameters[CallEntrypointSchema.commands] = this.commands;
    const obj = new CallEntrypoint(parameters);
    return obj;
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Write Form
  ///////////////////////////////////////////////////////////////////////////////////////////

  setFormFromObj(obj: CallEntrypoint) {
    const formConfig = {} as any;
    formConfig[CallEntrypointSchema.displayName] = [obj.getDisplayName(), [Validators.required, Validators.maxLength(500)]];
    formConfig[CallEntrypointSchema.description] = [obj.getDescription(), [Validators.required, Validators.maxLength(500)]];
    formConfig[CallEntrypointSchema.e164Phones] = [obj.getE164Phones(), [Validators.required, ArrayValidator.e164Phone()]];

    this.commands = obj.getCommands();
    this.form = this.formBuilder.group(formConfig);
  }

  ///////////////////////////////////////////////////////////////////////////////////////////
  // Form: Getters
  ///////////////////////////////////////////////////////////////////////////////////////////

  get displayName(): AbstractControl | null {
    return this.form.get(CallEntrypointSchema.displayName);
  }

  get description(): AbstractControl | null {
    return this.form.get(CallEntrypointSchema.description);
  }

  get e164Phones(): AbstractControl | null {
    return this.form.get(CallEntrypointSchema.e164Phones);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Is Valid Obj
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  isValidObjScoper = (): boolean => this.isValidObj();

  isValidObj(): boolean {
    // This checks that the form is valid.
    if (!super.isValidObj()) {
      return false;
    }

    if (isNil(this.commands) || this.commands.length === 0) {
      return false;
    }

    return true;
  }
}
