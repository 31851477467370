import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';

import { KVPair } from '../../../common/objects/kvpair';
import { DisplayableObj } from '../../../common/objects/types';

@Component({
  selector: 'app-obj-select-and-edit',
  templateUrl: './obj-select-and-edit.component.html',
  styleUrls: ['./obj-select-and-edit.component.css'],
  providers: [MessageService, ConfirmationService],
})
export class ObjSelectAndEditComponent<T extends DisplayableObj> {
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Lifecycle
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  constructor(
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
  ) {}

  /**
   * Built following tutorial:
   *
   * https://indepth.dev/posts/1405/ngtemplateoutlet
   *
   * and
   *
   * https://levelup.gitconnected.com/typescript-generic-types-as-parameters-882e692073d5
   *
   */
  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Inputs / Outputs
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  @Input() label = '';

  @Input() orderedItems: T[] = [];

  @Output() orderedItemsChange = new EventEmitter<T[]>();

  @Input() editorTemplate: TemplateRef<any>;

  @Input() selectedKVPair: KVPair<T>;

  @Output() selectedKVPairChange = new EventEmitter<KVPair<T> | undefined>();

  @Input() modifiedSelectedItem: T;

  @Input() doUpload: () => Promise<void>;

  @Output() duplicateClick = new EventEmitter<void>();

  @Input() doDelete: () => Promise<void>;

  @Input() doRefresh: () => Promise<void>;

  @Output() newClick = new EventEmitter<void>();

  @Input() kvPairBuilder: () => KVPair<T>;

  @Input() objIsValid: () => boolean = () => true;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Internal State
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  uploadInProcess = false;

  deleteInProgress = false;

  refreshInProgress = false;

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Selected Item Change
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  _selectedKVPairChange(kvPair: KVPair<T> | undefined) {
    this.selectedKVPair = kvPair;
    this.selectedKVPairChange.emit(kvPair);
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Button Presses
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  _uploadClick() {
    this.confirmationService.confirm({
      header: `Are you sure you want to upload ${this.label}`,
      message: 'This action cannot be undone.',
      accept: async () => {
        try {
          this.uploadInProcess = true;
          await this.doUpload();
          this.successMessage();
        } catch (error) {
          this.failureMessage(error);
        } finally {
          this.uploadInProcess = false;
        }
      },
    });
  }

  _duplicateClick() {
    this.duplicateClick.emit();
  }

  _newClick() {
    this.newClick.emit();
  }

  _deleteClick() {
    this.confirmationService.confirm({
      header: `Are you sure you want to delete ${this.label}`,
      message: 'This action cannot be undone.',
      accept: async () => {
        try {
          this.deleteInProgress = true;
          await this.doDelete();
          this.successMessage();
        } catch (error) {
          this.failureMessage(error);
        } finally {
          this.deleteInProgress = false;
        }
      },
    });
  }

  async _refreshClick() {
    try {
      this.refreshInProgress = true;
      await this.doRefresh();
      this.successMessage();
    } catch (error) {
      this.failureMessage(error);
    } finally {
      this.refreshInProgress = false;
    }
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Notifications
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  private successMessage() {
    this.messageService.add({
      severity: 'success',
      summary: 'Success',
      detail: '',
    });
  }

  private failureMessage(error: any) {
    console.error(error);
    this.messageService.add({
      severity: 'error',
      summary: 'Error',
      detail: JSON.stringify(error),
    });
  }
}
