import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { subHours } from 'date-fns';
import { isEmpty, orderBy } from 'lodash';
import moment from 'moment-timezone';
import { MessageService } from 'primeng/api';
import { Table, TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { firstValueFrom, lastValueFrom } from 'rxjs';

import { CallLogDisplay, ConversationLogDisplay, OrgData, UserData } from '@pwp-common';

import { DisplayableCommunication } from '../../../../../../../common/src/objects/core/communication/displayable-communication';
import { hasFilter } from '../../../common/p-table/has-filter';
import { PTableCol, subscribePTableTranslatedCols } from '../../../common/p-table/p-table-col';
import { AsyncServiceRequestService } from '../../../services/async-service-request/async-service-request.service';
import { CallListService } from '../../../services/call/call-list/call-list.service';
import { CallLogService } from '../../../services/call/call-log/call-log.service';
import { IvrService } from '../../../services/call/ivr/ivr.service';
import { ConversationConfigService } from '../../../services/conversation/conversation-config/conversation-config.service';
import { ConversationLogService } from '../../../services/conversation/conversation-log/conversation-log.service';
import { OrgDataService } from '../../../services/orgs/org-data/org-data.service';
import { RolesService } from '../../../services/user/roles/roles.service';
import { UserDataService } from '../../../services/user/user-data/user-data.service';
import { CommunicationLogDetailCallComponent } from '../communication-log-detail-call/communication-log-detail-call.component';
import { CommunicationLogDetailConversationComponent } from '../communication-log-detail-conversation/communication-log-detail-conversation.component';

@UntilDestroy()
@Component({
  selector: 'app-communication-logs-table',
  standalone: true,
  imports: [
    MatButtonModule,
    CommonModule,
    CommunicationLogDetailCallComponent,
    CommunicationLogDetailConversationComponent,
    FormsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    ReactiveFormsModule,
    TableModule,
    ToastModule,
    TranslocoModule,
  ],
  templateUrl: './communication-logs-table.component.html',
  styleUrls: ['./communication-logs-table.component.css'],
  providers: [MessageService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('rowExpansionTrigger', [
      state(
        'void',
        style({
          transform: 'translateX(-10%)',
          opacity: 0,
        }),
      ),
      state(
        'active',
        style({
          transform: 'translateX(0)',
          opacity: 1,
        }),
      ),
      transition('* <=> *', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class CommunicationLogsTableComponent implements OnInit {
  private timezone: string;

  public loading = true;

  public cols: PTableCol[] = [];

  public selectedCols: PTableCol[] = [];

  public allUserDataMap: Map<string, UserData>;

  public orgData: OrgData;

  public tableRows: DisplayableCommunication[];

  @Input() public contactCenterMode = false;

  public readonly form = new UntypedFormGroup({
    start: new UntypedFormControl(subHours(new Date(), 12)),
    end: new UntypedFormControl(new Date()),
  });

  public readonly roles$ = this.rolesService.getRolesMap();

  @Output() public readonly onRowExpanded = new EventEmitter<string>();

  constructor(
    private asyncServiceRequestService: AsyncServiceRequestService,
    private callListService: CallListService,
    private callLogService: CallLogService,
    private changeDetectorRef: ChangeDetectorRef,
    private conversationConfigService: ConversationConfigService,
    private conversationLogService: ConversationLogService,
    private ivrService: IvrService,
    private orgDataService: OrgDataService,
    private rolesService: RolesService,
    private translocoService: TranslocoService,
    private userDataService: UserDataService,
  ) {}

  private async getCallLogDisplayRows(start: moment.Moment, end: moment.Moment) {
    const callLogs = await this.callLogService.getCallLogsVisibleByUser(start, end);

    const [callListMap, ivrsMap] = await Promise.all([
      lastValueFrom(this.callListService.getDocs()),
      lastValueFrom(this.ivrService.getDocs()),
    ]);

    const asyncServiceRequestIds = callLogs.reduce((acc, callLog) => {
      const asyncServiceRequestId = callLog.getAsyncServiceRequestId();
      const canUseAsyncServiceRequest = !callLog.wasAnswered() && !isEmpty(asyncServiceRequestId);

      return canUseAsyncServiceRequest ? [...acc, asyncServiceRequestId] : acc;
    }, []);
    const asyncServiceRequests = await lastValueFrom(this.asyncServiceRequestService.getDocsWithIds(asyncServiceRequestIds));

    return callLogs.map(
      (callLog) =>
        new CallLogDisplay({
          callLog,
          callListMap,
          ivrsMap,
          allUserDataMap: this.allUserDataMap,
          asyncServiceRequest: asyncServiceRequests.get(callLog.getAsyncServiceRequestId()),
          timezone: this.timezone,
        }),
    );
  }

  private async getConversationLogDisplayRows(start: moment.Moment, end: moment.Moment) {
    const [conversationConfigMap, conversationLogs] = await Promise.all([
      lastValueFrom(this.conversationConfigService.getDocs()),
      lastValueFrom(
        this.conversationLogService.getConversationsWith({
          createTimeStart: start.toDate(),
          createTimeEnd: end.toDate(),
        }),
      ),
    ]);

    return conversationLogs.map(
      (conversationLog) => new ConversationLogDisplay(conversationLog, this.allUserDataMap, this.timezone, conversationConfigMap),
    );
  }

  ///////////////////////////////////////////////////////////////////////
  // Get Data
  ///////////////////////////////////////////////////////////////////////

  private async getInitialData(): Promise<void> {
    this.loading = true;

    // Get All User Data
    [this.allUserDataMap, this.orgData] = await Promise.all([
      lastValueFrom(this.userDataService.getDocs()),
      lastValueFrom(this.orgDataService.getOrgData()),
    ]);

    this.timezone = this.orgData.getTimezone();

    await this.getData();
    this.loading = false;
  }

  public ngOnInit() {
    void this.getInitialData();

    subscribePTableTranslatedCols(
      this,
      this.translocoService,
      'communication-logs-table',
      [
        'communicationType',
        'communicationTypeDetail',
        'receivedAtE164Phone',
        'day',
        'receiveTime',
        'holdDuration',
        'end',
        'talkDuration',
        'answeredBy',
        'disconnectReason',
        'stoppedDialingReason',
        'disconnectedBy',
        'numCounselorsOnCall',
        'hasAnonymousCaller',
        'language',
        'id',
        'incomingCallSID',
        'numUserMessages',
        'numExternalMessages',
        'hasCallbackRequest',
        'forwardedTo',
      ],
      [
        'communicationType',
        'communicationTypeDetail',
        'day',
        'receiveTime',
        'holdDuration',
        'talkDuration',
        'answeredBy',
        'disconnectReason',
        'language',
      ],
    );
  }

  /**
   * Get data based on date range specified in the range form.
   */
  public async getData() {
    this.loading = true;
    const start = moment(this.form.value.start).startOf('day');
    const end = moment(this.form.value.end).endOf('day');

    if (!start.isValid() || !end.isValid()) {
      this.loading = false;
      return;
    }

    const roles = await firstValueFrom(this.roles$);

    const [callLogDisplayRows, conversationLogDisplayRows] = await Promise.all([
      this.getCallLogDisplayRows(start, end),
      roles.orgAdmin ? this.getConversationLogDisplayRows(start, end) : [],
    ]);

    this.tableRows = orderBy([...callLogDisplayRows, ...conversationLogDisplayRows], (z) => z.timestamp.valueOf(), ['desc']);
    this.loading = false;
    this.onRowCollapse();
    this.changeDetectorRef.detectChanges();
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  // Filter
  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  public hasFilter(table: Table): boolean {
    return hasFilter(table);
  }

  public onRowExpand(callLogDisplay: CallLogDisplay) {
    if (this.contactCenterMode) {
      this.onRowExpanded.emit(callLogDisplay?.id);
    }
  }

  public onRowCollapse() {
    if (this.contactCenterMode) {
      this.onRowExpanded.emit();
    }
  }
}
