import {CommunicationSessionEventLogConferenceStartConstructor} from './communication-session-event-log-conference-start-constructor';
import {CommunicationSessionEventLogConferenceStartSchema} from './communication-session-event-log-conference-start-schema';
import {CommunicationSessionEventLogConference} from '../../generic/communication-session-event-log-conference/communication-session-event-log-event-conference';
import {CommunicationSessionEventLogConferenceType} from '../../generic/communication-session-event-log-conference-type';
import {CommunicationSessionEventLogConferenceSchema} from '../../generic/communication-session-event-log-conference/communication-session-event-log-conference-schema';

export class CommunicationSessionEventLogConferenceStart extends CommunicationSessionEventLogConference {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionEventLogConferenceStartConstructor) {
    (parameters as any)[CommunicationSessionEventLogConferenceSchema.conferenceEventType] = CommunicationSessionEventLogConferenceType.conferenceStart;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationSessionEventLogConferenceStart {
    return new CommunicationSessionEventLogConferenceStart(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationSessionEventLogConferenceStart.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): CommunicationSessionEventLogConferenceStartSchema {
    return new CommunicationSessionEventLogConferenceStartSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
