<!-- Switch Command -->
<form *ngIf="!loading" [formGroup]="form">
  <mat-button-toggle-group formControlName="switchOnField">
    <mat-button-toggle *ngFor="let switchField of switchOnFieldList" value="{{ switchField }}">
      {{ switchField }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <mat-error *ngIf="switchOnField.errors?.required"> Switch on field is required. </mat-error>
</form>

<app-switch-case-select-and-edit [(objList)]="cases" (objListChange)="updateObjAndEmit()"> </app-switch-case-select-and-edit>

<!-- Is Valid Indicator -->
<app-obj-is-valid *ngIf="!loading" [isValidObj]="isValidObjScoper"></app-obj-is-valid>
