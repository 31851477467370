<ng-container *transloco="let t; read: 'target-offers-skip-if-editor'">
  <form [formGroup]="form">
    <div class="formgrid grid p-fluid">
      <!-- Skip If Unavailable -->
      <div class="field col-12 mt-3">
        <span class="p-float-label">
          <p-dropdown
            attr.aria-label="{{ t('skipIfUnavailableLabel') }}"
            id="skipIfUnavailable"
            [autoDisplayFirst]="false"
            [formControl]="skipIfUnavailable"
            [ngClass]="{ 'ng-invalid': skipIfNumCallsInProgressGE?.invalid }"
            [options]="skipIfUnavailableOptions"
          >
            <ng-template pTemplate="selectedItem" let-item>
              <div class="row w-full">
                <div class="col">
                  {{ t(item.label) }}
                </div>
              </div>
            </ng-template>

            <ng-template pTemplate="item" let-item>
              <div class="row w-full">
                <div class="col">
                  {{ t(item.label) }}
                </div>
              </div>
            </ng-template>
          </p-dropdown>
          <label for="skipIfUnavailable">{{ t('skipIfUnavailableLabel') }}</label>
        </span>
        <p *ngIf="skipIfUnavailable.hasError('required')" class="p-error">{{ t('skipIfUnavailableErrorRequired') }}</p>
      </div>

      <!-- Select Skip If Num In Progress Calls >=  -->
      <div class="field col-12 mt-3">
        <span class="p-float-label">
          <p-dropdown
            attr.aria-label="{{ t('skipIfNumCallsInProgressGELabel') }}"
            id="skipIfNumCallsInProgressGE"
            [autoDisplayFirst]="false"
            [formControl]="skipIfNumCallsInProgressGE"
            [ngClass]="{ 'ng-invalid': skipIfNumCallsInProgressGE?.invalid }"
            [options]="skipIfNumCallsInProgressGEOptions"
          >
            <ng-template pTemplate="selectedItem" let-item>
              <div class="row w-full">
                <div class="col">
                  {{ t(item.label, { num: skipIfNumCallsInProgressGE.value }) }}
                </div>
              </div>
            </ng-template>

            <ng-template pTemplate="item" let-item>
              <div class="row w-full">
                <div class="col">
                  {{ t(item.label, { num: item.value }) }}
                </div>
              </div>
            </ng-template>
          </p-dropdown>
          <label for="skipIfNumCallsInProgressGE">{{ t('skipIfNumCallsInProgressGELabel') }}</label>
        </span>
        <p *ngIf="skipIfNumCallsInProgressGE.hasError('required')" class="p-error">{{ t('skipIfNumCallsInProgressGEErrorRequired') }}</p>
        <div class="wrap" id="skipIfNumCallsInProgressGE-help">
          <p *ngIf="skipIfNumCallsInProgressGE.value === 'neverSkip'">
            {{ t('skipIfNumCallsInProgressGENeverHelp', { num: skipIfNumCallsInProgressGE.value }) }}
          </p>
          <p *ngIf="skipIfNumCallsInProgressGE.value !== 'neverSkip'">
            {{ t('skipIfNumCallsInProgressGEHelp', { num: skipIfNumCallsInProgressGE.value }) }}
          </p>
        </div>
      </div>
    </div>
  </form>
</ng-container>
