
import {SerializableObjectSchema} from '../../generic/serialization/serializable-object-schema';
import {VoiceResponseCommandName} from '../any-voice-response-command/voice-response-command-name';
import {VoiceResponseCommand} from '../generic/voice-response-command';
import {VoiceResponseCommandSchema} from '../generic/voice-response-command-schema';
import {EnforceBlockedCallerConstructor} from './enforce-blocked-caller-constructor';
import {EnforceBlockedCallerSchema} from './enforce-blocked-caller-schema';
import {cloneDeep} from 'lodash';

export class EnforceBlockedCaller extends VoiceResponseCommand {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  commands!: VoiceResponseCommand[];
  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: EnforceBlockedCallerConstructor) {
    // This hack ensures that deserializing a VoiceCommand is equal to instanting it directly.
    // The difference is trivial, and only required for intuitive unit testing.
    (parameters as any)[VoiceResponseCommandSchema.commandName] = VoiceResponseCommandName.enforceBlockedCaller;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): EnforceBlockedCaller {
    return new EnforceBlockedCaller(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(EnforceBlockedCaller.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new EnforceBlockedCallerSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCommands(): VoiceResponseCommand[] {
    return cloneDeep(this.commands);
  }
  /////////////////////////////////////////////////////////////////////////////
  // Setters
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Other Methods
  /////////////////////////////////////////////////////////////////////////////
}
