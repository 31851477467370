<ng-container *transloco="let t; read: 'inbox-table'">
  <app-data-table
    [cols]="cols"
    [expansionRow]="expansionRow"
    [loading]="loading"
    [refreshClicked]="getDataWrapper"
    [rightActionsTemplate]="rightActionsTemplate"
    [selectedCols]="selectedCols"
    [tableRows]="tableRows"
    [translationScope]="'inbox-table'"
  >
    <ng-template #expansionRow let-rowData>
      <div [ngSwitch]="rowData?.rowType">
        <app-inbox-voicemail-detail *ngSwitchCase="'voicemail'" [row]="rowData"></app-inbox-voicemail-detail>
        <app-inbox-callback-detail *ngSwitchCase="'callbackRequest'" [row]="rowData"></app-inbox-callback-detail>
      </div>
    </ng-template>

    <ng-template #rightActionsTemplate>
      <app-date-range-select
        [label]="t('dateRangeSelectLabel')"
        [(ngModel)]="dateRange"
        (ngModelChange)="getDataWrapper()"
      ></app-date-range-select>
    </ng-template>
  </app-data-table>
</ng-container>
