import { isNil } from 'lodash';
import moment from 'moment-timezone';

import { displayTime } from '@pwp-common';

interface GetExpirationDisplayTimeOptions {
  piiRetentionTime: string;
  timezone: string;
}

const getEarliestDateTime = (objects: (Date | undefined)[]): number | undefined =>
  objects.reduce<number | undefined>((currentEarliestDate, date) => {
    if (isNil(date)) {
      return currentEarliestDate;
    }

    if (isNil(currentEarliestDate)) {
      return date.getTime();
    }

    return Math.min(currentEarliestDate, date.getTime());
  }, undefined);

export const getExpirationDisplayTime = (dates: (Date | undefined)[], options: GetExpirationDisplayTimeOptions): string => {
  const earliestDateTime = getEarliestDateTime(dates);

  if (isNil(earliestDateTime)) {
    return undefined;
  }

  const duration = moment.duration(options.piiRetentionTime);
  const expirationTime = moment(earliestDateTime).add(duration);

  return displayTime(expirationTime, options.timezone);
};
