import {ResponseSchema} from '../../generic/response/response-schema';
import {SchemaField} from '../../../objects/generic/serialization/schema-field';
import {TemplateText} from '../../../objects/text/template-text/template-text';

export class ExternalChatResponseSchema extends ResponseSchema {
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public static readonly jwt = 'jwt';
  public static readonly conversationSid = 'conversationSid';
  public static readonly message = 'message';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Defaults = class extends ResponseSchema.GenericDefaults {
    public static readonly error = undefined;
    public static readonly jwt = undefined;
    public static readonly conversationSid = undefined;
    public static readonly message = undefined;
  };
  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();
    /* eslint-disable */
    parameters[ExternalChatResponseSchema.jwt] = SchemaField.string(ExternalChatResponseSchema.Defaults.jwt);
    parameters[ExternalChatResponseSchema.conversationSid] = SchemaField.string(ExternalChatResponseSchema.Defaults.conversationSid);
    parameters[ExternalChatResponseSchema.message] = SchemaField.obj(TemplateText, ExternalChatResponseSchema.Defaults.message);
    /* eslint-enable */

    return parameters;
  }
}
