<ng-container *transloco="let t; read: 'accordion-wizard-steps'">
  <p-accordion [multiple]="false" [(activeIndex)]="activeIndex" (activeIndexChange)="updateVisibleStepComponent()">
    <p-accordionTab *ngFor="let step of steps; let i = index" [cache]="false">
      <ng-template pTemplate="header">
        <app-accordion-wizard-step-header
          class="flex-grow-1 flex"
          [hasError]="step.hasError"
          [label]="step.label"
        ></app-accordion-wizard-step-header>
      </ng-template>
      <ng-template pTemplate="content">
        <div (cdkObserveContent)="updateVisibleStepComponent()">
          <ng-content></ng-content>
        </div>
        <app-accordion-wizard-step-content-footer
          class="flex"
          [continueLoadingIndicator]="continueLoadingIndicator"
          [hasError]="step.hasError"
          [numSteps]="steps.length"
          [stepIndex]="i"
          (continue)="triggerStepAfterIndex(i)"
        >
        </app-accordion-wizard-step-content-footer>
      </ng-template>
    </p-accordionTab>
  </p-accordion>
</ng-container>
