import type {AnySchema} from 'joi';

import {SchemaField} from '../../../../../objects/generic/serialization/schema-field';
import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';

import {CommunicationsResponseGetVoiceDeviceTokenError} from './communications-response-get-voice-device-token-error';

export class CommunicationsResponseGetVoiceDeviceTokenSchema extends CommunicationsResponseSchema {
  public static readonly token = 'token';

  public static readonly Defaults = class extends CommunicationsResponseSchema.Defaults {
    public static readonly token = undefined;
  };

  public defaultValuesToSerialize(): Set<string> {
    return new Set([]);
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Definition
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: AnySchema} {
    const parameters = super.getSchemaDefinition();

    parameters[CommunicationsResponseGetVoiceDeviceTokenSchema.error] = SchemaField.string(CommunicationsResponseSchema.Defaults.error)
      .optional()
      .valid(...Object.values(CommunicationsResponseGetVoiceDeviceTokenError));
    parameters[CommunicationsResponseGetVoiceDeviceTokenSchema.token] = SchemaField.jwt(CommunicationsResponseGetVoiceDeviceTokenSchema.Defaults.token);

    return parameters;
  }
}
