import {EventDataSchema} from '../event-data/event-data-schema';

export const getEventColorDisplayName = (color: string | undefined): string => {
  switch (color) {
    case EventDataSchema.Colors.reserved: {
      return 'reserved';
    }
    default: {
      return '';
    }
  }
};
