import { AbstractControl } from '@angular/forms';

import { SequenceStep, SequenceStepType } from '../common/sequence-step';

export const makeSequenceSteps = (controls: AbstractControl[]): SequenceStep[] => {
  const sequenceSteps: SequenceStep[] = [
    { type: SequenceStepType.start, formControlIndex: undefined, showAddIcon: true, control: undefined },
  ];

  const inputLength = controls?.length ?? 0;

  for (let i = 0; i < inputLength; i++) {
    const row: SequenceStep = {
      type: SequenceStepType.step,
      formControlIndex: i,
      showAddIcon: true,
      control: controls[i],
    };
    sequenceSteps.push(row);
  }

  if (inputLength > 0) {
    sequenceSteps.push({ type: SequenceStepType.end, formControlIndex: undefined, control: undefined });
  }
  return sequenceSteps;
};
