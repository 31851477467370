import {UserData} from '../../user/user-data/user-data';
import {EntityStats} from '../entity-stats/entity-stats';
import {getDisplayName, getEmail} from '../../user/user-data/user-data-display';
import {AssignedUserStatTypes} from '../assigned-user-type-stats/assigned-user-stats-types';
import {EntityStatsDisplay} from './entity-stats-display';
import {EntityStatsDisplayWindow} from './entity-stats-display-window/enum';
import {EventType} from '../../org/event-type/event-type';
import {isNil} from 'lodash';
import {AllDataUser} from '../../user/all-data-user/all-data-user';

export type HoursOverTimePrefix = 'NUM_HOURS' | 'NUM_EVENTS';
export class HoursOverTime extends EntityStatsDisplay {
  public static NUM_HOURS_POSTFIX: HoursOverTimePrefix = 'NUM_HOURS';
  public static NUM_EVENTS_POSTFIX: HoursOverTimePrefix = 'NUM_EVENTS';

  public displayName: string;
  public email: string;
  public monthStrs: string[] = [];
  public totalHours = 0;
  public totalEvents = 0;

  constructor(
    entityStats: EntityStats,
    window: EntityStatsDisplayWindow | undefined,
    allUserDataMap: Map<string, AllDataUser | UserData>,
    eventTypes: EventType[]
  ) {
    super(entityStats, window);
    this.displayName = getDisplayName(allUserDataMap, entityStats.getId());
    this.email = getEmail(allUserDataMap, entityStats.getId());

    for (const [monthStr, entityStatsChunk] of entityStats.getByMonth()) {
      if (!isNil(this.window) && !this.isMonthContainedInWindow(monthStr)) {
        continue;
      }

      let hours = 0;
      let events = 0;
      for (const eventType of eventTypes) {
        hours += entityStatsChunk.getHours(eventType, AssignedUserStatTypes.any);
        events += entityStatsChunk.getNumEvents(eventType, AssignedUserStatTypes.any);
      }
      (this as any)[HoursOverTime.getKeyName(HoursOverTime.NUM_HOURS_POSTFIX, monthStr)] = hours;
      (this as any)[HoursOverTime.getKeyName(HoursOverTime.NUM_EVENTS_POSTFIX, monthStr)] = events;
      this.monthStrs.push(monthStr);
    }
    this.totalHours = entityStats.getTotal().getHours(undefined, AssignedUserStatTypes.any);
    this.totalEvents = entityStats.getTotal().getNumEvents(undefined, AssignedUserStatTypes.any);
  }

  public static getKeyName(prefix: HoursOverTimePrefix, monthStr: string): string {
    return `${monthStr}-${prefix}`;
  }
}
