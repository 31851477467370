import {isNil} from 'lodash';
import {ServiceException} from '../service-exception/service-exception/service-exception';
import {AllDataServiceExceptionActionInboundCallSession} from '../all-data-service-exception-action-inbound-call-session/all-data-service-exception-action-inbound-call-session';

export class AllDataServiceException {
  constructor(
    public serviceException: ServiceException,
    public inboundCallSessionAction: AllDataServiceExceptionActionInboundCallSession
  ) {
    if (isNil(serviceException)) {
      console.error('AllDataServiceException: Cannot initialize class with all empty values', {
        serviceException,
        inboundCallSessionAction,
      });
      throw new Error('AllDataServiceException: Cannot initialize class with all empty values');
    }
  }
}
