import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { AnyFormResponse, FormRequest, FormResponse } from '@pwp-common';

import { CallableFunctionService } from '../../../services/generic/callable-function-service/callable-function-service';

@Injectable({
  providedIn: 'root',
})
export class FormEndpointService extends CallableFunctionService<FormRequest, FormResponse<unknown>> {
  constructor(fns: AngularFireFunctions) {
    super(AnyFormResponse, fns);
  }

  public getFunctionName(): string {
    return 'forms';
  }
}
