import {isNil} from 'lodash';
import {AllDataServiceOperation} from '../../../voice-response-command/service-operation/all-data-service-operation/all-data-service-operation';

export class AllDataServiceExceptionActionInboundCallSession {
  constructor(public parameters: {onCreate: AllDataServiceOperation}) {
    if (isNil(parameters?.onCreate)) {
      console.error('AllDataServiceExceptionActionInboundCallSession: Cannot initialize class with all empty values', {
        parameters,
      });
      throw new Error('AllDataServiceExceptionActionInboundCallSession: Cannot initialize class with all empty values');
    }
  }
}
