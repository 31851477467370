import {SerializableObjectSchema} from '../../../../../objects/generic/serialization/serializable-object-schema';
import {CommunicationsResponse} from '../../generic/communications-response/communications-response';
import {CommunicationsResponseSchema} from '../../generic/communications-response/communications-response-schema';
import {CommunicationsResponseCreateAsyncServiceRequestConstructor} from './communications-response-create-async-service-request-constructor';
import {CommunicationsResponseCreateAsyncServiceRequestSchema} from './communications-response-create-async-service-request-schema';
import {CommunicationsResponseCreateAsyncServiceRequestError} from './communications-response-create-async-service-request-error';
import {CommunicationsRequestName} from '../../../communications-request-name';

export class CommunicationsResponseCreateAsyncServiceRequest extends CommunicationsResponse<CommunicationsResponseCreateAsyncServiceRequestError> {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationsResponseCreateAsyncServiceRequestConstructor) {
    (parameters as any)[CommunicationsResponseSchema.type] = CommunicationsRequestName.createAsyncServiceRequest;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationsResponseCreateAsyncServiceRequest {
    return new CommunicationsResponseCreateAsyncServiceRequest(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationsResponseCreateAsyncServiceRequest.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new CommunicationsResponseCreateAsyncServiceRequestSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
}
