<ng-container *transloco="let t; read: 'event-request-config-editor'">
  <p-toast position="bottom-center"></p-toast>
  <form [formGroup]="form">
    <fieldset class="event-request-config-editor__form-section">
      <legend class="event-request-config-editor__form-section-title">{{ t('requestableSlots') }}</legend>

      <div class="field col-12">
        <div class="field">
          <!-- Primary -->
          <p-checkbox
            ariaLabel="{{ t('requestSlotsPrimaryLabel') }}"
            id="requestSlotsPrimaryEnabled"
            label="{{ t('requestSlotsPrimaryLabel') }}"
            [binary]="true"
            [formControl]="form.controls.requestableSlotsPrimary"
          >
          </p-checkbox>
          <div *ngIf="form.controls.requestableSlotsPrimary.enabled" id="requestSlotsPrimaryEnabled-help">
            <ng-template [ngIf]="form.controls.requestableSlotsPrimary.value">
              <p class="mt-1 wrap">{{ t('requestSlotsPrimaryCheckedHelp') }}</p>
            </ng-template>
            <ng-template [ngIf]="!form.controls.requestableSlotsPrimary.value">
              <p class="mt-1 wrap">{{ t('requestSlotsPrimaryUnCheckedHelp') }}</p>
            </ng-template>
          </div>
        </div>
        <div class="field">
          <!-- Backup -->
          <p-checkbox
            ariaLabel="{{ t('requestSlotsBackupLabel') }}"
            id="requestSlotsBackupEnabled"
            label="{{ t('requestSlotsBackupLabel') }}"
            [binary]="true"
            [formControl]="form.controls.requestableSlotsBackup"
          >
          </p-checkbox>
          <div *ngIf="form.controls.requestableSlotsBackup.enabled" id="requestSlotsBackupEnabledLabel-help">
            <ng-template [ngIf]="form.controls.requestableSlotsBackup.value">
              <p class="mt-1 wrap">{{ t('requestSlotsBackupCheckedHelp') }}</p>
            </ng-template>
            <ng-template [ngIf]="!form.controls.requestableSlotsBackup.value">
              <p class="mt-1 wrap">{{ t('requestSlotsBackupUnCheckedHelp') }}</p>
            </ng-template>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset class="event-request-config-editor__form-section">
      <legend class="event-request-config-editor__form-section-title">{{ t('autoApproveShifts') }}</legend>

      <div class="field col-12">
        <div class="field">
          <!-- Primary -->
          <p-checkbox
            ariaLabel="{{ t('autoApproveSlotsPrimaryLabel') }}"
            id="autoApproveSlotsPrimaryEnabled"
            label="{{ t('autoApproveSlotsPrimaryLabel') }}"
            [binary]="true"
            [formControl]="form.controls.autoApproveSlotsPrimary"
          >
          </p-checkbox>
          <div *ngIf="form.controls.autoApproveSlotsPrimary.enabled" id="autoApproveSlotsPrimaryEnabled-help">
            <ng-template [ngIf]="form.controls.autoApproveSlotsPrimary.value">
              <p class="mt-1 wrap">{{ t('autoApproveShiftsPrimaryCheckedHelp') }}</p>
            </ng-template>
            <ng-template [ngIf]="!form.controls.autoApproveSlotsPrimary.value">
              <p class="mt-1 wrap">{{ t('autoApproveShiftsPrimaryUnCheckedHelp') }}</p>
            </ng-template>
          </div>
        </div>
        <div class="field">
          <!-- Backup -->
          <p-checkbox
            ariaLabel="{{ t('autoApproveSlotsBackupLabel') }}"
            id="autoApproveSlotsBackupEnabled"
            label="{{ t('autoApproveSlotsBackupLabel') }}"
            [binary]="true"
            [formControl]="form.controls.autoApproveSlotsBackup"
          >
          </p-checkbox>
          <div *ngIf="form.controls.autoApproveSlotsBackup.enabled" id="autoApproveSlotsBackupEnabled-help">
            <ng-template [ngIf]="form.controls.autoApproveSlotsBackup.value">
              <p class="mt-1 wrap">{{ t('autoApproveShiftsBackupCheckedHelp') }}</p>
            </ng-template>
            <ng-template [ngIf]="!form.controls.autoApproveSlotsBackup.value">
              <p class="mt-1 wrap">{{ t('autoApproveShiftsBackupUnCheckedHelp') }}</p>
            </ng-template>
          </div>
        </div>
      </div>
    </fieldset>

    <fieldset class="event-request-config-editor__form-section">
      <legend class="event-request-config-editor__form-section-title">{{ t('notificationsLegend') }}</legend>

      <label *ngrxLet="form.controls.emailOnNewRequestCreated as emailsControl" class="event-request-config-editor__form-field">
        <span class="event-request-config-editor__form-field-label">{{ t('emailOnNewRequestCreatedLabel') }}</span>

        <p-chips
          placeholder="email@example.com"
          [addOnBlur]="true"
          [addOnTab]="true"
          [allowDuplicate]="false"
          [formControl]="emailsControl"
        ></p-chips>

        <app-form-field-help *ngrxLet="emailsControl.errors as controlErrors" [control]="emailsControl">
          <ng-container form-field-help-hint>
            {{ t('emailOnNewRequestCreatedHelp', { eventTypeName: baseObj?.displayName }) }}
          </ng-container>

          <ng-container form-field-help-errors>
            <ng-container *ngIf="controlErrors?.invalidEmails as invalidEmails">
              {{ t('invalidEmailsError', { invalidEmails }) }}
            </ng-container>
          </ng-container>
        </app-form-field-help>
      </label>
    </fieldset>
  </form>
</ng-container>
