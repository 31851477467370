import {DBDocSchema} from '../../generic/db-doc/db-doc-schema';
import {SchemaField} from '../../generic/serialization/schema-field';
import {AnyVoiceResponseCommand} from '../../voice-response-command/any-voice-response-command/any-voice-response-command';
import {Hangup} from '../../voice-response-command/hangup/hangup';
import {Say} from '../../voice-response-command/say/say';
import {SaySchema} from '../../voice-response-command/say/say-schema';

/**
 * This is the set of commands that determines how to respond
 * when an incoming call arrives.
 */
export class CallEntrypointSchema extends DBDocSchema {
  // Set of commands to execute in response to an incoming call
  public static readonly commands = 'commands';

  // The e164 phones for which this is the entrypoint
  public static readonly e164Phones = 'e164Phones';

  // Description
  public static readonly description = 'description';

  // Display Name
  public static readonly displayName = 'displayName';

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Constants
  ////////////////////////////////////////////////////////////////////////////////////////////////

  static Constants = class {
    public static readonly collection = 'callEntrypoints';
  };

  static Defaults = class {
    // Set of commands to execute in response to an incoming call
    public static readonly commands = [
      new Say({
        message:
          'Were sorry but this line is not configured. If you feel this is due to an error, please email customerservice at helpline software dot com. Goodbye.',
        language: 'en-US',
        loop: 1,
        voice: SaySchema.Defaults.voice,
      }),
      new Hangup({}),
    ];

    // The e164 phones for which this is the entrypoint
    public static readonly e164Phones = [];

    // Description
    public static readonly description = 'missing-description';

    // Display Name
    public static readonly displayName = 'missing-displayName';
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Schema Parameters
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getSchemaDefinition(): {[key: string]: import('joi').AnySchema} {
    const parameters = super.getSchemaDefinition();

    /* eslint-disable */
    parameters[CallEntrypointSchema.commands] = SchemaField.shortObjArray(AnyVoiceResponseCommand, CallEntrypointSchema.Defaults.commands);
    parameters[CallEntrypointSchema.e164Phones] = SchemaField.shortStringArray(CallEntrypointSchema.Defaults.e164Phones);
    parameters[CallEntrypointSchema.description] = SchemaField.string(CallEntrypointSchema.Defaults.description);
    parameters[CallEntrypointSchema.displayName] = SchemaField.string(CallEntrypointSchema.Defaults.displayName);
    /* eslint-enable */

    return parameters;
  }

  ////////////////////////////////////////////////////////////////////////////////////////////////
  // Collection
  ////////////////////////////////////////////////////////////////////////////////////////////////

  public getCollection(orgId: string): string {
    return `${this.orgDoc(orgId)}/${CallEntrypointSchema.Constants.collection}`;
  }
}
