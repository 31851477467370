import { isNil } from 'lodash';

import { VoicemailMetadata } from '@pwp-common';

import { InboxRow } from '../inbox-row/inbox-row';
import { InboxRowStatus } from '../inbox-row/inbox-row-status';
import { InboxRowType } from '../inbox-row/inbox-row-type';

import { VoicemailRowConstructor } from './voicemail-row-constructor';

export class VoicemailRow extends InboxRow {
  //////////////////////////////////////////////////////////////////////////////////////////
  // Constructor
  //////////////////////////////////////////////////////////////////////////////////////////

  constructor(public params: VoicemailRowConstructor) {
    super({
      id: params.voicemailMetadata.getId(),
      timestamp: params.voicemailMetadata.getRecordingStartTime(),
      inboxRowType: InboxRowType.voicemail,
      handledByUserId: params.voicemailMetadata.getAssignedTo(),
      status: getStatus(params.voicemailMetadata),
      callLists: params.callLists,
      callLog: params.callLog,
      allUserDataMap: params.allUserDataMap,
      orgData: params.orgData,
    });
  }
}

const getStatus = (voicemailMetadata: VoicemailMetadata): InboxRowStatus => {
  if (isNil(voicemailMetadata)) {
    return InboxRowStatus.closed;
  }
  if (!isNil(voicemailMetadata.getAssignedTo())) {
    return InboxRowStatus.closed;
  }

  if (voicemailMetadata.getBlobDeleted()) {
    return InboxRowStatus.redacted;
  }

  return InboxRowStatus.open;
};
