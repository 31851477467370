import { isNil } from '@ngneat/transloco';
import parsePhoneNumber from 'libphonenumber-js';

export interface ParsePhonesResult {
  invalidPhones: string[];
  e164Phones: string[];
}

export const parsePhones = (phoneStrs: string[]): ParsePhonesResult => {
  const invalidPhones: string[] = [];
  const e164Phones: string[] = [];

  for (const phoneStr of phoneStrs ?? []) {
    const parsedPhone = parsePhoneNumber(phoneStr, 'US');
    if (isNil(parsedPhone) || !parsedPhone.isValid()) {
      invalidPhones.push(phoneStr);
      continue;
    }
    e164Phones.push(parsedPhone.number);
  }

  return { invalidPhones, e164Phones };
};
