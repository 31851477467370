<ng-container *transloco="let t; read: 'event-surgery-table'">
  <p-toast></p-toast>

  <p-card class="selector-card" [header]="t('title')">
    <p-table
      *ngIf="selection$ | async as selection"
      #dt
      dataKey="id"
      responsiveLayout="scroll"
      styleClass="p-datatable-gridlines"
      [columns]="selectedCols"
      [loading]="tableLoading"
      [selection]="selection"
      [value]="dbTransactions"
      (selectionChange)="selection$.next($event)"
    >
      <!-- Select Columns to Display -->
      <ng-template pTemplate="caption">
        <div class="row">
          <!-- Left Aligned Buttons -->
          <div class="col-6" class="event-surgery-table__actions">
            <p-multiSelect
              [defaultLabel]="t('columns')"
              [options]="cols"
              [selectedItemsLabel]="'{0} ' + t('selectedColumns') | lowercase"
              [(ngModel)]="selectedCols"
            >
            </p-multiSelect>
            <!-- Action Buttons -->
            <button pButton [disabled]="!(selection?.length > 0)" (click)="eventSelectionMenu.toggle($event)">
              {{ t('actionMenuTitle', { num: this.selection.length }) }}
            </button>
            <p-menu #eventSelectionMenu appendTo="body" [model]="eventSelectionActions$ | async" [popup]="true"> </p-menu>
          </div>
        </div>
      </ng-template>

      <!-- Header -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 3rem">
            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
          </th>
          <th *ngFor="let col of columns" pResizableColumn>
            {{ t(col) }}
          </th>
        </tr>
      </ng-template>

      <!-- Display Rows -->
      <ng-template pTemplate="body" let-columns="columns" let-expanded="expanded" let-rowData>
        <tr [ngClass]="rowData.value['alertLevel']" [pSelectableRow]="rowData">
          <td>
            <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
          </td>
          <td *ngFor="let col of columns" class="ui-resizable-column">
            {{ rowData.value[col] }}
          </td>
        </tr>
      </ng-template>
      <!-- Empty Message -->
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length + 1">
            {{ t('nothingToShow') }}
          </td>
        </tr>
      </ng-template>
    </p-table>
    <ng-template pTemplate="footer">
      <div class="event-surgery-table__actions">
        <button pButton class="p-button-secondary p-button-outlined" (click)="clearTable()">{{ t('clearAction') }}</button>
        <button
          pButton
          class="p-button-outlined"
          [disabled]="this.dbTransactions === undefined || this.dbTransactions.length === 0 || uploadInProgress"
          (click)="confirmAndUpload()"
        >
          {{ t('update', { num: this.dbTransactions.length }) }}
        </button>
      </div>
    </ng-template>
  </p-card>
</ng-container>
