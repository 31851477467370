import { isNil } from 'lodash';

import { CallListOfferConfig } from '@pwp-common';

import { CallerIdStrategyEditorOutput } from '../caller-id-strategy-editor-output';

export const dbToCallerIdStrategyEditorOutput = (
  callListOfferConfig: CallListOfferConfig | undefined,
): CallerIdStrategyEditorOutput | undefined => {
  if (isNil(callListOfferConfig?.getOfferChannelConfigPhoneCall()?.getCallerIdStrategy())) {
    return undefined;
  }

  return {
    callerIdObjId: callListOfferConfig.getOfferChannelConfigPhoneCall().getCallerIdStrategy().getCallerIdObjId(),
    type: callListOfferConfig.getOfferChannelConfigPhoneCall().getCallerIdStrategy().getType(),
  };
};
