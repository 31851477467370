import {cloneDeep} from 'lodash';
import {ReservationOfferChannelConfigTextConstructor} from './reservation-offer-channel-config-text-constructor';
import {ReservationOfferChannelConfigTextSchema} from './reservation-offer-channel-config-text-schema';
import {SerializableObject} from '../../../generic/serialization/serializable-object';
import {SerializableObjectSchema} from '../../../generic/serialization/serializable-object-schema';
import {TemplateText} from '../../../text/template-text/template-text';

export class ReservationOfferChannelConfigText extends SerializableObject {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////
  protected callerIdObjId!: string;
  protected body!: TemplateText;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: ReservationOfferChannelConfigTextConstructor) {
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only dialTimeoutMS
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): ReservationOfferChannelConfigText {
    return new ReservationOfferChannelConfigText(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(ReservationOfferChannelConfigText.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): SerializableObjectSchema {
    return new ReservationOfferChannelConfigTextSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////

  public getCallerIdObjId() {
    return cloneDeep(this.callerIdObjId);
  }

  public getBody() {
    return cloneDeep(this.body);
  }
}
