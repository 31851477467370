import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { EntityStats } from '@pwp-common';

import { DbDocumentService } from '../../generic/db-document-service';
import { AuthService } from '../../user/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class EntityStatsService extends DbDocumentService<EntityStats> {
  ///////////////////////////////////////////////////////////////////////
  // Constructor
  ///////////////////////////////////////////////////////////////////////

  constructor(db: AngularFirestore, authService: AuthService) {
    super(db, authService, EntityStats);
  }

  ///////////////////////////////////////////////////////////////////////
  // Get One User Data
  ///////////////////////////////////////////////////////////////////////

  public getLoggedInUserEntityData(): Observable<EntityStats> {
    const observable = this.getInjectedAuthService()
      .getUserId()
      .pipe(switchMap((userId: string, _: number) => this.getDoc(userId, EntityStats.deserialize({}))));

    return observable;
  }
}
