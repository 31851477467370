import {CommunicationSessionEventLogConferenceEndConstructor} from './communication-session-event-log-conference-end-constructor';
import {CommunicationSessionEventLogConference} from '../../generic/communication-session-event-log-conference/communication-session-event-log-event-conference';
import {CommunicationSessionEventLogConferenceType} from '../../generic/communication-session-event-log-conference-type';
import {CommunicationSessionEventLogConferenceSchema} from '../../generic/communication-session-event-log-conference/communication-session-event-log-conference-schema';
import {cloneDeep} from 'lodash';
import {CommunicationSessionEventLogConferenceEndSchema} from './communication-session-event-log-conference-end-schema';

export class CommunicationSessionEventLogConferenceEnd extends CommunicationSessionEventLogConference {
  /////////////////////////////////////////////////////////////////////////////
  // Variables
  /////////////////////////////////////////////////////////////////////////////

  protected callSidEndingConference!: string;
  protected reasonConferenceEnded!: boolean;

  /////////////////////////////////////////////////////////////////////////////
  // Constructor
  /////////////////////////////////////////////////////////////////////////////

  constructor(parameters: CommunicationSessionEventLogConferenceEndConstructor) {
    (parameters as any)[CommunicationSessionEventLogConferenceSchema.conferenceEventType] = CommunicationSessionEventLogConferenceType.conferenceEnd;
    super(parameters);
  }

  /////////////////////////////////////////////////////////////////////////////
  // Deserialize
  /////////////////////////////////////////////////////////////////////////////

  /**
   * This static function is private, and meant to be called only by
   * SerializableObject, and subclasses
   *
   * @param validationResult
   */
  protected static _deserialize(validationResult: import('joi').ValidationResult): CommunicationSessionEventLogConferenceEnd {
    return new CommunicationSessionEventLogConferenceEnd(super._deserialize(validationResult));
  }

  /////////////////////////////////////////////////////////////////////////////
  // Serialize
  /////////////////////////////////////////////////////////////////////////////

  public serialize() {
    return super.serialize(CommunicationSessionEventLogConferenceEnd.getSchema());
  }

  /////////////////////////////////////////////////////////////////////////////
  // Schema
  /////////////////////////////////////////////////////////////////////////////

  public static getSchema(): CommunicationSessionEventLogConferenceEndSchema {
    return new CommunicationSessionEventLogConferenceEndSchema();
  }

  /////////////////////////////////////////////////////////////////////////////
  // Getters
  /////////////////////////////////////////////////////////////////////////////
  public getCallSidEndingConference() {
    return cloneDeep(this.callSidEndingConference);
  }
  public getReasonConferenceEnded() {
    return cloneDeep(this.reasonConferenceEnded);
  }
}
