<ng-container *transloco="let t; read: 'service-command-editor-flag-service-request'">
  <p-messages severity="info">
    <ng-template pTemplate>
      <p class="wrap">{{ t('description') }}</p>
    </ng-template>
  </p-messages>

  <form [formGroup]="form">
    <div class="formgrid grid p-fluid">
      <!-- Deny -->
      <div class="field col-12">
        <p-selectButton
          ariaLabel="{{ t('denyLabel') }}"
          [formControl]="deny"
          [options]="[
            { label: t('flagAndDenyLabel'), value: true },
            { label: t('flagNoDenyLabel'), value: false }
          ]"
        ></p-selectButton>
        <p *ngIf="deny.hasError('required')" class="p-error">{{ t('denyErrorRequired') }}</p>
        <div id="deny-help">
          <p *ngIf="deny.value" class="wrap">{{ t('denyHelpTrue') }}</p>
          <p *ngIf="!deny.value" class="wrap">{{ t('denyHelpFalse') }}</p>
        </div>
      </div>
    </div>
  </form>
</ng-container>
