import { cloneDeep, isNil } from 'lodash';

import { CommunicationWorkflowEditorOutput } from '../communication-workflow-editor-output';
import { defaultEditorOutput } from '../db-to-editor-output/default-editor-output';

import { EditorFormType } from './editor-form-type';

/**
 * For a given formType return a sensible value of AllDataCommunicationWorkflowEditorTargetMode.
 */
export const formToEditorOutput = (
  base: CommunicationWorkflowEditorOutput,
  formType: EditorFormType,
): CommunicationWorkflowEditorOutput => {
  if (isNil(formType)) {
    return cloneDeep(defaultEditorOutput);
  }

  const result: CommunicationWorkflowEditorOutput = {
    ...defaultEditorOutput,
    ...base,
    displayName: formType.general.displayName,
    description: formType.general.description,
    targets: formType.targets,
    taskReservationTimeoutSeconds: formType.taskReservationTimeoutSeconds,
  };

  return result;
};
